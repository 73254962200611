import React, { useEffect, useState } from 'react'
import { KTIcon, KTSVG } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials'
import profile from '../../../../_metronic/assets/profile.png'
import { getEmployeeById } from '../core/_requests'
import { useParams } from 'react-router-dom'
import AboutMe from '../Components/AboutMe'
import { FilesPage } from '../Components/FilesPage'
import TimeTracker from '../Components/TimeTracker'
import EmployeeLeaveTab from '../Components/LeaveManagement/EmployeeLeaveTab'
import EmployeeAccounts from '../Components/EmployeeAccounts'

export default function ViewEmployee() {
  const [data, setData] = useState([])
  const [property, setProperty] = useState('Profile')
  const id = useParams().id

  const fetchData = async () => {
    try {
      const res = await getEmployeeById(id)
      setData(res)
      console.log('data', res)
    } catch (error) {
      console.error('Error fetching the employee details', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-gray'>
                {/* {data?.profilePic === '' ? ( */}
                <img src={`${profile}`} alt='Metornic' />
                {/* ) : (
                  <img
                    src={`${PF + data?.profilePic}`}
                    alt='Metornic'
                    className='object-fit-cover'
                  />
                )} */}
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle  border-2 border-white h-20px w-20px'></div>
              </div>
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {data?.firstName} {data?.lastName}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <i class="bi bi-telephone-fill fs-4 me-2"></i>
                      {data?.workPhoneNo}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      India
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {data?.email}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTIcon iconName='check' className='fs-3 d-none' />

                    <span className='indicator-label'>Whatsapp</span>
                    <span className='indicator-progress'>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                    Call
                  </a>
                  <div class="dropdown">
                    <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" href="#">Info</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === 'Profile' ? ' active' : ''}`}
                  onClick={() => setProperty('Profile')}
                >
                  Profile
                </div>
              </li>
              {/* <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === 'Team' ? ' active' : ''}`}
                  onClick={() => setProperty('Team')}
                >
                  Team
                </div>
              </li> */}
              {/* <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === 'Calender' ? ' active' : ''}`}
                  onClick={() => setProperty('Calender')}
                >
                  Calender
                </div>
              </li> */}
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === '   Attendance' ? ' active' : ''}`}
                  onClick={() => setProperty('   Attendance')}
                >
                  Attendance
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === ' Time Tracker' ? ' active' : ''}`}
                  onClick={() => setProperty(' Time Tracker')}
                >
                  Time Tracker
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === ' Leave Tracker' ? ' active' : ''}`}
                  onClick={() => setProperty(' Leave Tracker')}
                >
                  Leave Tracker
                </div>
              </li>


              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === 'accounts' ? ' active' : ''}`}
                  onClick={() => setProperty('accounts')}
                >
                  Accounts
                </div>
              </li>

              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === '   Files' ? ' active' : ''}`}
                  onClick={() => setProperty('Files')}
                >
                  Files
                </div>
              </li>
              {/* <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === '   Delegation' ? ' active' : ''}`}
                  onClick={() => setProperty('   Delegation')}
                >
                  Delegation
                </div>
              </li>{' '}
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
            ${property === '   Goals' ? ' active' : ''}`}
                  onClick={() => setProperty('   Goals')}
                >
                  Goals
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      {property === 'accounts' && <EmployeeAccounts />}
      {property === 'Profile' && <AboutMe data={data} />}
      {property === 'Files' && <FilesPage />}
      {property === ' Time Tracker' && <TimeTracker />}
      {property === ' Leave Tracker' && <EmployeeLeaveTab />}
    </div>
  )
}
