import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDocs } from '../core/_requests'

export default function LatestFile({ setProperty }) {
  const id = useParams().id
  const [docs, setdocs] = useState([])

  const getdocss = async () => {
    try {
      const res = await getDocs(id)
      console.log("doc", res.documents)
      setdocs(res.documents)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }
  useEffect(() => {
    getdocss()
  }, [])


  return (
    <div>
      <div class='card-header mt-6'>
        <div class='card-title flex-column'>
          <h3 class='fw-bold mb-1'>Latest Files</h3>
          <div class='fs-6 text-gray-400'> Total {`${docs?.length}`} fields </div>
        </div>
        <div class='card-toolbar'>
          <button href='#' class='btn btn-bg-light btn-active-color-primary btn-sm' onClick={() => setProperty("Files")}>
            View All
          </button>
        </div>

      </div>

      <div class='card-body p-9 pt-3'>
        {docs?.slice(0, 4).map((doc, i) => (
          <div class='d-flex flex-column mb-3'>
            <div class='d-flex align-items-center mb-3'>
              <div class='symbol symbol-30px me-5'>
                <img alt='Icon' src='/media/svg/files/pdf.svg' />
              </div>
              <div class='fw-semibold'>
                <a class='fs-6 fw-bold text-dark text-hover-primary' href='#'>
                  {doc.name}
                </a>
                <div class='text-gray-400'>
                  2 days ago
                  <a href='#'>Karina Clark</a>
                </div>
              </div>
              <button
                type='button'
                class='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <i class='ki-duotone ki-element-plus fs-3'>
                  <span class='path1'></span>
                  <span class='path2'></span>
                  <span class='path3'></span>
                  <span class='path4'></span>
                  <span class='path5'></span>
                </i>
              </button>
            </div>
          </div>
        ))}

        {/* <div class='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6' >
          <i class='ki-duotone ki-svg/files/upload.svg fs-2tx text-primary me-4'></i>
          <div class='d-flex flex-stack flex-grow-1'>
            <div class='fw-semibold'>
              <h4 class='text-gray-900 fw-bold'>Quick file uploader</h4>
              <div class='fs-6 text-gray-700'>Drag & Drop or choose files from computer</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
