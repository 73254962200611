import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  projects: [],
  allProjects: [],
  activeProjects: [],
  loading: true,
  error: null,
}

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects(state, action) {
      state.projects = action.payload
      state.loading = false
    },
    setAllProjects(state, action) {
      state.allProjects = action.payload
    },
    setActiveProjects(state, action) {
      state.activeProjects = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    addProject(state, action) {
      state.projects.push(action.payload)
    },
  },
})

export const {setProjects, setAllProjects, setActiveProjects, setLoading, setError, addProject} =
  projectSlice.actions
export default projectSlice.reducer
