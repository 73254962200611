import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import {getEmployee, getEmployeeByStatus, getLeaveTypeByDeptId, getLeaveTypes, postLeaveApply} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {useNavigate} from 'react-router-dom'

const LeaveApply = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [leaveData, setLeaveData] = useState({
    employeeId: '',
    jobRoleId: '',
    departmentId: '',
    leaveType: '',
    startDate: new Date(),
    endDate: new Date(),
    month: new Date(),
    reason: '',
    status: 'pending',
  })

  // const [employees, setEmployees] = useState([]);
  const [leaveList, setLeaveList] = useState([])
  const [selectedLeave, setSelectedLeave] = useState(null)
  const [employeeList, setEmployeeList] = useState<any>([])
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [roles, setRoles] = useState('')
  const [departments, setDepartments] = useState()

  const getLeaveList = async () => {
    try {
      console.log(' leave list')
      const res = await getLeaveTypes(currentUser?.organization)
      console.log('LEAVE LIST FETCHED', res)
      setLeaveList(
        res?.map((r) => ({
          value: r._id,
          label: r.leaveType,
        }))
      )
    } catch (error) {
      console.error('Error fetching Leave list:', error)
    }
  }

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      console.log('EMPLOYEE LIST FETCHED', res)
      setEmployeeList(
        res?.map((r) => ({
          value: r._id,
          label: r.firstName,
          jobRoleId: r.role, // Store jobRoleId along with employee data
          departmentId: r.department?._id || '',
          departmentName: r.department?.name, // Store departmentId along with employee data
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  // const getLeaveList = async () => {
  //   try {
  //     console.log(" leave list");
  //     const res = await getLeaveTypeByDeptId(currentUser?.organization, employeeList.departmentId)
  //     console.log('LEAVE LIST FETCHED', res)
  //     setLeaveList(
  //       res?.map((r) => ({
  //         value: r._id,
  //         label: r.leaveType,
  //       }))
  //     )
  //   } catch (error) {
  //     console.error('Error fetching Leave list:', error)
  //   }
  // }

  const handleLeaveChange = (selectedOption) => {
    setSelectedLeave(selectedOption)
    console.log(selectedOption)
  }
  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption)
    console.log(selectedOption)
    const employeeId = selectedOption?.value || ''
    // const selectedEmployee = employeeList.find((emp) => emp._id === value)

    setLeaveData((prevData) => ({
      ...prevData,
      employeeId: employeeId, // Corrected the key from "employee" to "employeeId"
      jobRoleId: selectedOption?.jobRoleId || '', // Update jobRoleId based on selected employee
      departmentId: selectedOption?.departmentId || '',
    }))

    console.log('1stone', selectedOption?.departmentName)
    console.log('2ndone', selectedOption?.jobRoleId)
    setDepartments(selectedOption?.departmentName)
    setRoles(selectedOption?.jobRoleId || '')
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setLeaveData({...leaveData, [name]: value})

    // If the changed field is employeeId, find the selected employee and update jobRoleId and departmentId
    if (name === 'employeeId') {
      const selectedEmployee = employeeList.find((emp) => emp._id === value)
      if (selectedEmployee) {
        setLeaveData({
          ...leaveData,
          jobRoleId: selectedEmployee.jobRoleId,
          departmentId: selectedEmployee.department._id,
        })
      }
    }
  }

  const handleDateChange = (date, name) => {
    setLeaveData({...leaveData, [name]: date[0]})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(leaveData)
    setLoading(true)
    const reason = leaveData.reason || ''
    const data = {
      ...leaveData,
      reason: reason,
      leaveType: selectedLeave.value,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      const res = await postLeaveApply(data)
      if (res) {
        navigate('/hrm/leaveManagement')
      }
    } catch (error) {
      console.error('Error submitting leave application:', error)
    }
  }

  useEffect(() => {
    getEmployeeList()
    getLeaveList()
  }, [])

  return (
    <div className='container-lg'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='card card-custom card-stretch'>
            <div className='card-body'>
              <h2 className='text-center mb-4'>APPLY LEAVE</h2>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='employeeId' className='form-label'>
                    Employee
                  </label>
                  <Select
                    name='employeeId'
                    options={employeeList}
                    onChange={handleEmployeeChange}
                    value={selectedEmployee}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='jobRoleId' className='form-label'>
                    Job Role
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='jobRole'
                    name='jobRoleId'
                    value={roles}
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='departmentId' className='form-label'>
                    Department
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='departmentId'
                    name='departmentId'
                    value={departments}
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='leaveType' className='form-label'>
                    Leave Type
                  </label>
                  <Select
                    name='leaveType'
                    options={leaveList}
                    onChange={handleLeaveChange}
                    value={selectedLeave}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='month' className='form-label'>
                    Select Month
                  </label>
                  <Flatpickr
                    value={leaveData.month}
                    onChange={(date) => handleDateChange(date, 'month')}
                    className='form-control'
                    options={{dateFormat: 'd-m-Y'}}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='startDate' className='form-label'>
                    Start Date
                  </label>
                  <Flatpickr
                    value={leaveData.startDate}
                    onChange={(date) => handleDateChange(date, 'startDate')}
                    className='form-control'
                    options={{
                      dateFormat: 'd-m-Y',
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='endDate' className='form-label'>
                    End Date
                  </label>
                  <Flatpickr
                    value={leaveData.endDate}
                    onChange={(date) => handleDateChange(date, 'endDate')}
                    className='form-control'
                    options={{
                      dateFormat: 'd-m-Y',
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='reason' className='form-label'>
                    Reason
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='reason'
                    name='reason'
                    value={leaveData.reason}
                    onChange={handleChange}
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    <span className='indicator-label'>
                      Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeaveApply
