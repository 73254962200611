import {createSlice} from '@reduxjs/toolkit'

const sprintSlice = createSlice({
  name: 'sprint',
  initialState: {
    sprintData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSprintData(state, action) {
      state.sprintData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setSprintData, setLoading, setError} = sprintSlice.actions
export default sprintSlice.reducer
