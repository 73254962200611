import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getPaymentReceivedDetailById} from '../../Core/_requests'

function PaymentReceivedMultiLang({companyLogo, color}) {
  const {id} = useParams()
  const [data, setPaymentReceivedData] = useState<any>({})
  const [page, setPage] = useState(1)
  const [contactPerson, setContactPerson] = useState<any>({})
  useEffect(() => {
    const getPaymentData = async () => {
      const res = await getPaymentReceivedDetailById(id)
      setPaymentReceivedData(res)
      if (res?.items?.length > 10) {
        const pages = Math.ceil((res?.items.length - 10) / 20)
        if ((res?.items.length - 10) / 20 > 4) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.customer?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.customer?.primaryContact)
      }
    }

    getPaymentData()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const [imageSize, setImageSize] = useState<boolean>(true)
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body*/}
      <div id='pdfHeader' className='p-2 mx-3 mb-6 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <div className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </div>
            {/*end::Logo*/}
          </div>
          {/* <h4 className='fw-bold text-gray-700 fs-qx pb-7'>Company Address</h4> */}
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column pb-7 text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-wider '
              style={{
                fontFamily: 'Rubik',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span className='mb-1 text-right fs-3 tracking-wider'>{data?.company?.name}</span>
            <span className='fs-6 text-muted'>
              VAT:{data?.company?.vat} | C.R : {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      {/*end::Header*/}
      <div className='separator'></div>
      <div className='card-body py-8'>
        {/* begin::Wrapper*/}
        <div className='mw-lg-1050px w-100'>
          {/* begin::Header*/}
          {/*begin::Body*/}

          <div className='pb-2 mt-5'>
            {idx === 0 && (
              <div className='text-center mb-6 fs-2 fw-bold text-gray-800'>
                إيصال الدفع Payment Receipt
              </div>
            )}
            <div className='pb-2 mt-5'>
              {/*begin::Wrapper*/}
              <div className='d-flex flex-column gap-7 gap-md-10'>
                {idx === 0 && (
                  <div className='d-flex justify-content-between flex-column flex-sm-row'>
                    <div className=' mb-5 fw-bold'>
                      <div className='d-flex  flex-column mb-5'>
                        <span className='text-muted'>
                          إيصال
                          <br />
                          Receipt
                        </span>
                        <span className='fs-7'>{data?.id}</span>
                      </div>
                      <div className='d-flex flex-column mb-5'>
                        <span className='text-muted'>
                          المدفوعة لل
                          <br />
                          Paid To
                        </span>

                        <span className='fs-7'>{data?.customer?.companyName}</span>
                      </div>
                      <div className='d-flex flex-md-root flex-column fw-bold mb-5'>
                        <span className='text-muted mb-1'>
                          عنوان
                          <br />
                          Address
                        </span>
                        <span className='fs-7 mw-200px'>
                          {data?.customer?.billingAddress.addressLine1}{' '}
                          {data?.customer?.billingAddress.addressLine2}
                          <br />
                          {data?.customer?.billingAddress.city}{' '}
                          {data?.customer?.billingAddress.postalCode}
                          <br />
                          {data?.customer?.billingAddress.country}
                        </span>
                      </div>
                    </div>
                    <div className='  mb-5  fw-bold'>
                      <div className='d-flex  flex-column mb-5'>
                        <span className='text-muted'>
                          انتباه
                          <br />
                          Attention
                        </span>
                        <span className='fs-7'>
                          {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                          {contactPerson?.lastName}
                        </span>
                      </div>

                      <div className=' d-flex flex-column  mb-5'>
                        <span className='text-muted'>
                          رقم الفاتورة
                          <br />
                          Invoice Number
                        </span>
                        <span className='fs-7'>{data?.reference?.id}</span>
                      </div>
                      <div className=' d-flex flex-column mb-5'>
                        <span className='text-muted'>
                          طريقة الدفع
                          <br />
                          Payment Mode
                        </span>
                        <span className='fs-7'>{data?.paymentMode}</span>
                      </div>
                    </div>
                    <div className='text-end  mb-5  fw-bold'>
                      <div className=' d-flex flex-column mb-5 '>
                        <span className='text-muted'>
                          حالة
                          <br />
                          Status
                        </span>
                        <span className='fs-7'>{data?.approval}</span>
                      </div>
                      <div className=' d-flex flex-column  mb-5 '>
                        <span className='text-muted'>
                          تاريخ الدفع
                          <br />
                          Payment Date
                        </span>

                        <span className='fs-7'>{formatCreatedAt(data?.paymentDate)}</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className='flex-grow-1'>
                  {/*begin::Table*/}
                  <div className='table-responsive  mb-9'>
                    <table className='table table-bordered m-0'>
                      <thead>
                        <tr
                          className='fs-9 fw-bold '
                          style={{
                            backgroundColor: color,
                          }}
                        >
                          <th className='min-w-50px text-white text-start pb-3 '>
                            <span>
                              رقم سري
                              <br />
                              Sr. No.
                            </span>
                          </th>

                          <th className='min-w-150px text-white text-center pb-3 '>
                            غرض
                            <br />
                            Item
                          </th>
                          <th className='min-w-70px text-white text-center pb-3'>
                            كمية
                            <br />
                            QTY
                          </th>
                          <th className='min-w-80px text-white text-center pb-3'>
                            المجموع الفرعي
                            <br />
                            Subtotal
                          </th>
                        </tr>
                      </thead>
                      <tbody className='' style={{height: '250px'}}>
                        {data?.items
                          ?.slice(
                            idx === 1 || idx === 0 ? idx * 9 : idx * 20 - 11,
                            idx === 0 ? 9 : idx * 20 + 9
                          )
                          ?.map((item, index) => (
                            <tr
                              key={index}
                              className='border fw-semibold text-gray-600 fs-7 text-center'
                            >
                              <td className=' text-start'>
                                {idx === 0
                                  ? index + 1
                                  : idx === 1
                                  ? index + 10
                                  : index + idx * 20 - 10}
                              </td>
                              <td className=' text-center '>{item.productName}</td>
                              <td className=''>{item.quantity}</td>
                              <td className=''>
                                {Number(item?.amount).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                              </td>
                            </tr>
                          ))}
                        {(idx + 1 === page || page === 1) && (
                          <tr className=''>
                            <td colSpan={3} className='p-0 text-sm'>
                              <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                <tbody>
                                  <tr className='text-end '>
                                    <td className=' '>
                                      المجموع الفرعي
                                      <br />
                                      Subtotal
                                    </td>
                                  </tr>

                                  <tr className='text-end '>
                                    <td className=' '>
                                      إجمالي المبلغ المستلم
                                      <br />
                                      Total amount received
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td colSpan={2} className='p-0 text-sm'>
                              <table className='table table-bordered fs-8 w-full m-0 p-0 '>
                                <tbody>
                                  <tr className=' text-center p-2 '>
                                    <td>
                                      {}
                                      <br />
                                      {data?.totalAmount
                                        ? Number(data?.totalAmount).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })
                                        : 0}{' '}
                                      {data?.customer?.currency}
                                    </td>
                                  </tr>

                                  <tr className='text-center p-2 '>
                                    <td>
                                      {}
                                      <br />
                                      {data?.amountReceived
                                        ? data?.amountReceived.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })
                                        : 0}{' '}
                                      {data?.customer?.currency}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            {/* Empty cell for design consistency */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='pdfFooter' className='card-footer text-center p-2 fw-bold'>
        <div className='bg-secondary -skew-x-12 text-gray-800'>
          <div className='skew-x-12 py-1'>
            Mobile : {data?.company?.mobileNumber} | E-mail : {data?.company?.companyEmail} |
            Website : {data?.company?.webURL}
          </div>
        </div>
        <div
          className='-skew-x-12 text-white '
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className='skew-x-12 py-1'>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center mb-4'>
        <p className='fs-8 serif-font'>
          Page {idx + 1} of {page}
        </p>
      </div>
      {/* end::Invoice 1*/}
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl'>
          {/* begin::Invoice 2 main */}

          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default PaymentReceivedMultiLang
