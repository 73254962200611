import React from 'react'
import {Route, Routes} from 'react-router-dom'
import PartsList from '../components/PartsList'
import AddParts from '../components/AddParts'
import PartsDetail from '../components/PartsDetail'

const Parts = () => {
  return (
    <Routes>
      <Route path='/' element={<PartsList />} />
      <Route path='/:id' element={<PartsDetail />} />
      <Route path='/add' element={<AddParts />} />
    </Routes>
  )
}

export default Parts
