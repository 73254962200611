/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Company Info
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='mb-10 fv-row'>
            <label className='d-flex align-items-center form-label mb-3'>
              Specify Company Size
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Provide your team size to help us setup your billing'
              ></i>
            </label>

            <div className='row mb-2' data-kt-buttons='true'>
              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='companySize'
                  value={1}
                  id='kt_account_team_size_select_1'
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                  htmlFor='kt_account_team_size_select_1'
                >
                  <span className='fw-bolder fs-3'>1-1</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='companySize'
                  value={10}
                  id='kt_account_team_size_select_2'
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                  htmlFor='kt_account_team_size_select_2'
                >
                  <span className='fw-bolder fs-3'>2-10</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='companySize'
                  value={30}
                  id='kt_account_team_size_select_3'
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                  htmlFor='kt_account_team_size_select_3'
                >
                  <span className='fw-bolder fs-3'>10-50</span>
                </label>
              </div>

              <div className='col'>
                <Field
                  type='radio'
                  className='btn-check'
                  name='companySize'
                  value={50}
                  id='kt_account_team_size_select_4'
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
                  htmlFor='kt_account_team_size_select_4'
                >
                  <span className='fw-bolder fs-3'>50+</span>
                </label>
              </div>
            </div>

            {/* <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div> */}
          </div>

          <div className='mb-10 fv-row'>
            <label className='form-label mb-3 required'>Company Name</label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='companyName'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='companyName' />
            </div>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='companySize' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
