import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
     apiKey: "AIzaSyCdccfjG437SajxmpfOodgbPxmXjjX2j70",
     authDomain: "fir-qf.firebaseapp.com",
     projectId: "firebase-qf",
     storageBucket: "firebase-qf.appspot.com",
     messagingSenderId: "360936183195",
     appId: "1:360936183195:web:a396fadcfc85931c34bd3e"
   };

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
     try {
       const permission = await Notification.requestPermission();
   
       if (permission === 'granted') {
         const token = await getToken(messaging, {
           vapidKey: "BHSJ4i7pHj0R1YgNScGZkAgMjwrweNZ-1ujzUjFteaxSgoxN4rZ7htKCX-gn3QRn0MdxSDTs_IZ1vwZl4vH5WNw"
         });
         return token;
       } else {
         return null;
       }
     } catch (error) {
       return null;
     }
   };
   