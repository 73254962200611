import {combineReducers} from '@reduxjs/toolkit'

import projectSlice from './projectSlice'
import projectPerformanceSlice from './projectPerformanceSlice'
import projectReportSlice from './projectReportSlice'
import projectDashboardSlice from './projectDashboardSlice'

const rootReducer = combineReducers({
  projects: projectSlice,
  projectPerformance: projectPerformanceSlice,
  projectReport: projectReportSlice,
  projectDashboard: projectDashboardSlice,
})

export default rootReducer
