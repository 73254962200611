import { useState } from 'react'

export default function NewContibutors({ team, setProperty }) {
  const [tasks, setTasks] = useState([]);

  console.log("team", team)

  console.log("task for each", tasks)
  return (
    <div>
      <div class='card-header mt-6'>
        <div class='card-title flex-column'>
          <h3 class='fw-bold mb-1'>Team Members</h3>
          <div class='fs-6 text-gray-400'> Total {`${team?.length}`} Members</div>
        </div>
        <div class='card-toolbar'>
          <button href='#' class='btn btn-bg-light btn-active-color-primary btn-sm' onClick={() => setProperty("Manpower")}>
            View All
          </button>
        </div>
      </div>

      <div class='card-body d-flex flex-column p-9 pt-3 mb-9'>
        {team?.map((team, i) => (
          <div key={i} className='d-flex align-items-center mb-5 mt-3 justify-content-between'>
            <div className='me-5 position-relative d-flex align-items-center'>
              <div className='symbol symbol-35px symbol-circle'>
                <img alt='Pic' src='/media/avatars/300-6.jpg' />
              </div>
              <div className='fw-semibold ms-4 ' >
                <div className='fs-5 fw-bold text-gray-900 text-hover-primary'>
                  {team.name}
                </div>
              </div>
            </div>
            <div className='text-gray-400 '>
              <div class="h-4px w-100 bg-light mb-8" data-bs-toggle="tooltip" title="This project 50% completed">
                <div class="bg-primary rounded h-4px mt-2" role="progressbar" style={{ width: "50%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                <div class="fw-semibold text-gray-400 mt-2">Performance</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}
