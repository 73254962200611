import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {KTUtil} from '../../../../_metronic/assets/ts'
import {useThemeMode} from '../../../../_metronic/partials'

import {Card} from 'react-bootstrap'
import {useAuth} from '../../../modules/auth'
import {getProject, getProjectByAgentId} from '../core/_requests'
import Swal from 'sweetalert2'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setAllProjects} from '../../../redux/projects/projectSlice'

const ProjectGanttChart = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const projectList = useSelector((state: any) => state.projects.projects.allProjects)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [calendarEvents, setCalendarEvents] = useState<any>([
    // {
    //   x: 'Project',
    //   y: [new Date().getTime(), new Date().getTime()],
    // },
    {
      name: 'Project',
      data: [
        {
          x: 'Project',
          y: [new Date().getTime(), new Date().getTime()],
        },
      ],
    },
  ])

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, calendarEvents])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-primary')
    var baseLightColor = KTUtil.getCSSVariableValue('--bs-success')
    var secondaryColor = KTUtil.getCSSVariableValue('--bs-info')

    return {
      series: calendarEvents,
      //  [
      //   {
      //     name: 'Project',
      //     data: calendarEvents,
      //   },
      // ],
      chart: {
        type: 'rangeBar',
        fontFamily: 'inherit',
        height: height,
        toolbar: {
          show: false,
        },
      },
      colors: [baseColor, secondaryColor, baseLightColor],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',

          borderRadius: 5,
          distributed: false,
          dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: 'horizontal',
          },
        },
      },
      xaxis: {
        type: 'datetime',
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        // show: false,
        axisBorder: {
          show: false,
        },
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProjectData = async () => {
    try {
      const response = await getProject(currentUser?.organization)
      console.log('res', response)
      // const data = response?.map((item) => ({
      //   x: item?.projectName,
      //   y: [new Date(item?.startDate).getTime(), new Date(item?.releaseDate).getTime()],
      // }))
      const data = response?.map((items) => ({
        name: items?.projectName,
        data: [
          {
            x: items?.projectName,
            y: [new Date(items?.startDate).getTime(), new Date(items?.releaseDate).getTime()],
          },
        ],
      }))
      setCalendarEvents(data)
      dispatch(setAllProjects(response))
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getProjectDataForAgent = async () => {
    try {
      const response = await getProjectByAgentId(currentUser?._id)
      const data = response?.map((item) => ({
        x: item?.projectName,
        y: [new Date(item?.startDate).getTime(), new Date(item?.releaseDate).getTime()],
      }))
      setCalendarEvents(data)
      dispatch(setAllProjects(response))
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (permissions?.myproject?.admin === false) {
      if (projectList.length === 0) {
        getProjectDataForAgent()
      } else {
        const data = projectList?.map((item) => ({
          x: item?.projectName,
          y: [new Date(item?.startDate).getTime(), new Date(item?.releaseDate).getTime()],
        }))
        setCalendarEvents(data)
        setLoading(false)
      }
    } else if (
      permissions?.myproject?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      if (projectList.length === 0) {
        getProjectData()
      } else {
        const data = projectList?.map((items) => ({
          name: items?.projectName,
          data: [
            {
              x: items?.projectName,
              y: [new Date(items?.startDate).getTime(), new Date(items?.releaseDate).getTime()],
            },
          ],
        }))
        setCalendarEvents(data)
        setLoading(false)
      }
    }
  }, [permissions])

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>Project Gantt Chart</h3>
        </div>
      </div>
      <div className=''>
        <div className={`card`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            {/* begin::Title */}
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-5 mb-1'>Gantt Chart</span>
            </h3>
            {/* end::Title */}

            {/* begin::Toolbar */}

            {/* end::Toolbar */}
          </div>

          {/* end::Header */}

          {/* begin::Body */}
          <div className='card-body'>
            {/* begin::Chart */}
            <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '500px'}} />
            {/* end::Chart */}
          </div>
          {/* end::Body */}
        </div>
      </div>
    </div>
  )
}

export default ProjectGanttChart
