import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {getProjectReportSubmissionByReportId} from '../core/_requests'

const SubmissionsView = () => {
  const {id} = useParams()
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const data = await getProjectReportSubmissionByReportId(id)
        console.log('data', data)
        setData(data)
      } catch (error) {
        console.error('Error fetching submissions:', error)
      }
    }

    fetchSubmissions()
  }, [])
  console.log('data Received', data)
  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Project Report Submissions</h4>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Form Name</th>
                  <th className='min-w-125px'>Project</th>
                  <th className='min-w-125px'>User</th>
                  <th className='min-w-125px'>Date</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.submissions.map((submission, index) => (
                    <tr key={index}>
                      <td>
                      <Link to={`/project/reports/viewDetails/${submission?._id}`}>
                        {data.projectReportTitle}
                      </Link></td>
                      <td>{submission.projectName}</td>
                      <td>{submission.userFullName}</td>
                      <td>{new Date(submission.submissionDate).toLocaleDateString('en-GB')}</td>
         
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmissionsView
