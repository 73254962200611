import {combineReducers} from '@reduxjs/toolkit'
import dashboardReducer from './dashboard/dashboardSlice'
import categoryReducer from './category/categorySlice'
import goodsReducer from './products/goodsSlice'
import rentalsReducer from './products/rentalsSlice'
import consumablesReducer from './products/consumablesSlice'
import servicesReducer from './products/servicesSlice'
import inventoryAdjustmentReducer from './inventory-adjustment/inventoryAdjustmentSlice'
import stockReducer from './products/stockSlice'

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  category: categoryReducer,
  goods: goodsReducer,
  rentals: rentalsReducer,
  consumables: consumablesReducer,
  services: servicesReducer,
  inventoryAdjustment: inventoryAdjustmentReducer,
  stock: stockReducer,
})

export default rootReducer
