import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {SetInventoryAdjustmentStatus, getInventoryAdjustmentById} from '../../Core/_requests'

const AdjustmentDetail = () => {
  const [data, setData] = useState<any>([])
  const {id} = useParams()
  const navigate = useNavigate()
  const fetchData = async () => {
    const res = await getInventoryAdjustmentById(id)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAdjusted = async () => {
    const res = await SetInventoryAdjustmentStatus(id)
    console.log(res)
    if (res) {
      navigate('/inventory/adjustments')
    }
  }

  return (
    <div>
      <h1>Adjustment Detail</h1>
      {data?.status === 'draft' && (
        <button className='btn btn-success' onClick={handleAdjusted}>
          ADJUSTED
        </button>
      )}
    </div>
  )
}

export default AdjustmentDetail
