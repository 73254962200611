import React, {lazy} from 'react'
import {Route, Routes} from 'react-router-dom'
import ReportsPage from './pages/ReportsPage'
import {SuspensedView} from '../../routing/PrivateRoutes'

const ReportWrapper = () => {
  const ProfitAndLoss = lazy(() => import('./components/reports/ProfitAndLoss'))
  const BalanceSheet = lazy(() => import('./components/reports/BalanceSheet'))
  const SalesByCustomer = lazy(() => import('./components/reports/sales/SalesByCustomer'))
  const SalesBySalesPerson = lazy(() => import('./components/reports/sales/SalesBySalesPerson'))
  const SalesByItem = lazy(() => import('./components/reports/sales/SalesByItem'))
  const CustomerBalance = lazy(() => import('./components/reports/receivables/CustomerBalance'))
  const CustomerBalanceSummary = lazy(
    () => import('./components/reports/receivables/CustomerBalanceSummary')
  )
  const ReceivableSummary = lazy(() => import('./components/reports/receivables/ReceivableSummary'))
  const ReceivableDetails = lazy(() => import('./components/reports/receivables/ReceivableDetails'))
  const InvoiceDetails = lazy(() => import('./components/reports/receivables/InvoiceDetails'))
  const DeliveryChallanDetails = lazy(
    () => import('./components/reports/receivables/DeliveryChallanDetails')
  )
  const QuoteDetails = lazy(() => import('./components/reports/receivables/QuoteDetails'))
  const PaymentReceived = lazy(
    () => import('./components/reports/payment-received/PaymentReceived')
  )
  const VendorBalanceSummary = lazy(
    () => import('./components/reports/payables/VendorBalanceSummary')
  )
  const PayableSummary = lazy(() => import('./components/reports/payables/PayableSummary'))
  const PayableDetails = lazy(() => import('./components/reports/payables/PayableDetails'))
  const TimeToGetPaid = lazy(() => import('./components/reports/payment-received/TimeToGetPaid'))
  const ExpenseDetails = lazy(
    () => import('./components/reports/purchases-and-expenses/ExpenseDetails')
  )
  const ExpenseByCategory = lazy(
    () => import('./components/reports/purchases-and-expenses/ExpenseByCategory')
  )
  const ExpenseByCustomer = lazy(
    () => import('./components/reports/purchases-and-expenses/ExpenseByCustomer')
  )
  const ExpenseByVendor = lazy(
    () => import('./components/reports/purchases-and-expenses/ExpenseByVendor')
  )
  const MovementOfEquity = lazy(() => import('./components/reports/MovementOfEquity'))
  const CashFlowStatement = lazy(() => import('./components/reports/CashFlowStatement'))

  return (
    <Routes>
      <Route path='/' element={<ReportsPage />} />
      {/* Business Overview */}
      <Route
        path='/profitandloss'
        element={
          <SuspensedView>
            <ProfitAndLoss />
          </SuspensedView>
        }
      />
      <Route
        path='/balancesheet'
        element={
          <SuspensedView>
            <BalanceSheet />
          </SuspensedView>
        }
      />
      <Route
        path='/movementofequity'
        element={
          <SuspensedView>
            <MovementOfEquity />
          </SuspensedView>
        }
      />
      <Route
        path='/cfstatement'
        element={
          <SuspensedView>
            <CashFlowStatement />
          </SuspensedView>
        }
      />

      {/* Sales */}
      <Route
        path='/salesbycustomer'
        element={
          <SuspensedView>
            <SalesByCustomer />
          </SuspensedView>
        }
      />
      <Route
        path='/salesbysp'
        element={
          <SuspensedView>
            <SalesBySalesPerson />
          </SuspensedView>
        }
      />
      <Route
        path='/salesbyitem'
        element={
          <SuspensedView>
            <SalesByItem />
          </SuspensedView>
        }
      />

      {/* Receivables */}
      <Route
        path='/customerbalances'
        element={
          <SuspensedView>
            <CustomerBalance />
          </SuspensedView>
        }
      />
      <Route
        path='/customerbalancesummary'
        element={
          <SuspensedView>
            <CustomerBalanceSummary />
          </SuspensedView>
        }
      />
      <Route
        path='/recevablesummary'
        element={
          <SuspensedView>
            <ReceivableSummary />
          </SuspensedView>
        }
      />
      <Route
        path='/receivabledetails'
        element={
          <SuspensedView>
            <ReceivableDetails />
          </SuspensedView>
        }
      />
      <Route
        path='/invoicedetails'
        element={
          <SuspensedView>
            <InvoiceDetails />
          </SuspensedView>
        }
      />
      <Route
        path='/dcdetails'
        element={
          <SuspensedView>
            <DeliveryChallanDetails />
          </SuspensedView>
        }
      />
      <Route
        path='/quotesdetails'
        element={
          <SuspensedView>
            <QuoteDetails />
          </SuspensedView>
        }
      />

      {/* Payment Receiveds */}
      <Route
        path='/paymentsreceived'
        element={
          <SuspensedView>
            <PaymentReceived />
          </SuspensedView>
        }
      />
      <Route
        path='/timetopay'
        element={
          <SuspensedView>
            <TimeToGetPaid />
          </SuspensedView>
        }
      />

      {/* Payment Receiveds */}
      <Route
        path='/vendorbalancesummary'
        element={
          <SuspensedView>
            <VendorBalanceSummary />
          </SuspensedView>
        }
      />
      <Route
        path='/payablesummary'
        element={
          <SuspensedView>
            <PayableSummary />
          </SuspensedView>
        }
      />
      <Route
        path='/payabledetails'
        element={
          <SuspensedView>
            <PayableDetails />
          </SuspensedView>
        }
      />

      {/* Purchases and Expenses */}
      <Route
        path='/expensedetails'
        element={
          <SuspensedView>
            <ExpenseDetails />
          </SuspensedView>
        }
      />
      <Route
        path='/expbycategory'
        element={
          <SuspensedView>
            <ExpenseByCategory />
          </SuspensedView>
        }
      />
      <Route
        path='/expbycustomer'
        element={
          <SuspensedView>
            <ExpenseByCustomer />
          </SuspensedView>
        }
      />
      <Route
        path='/expbyvendor'
        element={
          <SuspensedView>
            <ExpenseByVendor />
          </SuspensedView>
        }
      />
    </Routes>
  )
}

export default ReportWrapper
