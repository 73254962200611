import {createSlice} from '@reduxjs/toolkit'

const intialState = {
  projectReport: [],
  loading: true,
}

const projectReportSlice = createSlice({
  name: 'projectReport',
  initialState: intialState,
  reducers: {
    setProjectReport: (state, action) => {
      state.projectReport = action.payload
      state.loading = false
    },
  },
})

export const {setProjectReport} = projectReportSlice.actions
export default projectReportSlice.reducer
