import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getInspectionFormById, postInspectionRecord} from '../../core/_requests'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {useAuth} from '../../../../modules/auth'
import {productImage} from '../../../Inventory/Core/_requests'

const CreateInspection = () => {
  const {formId} = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [form, setForm] = useState([])
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [productType, setProductType] = useState([])
  const [isVehicle, setIsVehicle] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [odoMeter, setOdoMeter] = useState('')
  const [prevOdoMeter, setPrevOdoMeter] = useState({
    value: 0,
    date: new Date(),
  })
  const [response, setResponse] = useState([
    {
      question: '',
      questionType: '',
      answer: [''],
      comment: '',
      photo: '',
    },
  ])

  const fetchFormData = async () => {
    const res = await getInspectionFormById(formId)
    console.log(res)
    const data = res.products?.map((product) => {
      return {
        label: product.productName,
        value: product._id,
      }
    })
    setProducts(data)
    const data1 = res.products?.map((product) => {
      return {
        label: product._id,
        rentalType: product?.rentalType,
        odometerReading: product?.odometerReading,
        odometerReadingDate: product?.odometerReadingDate,
      }
    })
    setProductType(data1)
    setForm(res.inspectionForm?.questions)
    const resp = res.inspectionForm?.questions.map((question) => {
      return {
        question: question.questionText,
        questionType: question.questionType,
        answer: [''],
        comment: '',
        photo: '',
      }
    })
    setResponse(resp)
  }

  useEffect(() => {
    fetchFormData()
  }, [formId])

  useEffect(() => {
    const selected = productType.find((product) => product.label === selectedProduct)
    if (selected) {
      console.log(selected.rentalType)
      const isvehicle = selected.rentalType === 'vehicle'
      console.log(isvehicle)
      setIsVehicle(isvehicle)
      setPrevOdoMeter({
        value: selected?.odometerReading || 0,
        date: new Date(selected?.odometerReadingDate),
      })
    }
    setOdoMeter('')
    setResponse(
      form?.map((question) => {
        return {
          question: question.questionText,
          questionType: question.questionType,
          answer: [''],
          comment: '',
          photo: '',
        }
      })
    )
  }, [selectedProduct])

  //   question type can be [
  //     "text",
  //     "date",
  //     "number",
  //     "dropdown",
  //     "meter",
  //     "yesno",
  //     "photo",
  //     "signature",
  //     "checkbox",
  //     "multiplechoice",
  //   ]

  const formatDaysAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
      return `${interval} years ago`
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
      return `${interval} months ago`
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
      return `${interval} days ago`
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
      return `${interval} hours ago`
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
      return `${interval} minutes ago`
    }
    return `${Math.floor(seconds) || 0} seconds ago`
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('clicked')
    console.log(response)
    for (let i = 0; i < response.length; i++) {
      if (response[i].answer[0] === '' && form[i].isRequired) {
        alert('Please fill all the required fields')
        return
      }
      if (response[i].comment === '' && form[i].isCommentRequired) {
        alert('Please fill all the required comment fields')
        return
      }
      // if (response[i].photo === '' && form[i].isPhotoRequired) {
      //   alert('Please fill all the required image fields')
      //   return
      // }
    }
    setLoading(true)
    var lat, lng
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        lat = position.coords.latitude
        lng = position.coords.longitude
      })
      const data = {
        form: formId,
        answers: response,
        product: selectedProduct,
        odometer: odoMeter,
        lat: lat,
        lng: lng,
        createdBy: currentUser._id,
        company: currentUser.company,
        organization: currentUser.organization,
      }

      console.log(data)

      const res = await postInspectionRecord(data)
      if (res) {
        navigate('/inspection/history')
      }
    }
  }

  const handlePhotoUpload = async (e, index) => {
    const file = e.target.files[0]
    if (!file) {
      setResponse((prev) => {
        prev[index].photo = ''
        return prev
      })
      return
    }
    setLoading(true)
    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setResponse((prev) => {
      prev[index].photo = `${timestamp}${file.name}`
      return prev
    })

    const res = await productImage(formData1)
    console.log(res)
    if (res) {
      setLoading(false)
    }
  }

  const handlePhotoUploadInAnswer = async (e, index) => {
    const file = e.target.files[0]
    if (!file) {
      setResponse((prev) => {
        prev[index].answer = ['']
        return prev
      })
      return
    }
    setLoading(true)
    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setResponse((prev) => {
      prev[index].answer = [`${timestamp}${file.name}`]
      return prev
    })

    const res = await productImage(formData1)
    console.log(res)
    if (res) {
      setLoading(false)
    }
  }

  return (
    <div className='container'>
      <div className='card mb-5'>
        <div className='card-header'>
          <h3 className='card-title'>Inspection Details</h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='required'> Select the vehicle/equipment</label>
                <Select
                  options={products}
                  isSearchable={true}
                  placeholder='Select the vehicle/equipment'
                  //   value={selectedProduct}
                  onChange={(e: any) => setSelectedProduct(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedProduct && form ? (
        <div>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>Inspection Form</h3>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th className='w-250px'></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isVehicle && (
                      // input field for odometer reading and required
                      <tr>
                        <td>
                          <h6 className='required'>Odometer Reading</h6>
                        </td>
                        <td>
                          <div className='d-flex flex-column gap-1'>
                            <input
                              type='number'
                              className='form-control'
                              placeholder='Meter reading in mi'
                              required
                              value={odoMeter}
                              onChange={(e) => setOdoMeter(e.target.value)}
                            />
                            <div className=''>
                              <span className='text-muted'>
                                Last updated: {prevOdoMeter?.value} mi (
                                {formatDaysAgo(prevOdoMeter?.date)})
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                    {form.map((question, index) => (
                      <tr key={question._id}>
                        <td>
                          {/* question text and short description below if any */}
                          {/* {question.questionText} */}
                          <div className='d-flex flex-column gap-1'>
                            <h6 className={`${question.isRequired ? 'required' : ''}`}>
                              {question.questionText}
                            </h6>
                            {question.shortDescription && <p>{question.shortDescription}</p>}
                          </div>
                        </td>
                        <td>
                          {question.questionType === 'text' && (
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter your response'
                              value={response[index].answer[0]}
                              onChange={(e) => {
                                const temp = [...response]
                                temp[index].answer[0] = e.target.value
                                setResponse(temp)
                              }}
                              required={question.isRequired}
                            />
                          )}
                          {question.questionType === 'date' && (
                            <Datetime
                              inputProps={{className: 'form-control'}}
                              dateFormat='DD-MM-YYYY'
                              value={new Date(response[index].answer[0])}
                              onChange={(e: any) => {
                                const temp = [...response]
                                temp[index].answer[0] = String(e.toDate())
                                setResponse(temp)
                              }}
                            />
                          )}
                          {question.questionType === 'number' && (
                            <input
                              type='number'
                              className='form-control'
                              value={response[index].answer[0]}
                              min={question.min}
                              max={question.max}
                              onChange={(e) => {
                                const temp = [...response]
                                temp[index].answer[0] = e.target.value
                                setResponse(temp)
                              }}
                              required={question.isRequired}
                            />
                          )}
                          {question.questionType === 'dropdown' && (
                            <select
                              className='form-control'
                              value={response[index].answer[0]}
                              onChange={(e) => {
                                const temp = [...response]
                                temp[index].answer[0] = e.target.value
                                setResponse(temp)
                              }}
                              required={question.isRequired}
                            >
                              {question.options.map((option, i) => (
                                <option key={i} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}
                          {question.questionType === 'meter' && (
                            // input number field
                            <input
                              type='number'
                              className='form-control'
                              value={response[index].answer[0]}
                              placeholder='Meter reading in mi'
                              onChange={(e) => {
                                const temp = [...response]
                                temp[index].answer[0] = e.target.value
                                setResponse(temp)
                              }}
                              required={question.isRequired}
                            />
                          )}
                          {question.questionType === 'yesno' && (
                            //   radio button as pass or fail
                            <div className='d-flex gap-2'>
                              <div className='form-check'>
                                <input
                                  type='radio'
                                  className='form-check-input'
                                  value='Pass'
                                  name={question._id}
                                  onChange={(e) => {
                                    const temp = [...response]
                                    temp[index].answer[0] = e.target.value
                                    setResponse(temp)
                                  }}
                                />
                                <label className='form-check-label'>Pass</label>
                              </div>
                              <div className='form-check'>
                                <input
                                  type='radio'
                                  className='form-check-input'
                                  value='Fail'
                                  name={question._id}
                                  onChange={(e) => {
                                    const temp = [...response]
                                    temp[index].answer[0] = e.target.value
                                    setResponse(temp)
                                  }}
                                />
                                <label className='form-check-label'>Fail</label>
                              </div>
                            </div>
                          )}
                          {question.questionType === 'photo' && (
                            <input
                              type='file'
                              accept='image/*'
                              className='form-control'
                              onChange={(e) => {
                                handlePhotoUploadInAnswer(e, index)
                              }}
                            />
                          )}
                          {question.questionType === 'signature' && (
                            //   input filed with cursive font
                            <input
                              type='text'
                              className='form-control fw-bold fs-2'
                              value={response[index].answer[0]}
                              onChange={(e) => {
                                const temp = [...response]
                                temp[index].answer[0] = e.target.value
                                setResponse(temp)
                              }}
                              placeholder='Enter your signature'
                              style={{fontFamily: 'cursive'}}
                              required={question.isRequired}
                            />
                          )}
                          {question.questionType === 'checkbox' && (
                            <div className='d-flex flex-column gap-2'>
                              {question.options.map((option, i) => (
                                <div key={i} className='form-check'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    value={option}
                                    name={question._id}
                                    onChange={(e) => {
                                      const temp = [...response]
                                      if (e.target.checked) {
                                        temp[index].answer.push(e.target.value)
                                      } else {
                                        const idx = temp[index].answer.indexOf(e.target.value)
                                        if (idx > -1) {
                                          temp[index].answer.splice(idx, 1)
                                        }
                                      }
                                      setResponse(temp)
                                    }}
                                    required={question.isRequired}
                                  />
                                  <label className='form-check-label'>{option}</label>
                                </div>
                              ))}
                            </div>
                          )}
                          {question.questionType === 'multiplechoice' && (
                            <div className='d-flex flex-column gap-2'>
                              {question.options.map((option, i) => (
                                <div key={i} className='form-check'>
                                  <input
                                    type='radio'
                                    className='form-check-input'
                                    value={option}
                                    name={question._id}
                                    onChange={(e) => {
                                      const temp = [...response]
                                      temp[index].answer[0] = e.target.value
                                      setResponse(temp)
                                    }}
                                  />
                                  <label className='form-check-label'>{option}</label>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* optional input for comments and image upload*/}
                          <textarea
                            className='form-control mt-2'
                            placeholder={`Enter your comment ${
                              question.isCommentRequired ? '(required)' : ''
                            }`}
                            value={response[index].comment}
                            required={question.isCommentRequired}
                            onChange={(e) => {
                              const temp = [...response]
                              temp[index].comment = e.target.value
                              setResponse(temp)
                            }}
                          />
                          <input
                            type='file'
                            className='form-control mt-2'
                            accept='image/*'
                            required={question.isPhotoRequired}
                            onChange={(e) => {
                              handlePhotoUpload(e, index)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='d-flex justify-center my-5'>
            <button
              type='button'
              className='btn btn-primary'
              disabled={loading}
              onClick={handleSubmit}
            >
              Save Inspection
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CreateInspection
