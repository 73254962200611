/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  getUserByToken,
  login,
  getRoles,
  getMainModule,
  getPermissionsOfHierarchy1,
} from '../core/_requests'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import axios, {AxiosResponse} from 'axios'
import {generateToken} from '../../../firebase'
import logo from './logo.png'
import {Buffer} from 'buffer'
import {AuthModel} from '../core/_models'
import {current} from '@reduxjs/toolkit'
const API_URL = process.env.REACT_APP_THEME_API_URL

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  otp: '',
  // email: 'admin@qf.com',
  // password: 'Evotech@123',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const fcmTokenRef = useRef(null)
  const [twoFactor, setTwoFactor] = useState(false)
  const [authData, setAuthData] = useState<AuthModel>()
  const [error, setError] = useState('')
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', ''])
  const inputRefs = useRef([])

  const handleChange = (index, event) => {
    setError('')
    const value = event.target.value
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      const newVerificationCode = [...verificationCode]
      newVerificationCode[index] = ''
      setVerificationCode(newVerificationCode)
      if (index > 0) {
        inputRefs.current[index - 1].focus()
      }
      return
    }
    if (!isNaN(value) && value !== '') {
      const newVerificationCode = [...verificationCode]
      newVerificationCode[index] = value
      setVerificationCode(newVerificationCode)
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handleClick = (index) => {
    inputRefs.current[index].focus()
  }
  const handleKeyDown = (index, event) => {
    if (event.key === 'ArrowLeft') {
      // Move focus to the previous input field if the current field is not the first one
      if (index > 0) {
        inputRefs.current[index - 1].focus()
      }
    } else if (event.key === 'ArrowRight') {
      // Move focus to the next input field if the current field is not the last one
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  useEffect(() => {
    const generateFcmToken = async () => {
      try {
        const token = await generateToken()
        fcmTokenRef.current = token
      } catch (error) {
        console.error('Error generating FCM token:', error.message)
      }
    }

    generateFcmToken()
  }, [])

  const handleOTP = async (e) => {
    e.preventDefault()
    if (verificationCode.join('') == authData.otp) {
      saveAuth(authData)
      const {data: user} = await getUserByToken(authData.api_token)
      setCurrentUser(user)

      if (user.profileType !== 'superadmin') {
        if (user.hierarchy === 1) {
          const data = await getPermissionsOfHierarchy1(
            user?.company,
            user?.profileType?.toLowerCase()
          )
          user.permissions = data
        } else {
          const data = await getRoles(user?.department, user?.profileType?.toLowerCase())
          user.permissions = data
          const mainmodule = await getMainModule(user?.department)
          user.mainModule = mainmodule
        }
      } else {
        const res = await axios.get(
          `${API_URL}/user-management/organization/company/${user?.company}`
        )
        user.organization = res.data[0]?._id
      }
      setCurrentUser(user)

      // Reloading because permissions are not getting updated in sidebar
      // window.location.reload()
    } else {
      // Set error message
      setError('Incorrect OTP')
      // return
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let token = null
        token = await generateToken()
        const {data: auth} = await login(values.email, values.password, token)
        if (auth.twoFactor === true) {
          setAuthData(auth)
          setTwoFactor(true)
          setLoading(false)
          return
        }
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)

        if (user.profileType !== 'superadmin') {
          const data = await getRoles(user?.department, user?.profileType?.toLowerCase())
          user.permissions = data
        } else {
          const res = await axios.get(
            `${API_URL}/user-management/organization/company/${user?.company}`
          )
          user.organization = res.data[0]?._id
        }
        setCurrentUser(user)

        // Reloading because permissions are not getting updated in sidebar
        // window.location.reload()
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  function maskEmail(email) {
    const [localPart, domainPart] = email.split('@')
    const firstChar = localPart.charAt(0)
    const maskedLocalPart = firstChar + '*'.repeat(localPart.length - 2) + localPart.slice(-1)
    return maskedLocalPart + '@' + domainPart
  }

  return !twoFactor ? (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'> QuantumFlow ERP Suite</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Login using email and password provided by your company</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        {/* <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        /> */}
        <div className='position-relative d-flex align-items-center justify-content-end'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            placeholder='Password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            style={{height: '100%'}}
          />
          <span
            className='input-group-text btn btn-sm btn-outline h-100 py-3'
            onClick={togglePasswordVisibility}
            style={{height: '100%'}}
          >
            {showPassword ? (
              <KTIcon iconName='eye-slash' iconType='solid' className='fs-3 text-muted' />
            ) : (
              <KTIcon iconName='eye' iconType='solid' className='fs-3 text-muted' />
            )}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />


        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>

      </div> */}
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  ) : (
    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
      <div className='w-lg-500px p-10'>
        <form
          className='form w-100 mb-13'
          onSubmit={handleOTP}
          noValidate
          id='kt_sing_in_two_factor_form'
        >
          <div className='text-center mb-10'>
            <img
              alt='Logo'
              className='mh-125px d-block mx-auto'
              src={require('../../../../../src/_metronic/assets/mobile.png')}
            />
          </div>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-gray-900 mb-3'>Two-Factor Verification</h1>
            {/* end::Title */}

            {/* begin::Sub-title */}
            <div className='text-muted fw-semibold fs-5 mb-5'>
              Enter the verification code we sent to
            </div>
            {/* end::Sub-title */}

            {/* begin::Mobile no */}
            <div className='fw-bold text-gray-900 fs-3'>{maskEmail(authData?.email)}</div>
            {/* end::Mobile no */}
          </div>
          <div className='mb-10'>
            {/* begin::Label */}
            <div className='fw-bold text-start text-gray-900 fs-6 mb-1 ms-1'>
              Type your 6 digit security code
            </div>
            {/* end::Label */}

            {/* begin::Input group */}
            <div className='d-flex flex-wrap flex-stack'>
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  ref={(input) => (inputRefs.current[index] = input)}
                  type='text'
                  name={`code_${index + 1}`}
                  maxLength={1}
                  className='form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2'
                  value={digit}
                  onChange={(event) => handleChange(index, event)}
                  onClick={() => handleClick(index)}
                  onKeyDown={(event) => handleKeyDown(index, event)}
                />
              ))}
            </div>
            {/* end::Input group */}
            {error && (
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>
                  {error}
                </span>
              </div>
            )}
            <div className='d-flex flex-center'>
              <button
                type='submit'
                id='kt_sing_in_two_factor_submit'
                className='btn btn-lg btn-primary fw-bold'
              >
                <span className='indicator-label'>Submit</span>
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
