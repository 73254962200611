import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  getFilteredPurchaseQuotation,
  getFilteredPurchaseQuotationByAgentId,
  invalidatePurchaseOrder,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import Filter2 from '../Components/Filter2'
import {Filtertype2, ValidFilter} from '../Core/_model'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'
import {setPurchaseOrderDataList} from '../../../redux/procurement/purchaseOrder/purchaseOrderSlice'
import {RootState} from '../../../redux/store'
import {RiArrowDropDownLine} from 'react-icons/ri'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'

const PurchaseOrdersList = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredpurchaseOrderList, setFilteredPurchaseOrderList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const purchaseOrderList = useSelector(
    (state: RootState) => state.procurement.purchaseOrder.purchaseOrderData
  )
  const totalPagesStore = useSelector(
    (state: RootState) => state.procurement.purchaseOrder.totalPages
  )
  const reFetch = useSelector((state: RootState) => state.procurement.purchaseOrder.fetch)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const getList = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    try {
      // const data = await getPurchaseQuotation(currentUser?.organization)
      const data = await getFilteredPurchaseQuotation(
        queryString,
        currentUser?.organization,
        currentPage,
        25
      )
      setFilteredPurchaseOrderList(data?.purchaseOrders)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.purchaseOrders?.length)?.fill(false))
      return data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  // useEffect(() => {
  //   const filteredData = quotationList.filter(
  //     (item) =>
  //       item.vendor?.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.reference?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.associatedPurchaseReceive?.id?.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  //   setFilteredPurchaseOrderList(filteredData)
  //   setDropdown(Array(filteredData.length)?.fill(false))
  // }, [searchQuery, quotationList])

  // useEffect(() => {
  //   getList()
  // }, [])

  const handleActivation = async (id) => {
    const res = await invalidatePurchaseOrder(id)

    if (res) {
      fetchPurchaseOrdersData()
    }
  }

  const getBillsByAgent = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    try {
      const res = await getFilteredPurchaseQuotationByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25
      )
      setFilteredPurchaseOrderList(res?.purchaseOrders)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.purchaseOrders?.length)?.fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchPurchaseOrders = () => {
    if (permissions?.purchaseorder?.admin === false) {
      getBillsByAgent()
    } else if (
      permissions?.purchaseorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList()
    }
  }

  const fetchPurchaseOrdersData = () => {
    if (permissions?.purchaseorder?.admin === false) {
      getBillsByAgent().then((res) => dispatch(setPurchaseOrderDataList(res)))
    } else if (
      permissions?.purchaseorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList().then((res) => dispatch(setPurchaseOrderDataList(res)))
    }
  }

  useEffect(() => {
    if (purchaseOrderList.length === 0 || reFetch) {
      fetchPurchaseOrdersData()
    } else {
      setFilteredPurchaseOrderList(purchaseOrderList)
      setDropdown(Array(purchaseOrderList.length)?.fill(false))
      setTotalPages(totalPagesStore)
    }
  }, [permissions])

  const debounceSearch = debounce(fetchPurchaseOrders, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage])

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
      status: '',
    },
  }
  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    setFilteredPurchaseOrderList(purchaseOrderList)
    setCurrentPage(1)
    setDropdown(Array(purchaseOrderList.length)?.fill(false))
    setTotalPages(totalPagesStore)
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  const setStatusFilter = (status: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status, // Update status filter value
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Purchase Orders</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Purchase Order ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>

          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchPurchaseOrders}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'pending', label: 'Pending'},
                {value: 'received', label: 'Received'},
              ]}
              setApprovalFilter={setApprovalFilter}
              setStatusFilter={setStatusFilter}
            />
          </div>
        </div>

        {(permissions?.purchaseorder?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link
              to={'/procurement/purchaseOrdersList/add'}
              className='btn btn-sm btn-light-primary'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Record Purchase Order
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Purchase Order</th>
                  <th className='min-w-150px'>Reference</th>
                  <th className='min-w-100px'>Vendor Name</th>
                  <th className='min-w-100px'>Status</th>
                  {/* <th className='min-w-150px'>Received</th>
                  <th className='min-w-150px'>Billed</th> */}
                  <th className='min-w-150px'>Amount</th>
                  <th className='min-w-150px'>Expected Delivery Date</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.purchaseorder?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredpurchaseOrderList?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.date)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.reference}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>{item?.status}</span>
                      </Link>
                    </td>
                    {/* <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.associatedPurchaseReceive ? (
                          <span className={`badge fs-6 fw-semibold`}>
                            {item.associatedPurchaseReceive.id}
                          </span>
                        ) : (
                          <span className={`badge fs-6 fw-semibold`}>-</span>
                        )}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.associatedBill ? (
                          <span className={`badge fs-6 fw-semibold`}>{item.associatedBill.id}</span>
                        ) : (
                          <span className={`badge fs-6 fw-semibold`}>-</span>
                        )}
                      </Link>
                    </td> */}
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {/* {item?.items[0]?.amount
                            ? item?.items[0]?.amount?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : 0}{' '} */}
                          {item?.total?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          {item?.vendor?.currency}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {formatCreatedAt(item?.expectedDeliveryDate)}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.purchaseorder?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {item?.status === 'pending' && item?.valid && (
                              <>
                                <Link to={`edit/${item._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Edit
                                  </button>
                                </Link>
                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id)}
                                >
                                  Invalid
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default PurchaseOrdersList
