import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getCustomerDetails} from '../Core/_requests'
import Comments from './Comments'
import Mails from '../Components/Mails'
import Statements from '../Components/Statements'
import {KTIcon} from '../../../../_metronic/helpers'
import profile from '../../../../_metronic/assets/profile.png'
import EditContact from './EditContact'
import AddContactPerson from './AddContactPerson'
import Transaction from '../Components/Transaction'
import CustomerAddress from '../Components/CustomerAddress'
export default function ViewCustomer() {
  const {customerId} = useParams()
  const [customerData, setCustomerData] = useState([])
  const [property, setProperty] = useState('Overview')
  const [showRemark, setShowRemark] = useState(true)
  const [showAddress, setShowAddress] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [contactPerson, setContactPerson] = useState(false)
  const [recordInfo, setRecordInfo] = useState(false)
  const [invitePortal, setInvitePortal] = useState(false)

  const [formValues, setFormValues] = useState({
    primaryContact: {
      salutation: 'Mr',
      firstName: '',
      lastName: '',
    },
    emailAddress: '',
    contactNumbers: {
      workPhone: '',
      mobilePhone: '',
    },
    designation: '',
    department: '',
  })

  const [billingAddress, setBillingAddress] = useState({
    _id: '',
    attention: '',
    country: '',
    region: '',
    addressLine1: '',
    addressLine2: '',
    city: ' ',
    state: ' ',
    postalCode: ' ',
    phone: ' ',
    faxNumber: '',
  })

  const [shippingAddress, setShippingAddress] = useState({
    attention: '',
    country: '',
    region: '',
    addressLine1: '',
    addressLine2: '',
    city: ' ',
    state: ' ',
    postalCode: ' ',
    phone: ' ',
    faxNumber: '',
  })

  const handleInvitePortal = (e) => {
    e.preventDefault()
    setInvitePortal(true)
  }

  const handleshowRemark = (e) => {
    e.preventDefault()
    setShowRemark(!showRemark)
  }

  const handleshowAddress = (e) => {
    e.preventDefault()
    setShowAddress(!showAddress)
  }

  const handleOtherDetails = (e) => {
    e.preventDefault()
    setShowDetails(!showDetails)
  }

  const handleContactPerson = (e) => {
    e.preventDefault()
    setContactPerson(!contactPerson)
  }

  const handleRecordInfo = (e) => {
    e.preventDefault()
    setRecordInfo(!recordInfo)
  }

  const handleReset = async () => {
    getCustomer()
  }

  const getCustomer = async () => {
    const res = await getCustomerDetails(customerId)
    console.log(res)
    setCustomerData(res)

    const initialValues = {
      primaryContact: {
        salutation: res?.primaryContact?.salutation,
        firstName: res?.primaryContact?.firstName,
        lastName: res?.primaryContact?.lastName,
      },
      emailAddress: res?.emailAddress,
      contactNumbers: {
        workPhone: res?.contactNumbers?.workPhone,
        mobilePhone: res?.contactNumbers?.mobilePhone,
      },
      designation: res?.designation,
      department: res?.department,
    }
    setFormValues(initialValues)
    setBillingAddress({
      _id: res?.billingAddress?._id || '',
      ...res.billingAddress,
    })
    setShippingAddress(res.shippingAddress)
    console.log('formValues:', formValues)
    console.log('billingAddress', billingAddress)
    console.log('shippingAddress', shippingAddress)
  }

  useEffect(() => {
    getCustomer()
  }, [])
  console.log('customerData', customerData)

  return (
    <div>
      <form id='kt_ecommerce_add_product_form' className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 m-4 my-12 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush '>
            <div className='card-header'>
              <div className='card-title'>
                <h4 className=' '> {customerData?.companyName}</h4>
              </div>
            </div>

            <div className='card-body text-center  pt-0 '>
              <div className='d-flex justify-content-start  text-start'>
                {/* {customerData?.profilePic === '' ? ( */}
                <div>
                  <img src={`${profile}`} alt='Metornic' className='w-20 h-20' />
                </div>

                {/* ) : ( */}
                {/* <img */}
                {/* src={`${PF + data?.profilePic}`} */}
                {/* alt='Metornic'
                     className='object-fit-cover'
                   />
                 )} */}
                {/* <img src={`${profile}`} alt='pic' className='object-fit-cover w-20 h-20' /> */}
                <div className='ms-3'>
                  <span className='text-indigo-950 fw-bold text-hover-primary '>
                    {customerData?.primaryContact?.salutation}
                    {' ' + customerData?.primaryContact?.firstName}
                    {' ' + customerData?.primaryContact?.lastName}
                  </span>
                  <span className='text-gray-700  fs-6 mb-0'>
                    <p className=' mb-0'>{customerData?.emailAddress}</p>
                  </span>
                  <span className='text-gray-700 fs-6 mt-0'>
                    {customerData?.contactNumbers?.workPhone}
                  </span>
                  <div className='fs-8 text-muted'>
                    <a href='#' data-bs-toggle='modal' data-bs-target='#example'>
                      Edit
                    </a>
                    |
                    <a
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#exampleModal'
                      onClick={handleInvitePortal}
                    >
                      {invitePortal ? 'Re-Invite  ' : ' Invite to Portal'}
                    </a>
                    |<a href='#'> Delete </a>
                  </div>

                  <div
                    class='modal fade'
                    id='example'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabe'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog modal-lg'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <h1 class='modal-title fs-5' id='exampleModalLabe'>
                            Edit Contact Person
                          </h1>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body'>
                          <EditContact
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleReset={handleReset}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class='modal fade '
                    id='exampleModal'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body text-center '>Your Invitation has been Sent.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleshowRemark}
                  className='w-full d-flex justify-between card-title'
                >
                  REMARKS
                  {showRemark ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>
                <hr className='text-muted' />

                {showRemark && <p>Good customer,Late fee</p>}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleshowAddress}
                  className='w-full d-flex justify-between card-title'
                >
                  ADDRESS
                  {showAddress ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {showAddress && (
                  <CustomerAddress
                    customerData={customerData}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    handleReset={handleReset}
                    shippingAddress={shippingAddress}
                    setShippingAddress={setShippingAddress}
                  />
                )}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleOtherDetails}
                  className='w-full d-flex justify-between card-title'
                >
                  OTHER DETAILS
                  {showDetails ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {showDetails && (
                  <div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Customer Type</div>
                      <p className='m-0'>{customerData?.customerType}</p>
                    </div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Default Currency</div>
                      <p className='m-0'>{customerData?.currency}</p>
                    </div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Payment Terms</div>
                      <p className='m-0'>{customerData?.paymentTerms}</p>
                    </div>

                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Portal Language</div>
                      <p className='m-0'>{customerData?.portalLanguage}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleContactPerson}
                  className='w-full d-flex justify-between card-title'
                >
                  <div>
                    <p>CONTACT PERSONS</p>
                  </div>
                  <div>
                    <button data-bs-toggle='modal' data-bs-target='#exampleModal4'>
                      <KTIcon iconName='plus-circle' className='text-primary' />
                    </button>
                    ({customerData?.contactPersons?.length})
                  </div>
                  <div>
                    {contactPerson ? (
                      <KTIcon iconName='arrow-down' />
                    ) : (
                      <KTIcon iconName='arrow-up' />
                    )}
                  </div>

                  <div
                    class='modal fade'
                    id='exampleModal4'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabel4'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog modal-lg'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <h1 class='modal-title fs-5' id='exampleModalLabel4'>
                            Edit Contact Person
                          </h1>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body'>
                          <AddContactPerson
                            setCustomerData={setCustomerData}
                            customerData={customerData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </button>

                <hr className='text-muted' />

                {contactPerson && (
                  <div>
                    {customerData?.contactPersons.map((contactPerson, index) => (
                      <div className='d-flex justify-content-start text-start' key={index}>
                        <div>
                          <img src={`${profile}`} alt='Metornic' className='w-20 h-20' />
                        </div>
                        <div className='ms-3'>
                          <span className='text-indigo-950 fw-bold text-hover-primary '>
                            {contactPerson?.salutation}
                            {' ' + contactPerson?.firstName}
                            {' ' + contactPerson?.lastName}
                          </span>
                          <p className=' mb-0'>{contactPerson?.emailAddress}</p>
                          <p className=' '>{contactPerson?.workPhone}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleRecordInfo}
                  className='w-full d-flex justify-between card-title'
                >
                  RECORD INFO
                  {recordInfo ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {recordInfo && (
                  <div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4 '> Customer ID</div>
                      <p className='m-0 '>{customerData?._id}</p>
                    </div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4'>Created on</div>
                      <p className='m-0'>{customerData?.createdAt}</p>
                    </div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4'>Created By</div>
                      <p className='m-0'>{customerData?.displayName}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Overview' ? ' active' : ''}`}
                  onClick={() => setProperty('Overview')}
                >
                  Overview
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Comments' ? ' active' : ''}`}
                  onClick={() => setProperty('Comments')}
                >
                  Comments
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Transaction' ? ' active' : ''}`}
                  onClick={() => setProperty('Transaction')}
                >
                  Transaction
                </div>
              </li>

              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Mails' ? ' active' : ''}`}
                  onClick={() => setProperty('Mails')}
                >
                  Mails
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Statements' ? ' active' : ''}`}
                  onClick={() => setProperty('Statements')}
                >
                  Statements
                </div>
              </li>
            </ul>
          </div>
          {property === 'Overview' && (
            <div className='m-2 '>
              <div></div>
              <div className='pt-6'></div>

              <div className='card card-flush mb-7'>
                <div className='card-header pt-7'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800'> Basic Deatils</span>
                  </h3>
                </div>

                <div className='card-body pt-6'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                      <thead>
                        <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                          <th className=' text-start'>Full Name</th>
                          <th className=' '>Email Id</th>
                          <th className=' '>Company Name </th>
                          <th className=' '>Phone Number </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-indigo-950 fw-bold text-hover-primary '>
                                  {customerData?.primaryContact?.salutation}
                                  {' ' + customerData?.primaryContact?.firstName}
                                  {' ' + customerData?.primaryContact?.lastName}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className=' '>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              <p>{customerData?.emailAddress}</p>
                            </span>
                          </td>

                          <td className=''>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              <p>{customerData?.companyName}</p>
                            </span>
                          </td>
                          <td className=''>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {customerData?.contactNumbers?.workPhone}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='card card-flush mb-7'>
                <div className='card-header pt-7'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800'>Other Details</span>
                  </h3>
                </div>

                <div className='card-body pt-6'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                      <thead>
                        <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                          <th className=' text-start'>Currency</th>
                          <th className=' '>Opening Balance</th>
                          <th className=' '>Payment Terms</th>
                          <th className=' '>Portal Language</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-indigo-950 fw-bold text-hover-primary '>
                                  {customerData?.currency}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className=' '>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {customerData?.openingBalance}
                            </span>
                          </td>
                          <td className=' '>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {customerData?.paymentTerms}
                            </span>
                          </td>
                          <td className=''>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {customerData?.portalLanguage}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {property === 'Comments' && (
            <Comments className='card-xl-stretch mb-xl-8' commentList={customerData?.comments} />
          )}

          {property === 'Transaction' && <Transaction className='card-xl-stretch mb-xl-8' />}
          {property === 'Mails' && (
            <div className='m-3 '>
              <Mails />
            </div>
          )}
          {property === 'Statements' && (
            <div className='m-3 '>
              <Statements currency={customerData?.currency} />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
