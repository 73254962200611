import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  rentalsData: [],
  loading: true,
  fetch: true,
}

const rentalsSlice = createSlice({
  name: 'rentals',
  initialState,
  reducers: {
    setRentalsData(state, action) {
      state.rentalsData = action.payload
      state.loading = false
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
    },
    addRentals(state, action) {
      state.rentalsData.push(action.payload)
    },
    setRentalsDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setRentalsData, setLoading, addRentals, setRentalsDataFetch} = rentalsSlice.actions
export default rentalsSlice.reducer
