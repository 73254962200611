import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  orderData: [],
  loading: false,
  error: null,
}

const orderManagementSlice = createSlice({
  name: 'orderManagement',
  initialState,
  reducers: {
    setOrderData(state, action) {
      state.orderData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setOrderData, setLoading, setError} = orderManagementSlice.actions
export default orderManagementSlice.reducer
