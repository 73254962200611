import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  addReason,
  getAccountsForInventoryAdjustment,
  getReasons,
  getStocks,
  postAdjustment,
} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import Select from 'react-select'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setStock} from '../../../../redux/inventory/products/stockSlice'

const AddInventoryAdjustment = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const stock = useSelector((state: RootState) => state.inventory.stock.stock)
  const [loading, setLoading] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>({
    reason: '',
    description: '',
    referenceNo: '',
    mode: 'quantity',
    date: new Date(),
    account: '',
  })
  const [reasons, setReasons] = useState<any>([])
  const [newReason, setNewReason] = useState<string>('')
  const [accounts, setAccounts] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [items, setItems] = useState<any>([
    {
      itemId: '',
      item: '',
      quantity: '',
      newQuantity: '',
      value: '',
      newValue: '',
      adjustedValue: '',
    },
  ])

  const fetchReasons = async () => {
    const res = await getReasons(currentUser?.organization)
    setReasons(res)
  }

  const fetchAccounts = async () => {
    const res = await getAccountsForInventoryAdjustment(currentUser?.organization)
    let data = {
      'Cost of Goods Sold': [],
      Expense: [],
      Income: [],
      'Other Asset': [],
      'Other Current Asset': [],
      'Fixed Asset': [],
      Equity: [],
      'Other Current Liability': [],
      Cash: [],
    }
    res.forEach((r) => {
      if (r.accountType === 'costofgoodssold') {
        data['Cost of Goods Sold'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'expense') {
        data['Expense'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        data['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'otherasset') {
        data['Other Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        data['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        data['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        data['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'cash') {
        data['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'income') {
        data['Income'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setAccounts(data)
    setFormData({...formData, account: data['Cost of Goods Sold'][0]?.value})
  }

  const fetchItems = async () => {
    const res = await getStocks(currentUser?.organization)
    console.log(res)
    const data = res.map((r) => {
      return {
        value: r._id,
        label: r.productName, // Corrected the typo here from "labe" to "label"
        price: r.price,
        inWarehouseQuantity: r.inWarehouseQuantity,
      }
    })
    setProductList(data)
    dispatch(setStock(data))
  }

  const postReason = async () => {
    const data = {
      reason: newReason,
    }
    const res = await addReason(currentUser?.organization, data)
    if (res) {
      setNewReason('')
      setReasons([...reasons, newReason])
      const closeModalButton = document.getElementById('closeModalButton')
      closeModalButton.click()
    }
  }

  useEffect(() => {
    fetchReasons()
    fetchAccounts()
    if (stock.length === 0) fetchItems()
    else setProductList(stock)
  }, [])

  const addItem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        item: '',
        quantity: '',
        newQuantity: '',
        value: '',
        newValue: '',
        adjustedValue: '',
      },
    ])
  }

  const removeitem = (index: number) => {
    if (items.length === 1) {
      return
    }
    const list = [...items]
    list.splice(index, 1)
    setItems(list)
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        quantity: selectedOption.inWarehouseQuantity,
        newQuantity: 0,
        value: selectedOption.price * selectedOption.inWarehouseQuantity,
        newValue: 0,
        adjustedValue: 0,
      }
      return updatedItems
    })
  }

  const handleSubmit = async (status) => {
    setLoading(true)
    const data = {
      ...formData,
      items: items,
      status,
      company: currentUser?.company,
      organization: currentUser?.organization,
      createdBy: currentUser?._id,
    }
    console.log(data)
    const res = await postAdjustment(data, status)
    if (res) {
      setLoading(false)
      // redirect to inventory adjustment page
      navigate('/inventory/adjustments')
    }
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>New Adjustment</h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className=''>
              <div className='form-group'>
                <label className='required'>Mode of adjustment</label>
                <div className='form-check'>
                  <input
                    type='radio'
                    className='form-check-input'
                    id='quantity'
                    value='quantity'
                    checked={formData.mode === 'quantity'}
                    onChange={(e) => {
                      setFormData({...formData, mode: e.target.value})
                      setItems([
                        {
                          item: '',
                          quantity: '',
                          newQuantity: '',
                          value: '',
                          newValue: '',
                          adjustedValue: '',
                        },
                      ])
                    }}
                  />
                  <label className='form-check-label' htmlFor='quantity'>
                    Quantity Adjustment
                  </label>
                </div>
                <div className='form-check mt-2'>
                  <input
                    type='radio'
                    className='form-check-input'
                    id='value'
                    value='value'
                    checked={formData.mode === 'value'}
                    onChange={(e) => {
                      setFormData({...formData, mode: e.target.value})
                      setItems([
                        {
                          item: '',
                          quantity: '',
                          newQuantity: '',
                          value: '',
                          newValue: '',
                          adjustedValue: '',
                        },
                      ])
                    }}
                  />
                  <label className='form-check-label' htmlFor='value'>
                    Value Adjustment
                  </label>
                </div>
              </div>
            </div>
            <div className='mt-3'>
              <div className='form-group'>
                <label>Reference No</label>
                <input
                  type='text'
                  className='form-control w-50'
                  value={formData.referenceNo}
                  onChange={(e) => setFormData({...formData, referenceNo: e.target.value})}
                />
              </div>
            </div>
            <div className='mt-3'>
              <div className='form-group'>
                <label className='required'>Date</label>
                <input
                  type='date'
                  className='form-control w-50'
                  value={formData.date}
                  onChange={(e) => setFormData({...formData, date: e.target.value})}
                />
              </div>
            </div>
            <div className='mt-3'>
              <div className='form-group'>
                <label className='required'>Account</label>
                <select
                  className='form-select form-select-solid w-50'
                  value={formData.account}
                  onChange={(e) => setFormData({...formData, account: e.target.value})}
                >
                  {Object.keys(accounts).map((key, index) => {
                    return (
                      <optgroup label={key} key={index}>
                        {accounts[key].map((item) => {
                          return (
                            <option value={item?.value} key={item?.value}>
                              {item?.label}
                            </option>
                          )
                        })}
                      </optgroup>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='mt-3'>
            <div className='form-group'>
              <label className='required'>Reason</label>
              <select
                className='form-control w-50'
                value={formData.reason}
                onChange={(e) => setFormData({...formData, reason: e.target.value})}
              >
                <option value=''>Select a Reason</option>
                {reasons?.map((reason: any, idx: number) => (
                  <option key={idx} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
              <div
                className='card-toolbar my-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              >
                <a className='btn btn-sm btn-light-warning mb-10'>
                  <i className='ki-duotone ki-plus fs-2'></i>Create New Reason
                </a>
              </div>
            </div>
          </div>
          <div className='my-3'>
            <div className='form-group'>
              <label>Description</label>
              <textarea
                className='form-control w-50'
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
              />
            </div>
          </div>
          <div className='fs-6 fw-bold text-gray-700 mb-3'>Item Details</div>
          <div className='table-responsive mb-10'>
            {/* begin::Table */}
            <table className='table g-5 gs-0 mb-0 fw-bold text-gray-700' data-kt-element='items'>
              {/* begin::Table head */}
              <thead>
                <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                  <th className='min-w-100px w-500px'>Item</th>
                  <th className='min-w-150px w-250px'>
                    {formData.mode === 'quantity' ? 'Available Quantity' : 'Current Value'}
                  </th>
                  <th className='min-w-50px w-250px'>
                    {formData.mode === 'quantity' ? 'New Quantity on Hand' : 'Changed Value'}
                  </th>
                  <th className='min-w-70px w-150px'>
                    {formData.mode === 'quantity' ? 'Quantity adjusted' : 'Adjusted Value'}
                  </th>
                  <th className='min-w-70px w-200px'>Action</th>
                </tr>
              </thead>
              {/* Table head */}
              {/* begin::Table body */}
              <tbody className='z-5000'>
                {items.map((item, index) => (
                  <tr
                    className='border-bottom border-bottom-dashed z-5000'
                    data-kt-element='item'
                    key={index}
                  >
                    <td className='absolute w-[300px] pe-7'>
                      {/* begin::Input group */}
                      <Select
                        name='itemId'
                        aria-label='Select a Product'
                        placeholder='Select a Product'
                        options={productList}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={(e) => handleSelectChangeInRows(index, e)}
                        value={item.selectedOption}
                        required
                        menuPortalTarget={document.body}
                        menuPlacement='auto'
                      />
                    </td>
                    <td className='pe-7'>
                      {/* begin::Input group */}

                      <input
                        type='number'
                        className='form-control form-control-solid'
                        name='quantity'
                        placeholder='0.00'
                        min={0}
                        value={formData.mode === 'quantity' ? item?.quantity : item?.value}
                        readOnly
                      />
                    </td>
                    <td className='pe-7'>
                      {/* begin::Input group */}

                      <input
                        type='number'
                        className='form-control form-control-solid'
                        name='quantity'
                        placeholder='0.00'
                        min={0}
                        value={formData.mode === 'quantity' ? item?.newQuantity : item?.newValue}
                        onChange={(e) => {
                          if (formData.mode === 'quantity') {
                            setItems((prev) => {
                              const list = [...prev]
                              list[index].newQuantity = e.target.value
                              list[index].adjustedValue =
                                Number(e.target.value) - Number(item?.quantity)
                              return list
                            })
                          } else {
                            setItems((prev) => {
                              const list = [...prev]
                              list[index].newValue = e.target.value
                              list[index].adjustedValue =
                                Number(e.target.value) - Number(item?.value)
                              return list
                            })
                          }
                        }}
                      />
                    </td>

                    <td className='pe-7'>
                      {/* begin::Input group */}

                      <input
                        type='number'
                        className='form-control form-control-solid'
                        name='price'
                        placeholder='0.00'
                        value={
                          // formData.mode === 'quantity'
                          //   ? item?.newQuantity - item?.quantity
                          //   : item?.newValue - item?.value
                          item?.adjustedValue
                        }
                      />
                    </td>
                    <td>
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                        onClick={addItem}
                      >
                        <KTIcon iconName='plus-square' className='fs-3' />
                      </div>
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                        onClick={() => removeitem(index)}
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-start'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => {
                handleSubmit('draft')
              }}
              disabled={loading}
            >
              Save as Draft
            </button>

            <button
              className='btn btn-success mx-2'
              type='button'
              onClick={() => {
                handleSubmit('adjusted')
              }}
              disabled={loading}
            >
              Convert to Adjusted
            </button>

            <Link to={'/inventory/adjustments'}>
              <button className='btn btn-light' disabled={loading}>
                Cancel
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add New Reason</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Add Reason</label>
                    <input
                      type='text'
                      name='name'
                      value={newReason}
                      onChange={(e) => setNewReason(e.target.value)}
                      className='form-control'
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={postReason}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddInventoryAdjustment
