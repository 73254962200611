import React, {useEffect, useState} from 'react'
import {
  getCategoryCodes,
  getSystemCodes,
  getAssemblyCodes,
  getComponentCodes,
  getRepairCodes,
  postServiceTask,
  getServiceSubTasks,
} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'
import Select from 'react-select'
import {useNavigate} from 'react-router-dom'

const AddServiceTask = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [taskLists, setTaskLists] = useState([])
  const [subTask, setSubTask] = useState([null])
  const [loading, setLoading] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [systemList, setSystemList] = useState([])
  const [assemblyList, setAssemblyList] = useState([])
  const [componentList, setComponentList] = useState([])
  const [repairList, setRepairList] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedSystem, setSelectedSystem] = useState(null)
  const [selectedAssembly, setSelectedAssembly] = useState(null)
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    categoryCode: '',
    systemCode: '',
    assemblyCode: '',
    componentCode: '',
    repairCode: '',
  })
  const fetchCategoryList = async () => {
    const res = await getCategoryCodes(currentUser?.organization)
    console.log('categoryList', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.categoryCode + ' - ' + item.categoryName,
        value: item._id,
      }
    })
    setCategoryList(data)
  }

  const fetchSystemList = async () => {
    const res = await getSystemCodes(selectedCategory)
    console.log('systemList', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.systemCode + ' - ' + item.systemName,
        value: item._id,
      }
    })
    setSystemList(data)
  }

  const fetchAssemblyList = async () => {
    const res = await getAssemblyCodes(selectedSystem)
    console.log('assemblyList', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.assemblyCode + ' - ' + item.assemblyName,
        value: item._id,
      }
    })
    setAssemblyList(data)
  }

  const fetchComponentList = async () => {
    const res = await getComponentCodes(selectedAssembly)
    console.log('componentList', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.componentCode + ' - ' + item.componentName,
        value: item._id,
      }
    })
    setComponentList(data)
  }

  const fetchRepairList = async () => {
    const res = await getRepairCodes(currentUser?.organization)
    console.log('repairList', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.repairCode + ' - ' + item.repairName,
        value: item._id,
      }
    })
    setRepairList(data)
  }

  const fetchTaskList = async () => {
    const res = await getServiceSubTasks(currentUser?.organization)
    console.log('taskLists', res)
    const data = res.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item._id,
      }
    })
    setTaskLists(data)
  }

  useEffect(() => {
    fetchTaskList()
    fetchCategoryList()
    fetchRepairList()
  }, [])

  useEffect(() => {
    if (selectedCategory === null) return
    setSystemList([])
    setAssemblyList([])
    setComponentList([])
    setSelectedSystem(null)
    setSelectedAssembly(null)
    setFormData({
      ...formData,
      systemCode: '',
      assemblyCode: '',
      componentCode: '',
    })
    fetchSystemList()
  }, [selectedCategory])

  useEffect(() => {
    if (selectedSystem === null) return
    setAssemblyList([])
    setComponentList([])
    setSelectedAssembly(null)
    setFormData({
      ...formData,
      assemblyCode: '',
      componentCode: '',
    })
    fetchAssemblyList()
  }, [selectedSystem])

  useEffect(() => {
    if (selectedAssembly === null) return
    setComponentList([])
    setFormData({
      ...formData,
      componentCode: '',
    })
    fetchComponentList()
  }, [selectedAssembly])

  const handleSubmit = async () => {
    if (formData.name === '') {
      alert('Name is required')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      subTask: subTask,
      organization: currentUser?.organization,
      company: currentUser?.company,
    }
    console.log('submitted', data)
    const res = await postServiceTask(data)
    if (res) {
      navigate('/maintenance/tasks')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4 className='card-title'>Add Service Task</h4>
        </div>
        <div className='card-body'>
          <div className='form-group'>
            <label className='required'>Name</label>
            <input
              type='text'
              className='form-control'
              placeholder='Enter task name'
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
            />
            <p className='text-muted'>
              A brief title for your unique task. See examples of Fleetio's comprehensive list of
              Standard Service Tasks that cover most common repairs and maintenance.
            </p>

            <label className='mt-3'>Description</label>
            <textarea
              className='form-control'
              placeholder='Enter task description'
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
            ></textarea>
            <p className='text-muted'>Additional details about the service/maintenance task</p>

            <label className='mt-3'>Sub Task</label>
            <Select
              options={taskLists}
              isMulti
              value={taskLists.filter((item) => subTask.includes(item.value))}
              onChange={(e) => setSubTask(e.map((item) => item.value))}
            />
            <p className='text-muted'>Only Service Tasks without Subtasks can be added.</p>

            <label className='mt-3'>Category Code</label>
            <Select
              options={categoryList}
              value={categoryList.find((item) => item.value === selectedCategory)}
              onChange={(e) => {
                setSelectedCategory(e.value)
                setFormData({...formData, categoryCode: e.label})
              }}
            />
            <p className='text-muted'>
              Category Code: a one-digit number that identifies the category involved in the repair
              (chassis, for example).
            </p>

            <label className='mt-3'>System Code</label>
            <Select
              options={systemList}
              value={systemList.find((item) => item.value === selectedSystem) || {label: ''}}
              onChange={(e) => {
                setSelectedSystem(e.value)
                setFormData({...formData, systemCode: e.label})
              }}
            />
            <p className='text-muted'>
              Code Key 31: System Level Codes -- a three-digit number that identifies the system
              involved in the repair (brakes, for example).
            </p>

            <label className='mt-3'>Assembly Code</label>
            <Select
              options={assemblyList}
              value={assemblyList.find((item) => item.value === selectedAssembly) || {label: ''}}
              onChange={(e) => {
                setSelectedAssembly(e.value)
                setFormData({...formData, assemblyCode: e.label})
              }}
            />
            <p className='text-muted'>
              Code Key 32: Assembly Level Codes -- used to further define the system (front brakes,
              for example).
            </p>

            <label className='mt-3'>Component Code</label>
            <Select
              options={componentList}
              value={
                componentList.find((item) => item.label === formData.componentCode) || {label: ''}
              }
              onChange={(e) => setFormData({...formData, componentCode: e.label})}
            />
            <p className='text-muted'>
              Code Key 33: Component Level Codes -- a three-digit number that identifies the part;
              this is not the manufacturer's part number, but rather a generic term for the part
              (front brake lining, for example).
            </p>

            <label className='mt-3'>Repair Code</label>
            <Select
              options={repairList}
              value={repairList.find((item) => item.label === formData.repairCode) || {label: ''}}
              onChange={(e) => setFormData({...formData, repairCode: e.label})}
            />
            <p className='text-muted'>
              Code Key 14: Divided into the subcategories of maintenance, management decision and
              outside influence, Reason for Repair codes indicate why the asset has been sent to the
              shop (breakdown, for example).
            </p>

            <button className='btn btn-primary my-3' disabled={loading} onClick={handleSubmit}>
              Save Service Task
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddServiceTask
