import {createSlice} from '@reduxjs/toolkit'

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categoryData: [],
    loading: true,
    error: null,
  },
  reducers: {
    setCategoryData(state, action) {
      state.categoryData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    addCategory(state, action) {
      state.categoryData.push(action.payload)
    },
    updateCategory(state, action) {
      const {_id, categoryName, description, type} = action.payload
      const existingCategory = state.categoryData.find((category) => category._id === _id)
      if (existingCategory) {
        existingCategory.categoryName = categoryName
        existingCategory.description = description
        existingCategory.type = type
      }
    },
  },
})

export const {setCategoryData, setLoading, setError, addCategory, updateCategory} =
  categorySlice.actions
export default categorySlice.reducer
