import React, {FC, useState} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import {DatePicker} from '../../../../../_metronic/partials/widgets'
import {KTIcon} from '../../../../../_metronic/helpers'

const Step2: FC = () => {
  const {values, setFieldValue} = useFormikContext()

  const {currency} = values as {
    currency: any
  }
  console.log('values', values)

  const [editable, setEditable] = useState(false)

  const toggleEditable = () => {
    setEditable(!editable)
  }

  const [selectedOption, setSelectedOption] = useState('computer')

  const handleFileUpload = (e) => {
    const file = e.target.files
  }

  // Function to handle file upload from cloud
  const handleCloudUpload = () => {
    // Handle cloud upload logic here
  }

  const currencies = [
    {value: '', label: ''},
    {value: 'USD', label: 'USD - US Dollar'},
    {value: 'EUR', label: 'EUR - Euro'},
    {value: 'JPY', label: 'JPY - Japanese Yen'},
    {value: 'GBP', label: 'GBP - British Pound Sterling'},
    {value: 'AUD', label: 'AUD - Australian Dollar'},
    {value: 'CAD', label: 'CAD - Canadian Dollar'},
    {value: 'CNY', label: 'CNY - Chinese Yuan'},
    {value: 'INR', label: 'INR - Indian Rupee'},
    {value: 'AED', label: 'AED - United Arab Emirates Dirham'},
    {value: 'SAR', label: 'SAR - Saudi Riyal'},
    {value: 'QAR', label: 'QAR - Qatari Rial'},
    {value: 'KWD', label: 'KWD - Kuwaiti Dinar'},
    {value: 'OMR', label: 'OMR - Omani Rial'},
    {value: 'BHD', label: 'BHD - Bahraini Dinar'},
    {value: 'EGP', label: 'EGP - Egyptian Pound'},
    {value: 'TRY', label: 'TRY - Turkish Lira'},
    {value: 'ZAR', label: 'ZAR - South African Rand'},
  ]

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Other Details</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div> */}
      </div>
      {/* <div className='fv-row mb-10'>
        <label className='form-label  '>PAN</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='The name you enter here will be the primary contact for this vendor. This person will be the main point of contact for all communications with this vendor.'
        ></i>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='idNumber'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='idNumber' />
        </div> */}
      {/* <div className='text-danger mt-2'>
          <ErrorMessage name='department' />
        </div> */}
      {/* </div> */}
      <div className='fv-row mb-10'>
        <label className='form-label  '>Currency</label>
        <Field as='select' name='currency' className='form-select form-select-lg form-select-solid'>
          {currencies.map((currency) => (
            <option
              key={currency.value}
              value={currency.value}
              defaultValue={currency.value === 'SAR' ? 'SAR' : undefined}
            >
              {currency.label}
            </option>
          ))}
        </Field>

        <div className='text-danger mt-2'>
          <ErrorMessage name='currency' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label mb-3 '>Opening Balance</label>
        <div className='relative d-flex align-items-center'>
          <div className='absolute bg-secondary fs-3 py-3 px-4 rounded-s-xl'>{currency}</div>
          <Field
            type='number'
            name='openingBalance'
            className='form-select form-select-lg form-select-solid ps-20'
          />
        </div>
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='openingBalance' />
        </div> */}
      </div>{' '}
      {/* <div className='fv-row mb-10'>
        <label className='form-label mb-3  '>Exchange Rate</label>
        <div className='d-flex align-items-center'>
          <Field
            type='number'
            name='exchangeRate'
            className='form-control form-control-lg form-control-solid'
            readOnly={!editable}
          />
          <button type='button' onClick={toggleEditable} className='text-primary'>
            {editable ? <i className='fas fa-check '></i> : <i className='fas fa-pencil-alt '></i>}
          </button>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='exchangeRate' />
        </div>
      </div> */}
      <div className='fv-row mb-10'>
        <label className='form-label  '>Payment Terms</label>
        <Field
          as='select'
          name='paymentTerms'
          className='form-select form-select-lg form-select-solid'
        >
          <option value='Net 15 days'>Net 15 days</option>
          <option value='Net 30 days'>Net 30 days</option>
          <option value='Net 45 days'>Net 45 days</option>
          <option value='Net 60 days'>Net 60 days</option>
          <option value='Net 90 days'>Net 90 days</option>
          <option value='50% Advance'>50% Advance</option>
          <option value='100% Advance'>100% Advance</option>
          <option value='Due end of the month'>Due end of the month</option>
          <option value='Due end of next month'>Due end of next month</option>
          <option value='Due on Receipt'>Due on Receipt</option>
        </Field>
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='paymentTerms' />
        </div> */}
      </div>{' '}
      {/* <div className='fv-row mb-10'>
        <label className='form-label  '>TDS</label>
        <Field as='select' name='tds' className='form-select form-select-lg form-select-solid'>
          <option></option>
          <option value='Full-Time'>Full-Time</option>
          <option value='Part-Time'>Part-Time</option>
          <option value='Remote'>Remote</option>
          <option value='Contract'>Contract</option>
        </Field> */}
      {/* <div className='text-danger mt-2'>
          <ErrorMessage name='tds' />
        </div> */}
      {/* </div> */}
      {/* <div className='fv-row mb-10'>
        <label className='form-label  '>Enable Portal?</label>
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='The name you enter here will be the primary contact for this vendor. This person will be the main point of contact for all communications with this vendor.'
        ></i>
        <label className='form-control form-control-lg form-control-solid align-items-center'>
          <Field type='checkBox' name='enablePortal' className='w-15px h-15px ' value='1' />
          <span className='mx-4'> Allow Portal Access for this vendor</span>
        </label> */}
      {/* <div className='text-danger mt-2'>
          <ErrorMessage name='enablePortal' />
        </div> */}
      {/* </div> */}
      {/* <div className='fv-row mb-10'>
        <label className='form-label'>Portal Language</label> */}
      {/* <Field
          as='select'
          name='portalLanguage'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='1'>S Corporation</option>
          <option value='1'>C Corporation</option>
          <option value='2'>Sole Proprietorship</option>
          <option value='3'>Non-profit</option>
          <option value='4'>Limited Liability</option>
          <option value='5'>General Partnership</option>
        </Field> */}
      {/* <div className='text-danger mt-2'>
          <ErrorMessage name='portalLanguage' />
        </div> */}
      {/* </div> */}
      {/* Select field to choose upload method */}
      {/* <div className='fv-row mb-10'> */}
      {/* Select field to choose upload method */}
      {/* <div className='fv-row mb-5'>
          <label className='form-label'>Choose Upload Method</label>
          <select
            className='form-select form-select-lg form-select-solid'
            value={selectedOption}
            onChange={(e) => {
              setSelectedOption(e.target.value)
              if (e.target.value === 'computer') {
                handleFileUpload(e)
              } else if (e.target.value === 'cloud') {
                handleCloudUpload()
              }
            }}
          >
            <option value='computer'>Upload File from Computer</option>
            <option value='cloud'>Upload File from Cloud</option>
          </select>
        </div> */}
      {/* {selectedOption === 'computer' && (
          <div className='mb-4'>
            <input
              type='file'
              onChange={handleFileUpload}
              className='form-control form-control-sm form-control-solid'
            />
          </div>
        )}

        {selectedOption === 'cloud' && (
          <div className='mb-4'>
            <button onClick={handleCloudUpload} className='btn btn-primary'>
              Upload File from Cloud
            </button>
          </div>
        )} */}
      {/* </div> */}
      <div className='fv-row mb-10'>
        <label className='form-label  '>Website Url</label>
        <Field
          type='link'
          className='form-control form-control-sm form-control-solid'
          name='websiteUrl'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='websiteUrl' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label  '>Industry</label>

        <Field
          type='link'
          className='form-control form-control-lg form-control-solid'
          name='industry'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='industry' />
        </div>
      </div>
      {/* <div className='fv-row mb-10'>
        <label className='form-label  '>Designation</label>

        <Field
          type='link'
          className='form-control form-control-lg form-control-solid'
          name='designation'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='designation' />
        </div>
      </div> */}
    </div>
  )
}

export {Step2}
