/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

const rows: Array<{description: string; icons: string; colors: string; link: string}> = [
  {
    description: 'Create Quotation',
    icons: 'arrow-up-right',
    colors: 'success',
    link: '/sales/quotes/add',
  },
  {
    description: 'Create Invoice',
    icons: 'arrow-up-right',
    colors: 'success',
    link: '/sales/invoice/add',
  },
  {
    description: 'Add New Project',
    icons: 'arrow-up-right',
    colors: 'success',
    link: '/project/projectlist/add',
  },
]

const ListsWidget27 = ({className}: Props) => (
  <div className={`card card-flush ${className} bg-light-primary border-primary`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Quick Tabs</h3>
      {/* <span className='text-gray-400 fw-semibold'>Latest social statistics</span> */}
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <Link to={row.link} className='text-black fw-semibold fs-6 me-2'>
              {row.description}
            </Link>
            <button
              type='button'
              className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-gray-700 justify-content-end'
            >
              <KTIcon iconName={row.icons} className={`fs-2 text-${row.colors}`} />
            </button>
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {ListsWidget27}
