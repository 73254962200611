import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {editProjectById, getProjectById} from '../core/_requests'
import {getCustomer, getEmployee, getUsersByOrgId} from '../../sales/Core/_requests'
import {useAuth} from '../../../modules/auth'
import ProductTable from './steps/ProductTable'
import Card1 from './updateProject/Card1'
import Card2 from './updateProject/Card2'
import Card3 from './updateProject/Card3'
import Card4 from './updateProject/Card4'
import Card5 from './updateProject/Card5'

export const UpdateProject = () => {
  const {id} = useParams()
  console.log('projectId', id)
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const initialValues = {
    projectType: '',
    customer: '',
    projectName: '',
    projectDescription: '',
    startDate: ' ',
    releaseDate: '',
    notification: {
      email: false,
      phone: false,
    },
    budgetAmount: '',
    changeInBudget: false,
    team: [],
    assignedAgents: [],
    items: [],
    budgetNotes: '',
    morningShiftBoolean: false,
    morningShiftValue: 0,
    eveningShiftValue: 0,
    eveningShiftBoolean: ' ',
    totalCalculatedDays: ' ',
    totalCalculatedHours: ' ',
    productSubTotal: 0,
    totalHourlyCharges: 0,
  }

  const [data, setData] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState([])
  const [agentList, setAgentList] = useState<any>([])
  const [product, setProduct] = useState<any>([])

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log('res', res)
      setCustomerList(
        res.map((r) => ({
          value: r._id,
          label: r.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getAgents = async () => {
    try {
      const res = await getUsersByOrgId(currentUser?.organization)
      console.log(res)
      setAgentList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName,
          email: r.email,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  console.log('employeeList', employeeList)

  useEffect(() => {
    const fetchProject = async () => {
      try {
        setLoading(true)
        // const projectData = await getProjectById(projectId);
        const projectData = await getProjectById(id)
        console.log('ProjectById DATA', projectData)
        const teamData = Array.isArray(projectData.team)
          ? projectData.team.map((member) => ({
              value: member.employee?._id,
              label: member.employee?.firstName, // You can add more fields here
            }))
          : []

        const assignedUsers = projectData.assignedAgents?.map((user) => ({
          value: user._id,
          label: user.fullName,
        }))

        setData({
          ...projectData,
          releaseDate: projectData.releaseDate ? projectData.releaseDate.slice(0, 10) : '',
          startDate: projectData.startDate ? projectData.startDate.slice(0, 10) : '',
          team: teamData,
          assignedAgents: assignedUsers,
          customer: projectData?.customer?._id
        })
        console.log('team data', teamData)

        setLoading(false)
      } catch (error) {
        console.error('Error fetching project details:', error)
        setLoading(false)
      }
    }
    fetchProject()
  }, [id])

  const handleInputChange = (e) => {
    const {name, value, type, checked} = e.target
    const fieldValue = type === 'checkbox' ? checked : value
    setData({
      ...data,
      [name]: fieldValue,
    })
  }

  const handleNotificationChange = (type) => {
    setData({
      ...data,
      notification: {
        ...data.notification,
        [type]: !data.notification[type],
      },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const body = {
      ...data,
      team: data.team.map((member) => ({
        employee: member.value,
        name: member.label,
        email: member?.email,
        department: member?.department,
        dailyPay: member?.dailyPay,
        hourlyPay: member?.hourlyPay,
      })),
      items: product,
      // .map((item) => ({
      //   itemId: item.itemId,
      //   price: item.price,
      //   quantity: item.quantity,
      //   amount: item.amount,
      //   type: item.type,
      // })),
      assignedAgents: data.assignedAgents.map((member) => member.value),
      organization: currentUser?.organization,
      company: currentUser?.company,
    }
    try {
      await editProjectById(body, id)
      console.log('Submitted data:', data)
      setLoading(false)
      navigate('/project/projectlist')
    } catch (error) {
      console.error('Error submitting data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomerList()
    getAgents()
  }, [])

  return (
    <div>
      <div className='container-xxl'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'> EDIT PROJECT </h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <Card1
              data={data}
              handleInputChange={handleInputChange}
              handleNotificationChange={handleNotificationChange}
              customerList={customerList}
              setData={setData}
            />

            <Card2 data={data} handleInputChange={handleInputChange} setData={setData} />

            <div className='card mb-5 mb-xl-10'>
              <div className='container-xxl'>
                <div className='card-header border-0 cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Product Table</h3>
                  </div>
                </div>
                <ProductTable
                  products={data.items}
                  data={data}
                  setProducts={setProduct}
                  setData={setData}
                />
              </div>
            </div>

            <Card4 data={data} setData={setData} />

            <Card5 data={data} setData={setData} agentList={agentList} />

            <Card3 loading={loading} data={data} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateProject
