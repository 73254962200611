import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getIssues} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setIssueData} from '../../../../redux/maintenance/issues/issues/issuesSlice'

const IssueList = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [issuesList, setIssuesList] = useState<any>([])
  const dispatch = useDispatch()
  const issuesList = useSelector((state: any) => state.maintenance.issues.issueData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getIssues(currentUser?.organization)
    console.log(res)
    // setIssuesList(res)
    dispatch(setIssueData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4>Issues</h4>
          <div>
            <Link to='add' className='text-white btn btn-primary'>
              <KTIcon iconName='plus' className='me-2' />
              Add Issue
            </Link>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-striped align-middle gs-0 gy-4'>
              <thead className='bg-gray-200'>
                <tr className='fw-bold text-muted'>
                  <th>Issue</th>
                  <th>Item Name</th>
                  <th>Type</th>
                  <th>Priority</th>
                  <th>Summary</th>
                  <th>Status</th>
                  <th>Source</th>
                  <th>Reported Date</th>
                  <th>Assigned To</th>
                </tr>
              </thead>
              <tbody>
                {issuesList?.map((issue: any) => (
                  <tr key={issue._id}>
                    <td>
                      <Link to={`/issues/issue-list/${issue._id}`}>#{issue?.issueId}</Link>
                    </td>
                    <td>{issue?.product?.productName}</td>
                    <td>{issue?.product?.rentalType}</td>
                    <td className='uppercase'>{issue.priority}</td>
                    <td>{issue.summary}</td>
                    <td>{issue.status}</td>
                    <td>
                      <Link to={`/inspection/history/view/${issue?.source}`}>
                        {issue?.source ? 'link' : ''}
                      </Link>
                    </td>
                    <td>{formatDate(issue.reportedDate)}</td>
                    <td>
                      {issue?.assignedTo?.firstName} {issue?.assignedTo?.lastName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IssueList
