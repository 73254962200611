import React from 'react'
import { CardsWidget10, CardsWidget11, CardsWidget13, CardsWidget7, ChartsWidget10, ChartsWidget11, ChartsWidget12, ChartsWidget2, ChartsWidget3, ChartsWidget5, ChartsWidget6, ChartsWidget7, ListsWidget1, ListsWidget13, ListsWidget14, StatisticsWidget4, StatisticsWidget5, TablesWidget17 } from '../../../../_metronic/partials/widgets'
import { ChartsWidget13 } from '../../../../_metronic/partials/widgets/charts/ChartsWidget13'

const DashboardPage = () => {
    return (
        <>
            <div className=" row g-5 g-xl-8 mb-5 mb-xl-8">
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-2 ">
                    <div className="mb-5 mb-xl-8 ">
                        <ChartsWidget3 className='card-shrink overflow-hidden ' />
                    </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="row g-5 g-xl-8 mb-5 mb-xl-8">
                        <div className="col-12 ">
                            <div className="mb-5  ">
                                <StatisticsWidget4 className={'card-stretch overflow-hidden '} color={'info'} svgIcon={''} description={'Description'} change={''} height='150'/>
                            </div>
                        </div>
                    </div>
                    <div className="row  ">
                        <div className="col-6  ">
                            <div className="">
                                <CardsWidget13 className='' title={'Service Reminders'} Param1={'Overdue'} Param1Number={2} Param2={'Due Soon'} Param2Number={3} />
                            </div>
                        </div>
                        <div className="col-6 ">
                            <div className=" ">
                                <CardsWidget13 className='' title={'Overdue Inspection'} Param1={'OverDue'} Param1Number={0} Param2={'of Total Use'} Param2Number={0} /></div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row  g-5 g-xl-8 mb-5 mb-xl-8">
                <div className="col-xl-3 col-6  mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <ListsWidget14 className=' overflow-auto ' title='Vehicle Status'/>
                    </div>
                </div>
                <div className="col-xl-3 col-6 mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <ListsWidget14 className=' overflow-auto ' title='Equipment Status' />
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='' title={'Vehicle Assignments'} Param1={'Assigned'} Param1Number={0} Param2={'Unassigned'} Param2Number={0} />
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='' title={'Vehicle Renewal Reminders'} Param1={'OverDue'} Param1Number={0} Param2={'Due Soon'} Param2Number={0} />
                    </div>
                </div>
            </div>
            <div className="row  g-5 g-xl-8 mb-5 mb-xl-8">
                <div className="col-xl-3 col-6  mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='card-stretch' title={'Inspection Item Failure Rate'} Param1={'This Week'} Param1Number={0} Param2={'Change from last week'} Param2Number={0} />
                    </div>
                </div>
                <div className="col-xl-3 col-6 mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='card-stretch' title={'Open Issue'} Param1={'Open'} Param1Number={0} Param2={'Overdue'} Param2Number={0} />
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='card-stretch' title={'All Faults'} Param1={'Open'} Param1Number={0} Param2={'Pending'} Param2Number={0} />
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='card-stretch' title={'Active Work Orders'} Param1={'OverDue'} Param1Number={0} Param2={'of Total Use'} Param2Number={0} />
                    </div>
                </div>
            </div>
            <div className="row  g-5 g-xl-8 mb-5 mb-xl-8">
                <div className="col-xl-3 col-6  mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <ChartsWidget13 className={''} color='primary' title='Service Costs'/>
                    </div>
                </div>
                <div className="col-xl-3 col-6 mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <ChartsWidget13 className={''} color='success' title='Fuel Costs'/>
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <ChartsWidget13 className={''} color='warning' title='Other Costs'/>
                    </div>
                </div>
                <div className="col-6 col-xl-3 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <ChartsWidget13 className={''} color='danger' title='Total Costs'/>
                    </div>
                </div>
            </div>
            <div className="row  g-5 g-xl-8 mb-5 mb-xl-8">
                <div className="col-xl-3 col-6  mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <CardsWidget13 className='card-stretch flex flex-grow' title={'On time service compliance'} Param1={'All time'} Param1Number={0} Param2={'Last 30 days'} Param2Number={0} />
                    </div>
                </div>
                <div className="col-xl-3 col-6 mb-md-2 ">
                    <div className="mb-5 mb-xl-8">
                        <ChartsWidget13 className={''} color='primary' title='Inspection Summary'/>
                    </div>
                </div>
                <div className="col-6 col-xl-6 mb-md-2">
                    <div className="mb-5 mb-xl-8">
                        <StatisticsWidget4 className={'card-stretch overflow-hidden '} color={'info'} svgIcon={''} description={'Inspection Submissions'} change={''} height='100' />
                    </div>
                </div>
            </div>


        </>
    )
}

export default DashboardPage