import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  vendorData: [],
  dateState: {
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    endDate: new Date().toISOString(),
  },
  loading: false,
  error: null,
}

const vendorPerformanceSlice = createSlice({
  name: 'vendorPerformance',
  initialState,
  reducers: {
    setVendorPerformanceData(state, action) {
      state.vendorData = action.payload
      state.loading = false
      state.error = null
    },
    setDateRange(state, action) {
      state.dateState = {
        startDate: new Date(action.payload.startDate).toISOString(),
        endDate: new Date(action.payload.endDate).toISOString(),
      }
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setVendorPerformanceData, setLoading, setError, setDateRange} =
  vendorPerformanceSlice.actions
export default vendorPerformanceSlice.reducer
