import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Step3: FC = () => {
  const {values, setFieldValue} = useFormikContext()
  const [workPhoneNo, setWorkPhoneNo] = useState<string | undefined>('')
  const [personalNo, setPersonalNo] = useState<string | undefined>('')
  console.log('values address', values)

  const {billingAddress, shippingAddress, sameAsBillingAddress} = values as {
    billingAddress: any
    shippingAddress: any
    sameAsBillingAddress: boolean
  }

  useEffect(() => {
    if (billingAddress?.phone && shippingAddress?.phone) {
      setWorkPhoneNo(String(billingAddress?.phone) || '')
      setPersonalNo(String(shippingAddress?.phone) || '')
    }
  }, [])

  useEffect(() => {
    setFieldValue('billingAddress.phone', workPhoneNo)
    setFieldValue('shippingAddress.phone', personalNo)
  }, [setFieldValue, workPhoneNo, personalNo])

  useEffect(() => {
    if (sameAsBillingAddress) {
      setFieldValue('shippingAddress', {...billingAddress})
    }
  }, [sameAsBillingAddress, billingAddress, setFieldValue])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Address Information</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Billing Address</label>
        <div className='row'>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.addressLine1'
              className='form-control form-control-lg form-control-solid'
              placeholder='Address Line 1'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.addressLine2'
              className='form-control form-control-lg form-control-solid'
              placeholder='Address Line 2'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.country'
              className='form-control form-control-lg form-control-solid'
              placeholder='Country'
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name='country' />
            </div>
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.state'
              className='form-control form-control-lg form-control-solid'
              placeholder='State'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.city'
              className='form-control form-control-lg form-control-solid'
              placeholder='City'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='billingAddress.postalCode'
              className='form-control form-control-lg form-control-solid'
              placeholder='Postal Code'
            />
          </div>
          <div className='col-md-6 mb-3'>
            {/* <Field
              type='number'
              name='billingAddress.phone'
              className='form-control form-control-lg form-control-solid'
              placeholder='Phone'
            /> */}
            <div className='form-control form-control form-control-solid px-1'>
              <PhoneInput
                country={'sa'}
                value={workPhoneNo}
                onChange={(phone) => setWorkPhoneNo(phone)}
              />
            </div>
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='number'
              name='billingAddress.faxNumber'
              className='form-control form-control-lg form-control-solid'
              placeholder='Fax Number'
            />
          </div>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentAddressLine1' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentAddressLine2' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentCountry' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentCity' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentPostalCode' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentPhoneNumber' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentFaxNumber' />
        </div>
      </div>

      <div className='mb-10'>
        <Field type='checkbox' name='sameAsBillingAddress' id='sameAsBillingAddress' />
        <label htmlFor='sameAsBillingAddress' className='ms-2'>
          Same as Billing Address
        </label>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Shipping Address</label>
        {/* Conditional rendering based on checkbox */}
        {(values as {sameAsBillingAddress: boolean}).sameAsBillingAddress ? (
          <>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.addressLine1'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 1'
                  value={billingAddress.addressLine1}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.addressLine2'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 2'
                  value={billingAddress.addressLine2}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.country'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                  value={billingAddress.country}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='country' />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.state'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                  value={billingAddress.state}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.city'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  value={billingAddress.city}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.postalCode'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                  value={billingAddress.postalCode}
                />
              </div>
              <div className='col-md-6 mb-3'>
                {/* <Field
                  type='number'
                  name='shippingAddress.phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone'
                  value={billingAddress.phone}
                /> */}
                <div className='form-control form-control form-control-solid px-1'>
                  <PhoneInput
                    country={'sa'}
                    value={workPhoneNo}
                    onChange={(phone) => setWorkPhoneNo(phone)}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='number'
                  name='shippingAddress.faxNumber'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Fax Number'
                  value={billingAddress.faxNumber}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.addressLine1'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 1'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.addressLine2'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 2'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.country'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='country' />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.state'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.city'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='shippingAddress.postalCode'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                />
              </div>
              <div className='col-md-6 mb-3'>
                {/* <Field
                  type='number'
                  name='shippingAddress.phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone '
                /> */}
                <div className='form-control form-control-lg form-control-solid px-1'>
                  <PhoneInput
                    country={'sa'}
                    value={personalNo}
                    onChange={(phone) => setPersonalNo(phone)}
                  />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='number'
                  name='shippingAddress.faxNumber'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Fax Number'
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {Step3}
