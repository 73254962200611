import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {addProject} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import AgentList from '../../calender/AgentList'
import List from './List'

const SprintList: React.FC = () => {
  const [assiopen, setassiopen] = React.useState(false)
  const [projectName, setProjectName] = React.useState('')
  const [agentids, setagentids] = React.useState<any>([])
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleSubmit = async () => {
    const data = {
      name: projectName,
      agentids: agentids,
      createdBy: currentUser?._id,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await addProject(data)
    if (res) {
      setProjectName('')
      setagentids([])
      const closeModalButton = document.getElementById('closeModalButton')
      closeModalButton?.click()
    }
  }

  return (
    <div className={`card h-md-50 mb-5 mb-xl-10`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Sprints</span>
        </h3>

        {(currentUser?.profileType === 'superadmin' || permissions?.sprint?.admin) && (
          <div className='card-toolbar' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
            <button type='button' className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Sprint
            </button>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <List />
      {/* begin::Body */}

      {/* Modal for add client */}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Sprint</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                {!assiopen ? (
                  <div>
                    <div className='mb-10'>
                      <label className='form-label'>Name</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter title'
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mt-5'
                      onClick={() => setassiopen(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                      Assign Agent
                    </button>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setassiopen(false)}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <AgentList agentids={agentids} setagentids={setagentids} />
                  </div>
                )}
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SprintList
