import { createSlice } from '@reduxjs/toolkit';

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    invoiceData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setInvoiceData(state, action) {
      state.invoiceData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setInvoiceData, setLoading, setError } = invoiceSlice.actions;
export default invoiceSlice.reducer;
