import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { getEmployeeReportSubmissionDetailsById } from '../core/_requests'
// import {getEmployeeReportSubmissionDetailsById} from '../core/_requests'

const ViewSubmissionDetails = () => {
  const {id} = useParams()
  const [submission, setSubmission] = useState(null)
  useEffect(() => {
    const fetchSubmissionDetails = async () => {
      try {
        const data = await getEmployeeReportSubmissionDetailsById(id)
        setSubmission(data.submission)
      } catch (error) {
        console.error('Error fetching submission details:', error)
      }
    }

    fetchSubmissionDetails()
  }, [id])
  console.log('Answers', submission)

  if (!submission) {
    return <div>Loading...</div>
  }
  return (
    <div className='container p-4 border border-gray-300 rounded'>
      <h2 className='text-2xl font-bold mb-4 border-b pb-2'>{submission?.form?.title}</h2>
      <div className='mb-4'>
        <p className='font-bold'>
          Submitted by: {submission?.createdBy?.firstName} {submission?.createdBy?.lastName} -{' '}
          {submission?.createdBy?.email} | Date:{' '}
          {new Date(submission?.inspectionDate).toLocaleDateString('en-GB')}
        </p>
      </div>
      <div className='space-y-6'>
        {submission?.answers?.map((answer, index) => (
          <div key={index}>
            <p className='font-bold text-gray-800'>Q{index + 1}: {answer?.question}</p>
            <p className='text-blue-600'>  {answer?.answer?.join(', ')}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ViewSubmissionDetails
