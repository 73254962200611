import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Step1} from '../Components/Steps/Step1'
import {Step2} from '../Components/Steps/Step2'
import {Step3} from '../Components/Steps/Step3'
import {Step4} from '../Components/Steps/Step4'

import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from '../Core/CreateVendorHelper'
import {useNavigate, useParams} from 'react-router-dom'
import {editVendor, getVendorById, postVendor} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'

const EditVendor = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues, setInitValues] = useState<any>()
  const [isSubmitButton, setSubmitButton] = useState(false)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const navigate = useNavigate()
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      const response = await editVendor(values, id)
      console.log('form', response)
      stepper.current.goto(1)

      navigate('/procurement/vendor')
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await getVendorById(id)
          console.log('res', response)

          setInitValues(response)

          console.log('initial value vendorsss', initValues)
        } catch (error) {
          console.error('Error fetching customer details:', error)
        }
      }
    }
    fetchData()
  }, [stepperRef])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    console.log('initial value vendorsss', initValues)
    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        <div className='card d-flex overflow-auto justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='justify-content-between align-items-center'>
            <div className='px-6 px-lg-10 px-xxl-15 py-20 justify-content-between '>
              <div className='stepper-nav'>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit New Vendor</h3>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Other Details</h3>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Address Info</h3>
                    </div>
                  </div>
                  <div className='stepper-line h-40px'></div>
                </div>
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Contact Persons</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-center bg-body rounded '>
          {initValues && (
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {() => (
                <Form
                  className='py-20 w-100 w-xl-700px px-9'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step2 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step3 />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Step4 />
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {!isSubmitButton && 'Continue'}
                          {isSubmitButton && 'Submit'}
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditVendor
