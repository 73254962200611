import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers/components/KTIcon'
import {useFormikContext, Field} from 'formik'
import {ICreateAccount} from '../AddProjectHelper'

const Completed: FC = () => {
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()

  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Your Project Details Are Added!</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
            If you need more i\nfo, please
            <Link to='/auth/login' className='link-primary fw-bolder'>
              {' '}
              Sign In
            </Link>
            .
          </div> */}
      </div>

      {/* <div className='mb-0'>
          <div className='fs-6 text-gray-600 mb-5'>
            Writing headlines for blog posts is as much an art as it is a science and probably
            warrants its own post, but for all advise is with what works for your great & amazing
            audience.
          </div> */}

      <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 '>
        {/* <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' /> */}
        <div className='d-flex flex-stack flex-grow-1'>
          <div className='fw-bold'>
            <h4 className='text-gray-800 fw-bolder'>Project Summary</h4>

            <div className='row w-100 pt-8'>
              <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                <label className='form-label col-md-9 ps-12'>Estimated Budget:</label>

                <Field
                  type='text'
                  className='form-control form-control-md form-control-solid w-150px '
                  value={
                    values?.budgetAmount
                      ? values.budgetAmount.toLocaleString('en-US', {minimumFractionDigits: 2})
                      : ''
                  }
                  readOnly
                />
                <span className='text-muted fw-semibold'> {values?.currency}</span>
              </div>
              <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                <label className='form-label col-md-9 ps-12'>Total Product Budget</label>
                <Field
                  type='text'
                  className='form-control form-control-md form-control-solid w-150px'
                  value={values?.productSubTotal.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                  readOnly
                />
                <span className='text-muted fw-semibold'> {values?.currency}</span>
              </div>
              <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                <label className='form-label col-md-9 ps-12 '> Total Manpower Budget:</label>

                <Field
                  type='text'
                  className='form-control form-control-md form-control-solid w-150px '
                  value={values?.totalHourlyCharges.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                  readOnly
                />
                <span className='text-muted fw-semibold'> {values?.currency}</span>
                {/* <span className='text-muted fw-semibold'>Total Amount</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Completed}
