import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {Step1} from '../../modules/wizards/components/steps/Step1'
import {Step2} from '../../modules/wizards/components/steps/Step2'
import {Step3} from '../../modules/wizards/components/steps/Step3'
// import { Step4 } from '../../modules/wizards/components/steps/Step4';
import {Step5} from '../../modules/wizards/components/steps/Step5'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues, useFormikContext} from 'formik'
import {
  createAccountSchemas,
  ICreateAccount,
  inits,
} from '../../modules/wizards/components/CreateAccountWizardHelper'
import {Button, Image, Modal} from 'react-bootstrap'
import {postCompany} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import logo from '../../modules/auth/components/logo.png'

const MultiStepSignup = () => {
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [showModal, setShowModal] = useState(false)
  const formik = useFormikContext()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber - 1) {
      // console.log(stepper.current.currentStepIndex, stepper.current.totalStepsNumber)
      stepper.current.goNext()
    } else {
      console.log('Submitting', values)
      submitCompanyData(values)
    }
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitCompanyData = async (values: ICreateAccount) => {
    const res = await postCompany(values)
    if (res) {
      navigate('/auth/login')
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    formik?.errors ? setShowModal(true) : setShowModal(false)
    console.log('errors', formik?.errors)
  }, [formik?.errors])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Required Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please fill in all required fields before proceeding.</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* begin::Aside*/}
      <div
        className=' d-flex justify-content-center align-items-center flex-row-auto w-100 w-xl-300px w-xxl-500px me-9 overflow-y '
        style={{backgroundImage: 'url("/media/misc/auth-bg.png")'}}
      >
        {/* begin::Wrapper*/}

        <div className='justify-content-between align-items-center'>
          <div className='px-6 px-lg-10 px-xxl-15 py-20 justify-content-between '>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <Image
                  alt='Logo'
                  // src='/media/logos/custom-1.png'
                  src={logo}
                  className='h-70px logo mx-auto my-20'
                ></Image>

                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Company Info</h3>

                    <div className='stepper-desc fw-semibold'>Setup Your Company Details</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Super Admin Details</h3>
                    <div className='stepper-desc fw-semibold'>Setup Your Admin Settings</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Subscribe Modules</h3>
                    <div className='stepper-desc fw-semibold'>
                      Select Modules for your Company
                    </div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>

                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Billing Details</h3>
                    <div className='stepper-desc fw-semibold'>Set Your Payment Methods</div>
                  </div>
                </div>

                <div className='stepper-line h-40px'></div>
              </div> */}
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>
                    <div className='stepper-desc fw-semibold'>Woah, we are here</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
          <div className='d-flex flex-col-auto justify-content-between px-6 px-lg-10 px-xxl-15 text-success py-10'>
            <span>Terms</span>
            <span>Plans</span>
            <span>Contact Us</span>
          </div>
        </div>
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              {/* <div data-kt-stepper-element='content'>
                <Step4 />
              </div> */}

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Previous
                  </button>
                </div>

                <div>
                  {stepper.current?.currentStepIndex !== 4 && (
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex !==
                          stepper.current?.totalStepsNumber! - 1 && 'Continue'}
                        {stepper.current?.currentStepIndex ===
                          stepper.current?.totalStepsNumber! - 1 && 'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {MultiStepSignup}
