import React, { useEffect } from 'react'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { editDocs } from '../Core/_requests'
import { Docs } from '../Core/_models'

type props = {
    status:string
    id:string
    handlereset: () => void;
}

const Status:React.FC<props> = ({status,id,handlereset}) => {

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])

      const handlestatus = ( changedstatus: string) => {
        const data: Docs = {
          status: changedstatus
        };
      
        editDocs(id, data)
          .then(res => handlereset())
          .catch(error => console.error(error));  
      };
      

 

  return (
    <div>
         <span
        className={`cursor-pointer badge fs-7 fw-semibold  ${
            status === 'available'
            ? 'badge-light-primary'
            :status === 'busy'
            ? 'badge-light-warning'
            :status === 'offline'
       
        }`}
   

      data-kt-menu-trigger='hover'
      data-kt-menu-placement='bottom-end'
      data-kt-menu-flip='top-end'

    >
    {status}                
    </span>
    <div
        className='menu menu-sub menu-sub-dropdown w-150px w-md-150px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${status ==="available" ? "badge-primary": "badge-light-primary"} `} onClick={() => handlestatus("available")}>new</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${status ==="busy" ? "badge-warning": "badge-light-warning"} `} onClick={() => handlestatus("busy")}>busy</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${status ==="offline" ? "badge-info": "badge-light-info"} `} onClick={() => handlestatus("offline")}>offline</div>

      </div>
    </div>
  )
}

export default Status