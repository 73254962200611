import * as Yup from 'yup'

export interface ICreateAccount {
  // accountType: string
  // accountTeamSize: string
  employeeId: string
  firstName: string
  lastName: string
  nickName: string
  email: string
  department: string
  location: string
  designation: string
  role: string
  employmentType: string
  contractType: string
  employeeStatus: string
  sourceOfHire: string
  dateOfJoining: string
  reportingManager: string
  dateOfBirth: string
  age: number
  gender: string
  aboutMe: string
  passportNumber: string
  idNumber: string
  aadharNumber: number
  workPhoneNo: number
  personalMobile: number
  extension: number
  personalEmail: string
  seatingLocation: string
  tags: string
  presentAddressLine1: string
  presentAddressLine2: string
  presentCity: string
  presentState: string
  presentCountry: string
  presentPostalcode: number
  permanentAddressLine1: string
  permanentAddressLine2: string
  permanentCountry: string
  permanentState: string
  permanentCity: string
  permanentPostalCode: number
  maritalStatus: string
  sameAsPresentAddress: boolean
  yearlyPay: number
  monthlyPay: number
  hourlyPay: number
  dailyPay:number
  dailyWorkingHours: number
  currentExperience : string
  overtimePay: number
  addUser: boolean
  departmentID: string
  roleID: string
  password: string
}

const createAccountSchemas = [
  Yup.object({
    employeeId: Yup.string().required().label('Employee Id'),
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().label(' Email'),
  }),
  Yup.object({
    // department: Yup.string().required().label('Department'),
    // location: Yup.string().required().label('Location'),
    // designation: Yup.string().required().label('Designation'),
    // role: Yup.string().required().label('Zoho Role'),
    // employementType: Yup.string().label('Employement Type'),
    // dateOfJoining: Yup.string().required().label('Date Of Joining'),
    // employementStatus: Yup.string().required().label('Employement Status'),
    // sourceHire: Yup.string().required().label('Source Hire'),
  }),
  Yup.object({
    hourlyPay: Yup.number().required().min(1).label('Hourly Pay'),
    dailyWorkingHours: Yup.number().required().min(1).label('Daily Working Hours'),
    overtimePay: Yup.number().min(1).label('Overtime Pay'),
  }),
  Yup.object({
    // dateOfBirth: Yup.string().required().label('Date Of Birth'),
    // age: Yup.number().required().label('Age'),
    // aboutMe: Yup.string().required().label('About Me'),
  }),

  Yup.object({
    // passportNumber: Yup.string().required().label('UAN Number'),
    // idNumber: Yup.string().required().label('PAN Number'),
    // aadharNumber: Yup.number().required().label('Aadhar Number'),
  }),
  Yup.object({
    // workPhone: Yup.number().required().label('Work Phone'),
  }),
]

const inits: ICreateAccount = {
  // accountType: 'personal',
  // accountTeamSize: '50+',
  employeeId: '',
  firstName: '',
  lastName: '',
  nickName: ' ',
  email: '',
  department: '',
  location: '',
  designation: '',
  role: '',
  employmentType: '',
  contractType: 'temporary',
  employeeStatus: '',
  sourceOfHire: '',
  dateOfJoining: '',
  reportingManager: '',
  dateOfBirth: '',
  age: 0,
  gender: ' ',
  aboutMe: '',
  passportNumber: '',
  idNumber: '',
  aadharNumber: 0,
  workPhoneNo: 0,
  personalMobile: 0,
  extension: 0,
  personalEmail: '',
  seatingLocation: '',
  tags: '',
  presentAddressLine1: '',
  presentAddressLine2: '',
  presentCity: '',
  presentState: '',
  presentCountry: '',
  presentPostalcode: 0,
  maritalStatus: ' ',
  permanentAddressLine1: ' ',
  permanentAddressLine2: ' ',
  permanentCountry: ' ',
  permanentState: ' ',
  permanentCity: ' ',
  permanentPostalCode: 0,
  sameAsPresentAddress: false,
  yearlyPay:0,
  monthlyPay:0,
  hourlyPay:0,
  dailyPay:0,
  dailyWorkingHours:0,
  currentExperience : '',
  overtimePay:0,
  addUser: false,
  departmentID: '',
  roleID: '',
  password: '',
}

export {createAccountSchemas, inits}
