import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getInventoryAdjustments} from '../../Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setInventoryAdjustment} from '../../../../redux/inventory/inventory-adjustment/inventoryAdjustmentSlice'
import {RootState} from '../../../../redux/store'

const InventoryAdjustment = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState<any>({})
  const data = useSelector(
    (state: RootState) => state.inventory.inventoryAdjustment.inventoryAdjustment
  )
  const loading = useSelector((state: RootState) => state.inventory.inventoryAdjustment.loading)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getInventoryAdjustments(currentUser?.organization)
    console.log('inventory adj', res)
    dispatch(setInventoryAdjustment(res))
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (loading) fetchData()
  }, [currentUser])

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className='card'>
      <div className='card-header d-flex align-items-center justify-between'>
        <h3>Inventory Adjustments</h3>
        {(currentUser?.profileType === 'superadmin' ||
          permissions?.inventory?.inventoryadjustments) && (
          <div className=''>
            <Link to={'add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              New
            </Link>
          </div>
        )}
      </div>
      <div className='card-body'>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Reason</th>
                  <th className='min-w-150px'>Description</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-100px'>Reference No.</th>
                  <th className='min-w-100px'>Type</th>
                  <th className='min-w-100px'>Created By</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data?.map((item: any, index) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.createdAt)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?.reason}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.description}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 uppercase'
                      >
                        {item?.status}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.referenceNo}
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                      >
                        {item?.mode}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.createdBy?.fullName}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </div>
  )
}

export default InventoryAdjustment
