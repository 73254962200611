import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import {
  getEquipmentsList,
  getIssuesByProductId,
  getRepairPriorityCodes,
  getServiceTasks,
  getVehiclesList,
  postServiceRecord,
} from '../../core/_requests'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {getVendor} from '../../../purchaseOrders/Core/_requests'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {productImage} from '../../../Inventory/Core/_requests'

const AddServiceEntry = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [issuesList, setIssuesList] = useState<any>([])
  const [serviceTaskList, setServiceTaskList] = useState<any>([])
  const [selectedIssues, setSelectedIssues] = useState<any>([])
  const [selectedServiceTasks, setSelectedServiceTasks] = useState<any>([])
  const [startDateReq, setStartDateReq] = useState<any>(false)
  const [vendorList, setVendorList] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [priorityList, setPriorityList] = useState<any>([])
  const [productType, setProductType] = useState<any>([])
  const [isVehicle, setIsVehicle] = useState<any>(true)
  const [selectedPhoto, setSelectedPhoto] = useState<any>(null)
  const [selectedDocument, setSelectedDocument] = useState<any>(null)
  const photoRef = useRef<any>(null)
  const documentRef = useRef<any>(null)
  const [prevOdoMeter, setPrevOdoMeter] = useState<any>({
    value: 0,
    date: new Date(),
  })
  const [formData, setFormData] = useState({
    rentalType: 'vehicle',
    product: '',
    priority: '',
    priorityId: '',
    completionDate: new Date(),
    startDate: null,
    reference: '',
    vendor: null,
    comments: '',
    notes: '',
    odometer: 0,
    laborCost: 0,
    partsCost: 0,
    subtotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
    photo: '',
    document: '',
  })

  const fetchProducts = async () => {
    if (formData?.rentalType === 'vehicle') {
      const res = await getVehiclesList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      const data1 = res?.map((product) => {
        return {
          label: product._id,
          rentalType: product?.rentalType,
          odometerReading: product?.odometerReading,
          odometerReadingDate: product?.odometerReadingDate,
        }
      })
      setProductType(data1)
      setFormData({...formData, product: data[0]?.value})
    } else {
      const res = await getEquipmentsList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      setFormData({...formData, product: data[0]?.value})
    }
  }

  const fetchVendors = async () => {
    const res = await getVendor('', currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.displayName,
      value: item._id,
    }))
    setVendorList(data)
  }

  const fetchServiceTasks = async () => {
    const res = await getServiceTasks(currentUser?.organization)
    console.log(res)
    const data = res?.map((item: any) => ({
      label: item.name,
      value: item._id,
      labor: 0,
      parts: 0,
    }))
    setServiceTaskList(data)
  }

  const fetchPriorityCodes = async () => {
    const res = await getRepairPriorityCodes(currentUser?.organization)
    const data = res?.map((item: any) => ({
      label: item.priorityName,
      value: item._id,
    }))
    setPriorityList(data)
    setFormData({...formData, priority: data[0]?.label, priorityId: data[0]?.value})
  }

  useEffect(() => {
    fetchVendors()
    fetchPriorityCodes()
    fetchServiceTasks()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [formData.rentalType])

  useEffect(() => {
    const priority = priorityList.find((item: any) => item.value === formData.priorityId)
    setFormData({...formData, priority: priority?.label})
  }, [formData.priorityId])

  const fetchIssuesByProduct = async () => {
    const res = await getIssuesByProductId(formData.product)
    console.log(res)
    setIssuesList(res)
  }

  const formatDaysAgo = (date: Date) => {
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
      return `${interval} years ago`
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
      return `${interval} months ago`
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
      return `${interval} days ago`
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
      return `${interval} hours ago`
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
      return `${interval} minutes ago`
    }
    return `${Math.floor(seconds) || 0} seconds ago`
  }

  useEffect(() => {
    if (formData.product !== '') {
      fetchIssuesByProduct()
    }
    setSelectedIssues([])
    const selectedproduct = productType.find((item: any) => item.label === formData.product)
    if (selectedproduct) {
      setPrevOdoMeter({
        value: selectedproduct?.odometerReading || 0,
        date: new Date(selectedproduct?.odometerReadingDate),
      })
      setFormData({...formData, odometer: 0})
    }
  }, [formData.product])

  useEffect(() => {
    // discount and tax are in percentage
    const subtotal = formData.laborCost + formData.partsCost
    const discount = (subtotal * formData.discount) / 100
    const tax = (subtotal * formData.tax) / 100
    const total = subtotal + tax - discount
    setFormData({...formData, subtotal, total})
  }, [formData.laborCost, formData.partsCost, formData.tax, formData.discount])

  useEffect(() => {
    const laborCost = serviceTaskList.reduce((acc: number, item: any) => acc + item.labor, 0)
    const partsCost = serviceTaskList.reduce((acc: number, item: any) => acc + item.parts, 0)
    setFormData({...formData, laborCost, partsCost})
  }, [serviceTaskList])

  useEffect(() => {
    // put the labor and parts cost value to 0 if the task is not selected
    const newServiceTaskList = serviceTaskList.map((item: any) => {
      if (!selectedServiceTasks.includes(item.value)) {
        return {...item, labor: 0, parts: 0}
      }
      return item
    })
    setServiceTaskList(newServiceTaskList)
  }, [selectedServiceTasks])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedIssues.length === 0) {
      alert('Please select at least one issue')
      return
    }
    if (isVehicle && formData.odometer <= 0) {
      alert('Please enter a valid odometer reading')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      issues: selectedIssues,
      serviceTask: serviceTaskList
        .filter((item: any) => selectedServiceTasks.includes(item.value))
        .map((item: any) => ({
          task: item.value,
          laborCost: item.labor,
          partsCost: item.parts,
        })),
      organization: currentUser?.organization,
      createdBy: currentUser?._id,
      company: currentUser?.company,
    }
    console.log(data)
    const res = await postServiceRecord(data)
    if (res) {
      navigate('/service/history')
    }
  }

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      setSelectedPhoto(null)
      setFormData({...formData, photo: ''})
      return
    }
    setSelectedPhoto(file)

    setLoading(true)

    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setFormData({...formData, photo: `${timestamp}${file.name}`})

    const res = await productImage(formData1)
    console.log(res)
    setLoading(false)
  }

  const handleDocumentUpload = async (e) => {
    const file = e.target.files[0]
    if (!file) {
      setSelectedDocument(null)
      setFormData({...formData, document: ''})
      return
    }
    setSelectedDocument(file)

    setLoading(true)

    const timestamp = Date.now()
    const formData1 = new FormData()
    formData1.append('image', file)
    formData1.append('name', `${timestamp}${file.name}`)

    setFormData({...formData, document: `${timestamp}${file.name}`})

    const res = await productImage(formData1)
    console.log(res)
    setLoading(false)
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h3>New Service Entry</h3>
        </div>
        <div className='card-body'>
          <label className='form-label'>Rental Type</label>
          <select
            className='form-select'
            value={formData.rentalType}
            onChange={(e) => {
              setFormData({...formData, rentalType: e.target.value})
              setIsVehicle(e.target.value === 'vehicle')
            }}
          >
            <option value='vehicle'>Vehicle</option>
            <option value='equipment'>Equipment</option>
          </select>
          <label className='form-label mt-3 required'>Product</label>
          <Select
            options={products}
            isSearchable
            value={products.find((item: any) => item.value === formData.product)}
            onChange={(e) => setFormData({...formData, product: e.value})}
          />
          <label className='form-label mt-3'>Repair Priority Class</label>
          <select
            className='form-select'
            value={formData.priorityId}
            onChange={(e) => setFormData({...formData, priorityId: e.target.value})}
          >
            {priorityList.map((item: any) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {isVehicle && (
            <>
              <label className='form-label mt-3 required'>Odometer</label>
              <input
                type='number'
                className='form-control'
                value={formData.odometer}
                onChange={(e) => setFormData({...formData, odometer: +e.target.value})}
              />
              <div className=''>
                <span className='text-muted'>
                  Last updated: {prevOdoMeter?.value} mi ({formatDaysAgo(prevOdoMeter?.date)})
                </span>
              </div>
            </>
          )}
          <label className='form-label mt-3 required'>Completion Date</label>
          <Datetime
            value={formData.completionDate}
            dateFormat='DD/MM/YYYY'
            onChange={(e: any) => setFormData({...formData, completionDate: e.toDate()})}
          />
          {/* checkbox for set start date */}
          <div className='form-check mt-3'>
            <input
              type='checkbox'
              className='form-check-input'
              id='startDate'
              checked={startDateReq}
              onChange={(e) => {
                setStartDateReq(e.target.checked)
                setFormData({...formData, startDate: e.target.checked ? new Date() : null})
              }}
            />
            <label className='form-check-label' htmlFor='startDate'>
              Set Start Date
            </label>
          </div>
          {startDateReq && (
            <>
              <label className='form-label mt-3'>Start Date</label>
              <Datetime
                value={formData.startDate}
                dateFormat='DD/MM/YYYY'
                onChange={(e: any) => setFormData({...formData, startDate: e.toDate()})}
              />
            </>
          )}
          <label className='form-label mt-3'>Reference</label>
          <input
            type='text'
            className='form-control'
            value={formData.reference}
            onChange={(e) => setFormData({...formData, reference: e.target.value})}
          />
          <label className='form-label mt-3'>Vendor</label>
          <Select
            options={vendorList}
            isSearchable
            value={vendorList.find((item: any) => item.value === formData.vendor)}
            onChange={(e) => setFormData({...formData, vendor: e.value})}
          />
          <div className='card mt-3'>
            <div className='card-body'>
              <h3>Issues</h3>
              <table className='table table-striped'>
                <thead>
                  <tr className='bg-primary text-white fw-semibold'>
                    <th className='ps-2'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        checked={selectedIssues.length === issuesList.length}
                        onChange={(e) =>
                          setSelectedIssues(
                            e.target.checked ? issuesList.map((issue: any) => issue._id) : []
                          )
                        }
                      />
                    </th>
                    <th>Priority</th>
                    <th>Issue</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <tbody>
                  {issuesList?.map((issue: any) => (
                    <tr key={issue._id}>
                      <td className='ps-2'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={selectedIssues.includes(issue._id)}
                          onChange={(e) =>
                            setSelectedIssues(
                              e.target.checked
                                ? [...selectedIssues, issue._id]
                                : selectedIssues.filter((id: string) => id !== issue._id)
                            )
                          }
                        />
                      </td>
                      <td className='uppercase'>{issue?.priority}</td>
                      <td>#{issue.issueId}</td>
                      <td>{issue.summary}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='card mt-3'>
            <div className='card-header d-flex justify-between align-items-center'>
              <h3>
                Line Items{' '}
                <span className='text-muted rounded-full bg-gray-300 p-1'>
                  {selectedServiceTasks.length}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <Select
                options={serviceTaskList}
                isMulti
                isSearchable
                value={serviceTaskList.filter((item: any) =>
                  selectedServiceTasks.includes(item.value)
                )}
                onChange={(e) => {
                  setSelectedServiceTasks(e.map((item: any) => item.value))
                }}
              />
              <table className='table table-striped my-3'>
                <thead>
                  <tr className='bg-primary text-white fw-semibold'>
                    <th className='ps-2 min-w-300px'>Task</th>
                    <th>Labor</th>
                    <th>Parts</th>
                    <th>Subtotal</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceTaskList
                    ?.filter((item: any) => selectedServiceTasks.includes(item.value))
                    ?.map((task: any) => (
                      <tr key={task.value}>
                        <td className='ps-2'>{task.label}</td>
                        <td>
                          <input
                            type='number'
                            className='form-control'
                            value={task.labor}
                            min={0}
                            onChange={(e) => {
                              const newServiceTaskList = serviceTaskList.map((item: any) =>
                                item.value === task.value ? {...item, labor: +e.target.value} : item
                              )
                              setServiceTaskList(newServiceTaskList)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control'
                            value={task.parts}
                            min={0}
                            onChange={(e) => {
                              const newServiceTaskList = serviceTaskList.map((item: any) =>
                                item.value === task.value ? {...item, parts: +e.target.value} : item
                              )
                              setServiceTaskList(newServiceTaskList)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control'
                            value={task.labor + task.parts}
                            disabled
                          />
                        </td>
                        <td>
                          <button
                            className='btn btn-danger btn-sm'
                            onClick={() => {
                              setSelectedServiceTasks(
                                selectedServiceTasks.filter((id: string) => id !== task.value)
                              )
                            }}
                          >
                            <KTIcon iconName='trash' />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className='row gx-10 mt-5'>
                <div className='col-8'>
                  {/* text area for notes */}
                  <label className='form-label'>General Notes</label>
                  <textarea
                    className='form-control'
                    placeholder='Add notes or additional details'
                    value={formData.notes}
                    onChange={(e) => setFormData({...formData, notes: e.target.value})}
                    rows={10}
                  />
                </div>
                <div className='col-4 d-flex flex-column gap-3'>
                  <h5>Cost Summary</h5>
                  {/* labor parts subtotal discount tax total */}
                  <div className='d-flex gap-2 justify-between mt-5'>
                    <label className='form-label mt-3'>Labor</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      min={0}
                      value={formData.laborCost}
                      onChange={(e) => setFormData({...formData, laborCost: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Parts</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      min={0}
                      value={formData.partsCost}
                      onChange={(e) => setFormData({...formData, partsCost: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Subtotal</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      value={formData.subtotal}
                      disabled
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Tax (%)</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      min={0}
                      value={formData.tax}
                      onChange={(e) => setFormData({...formData, tax: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3'>Discount (%)</label>
                    <input
                      type='number'
                      className='form-control w-75'
                      min={0}
                      value={formData.discount}
                      onChange={(e) => setFormData({...formData, discount: +e.target.value})}
                    />
                  </div>
                  <div className='d-flex gap-2 justify-between'>
                    <label className='form-label mt-3 fw-semibold'>Total</label>
                    <input
                      type='number'
                      className='form-control fw-semibold w-75'
                      value={formData.total}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* photo and document upload */}
          <div className='row gx-10 mt-4'>
            <div className='col-6'>
              <label className='form-label'>Upload Photo</label>
              <input
                type='file'
                accept='image/*'
                className='form-control'
                ref={photoRef}
                onChange={handlePhotoUpload}
              />
            </div>
            <div className='col-6'>
              <label className='form-label'>Upload Document</label>
              <input
                type='file'
                className='form-control'
                ref={documentRef}
                onChange={handleDocumentUpload}
              />
            </div>
          </div>
          <label className='form-label mt-3'>Comments</label>
          <textarea
            className='form-control'
            placeholder='Add an optional comment'
            value={formData.comments}
            onChange={(e) => setFormData({...formData, comments: e.target.value})}
          />
          {/* submit button */}
          <div className='my-3'>
            <button className='btn btn-primary' disabled={loading} onClick={handleSubmit}>
              Save Service Entry
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddServiceEntry
