import React, {useEffect, useState} from 'react'
import Select from 'react-select' // Make sure to import Select component from 'react-select' package
import Flatpickr from 'react-flatpickr'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {
  getOrderById,
  getPurchaseOrderByVendorId,
  getPurchaseReceiveLastId,
  getPurchaseReceiveLength,
  getVendor,
  getVendorByAgentId,
  postPurchaseReceive,
} from '../Core/_requests'
import {PurchaseOrder} from '../Core/_model'
import {useNavigate, useParams} from 'react-router-dom'
import {getNotes, getTerms} from '../../account_settings/core/_request'
import {useDispatch} from 'react-redux'
import {setPurchaseReceiveDataFetch} from '../../../redux/procurement/purchaseReceive/purchaseReceiveSlice'

export const AddPurchaseReceiveToOrder = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const params = useParams()
  const dispatch = useDispatch()
  const [contactPersonList, setContactPersonList] = useState([])
  const [contactPerson, setContactPerson] = useState('')
  const [loading, setLoading] = useState(false)
  const currentDate = new Date().toISOString().split('T')[0]
  const [permissions, setPermissions] = useState<any>({})
  const [purchaseReceive, setPurchaseReceive] = useState({
    issuer: 'EvoTech',
    date: currentDate,
    id: '', // Initialize with appropriate value
    subtotal: 0, // Initialize with appropriate value
    tax: 0, // Initialize with appropriate value
    total: 0, // Initialize with appropriate value
    notes: '', // Initialize with appropriate value
    termsCondition: '',
    reference: '',
    expectedDeliveryDate: '',
    paymentTerms: '',
    document: '',
    totalBalance: 0,
    totalInventory: 0,
  })
  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [purchaseOrder, setPurchaseOrder] = useState<any>([])
  const [selectedPurchaseQuotationId, setSelectedPurchaseQuotationId] = useState(null)
  const [selectedPurchaseQuotationLabel, setSelectedPurchaseQuotationLabel] = useState(null)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  const [selectedPurchaseQuotationItems, setSelectedPurchaseQuotationItems] = useState([
    {
      productName: '',
      unit: '',
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      received: 0,
      balance: 0,
      inTransit: 0,
    },
  ])

  //useState for Custom Purchase Receive no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const purchaseQuotationOptions = purchaseOrder?.purchaseQuotations
    ?.filter((quotation) => quotation.status === 'pending')
    ?.map((quotation) => ({
      value: quotation._id,
      label: quotation.id,
    }))
  console.log('ye hai selectyed', selectedPurchaseQuotationItems)
  console.log(purchaseOrder)
  const handlePurchaseQuotationChange = (selectedOption) => {
    setSelectedPurchaseQuotationId(selectedOption.value)
    setSelectedPurchaseQuotationLabel(selectedOption.label)

    // Find the selected purchase quotation and set its items to the state
    const selectedQuotation = purchaseOrder?.purchaseQuotations.find(
      (quotation) => quotation._id === selectedOption.value
    )

    if (selectedQuotation) {
      setSelectedPurchaseQuotationItems(selectedQuotation.items)
    }
  }

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
  }
  console.log(selectedVendor)

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setPurchaseReceive((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else setPurchaseReceive({...purchaseReceive, [name]: value})
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setPurchaseReceive((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  useEffect(() => {
    if (purchaseReceive.termsCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [purchaseReceive.termsCondition])

  useEffect(() => {
    if (purchaseReceive.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [purchaseReceive.notes])

  const getOrderDetails = async () => {
    const response = await getOrderById(params.id)
    const res = response[0]
    console.log(res)
    setSelectedVendor({value: res?.vendor?._id, label: res?.vendor?.displayName})
    setContactPersonList(
      res?.vendor?.contactPersons?.map((person) => ({
        value: person?._id,
        label: person?.firstName,
      })) || []
    )
    setContactPerson(res?.vendor?.contactPersons?.[0]?._id)
    setPurchaseReceive((prev) => ({
      ...prev,
      vendor: res?.vendor?._id,
    }))
  }

  useEffect(() => {
    if (params.id !== undefined) getOrderDetails()
  }, [params.id])

  useEffect(() => {
    const fetchPurchaseReceive = async () => {
      try {
        const PurchaseReceiveNotes = await getNotes(currentUser?.organization, 'Purchase Receipt')
        const PurchaseReceiveTerms = await getTerms(currentUser?.organization, 'Purchase Receipt')
        console.log('PurchaseReceiveTerms', PurchaseReceiveTerms)
        setPurchaseReceive({
          ...purchaseReceive,
          notes: PurchaseReceiveNotes[0]?.notes || '',
          termsCondition: PurchaseReceiveTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchPurchaseReceive()
  }, [])

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedVendor === null || selectedVendor === undefined) {
      alert('Please select a vendor')
      return
    } else if (selectedPurchaseQuotationId === null || selectedPurchaseQuotationId === undefined) {
      alert('Please select a purchase order')
      return
    }

    const hasValidReceive = selectedPurchaseQuotationItems.some((item) => item.received >= 1)
    if (!hasValidReceive) {
      alert('Please select at least 1 receive item')
      return
    }
    setLoading(true)

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      vendor: selectedVendor.value, // Replace 'VENDOR_ID' with the actual vendor ID
      contactPerson: contactPerson,
      purchaseOrder: selectedPurchaseQuotationId, // Replace 'PURCHASE_ORDER_ID' with the actual purchase order ID
      receivedDate: purchaseReceive.date,
      items: selectedPurchaseQuotationItems,
      totalBalance: purchaseReceive.totalBalance,
      totalInventory: purchaseReceive.totalInventory,
      notes: purchaseReceive.notes,
      termsCondition: purchaseReceive.termsCondition,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      approval: permissions?.purchasereceives?.enable ? 'accepted' : 'pending',
      status: purchaseReceive.totalBalance === 0 ? 'pending' : 'partial',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }

    console.log('Purchase receive submitted successfully:', data)
    try {
      const response = await postPurchaseReceive(data, params.id)
      console.log(response)
      dispatch(setPurchaseReceiveDataFetch())
      navigate(`/procurement/purchaseReceiveList`)
      // setSubmitted(true)
    } catch (error) {
      console.error('Error submitting purchase receive:', error)
    }
  }

  const getVendorList = async () => {
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      console.log('Vendor List:', data)
      setVendorList(
        data.map((r) => ({
          value: r._id,
          label: r.displayName, // Assuming name is the property containing the vendor's name
        }))
      )
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }
  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }
  const getPurchaseOrder = async (id) => {
    try {
      const data = await getPurchaseOrderByVendorId(id)
      console.log('Purchase Order List:', data)
      setPurchaseOrder(data)
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  useEffect(() => {
    if (selectedVendor?.value !== undefined) {
      getPurchaseOrder(selectedVendor?.value)
    }
    setSelectedPurchaseQuotationId(null)
    setSelectedPurchaseQuotationLabel(null)
    setSelectedPurchaseQuotationItems([])
  }, [selectedVendor])

  const getLength = async () => {
    try {
      const res = await getPurchaseReceiveLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.purchasereceives?.admin === false) {
      getVendorListForAgent()
    } else if (
      permissions?.purchasereceives?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getVendorList()
    }
  }, [permissions])

  useEffect(() => {
    getLength()
  }, [])
  console.log(selectedVendor)

  //functions for Custom Purchase Receive no
  const handleCloseModal = () => {
    setShowModal(false)
    // setCustomID('')
    // setPrefix('')
    // setNextNumber('')
    // getLength()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  const handleDateChange = (date) => {
    setPurchaseReceive({...purchaseReceive, date: date[0]})
  }
  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      {/* begin::Date */}
                      {/* <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div> */}
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        {/* <Flatpickr
                          value={purchaseReceive.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                        /> */}
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Purchase Receive number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>NEW PURCHASE RECEIVE #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        // value={purchaseReceive.id}
                        // placeholder='...'
                        disabled
                      /> */}

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Purchase Receive No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Purchase Receive numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Purchase Receive numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Purchase Receive numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Purchase Receive No. :</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Purchase Receive Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Vendor Name
                        </label>
                        {/* begin::Input group */}
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          name='vendor'
                          placeholder='Select a vendor'
                          value={selectedVendor?.label}
                          readOnly
                        />
                      </div>
                      {/* Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Contact Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(selectedOption) => setContactPerson(selectedOption.value)}
                          value={
                            contactPersonList.find((person) => person.value === contactPerson) ||
                            null
                          }
                        />
                      </div>
                    </div>
                    {/* Row */}

                    <div className='w-50 mt-3'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Purchase Order #
                      </label>
                      <Select
                        name='purchaseOrder'
                        aria-label='Select a Purchase Order'
                        placeholder='Select a Purchase Order'
                        options={purchaseQuotationOptions}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={handlePurchaseQuotationChange}
                        value={
                          selectedPurchaseQuotationLabel
                            ? {
                                value: selectedPurchaseQuotationId,
                                label: selectedPurchaseQuotationLabel,
                              }
                            : null
                        }
                      />
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Date</label>
                        {/* <input
                          type='text'
                          className='form-control form-control-solid '
                          name='date'
                          placeholder='DD-MM-YYYY'
                          value={purchaseReceive.date}
                          onChange={handleChange}
                        /> */}
                        <Flatpickr
                          value={purchaseReceive.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      {/* <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Expected Delivery Date</label>
                        <input
                          type='date'
                          className='form-control form-control-solid '
                          name='expecteddeliverydate'
                          placeholder='YYYY-MM-DD'
                          value={purchaseReceive.expectedDeliveryDate}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>ITEM</th>
                            <th className='min-w-150px w-250px'>QUANTITY</th>
                            <th className='min-w-70px w-150px'>RECEIVED</th>
                            <th className='min-w-50px w-250px'>ORDERED</th>
                            {/* <th className='min-w-70px w-150px'>BALANCE RECEIVED</th> */}
                            <th className='min-w-70px w-250px'>IN TRANSIT</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectedPurchaseQuotationItems.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0'
                                  value={item.quantity}
                                  disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='received'
                                  placeholder=''
                                  min='1'
                                  max={item.quantity}
                                  value={item.received}
                                  onChange={(e) => {
                                    const newItems = [...selectedPurchaseQuotationItems]
                                    newItems[index].received = Number(e.target.value)
                                    newItems[index].balance =
                                      newItems[index].quantity - Number(e.target.value)
                                    newItems[index].inTransit =
                                      (newItems[index].quantity - Number(e.target.value)) *
                                      item.price
                                    setSelectedPurchaseQuotationItems(newItems)
                                    setPurchaseReceive((prev) => {
                                      return {
                                        ...prev,
                                        totalBalance: newItems.reduce(
                                          (acc, item) => acc + item.balance,
                                          0
                                        ),
                                        totalInventory: newItems.reduce(
                                          (acc, item) => acc + Number(item.amount) - item.inTransit,
                                          0
                                        ),
                                      }
                                    })
                                  }}
                                  // disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='ordered'
                                  placeholder='0'
                                  value={item.quantity}
                                  disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='balance'
                                  placeholder='0'
                                  value={item.balance}
                                  disabled={true}
                                />
                              </td>

                              {/* <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='intransit'
                                  placeholder='0'
                                  value={item.inTransit}
                                  disabled={true}
                                />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className='mb-0 position-relative '>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={` form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter Notes'
                        value={purchaseReceive.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={purchaseReceive.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-8 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Save as Received
                    </button>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddPurchaseReceiveToOrder
