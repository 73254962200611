import { createSlice } from '@reduxjs/toolkit';

const orderManagementSlice = createSlice({
  name: 'orderManagement',
  initialState: {
    orderManagementData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setOrderManagementData(state, action) {
      state.orderManagementData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setOrderManagementData, setLoading, setError } = orderManagementSlice.actions;
export default orderManagementSlice.reducer;
