import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import profile from '../../../_metronic/assets/profile.png'
import qs from 'qs'
import {useAuth} from '../../modules/auth'
import {
  getEmployeeAttendanceByDate,
  getEmployeeByStatus,
  getEmployeeWebAttendanceByDate,
} from '../hrm/core/_requests'
import {Filtertype, initialSearchFilter, SearchFilter} from '../hrm/core/_model'

const EmployeeTable: React.FC<any> = ({tableHeight}) => {
  const {currentUser} = useAuth()
  const [employeeData, setEmployeeData] = useState<Array<any>>([])
  const [searchTerm] = useState<string>('')
  const [presentEmployees, setPresentEmployees] = useState([])

  const fetchAttendanceByDate = async () => {
    const data = {
      date: new Date(),
      orgid: currentUser?.organization,
    }
    if (currentUser?.attendanceMethod === 'mobile') {
      const res = await getEmployeeAttendanceByDate(data)
      setPresentEmployees(res?.present || [])
      // setAbsentEmployees(employeeData.filter((item) => !res?.presentIds?.includes(item._id)))
    } else if (currentUser?.attendanceMethod === 'web') {
      const res = await getEmployeeWebAttendanceByDate(data)
      setPresentEmployees(res?.presentIds || [])
      // setAbsentEmployees(employeeList.filter((item) => !res?.presentIds?.includes(item._id)))
    }
  }

  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.isActive,
        filter_dept: obj.filter.dept,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )
    return queryString
  }

  const getEmployeelist = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getEmployeeByStatus(queryString, currentUser?.organization)
      setEmployeeData(res)
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentUser.organization !== undefined) {
        getEmployeelist()
        fetchAttendanceByDate()
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [searchTerm])

  return (
    <>
      <div className='card h-100'>
        <span className=' card-label fw-bold fs-3 ps-15 pt-8 '>Employee Status</span>
        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className={`table-responsive h-${tableHeight}px overflow-y-scrol`}>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                  <th className='min-w-125px text-center'>Department</th>
                  <th className='min-w-125px text-center'>Roles</th>
                  <th className='min-w-125px text-center'>Attendance</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {employeeData &&
                  employeeData
                    // .filter((item) => item.isActivated === true)
                    .map((item, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={`/hrm/employee/view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'>
                            {item?.department?.name}
                          </div>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                        </td>
                        <td>
                          <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'>
                            {item?.role}
                          </div>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                        </td>
                        <td className='text-center'>
                          {presentEmployees.includes(item?._id) ? (
                            <span className='badge badge-light-primary fs-7 fw-semibold'>
                              Present
                            </span>
                          ) : (
                            <span className='badge badge-light-danger fs-7 fw-semibold'>
                              Absent
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export default EmployeeTable
