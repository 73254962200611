import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  paymentMadeData: [],
  totalPages: 1,
  loading: false,
  error: null,
  fetch: true,
}

const paymentMadeSlice = createSlice({
  name: 'paymentMade',
  initialState,
  reducers: {
    setPaymentMadeDataList(state, action) {
      state.paymentMadeData = action.payload.paymentsMade
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPaymentMadeDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setPaymentMadeDataList, setLoading, setError, setPaymentMadeDataFetch} =
  paymentMadeSlice.actions
export default paymentMadeSlice.reducer
