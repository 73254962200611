import React, {useEffect, useState} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {CgDanger} from 'react-icons/cg'
import {KTIcon} from '../../../../_metronic/helpers'
import Search from '../../documentation/Components/Search'
import {
  deleteEmployeeDepartmentById,
  getAllDepartment,
  getEmployeeDepartmentById,
} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {setDepartmentData} from '../../../redux/hrm/department/departmentSlice'

const Department = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  // const [originalData, setOriginalData] = useState([])
  const [selectedIndex, setSelectedIndex] = useState<any>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const originalData = useSelector((state: any) => state.hrm.department.departmentData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getAllDepartment(currentUser?.organization)
    console.log('Get all Department Data', res)
    setTableData(res)
    // setOriginalData(res)
    dispatch(setDepartmentData(res))
  }
  useEffect(() => {
    const filteredData = originalData?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setTableData((prevTableData) => (searchQuery.trim() === '' ? originalData : filteredData))
  }, [searchQuery, originalData])
  const handleClick = () => {
    navigate('/hrm/empDepartment/add')
  }

  const handleActionClick = async (action: string, e, deptId: string) => {
    e.preventDefault()
    console.log('action is', action)
    if (action === 'deactivate') {
      console.log('i am deleted')
      setSelectedIndex(deptId)
      setShowConfirmationModal(true)
      const response = await deleteEmployeeDepartmentById(deptId)
      if (response.status === 200) fetchData()
    } else if (action === 'edit') {
      try {// Fetch empDept data by ID
        navigate(`edit/${deptId}`)
      } catch (error) {
        console.error('Error fetching project:', error)
      }
    }
  }
  const handleDeleteToggle = (i) => {
    setSelectedIndex(i)
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div className='p-6 md:m-4  card  card-xxl-stretch'>
      <div className='d-flex  justify-between  card-label fw-bold fs-3 mb-1s '>Department List</div>
      <div className='d-flex  justify-between card-header py-5 '>
        <div className='d-flex  items-center relative space-x-3 mb-3 mb-md-0'>
          <form
            data-kt-search-element='form'
            className='w-100 position-relative '
            autoComplete='off'
          >
            <KTIcon
              iconName='magnifier'
              className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
            />
            <input
              type='text'
              className='form-control  border-0  ps-10 bg-gray-100'
              name='search'
              placeholder='Search...'
              data-kt-search-element='input'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form>
        </div>
        {(currentUser?.profileType === 'superadmin' || permissions?.employee?.create) && (
          <div className='card-toolbar'>
            <div onClick={handleClick} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              New Department
            </div>
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-25px'>No.</th>
                <th className='min-w-200px rounded-start'>Department</th>
                <th className='min-w-125px'>No. of Employees</th>
                <th className='min-w-150px'>No. of Roles</th>
                {(currentUser?.profileType === 'superadmin' || permissions?.employee?.update) && (
                  <th className='min-w-100px text-center'>Action</th>
                )}

                {/* <th className='min-w-200px text-end rounded-end'></th> */}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, i) => (
                  <tr key={i} style={{marginBottom: '10px'}}>
                    <td>{i + 1}</td>

                    <td>
                      <Link to={`${item._id}`}>
                        <span className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer'>
                          {item?.name}
                        </span>
                      </Link>
                    </td>
                    <td>{item?.employeeCount}</td>

                    <td>{Array.isArray(item.roles) ? item.roles.length : 1}</td>

                    {(currentUser?.profileType === 'superadmin' ||
                      permissions?.employee?.update) && (
                      <td className='text-center '>
                        <button
                          className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
                          type='button'
                          id='dropdownMenuButton'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          Actions
                          <i className='ki-duotone ki-down fs-5 ms-1'></i>
                        </button>
                        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                          <div
                            className='dropdown-item cursor-pointer'
                            onClick={(e: any) => {
                              handleActionClick('edit', e, item._id)
                            }}
                          >
                            {/* <a
                          className='dropdown-item'
                          href='#'
                          onClick={(e: any) => {
                            handleActionClick('edit', e, item._id)
                          }}
                        > */}
                            Edit
                            {/* </a> */}
                          </div>
                          {/* <div className='menu-item px-3'> */}
                          <button
                            className='dropdown-item cursor-pointer'
                            data-bs-toggle='modal'
                            data-bs-target='#myModal'
                            data-kt-users-table-filter='delete_row'
                            onClick={() => handleDeleteToggle(item._id)}
                          >
                            Deactivate
                          </button>
                          {/* </div> */}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className='modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>
            <div className='modal-body text-center fs-6'>
              Are you sure you want to Deactivate{' '}
              {originalData?.find((department) => department._id === selectedIndex)?.name}?
            </div>
            <div className='modal-footer text-center mx-auto'>
              <button
                type='button'
                className='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={(e: any) => {
                  handleActionClick('deactivate', e, selectedIndex)
                }}
              >
                Deactivate
              </button>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Department
