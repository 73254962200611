import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getProjectReportSubmissionById } from '../core/_requests';
// import { getProjectReportSubmissionById } from '../core/_requests';

const SubmissionDetails = () => {
    const { id } = useParams();
    const [submission, setSubmission] = useState(null);
    
  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const submissionData = await getProjectReportSubmissionById(id);
        setSubmission(submissionData);
      } catch (error) {
        console.error('Error fetching submission:', error);
      }
    };

    fetchSubmission();
  }, [id]);

  // If submission is not fetched yet, display loading or return null
  if (!submission) {
    return <div>Loading...</div>;
  }
  return (
    <div className="p-4 border border-gray-300 rounded">
      {/* Project Title */}
      <h2 className="text-2xl font-bold mb-4 border-b pb-2">{submission?.form?.title}</h2>
      
      {/* User Full Name and Date */}
      <div className="flex mb-4">
      <p className="mr-5 text-gray-[600] font-bold">User: {submission?.createdBy?.fullName}</p>
        <p className="mr-5 text-gray-[600] font-bold">Date: {new Date(submission?.createdAt).toLocaleDateString('en-GB')}</p>
      </div>

      {/* Questions and Answers */}
      <div className="space-y-6">
        
        {submission?.form?.questions?.map((question, index) => (
          <div key={question?._id} className="">
            {/* Question */}
            <p className="font-bold text-gray-800">Q{index + 1}: {question?.questionText}</p>
            {/* Answer */}
            <p className="text-blue-600">{submission?.answers[index]?.answer[0]}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubmissionDetails