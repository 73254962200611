import React, {useCallback, useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getVendorPerformance} from '../core/requests'
import VendorSummaryCard from './vendorSummaryCard'
import VendorStatBar from './vendorStatBar'
import {useDispatch, useSelector} from 'react-redux'
import {
  setDateRange,
  setLoading,
  setVendorPerformanceData,
} from '../../../redux/procurement/vendorPerformance/vendorPerformanceSlice'
import DateFilter from '../../account/components/DateFilter'
import {RootState} from '../../../redux/store'

const VendorPerformance: React.FC = () => {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const orgid = currentUser.organization
  const [vendorsData, setVendorsData] = useState([])
  const dispatch = useDispatch()
  const vendorData = useSelector(
    (state: RootState) => state.procurement.vendorPerformance.vendorData
  )
  const dateRange = useSelector((state: RootState) => state.procurement.vendorPerformance.dateState)
  const [dateState, setDateState] = useState({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  })
  const loading = useSelector((state: RootState) => state.procurement.vendorPerformance.loading)

  const fetchData = useCallback(async () => {
    try {
      if (dateState.startDate !== undefined && dateState.endDate !== undefined) {
        dispatch(setLoading())
        const data = await getVendorPerformance(orgid, dateState.startDate, dateState.endDate)
        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)
        setVendorsData(data)
        dispatch(setVendorPerformanceData(data))
        dispatch(setDateRange(dateState))
      }
    } catch (error) {
      console.error('Error fetching vendor performance data:', error)
    }
  }, [orgid, dateState, dispatch])

  useEffect(() => {
    if (loading) {
      fetchData()
    } else {
      setVendorsData(vendorData)
    }
  }, [orgid])

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    fetchData()
  }, [dateState.endDate, fetchData])

  // Slice the first 5 customers from the salesPerformanceData array
  const top5Vendors = vendorsData.filter((vendor) => vendor.isActivated === true).slice(0, 5)

  return (
    <div>
      <div className='flex-1'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 '>
          <div className='md:col-span-1 pb-4'>
            {/* Left card */}
            <div className='card h-fit'>
              {/* Card content */}

              {/* <!--begin::Chart widget 5--> */}
              <div className='card card-flush h-md-100'>
                <div id='kt_charts_widget_5' className='min-h-auto'>
                  <VendorStatBar vendors={top5Vendors} />
                </div>
              </div>
              {/* <!--end::Chart widget 5--> */}
            </div>
          </div>
          <div className='md:col-span-1 pb-4'>
            <div className='card card-flush h-md-100'>
              <div id='kt_charts_widget_5' className='min-h-auto'>
                <VendorSummaryCard vendors={top5Vendors} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card p-4 '>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-between align-items-center'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Vendors Performance</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
          </h3>
          <DateFilter setDateState={setDateState} dateState={dateState} />
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px'>Vendors </th>
                    <th className='min-w-140px'>No. of RFQ</th>
                    <th className='min-w-140px'>No. of Purchase Orders</th>
                    <th className='min-w-140px'>No. of Purchase Receives</th>
                    <th className='min-w-140px'>No. of Bills</th>
                    <th className='min-w-140px'>No. of Payment Made</th>
                    <th className='min-w-140px'>Total Business</th>
                    <th className='min-w-140px'>Performance Percentage</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {vendorsData
                    .filter((vendor) => vendor.isActivated === true)
                    .map((customer, index) => (
                      <tr key={index}>
                        <td className='text-start'>
                          <div className='d-flex align-items-start'>
                            <div className='symbol symbol-45px'></div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {customer.vendorName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>{customer.rfqCount}</td>
                        <td className='text-start'>{customer.purchaseOrderCount}</td>
                        <td className='text-start'>{customer.purchaseReceiveCount}</td>
                        <td className='text-start'>{customer.billCount}</td>
                        <td className='text-start'>{customer.paymentCount}</td>
                        <td className='text-start'>
                          {customer?.totalBusiness?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          {customer.currency}
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-muted me-2 fs-7 fw-semibold'>
                                {customer.performancePercentage.toFixed(2)}%
                              </span>
                            </div>
                            <div className='progress h-6px w-100'>
                              <div
                                className='progress-bar bg-info'
                                role='progressbar'
                                style={{width: `${customer.performancePercentage}%`}}
                              ></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default VendorPerformance
