import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getSalesOrderDetailById} from '../../Core/_requests'
import {getCompanyById} from '../../../setting/core/_requests'
import {useAuth} from '../../../../modules/auth'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'

interface IBankDetails {
  bankName: string
  accountNumber: string
  iBANNumber: string
}

function SalesOrder({companyLogo, color, setColor, minFooter}) {
  const {currentUser} = useAuth()
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const [vat, setVat] = useState(0)
  const [data, setSalesdata] = useState<any>({})
  const [page, setPage] = useState(1)

  const {id} = useParams()

  useEffect(() => {
    const getSalesdata = async () => {
      const res = await getSalesOrderDetailById(id)
      setSalesdata(res)
      if (res?.items?.length > 5) {
        const pages = Math.ceil((res?.items.length - 5) / 20)
        if ((res?.items.length - 12) / 20 > 12) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.customer?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.customer?.primaryContact)
      }
    }

    getSalesdata()
  }, [id])

  const [imageSize, setImageSize] = useState<boolean>(true)
  const [contactPerson, setContactPerson] = useState<any>({})
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const convertSARAmountToWords = (amount) => {
    const [riyals, halalas] = amount.toString().split('.').map(Number)
    const riyalsInWords = numberToWords(riyals)
    const halalasInWords = halalas ? numberToWords(halalas.toString().padEnd(2, '0')) : ''

    return `${riyalsInWords} Riyals${halalasInWords ? ` and ${halalasInWords} Halalas` : ''} Only`
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  const [bank, setBank] = useState<IBankDetails>({
    bankName: '',
    accountNumber: '',
    iBANNumber: '',
  })

  useEffect(() => {
    const getCompany = async () => {
      const res = await getCompanyById(currentUser.company)
      setColor(res?.salesColor)

      setBank({
        bankName: res?.bankName || '',
        accountNumber: res?.accountNumber || '',
        iBANNumber: res?.iBANNumber || '',
      })
    }

    getCompany()
  }, [setColor, currentUser.company])

  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div className='px-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row '>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <div className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </div>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-[1px]'
              style={{
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-2 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-5 text-muted'>
              VAT:{data?.company?.vat} <span style={{color: color}}>|</span> C.R :{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='border-b-black border-b mx-9'></div>
      <div className='card-body px-lg-15'>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              <div className=''>
                {idx === 0 && (
                  <div className='mb-6 fs-5 fw-bold text-gray-800'>
                    <div className='uppercase'>Sales Order</div>
                  </div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row fs-7 text-black'
                      style={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <div className=' mb-5'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Order NO</span>
                          <span className=''>{data?.reference}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Customer</span>

                          <span className=''>{data?.customer?.companyName}</span>
                        </div>
                        <div className='d-flex flex-md-root flex-column mb-5'>
                          <span className='fw-bold mb-1'>Customer Address</span>
                          <span className=' mw-250px'>
                            {data?.customer?.billingAddress.addressLine1}{' '}
                            {data?.customer?.billingAddress.addressLine2}
                            <br />
                            {data?.customer?.billingAddress.city}{' '}
                            {data?.customer?.billingAddress.postalCode}{' '}
                            {data?.customer?.billingAddress.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Attention</span>
                          <span className=''>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Designation</span>
                          <span className=''>{contactPerson?.designation}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Delivery Method</span>
                          <span className=''>{data?.deliveryMethod}</span>
                        </div>
                      </div>
                      <div className='text-start  mb-5 '>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Order Date</span>

                          <span className=''>{formatCreatedAt(data?.salesOrderDate)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Sales Number</span>

                          <span className=''>{data?.id}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      <table
                        className='table table-bordered m-0'
                        style={{
                          borderColor: '#020617',
                        }}
                      >
                        <thead>
                          <tr
                            className='fs-7 fw-bold uppercase'
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            <th className='min-w-25px text-white text-center pb-3 '>
                              <span>
                                <span className='arabic-font'>رقم سري</span>
                                <br />
                                S.No.
                              </span>
                            </th>

                            <th className='min-w-175px text-white text-center pb-3 '>
                              <span className='arabic-font'>وصف</span>
                              <br />
                              Description
                            </th>

                            <th className='min-w-70px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية</span>
                              <br />
                              Quantity
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>وحدة</span>
                              <br />
                              UOM
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>سعر الوحدة</span>
                              <br />
                              Unit Price
                            </th>

                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>المجموع</span>
                              <br />
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody className='' style={{height: '250px'}}>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 12 : idx * 20 - 8,
                              idx === 0 ? 12 : idx * 20 + 12
                            )
                            .map((item, index) => (
                              <tr key={index} className='fw-semibold text-black fs-8 text-center'>
                                <td className='text-black'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 13
                                    : index + idx * 20 - 7}
                                </td>
                                <td className='text-justify text-black'>{item?.productName}</td>
                                <td className='text-black'>{item.quantity}</td>
                                <td className='uppercase text-black'>
                                  {item?.unit ||
                                    item?.itemId?.unit ||
                                    item?.itemsId?.unit ||
                                    'units'}
                                </td>
                                <td className='text-end text-black'>
                                  {Number(item?.price).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                                <td className='text-end text-black'>
                                  {Number(item?.amount).toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </td>
                              </tr>
                            ))}
                          {(idx + 1 === page || page === 1) && (
                            <>
                              <tr className=''>
                                <td colSpan={3} className='p-0 m-0'>
                                  <table className='table table-bordered w-full m-0 p-0 border-transparent'>
                                    <tbody className='min-w-80px '>
                                      <tr className='p-0 '>
                                        <td className='text-start flex items-center mt-6 justify-center '>
                                          <div className=''>
                                            <h6 className='mb-3 fw-bolder text-black fs-7'>
                                              BANK DETAILS
                                            </h6>
                                            <div className='mb-2 d-flex '>
                                              <div className='fw-semibold text-black fs-7 w-100px'>
                                                Bank Name:
                                              </div>
                                              <div className='fw-bold text-black fs-7'>
                                                {bank.bankName}
                                              </div>
                                            </div>
                                            <div className='mb-2 d-flex '>
                                              <div className='fw-semibold text-black fs-7 w-100px'>
                                                Account Number:
                                              </div>
                                              <div className='fw-bold text-black fs-7'>
                                                {bank.accountNumber}
                                              </div>
                                            </div>
                                            <div className='mb-2 d-flex'>
                                              <div className='fw-semibold text-black fs-7 w-100px'>
                                                IBAN Number:
                                              </div>
                                              <div className='fw-bold text-black fs-7'>
                                                {bank.iBANNumber}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td colSpan={2} className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 uppercase fs-7 text-black'>
                                    <div
                                      className='border-b px-3 py-6 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>Sub Total</p>
                                    </div>
                                    <div
                                      className='border-b px-3 py-6 text-right'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0'>Total VAT Amount ({data?.tax ?? 0}%)</p>
                                    </div>
                                    <div className='px-3 py-6 text-right'>
                                      <p className='font-bold mb-0'>Grand Total</p>
                                    </div>
                                  </div>
                                </td>
                                <td className='p-0 text-sm'>
                                  <div className='d-flex flex-column justify-between w-100 h-100 fs-7 text-black'>
                                    <div
                                      className='border-b px-3 py-6  d-flex justify-between'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='ps-3 mb-0'>{data?.customer?.currency}</p>
                                      <p className='text-end ps-0 mb-0'>
                                        {data?.subtotal?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </p>
                                    </div>
                                    <div
                                      className='border-b px-3 py-6  d-flex justify-between'
                                      style={{
                                        borderColor: '#020617',
                                      }}
                                    >
                                      <p className='mb-0 ps-3'>{data?.customer?.currency}</p>
                                      <p className='mb-0 text-end ps-0'>
                                        {vat?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </p>
                                    </div>
                                    <div className='px-3 py-6  d-flex justify-between'>
                                      <p className='mb-0 ps-3'>{data?.customer?.currency}</p>
                                      <p className='mb-0 text-end ps-0'>
                                        {data?.total?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}{' '}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                {/* Empty cell for design consistency */}
                              </tr>
                              <tr className='p-0 text-muted fs-7'>
                                <td colSpan={6} className='text-black'>
                                  <span className='fs-7 fw-bold text-black uppercase'>
                                    Total in words
                                  </span>
                                  <br />
                                  <span className='fw-bold'>{data?.customer?.currency}</span>{' '}
                                  {/* {numberToWords(data?.total)}
                                  {data?.customer?.currency === 'SAR' && ' Riyals'} */}
                                  {data?.customer?.currency === 'SAR'
                                    ? convertSARAmountToWords(data?.total)
                                    : `${numberToWords(data?.total)} Only.`}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx === page - 1 || page === 1) && (
            <div className={`m-0 p-2 mt-5`}>
              <div className='d-flex  flex-column fs-6'>
                <h6 className=' fw-bold text-danger'>Notes</h6>
                <p
                  className='min-h-40px text-black'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.notes?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>

                <h6 className=' fw-bold text-danger'>Terms</h6>
                <p
                  className='min-h-40px text-black'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.termsNCondition?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-gray-700 ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter && `Mobile : ${data?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white `}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center'>
        <p className='fs-7 mb-1'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default SalesOrder
