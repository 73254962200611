import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getVendorDetails} from '../Core/_requests'
import CommentsVendor from '../Components/CommentsVendor'
import TransactionVendor from '../Components/TransactionVendor'
import MailsVendor from '../Components/MailsVendor'
import StatementVendor from '../Components/StatementVendor'
import profile from '../../../../_metronic/assets/profile.png'
import {KTIcon} from '../../../../_metronic/helpers'

export const ViewVendor = () => {
  const {id} = useParams()
  const [vendorData, setVendorData] = useState([])
  const [property, setProperty] = useState('Overview')
  const [showRemark, setShowRemark] = useState(true)
  const [showAddress, setShowAddress] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [contactPerson, setContactPerson] = useState(false)
  const [recordInfo, setRecordInfo] = useState(false)
  const [invitePortal, setInvitePortal] = useState(false)

  const handleInvitePortal = (e) => {
    e.preventDefault()
    setInvitePortal(true)
  }

  const handleshowRemark = (e) => {
    e.preventDefault()
    setShowRemark(!showRemark)
  }

  const handleshowAddress = (e) => {
    e.preventDefault()
    setShowAddress(!showAddress)
  }

  const handleOtherDetails = (e) => {
    e.preventDefault()
    setShowDetails(!showDetails)
  }

  const handleContactPerson = (e) => {
    e.preventDefault()
    setContactPerson(!contactPerson)
  }

  const handleRecordInfo = (e) => {
    e.preventDefault()
    setRecordInfo(!recordInfo)
  }

  useEffect(() => {
    const getCustomer = async () => {
      const res = await getVendorDetails(id)
      console.log(res)
      setVendorData(res)
    }

    getCustomer()
  }, [])

  return (
    <div>
      <form id='kt_ecommerce_add_product_form' className='form d-flex flex-column flex-lg-row'>
        <div className='d-flex flex-column gap-7 m-4 my-12 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
          <div className='card card-flush '>
            <div className='card-header'>
              <div className='card-title'>
                <h4 className=' '> {vendorData?.companyName}</h4>
              </div>
            </div>

            <div className='card-body text-center  pt-0 '>
              <div className='d-flex justify-content-start  text-start'>
                {/* {vendorData?.profilePic === '' ? ( */}
                <div>
                  <img src={`${profile}`} alt='Metornic' className='w-20 h-20' />
                </div>
                {/* ) : (
                  <img
                    src={`${PF + data?.profilePic}`}
                    alt='Metornic'
                    className='object-fit-cover'
                  />
                )} */}
                {/* <img src={`${profile}`} alt='pic' className='object-fit-cover w-20 h-20' /> */}
                <div className='ms-3'>
                  <span className='text-indigo-950 fw-bold text-hover-primary '>
                    {vendorData?.displayName}
                  </span>
                  <span className='text-gray-700  fs-6 mb-0'>
                    <p className=' mb-0'>{vendorData?.emailAddress}</p>
                  </span>
                  <span className='text-gray-700 fs-6 mt-0'>
                    {vendorData?.contactNumbers?.workPhone}
                  </span>
                  <div className='fs-8 text-muted'>
                    <a href='#' data-bs-toggle='modal' data-bs-target='#example'>
                      Edit
                    </a>
                    |
                    <a
                      href='#'
                      data-bs-toggle='modal'
                      data-bs-target='#exampleModal'
                      onClick={handleInvitePortal}
                    >
                      {invitePortal ? 'Re-Invite  ' : ' Invite to Portal'}
                    </a>
                    |<a href='#'> Delete </a>
                  </div>

                  <div
                    class='modal fade'
                    id='example'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabe'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog modal-lg'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <h1 class='modal-title fs-5' id='exampleModalLabe'>
                            Edit Contact Person
                          </h1>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body'>
                          {/* <EditContact
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleReset={handleReset}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class='modal fade '
                    id='exampleModal'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body text-center '>Your Invitation has been Sent.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleshowRemark}
                  className='w-full d-flex justify-between card-title'
                >
                  REMARKS
                  {showRemark ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>
                <hr className='text-muted' />

                {showRemark && <p>Good customer,Late fee</p>}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleshowAddress}
                  className='w-full d-flex justify-between card-title'
                >
                  ADDRESS
                  {showAddress ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {showAddress && (
                  <div>
                    <div>
                      <div className='card-title mt-4 relative'> Billing Address</div>
                      <button type='button' data-bs-toggle='modal' data-bs-target='#exampleModal2'>
                        <KTIcon iconName='pencil' className='absolute right-9' />
                      </button>
                      <p className='m-0'>{vendorData?.billingAddress?.attention}</p>
                      <p className='m-0'>{vendorData?.billingAddress?.addressLine1}</p>
                      <p className='m-0'>{vendorData?.billingAddress?.addressLine2}</p>
                      <p className='m-0'> {vendorData?.billingAddress?.state}</p>
                      <p className='m-0'> {vendorData?.billingAddress?.city}</p>
                      <p className='m-0'> {vendorData?.billingAddress?.postalCode}</p>
                      <p className=' m-0'>{vendorData?.billingAddress?.phone}</p>
                    </div>
                    <div>
                      <div className='card-title mt-4 relative'>Shipping Address </div>
                      <button type='button' data-bs-toggle='modal' data-bs-target='#exampleModal3'>
                        <KTIcon iconName='pencil' className='absolute right-9' />
                      </button>
                      <p className='m-0'>{vendorData?.shippingAddress?.attention}</p>
                      <p className='m-0'>{vendorData?.shippingAddress?.addressLine1}</p>
                      <p className='m-0'>{vendorData?.shippingAddress?.addressLine2}</p>
                      <p className='m-0'> {vendorData?.shippingAddress?.state}</p>
                      <p className='m-0'> {vendorData?.shippingAddress?.city}</p>
                      <p className='m-0'> {vendorData?.shippingAddress?.postalCode}</p>
                      <p className=' m-0'>{vendorData?.shippingAddress?.phone}</p>
                    </div>
                  </div>
                )}

                <div
                  class='modal fade'
                  id='exampleModal2'
                  tabindex='-1'
                  aria-labelledby='exampleModalLabel2'
                  aria-hidden='true'
                >
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header'>
                        <h1 class='modal-title fs-5' id='exampleModalLabel2'>
                          Billing Address
                        </h1>
                        <button
                          type='button'
                          class='btn-close'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        ></button>
                      </div>
                      <div class='modal-body'>
                        {/* <BillingAddress
                          billingAddress={billingAddress}
                          handleBillingInputChange={handleBillingInputChange}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class='modal fade'
                  id='exampleModal3'
                  tabindex='-1'
                  aria-labelledby='exampleModalLabel3'
                  aria-hidden='true'
                >
                  <div class='modal-dialog'>
                    <div class='modal-content'>
                      <div class='modal-header'>
                        <h1 class='modal-title fs-5' id='exampleModalLabel3'>
                          Shipping Address
                        </h1>
                        <button
                          type='button'
                          class='btn-close'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        ></button>
                      </div>
                      <div class='modal-body'>
                        {/* <ShippingAddress
                          shippingAddress={shippingAddress}
                          handleShippingInputChange={handleShippingInputChange}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleOtherDetails}
                  className='w-full d-flex justify-between card-title'
                >
                  OTHER DETAILS
                  {showDetails ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {showDetails && (
                  <div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Customer Type</div>
                      <p className='m-0'>{vendorData?.customerType}</p>
                    </div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Default Currency</div>
                      <p className='m-0'>{vendorData?.currency}</p>
                    </div>
                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Payment Terms</div>
                      <p className='m-0'>{vendorData?.paymentTerms}</p>
                    </div>

                    <div className='d-flex justify-between items-center'>
                      <div className='card-title mt-4'> Portal Language</div>
                      <p className='m-0'>{vendorData?.portalLanguage}</p>
                    </div>
                  </div>
                )}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleContactPerson}
                  className='w-full d-flex justify-between card-title'
                >
                  <div>
                    <p>CONTACT PERSONS</p>
                  </div>
                  <div>
                    <button data-bs-toggle='modal' data-bs-target='#exampleModal4'>
                      <KTIcon iconName='plus-circle' className='text-primary' />
                    </button>
                    ({vendorData?.contactPersons?.length})
                  </div>
                  <div>
                    {contactPerson ? (
                      <KTIcon iconName='arrow-down' />
                    ) : (
                      <KTIcon iconName='arrow-up' />
                    )}
                  </div>

                  <div
                    class='modal fade'
                    id='exampleModal4'
                    tabindex='-1'
                    aria-labelledby='exampleModalLabel4'
                    aria-hidden='true'
                  >
                    <div class='modal-dialog modal-lg'>
                      <div class='modal-content'>
                        <div class='modal-header'>
                          <h1 class='modal-title fs-5' id='exampleModalLabel4'>
                            Edit Contact Person
                          </h1>
                          <button
                            type='button'
                            class='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          ></button>
                        </div>
                        <div class='modal-body'>{/* <AddContactPerson />{' '} */}</div>
                      </div>
                    </div>
                  </div>
                </button>

                <hr className='text-muted' />

                {contactPerson && <div>
                  {vendorData?.contactPersons.map((contactPerson, index) => (
                      <div className='d-flex justify-content-start text-start' key={index}>
                        <div>
                          <img src={`${profile}`} alt='Metornic' className='w-20 h-20' />
                        </div>
                        <div className='ms-3'>
                          <span className='text-indigo-950 fw-bold text-hover-primary '>
                            {contactPerson?.salutation}
                            {' ' + contactPerson?.firstName}
                            {' ' + contactPerson?.lastName}
                          </span>
                          <p className=' mb-0'>{contactPerson?.emailAddress}</p>
                          <p className=' '>{contactPerson?.workPhone}</p>
                        </div>
                      </div>
                    ))}
                    </div>}
              </div>

              <div className='card-title text-start mt-8 '>
                <button
                  onClick={handleRecordInfo}
                  className='w-full d-flex justify-between card-title'
                >
                  RECORD INFO
                  {recordInfo ? <KTIcon iconName='arrow-down' /> : <KTIcon iconName='arrow-up' />}
                </button>

                <hr className='text-muted' />

                {recordInfo && (
                  <div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4 '> Customer ID</div>
                      <p className='m-0 '>{vendorData?._id}</p>
                    </div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4'>Created on</div>
                      <p className='m-0'>{vendorData?.createdAt}</p>
                    </div>
                    <div className='d-flex justify-between items-center fs-8'>
                      <div className='card-title mt-4'>Created By</div>
                      <p className='m-0'>{vendorData?.displayName}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Overview' ? ' active' : ''}`}
                  onClick={() => setProperty('Overview')}
                >
                  Overview
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Comments' ? ' active' : ''}`}
                  onClick={() => setProperty('Comments')}
                >
                  Comments
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Transaction' ? ' active' : ''}`}
                  onClick={() => setProperty('Transaction')}
                >
                  Transaction
                </div>
              </li>

              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Mails' ? ' active' : ''}`}
                  onClick={() => setProperty('Mails')}
                >
                  Mails
                </div>
              </li>
              <li className='nav-item'>
                <div
                  className={`nav-link text-active-primary me-6  
															${property === 'Statements' ? ' active' : ''}`}
                  onClick={() => setProperty('Statements')}
                >
                  Statements
                </div>
              </li>
            </ul>
          </div>
          {property === 'Overview' && (
            <div className='m-2 '>
              <div>
                {/* <h4 className=' text-muted'>Company</h4> */}
                {/* <p className=' font-semibold '>{data.name}</p> */}
                {/* <p className='leading-none text-muted '>{data.address}</p> */}
              </div>
              <div className='pt-6'>
                {/* <h4 className=' text-muted'>Description</h4> */}
                {/* <p className=' font-medium'>{data.description}</p> */}
              </div>

              <div className='card card-flush mb-7'>
                <div className='card-header pt-7'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800'> Basic Deatils</span>
                  </h3>
                </div>

                <div className='card-body pt-6'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                      <thead>
                        <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                          <th className=' text-start'>Full Name</th>
                          <th className=' '>Email Id</th>
                          <th className=' '>Company Name </th>
                          <th className=' '>Phone Number </th>
                        </tr>
                      </thead>

                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='card card-flush mb-7'>
                <div className='card-header pt-7'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold text-gray-800'>Other Details</span>
                  </h3>
                </div>

                <div className='card-body pt-6'>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed align-middle gs-0 gy-3 my-0'>
                      <thead>
                        <tr className='fs-7 fw-bold text-gray-400 border-bottom-0'>
                          <th className=' text-start'>Currency</th>
                          <th className=' '>Opening Balance</th>
                          <th className=' '>Payment Terms</th>
                          <th className=' '>Portal Language</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span className='text-indigo-950 fw-bold text-hover-primary '>
                                  {vendorData?.currency}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className=' '>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {vendorData?.openingBalance}
                            </span>
                          </td>
                          <td className=' '>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {vendorData?.paymentTerms}
                            </span>
                          </td>
                          <td className=''>
                            <span className='text-indigo-950 fw-bold fs-6'>
                              {vendorData?.portalLanguage}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {property === 'Comments' && <CommentsVendor className='card-xl-stretch mb-xl-8' />}

          {property === 'Transaction' && <TransactionVendor className='card-xl-stretch mb-xl-8' />}
          {property === 'Mails' && (
            <div className='m-3 '>
              <MailsVendor />
            </div>
          )}
          {property === 'Statements' && (
            <div className='m-3 '>
              <StatementVendor currency={vendorData?.currency}/>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
