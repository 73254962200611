import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {
  getCustomer,
  getCustomersByAgentId,
  getOrders,
  getOrdersByAgentId,
  postorder,
} from '../Core/_requests'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getFilteredProject, getFilteredProjectByAgentId} from '../../projects/core/_requests'
import {getEmployeeByStatus} from '../../hrm/core/_requests'
import {useDispatch, useSelector} from 'react-redux'
import {setOrderManagementData} from '../../../redux/sales/orderManangement/orderManagementSlice'
import {setActiveProjects} from '../../../redux/projects/projectSlice'
function OrderManagement() {
  const [orderName, setOrderName] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [customerList, setCustomerList] = useState<any>([])
  const [projectList, setProjectList] = useState<any>([])
  const [selectedProject, setselectedProject] = useState(null)
  const [selectedEmployee, setselectedEmployee] = useState(null)
  const [employeeList, setEmployeeList] = useState<any>([])
  const dispatch = useDispatch()
  const orderList = useSelector((state: any) => state.sales.orderManagement.orderManagementData)
  const projectListData = useSelector((state: any) => state.projects.projects.activeProjects)

  const {currentUser} = useAuth()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getClientList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getClientListForAgent = async () => {
    try {
      const res = await getCustomersByAgentId('', currentUser?._id)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getProjectList = async () => {
    const res = await getFilteredProject('', currentUser?.organization)
    console.log(res)
    const data = res?.map((r) => ({
      value: r?._id,
      label: r?.projectName,
    }))
    // add new data none and value as null
    data.unshift({value: null, label: 'None'})
    setProjectList(data)
    dispatch(setActiveProjects(data.slice(1)))
  }

  const getProjectListByAgentid = async () => {
    const res = await getFilteredProjectByAgentId('', currentUser?.organization)
    console.log(res)
    const data = res?.map((r) => ({
      value: r?._id,
      label: r?.projectName,
    }))
    // add new data none and value as null
    data.unshift({value: null, label: 'None'})
    setProjectList(data)
    dispatch(setActiveProjects(data.slice(1)))
  }

  useEffect(() => {
    if (permissions?.bills?.admin === false) {
      getorderslistForAgent()
      getClientListForAgent()
    } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
      getorderslist()
      getClientList()
    }
    if (permissions?.myproject?.read === true && permissions?.myproject?.admin === false) {
      if (projectListData.length === 0) {
        getProjectListByAgentid()
      } else {
        setProjectList([...projectListData, {value: null, label: 'None'}])
      }
    } else if (permissions?.myproject?.read === true || currentUser?.profileType === 'superadmin') {
      if (projectListData.length === 0) {
        getProjectList()
      } else {
        setProjectList([...projectListData, {value: null, label: 'None'}])
      }
    }
  }, [permissions])

  const getorderslist = async () => {
    const res = await getOrders(currentUser?.organization)
    // setOrderList(res)
    dispatch(setOrderManagementData(res))

    console.log(res)
  }

  const getorderslistForAgent = async () => {
    const res = await getOrdersByAgentId(currentUser?._id)
    // setOrderList(res)
    dispatch(setOrderManagementData(res))

    console.log(res)
  }

  const getEmployeeList = async () => {
    const res = await getEmployeeByStatus('', currentUser?.organization)
    console.log(res)
    const data = res?.map((r) => ({
      value: r?._id,
      label: r?.firstName + ' ' + r?.lastName,
    }))
    setEmployeeList(data)
  }

  useEffect(() => {
    // getorderslist()
    getEmployeeList()
  }, [])

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
  }

  const handleProjectChange = (selectedOption) => {
    setselectedProject(selectedOption)
  }

  const handleEmployeeChange = (selectedOption) => {
    setselectedEmployee(selectedOption)
  }

  const handleSubmit = async () => {
    if (orderName === '') {
      alert('Please enter order name')
      return
    }
    if (!selectedEmployee) {
      alert('Please select employee')
      return
    }
    if (!selectedBillFrom) {
      alert('Please select customer')
      return
    }
    const data: any = {
      orderName,
      employee: selectedEmployee ? selectedEmployee.value : null,
      customer: selectedBillFrom ? selectedBillFrom.value : null,
      project: selectedProject ? selectedProject.value : null,
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postorder(data).then((res) => {
        setOrderName('')
        setselectedEmployee(null)
        setselectedBillFrom(null)
        setselectedProject(null)
        getorderslist()
        console.log(res)

        const closeModalButton = document.getElementById('closeModalButton')
        if (closeModalButton) {
          closeModalButton.click()
        }
      })
    } catch (error) {}
  }

  return (
    <div className={`card h-md-50 mb-5 mb-xl-10`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Order Management</span>
        </h3>

        <div className='card-toolbar' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-100px rounded-start'>ID</th>
                <th className='min-w-200px'>Name</th>
                <th className='min-w-200px'>Employee</th>
                <th className='min-w-200px'>Customer</th>
                {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-200px'>Project</th>
                )}
                <th className='min-w-100px'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {orderList?.map((item: any) => (
                <tr key={item?._id?.toString()}>
                  <td>
                    <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                      <div className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item?.id}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item?.orderName || ''}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {(item?.employee?.firstName || '') + ' ' + (item?.employee?.lastName || '')}
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {item?.customer?.displayName}
                      </div>
                    </Link>
                  </td>
                  {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                    <td>
                      <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {item?.project?.projectName}
                        </div>
                      </Link>
                    </td>
                  )}

                  <td>
                    <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.status === 'open' ? 'badge-light-primary' : 'badge-light-success'
                          }`}
                        >
                          {item?.status}
                        </span>
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Order Management</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-6 col-md-12'>
                    <label className='required'>Order Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Order Name'
                      value={orderName}
                      onChange={(e) => setOrderName(e.target.value)}
                    />
                  </div>
                  <div className='form-group mb-6 col-md-12'>
                    <label className='required'>Select Employee</label>
                    <Select
                      name='employee'
                      aria-label='Select Employee'
                      placeholder='Select Employee'
                      options={employeeList}
                      isSearchable={true}
                      onChange={handleEmployeeChange}
                      value={selectedEmployee}
                    />
                  </div>
                  <div className='form-group mb-6 col-md-12'>
                    <label className='required'>Select Customer</label>
                    <Select
                      name='client'
                      aria-label='Select Customer'
                      placeholder='Select Customer'
                      options={customerList}
                      isSearchable={true}
                      onChange={handleBillFromChange}
                      value={selectedBillFrom}
                    />
                  </div>
                  {(permissions?.myproject?.read || currentUser?.profileType === 'superadmin') && (
                    <div className='form-group mb-6 col-md-12'>
                      <label>Select Project</label>
                      <Select
                        name='project'
                        aria-label='Select Project'
                        placeholder='Select Project'
                        options={projectList}
                        isSearchable={true}
                        onChange={handleProjectChange}
                        value={selectedProject}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderManagement
