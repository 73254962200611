import { createSlice } from '@reduxjs/toolkit';

const quotationSlice = createSlice({
  name: 'quotation',
  initialState: {
    quotationData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setQuotationData(state, action) {
      state.quotationData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setQuotationData, setLoading, setError } = quotationSlice.actions;
export default quotationSlice.reducer;
