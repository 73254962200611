import { createSlice } from '@reduxjs/toolkit';

const deliveryNoteSlice = createSlice({
  name: 'deliveryNote',
  initialState: {
    deliveryNoteData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDeliveryNoteData(state, action) {
      state.deliveryNoteData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setDeliveryNoteData, setLoading, setError } = deliveryNoteSlice.actions;
export default deliveryNoteSlice.reducer;
