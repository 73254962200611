/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {getOrderUpdates} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
type Props = {
  className?: string
}

const OrderUpdate: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>({})

  useEffect(() => {
    const fetchData = async () => {
      await getOrderUpdates(currentUser.organization).then((res) => {
        setData(res)
      })
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])
  return (
    <div className={`card ${className} h-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Order Updates</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        <div className='row d-flex justify-evenly h-100'>
          <div className='col-lg-5 h-100 shadow-md h-100'>
            <h3 className='py-2'>Products Ordered</h3>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100px text-start ps-1'>Product Name</th>
                    <th className='min-w-50px text-center'>Qty Ordered</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.purchaseOrders?.map((item, i) => (
                    <tr key={i} className=''>
                      <td className='text-muted fw-semibold text-start ps-1'>
                        {item?.productName}
                      </td>
                      <td className='fw-semibold text-center text-primary'>{item?.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-lg-5 h-100 shadow-md h-100'>
            <h3 className='py-2'>Products Received</h3>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-100px text-start ps-1'>Product Name</th>
                    <th className='min-w-50px text-center'>Qty Restocked</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.purchaseReceives?.map((item, i) => (
                    <tr key={i} className=''>
                      <td className='text-muted fw-semibold text-start ps-1'>
                        {item?.productName}
                      </td>
                      <td className='fw-semibold text-center text-success'>{item?.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* end::Body */}
    </div>
  )
}

export {OrderUpdate}
