import {createSlice} from '@reduxjs/toolkit'

const inspectionHistorySlice = createSlice({
  name: 'inspectionHistory',
  initialState: {
    inspectionHistoryData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setInspectionHistoryData(state, action) {
      state.inspectionHistoryData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setInspectionHistoryData, setLoading, setError} = inspectionHistorySlice.actions
export default inspectionHistorySlice.reducer
