import {useEffect, useState} from 'react'
import {useAuth} from '../../../../../app/modules/auth'
import {KTIcon} from '../../../../helpers'
import {getProjectTargets} from '../../../../../app/pages/projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../../app/redux/store'
import {setProjectTargets} from '../../../../../app/redux/projects/projectDashboardSlice'

const CardsWidget10 = () => {
  const dispatch = useDispatch()
  const projectTargets = useSelector((state: any) => state.projects.projectDashboard.projectTargets)
  const projectTargetsLoading = useSelector(
    (state: any) => state.projects.projectDashboard.projectTargetsLoading
  )
  const {currentUser} = useAuth()
  const orgid = currentUser.organization
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProjectTargets(orgid)
        setProjects(data)
        dispatch(setProjectTargets(data))
        console.log('project data', data)
      } catch (error) {
        console.error('Error fetching project  data:', error)
      }
    }

    if (projectTargetsLoading && projectTargets.length === 0) fetchData()
    else {
      setProjects(projectTargets)
    }
  }, [orgid])

  if (projectTargetsLoading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className='overflow-auto h-100' style={{minHeight: '400px'}}>
      <div className='card bg-white rounded-2xl px-3 py-2 h-100'>
        <div className='d-flex align-items-center gap-2'>
          <div className='rounded-circle bg-primary d-flex align-items-center justify-content-center m-2 p-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <g id='icons'>
                <g id='vuesax/linear/task'>
                  <g id='task'>
                    <path
                      id='Vector (Stroke)'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M8.54163 16.25C8.54163 15.9048 8.82145 15.625 9.16663 15.625H17.5C17.8451 15.625 18.125 15.9048 18.125 16.25C18.125 16.5952 17.8451 16.875 17.5 16.875H9.16663C8.82145 16.875 8.54163 16.5952 8.54163 16.25Z'
                      fill='white'
                    />
                    <path
                      id='Vector (Stroke)_2'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M8.54163 10.417C8.54163 10.0718 8.82145 9.79199 9.16663 9.79199H17.5C17.8451 9.79199 18.125 10.0718 18.125 10.417C18.125 10.7622 17.8451 11.042 17.5 11.042H9.16663C8.82145 11.042 8.54163 10.7622 8.54163 10.417Z'
                      fill='white'
                    />
                    <path
                      id='Vector (Stroke)_3'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M8.54163 4.58398C8.54163 4.23881 8.82145 3.95898 9.16663 3.95898H17.5C17.8451 3.95898 18.125 4.23881 18.125 4.58398C18.125 4.92916 17.8451 5.20898 17.5 5.20898H9.16663C8.82145 5.20898 8.54163 4.92916 8.54163 4.58398Z'
                      fill='white'
                    />
                    <path
                      id='Vector (Stroke)_4'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M6.27527 2.47505C6.51935 2.71913 6.51935 3.11486 6.27527 3.35893L3.77527 5.85893C3.5312 6.10301 3.13547 6.10301 2.89139 5.85893L2.05806 5.0256C1.81398 4.78152 1.81398 4.38579 2.05806 4.14172C2.30214 3.89764 2.69786 3.89764 2.94194 4.14172L3.33333 4.53311L5.39139 2.47505C5.63547 2.23097 6.0312 2.23097 6.27527 2.47505Z'
                      fill='white'
                    />
                    <path
                      id='Vector (Stroke)_5'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M6.27527 8.30806C6.51935 8.55214 6.51935 8.94786 6.27527 9.19194L3.77527 11.6919C3.5312 11.936 3.13547 11.936 2.89139 11.6919L2.05806 10.8586C1.81398 10.6145 1.81398 10.2188 2.05806 9.97472C2.30214 9.73065 2.69786 9.73065 2.94194 9.97472L3.33333 10.3661L5.39139 8.30806C5.63547 8.06398 6.0312 8.06398 6.27527 8.30806Z'
                      fill='white'
                    />
                    <path
                      id='Vector (Stroke)_6'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M6.27527 14.142C6.51935 14.3861 6.51935 14.7818 6.27527 15.0259L3.77527 17.5259C3.5312 17.77 3.13547 17.77 2.89139 17.5259L2.05806 16.6926C1.81398 16.4485 1.81398 16.0528 2.05806 15.8087C2.30214 15.5646 2.69786 15.5646 2.94194 15.8087L3.33333 16.2001L5.39139 14.142C5.63547 13.898 6.0312 13.898 6.27527 14.142Z'
                      fill='white'
                    />
                  </g>
                </g>
              </g>
            </svg>{' '}
          </div>
          <div className='fw-bold fs-4'>Targets</div>
        </div>
        {projects.map((project) => (
          <div key={project.projectId} className='d-flex flex-column mt-3 text-muted'>
            <div className='border-bottom border-solid border-gray-100 pb-2 border-gray-300 border-bottom'>
              <div className='fw-bold text-black'>{project.projectName}</div>
              <div className='d-flex justify-content-between'>
                <div className='text-muted'>{project.projectDescription}</div>
                <div className='d-flex align-items-center gap-1  fw-thin'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='74'
                    height='9'
                    viewBox='0 0 96 9'
                    fill='none'
                  >
                    <g id='Rectangle 41947'>
                      <path
                        d='M4.5 0.5C2.29086 0.5 0.5 2.29086 0.5 4.5C0.5 6.70914 2.29086 8.5 4.5 8.5H63.2704V0.5H4.5Z'
                        fill='#2196f3'
                      />
                      <path
                        d='M91.5 8.5C93.7091 8.5 95.5 6.70914 95.5 4.5C95.5 2.29086 93.7091 0.5 91.5 0.5H63.2704V8.5H91.5Z'
                        fill='#2196f3'
                        fill-opacity='0.25'
                      />
                    </g>
                  </svg>
                  <div className='fs-9 px-1  text-gray-500'>
                    {' '}
                    {`${project.completedTargets}/${project.totalTargets} Targets`}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mt-3">
            <div className="fw-bold text-black">Food delivery app</div>
            <div className="d-flex justify-content-between">
              <div className="text-muted">Design and Dev.</div>
              <div className="d-flex align-items-center gap-1 text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="9" viewBox="0 0 96 9" fill="none">
                  <g id="Rectangle 41947">
                    <path d="M4.50003 0.500028C2.29088 0.500012 0.5 2.29088 0.5 4.50003C0.5 6.70916 2.29084 8.50001 4.49997 8.50003L35.5 8.50024V0.500244L4.50003 0.500028Z" fill="#2196f3" />
                    <path d="M91.5 8.50002C93.7092 8.50001 95.5 6.70915 95.5 4.50002C95.5 2.29087 93.7091 0.500007 91.5 0.500016L35.5 0.500244V8.50024L91.5 8.50002Z" fill="#2196f3" fill-opacity="0.25" />
                  </g>
                </svg>
                <div className="fw-thin fs-9 px-1 text-gray-500 text-sm">5/18 Tasks</div>
              </div>
            </div>
          </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export {CardsWidget10}
