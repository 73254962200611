import {createSlice} from '@reduxjs/toolkit'

const issueSlice = createSlice({
  name: 'issue',
  initialState: {
    issueData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setIssueData(state, action) {
      state.issueData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setIssueData, setLoading, setError} = issueSlice.actions
export default issueSlice.reducer
