import * as Yup from 'yup'
interface Address {
  attention: string
  country: string
  region: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  postalCode: string
  phone: string
  faxNumber?: string
}

interface ContactPerson {
  salutation?: string
  firstName: string
  lastName: string
  emailAddress?: string
  workPhone?: string
  mobile?: string
  designation?: string;
  department?: string;
}

export interface CustomerInterface {
  //  _id:string;
  customerType: string
  primaryContact: {
    salutation: string
    firstName: string
    lastName: string
  }
  companyName: string
  displayName: string
  vatNumber:string
  emailAddress?: string
  contactNumbers?: {
    workPhone?: string
    mobilePhone?: string
  }
  currency: string
  openingBalance?: number
  paymentTerms?: string
  portalEnabled: boolean
  portalLanguage?: string
  document?: string
  billingAddress: Address
  sameAsBillingAddress: boolean

  shippingAddress: Address
  contactPersons: ContactPerson[]

  // contactSalutation: string
  // contactFirstName: string
  // contactLastName: string
  // contactEmail: string
  // contactWorkPhone: number
  // contactPersonalMobile: number
  // contactDesignation: string
  // contactDepartment: string

  createdAt?: Date
  updatedAt?: Date
}

// const addressSchema = Yup.object({
//   attention: Yup.string().required().label('Attention'),
//   country: Yup.string().required().label('Country'),
//   region: Yup.string().required().label('Region'),
//   addressLine1: Yup.string().required().label('Address Line 1'),
//   addressLine2: Yup.string().label('Address Line 2'),
//   city: Yup.string().required().label('City'),
//   state: Yup.string().required().label('State'),
//   postalCode: Yup.string().required().label('Postal Code'),
//   phone: Yup.string().required().label('Phone'),
//   faxNumber: Yup.string().label('Fax Number'),
// })

// const contactPersonSchema = Yup.object({
//   salutation: Yup.string().label('Salutation'),
//   firstName: Yup.string().required().label('First Name'),
//   lastName: Yup.string().required().label('Last Name'),
//   emailAddress: Yup.string().email().label('Email Address'),
//   workPhone: Yup.string().label('Work Phone'),
//   mobile: Yup.string().label('Mobile'),
// })

const createCustomerSchemas = [
  Yup.object({
    // customerType: Yup.string().required().label('Customer Type'),
  }),
  Yup.object({
    // customerType: Yup.string().required().label('Customer Type'),
    primaryContact: Yup.object().shape({
      salutation: Yup.string().required("Salutation is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last name is required"),
    }),
    companyName: Yup.string().required("Company Name is required"),
    displayName: Yup.string().required("Display Name is required"),
    vatNumber: Yup.string().required("VAT Number is required"),
    emailAddress: Yup.string().required("Customer email is required"),
    contactNumbers: Yup.object().shape({
      // workPhone: Yup.string().required("Work Phone Number is required"),
      mobilePhone: Yup.string().required("Mobile Phone Number is required"),
    }),
  }),
  
  // Yup.object({

  //     primaryContact: Yup.object({
  //         salutation: Yup.string().required().label('Primary Contact Salutation'),
  //         firstName: Yup.string().required().label('Primary Contact First Name'),
  //         lastName: Yup.string().required().label('Primary Contact Last Name'),
  //     }),
  //     emailAddress: Yup.string().email().label('Email Address'),

  // })
]

const inits: CustomerInterface = {
  // _id : '',
  customerType: 'individual',
  primaryContact: {
    salutation: '',
    firstName: '',
    lastName: '',
  },
  companyName: '',
  displayName: '',
  vatNumber:'',
  emailAddress: '',
  contactNumbers: {
    workPhone: '',
    mobilePhone: '',
  },
  currency: 'SAR',
  openingBalance: 0,
  paymentTerms: '',
  portalEnabled: false,
  portalLanguage: '',
  document: '',
  billingAddress: {
    attention: '',
    country: '',
    region: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    faxNumber: '',
  },
  sameAsBillingAddress: false,

  shippingAddress: {
    attention: '',
    country: '',
    region: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    faxNumber: '',
  },
  contactPersons: [
    {
      salutation: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      workPhone: '',
      mobile: '',
      designation: '',
      department: '',
    },
  ],

  // contactSalutation: '',
  // contactFirstName: '',
  // contactLastName: '',
  // contactEmail: '',
  // contactWorkPhone: 123,
  // contactPersonalMobile: 123,
  // contactDesignation: '',
  // contactDepartment: '',
}

export {createCustomerSchemas, inits}
