import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
// import { getDeals, getQuotes, getQuotesbyAgentId } from '../core/_requests';
import {KTIcon} from '../../../../_metronic/helpers'
import {getPayrollWithMonth} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setPayrollData} from '../../../redux/hrm/payroll/payrollSlice'

function PayrollPage() {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [payrollList, setpayrollList] = useState<any>([])
  const dispatch = useDispatch()
  const payrollList = useSelector((state: any) => state.hrm.payroll.payrollData)
  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchpayrollList = async () => {
    const res = await getPayrollWithMonth(currentUser?.organization)
    // setpayrollList(res)
    dispatch(setPayrollData(res))
    console.log(res)
  }

  useEffect(() => {
    fetchpayrollList()
  }, [])

  // function for formatting date to string of month and year
  // const formatDate = (date: any) => {
  //   const d = new Date(date)
  //   const month = d.toLocaleString('default', {month: 'long'})
  //   const year = d.getFullYear()
  //   return `${month} ${year}`
  // }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payroll</span>
        </h3>

        {(permissions?.payroll?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Payroll
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Month</th>
                <th className='min-w-150px text-center'>Total Payroll</th>
                <th className='min-w-150px text-center'>Total Amount</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {payrollList?.map((item: any, index) => (
                <tr key={item?._id?.toString()}>
                  <td>
                    <Link
                      to={`${item?._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?._id}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?.count}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`${item?._id}`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.totalSalary.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default PayrollPage
