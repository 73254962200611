/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import axios from 'axios'
import Swal from 'sweetalert2'
import {useDispatch} from 'react-redux'
import {resetStore} from '../../../../app/redux/resetAction'
const API_URL = process.env.REACT_APP_THEME_API_URL

const HeaderOrganizationsMenu: FC = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const dispatch = useDispatch()
  const [organizations, setOrganizations] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `${API_URL}/user-management/organization/company/${currentUser?.company}`
      )
      setOrganizations(res.data)
    }

    if (currentUser?.profileType === 'superadmin') {
      fetchData()
    }
  }, [currentUser])

  const handleOrganizationChange = (id: string) => {
    setCurrentUser({...currentUser, organization: id})
    dispatch(resetStore())
    Swal.fire({
      icon: 'success',
      title: 'Organization Switched Successfully',
      showConfirmButton: false,
      timer: 1500,
    })
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Organizations <span className='fs-8 opacity-75 ps-3'></span>
        </h3>
      </div>

      <div className='tab-content'>
        {/* showing organizations in 2 column grid in box format */}
        <div className='tab-pane active'>
          <div className='menu-item px-3'>
            <div className='menu-content pt-4 pb-4'>
              <div className='row g-4'>
                {organizations?.map((org, index) => (
                  <div key={index} className='col-6 hover:scale-105 transition-all'>
                    <button
                      type='button'
                      className={`d-flex w-100 h-100 align-items-center rounded p-3 bg-light-primary bg-active-primary ring-1 hover:ring-blue-700 ${
                        currentUser?.organization === org._id ? 'active text-white' : ''
                      }`}
                      onClick={() => handleOrganizationChange(org._id)}
                    >
                      <div className='d-flex flex-column flex-grow-1'>
                        <div className='fw-bolder fs-6'>{org.name}</div>
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderOrganizationsMenu}
