import React, {useEffect, useState} from 'react'
import Select from 'react-select' // Make sure to import Select component from 'react-select' package
import Flatpickr from 'react-flatpickr'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getPurchaseReceiveByPurchaseId, postPartialPurchaseReceive} from '../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setPurchaseReceiveDataFetch} from '../../../redux/procurement/purchaseReceive/purchaseReceiveSlice'

export const AddPartialPurchaseReceive = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const params = useParams()
  const dispatch = useDispatch()
  const [purchaseReceiveId, setPurchaseReceiveId] = useState('')
  const [vendorName, setVendorName] = useState('')
  const [purchaseId, setPurchaseId] = useState('')
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)
  const currentDate = new Date().toISOString().split('T')[0]
  const [permissions, setPermissions] = useState<any>({})
  const [purchaseReceive, setPurchaseReceive] = useState({
    issuer: 'EvoTech',
    date: currentDate,
    contactPerson: '',
    id: '', // Initialize with appropriate value
    subtotal: 0, // Initialize with appropriate value
    tax: 0, // Initialize with appropriate value
    total: 0, // Initialize with appropriate value
    notes: '', // Initialize with appropriate value
    termsCondition: '',
    reference: '',
    expectedDeliveryDate: '',
    paymentTerms: '',
    document: '',
    totalBalance: 0,
    totalInventory: 0,
  })
  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [purchaseOrder, setPurchaseOrder] = useState<any>([])
  const [selectedPurchaseQuotationId, setSelectedPurchaseQuotationId] = useState(null)
  const [selectedPurchaseQuotationLabel, setSelectedPurchaseQuotationLabel] = useState(null)

  const [selectedPurchaseQuotationItems, setSelectedPurchaseQuotationItems] = useState([
    {
      productName: '',
      unit: '',
      quantity: 0,
      price: 0,
      discount: 0,
      amount: 0,
      received: 0,
      balance: 0,
      inTransit: 0,
      max: 0,
      maxAmount: 0,
    },
  ])

  //useState for Custom Purchase Receive no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getPurchaseReceiveByPurchaseId(params.id)
    console.log(res)
    let totalBalance = 0
    const data = res.items?.map((item) => {
      totalBalance += item.balance
      return {
        productName: item.productName,
        unit: item?.unit || null,
        quantity: item.quantity,
        price: item.price,
        discount: item.discount,
        amount: item.amount,
        received: 0,
        balance: item.balance,
        inTransit: item.inTransit,
        itemId: item.itemId,
        itemsId: item.itemsId,
        max: item.balance,
        maxAmount: Number(item.amount),
      }
    })
    if (totalBalance === 0) {
      navigate(`/procurement/purchaseReceiveList`)
    }
    setSelectedPurchaseQuotationItems(data)
    setPurchaseReceiveId(res.id)
    setVendorName(res.vendor?.displayName)
    setSelectedVendor({value: res.vendor?._id, label: res.vendor?.displayName})
    setPurchaseId(res.purchaseOrder?.id)
    setSelectedPurchaseQuotationId(res.purchaseOrder?._id)
    setPurchaseReceive({
      ...purchaseReceive,
      totalBalance: totalBalance,
      totalInventory: 0,
      notes: res.notes,
      contactPerson: res?.contactPerson || '',
    })
    setOrderId(res.order || null)
  }

  useEffect(() => {
    if (params.id !== undefined) {
      fetchData()
    }
  }, [params])

  const handleChange = (e) => {
    const {name, value} = e.target
    setPurchaseReceive({...purchaseReceive, [name]: value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (selectedVendor === null || selectedVendor === undefined) {
      alert('Please select a vendor')
      return
    } else if (selectedPurchaseQuotationId === null || selectedPurchaseQuotationId === undefined) {
      alert('Please select a purchase order')
      return
    }
    setLoading(true)
    const data = {
      vendor: selectedVendor.value, // Replace 'VENDOR_ID' with the actual vendor ID
      purchaseOrder: selectedPurchaseQuotationId, // Replace 'PURCHASE_ORDER_ID' with the actual purchase order ID
      receivedDate: purchaseReceive.date,
      items: selectedPurchaseQuotationItems,
      totalBalance: purchaseReceive.totalBalance,
      totalInventory: purchaseReceive.totalInventory,
      notes: purchaseReceive.notes,
      currentId: purchaseReceiveId,
      prefix: prefix,
      customID: customID,
      id: nextNumber,
      approval: permissions?.purchasereceives?.enable ? 'accepted' : 'pending',
      // status: purchaseReceive.totalBalance === 0 ? 'pending' : 'partial',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
      orderId,
    }

    console.log('Purchase receive submitted successfully:', data)
    try {
      const response = await postPartialPurchaseReceive(data)
      console.log(response)
      dispatch(setPurchaseReceiveDataFetch())
      navigate(`/procurement/purchaseReceiveList`)
      // setSubmitted(true)
    } catch (error) {
      console.error('Error submitting purchase receive:', error)
    }
  }

  const handleDateChange = (date) => {
    setPurchaseReceive({...purchaseReceive, date: date[0]})
  }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    } else {
      setSalesOrderNumber(`${prefix}${nextNumber}`)
    }
  }, [prefix, nextNumber, customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <span className='fs-2x fw-bold text-gray-800'>
                        PARTIAL PURCHASE RECEIVE #{' '}
                      </span>
                      <span className='fs-2x fw-bold text-gray-800'>{purchaseReceiveId}</span>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Vendor Name
                      </label>
                      {/* begin::Input group */}
                      <span className='fs-6 fw-bold text-gray-800'>{vendorName}</span>
                    </div>

                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Purchase Order #{' '}
                      </label>
                      <span className='fs-6 fw-bold text-gray-800'>{purchaseId}</span>
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Date</label>
                        {/* <input
                          type='text'
                          className='form-control form-control-solid '
                          name='date'
                          placeholder='DD-MM-YYYY'
                          value={purchaseReceive.date}
                          onChange={handleChange}
                        /> */}
                        <Flatpickr
                          value={purchaseReceive.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      {/* <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Expected Delivery Date</label>
                        <input
                          type='date'
                          className='form-control form-control-solid '
                          name='expecteddeliverydate'
                          placeholder='YYYY-MM-DD'
                          value={purchaseReceive.expectedDeliveryDate}
                          onChange={handleChange}
                        />
                      </div> */}
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>ITEM</th>
                            <th className='min-w-150px w-250px'>QUANTITY</th>
                            <th className='min-w-70px w-150px'>RECEIVED</th>
                            <th className='min-w-50px w-250px'>ORDERED</th>
                            {/* <th className='min-w-70px w-150px'>BALANCE RECEIVED</th> */}
                            <th className='min-w-70px w-250px'>IN TRANSIT</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {selectedPurchaseQuotationItems.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0'
                                  value={item.quantity}
                                  disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='received'
                                  placeholder='0'
                                  min='0'
                                  max={item.max}
                                  value={item.received}
                                  onChange={(e) => {
                                    const newItems = [...selectedPurchaseQuotationItems]
                                    newItems[index].received = Number(e.target.value)
                                    newItems[index].balance =
                                      newItems[index].max - Number(e.target.value)
                                    newItems[index].inTransit =
                                      (newItems[index].max - Number(e.target.value)) * item.price
                                    setSelectedPurchaseQuotationItems(newItems)
                                    setPurchaseReceive((prev) => {
                                      return {
                                        ...prev,
                                        totalBalance: newItems.reduce(
                                          (acc, item) => acc + item.balance,
                                          0
                                        ),
                                        totalInventory: newItems.reduce(
                                          (acc, item) =>
                                            acc + Number(item.maxAmount) - item.inTransit,
                                          0
                                        ),
                                      }
                                    })
                                  }}
                                  // disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='ordered'
                                  placeholder='0'
                                  value={item.quantity}
                                  disabled={true}
                                />
                              </td>

                              <td className='pe-7'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='balance'
                                  placeholder='0'
                                  value={item.balance}
                                  disabled={true}
                                />
                              </td>

                              {/* <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='intransit'
                                  placeholder='0'
                                  value={item.inTransit}
                                  disabled={true}
                                />
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Notes (For Internal Use)
                      </label>
                      <input
                        type='textarea'
                        name='notes'
                        className='form-control form-control-solid'
                        placeholder=''
                        value={purchaseReceive.notes}
                        onChange={handleChange}
                      />
                    </div>
                    <div></div>

                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Save as Received
                    </button>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddPartialPurchaseReceive
