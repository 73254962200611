import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {getEmployeeReportSubmissionCount} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setReportSubmissionData} from '../../../redux/hrm/reportSubmission/reportSubmissionSlice'
import {useAuth} from '../../../modules/auth'

const ReportSubmissionSummary = () => {
  const dispatch = useDispatch()
  const {currentUser} = useAuth()

  const list = useSelector((state: any) => state.hrm.reportSubmission.reportSubmissionData)

  const fetchReportsSubmission = async () => {
    const res = await getEmployeeReportSubmissionCount(currentUser.organization)
    // setList(res)
    dispatch(setReportSubmissionData(res))
  }
  useEffect(() => {
    if (currentUser.organization !== undefined) fetchReportsSubmission()
  }, [currentUser])

  return (
    <div className='card h-100'>
      <div className='card-header d-flex align-items-center justify-between'>
        <h4 className=''>Employee Report Submission</h4>
      </div>
      <div className='card-body'>
        <div className='table-responsive overflow-y-scroll h-300px'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th className='min-w-125px'>Form Name</th>
                {/* <th className='min-w-125px'>Department</th>
                <th className='min-w-125px'>Role</th>
                <th className='min-w-125px'>Date</th> */}
                <th className='min-w-125px text-center'>Count</th>
                {/* <th className='min-w-125px'>Employee Email</th> */}
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/hrm/submissionReports/viewSubmissions/${item?._id}`}>
                        {item?.report?.title}
                      </Link>
                    </td>
                    {/* <td>{item?.department?.name}</td>
                    <td>{item?.report?.role}</td>
                    <td>{new Date(item?.report?.createdAt).toLocaleDateString('en-GB')}</td> */}
                    <td className='text-center'>{item?.count}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ReportSubmissionSummary
