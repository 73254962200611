import {createSlice} from '@reduxjs/toolkit'

const timeTrackerSlice = createSlice({
  name: 'timeTracker',
  initialState: {
    timeTrackerData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setTimeTrackerData(state, action) {
      state.timeTrackerData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setTimeTrackerData, setLoading, setError} = timeTrackerSlice.actions
export default timeTrackerSlice.reducer
