import axios, { AxiosResponse } from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const Terms_URL = `${API_URL}/terms`
const Notes_URL = `${API_URL}/notes`
console.log("Terms_URL")
console.log("Notes_URL")


export async function editTerms(orgid, name, terms) {
    try {
        const response = await axios.put(`${Terms_URL}/${orgid}/${name}`, { name, terms })
        return response.data
    } catch (error) {
        console.error('Edit Notes error:', error)
        throw error
    }
}

export async function editNotes(orgid, name, notes) {
    try {
        const response = await axios.put(`${Notes_URL}/${orgid}/${name}`, { name, notes })
        return response.data
    } catch (error) {
        console.error('Edit Terms error:', error)
        throw error
    }
}

export async function getTerms(orgid, name) {
    try {
        console.log(`${Terms_URL}`)
        const response = await axios.get(`${Terms_URL}/${orgid}/${name}`)
        return response.data
    } catch (error) {
        console.error('Get Terms error:', error)
        throw error
    }
}

export async function getNotes(orgid, name) {
    try {
        console.log(`${Terms_URL}`)
        const response = await axios.get(`${Notes_URL}/${orgid}/${name}`)
        return response.data
    } catch (error) {
        console.error('Get Notes error:', error)
        throw error
    }
}

export async function uploadNotes(name, notes) {
    try {
        console.log("name,notes", name, notes)
        console.log("name,notes", `${Notes_URL}`, name, notes)
        const response = await axios.post(`${Notes_URL}`, { name, ...notes })
        return response.data
    } catch (error) {
        console.error('Get Notes error:', error)
        throw error
    }
}

export async function uploadTerms(name, terms) {
    try {
        const response = await axios.post(`${Terms_URL}`, { name, ...terms })
        return response.data
    } catch (error) {
        console.error('Get Terms error:', error)
        throw error
    }
}


