import {combineReducers} from '@reduxjs/toolkit'
import payrollReducer from './payroll/payrollSlice'
import departmentReducer from './department/departmentSlice'
import employeeReducer from './employee/employeeSlice'
import timeTrackerReducer from './time-tracker/time-trackerSlice'
import leaveManagementReducer from './leaveManagement/leaveManagementSlice'
import reportSubmissionReducer from './reportSubmission/reportSubmissionSlice'
import hrmDocumentReducer from './hrmDocument/hrmDocumentSlice'

const rootReducer = combineReducers({
  payroll: payrollReducer,
  department: departmentReducer,
  employee: employeeReducer,
  timeTracker: timeTrackerReducer,
  leaveManagement: leaveManagementReducer,
  reportSubmission: reportSubmissionReducer,
  hrmDocument: hrmDocumentReducer,
})
export default rootReducer
