import React, { useState } from 'react'
import { KTIcon, KTSVG } from '../../../../_metronic/helpers'
import { deleteDocs } from '../Core/_requests'

type props = {
    id: string
    handlereset: () => void;
}

const DeleteDoc: React.FC<props> = ({id,handlereset}) => {
  const [showModal, setShowModal] = useState<any>(false);
    const handledelete = async () => {
        await deleteDocs(id).then((res) => {
            handlereset()
            setShowModal(false)
        })
        
       
        }
    
  return (
    <>
    <div
    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
    onClick={()=> setShowModal(true)}
>
    <KTIcon iconName='trash' className='fs-3' />
</div>

{
  showModal && (
    <div className='modal fade modal fade show d-block' tabIndex={-1}  role='dialog' aria-modal='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                onClick={()=> setShowModal(false)}
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handledelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
)}
</>
  )
}

export default DeleteDoc