import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import {EmployeeWrapper} from './Pages/EmployeeWrapper'
import {NewMember} from './Pages/NewMember'
import ViewEmployee from './Pages/ViewEmployee'
import PayrollPage from '../account/pages/PayrollPage'
import AddPayroll from '../account/components/AddPayroll'
import PayrollMonthTable from '../account/pages/PayrollMonthTable'
import EditEmployee from './Pages/EditEmployee'
import HrmWrapper from './Pages/HrmWrapper'
import DepartmentRoutes from './DepartmentRoutes'
import LeavePage from './AttendancePage'
import LeaveManagement from './LeaveManagement'
import TimeTrackerPage from './TimeTrackerPage'
import EmployeeReportsRoutes from './employeeReportsRoutes'
import HRMDocument from './Pages/HRMDocument'
import {FilesPage} from './Components/FilesPage'
import EmployeePerformance from './Pages/EmployeePerformance'

export default function HrmPage() {
  return (
    <Routes>
      <Route path='/dashboard' element={<HrmWrapper />} />
      <Route path='/employee/' element={<EmployeeWrapper />} />
      <Route path='/employee/newMember' element={<NewMember />} />
      <Route path='/employee/view-employee/:id' element={<ViewEmployee />} />
      <Route path='/employee/edit-employee/:id' element={<EditEmployee />} />
      <Route path='/payroll' element={<PayrollPage />} />
      <Route path='/payroll/add' element={<AddPayroll />} />
      <Route path='/payroll/:month' element={<PayrollMonthTable />} />
      <Route path='/empDepartment/*' element={<DepartmentRoutes />} />
      <Route path='/attendance/*' element={<LeavePage />} />
      <Route path='/leaveManagement/*' element={<LeaveManagement />} />
      <Route path='/time-tracker/*' element={<TimeTrackerPage />} />
      <Route path='/submissionReports/*' element={<EmployeeReportsRoutes />} />
      <Route path='/documents/' element={<HRMDocument />} />
      <Route path='/documents/:id' element={<FilesPage />} />
      <Route path='/performance' element={<EmployeePerformance />} />
    </Routes>
  )
}
