import React, {useEffect, useState} from 'react'
import {FieldArray, Field, useFormikContext, useField} from 'formik'
import Select from 'react-select'
import {useAuth} from '../../../../modules/auth'
import {getStocks} from '../../../sales/Core/_requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ICreateAccount} from '../AddProjectHelper'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setStockedProducts} from '../../../../redux/inventory/products/stockSlice'

export default function ProductAssign() {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const productList = useSelector((state: RootState) => state.inventory.stock.stockedProducts)
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [subTotal, setSubTotal] = useState(0)
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
    },
  ])
  // const {values} = useFormikContext()
  // console.log('values', values)

  const getProductlist = async () => {
    try {
      const res = await getStocks(currentUser?.organization)
      console.log(res)
      // setProductList((prev) => {
      //   const updatedData = [
      //     ...res.map((r) => ({
      //       value: r._id,
      //       label: r.productName, // Corrected the typo here from "labe" to "label"
      //       price: r.price,
      //       inWarehouseQuantity: r.inWarehouseQuantity,
      //       type: 'product',
      //     })),
      //   ]
      //   return updatedData
      // })
      const data = res.map((r) => ({
        value: r._id,
        label: r.productName,
        price: r.price,
        inWarehouseQuantity: r.inWarehouseQuantity,
        type: 'product',
      }))
      dispatch(setStockedProducts(data))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (productList.length === 0) {
      getProductlist()
    }
  }, [currentUser])

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity') {
      // console.log(value, items[index].selectedOption.inWarehouseQuantity)
      if (value > items[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      setFieldValue('items', updatedItems)
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
      }
      setFieldValue('items', updatedItems)
      return updatedItems
    })
  }

  useEffect(() => {
    const calculateSubTotal = () => {
      const total = items.reduce((acc, curr) => acc + parseFloat(curr.amount || '0'), 0)
      setSubTotal(total)
      setFieldValue('productSubTotal', total)
    }

    calculateSubTotal()
  }, [items, setFieldValue])

  console.log('product total', subTotal)

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  return (
    <div className='w-100'>
      <div className='pb-12 '>
        <h1 className='fw-bold text-dark'>Add Items for the Project</h1>

        <div className='text-muted fw-semibold fs-4'>
          If you need more info, please check{' '}
          <a href='#' className='link-primary'>
            Project Guidelines
          </a>
        </div>
      </div>

      <div className='mb-8'>
        <div className='fs-6 fw-bold text-gray-700 mb-3'>Items Table</div>
        <div className='table-responsive mb-10'>
          {/* begin::Table */}
          <table className='table g-5 gs-0 mb-0 fw-bold text-gray-700' data-kt-element='items'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                <th className='min-w-100px w-500px'>Item</th>
                <th className='min-w-150px w-175px'>Quantity</th>
                <th className='min-w-50px w-250px'>Rate</th>
                <th className='min-w-70px w-250px'>Amount</th>
                <th className='min-w-70px w-200px'>Action</th>
              </tr>
            </thead>
            {/* Table head */}
            {/* begin::Table body */}
            <tbody>
              {items.map((item, index) => (
                <tr
                  className='border-bottom border-bottom-dashed'
                  data-kt-element='item'
                  key={index}
                >
                  <td className='absolute w-[250px] pe-7'>
                    {/* begin::Input group */}
                    <Select
                      name='itemId'
                      aria-label='Select an Item'
                      placeholder='Select an Item'
                      options={productList}
                      isSearchable={true}
                      className='form-control form-control-solid'
                      onChange={(e) => handleSelectChangeInRows(index, e)}
                      value={item.selectedOption}
                      required
                    />
                  </td>
                  <td className='pe-7'>
                    {/* begin::Input group */}

                    <input
                      type='number'
                      className='form-control form-control-solid'
                      name='quantity'
                      placeholder='0.00'
                      max={item?.selectedOption?.inWarehouseQuantity}
                      min={0}
                      value={item.quantity}
                      onChange={(e) => handleChangerows(index, e)}
                    />
                  </td>

                  <td className='pe-7'>
                    {/* begin::Input group */}

                    <input
                      type='number'
                      className='form-control form-control-solid'
                      name='price'
                      placeholder='0.00'
                      disabled
                      value={item.price}
                      onChange={(e) => handleChangerows(index, e)}
                    />
                  </td>

                  <td>
                    <input
                      type='number'
                      className='form-control form-control-solid text-end'
                      name='amount'
                      placeholder='0.00'
                      value={item.amount}
                      disabled
                      // onChange={(e) => handleChangerows(index, e)}
                    />
                  </td>
                  <td>
                    <div
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                      onClick={additem}
                    >
                      <KTIcon iconName='plus-square' className='fs-3' />
                    </div>
                    <div
                      className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                      onClick={() => removeitem(index)}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* Table body */}
            {/* begin::Table foot */}
            <tfoot>
              <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                <th className='text-primary'></th>

                <th className='text-primary'></th>
                <th className='border-bottom border-bottom-dashed ps-0'>
                  <div className='d-flex flex-column align-items-end'>
                    <div className='fs-5'>Subtotal</div>
                  </div>
                </th>
                <th className='border-bottom border-bottom-dashed text-end'>
                  <span data-kt-element='sub-total' className=' '>
                    {subTotal.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}{' '}
                    {values.currency}
                  </span>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
