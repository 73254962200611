import {createSlice} from '@reduxjs/toolkit'

const vehicleRenewalSlice = createSlice({
  name: 'vehicleRenewal',
  initialState: {
    vehicleRenewalData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setVehicleRenewalData(state, action) {
      state.vehicleRenewalData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setVehicleRenewalData, setLoading, setError} = vehicleRenewalSlice.actions
export default vehicleRenewalSlice.reducer
