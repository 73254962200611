import { createSlice } from '@reduxjs/toolkit';

const invoiceApprovalSlice = createSlice({
  name: 'invoiceApproval',
  initialState: {
    invoiceApprovalData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setInvoiceApprovalData(state, action) {
      state.invoiceApprovalData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setInvoiceApprovalData, setLoading, setError } = invoiceApprovalSlice.actions;
export default invoiceApprovalSlice.reducer;
