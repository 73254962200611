import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getAccountsByCategory, updateActivationStatus} from '../core/_requests'
import {useAuth} from '../../../modules/auth'

const CategoryDetailPage = () => {
  const {currentUser} = useAuth()
  const id = useParams().id
  const [permissions, setPermissions] = useState<any>({})
  const [parentAccountName, setParentAccountName] = useState('Category of Accounts')
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getAccountsByCategory(id)
    console.log(res)
    setParentAccountName(res?.name)
    setTableData(res?.accounts)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleActivation = async (id, status) => {
    const res = await updateActivationStatus(id, {status: !status})
    if (res) {
      fetchData()
    }
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5 d-flex '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{parentAccountName}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px'>Account Name</th>
                <th className='min-w-150px'>Account Code</th>
                <th className='min-w-150px'>Account Type</th>
                {(currentUser?.profileType === 'superadmin' || permissions?.accountant?.update) && (
                  <th className='min-w-150px'>Action</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={item?._id}>
                  {/* show lock icon if fixed value is true */}
                  <td
                    className='ps-4 capitalize cursor-pointer fw-bold text-hover-primary mb-1'
                    // onClick={() => handleShowModal(index)}
                  >
                    <Link to={`/account/chartofaccounts/${item?._id}`}>{item?.accountName}</Link>
                  </td>
                  <td>{item?.accountCode}</td>
                  <td>{item?.accountType}</td>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.accountant?.create) && (
                    <td>
                      {!item?.fixed && (
                        <button
                          type='button'
                          className='btn btn-sm btn-color-dark btn-active-light-dark'
                          data-kt-menu-trigger='hover'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          Actions
                        </button>
                      )}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-125px'
                        data-kt-menu='true'
                        style={{zIndex: 1000000}}
                      >
                        {/* begin::Menu item */}

                        <div className='menu-item px-3 mt-1'>
                          <Link
                            to={`/account/chartofaccounts/edit/${item?._id}`}
                            className='menu-link px-3'
                          >
                            Edit
                          </Link>
                        </div>
                        <div className='separator mt-3 opacity-75'></div>
                        {item?.status ? (
                          <div
                            className='menu-item px-3'
                            onClick={() => handleActivation(item?._id, item?.status)}
                          >
                            <a href='#' className='menu-link px-3'>
                              Deactivate
                            </a>
                          </div>
                        ) : (
                          <div
                            className='menu-item px-3'
                            onClick={() => handleActivation(item?._id, item?.status)}
                          >
                            <a href='#' className='menu-link px-3'>
                              Activate
                            </a>
                          </div>
                        )}
                        <div className='separator mt-3 opacity-75'></div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default CategoryDetailPage
