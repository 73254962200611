import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {AddEvents, Events, EventsQueryResponse} from './_models'
import {array} from 'yup'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/task/events`
const GET_TODO_URL = `${API_URL}/task/events/`
const EVENTS_URL = `${API_URL}/task/events`
const GET_TODOS_URL = `${API_URL}/task/todo`
const ADD_TODO_LIST = `${API_URL}/task/todo`

const DELETE_TODO = `${API_URL}/task/todo`
const DELETE_EVENTS = `${API_URL}/task/events`

const BULKUPDATE_URL = `${API_URL}/client/bulkupload`
const CLIENTPIPECOUNT_URL = `${API_URL}/agent/pipelinetotallenght/`

// export const getClient = (query: string) => {
//     console.log(query)
//      return axios
//       .get(`${GET_USERS_URL}?${query}`)
//       .then((res: AxiosResponse<ClientQueryResponse>) => res.data)
//   }

export async function getevents(agentId: string): Promise<Array<any>> {
  try {
    const response = await axios.get(`${EVENTS_URL}/${agentId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export async function getAllevents(companyId: string): Promise<Array<any>> {
  try {
    const response = await axios.get(`${EVENTS_URL}/all/${companyId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export async function editEvent(todoId: string, data: any): Promise<Array<any>> {
  try {
    const response = await axios.put(`${EVENTS_URL}/${todoId}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export async function gettodo(agentId: string): Promise<Array<any>> {
  try {
    const response = await axios.get(`${GET_TODOS_URL}/${agentId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Events error:', error)
    throw error
  }
}

export async function gettodolist(agentId: string): Promise<Array<any>> {
  try {
    const response = await axios.get(`${GET_TODO_URL}${agentId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Todolist error:', error)
    throw error
  }
}

export async function addevents(formData, agentId) {
  try {
    const response = await axios.post(`${EVENTS_URL}/${agentId}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add agent error:', error)
    throw error
  }
}

export async function deletetodo(todoId) {
  try {
    const response = await axios.delete(`${DELETE_TODO}/${todoId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add agent error:', error)
    throw error
  }
}

export async function bulkUpdate(formData: any): Promise<any> {
  try {
    const response: AxiosResponse<string> = await axios.post(`${BULKUPDATE_URL}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function addtodolist(formData, agentId) {
  try {
    const response = await axios.post(`${ADD_TODO_LIST}/${agentId}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add agent error:', error)
    throw error
  }
}

export async function deleteevents(eventId) {
  try {
    const response = await axios.delete(`${DELETE_EVENTS}/${eventId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add agent error:', error)
    throw error
  }
}
