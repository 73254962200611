import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  stock: [],
  stockedProducts: [],
  salesStock: [],
  purchaseStock: [],
}

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setStock(state, action) {
      state.stock = action.payload
    },
    setStockedProducts(state, action) {
      state.stockedProducts = action.payload
    },
    setSalesStock(state, action) {
      state.salesStock = action.payload
    },
    setPurchaseStock(state, action) {
      state.purchaseStock = action.payload
    },
  },
})

export const {setStock, setStockedProducts, setSalesStock, setPurchaseStock} = stockSlice.actions
export default stockSlice.reducer
