import {createSlice} from '@reduxjs/toolkit'

const serviceReminderSlice = createSlice({
  name: 'serviceReminder',
  initialState: {
    serviceReminderData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setServiceReminderData(state, action) {
      state.serviceReminderData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setServiceReminderData, setLoading, setError} = serviceReminderSlice.actions
export default serviceReminderSlice.reducer
