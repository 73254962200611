import React, {useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  getFilteredQuotes,
  getFilteredQuotesByAgentId,
  updateValidationStatus,
} from '../Core/_requests'
import {RiArrowDropDownLine} from 'react-icons/ri'
import Filter2 from '../Components/Filter2'
import {Filtertype2, RootState, ValidFilter} from '../Core/_model'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'
import {setQuotationData} from '../../../redux/sales/quotation/quotationSlice'
import Pagination from '../../purchaseOrders/Components/Pagination'
import debounce from 'lodash/debounce'

function QuotesList() {
  const {currentUser} = useAuth()
  const {status} = useParams()
  const initialRef = useRef(true)
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredQuotesList, setFilteredQuotesList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const quotesList = useSelector((state: RootState) => state.sales.quotation.quotationData)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_acceptStatus: status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getdealslist = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredQuotes(queryString, currentUser?.organization, currentPage, 25)
      setFilteredQuotesList(res?.quotes)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.quotes?.length).fill(false))
      return res?.quotes
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const getQuotesbyAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredQuotesByAgentId(queryString, currentUser?._id, currentPage, 25)
      setFilteredQuotesList(res?.quotes)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.quotes?.length).fill(false))
      return res?.quotes
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getQuotesData = () => {
    if (permissions?.quotation?.admin === false) {
      getQuotesbyAgent()
    } else if (
      permissions?.quotation?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getdealslist()
    }
  }

  const getQuotes = () => {
    if (quotesList.length === 0) {
      if (permissions?.quotation?.admin === false) {
        getQuotesbyAgent().then((res) => dispatch(setQuotationData(res)))
      } else if (
        permissions?.quotation?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getdealslist().then((res) => dispatch(setQuotationData(res)))
      }
    } else {
      setFilteredQuotesList(quotesList)
      setDropdown(Array(quotesList.length).fill(false))
    }
  }
  // useEffect(() => {
  //   const filteredData = quotesList?.filter(
  //     (item) =>
  //       item?.customer?.displayName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.rfqNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //       item?.id?.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  //   setFilteredQuotesList(filteredData)
  // }, [searchQuery, quotesList])

  useEffect(() => {
    getQuotes()
  }, [permissions])

  const debounceSearch = debounce(getQuotesData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage, status])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleActivation = async (id, valid) => {
    const res = await updateValidationStatus(id, {valid: !valid})
    if (res) {
      if (permissions?.quotation?.admin === false) {
        getQuotesbyAgent()
      } else {
        getdealslist()
      }
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }
  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    getQuotes()
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Quotes</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Quote ID, RFQ number)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getQuotesData}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        {(permissions?.quotation?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/sales/quotes/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Quote
            </Link>
          </div>
        )}
      </div>

      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='min-w-150px ps-4'> Date</th>
                  <th className='ps-4 min-w-50px rounded-start'>Quote Number</th>
                  <th className='min-w-150px'>Reference Number</th>
                  <th className='min-w-150px'>Customer Name</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.quotation?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {filteredQuotesList?.map((lead: any, index: number) => (
                  <tr key={lead?._id?.toString()}>
                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(lead.date)?.toLocaleDateString('en-GB')}
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.rfqNumber}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.customer?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {lead?.total.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          {lead?.customer?.currency}
                        </span>
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={`/sales/quotes/${lead._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            lead?.approval === 'pending'
                              ? 'badge-light-primary'
                              : lead?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {lead?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.quotation?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            <Link to={`/sales/quotes/edit/${lead._id}`}>
                              <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link>
                            {!lead?.valid ? (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Valid
                              </button>
                            ) : (
                              <button
                                className='hover:bg-lime-50 p-2 text-start rounded'
                                onClick={() => handleActivation(lead._id, lead.valid)}
                              >
                                Invalid
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default QuotesList
