import {createSlice} from '@reduxjs/toolkit'

const leaveManagementSlice = createSlice({
  name: 'leaveManagement',
  initialState: {
    leaveManagementData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLeaveManagementData(state, action) {
      state.leaveManagementData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setLeaveManagementData, setLoading, setError} = leaveManagementSlice.actions
export default leaveManagementSlice.reducer
