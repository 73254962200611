import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import { getTargetByProjectId } from '../core/_requests'
import { useParams } from 'react-router-dom'
Chart.register(ArcElement)

export default function TaskSummery({ setProperty }) {
  const [taskSummary, setTaskSummary] = useState({
    new: 0,
    inprogress: 0,
    done: 0,
  });

  const projectid = useParams().id

  const fetchTasks = async () => {
    try {
      const res = await getTargetByProjectId(projectid)
      console.log("task", res)
      const summary = res.reduce((acc, task) => {
        acc[task.status] = (acc[task.status] || 0) + 1;
        return acc;
      }, { new: 0, inprogress: 0, done: 0 });
      setTaskSummary(summary);
      console.log("taskSummary", taskSummary)
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchTasks()
    console.log("taskSummary", taskSummary)
  }, [])


  const data = {
    datasets: [
      {
        data: [taskSummary.new, taskSummary.inprogress, taskSummary.done],
        backgroundColor: ['#1e90ff', '#32cd32 ', 'lightgray'],
        borderWidth: 0,
      },
    ],
  }

  console.log("task summary")

  const options = {
    responsive: true,
    cutout: '80%',
    maintainAspectRatio: false,
  }


  return (
    <div >
      {/* <div className='card card-flush h-lg-100 card-xl-stretch'> */}
      <div className='card-header mt-6 border-0'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>Tasks Summary</h3>
          <div className='fs-6 fw-semibold text-gray-400'>24 Overdue Tasks</div>
        </div>

        <div className='card-toolbar'>
          <button onClick={() => setProperty('Targets')} className='btn btn-light btn-sm'>
            View Tasks
          </button>
        </div>
      </div>


      <div className='d-flex items-center justify-center my-8'>
        <div className='card-body p-9 pt-5 '>
          <div className='d-flex flex-wrap items-center'>
            <div className='position-relative d-flex flex-center h-175px w-175px me-15 mb-7'>
              <div className='position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center'>
                <span className='fs-2qx fw-bold'>{`${taskSummary.new + taskSummary.inprogress + taskSummary.done}`}</span>
                <span className='fs-6 fw-semibold text-gray-400'>Total Tasks</span>
              </div>
              <div className='w-48 h-80 position-absolute  '>
                <Doughnut key='myDoughnutChart' data={data} options={options} />
              </div>
              <canvas id='project_overview_chart'></canvas>
            </div>

            <div className='d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5'>
              <div className='d-flex fs-6 fw-semibold align-items-center mb-3'>
                <div className='bullet bg-primary me-3'></div>
                <div className='text-gray-400'>Not Started</div>
                <div className='ms-auto fw-bold text-gray-700'>{taskSummary.new}</div>

              </div>

              <div className='d-flex fs-6 fw-semibold align-items-center mb-3'>
                <div className='bullet bg-success me-3'></div>
                <div className='text-gray-400'> In Progress</div>
                <div className='ms-auto fw-bold text-gray-700'>{taskSummary.inprogress}</div>
              </div>

              <div className='d-flex fs-6 fw-semibold align-items-center mb-3'>
                <div className='bullet bg-dark-subtle me-3'></div>
                <div className='text-gray-400'>Done</div>
                <div className='ms-auto fw-bold text-gray-700'>{taskSummary.done}</div>
              </div>
            </div>
          </div>
          {/* 
          <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <div className='fs-6 text-gray-700'>
                  <a href='#' className='fw-bold me-1'>
                    Invite New .NET Collaborators
                  </a>
                  to create great outstanding business to business .jsp modutr className scripts
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}
