import axios, {AxiosResponse} from 'axios'
import {ApiResponse} from '../../Inventory/Components/Chart3'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ACCOUNTS_URL = `${API_URL}/accounts`
const EXPENSE_URL = `${ACCOUNTS_URL}/expenses`
const JOURNAL_URL = `${ACCOUNTS_URL}/journals`
const ORDER_ID = `${API_URL}/lastInsertedId`
const TRANSACTIONS_URL = `${API_URL}/transactions`
const PAYROLL_URL = `${API_URL}/payroll`
const REPORTS_URL = `${API_URL}/accounts/reports`
const POST_DOCS_URL = `${API_URL}/uploaddocs/accounts`
const PROJECT_TIMESHEET_URL = `${API_URL}/project/projectTimesheet`

export const postAccount = async (data) => {
  try {
    const response = await axios.post(`${ACCOUNTS_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCOA = async (data) => {
  try {
    const response = await axios.post(`${ACCOUNTS_URL}/addcategory`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccounts = async (accountsType: string, orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/${orgid}/${accountsType}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getReceivablesNPayables = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getReceivablesNPayables/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getIncomeNExpense = async (orgid: string) => {
  try {
    const response = await axios.get<ApiResponse>(`${ACCOUNTS_URL}/getIncomeNExpense/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTopExpenseAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getTopExpenseAccounts/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCashFlowChartData = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/monthly-cfstatement/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllGroupedAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/accountgroupedwithtypes/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccountsForExpense = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/accountsforexpense/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getAccountsForExpensePaidThrough = async (orgid: string) => {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/getaccounts/accountsforexpensepaidthrough/${orgid}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postExpense = async (data) => {
  try {
    const response = await axios.post(`${EXPENSE_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenses = async (orgid: string) => {
  try {
    const response = await axios.get(`${EXPENSE_URL}/getexpenses/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postJournal = async (data) => {
  try {
    const response = await axios.post(`${JOURNAL_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getJournals = async (orgid: string) => {
  try {
    const response = await axios.get(`${JOURNAL_URL}/getjournals/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getJournalLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/Journal`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function deleteAccount(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${ACCOUNTS_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getAccountById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ACCOUNTS_URL}/getaccountbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateAccount(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${ACCOUNTS_URL}/editaccountbyid/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateActivationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${ACCOUNTS_URL}/changeactivation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getTransactionsByAccountId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${TRANSACTIONS_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

//post function for payroll
export const postPayroll = async (data) => {
  try {
    const response = await axios.post(`${PAYROLL_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get function for payroll
export const getPayroll = async () => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayroll`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by month grouping
export const getPayrollWithMonth = async (orgid: string) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by months and year
export const getPayrollsByMonth = async (month: string, orgid: string) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}/${month}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by months and year
export const getPayrollsByEmployee = async (empid: string) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbyemployee/${empid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getProfitAndLoss = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/profitandloss/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getBalanceSheet = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/balancesheet/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getSalesByCustomer = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/salesbycustomer/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getSalesBySalesPerson = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/salesbysalesperson/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getSalesByItem = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/salesbyitem/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomerBalances = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/customerbalances/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCustomerBalancesSummary = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/customerbalancesummary/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getReceivableSummary = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/recevablesummary/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getReceivableDetails = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/receivabledetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getInvoiceDetails = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/invoicedetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getDeliveryChallanDetails = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/dcdetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getQuoteDetailsForReports = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/quotesdetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPaymentReceivedForReports = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/paymentsreceived/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getVendorBalanceSummary = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/vendorbalancesummary/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPayableSummary = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/payablesummary/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getPayableDetails = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/payabledetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTimeToGetPaid = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/timetopay/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenseDetails = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/expensedetails/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenseDetailsByCategory = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/expbycategory/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenseDetailsByCustomer = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/expbycustomer/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenseDetailsByVendor = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/expbyvendor/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getMovementOfEquity = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/movementofequity/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCashFlowStatement = async (orgid: string, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/cfstatement/${orgid}?startDate=${startDate}&endDate=${endDate}`
    )
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getParentAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/parentaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCategoryOfAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getcategoryofaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccountsByCategory = async (id: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/category/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getDocs(docid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ACCOUNTS_URL}/files/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deletedocs = async (id: string, docid: string) => {
  try {
    const response = await axios.delete(`${ACCOUNTS_URL}/files/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocs(formData: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}/${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEmployeeTotalWorkingHoursByProject(
  projectid: string,
  empid: string
): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${PROJECT_TIMESHEET_URL}/get/${projectid}/${empid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
