import React, {useState, useEffect} from 'react'

export default function MetaOptions({
  metaTagTitle,
  setMetaTagTitle,
  description,
  setDescription,
  metaTagKeywords,
  setMetaTagKeywords,
}) {
  return (
    // <div className='card border-0 p-4 rounded-md mt-3'>
    //   <div className='card-body space-y-6'>
    //     <h5 className=' text-start fw-semibold fs-5 '>Meta Options</h5>

    //     <div className='text-start space-y-2'>
    //       <label htmlFor='metaTagTitle' className='text-start'>
    //         Meta Tag Title
    //       </label>
    //       <div>
    //         <input
    //           id='metaTagTitle'
    //           placeholder='Meta Tag name'
    //           className='rounded border px-4 py-2 w-full'
    //           value={metaTagTitle}
    //           onChange={(e) => setMetaTagTitle(e.target.value)}
    //         />
    //       </div>
    //       <p className='text-gray-400'>
    //         Set a meta tag title. Recommended to be simple and precise keywords.
    //       </p>
    //     </div>

    //     <div className='space-y-3 mt-3 text-start'>
    //       <label htmlFor='description'>Description</label>
    //       <div className='w-full rounded-lg h-full'>
    //         <textarea
    //           name='description'
    //           placeholder='type your text here..'
    //           value={description}
    //           onChange={(e) => setDescription(e.target.value)}
    //           rows='4'
    //           cols='50'
    //           className='w-full rounded border px-4 py-2'
    //         ></textarea>
    //       </div>
    //     </div>

    //     <p className='card-text w-full mt-4 text-gray-500 text-start'>
    //       Set a description to the product for better visibility.
    //     </p>

    //     <div className='text-start space-y-2'>
    //       <label htmlFor='metaTagKeywords'>Meta Tag Keywords</label>
    //       <div>
    //         <input
    //           id='metaTagKeywords'
    //           className='rounded border px-4 py-2 w-full'
    //           value={metaTagKeywords}
    //           onChange={(e) => setMetaTagKeywords(e.target.value)}
    //         />
    //       </div>
    //       <p className='text-gray-400'>
    //         Set a list of keywords that the product is related to. Separate the keywords by adding a
    //         comma between each keyword.
    //       </p>
    //     </div>
    //   </div>
    // </div>
    <div class='card card-flush py-4'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>Meta Options</h2>
        </div>
      </div>

      <div class='card-body pt-0'>
        <div class='mb-10'>
          <label class='form-label'>Meta Tag Title</label>

          <input
            type='text'
            class='form-control mb-2'
            name='meta_title'
            placeholder='Meta tag name'
            value={metaTagTitle}
            onChange={(e) => setMetaTagTitle(e.target.value)}
          />

          <div class='text-muted fs-7'>
            Set a meta tag title. Recommended to be simple and precise keywords.
          </div>
        </div>

        <div class='mb-10'>
          <label class='form-label'>Meta Tag Description</label>

          {/* <input
            id='kt_ecommerce_add_product_meta_description'
            name='kt_ecommerce_add_product_meta_description'
            class='min-h-100px mb-2'
          ></input> */}

          <textarea
            name='kt_ecommerce_add_product_meta_description'
            placeholder='type your text here..'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows='4'
            cols='50'
            // class='min-h-100px mb-2'
            // class='form-control mb-2'
            className='w-full rounded border px-4 py-2'
          ></textarea>

          <div class='text-muted fs-7'>
            Set a meta tag description to the product for increased SEO ranking.
          </div>
        </div>

        <div>
          <label class='form-label'>Meta Tag Keywords</label>
          <input
            id='kt_ecommerce_add_product_meta_keywords'
            name='kt_ecommerce_add_product_meta_keywords'
            class='form-control mb-2'
            value={metaTagKeywords}
            onChange={(e) => setMetaTagKeywords(e.target.value)}
          />

          <div class='text-muted fs-7'>
            Set a list of keywords that the product is related to. Separate the keywords by adding a
            comma
            <code>,</code>between each keyword.
          </div>
        </div>
      </div>
    </div>
  )
}
