import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getDeliveryNotesDetailById} from '../../Core/_requests'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'

function DeliveryNote({companyLogo, color, setColor, minFooter}) {
  const [contactPerson, setContactPerson] = useState<any>({})

  const [data, setDeliveryNoteData] = useState<any>()
  const {id} = useParams()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDeliveryNotesDetailById(id)
        setDeliveryNoteData(response)
        if (response?.items?.length > 4) {
          const pages = Math.ceil((response?.items.length - 4) / 20)
          if ((response?.items.length - 10) / 20 > 10) {
            setPage(pages + 2)
          } else {
            setPage(pages + 1)
          }
        }
        setColor(response?.company?.salesColor)
        if (response?.contactPerson !== '') {
          setContactPerson(
            response?.customer?.contactPersons?.find(
              (person) => person?._id === response?.contactPerson
            ) || {}
          )
        } else {
          setContactPerson(response?.customer?.primaryContact)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const [imageSize, setImageSize] = useState<boolean>(true)
  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div id='pdfHeader' className='px-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1 tracking-[1px]'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-2 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-5 text-muted'>
              VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='border-b-black border-b mx-9'></div>
      <div className='card-body p-lg-15 '>
        {/* begin::Layout */}
        <div className='d-flex flex-column'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              {/* begin::Top */}
              {/* end::Top */}
              {/* begin::Wrapper */}
              <div className=''>
                {/*begin::Top*/}
                {idx === 0 && (
                  <div className='fw-bold fs-5 mb-6 text-gray-800 uppercase'>DELIVERY NOTE</div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row fs-7 text-black'
                      style={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <div className=' mb-5'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Attention</span>
                          <span className=''>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Customer</span>

                          <span className=''>{data?.customer?.companyName}</span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Delivery Date</span>
                          <span className=''>{formatCreatedAt(data?.createdAt)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Delivery Number</span>
                          <span className=''>{data?.id}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5 '>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>PO Number</span>

                          <span className=''>{formatCreatedAt(data?.createdAt)}</span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Quotation Number</span>

                          <span className=''>{data?.quotationId?.id}</span>
                        </div>
                      </div>
                      {/*end::Order details*/}
                      {/*begin::Billing & shipping*/}
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive mb-9'>
                      <table
                        className='table table-bordered m-0'
                        style={{
                          borderColor: '#020617',
                        }}
                      >
                        <thead>
                          <tr
                            className='fs-7 fw-bold uppercase'
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            <th className='min-w-50px text-white text-center pb-3 '>
                              <span>
                                <span className='arabic-font'>رقم</span>
                                <br />
                                S.No.
                              </span>
                            </th>

                            <th className='min-w-150px text-white text-center pb-3 '>
                              <span className='arabic-font'>وصف طلب عرض الأسعار</span>
                              <br />
                              RFQ Description
                            </th>

                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>وحدة</span>
                              <br />
                              UOM
                            </th>
                            <th className='min-w-70px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية</span>
                              <br />
                              QTY
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية تسليمها</span>
                              <br />
                              Delivered QTY
                            </th>

                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>تسليم الكمية</span>
                              <br />
                              Delivering QTY
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية الاتزان</span>
                              <br />
                              Balance QTY
                            </th>
                          </tr>
                        </thead>
                        <tbody className=''>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 14 : idx * 20 - 6,
                              idx === 0 ? 14 : idx * 20 + 14
                            )
                            .map((item, index) => (
                              <tr key={index} className=' text-black fs-8 text-center'>
                                <td className='text-black'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 15
                                    : index + idx * 20 - 5}
                                </td>

                                <td className='text-black text-justify '>{item?.productName}</td>

                                <td className='uppercase text-black'>
                                  {item?.unit ||
                                    item?.itemId?.unit ||
                                    item?.itemsId?.unit ||
                                    'units'}
                                </td>
                                <td className='text-black'>{item?.quantity}</td>
                                <td className='text-black'>{item?.deliveredQuantity}</td>
                                <td className='text-black'>{item?.deliveringQuantity}</td>
                                <td className='text-black'>{item?.balanceQuantity}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {(idx + 1 === page || page === 1) && (
                      <>
                        <div className='d-flex justify-content-between flex-column flex-sm-row px-10 fw-semibold fs-7 text-black'>
                          <div className='mb-7 mb-sm-0 d-flex flex-column gap-2'>
                            <p>Prepared By</p>
                            {data?.agent?.signature && data?.agent?.signature !== '' && (
                              <div>
                                <img
                                  src={PF + data?.agent?.signature}
                                  alt='sign'
                                  className='w-80px mt-3 h-80px aboslute top-5 right-5 z-1'
                                />
                              </div>
                            )}
                          </div>
                          <div className='mb-7 mb-sm-0'>Delivered By</div>
                          <div className='mb-7 mb-sm-0'>Received By</div>
                        </div>
                        <div className='mb-20 sm:text-right text mt-10 mx-10 fs-7 text-black'>
                          Empoyee Name & Id :
                        </div>
                        <div className='pt-10 fs-7 text-black'>
                          <div className='d-flex flex-row justify-content-sm-end  fw-semibold  px-5'>
                            <div className='border-t border-dashed py-2 px-10 pt-10 border-black'>
                              <span className='fw-bold'>(Sign & Stamp)</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter && `Mobile : ${data?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white `}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center'>
        <p className='fs-7 mb-1'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}
          {PageContent}
        </div>
      </div>
    </>
  )
}

export default DeliveryNote
