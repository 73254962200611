import {createSlice} from '@reduxjs/toolkit'

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState: {
    workOrderData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setWorkOrderData(state, action) {
      state.workOrderData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setWorkOrderData, setLoading, setError} = workOrderSlice.actions
export default workOrderSlice.reducer
