import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getWorkOrderById } from '../core/_requests'

const WorkOrderDetail = () => {
  const id = useParams().id
  const [data, setWorkOrder] = useState<any>()

  const fetchData = async () => {
    const res = await getWorkOrderById(id)
    console.log(res)
    setWorkOrder(res)
  }

  useEffect(() => {
    fetchData()
  }, [])



  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <>
      {/* <div className='card'>
        <div className='card-header d-flex align-items-center my-3'>
          <h3 className='card-title'>Work Order</h3>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label className='form-label'>Rental Item</label>
                <p>{data?.item?.productName}</p>
              </div>
              <div className='mb-3'>
                <label className='form-label'>Issue</label>
                <p>{data?.issue}</p>
              </div>
              <div className='mb-3'>
                <label className='form-label'>Status</label>
                <p>{data?.status}</p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label className='form-label'>Issue Date</label>
                <p>{formatDate(data?.issueDate)}</p>
              </div>
              <div className='mb-3'>
                <label className='form-label'>Start Date</label>
                <p>{formatDate(data?.startDate)}</p>
              </div>
              <div className='mb-3'>
                <label className='form-label'>Completion Date</label>
                <p>{formatDate(data?.completionDate)}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className='row g-5 gx-xxl-8'>
        <div className="flex-col col-xl-5 mb-5">
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 mt-5">
              <div className="card bg-white card-shadow border">
                <div className="card-header px-6 py-5 border-0">Details</div>
                <div className="card-content p-6" style={{ height: "auto" }}>
                  <div className="flex flex-col w-full">
                    <div className="w-full first:mt-0 ">
                      <div className="flex flex-col w-full">
                        <div className="w-full first:mt-0 mt-4">
                          <h4 className="font-sans m-0 font-bold text-md">All Fields</h4>
                        </div>
                        <div className="w-full first:mt-0 mt-4">
                          <div>
                            <div className="flex flex-col rounded-none">
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4">
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Vehicle
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div style={{ maxWidth: "max-content" }}>
                                      <div className="flex w-full items-center">
                                        <div className="ml-4 flex items-center first:ml-0 flex-none">
                                          <div className="relative">
                                            <img
                                              alt="Pickup Truck"
                                              className="object-cover outline-none rounded"
                                              src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI5MnhQaktrV1Q2aDUxTkZmQXF6eiIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:98512fe85077eaf682cd7fae5577dab894121be7d74f22830326c6a78b5894f2/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/92xPjKkWT6h51NFfAqzz"
                                              style={{ height: 26, width: 26 }}
                                            />
                                            <div
                                              className="absolute rounded-full text-white shadow-solid"
                                              style={{
                                                bottom: "-2px",
                                                height: 9,
                                                right: "-3px",
                                                width: 9
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(24, 204, 108)",
                                                  left: "-5px",
                                                  position: "absolute",
                                                  top: "-5px"
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="dot-fill"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                  />
                                                </svg>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 -mt-3">
                                              <a
                                                className="text-blue-700 spark-a-focus hover:underline"
                                                role="link"
                                                href="/552aeb9291/vehicles/3274956"
                                                style={{
                                                  textDecoration: "underline dotted"
                                                }}
                                              >
                                                {data?.item?.productName}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4">
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Status
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div className="flex w-full justify-between items-center">
                                      <div className="ml-5 flex items-center first:ml-0 flex-1 min-w-0">
                                        <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                                          <div className="flex w-full justify-between items-center">
                                            <div className="flex items-center first:ml-0 flex-1 min-w-0">
                                              <div className="w-full break-words">
                                                <div className="flex w-full justify-start items-center">
                                                  <div className="flex items-center first:ml-0">
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={20}
                                                        fill="currentColor"
                                                        viewBox="0 0 24 24"
                                                        color="#15d4cd"
                                                        name="dot-fill"
                                                        className="#15d4cd custom-color"
                                                      >
                                                        <path
                                                          fill=""
                                                          d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </div>
                                                  <div className="flex items-center first:ml-0">
                                                    <div className="flex w-full items-center">
                                                      <div className="ml-2 flex items-center first:ml-0">
                                                        <span className="text-md text-gray-900 text-left font-normal normal-case font-sans m-0 truncate">
                                                          {data?.status}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex items-center first:ml-0">
                                              <div
                                                aria-label="Click to edit"
                                                className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                                data-testid="inline-edit-button"
                                                role="button"
                                                tabIndex={0}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="edit"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    fillRule="evenodd"
                                                    d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="ml-5 flex items-center first:ml-0">
                                        <a
                                          className="cursor-pointer text-blue-700 spark-a-focus hover:underline"
                                          role="link"
                                          tabIndex={-1}
                                        >
                                          History
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Repair Priority Class
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div className="flex w-full justify-between items-center">
                                      <div className="ml-5 flex items-center first:ml-0 flex-1 min-w-0">
                                        <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                                          <div className="flex w-full justify-between items-center">
                                            <div className="flex items-center first:ml-0 flex-1 min-w-0">
                                              <div className="w-full break-words">
                                                <div className="flex w-full justify-start items-center">
                                                  <div className="flex items-center first:ml-0">
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={20}
                                                        fill="currentColor"
                                                        viewBox="0 0 24 24"
                                                        color="green"
                                                        name="dot-fill"
                                                        className="green custom-color"
                                                      >
                                                        <path
                                                          fill=""
                                                          d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </div>
                                                  <div className="flex items-center first:ml-0">
                                                    <div className="flex w-full items-center">
                                                      <div className="ml-2 flex items-center first:ml-0">
                                                        <span className="text-md text-gray-900 text-left font-normal normal-case font-sans m-0 truncate">
                                                          ....
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex items-center first:ml-0">
                                              <div
                                                aria-label="Click to edit"
                                                className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                                data-testid="inline-edit-button"
                                                role="button"
                                                tabIndex={0}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="edit"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    fillRule="evenodd"
                                                    d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Issued By
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div style={{ maxWidth: "max-content" }}>
                                      <div className="flex w-full items-center">
                                        <div className="ml-4 flex items-center first:ml-0 flex-none">
                                          <div>
                                            <svg
                                              aria-hidden="true"
                                              aria-label="AS"
                                              height={26}
                                              viewBox="0 0 26 26"
                                              width={26}
                                              style={{
                                                color: "rgb(138, 129, 213)",
                                                display: "block"
                                              }}
                                            >
                                              <circle
                                                cx={13}
                                                cy={13}
                                                r={13}
                                                style={{ fill: "currentcolor" }}
                                              />
                                              <text
                                                alignmentBaseline="central"
                                                className="text-white"
                                                dominantBaseline="central"
                                                fontSize="10px"
                                                textAnchor="middle"
                                                x={13}
                                                y={13}
                                                style={{
                                                  cursor: "inherit",
                                                  fill: "currentcolor",
                                                  userSelect: "none"
                                                }}
                                              >
                                                {data?.agent?.fullName[0]}
                                              </text>
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0">
                                          <a
                                            className="text-blue-700 spark-a-focus hover:underline"
                                            role="link"
                                            href="/552aeb9291/contacts/1866579"
                                            style={{ textDecoration: "underline dotted" }}
                                          >
                                            {data?.agent?.fullName}
                                          </a>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Assigned To
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div style={{ maxWidth: "max-content" }}>
                                      <div className="flex w-full items-center">
                                        <div className="ml-4 flex items-center first:ml-0 flex-none">
                                          <div>
                                            <svg
                                              aria-hidden="true"
                                              aria-label="AS"
                                              height={26}
                                              viewBox="0 0 26 26"
                                              width={26}
                                              style={{
                                                color: "rgb(138, 129, 213)",
                                                display: "block"
                                              }}
                                            >
                                              <circle
                                                cx={13}
                                                cy={13}
                                                r={13}
                                                style={{ fill: "currentcolor" }}
                                              />
                                              <text
                                                alignmentBaseline="central"
                                                className="text-white"
                                                dominantBaseline="central"
                                                fontSize="10px"
                                                textAnchor="middle"
                                                x={13}
                                                y={13}
                                                style={{
                                                  cursor: "inherit",
                                                  fill: "currentcolor",
                                                  userSelect: "none"
                                                }}
                                              >
                                                {data?.assignedTo?.firstName[0]}{data?.assignedTo?.lastName[0]}
                                              </text>
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0">
                                          <a
                                            className="text-blue-700 spark-a-focus hover:underline"
                                            role="link"
                                            href="/552aeb9291/contacts/1866579"
                                            style={{ textDecoration: "underline dotted" }}
                                          >
                                            {data?.assignedTo?.firstName} {data?.assignedTo?.lastName}
                                          </a>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Issue Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.issueDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Scheduled Start Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.scheduledStartDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Actual Start Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.startDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Description
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.expectedCompletionDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Actual Completion Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.completionDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4">
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Odometer
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div className="flex w-full justify-start items-center">
                                      <div className="ml-3 flex items-center first:ml-0">
                                        <p className="break-words max-w-full text-md undefined text-left font-normal font-sans m-0">
                                          {data?.odometer}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Description
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.description}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        vendor
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.vendor?.displayName}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Invoice Number
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.invoiceNo}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Po Number
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.poNo}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 mt-5">
              <div className="card bg-white card-shadow border">
                <div className="card-header px-6 pt-6 fs-3 text-md fw-bold border-0">Resolved Issues</div>
                <div className="card-content p-6" style={{ height: "auto" }}>
                  <div className="flex flex-col w-full">
                    <div className="w-full first:mt-0">
                      <div className="flex flex-col w-full">
                        <div className="w-full first:mt-0 mt-2 text-blue-700 spark-a-focus hover:underline" style={{ textDecoration: "underline dotted" }}>
                            #{data?.issues[0]?.issueId} ·
                        </div>
                        <div className="w-full first:mt-0 mt-2">
                          <p className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            {/* Bolt in back left side tire. Slow leak. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-xxl-7 mb-5 mb-xl-8">


        </div>
      </div>

    </>
  )
}

export default WorkOrderDetail
