import {createSlice} from '@reduxjs/toolkit'

const itemFailureSlice = createSlice({
  name: 'itemFailure',
  initialState: {
    itemFailureData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setItemFailureData(state, action) {
      state.itemFailureData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setItemFailureData, setLoading, setError} = itemFailureSlice.actions
export default itemFailureSlice.reducer
