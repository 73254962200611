import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getIssueById } from '../../core/_requests'
import { KTSVG } from '../../../../../_metronic/helpers'
import { CardsWidget10, CardsWidget20 } from '../../../../../_metronic/partials/widgets'

const IssueDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<any>({})

  const fetchData = async () => {
    const res = await getIssueById(id)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }
  return (
    <>
      <div className='row g-5 gx-xxl-8'>
        <div className="flex-col col-xl-6 mb-5">
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 ">
              <div
                className="spark-column"
                style={{ justifySelf: "stretch", gridColumnEnd: "span 1" }}
              >
                <div className="card bg-white border shadow-inherit">
                  <div className="card-header px-6 fs-2 text-md py-0  border-0 pt-6 fw-bold ">Details</div>
                  <div className="card-content px-6" style={{ height: "auto" }}>
                    <div className="flex flex-col w-full">
                      <div className="w-full first:mt-0 ">
                        <div className="flex flex-col w-full">
                          <div className="w-full first:mt-0 mt-4">
                            <h4 className="font-sans m-0 font-bold text-md">All Fields</h4>
                          </div>
                          <div className="w-full first:mt-0 mt-4">
                            <div>
                              <div className="flex flex-col rounded-none">
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Issue #
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <p className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0">
                                        {data?.issueId}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Status
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div
                                        className="font-medium rounded-full inline-block text-sm px-4 leading-4 border border-solid  text-white bg-yellow-600 border-yellow-600"
                                        data-testid="tag"
                                      >
                                        <div className="flex w-full items-center">
                                          <div className="ml-3 flex items-center first:ml-0">
                                            {data?.status}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Summary
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <p className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0">
                                        {data?.summary}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Description
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div className="flex flex-col w-full">
                                        <div className="w-full first:mt-0 mt-3">
                                          <div className="whitespace-pre-wrap">
                                            <p className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                              {data?.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Priority
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div className="flex w-full justify-between items-center">
                                        <div className="ml-5 flex items-center first:ml-0 flex-1 min-w-0">
                                          <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                                            <div className="flex w-full justify-between items-center">
                                              <div className="flex items-center first:ml-0 flex-1 min-w-0">
                                                <div className="w-full break-words">
                                                  <div className="flex flex-col w-full">
                                                    <div className="w-full first:mt-0 mt-2">
                                                      <div className="flex w-full items-center">
                                                        <div className="ml-3 flex items-center first:ml-0">
                                                          {/* <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={18}
                                                            height={18}
                                                            fill="currentColor"
                                                            viewBox="0 0 24 24"
                                                            color="text-gray-400"
                                                            name="circle-dashed"
                                                            className="text-gray-400 custom-color"
                                                          >
                                                            <path
                                                              fill=""
                                                              fillRule="evenodd"
                                                              d="M10.05 2.19a10.043 10.043 0 013.9 0l-.388 1.962a8.044 8.044 0 00-3.124 0l-.389-1.962zm7.507 1.495a10.05 10.05 0 012.758 2.758l-1.662 1.113a8.048 8.048 0 00-2.209-2.21l1.113-1.661zM3.685 6.443a10.05 10.05 0 012.758-2.758l1.113 1.662a8.05 8.05 0 00-2.21 2.209L3.686 6.443zM2 12c0-.667.065-1.319.19-1.95l1.962.388a8.044 8.044 0 000 3.124l-1.962.389A10.043 10.043 0 012 12zm19.81-1.95a10.048 10.048 0 010 3.9l-1.962-.388a8.044 8.044 0 000-3.124l1.962-.389zM6.443 20.315a10.05 10.05 0 01-2.758-2.758l1.662-1.113a8.048 8.048 0 002.209 2.21l-1.113 1.661zm13.872-2.758a10.052 10.052 0 01-2.758 2.758l-1.113-1.662a8.046 8.046 0 002.21-2.209l1.661 1.113zM12 22c-.667 0-1.319-.065-1.95-.19l.388-1.962a8.044 8.044 0 003.124 0l.389 1.962c-.632.125-1.284.19-1.951.19z"
                                                              clipRule="evenodd"
                                                            />
                                                          </svg> */}
                                                        </div>
                                                        <div className="ml-3 flex items-center first:ml-0">
                                                          <p className="break-words max-w-full text-default text-gray-900 text-left font-normal normal-case font-sans m-0">
                                                            {data?.priority}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="w-full first:mt-0 mt-2">
                                                      {/* <p className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                                        No Priority
                                                      </p> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="flex items-center first:ml-0">
                                                <div
                                                  aria-label="Click to edit"
                                                  className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                                  data-testid="inline-edit-button"
                                                  role="button"
                                                  tabIndex={0}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={18}
                                                    height={18}
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    name="edit"
                                                    className=""
                                                  >
                                                    <path
                                                      fill="red"
                                                      fillRule="evenodd"
                                                      d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Vehicle
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div style={{ maxWidth: "max-content" }}>
                                        <div className="flex w-full items-center">
                                          <div className="ml-4 flex items-center first:ml-0 flex-none">
                                            <div className="relative">
                                              {/* <img
                                                alt="Van"
                                                className="object-cover outline-none rounded"
                                                src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiJpd2RPNEh6QlFHdXZ2UDZwM0RkTSIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:5a552fbbb75f8091ee1a458ba949116101d5a06c4e5930d88352844c7ffa07ce/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/iwdO4HzBQGuvvP6p3DdM"
                                                style={{ height: 26, width: 26 }}
                                              /> */}
                                              <div
                                                className="absolute rounded-full text-white shadow-solid"
                                                style={{
                                                  bottom: "-2px",
                                                  height: 9,
                                                  right: "-3px",
                                                  width: 9
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(230, 145, 32)",
                                                    left: "-5px",
                                                    position: "absolute",
                                                    top: "-5px"
                                                  }}
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={18}
                                                    height={18}
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    name="dot-fill"
                                                    className=""
                                                  >
                                                    <path
                                                      fill=""
                                                      d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                    />
                                                  </svg>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0">
                                            <div className="flex flex-col w-full">
                                              <div className="w-full first:mt-0 -mt-3">
                                                <a
                                                  className="text-blue-700 spark-a-focus hover:underline"
                                                  role="link"
                                                  href="/552aeb9291/vehicles/3274957"
                                                  style={{
                                                    textDecoration: "underline dotted"
                                                  }}
                                                >
                                                  {data?.product?.productName}
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0">
                                            <div
                                              className="font-medium rounded-full inline-block text-sm px-4 leading-4 border border-solid  bg-gray-50 text-gray-600 border-gray-50"
                                              data-testid="tag"
                                            >
                                              <div className="flex w-full items-center">
                                                <div className="ml-3 flex items-center first:ml-0">
                                                  Sample
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Reported Date
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <abbr
                                        className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                        style={{ textDecoration: "underline dotted" }}
                                      >
                                        {formatDate(data?.reportedDate)}
                                      </abbr>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Reported By
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div style={{ maxWidth: "max-content" }}>
                                        <div className="flex w-full items-center">
                                          <div className="ml-4 flex items-center first:ml-0 flex-none">
                                            <div>
                                              {/* <img
                                                alt="CG"
                                                className="object-cover outline-none rounded-full"
                                                src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiJObTI4TkNsVDg2bTdPNkFCeXJNdyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:accd622cb4ddc96f3e451d6a898c3c682ca5699b97782a216a86f92ceb078a0c/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/Nm28NClT86m7O6AByrMw"
                                                style={{ height: 26, width: 26 }}
                                              /> */}
                                            </div>
                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0 text-blue-700 spark-a-focus hover:underline" style={{ textDecoration: "underline dotted" }}>

                                            Carlos Garcia

                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <abbr
                                          className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case cursor-default font-sans m-0"
                                          style={{ textDecoration: "underline dotted" }}
                                        >
                                          Odometer
                                        </abbr>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div className="flex w-full justify-start items-center">
                                        <div className="ml-3 flex items-center first:ml-0">
                                          <p className="break-words max-w-full text-md undefined text-left font-normal font-sans m-0">
                                            136,531 mi
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Source
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full" />
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                          Assigned To
                                        </span>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full">
                                      <div style={{ maxWidth: "max-content" }}>
                                        <div className="flex w-full items-center">
                                          <div className="ml-4 flex items-center first:ml-0 flex-none">
                                            <div>
                                              {/* <img
                                                alt="CG"
                                                className="object-cover outline-none rounded-full"
                                                src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiJObTI4TkNsVDg2bTdPNkFCeXJNdyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:accd622cb4ddc96f3e451d6a898c3c682ca5699b97782a216a86f92ceb078a0c/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/Nm28NClT86m7O6AByrMw"
                                                style={{ height: 26, width: 26 }}
                                              /> */}
                                            </div>
                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0 text-blue-700 spark-a-focus hover:underline" style={{ textDecoration: "underline dotted" }}>

                                            {data?.assignedTo?.firstName} {data?.assignedTo?.lastName}

                                          </div>
                                          <div className="ml-4 flex items-center first:ml-0" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <abbr
                                          className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case cursor-default font-sans m-0"
                                          style={{ textDecoration: "underline dotted" }}
                                        >
                                          Due Date
                                        </abbr>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full" />
                                  </div>
                                </div>
                                <div
                                  className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                  data-testid="detail-field"
                                  role="group"
                                >
                                  <div className="w-full sm:w-1/2 md:w-1/3">
                                    <div className="flex items-center w-full relative">
                                      <div className="mr-4">
                                        <abbr
                                          className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case cursor-default font-sans m-0"
                                          style={{ textDecoration: "underline dotted" }}
                                        >
                                          Due Odometer
                                        </abbr>
                                      </div>
                                      <div
                                        className="flex align-items-center"
                                        style={{ width: 16 }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                    <div className="break-words whitespace-pre-wrap w-full" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div className="col-xxl-6 mb-5 mb-xl-8">
          <div
            className="spark-column"
            style={{ justifySelf: "stretch", gridColumnEnd: "span 1" }}
          >
            <div className="flex flex-col w-full">
              <div className="w-full first:mt-0 ">
                <div className="card bg-white border box-shadow shadow-inherit">
                  <div className="card-header px-6 py-5">Timeline</div>
                  <div
                    className="card-content card-content-flush p-0"
                    style={{ height: "auto" }}
                  >
                    <div className="pb-6">
                      <div className="flex flex-col w-full">
                        <div className="w-full first:mt-0 mt-5">
                          <div className="px-6 py-5">
                            <div className="flex w-full items-center">
                              <div className="ml-6 flex items-center first:ml-0 flex-none">
                                <div
                                  className="relative"
                                  style={{ height: 40, width: 40 }}
                                >
                                  <img
                                    alt="CG"
                                    className="object-cover outline-none rounded-full"
                                    src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiJObTI4TkNsVDg2bTdPNkFCeXJNdyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:accd622cb4ddc96f3e451d6a898c3c682ca5699b97782a216a86f92ceb078a0c/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/Nm28NClT86m7O6AByrMw"
                                    style={{ height: 40, width: 40 }}
                                  />
                                  <div
                                    className="absolute text-white rounded-full"
                                    style={{
                                      bottom: "-6px",
                                      boxShadow: "white 0px 0px 0px 2px",
                                      height: 24,
                                      right: "-6px",
                                      width: 24
                                    }}
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="text-yellow-600"
                                      height={24}
                                      viewBox="0 0 24 24"
                                      width={24}
                                      style={{ display: "block" }}
                                    >
                                      <circle
                                        cx={12}
                                        cy={12}
                                        r={12}
                                        style={{ fill: "currentcolor" }}
                                      />
                                      <svg
                                        height="13.919999999999998"
                                        width="13.919999999999998"
                                        x="21%"
                                        y="21%"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13.919999999999998"
                                          height="13.919999999999998"
                                          fill="currentColor"
                                          viewBox="0 0 24 24"
                                          color="text-white"
                                          name="warning"
                                          className="text-white custom-color"
                                        >
                                          <path
                                            fill=""
                                            fillRule="evenodd"
                                            d="M21.527 15.476L14.987 4.85c-1.317-2.468-4.658-2.468-5.975 0L2.473 15.476a3.87 3.87 0 00-.024 3.66C3.006 20.186 4.079 21 5.445 21h13.112c1.366 0 2.439-.815 2.994-1.864a3.87 3.87 0 00-.023-3.66zm-10.76-9.667c.563-1.079 1.902-1.079 2.465 0 .01.021.023.042.035.062l6.536 10.62a1.87 1.87 0 01-.02 1.71c-.268.505-.72.799-1.227.799H5.444c-.508 0-.96-.294-1.227-.8a1.87 1.87 0 01-.02-1.71l6.535-10.619a.978.978 0 00.035-.062zM12 8a1 1 0 011 1v5a1 1 0 11-2 0V9a1 1 0 011-1zm1 9a1 1 0 11-2 0 1 1 0 012 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </svg>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="ml-6 flex items-center first:ml-0 flex-1 min-w-0">
                                <div className="flex flex-col w-full">
                                  <div className="w-full first:mt-0 mt-3">
                                    <div className="flex flex-col w-full">
                                      <div className="w-full first:mt-0 mt-1">
                                        <div className="flex">
                                          <div
                                            className="flex-1"
                                            style={{ flexBasis: 200 }}
                                          >
                                            <p className="break-words max-w-full text-sm text-gray-600 text-left font-normal normal-case font-sans m-0">
                                              <span className="break-words max-w-full text-sm text-gray-900 text-left font-medium normal-case font-sans m-0">
                                                Carlos Garcia
                                              </span>{" "}
                                              opened this issue
                                            </p>
                                          </div>
                                          <div className="flex-shrink-0 w-">
                                            <span className="break-words max-w-full text-sm text-gray-400 text-left font-normal normal-case font-sans m-0">
                                              <div className="flex w-full items-center">
                                                <div className="ml-3 flex items-center first:ml-0">
                                                  <span>13d</span>
                                                </div>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-full first:mt-0 mt-1">
                                        <div className="-mb-3" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>

  )
}

export default IssueDetail
