import React from 'react'
import {Route, Routes} from 'react-router-dom'
import IssueList from '../components/issues/IssueList'
import AddIssue from '../components/issues/AddIssue'
import IssueDetail from '../components/issues/IssueDetail'

const IssuesPage = () => {
  return (
    <Routes>
      <Route path='/issue-list' element={<IssueList />} />
      <Route path='/issue-list/add' element={<AddIssue />} />
      <Route path='/issue-list/:id' element={<IssueDetail />} />
    </Routes>
  )
}

export default IssuesPage
