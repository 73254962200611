import axios, {AxiosResponse} from 'axios'
import {IProfileDetails} from './SettingsModel'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/agent/details`
const RESET_PASSWORD_URL = `${API_URL}/auth/resetpassword`
const ADD_IMAGES_URL = `${API_URL}/images/`
const EDIT_AGENT_URL = `${API_URL}/agent/edit`
const COMPANY_URL = `${API_URL}/company`

export const getagentdetails = async (id: string): Promise<IProfileDetails> => {
  try {
    const response: AxiosResponse<IProfileDetails> = await axios.get<IProfileDetails>(
      `${GET_USERS_URL}/${id}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const resetPassword = async (data, id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axios.put<any>(`${RESET_PASSWORD_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postImage(imageData) {
  try {
    const response = await axios.post(ADD_IMAGES_URL, imageData)
    console.log('image uploaded to server')
    return response.data
  } catch (error) {
    console.error('Add image error:', error)
    throw error
  }
}

export async function editAgent(data, id: string) {
  try {
    const response = await axios.put(`${EDIT_AGENT_URL}/${id}`, data)
    console.log('agent updated')
    return response.data
  } catch (error) {
    console.error('Add agent error:', error)
    throw error
  }
}

export async function getCompanyById(id: string) {
  try {
    const response = await axios.get(`${COMPANY_URL}/${id}`)
    console.log('agent updated')
    return response.data
  } catch (error) {
    console.error('Add agent error:', error)
    throw error
  }
}

export async function editCompany(data, id: string) {
  try {
    const response = await axios.put(`${COMPANY_URL}/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Add agent error:', error)
    throw error
  }
}

export async function updateBillInfo(data, id: string) {
  try {
    const response = await axios.put(`${COMPANY_URL}/billing/${id}`, data)
    return response.data
  } catch (error) {
    console.error('Add agent error:', error)
    throw error
  }
}
