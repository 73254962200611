import {combineReducers} from '@reduxjs/toolkit'
import vehicleReducer from './vehicle/vehicleSlice'
import equipmentReducer from './equipment/equipmentSlice'
import inspectionHistoryReducer from './inspection/inspectionHistory/inspectionHistorySlice'
import itemFailureReducer from './inspection/itemFailure/itemFailureSlice'
import formsReducer from './inspection/forms/formsSlice'
import issuesReducer from './issues/issues/issuesSlice'
import serviceReminderReducer from './reminders/serviceReminder/serviceReminderSlice'
import vehicleRenewalReducer from './reminders/vehicleRenenwal/vehicleRenewalSlice'
import serviceHistoryReducer from './service/serviceHistory/serviceHistorySlice'
import workOrderReducer from './service/workOrder/workOrderSlice'
import serviceTaskReducer from './service/serviceTask/serviceTaskSlice'
import inventoryMaintenanceReducer from './inventory/inventoryMaintenanceSlice'
import documentMaintenanceReducer from './documents/documentMaintenanceSlice'

const rootReducer = combineReducers({
  vehicle: vehicleReducer,
  equipment: equipmentReducer,
  inspectionHistory: inspectionHistoryReducer,
  itemFailure: itemFailureReducer,
  forms: formsReducer,
  issues: issuesReducer,
  serviceReminder: serviceReminderReducer,
  vehicleRenewal: vehicleRenewalReducer,
  serviceHistory: serviceHistoryReducer,
  workOrder: workOrderReducer,
  serviceTask: serviceTaskReducer,
  inventoryMaintenance: inventoryMaintenanceReducer,
  documentMaintenance: documentMaintenanceReducer,
})

export default rootReducer
