import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
type Props = {
  searchTerm: string
  setSearchTerm: (string: string) => void
}

const Search: React.FC<Props> = ({searchTerm, setSearchTerm}) => {
  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className='d-flex align-items-center position-relative me-5'>
      <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid w-250px ps-14'
        placeholder='Search...'
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </div>
  )
}

export default Search
