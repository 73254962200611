import {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import {getProjectById} from '../../core/_requests'
import {getCustomer} from '../../../sales/Core/_requests'
import Select from 'react-select'
import {useAuth} from '../../../../modules/auth'
import {ICreateAccount} from '../AddProjectHelper'

const ProjectSettings: FC = () => {
  const {values, setFieldValue} = useFormikContext<ICreateAccount>()
  const [daysDifference, setDaysDifference] = useState(null)
  const [totalHours, setTotalHours] = useState<number | null>(null)
  const {projectId} = useParams()
  const {currentUser} = useAuth()
  const [customerList, setCustomerList] = useState<any>([])
  const [projectData, setProjectData] = useState({
    projectType: '',
    customer: '',
    currency: '',
    projectName: '',
    progress: 0,
    projectDescription: '',
    startDate: ' ',
    releaseDate: '',
    notification: {
      email: false,
      phone: false,
    },
    budgetAmount: 0,
    changeInBudget: false,
    status: 'InProgress',
    team: [],
    target: [],
    isDeleted: false,
  })

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await getProjectById(projectId)
        setProjectData(response.data) // Assuming the response contains the project data
      } catch (error) {
        console.error('Error fetching project data:', error)
      }
    }

    fetchProjectData()
  }, [projectId])

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log('res', res)
      setCustomerList(
        res.map((r) => ({
          value: r._id,
          currency: r.currency,
          label: r.displayName,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const handleCustomerNameChange = (selectedOption) => {
    console.log(selectedOption)
    setFieldValue('customer', selectedOption.value)
    setFieldValue('currency', selectedOption.currency)
  }

  useEffect(() => {
    getCustomerList()
  }, [])

  useEffect(() => {
    if (values?.startDate && values?.releaseDate) {
      const start = new Date(values?.startDate)
      const release = new Date(values?.releaseDate)

      const differenceInTime = release.getTime() - start.getTime()
      const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24))
      setFieldValue('totalCalculatedDays', differenceInDays)
      setDaysDifference(differenceInDays)
    }
  }, [values?.startDate, values?.releaseDate])

  useEffect(() => {
    if (daysDifference !== null) {
      let morningShiftHours = values.morningShiftValue || 0
      let eveningShiftHours = values.eveningShiftValue || 0
      const totalHours = daysDifference * (morningShiftHours + eveningShiftHours)
      setFieldValue('totalCalculatedHours', totalHours)
      setTotalHours(totalHours)
    }
  }, [daysDifference, values.morningShiftValue, values.eveningShiftValue])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Project Details</h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Customer Name</label>
        {/* <Field name='customerName' className='form-control form-control-lg form-control-solid' /> */}
        <Field
          component={Select}
          name='customer'
          aria-label='Select a Customer'
          placeholder='Select a Customer'
          options={customerList}
          onChange={handleCustomerNameChange}
          //defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
          isSearchable={true}
          className='form-control form-control-solid'
          // value={projectData.customer}
        />

        <div className='text-danger mt-2'>
          <ErrorMessage name='customer' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Project Name</label>

        <Field name='projectName' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='projectName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Project Description</span>
        </label>

        <Field
          // value={projectData.projectDescription}
          name='projectDescription'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='projectDescription' />
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your project descriptor
        </div>
      </div>

      <div className='fv-row mb-10'>
        <div className='row'>
          <div className='col-md-6 mb-3'>
            <label className='form-label required'>Start Date</label>
            <Field
              type='date'
              name='startDate'
              className='form-control form-control-md form-control-solid'
            ></Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='startDate' />
            </div>
          </div>

          <div className='col-md-6 mb-3'>
            <label className='form-label required'>Release Date</label>
            <Field
              type='date'
              name='releaseDate'
              className='form-control form-control-lg form-control-solid'
            ></Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='releaseDate' />
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <div className='row'>
          {daysDifference !== '' && (
            <div className='col-md-6 mb-3 d-flex whitespace-nowrap space-x-4 items-center'>
              <label className='form-label '>Total number Of Days</label>
              <Field
                type='text'
                name='totalCalculatedDays'
                className='form-control form-control-md form-control-solid'
                value={daysDifference}
                readOnly
              />
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-10'>
        <div className='row '>
          <div className='col-md-6 mb-3'>
            <label className='form-label '>Morning Shift</label>
            <div className='d-flex'>
              <label className='form-check form-check-custom form-check-solid  w-full'>
                <Field
                  className='form-check-input me-3'
                  type='checkbox'
                  name='morningShiftBoolean'
                />
                <Field
                  type='number'
                  name='morningShiftValue'
                  placeholder='Number of Hours'
                  className='form-control form-control-lg form-control-solid'
                  disabled={!values.morningShiftBoolean}
                ></Field>
              </label>
            </div>
            <div className='text-danger mt-2'>
              {values.morningShiftBoolean && !values.morningShiftValue && (
                <ErrorMessage name='morningShiftValue' />
              )}
            </div>

            {/* <div className='text-danger mt-2'>
  <ErrorMessage name='morningShiftValue' />
</div> */}
          </div>

          <div className='col-md-6 mb-3'>
            <label className='form-label '>Evening Shift</label>
            <div className='d-flex '>
              <label className='form-check form-check-custom form-check-solid w-full'>
                <Field
                  className='form-check-input me-3'
                  type='checkbox'
                  name='eveningShiftBoolean'
                />
                <Field
                  type='number'
                  name='eveningShiftValue'
                  placeholder='Number of Hours'
                  className='form-control form-control-lg form-control-solid '
                  disabled={!values.eveningShiftBoolean}
                ></Field>
              </label>
            </div>
            <div className='text-danger mt-2'>
              {values.eveningShiftBoolean && !values.eveningShiftValue && (
                <ErrorMessage name='eveningShiftValue' />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <div className='row'>
          {typeof totalHours === 'number' && (
            <div className='col-md-6 mb-3 d-flex whitespace-nowrap space-x-4 items-center'>
              <label className='form-label'>Total number of hours</label>
              <Field
                type='text'
                className='form-control form-control-md form-control-solid'
                name='totalCalculatedHours'
                value={totalHours}
                readOnly
              />
            </div>
          )}
        </div>
      </div>

      <div className='d-flex flex-stack'>
        <div className='me-5'>
          <label className='fs-6 fw-semibold'>Notifications</label>
          <div className='fs-7 fw-semibold text-muted'>Allow Notifications by Phone or Email</div>
        </div>

        <div className='d-flex'>
          <label className='form-check form-check-custom form-check-solid me-10'>
            <Field
              className='form-check-input h-20px w-20px'
              type='checkbox'
              name='notification.email'
            />

            <span className='form-check-label fw-semibold'>Email</span>
          </label>

          <label className='form-check form-check-custom form-check-solid'>
            <Field
              className='form-check-input h-20px w-20px'
              type='checkbox'
              name='notification.phone'
            />

            <span className='form-check-label fw-semibold'>Phone</span>
          </label>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='projectSettings' />
        </div>
      </div>
    </div>
  )
}

export {ProjectSettings}
