import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {onMessage} from 'firebase/messaging'
import {messaging} from './firebase'
import toast, {Toaster} from 'react-hot-toast'
import {Provider} from 'react-redux'
import store from './redux/store'
const App = () => {
  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('Received message on focused tab', payload)
      toast.success(payload.notification.title)
    })
  }, [])
  return (
    <Provider store={store}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
                <Toaster position='top-right' reverseOrder={false} />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </Provider>
  )
}
export {App}
