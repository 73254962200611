import React, { useEffect, useState } from 'react'

export default function Products({ products }) {
    console.log("products", products)
    // const [masterCheckbox, setMasterCheckbox] = useState(false)
    // const [checkboxes, setCheckboxes] = useState([]);
    // const [data, setData] = useState([])
    const [productList, setProductList] = useState([])
    const [search, setSearch] = useState("")

    const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

    useEffect(()=>{
        if(products !== undefined)  setProductList(products)
    }, [products])

    useEffect(()=>{
        const filteredProducts = products?.filter((item)=> item?.itemId?.productName?.toLowerCase().includes(search.toLowerCase()))
        setProductList(filteredProducts)
    }, [search])

    // const handleMasterCheckboxChange = () => {
    //     setMasterCheckbox(!masterCheckbox);
    //     const updatedData = data?.map(item => ({
    //         ...item,
    //         isChecked: !masterCheckbox
    //     }));
    //     setData(updatedData);
    // };

    // useEffect(() => {
    //     const updatedData = products?.map(item => ({
    //         ...item,
    //         isChecked: masterCheckbox
    //     }));
    //     setData(updatedData);
    //     console.log("data",data)
    // }, [masterCheckbox, products]);

    // // Function to handle individual checkbox change
    // const handleCheckboxChange = (index) => {
    //     const updatedData = [...data];
    //     updatedData[index].isChecked = !updatedData[index].isChecked;
    //     setData(updatedData);
    // };

    return (

        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">

            <div class="container-xxl" id="kt_content_container">

                <div class="card card-flush">

                    <div class="card-header align-items-center py-5 gap-2 gap-md-5">

                        <div class="card-title">

                            <div class="d-flex align-items-center position-relative my-1">
                                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                    <span class="path1"></span>
                                    <span class="path2"></span>
                                </i>
                                <input type="text" data-kt-ecommerce-product-filter="search" class="form-control form-control-solid w-250px ps-12" placeholder="Search Product" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }}/>
                            </div>

                        </div>

                        <div class="card-toolbar flex-row-fluid justify-content-end gap-5">
                            <div class="w-100 mw-150px">

                                <select class="form-select form-select-solid" data-control="select2" data-hide-search="true" data-placeholder="Status" data-kt-ecommerce-product-filter="status">
                                    <option value="all">All</option>
                                    <option value="published">Published</option>
                                    <option value="scheduled">Scheduled</option>
                                    <option value="inactive">Inactive</option>
                                </select>

                            </div>

                            {/* <a href="#" class="btn btn-primary">Add Product</a> */}

                        </div>

                    </div>

                    <div class="card-body pt-0 table-responsive">
                        <table class="table align-middle table-row-dashed fs-6 gy-5 px-4" id="kt_ecommerce_products_table">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    {/* <th class="w-10px pe-2">
                                        <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-9-check'
                                                checked={masterCheckbox}
                                                onChange={handleMasterCheckboxChange}
                                            />
                                        </div>
                                    </th> */}
                                    <th class="min-w-200px">Product</th>
                                    <th class="text-end min-w-100px">SKU</th>
                                    <th class="text-end min-w-70px">Qty</th>
                                    <th class="text-end min-w-100px">Price</th>

                                    {/* <th class="text-end min-w-100px">Status</th> */}

                                </tr>
                            </thead>
                            <tbody class="fw-semibold text-gray-600">
                                {productList?.map((product, i) => (
                                    <tr>
                                        {/* <td>
                                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox"
                                                    checked={product.isChecked}
                                                    onChange={() => handleCheckboxChange(i)} />
                                            </div>
                                        </td> */}
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div className='symbol symbol-50px me-2'>
                                                    <span className='symbol-label'>
                                                        <img
                                                            src={`${PF}${product?.itemId?.thumbnail}`}
                                                            alt=''
                                                            className='h-50 align-self-center'
                                                        />
                                                    </span>
                                                </div>
                                                <div class="ms-5">

                                                    <a href="" class="text-gray-800 text-hover-primary fs-5 fw-bold" data-kt-ecommerce-product-filter="product_name">{product?.itemId?.productName}</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end pe-0">
                                            <span class="fw-bold">{product?.itemId?.sku}</span>
                                        </td>
                                        <td class="text-end pe-0" data-order="20">
                                            <span class="fw-bold ms-3">{product?.quantity}</span>
                                        </td>
                                        <td class="text-end pe-0">{product?.price}</td>

                                        {/* <td class="text-end pe-0" data-order="Inactive">
                                            <div class="badge badge-light-danger">{product?.itemId?.status}</div>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
