/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../helpers'
import {Link, useNavigate} from 'react-router-dom'

type Props = {
  className: string
}

const rows: Array<{description: string; link: string}> = [
  {description: 'Create Purchase Order', link: '/procurement/purchaseOrdersList/add'},
  {description: 'Add Expenses', link: '/account/expenses/add'},
  {description: 'Create Payroll', link: '/hrm/payroll/add'},
]

const ListsWidget26 = ({className}: Props) => {
  const navigate = useNavigate()
  return (
    <div className={`card card-flush ${className} bg-light-primary border-primary`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>Quick Tabs</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack'>
              <Link to={row.link} className='fw-semibold text-black fs-6 me-2'>
                {row.description}
              </Link>
              <button
                type='button'
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                onClick={() => navigate(row.link)}
              >
                <KTIcon iconName='exit-right-corner' className='fs-2' />
              </button>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
export {ListsWidget26}
