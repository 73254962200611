import {combineReducers} from '@reduxjs/toolkit'
import expenseReducer from './expenses/expenseSlice'
import journalEntrySlice from './accountant/journalEntry/journalEntrySlice'
import chartOfAccountantReducer from './accountant/chartOfAccountant/chartOfAccountantSlice'
import categoryOfAccountsReducer from './accountant/categoryOfAccountant/categoryOfAccountsSlice'
import documentReducer from './documents/documentSlice'

const rootReducer = combineReducers({
  expense: expenseReducer,
  journalEntry: journalEntrySlice,
  chartOfAccountant: chartOfAccountantReducer,
  categoryOfAccounts: categoryOfAccountsReducer,
  document: documentReducer,
})
export default rootReducer
