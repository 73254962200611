import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {RFQApproval} from '../Components/approvals/RFQApproval'
import {PurchaseOrderApproval} from '../Components/approvals/PurchaseOrderApproval'
import {PurchaseReceiveApproval} from '../Components/approvals/PurchaseReceiveApproval'
import BillsApproval from '../Components/approvals/BillsApproval'
import PaymentMadeApproval from '../Components/approvals/PaymentMadeApproval'

const ApprovalPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route path='rfq' element={<RFQApproval />} />
      <Route path='purchaseOrder' element={<PurchaseOrderApproval />} />
      <Route path='purchaseReceive' element={<PurchaseReceiveApproval />} />
      <Route path='billsList' element={<BillsApproval />} />
      <Route path='paymentMadeList' element={<PaymentMadeApproval />} />
      <Route index element={<Navigate to='rfq' />} />
    </Routes>
  )
}

export default ApprovalPage
