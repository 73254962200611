import {createSlice} from '@reduxjs/toolkit'

const meetingSlice = createSlice({
  name: 'meeting',
  initialState: {
    meetingData: [],
    loading: true,
    error: null,
  },
  reducers: {
    setMeetingData(state, action) {
      state.meetingData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setMeetingData, setLoading, setError} = meetingSlice.actions
export default meetingSlice.reducer
