export type Docs = {
  _id?: string
  name?: string
  profilePic?: string
  email?: string
  phone?: string
  status?: string
}
export interface Filtertype {
  status?: string
}

export interface SearchFilter {
  search?: string
  agent?: string
  filter?: Filtertype
}

export const initialSearchFilter: SearchFilter = {
  search: '',
  agent: '',
  filter: {
    status: '',
  },
}
