import {createSlice} from '@reduxjs/toolkit'

const departmentSlice = createSlice({
  name: 'department',
  initialState: {
    departmentData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDepartmentData(state, action) {
      state.departmentData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setDepartmentData, setLoading, setError} = departmentSlice.actions
export default departmentSlice.reducer
