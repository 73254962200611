import React from 'react'
import Flatpickr from 'react-flatpickr'

const DateFilter = ({dateState, setDateState}) => {
  return (
    <Flatpickr
      value={[dateState.startDate, dateState.endDate]}
      onChange={([startDate, endDate]) => {
        setDateState({startDate, endDate})
      }}
      options={{
        mode: 'range',
      }}
      className='form-control form-control-solid w-250px mx-2 my-4'
      placeholder='Pick date'
    />
  )
}

export default DateFilter
