import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {
  postPartialDeliveryNote,
  getDeliveryNotes,
  getDeliveryNotesDetailBySalesOrderId,
} from '../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'

const AddPartialDeliveryNote = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const deliveryNoteId = useParams().id
  const [orderId, setOrderId] = useState('')
  const [totalBalance, setTotalBalance] = useState(0)
  const [quotationName, setQuotationName] = useState('')
  const [salesOrderName, setSalesOrderName] = useState('')
  const [quotationId, setQuotationId] = useState('')
  const [salesOrderId, setSalesOrderId] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [customerName, setCustomerName] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(new Date())
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')
  const [selectedQuotationItems, setSelectedQuotationItems] = useState([
    {
      productName: '',
      itemId: null,
      itemsId: null,
      unit: '',
      price: '',
      quantity: 0,
      type: 'product',
      deliveredQuantity: 0,
      deliveringQuantity: 0,
      balanceQuantity: 0,
      discount: 0,
      amount: 0,
    },
  ])

  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)

  const [deliveryNoteIds, setDeliveryNoteIds] = useState('')

  const [deliveryNote, setDeliveryNote] = useState({
    customer: '',
    contactPerson: '',
    id: '',
    reference: '',
    date: new Date(),
    challanType: '',
    items: [],
    notes: ' ',
    termsNCondition: ' ',
    document: '',
  })

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (deliveryNote.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [deliveryNote.termsNCondition])

  useEffect(() => {
    if (deliveryNote.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [deliveryNote.notes])

  //Function to handle changes in input fields
  const handleChange = (e) => {
    const {name, value} = e.target
    console.log('change', name, value)
    if (name === 'notes' && value.trim() === '') {
      setDeliveryNote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    }
    setDeliveryNote((prevNote) => ({...prevNote, [name]: value}))
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setDeliveryNote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const handleDateChange = (date) => {
    setDeliveryDate(date)
  }

  useEffect(() => {
    setSelectedQuotationItems((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          balanceQuantity: item.quantity - item.deliveredQuantity - item.deliveringQuantity,
        }
      })
    })
    setTotalBalance(selectedQuotationItems.reduce((acc, item) => acc + item.balanceQuantity, 0))
  }, [selectedQuotationItems])

  const fetchDeliveryNoteData = async () => {
    const res = await getDeliveryNotesDetailBySalesOrderId(deliveryNoteId)
    console.log(res)
    let balancequantity = 0
    setSelectedQuotationItems(
      res.items.map((item) => {
        balancequantity += item.balanceQuantity
        return {
          productName: item?.productName
            ? item?.productName
            : item?.itemId
            ? item?.itemId?.productName
            : item?.itemsId?.name,
          itemId: item.itemId?._id || null,
          unit: item?.unit || null,
          itemsId: item.itemsId?._id || null,
          price: item.price,
          quantity: item.quantity,
          type: item.type,
          deliveredQuantity: item.quantity - item.balanceQuantity,
          deliveringQuantity: 0,
          balanceQuantity: item.balanceQuantity,
          discount: item.discount,
          amount: item.amount,
        }
      })
    )
    if (balancequantity === 0) {
      navigate(`/sales/deliveryChallan`)
    }
    setDeliveryNote({
      ...res,
      customer: res.customer?._id,
      contactPerson: res.contactPerson,
      reference: res.reference,
      date: new Date(res.date),
      challanType: res.challanType,
      notes: res.notes,
      termsNCondition: res.termsNCondition,
      document: res.document,
    })
    setDeliveryNoteIds(res.id)
    setOrderId(res.order)
    setCustomerName(res.customer?.displayName)
    setCustomerId(res.customer?._id)
    setQuotationName(res.quotationId?.id)
    setQuotationId(res.quotationId?._id)
    setSalesOrderName(res.salesOrderId?.id)
    setSalesOrderId(res.salesOrderId?._id)
  }

  // Function to handle form submission
  const handleEdit = async (e) => {
    e.preventDefault()
    const data = {
      ...deliveryNote,
      customer: customerId,
      items: selectedQuotationItems,
      quotationId: quotationId || null,
      salesOrderId: salesOrderId,
      order: orderId,
      currentId: deliveryNoteIds,
      totalBalance,
      approval: permissions?.deliverynote?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postPartialDeliveryNote(data).then((res) => {
        navigate(`/sales/deliveryChallan`)
      })
    } catch (error) {
      console.error('Error submitting delivery note', error)
    }
  }

  useEffect(() => {
    const fetchDeliveryNotes = async () => {
      try {
        const deliveryNotesList = await getDeliveryNotes(currentUser?.organization)
        console.log('list', deliveryNotesList)

        if (deliveryNotesList && deliveryNotesList.length > 0) {
          const firstDeliveryNote = deliveryNotesList[0]

          setDeliveryNote({
            ...deliveryNote,
            notes: firstDeliveryNote.notes || 'Default notes value',
            termsNCondition:
              firstDeliveryNote.termsNCondition || 'Default terms and condition value',
          })
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchDeliveryNotes()
  }, [])

  useEffect(() => {
    fetchDeliveryNoteData()
  }, [])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <span className='fs-2x fw-bold text-gray-800'>
                        PARTIAL DELIVERY CHALLAN #{' '}
                      </span>
                      <span className='fs-2x fw-bold text-gray-800'>{deliveryNoteIds}</span>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Customer Name{' '}
                      </label>
                      {/* begin::Input group */}
                      <span className='fs-6 fw-bold text-gray-800'>{customerName}</span>
                    </div>

                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Quotation #{' '}
                      </label>
                      <span className='fs-6 fw-bold text-gray-800'>{quotationName || 'None'}</span>
                    </div>

                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Sales Order #{' '}
                      </label>
                      <span className='fs-6 fw-bold text-gray-800'>{salesOrderName}</span>
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Date</label>
                        <Flatpickr
                          value={deliveryDate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick delivery date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />

                        {/* Input group */}
                      </div>
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}

                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Delivered Quantity</th>
                            <th className='min-w-70px w-150px'>Delivering Quantity</th>
                            <th className='min-w-70px w-250px'>Balance Quantity</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody className=''>
                          {selectedQuotationItems.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  value={item.quantity}
                                  disabled
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='deliveredQuantity'
                                  placeholder='0.00'
                                  value={item.deliveredQuantity}
                                  disabled
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='deliveringQuantity'
                                  placeholder='0.00'
                                  value={item.deliveringQuantity}
                                  onChange={(e) => {
                                    setSelectedQuotationItems((prevItems) => {
                                      const updatedItems = [...prevItems]
                                      if (Number(e.target.value) > item.quantity) {
                                        return updatedItems
                                      } else if (Number(e.target.value) < 0) {
                                        return updatedItems
                                      }
                                      updatedItems[index] = {
                                        ...updatedItems[index],
                                        deliveringQuantity: Number(e.target.value),
                                      }
                                      return updatedItems
                                    })
                                  }}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='balanceQuantity'
                                  placeholder='0.00'
                                  value={item.balanceQuantity}
                                  disabled
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Customer Notes
                      </label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={deliveryNote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-16 bottom-64 fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={deliveryNote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-16 bottom-36 fs-3'
                        />
                      </button>
                    </div>
                    <div></div>

                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      onClick={handleEdit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Create Delivery Challan
                    </button>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}
export default AddPartialDeliveryNote
