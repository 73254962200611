import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getAllEmployeeByID} from '../core/_requests'
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets'
import {Link, useNavigate} from 'react-router-dom'
import profile from '../../../../_metronic/assets/profile.png'

const ViewDepartment = () => {
  const {id} = useParams()
  const [employeeData, setEmployeeData] = useState<Array<any>>([])
  const fetchData = async () => {
    const response = await getAllEmployeeByID(id)
    console.log(response)
    setEmployeeData(response)
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      {/* <div className='d-md-flex g-5 g-xl-8 mb-5 mb-xl-8 gap-5 px-8 justify-content-center'>
        <StatisticsWidget5
          className={'col-md-3 mb-5'}
          color={'success'}
          title={'480/500'}
          titleColor={'white'}
          descriptionColor={'white'}
          description={'Present'}
          svgIcon={'check-circle'}
          iconColor={'white'}
        />
        <StatisticsWidget5
          className={'col-md-3 mb-5'}
          color={'danger'}
          title={'480/500'}
          titleColor={'white'}
          descriptionColor={'white'}
          description={'Absent'}
          svgIcon={'cross-circle'}
          iconColor={'white'}
        />
        <StatisticsWidget5
          className={'col-md-3 mb-5'}
          color={'primary'}
          title={'480/500'}
          titleColor={'white'}
          descriptionColor={'white'}
          description={'Onsite'}
          svgIcon={'geolocation-home'}
          iconColor={'white'}
        />
        <StatisticsWidget5
          className={'col-md-3 mb-5'}
          color={'info'}
          title={'480/500'}
          titleColor={'white'}
          descriptionColor={'white'}
          description={'Remote'}
          svgIcon={'check-circle'}
          iconColor={'white'}
        />
      </div> */}

      <div className={`card `}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              {employeeData[0]?.department?.name || null}
            </span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 new products</span> */}
          </h3>
          <div className='card-toolbar'></div>
        </div>

        <div className='card-body py-3 '>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Employes</th>
                  <th className='min-w-125px'>Department</th>
                  <th className='min-w-125px'>Employee Id</th>
                  <th className='min-w-150px'>Location</th>
                  <th className='min-w-150px'>Status</th>
                  {/* <th className='min-w-200px text-end rounded-end'></th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {employeeData &&
                  employeeData
                    .filter((employee) => employee.isActivated)
                    .map((item, i) => (
                      <tr key={i}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <Link
                                to={`/hrm/employee/view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {item.firstName} {item.lastName}
                              </Link>
                              {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                         HTML, JS, ReactJS
                       </span> */}
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.department?.name}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span> */}
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.employeeId}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Rejected</span> */}
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.location}
                          </a>
                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span> */}
                        </td>
                        <td>
                          <span className='badge badge-light-primary fs-7 fw-semibold'>
                            {item.employeeStatus}
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export default ViewDepartment
