import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  deleteAccount,
  getAccountById,
  getTransactionsByAccountId,
  updateActivationStatus,
} from '../core/_requests'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'

const AccountDetails = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState({
    accountName: '',
    amount: 0,
    description: '',
    fixed: false,
    status: true,
  })
  const [transactions, setTransactions] = useState<any[]>([])

  const fetchAccountDetails = async () => {
    const res = await getAccountById(params.id)
    console.log(res)
    setData(res)

    const resp = await getTransactionsByAccountId(params.id)
    console.log(resp)
    setTransactions(resp)
  }

  const handleActivation = async (status) => {
    const res = await updateActivationStatus(params.id, {status: !status})
    if (res) {
      setData({...data, status: !status})
    }
  }

  useEffect(() => {
    fetchAccountDetails()
  }, [])

  const handleDelete = async () => {
    await deleteAccount(params.id)
      .then((res) => {
        if (res) {
          const closeModalButtondelete = document.getElementById('closeModalButtondelete')
          closeModalButtondelete?.click()
          navigate('/account/chartofaccounts')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4 className='card-title fw-bolder capitalize'>{data?.accountName}</h4>
          <div>
            {/* menu button which on click have delete option */}
            {!data?.fixed && (
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-dark btn-active-light-dark'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='menu' className='svg-icon-3 fs-3' />
              </button>
            )}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-125px'
              data-kt-menu='true'
              style={{zIndex: 1000000}}
            >
              {/* begin::Menu item */}

              {data?.amount === 0 && (
                <>
                  <div className='menu-item px-3 mt-1'>
                    <a
                      href='#'
                      className='menu-link px-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_2'
                    >
                      Delete
                    </a>
                  </div>
                  <div className='separator mt-3 opacity-75'></div>
                </>
              )}
              {data?.status ? (
                <div className='menu-item px-3' onClick={() => handleActivation(data?.status)}>
                  <a href='#' className='menu-link px-3'>
                    Deactivate
                  </a>
                </div>
              ) : (
                <div className='menu-item px-3' onClick={() => handleActivation(data?.status)}>
                  <a href='#' className='menu-link px-3'>
                    Activate
                  </a>
                </div>
              )}
              <div className='separator mt-3 opacity-75'></div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <h4>
            Closing Balance <span className='text-primary'>AED {data?.amount}</span>
          </h4>
          <p className='mt-3'>
            <span className='fw-semibold '>Description:</span> {data?.description}
          </p>
        </div>
      </div>
      <div className='card mt-5'>
        <div className='card-header'>
          <h4 className='card-title'>Recent Transactions</h4>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction Details</th>
                  <th>Type</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {transactions?.map((item, index) => (
                  <tr key={item?._id}>
                    <td>{formatCreatedAt(item?.createdAt)}</td>
                    <td>{item?.id}</td>
                    <td className='capitalize'>{item?.type}</td>
                    <td>{item?.debit !== 0 && item?.debit}</td>
                    <td>{item?.credit !== 0 && item?.credit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Delete</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <h3> Are you sure you want to delete</h3>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  id='closeModalButtondelete'
                >
                  No
                </button>
                <button type='button' className='btn btn-primary' onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountDetails
