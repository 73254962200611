import {FaImages} from 'react-icons/fa'
import {BsFillPencilFill} from 'react-icons/bs'
import {RxCross2} from 'react-icons/rx'

const Thumbnail = ({
  previewImage,
  setPreviewImage,
  selectedImage,
  setSelectedImage,
  thumbnail,
  setThumbnail,
}) => {
  const handleFileChange = (event) => {
    const file = event.target.files?.[0]

    if (file) {
      setSelectedImage(file)

      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result)
      }
      setThumbnail(Date.now() + file.name)
      reader.readAsDataURL(file)
    }
  }

  const handleClearImage = () => {
    setPreviewImage(null)
    const fileInput = document.getElementById('fileInput')
    if (fileInput) {
      fileInput.value = ''
    }
    setSelectedImage(null)
  }

  return (
    <div className='card border-0 p-4 rounded-md '>
      <div className='card-body '>
        <h5 className='card-title text-start fw-semibold fs-5'>Thumbnail</h5>
        <input
          type='file'
          onChange={handleFileChange}
          name='thumbnail'
          className='hidden'
          id='fileInput'
        />
        <div className='d-flex justify-center'>
          <label htmlFor='fileInput'>
            <span className='p-2 rounded-full  absolute  cursor-pointer   bg-white'>
              <BsFillPencilFill
                className=' border rounded-full h-full p-1 w-full shadow-md '
                size={15}
              />
            </span>
          </label>

          {previewImage ? (
            <div className='flex items-center justify-center bg-cover bg-center relative mt-4 p-4 w-48 h-48 shadow-2xl border-gray-300'>
              <img src={previewImage} alt='Preview' className='w-full h-full object-cover' />
              <span
                className='absolute rounded-full bottom-[-12px] right-[-15px] cursor-pointer text-gray-700 bg-white'
                onClick={handleClearImage}
              >
                <RxCross2 className='border rounded-full p-1 h-full w-full shadow-md ' />
              </span>
            </div>
          ) : (
            <FaImages className='text-gray-300 mt-4 p-4 w-48 h-48 shadow-2xl border-gray-300 ' />
          )}
        </div>

        <p className='card-text w-full mt-4 text-gray-500'>
          Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted
        </p>
      </div>
    </div>
  )
}

export default Thumbnail
