import {createSlice} from '@reduxjs/toolkit'

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: {
    equipmentData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setEquipmentData(state, action) {
      state.equipmentData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setEquipmentData, setLoading, setError} = equipmentSlice.actions
export default equipmentSlice.reducer
