import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Search from '../Components/Search'
import Filter from '../Components/Filter'
import qs from 'qs'

import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {deactivateVendor, getVendor, getVendorByAgentId} from '../Core/_requests'
import {Filtertype, RootState, SearchFilter, initialSearchFilter} from '../Core/_model'
import {useAuth} from '../../../modules/auth'
import AgentSearch from '../Components/AgentSearch'
import {useSelector, useDispatch} from 'react-redux'
import {debounce} from 'lodash'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'

const VendorWrapper = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const initialRef = useRef(true)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<any>(null)
  const [permissions, setPermissions] = useState<any>({})
  const [vendorId, setVendorId] = useState<string | null>(null)
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)
  const vendorLoading = useSelector((state: RootState) => state.procurement.vendor.loading)
  const vendorError = useSelector((state: RootState) => state.procurement.vendor.error)
  const [vendorData, setVendorData] = useState(vendorDataList.length > 0 ? vendorDataList : [])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const navigate = useNavigate()

  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setStatusFilter = (isActive: boolean) => {
    setSearchFilter({
      filter: {
        isActive,
      },
    })
  }

  const handleTrigger = () => {
    getVendors()
  }

  const handleDeleteToggle = (i) => {
    setSelectedIndex(i)
  }

  const handleDelete = async (i) => {
    console.log(i)
    try {
      await deactivateVendor(i)
      getVendors()
    } catch (error) {
      console.error('Error deleting employee:', error)
    }
  }
  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.isActive,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getVendorList = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)

      const res = await getVendor(queryString, currentUser?.organization)
      console.log(res)
      const sortedData = res.sort((a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateB - dateA
      })
      setVendorData(sortedData)
      return res
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }

  const getVendorForAgent = async () => {
    setSearchFilter({
      search: searchTerm,
    })

    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getVendorByAgentId(queryString, currentUser?._id)
      console.log('agent', res)
      const sortedData = res.sort((a: any, b: any) => {
        const dateA: any = new Date(a.createdAt)
        const dateB: any = new Date(b.createdAt)
        return dateB - dateA
      })
      setVendorData(sortedData)
      return res
    } catch (error) {
      console.error('Error fetching Customer list:', error)
    }
  }

  const getVendors = () => {
    if (permissions?.vendors?.admin === false) {
      return getVendorForAgent()
    } else if (permissions?.vendors?.admin === true || currentUser?.profileType === 'superadmin') {
      return getVendorList()
    }
  }

  useEffect(() => {
    if (vendorDataList?.length === 0) {
      if (permissions?.vendors?.admin === false) {
        getVendorForAgent().then((res) => {
          dispatch(setVendorDataList(res))
        })
      } else if (
        permissions?.vendors?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getVendorList().then((res) => {
          dispatch(setVendorDataList(res))
        })
      }
    }
  }, [permissions, currentUser, dispatch])

  const debouncedSearch = debounce(getVendors, 500)
  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }
    debouncedSearch()

    return () => debouncedSearch.cancel()
  }, [searchTerm])

  const handlereset = async () => {
    setSearchFilter(initialSearchFilter)
    setVendorData(vendorDataList)
  }

  const handleClick = (e) => {
    e.preventDefault()
    navigate('/procurement/vendor/newVendor')
  }

  if (vendorLoading) {
    return <div>Loading...</div>
  }

  if (vendorError) {
    return <div>Error fetching data</div>
  }

  return (
    <div className='card'>
      <span className=' card-label fw-bold fs-3 ps-15 pt-8 '>Vendor List</span>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Filter
            searchFilters={searchFilters}
            setSearchFilter={setSearchFilter}
            handlereset={handlereset}
            getDocsList={getVendors}
            setStatusFilter={setStatusFilter}
          />
        </div>
        {(permissions?.vendors?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <div onClick={handleClick} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              New Members
            </div>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Vendor Display Name</th>
                <th className='min-w-125px'>Company Name</th>
                <th className='min-w-125px'>Contact Number</th>
                <th className='min-w-150px'>Email</th>
                {(permissions?.vendors?.update || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-150px'>Currency</th>
                )}
                <th className='min-w-200px text-center'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Array.isArray(vendorData) &&
                vendorData
                  // .filter((customer) => customer.isActivated === true)

                  .map((customer, index) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-50px me-5'>
                            {/* <img
                            src={toAbsoluteUrl('/media/avatars/300-11.jpg')}
                            className='img-fluid rounded-circle'
                            alt=''
                          /> */}
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              to={`/procurement/vendor/view-vendor/${customer._id}`}
                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {customer.displayName}
                            </Link>
                          </div>
                        </div>
                      </td>

                      <td>
                        <Link
                          to={`/procurement/vendor/view-vendor/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.companyName}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/vendor/view-vendor/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.contactNumbers.mobilePhone || customer.contactNumbers.workPhone}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/procurement/vendor/view-vendor/${customer._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {customer.emailAddress}
                        </Link>
                      </td>
                      <td>
                        <span className='badge badge-light-primary fs-7 fw-semibold'>
                          {customer.currency}
                        </span>
                      </td>
                      {(permissions?.vendors?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='text-center '>
                          <a
                            href='#'
                            className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            Actions
                            <i className='ki-duotone ki-down fs-5 ms-1'></i>
                          </a>

                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <Link
                                to={`/procurement/vendor/edit/${customer._id}`}
                                className='menu-link px-3'
                              >
                                Edit
                              </Link>
                            </div>

                            <div className='menu-item px-3'>
                              <a
                                href='#'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_4'
                                className='menu-link px-3'
                                onClick={() => setVendorId(customer._id)}
                              >
                                Assign
                              </a>
                            </div>

                            <div className='menu-item px-3'>
                              <button
                                onClick={() => handleDeleteToggle(customer._id)}
                                data-bs-toggle='modal'
                                data-bs-target='#myModal'
                                className='menu-link px-3 hover:w-full'
                                data-kt-users-table-filter='delete_row'
                              >
                                {customer?.isActivated ? 'Deactivate' : 'Activate'}
                              </button>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <AgentSearch vendorId={vendorId} handleTrigger={handleTrigger} />
      <div className='modal' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
            </div>

            <div className='modal-body text-center fs-6'>
              Are you sure you want to{' '}
              {vendorData.find((vendor) => vendor._id === selectedIndex)?.isActivated
                ? 'Deactivate'
                : 'Activate'}{' '}
              the {vendorData.find((vendor) => vendor._id === selectedIndex)?.displayName} ?
            </div>

            <div className='modal-footer text-center mx-auto'>
              <button
                type='button'
                className='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(selectedIndex)}
              >
                {vendorData.find((vendor) => vendor._id === selectedIndex)?.isActivated
                  ? 'Deactivate'
                  : 'Activate'}
              </button>
              <button
                type='button'
                className='btn btn-light '
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorWrapper
