import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../modules/auth';
import { getProjectById, getTargetByProjectId } from '../core/_requests';
import { useParams } from 'react-router-dom';

export default function WhatsOnTheRoad() {
  const { currentUser } = useAuth();
  const params = useParams();
  const projectid = params.id;
  const [permissions, setPermissions] = useState({});
  const [activeDate, setActiveDate] = useState(new Date().toLocaleDateString('en-US'));
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [data, setData] = useState([])


  const fetchData = async () => {
    try {
      const res = await getProjectById(projectid)
      setData(res)
      setActiveDate(new Date(res.startDate).toLocaleDateString('en-US'));
      console.log('data', res)
    } catch (error) {
      console.error('Error fetching the employee details', error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  // const currentDate = new Date();
  // const currentDayOfMonth = currentDate.getDate();
  // const daysToShowBefore = 3;
  // const daysToShowAfter = 10;

  // const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  // const dayElements = [];
  // for (let i = currentDayOfMonth - daysToShowBefore; i <= currentDayOfMonth + daysToShowAfter; i++) {
  //   const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
  //   const dayOfMonth = date.getDate();
  //   const isActive = dayOfMonth === currentDayOfMonth;
  //   dayElements.push(
  //     <li key={i} className='nav-item me-1'>
  //       <a
  //         className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary ${isActive ? 'active' : ''}`}
  //         data-bs-toggle='tab'
  //         href={`#kt_schedule_day_${i}`}
  //         onClick={() => setActiveDate(date.toLocaleDateString('en-US'))}
  //       >
  //         <span className='opacity-50 fs-7 fw-semibold'>{days[date.getDay()]}</span>
  //         <span className='fs-6 fw-bold'>{dayOfMonth}</span>
  //       </a>
  //     </li>
  //   );
  // }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser.permissions);
    }
  }, [currentUser]);


  const getProjectData = async () => {
    try {
      const response = await getTargetByProjectId(projectid);
      console.log("response", response)
      const filteredTasks = response?.filter(item => new Date(item.dueDate).toLocaleDateString('en-US') === activeDate);
      setTasks(filteredTasks);
      console.log("task", tasks)
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProjectData();
    if (data) {
      getProjectData();
    }
  }, [activeDate, data, projectid]);


  const generateDayElements = () => {
    // if (!data) return [];

    const startDate = new Date(data.startDate);
    const endDate = new Date(data.releaseDate);

    const dayElements = [];
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const currentDateStr = d.toLocaleDateString('en-US');
      const isActive = currentDateStr === activeDate;
      dayElements.push(
        <li key={currentDateStr} className="nav-item me-1">
          <button
            className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 ${isActive ? 'btn-active-primary active' : 'btn-light'}`}
            onClick={() => setActiveDate(currentDateStr)}
          >
            <span className="opacity-50 fs-7 fw-semibold">{days[d.getDay()]}</span>
            <span className="fs-6 fw-bold">{d.getDate()}</span>
          </button>
        </li>
      );
    }
    // Reset the modified date object to its original value
    startDate.setDate(new Date(data.startDate).getDate());

    return dayElements;
  };

  const openModal = (task) => {
    setSelectedTask(task);
  };

  return (
    <div>
      <div className='card-header mt-6'>
        <div className='card-title flex-column'>
          <h3 className='fw-bold mb-1'>What's on the road?</h3>
          <div className='fs-6 text-gray-400'>Total 482 participants</div>
        </div>
        <div className='card-toolbar'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-solid form-select-sm fw-bold w-100px'
          >
            <option value='1' selected='selected'>
              Options
            </option>
            <option value='2'>Option 1</option>
            <option value='3'>Option 2</option>
            <option value='4'>Option 3</option>
          </select>
        </div>
      </div>

      <div className='card-body p-9 pt-4'>
        <ul className='nav nav-pills d-flex flex-nowrap hover-scroll-x py-2'>
          {/* {dayElements} */}
          {/* {generateDayElements()} */}
          {generateDayElements()}
        </ul>
        <div className='tab-content'>
          <div id='kt_schedule_day_0' className='tab-pane fade show active'>
            {tasks.map((task, index) => (
              <div key={index} className='d-flex flex-stack position-relative mt-8'>
                <div className='position-absolute h-100 w-4px bg-secondary rounded top-0 start-0'></div>
                <div className='fw-semibold ms-5 text-gray-600'>
                  <div className='fs-5'>
                    {task.startTime} - {task.endTime}
                    <span className='fs-7 text-gray-400 text-uppercase'>{task.period}</span>
                  </div>
                  <a href='#' className='fs-5 fw-bold text-gray-800 text-hover-primary mb-2'>
                    {task.title}
                  </a>
                  <div className='text-gray-400'>
                    Lead by
                    <a href='#'>{task.lead}</a>
                  </div>
                </div>
                <button type="button" class="btn btn-bg-light btn-active-color-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => openModal(task)}>
                  View
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className='modal-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          <strong className='capitalize'>{selectedTask?.title}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          <strong>{selectedTask?.targetDetails}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <strong>{selectedTask?.dueDate ? new Date(selectedTask.dueDate).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) : ''}</strong>

                        </td>
                      </tr>
                      <tr>
                        <td>Created date</td>
                        <td>
                          <strong>{selectedTask?.createdAt ? new Date(selectedTask.dueDate).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) : ''}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Assignee</td>
                        <td>
                          <strong>
                            {/* {selectedTask?.assignedTo
                              ?.map((agent) => agent.displayName)
                              .join(', ')} */}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <strong
                            className={`badge uppercase ${selectedTask?.status === 'inprogress'
                              ? 'badge-light-warning'
                              : selectedTask?.status === 'new'
                                ? 'badge-light-primary'
                                : 'badge-light-success'
                              }`}
                          >
                            {selectedTask?.status === 'new'
                              ? 'NOT STARTED'
                              : selectedTask?.status === 'inprogress'
                                ? 'IN PROGRESS'
                                : 'DONE'}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Tags</td>
                        <td>
                          <strong
                            className={`badge uppercase ${selectedTask?.tags === 'medium'
                              ? 'badge-light-warning'
                              : selectedTask?.tags === 'flexible'
                                ? 'badge-light-primary'
                                : 'badge-light-success'
                              }`}
                          >
                            {selectedTask?.tags}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type='button' className='btn btn-secondary' data-bs-dismiss="modal">
                Close
              </button>

              <button type='button' className='btn btn-danger'>
                Remove Event
              </button>

              <button type='button' className='btn btn-warning'>
                Edit Event
              </button>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
}
