import React from 'react'
import {Route, Routes} from 'react-router-dom'
import TimeTrackerEmployee from './Pages/TimeTrackerEmployee'
import TimeTracker from './Components/TimeTracker'

const TimeTrackerPage = () => {
  return (
    <Routes>
      <Route path='/' element={<TimeTrackerEmployee />} />
      <Route path='/:id' element={<TimeTracker />} />
    </Routes>
  )
}

export default TimeTrackerPage
