import React, {useEffect, useState} from 'react'
import profile from '../../../../_metronic/assets/profile.png'
import {getEmployee} from '../../hrm/core/_requests'
import {useAuth} from '../../../modules/auth'
import {editProjectTimesheet, getProjectTimesheet, postProjectTimesheet} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'

export default function TimeSheet({team}) {
  const id = useParams().id

  const {currentUser} = useAuth()

  const [employeeData, setEmployeeData] = useState([
    {
      employeeId: '',
      regular: 0,
      ot: 0,
    },
  ])

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [month, setMonth] = useState(new Date().getMonth() + 1)
  const [loading, setLoading] = useState(false)
  const [timesheetData, setTimesheetData] = useState([])
  const [monthData, setMonthData] = useState('')
  const [updateId, setUpdateId] = useState('')

  const initializeEmpData = () => {
    const data = team?.map((item) => {
      return {
        employeeId: item?.employee?._id,
        regular: 0,
        ot: 0,
      }
    })

    setEmployeeData(data)
  }

  useEffect(() => {
    initializeEmpData()
  }, [team])

  useEffect(() => {
    fetchTimeSheetData()
  }, [])

  const fetchTimeSheetData = async () => {
    const res = await getProjectTimesheet(id)
    console.log(res)
    if (res) {
      setTimesheetData(res)
    }
  }

  const handleSubmit = async () => {
    if (!startDate || !endDate) return alert('Please select start and end date')
    setLoading(true)
    const data = {
      projectId: id,
      startDate,
      endDate,
      month,
      recordedTime: employeeData,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await postProjectTimesheet(data)
    console.log('res', res)
    if (res) {
      setLoading(false)
      const closeButton = document.getElementById('kt_modal_create_app_cancel')
      closeButton.click()
      Swal.fire({
        icon: 'success',
        title: 'Project TimeSheet Created Successfully',
      })
    }
  }

  const handleEdit = async () => {
    if (!startDate || !endDate) return alert('Please select start and end date')
    setLoading(true)
    const data = {
      projectId: id,
      startDate,
      endDate,
      month,
      recordedTime: employeeData,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await editProjectTimesheet(updateId, data)
    console.log('res', res)
    if (res) {
      setLoading(false)
      const closeButton = document.getElementById('kt_modal_edit_app_cancel')
      closeButton.click()
      Swal.fire({
        icon: 'success',
        title: 'Project TimeSheet Updated Successfully',
      })
      fetchTimeSheetData()
    }
  }

  const formatDate = (date) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5 d-flex align-items-center justify-between'>
        <span className=' card-label fw-bold fs-3 ps-20 pt-8 '>Timesheet</span>

        <div className='d-flex gap-3'>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_app'
            onClick={() => {
              initializeEmpData()
              setStartDate(new Date())
              setEndDate(new Date())
              setMonth(new Date().getMonth() + 1)
            }}
          >
            Create TimeSheet
          </button>
        </div>
      </div>

      <div className='card-header border-0 pt-5 '>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <h3 className='d-flex flex-row card-title align-items-start '></h3>
        </div>
        <div className='card-toolbar'></div>

        <div className='card-body py-3 w-100 '>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 w-100'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Sr. No.</th>
                  <th className='min-w-125px'>Month</th>
                  <th className='min-w-125px'>No. of employees</th>
                  <th className='min-w-125px'>Start Date</th>
                  <th className='min-w-150px'>End Date</th>
                  <th className='min-w-150px'>Action</th>
                </tr>
              </thead>

              <tbody>
                {/* {team?.map((team, i) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <div
                            // to={`view-employee/${item._id}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {team?.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {team?.department}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {team?.employee?.role}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {team?.employee?.employeeId}
                      </a>
                    </td>
                    <td>{team?.employee?.location}</td>
                    <td>
                      <span className='badge badge-light-primary fs-7 fw-semibold'>
                        {team?.employee?.employeeStatus}
                      </span>
                    </td>
                  </tr>
                ))} */}
                {timesheetData?.map((item, i) => (
                  <tr>
                    <td className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6'>{i + 1}</td>
                    <td
                      className='text-dark capitalize fw-bold text-hover-primary mb-1 fs-6 cursor-pointer'
                      onClick={() => {
                        setMonthData(item?.month)
                        setStartDate(new Date(item?.startDate))
                        setEndDate(new Date(item?.endDate))
                        setEmployeeData(item?.recordedTime)
                      }}
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view'
                    >
                      {item?.month}
                    </td>
                    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {item?.recordedTime?.length}
                    </td>
                    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {formatDate(item?.startDate)}
                    </td>
                    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {formatDate(item?.endDate)}
                    </td>
                    <td className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      {/* action dropdown with edit and generate summary */}
                      <div className='dropdown dropdown-inline'>
                        <button
                          className='btn btn-sm btn-bg-light btn-active-light-primary cursor-pointer dropdown-toggle'
                          data-bs-toggle='dropdown'
                          aria-expanded='false'
                        >
                          Action
                        </button>
                        <ul className='dropdown-menu dropdown-menu-end'>
                          <li>
                            <a
                              className='dropdown-item cursor-pointer'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_edit_app'
                              onClick={() => {
                                setMonth(item?.month)
                                setUpdateId(item?._id)
                                setStartDate(new Date(item?.startDate))
                                setEndDate(new Date(item?.endDate))
                                setEmployeeData(item?.recordedTime)
                              }}
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <Link
                              to={`/project/category-summary/${item?._id}`}
                              className='dropdown-item cursor-pointer'
                            >
                              Generate Category Summary
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/project/individual-summary/${item?._id}`}
                              className='dropdown-item cursor-pointer'
                            >
                              Generate Indiviudal Summary
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_create_app' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder'>Generate TimeSheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='required fw-bold fs-6 mb-2'>Select Month</label>
                <input
                  type='month'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={month}
                  required
                  onChange={(e: any) => setMonth(e.target.value)}
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      startDate?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setStartDate(e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      endDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                      <th className='min-w-125px'>Department</th>
                      <th className='min-w-125px'>Roles</th>
                      <th className='min-w-125px'>Regular</th>
                      <th className='min-w-125px'>OT</th>
                    </tr>
                  </thead>

                  <tbody>
                    {team?.map((team, i) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {team?.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.department}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.employee?.role}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Regular'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.regular}
                            onChange={(e) => {
                              const data = [...employeeData]
                              data[i].regular = Number(e.target.value)
                              setEmployeeData(data)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='OT'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.ot}
                            onChange={(e) => {
                              const data = [...employeeData]
                              data[i].ot = Number(e.target.value)
                              setEmployeeData(data)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_create_app_cancel'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={loading}
                onClick={handleSubmit}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_edit_app' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder'>Edit TimeSheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='required fw-bold fs-6 mb-2'>Select Month</label>
                <input
                  type='month'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={month}
                  required
                  onChange={(e: any) => setMonth(e.target.value)}
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      startDate?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setStartDate(e.target.value)}
                  />
                </div>
                <div className='col-md-6'>
                  <label className='required fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      endDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0]
                    }
                    required
                    onChange={(e: any) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                      <th className='min-w-125px'>Department</th>
                      <th className='min-w-125px'>Roles</th>
                      <th className='min-w-125px'>Regular</th>
                      <th className='min-w-125px'>OT</th>
                    </tr>
                  </thead>

                  <tbody>
                    {team?.map((team, i) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {team?.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.department}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.employee?.role}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Regular'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.regular}
                            onChange={(e) => {
                              const data = [...employeeData]
                              data[i].regular = Number(e.target.value)
                              setEmployeeData(data)
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='OT'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.ot}
                            onChange={(e) => {
                              const data = [...employeeData]
                              data[i].ot = Number(e.target.value)
                              setEmployeeData(data)
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_edit_app_cancel'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-warning'
                disabled={loading}
                onClick={handleEdit}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='kt_modal_view' tabIndex={-1} aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-850px'>
          <div className='modal-content'>
            <div className='modal-header' id='kt_modal_create_app_header'>
              <h2 className='fw-bolder uppercase'>{monthData} Timesheet</h2>

              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='bi bi-x fs-3'></i>
              </button>
            </div>
            <div className='modal-body scroll-y mx-5 my-7'>
              {/* select month */}
              <div className='fv-row mb-10'>
                <label className='fw-bold fs-6 mb-2'>Month</label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Month'
                  value={monthData}
                  readOnly
                />
              </div>

              <div className='fv-row mb-10 gx-10 row'>
                <div className='col-md-6'>
                  <label className='fw-bold fs-6 mb-2'>Start Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='Start Date'
                    value={
                      startDate?.toISOString().split('T')[0] ||
                      new Date().toISOString().split('T')[0]
                    }
                    readOnly
                  />
                </div>
                <div className='col-md-6'>
                  <label className='fw-bold fs-6 mb-2'>End Date</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    placeholder='End Date'
                    value={
                      endDate?.toISOString().split('T')[0] || new Date().toISOString().split('T')[0]
                    }
                    readOnly
                  />
                </div>
              </div>

              {/* employee table with regular and OT input field */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 w-100'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Employees</th>
                      <th className='min-w-125px'>Department</th>
                      <th className='min-w-125px'>Roles</th>
                      <th className='min-w-125px'>Regular</th>
                      <th className='min-w-125px'>OT</th>
                    </tr>
                  </thead>

                  <tbody>
                    {team?.map((team, i) => (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px me-5'>
                              <img src={`${profile}`} className='img-fluid rounded-circle' alt='' />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <div
                                // to={`view-employee/${item._id}`}
                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                              >
                                {team?.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.department}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {team?.employee?.role}
                          </a>
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='Regular'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.regular}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            type='number'
                            className='form-control form-control-solid'
                            placeholder='OT'
                            min='0'
                            value={employeeData?.length > 0 && employeeData[i]?.ot}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='modal-footer flex-center'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary me-3'
                data-bs-dismiss='modal'
                id='kt_modal_create_app_cancel'
                onClick={() => {
                  setStartDate(new Date())
                  setEndDate(new Date())
                  setMonth(new Date().getMonth() + 1)
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
