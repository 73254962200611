import React, {Fragment} from 'react'

// Define interfaces for TimeSlot and DaySchedule
interface TimeSlot {
  start: number
  end: number
  className: string
}

interface DaySchedule {
  dayStart: number
  dayEnd: number
  workStart: number
  workEnd: number
  breaks: TimeSlot[]
}

// Function to calculate the progress bar segments
const generateProgressBarSegments = (
  schedule: DaySchedule
): {color: string; percentage: number}[] => {
  const progressSegments: {color: string; percentage: number}[] = []
  const totalWorkingHours = schedule.workEnd - schedule.workStart

  // Iterate through the hours of the day
  for (let hour = schedule.dayStart; hour < schedule.dayEnd; hour++) {
    const className = getClassForTime(schedule, hour)
    progressSegments.push({color: className, percentage: 100 / totalWorkingHours})
  }
  return progressSegments
}

// Function to determine the class for each hour

const getClassForTime = (schedule: DaySchedule, currentTime: number): string => {
  if (currentTime < schedule.workStart || currentTime >= schedule.workEnd) {
    return 'bg-secondary' // If it's outside of work hours
  }

  for (const timeSlot of schedule.breaks) {
    if (currentTime >= timeSlot.start && currentTime < timeSlot.end) {
      return 'bg-danger' // If it's a break time
    }
  }
  return 'bg-success' // If it's work time
}

// Progress bar component
const ProgressBar: React.FC<{schedule: DaySchedule}> = ({schedule}) => {
  const progressSegments = generateProgressBarSegments(schedule)

  return (
    <>
      <div className='' style={{position: 'relative'}}>
        <div
          className='dot bg-primary'
          style={{
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translate(0, -225%)',
            width: 7,
            height: 7,
            borderRadius: '50%',
          }}
        />
        <div
          className='dot bg-success'
          style={{
            position: 'absolute',
            left: `${
              ((schedule.workStart - schedule.dayStart) / (schedule.dayEnd - schedule.dayStart)) *
              100
            }%`,
            top: '50%',
            transform: 'translate(0, -225%)',
            width: 7,
            height: 7,
            borderRadius: '50%',
          }}
        />
        <div
          className='dot bg-success'
          style={{
            position: 'absolute',
            left: `${
              ((schedule.workEnd - schedule.dayStart) / (schedule.dayEnd - schedule.dayStart)) * 100
            }%`,
            top: '50%',
            transform: 'translate(0, -225%)',
            width: 7,
            height: 7,
            borderRadius: '50%',
          }}
        />
        <div
          className='dot bg-primary'
          style={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translate(0, -225%)',
            width: 7,
            height: 7,
            borderRadius: '50%',
          }}
        />

        {/* Red dots for breaks */}
        {schedule.breaks.map((timeSlot, index) => (
          <Fragment key={index}>
            <div
              className='dot bg-danger'
              style={{
                position: 'absolute',
                left: `${
                  ((timeSlot.start - schedule.dayStart) / (schedule.dayEnd - schedule.dayStart)) *
                  100
                }%`,
                top: '50%',
                transform: 'translate(0, -225%)',
                width: 7,
                height: 7,
                borderRadius: '50%',
              }}
            />
            <div
              className='dot bg-danger'
              style={{
                position: 'absolute',
                left: `${
                  ((timeSlot.end - schedule.dayStart) / (schedule.dayEnd - schedule.dayStart)) * 100
                }%`,
                top: '50%',
                transform: 'translate(0, -225%)',
                width: 7,
                height: 7,
                borderRadius: '50%',
              }}
            />
          </Fragment>
        ))}

        <div
          className='progress'
          role='progressbar'
          aria-label='2px high'
          aria-valuemin={0}
          aria-valuemax={100}
          style={{height: 2}}
        >
          {/* Render progress bars */}
          {progressSegments.map((segment, index) => (
            <div
              key={index}
              className={`progress-bar ${segment.color}`}
              style={{width: `${segment.percentage}%`}}
            />
          ))}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 5}}>
          {[...Array(schedule.dayEnd - schedule.dayStart)].map((_, hour) => (
            <div key={hour} style={{width: `${100 / 9}%`, textAlign: 'start'}}>
              {schedule.dayStart + hour}:00
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ProgressBar
