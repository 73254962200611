import {createSlice} from '@reduxjs/toolkit'

const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    formsData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setFormsData(state, action) {
      state.formsData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setFormsData, setLoading, setError} = formsSlice.actions
export default formsSlice.reducer
