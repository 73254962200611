import React, {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {getAllDepartment} from '../../core/_requests'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'
import {useAuth} from '../../../../modules/auth'

const Step3: FC = () => {
  const {currentUser} = useAuth()
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [roles, setRoles] = useState({})

  const fetchDepartments = async () => {
    try {
      const response = await getAllDepartment(currentUser?.organization)
      let temp = {}
      response.forEach((item) => {
        temp[item._id] = item.roles
      })
      setRoles(temp)
    } catch (error) {
      console.error('Error fetching departments:', error)
    }
  }

  useEffect(() => {
    fetchDepartments()
  }, [])

  const calculateValues = (changedField: string, value: number) => {
    const workingDaysPerMonth = 22 // Assuming 22 working days per month

    let monthlyPay = Number(values.monthlyPay) || 0
    let hourlyPay = Number(values.hourlyPay) || 0
    let dailyWorkingHours = Number(values.dailyWorkingHours) || 0
    let dailyPay = Number(values.dailyPay) || 0

    switch (changedField) {
      case 'monthlyPay':
        monthlyPay = value
        dailyPay = monthlyPay / workingDaysPerMonth
        hourlyPay = dailyWorkingHours ? dailyPay / dailyWorkingHours : 0
        break
      case 'hourlyPay':
        hourlyPay = value
        dailyPay = hourlyPay * dailyWorkingHours
        monthlyPay = dailyPay * workingDaysPerMonth
        break
      case 'dailyWorkingHours':
        dailyWorkingHours = value
        dailyPay = hourlyPay * dailyWorkingHours
        monthlyPay = dailyPay * workingDaysPerMonth
        break
      case 'dailyPay':
        dailyPay = value
        monthlyPay = dailyPay * workingDaysPerMonth
        hourlyPay = dailyWorkingHours ? dailyPay / dailyWorkingHours : 0
        break
    }

    setFieldValue('monthlyPay', monthlyPay.toFixed(2))
    setFieldValue('hourlyPay', hourlyPay.toFixed(2))
    setFieldValue('dailyWorkingHours', dailyWorkingHours.toFixed(2))
    setFieldValue('dailyPay', dailyPay.toFixed(2))
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Salary Information</h2>
        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Daily Working Hours:</label>
        <Field
          type='number'
          name='dailyWorkingHours'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('dailyWorkingHours', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='dailyWorkingHours' />
        </div>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Monthly Pay :</label>
        <Field
          type='number'
          name='monthlyPay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('monthlyPay', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='monthlyPay' />
        </div>
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label required col-md-3'>Hourly Pay :</label>
        <Field
          type='number'
          name='hourlyPay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
          required
          onChange={(e) => calculateValues('hourlyPay', parseFloat(e.target.value) || 0)}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='hourlyPay' />
        </div>
      </div>

      <div className='mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
        <label className='form-label col-md-3'>Daily Pay:</label>
        <Field
          type='number'
          name='dailyPay'
          className='form-control form-control-lg form-control-solid'
          onChange={(e) => calculateValues('dailyPay', parseFloat(e.target.value) || 0)}
        />
      </div>

      <div className='d-flex whitespace-nowrap space-x-4 items-center w-100 mb-10'>
        <label className='form-label col-md-3'>Over Time Pay :</label>
        <Field
          type='number'
          name='overtimePay'
          placeholder='0.00'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='overtimePay' />
        </div>
      </div>
    </div>
  )
}

export {Step3}
