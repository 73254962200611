import React from 'react'
import {Route, Routes} from 'react-router-dom'
import WorkOrder from './pages/WorkOrder'
import AddWorkOrder from './components/AddWorkOrder'
import AddWorkOrderWithItem from './components/AddWorkOrderWithItem'
import WorkOrderDetail from './components/WorkOrderDetail'
import EditWorkOrder from './components/EditWorkOrder'
import Document from './pages/Document'
import {FilesPage} from './components/FilesPage'
import ServiceTask from './components/service/ServiceTask'
import AddServiceTask from './components/service/AddServiceTask'

const Maintenance = () => {
  return (
    <Routes>
      <Route path='/work-order' element={<WorkOrder />} />
      <Route path='/work-order/add' element={<AddWorkOrder />} />
      <Route path='/work-order/add/:id' element={<AddWorkOrderWithItem />} />
      <Route path='/work-order/edit/:id' element={<EditWorkOrder />} />
      <Route path='/work-order/:id' element={<WorkOrderDetail />} />
      <Route path='/tasks' element={<ServiceTask />} />
      <Route path='/tasks/add' element={<AddServiceTask />} />

      <Route path='/documents' element={<Document />} />
      <Route path='/documents/:id' element={<FilesPage />} />
    </Routes>
  )
}

export default Maintenance
