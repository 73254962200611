import React, {useEffect, useRef, useState} from 'react'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {createCustomerSchemas} from '../AddCustomer/Helper'
import {Form, Formik, FormikValues} from 'formik'
import {Step1} from '../AddCustomer/steps/Step1'
import {Step2} from '../AddCustomer/steps/Step2'
import {Step3} from '../AddCustomer/steps/Step3'
import {Step4} from '../AddCustomer/steps/Step4'
import {Step5} from '../AddCustomer/steps/Step5'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import {CustomerInterface} from '../../Core/_model'
import {editCustomer, getCustomerByID} from '../../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'

export const EditCustomer = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createCustomerSchemas[0])
  const [initValues, setInitValues] = useState<CustomerInterface>()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createCustomerSchemas[stepper.current.currentStepIndex - 1])

  }

  // ...

  const submitStep = async (values: CustomerInterface, actions: FormikValues) => {
    console.log(values)
    console.log('Continue button')
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      // Log the account type
      stepper.current.goNext()
    } else {
      const data = {
        ...values,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      const res = await editCustomer(data, id) //yeha change hoga
      console.log(res.data)
      navigate('/sales/customer')
      stepper.current.goto(1)
      actions.resetForm()
    }

    setCurrentSchema(createCustomerSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const response = await getCustomerByID(id)
          console.log(response)
          setInitValues(response)
        } catch (error) {
          console.error('Error fetching customer details:', error)
        }
      }
    }
    fetchData()
  }, [stepperRef])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Required Fields</Modal.Title>
          </Modal.Header>
          <Modal.Body>Please fill in all required fields before proceeding.</Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className='card d-flex overflow-auto justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='justify-content-between align-items-center'>
            <div className='px-6 px-lg-10 px-xxl-15 py-20 justify-content-between '>
              <div className='stepper-nav'>
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Customer Type</h3>
                      <div className='stepper-desc-dark fw-semibold'>Choose your Customer Type</div>
                    </div>
                  </div>

                  <div className='stepper-line h-40px'></div>
                </div>

                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Basic Details</h3>
                      <div className='stepper-desc-dark fw-semibold'>
                        Provide us with your basic details
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Other details</h3>
                      <div className='stepper-desc-dark fw-semibold'>
                        Provide us with other details
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 4*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Address</h3>
                      <div className='stepper-desc-dark fw-semibold'>Provide us your Address</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 4*/}

                {/* begin::Step 5*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title-dark'>Edit Contact Person</h3>
                      <div className='stepper-desc-dark fw-semibold'>
                        Provide us your Contact persons
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 5*/}
              </div>
              {/* end::Nav*/}
            </div>
            {/* end::Wrapper*/}
          </div>
        </div>
        {/* begin::Aside*/}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          {initValues && (
            <Formik
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {() => (
                <Form
                  className='py-20 w-100 w-xl-700px px-9'
                  noValidate
                  id='kt_create_account_form'
                >
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step3 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step4 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step5 />
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      {stepper.current?.currentStepIndex !== 5 && (
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                          Previous
                        </button>
                      )}
                    </div>

                    <div>
                      {stepper.current?.currentStepIndex !== 5 && (
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>Continue</span>
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </button>
                      )}

                      {stepper.current?.currentStepIndex === 5 && (
                        <button type='submit' className='btn btn-lg btn-primary me-3'>
                          <span className='indicator-label'>Submit</span>
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}
