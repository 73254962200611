import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  rfqData: [],
  totalPages: 1,
  loading: false,
  error: null,
  fetch: true,
}

const rfqSlice = createSlice({
  name: 'rfq',
  initialState,
  reducers: {
    setRfqDataList(state, action) {
      state.rfqData = action.payload.rfq
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setRFQDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setRfqDataList, setLoading, setError, setRFQDataFetch} = rfqSlice.actions
export default rfqSlice.reducer
