import { useState } from "react"
import { useAuth } from "../../../modules/auth"
import EmployeesSummaryCard from "./EmployeeSummaryCard"


const EmployeePerformance: React.FC = () => {
  const {currentUser} = useAuth()
  const orgid = currentUser.organization
  const [employeePerformanceData, setEmployeePerformanceData] = useState([])

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await getProjectPerformance(orgid)
//         // Sort data by performance percentage in descending order
//         data.sort((a, b) => b.performancePercentage - a.performancePercentage)
//         setProjectPerformanceData(data)
//         console.log('projectperformance', projectPerformanceData)
//       } catch (error) {
//         console.error('Error fetching project performance data:', error)
//       }
//     }

//     fetchData()
//   }, [orgid])

  // Slice the first 5 projects from the salesPerformanceData array
  const top5Employees = employeePerformanceData.filter((project) => !project.isDeleted).slice(0, 5)

  return (
    <div>
      <div className='flex-1'>
        <EmployeesSummaryCard projects={top5Employees} />
      </div>
      <div className='card p-4'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Employees Performance</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Employees</th>
                  <th className='min-w-140px'>No.of Targets</th>
                  <th className='min-w-140px'>No.of Completed Targets</th>
                  <th className='min-w-140px'>No.of In Progress Tasks</th>
                  <th className='min-w-140px'>No.of Products</th>
                  <th className='min-w-140px'>No.of Employees</th>
                  <th className='min-w-140px'>Total Expense</th>
                  <th className='min-w-140px'>Total Budget</th>
                  <th className='min-w-140px'>Performance Percentage</th>
                </tr>
              </thead>
              {/* <tbody>
                {employeePerformanceData
                  .filter((project) => !project.isDeleted)
                  .map((project, index) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'></div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {project.projectName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className='text-start'>{project.numOfTargets}</td>
                      <td className='text-start'>{project.numOfCompletedTargets}</td>
                      <td className='text-start'>{project.numOfInProgressTasks}</td>
                      <td className='text-start'>{project.numOfProducts}</td>
                      <td className='text-start'>{project.numOfEmployees}</td>
                      <td className='text-start'>
                        {project?.totalExpense?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {project.currency}
                      </td>
                      <td className='text-start'>
                        {project?.totalBudget?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {project.currency}
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {project.performancePercentage.toFixed(2)}%
                            </span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-info'
                              role='progressbar'
                              style={{width: `${project.performancePercentage}%`}}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeePerformance
