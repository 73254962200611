import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getServiceReminders} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setServiceReminderData} from '../../../../redux/maintenance/reminders/serviceReminder/serviceReminderSlice'

const ServiceReminder = () => {
  const {currentUser} = useAuth()
  // const [reminderList, setReminderList] = useState([])
  const dispatch = useDispatch()
  const reminderList = useSelector(
    (state: any) => state.maintenance.serviceReminder.serviceReminderData
  )

  const fetchReminder = async () => {
    const res = await getServiceReminders(currentUser.organization)
    console.log(res)
    // setReminderList(res)
    dispatch(setServiceReminderData(res))
  }

  useEffect(() => {
    fetchReminder()
  }, [])

  const formatDate = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center justify-between'>
          <h3>Service Reminder</h3>
          <Link to='add' className='btn btn-primary'>
            <KTIcon iconName='plus' className='me-2' />
            Add Reminder
          </Link>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed align-middle gs-0 gy-4'>
              <thead className='bg-gray-200'>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-2'>Vehicle</th>
                  <th>Service Task</th>
                  <th>Status</th>
                  <th>Next Due</th>
                </tr>
              </thead>
              <tbody>
                {reminderList?.map((item: any) => (
                  <tr key={item?._id}>
                    <td className='ps-2'>{item?.vehicle?.productName}</td>
                    <td>{item?.serviceTask?.name}</td>
                    <td>
                      {
                        // status will be overdue if the timeDue is less than the current date or it eill be upcoming
                        new Date(item?.timeDue) < new Date() ? 'Overdue' : 'Upcoming'
                      }
                    </td>
                    <td>{formatDate(new Date(item?.timeDue))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceReminder
