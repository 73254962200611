import axios, {Axios, AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const REPORTS_URL=`${API_URL}/reports`
const SALES_PERFORMANCE_URL=`${REPORTS_URL}/salesperformance`
const VENDOR_PERFORMANCE_URL=`${REPORTS_URL}/vendorperformance`
const PROJECT_PERFORMANCE_URL=`${REPORTS_URL}/projectperformance`


//get Sales Performance
export const getSalesPerformance = async (orgid: string, startDate: string, endDate: string) => {
    try {
      const response = await axios.get(`${SALES_PERFORMANCE_URL}/customerreports/${orgid}?startDate=${startDate}&endDate=${endDate}`)
      return response.data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }


  //get Vendor Performance
export const getVendorPerformance = async (orgid: string, startDate: string, endDate: string) => {
    try {
      const response = await axios.get(`${VENDOR_PERFORMANCE_URL}/vendorreports/${orgid}?startDate=${startDate}&endDate=${endDate}`)
      return response.data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }

  
  //get Project Performance
export const getProjectPerformance = async (orgid: string) => {
  try {
    const response = await axios.get(`${PROJECT_PERFORMANCE_URL}/projectreports/${orgid}`)
    console.log("Response of project performance", response.data);
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}