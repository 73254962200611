import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getInvoicebyids} from '../../Core/_requests'
import './invoiceThird.css'

function InvoiceThird({companyLogo}) {
  const [vat, setVat] = useState(0)

  const id = useParams().id
  const [data, setdata] = useState<any>()
  
  useEffect(() => {
    const getinvoicedetails = async () => {
      await getInvoicebyids(id).then((res) => {
        console.log('data', res)
        setdata(res)
      })
    }

    getinvoicedetails()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  return (
    <div className='a4-wrapper flex-column flex-row-fluid' id='kt_app_main'>
      <div id='pdf-content' className='print-content'>
        <div className='app-content border-8  flex-column-fluid '>
          <div className='p-10'>
            <div className='d-flex justify-content-between align-items-center flex-row mb-4 bg-primary rounded-full px-5 py-2'>
              <span className='fw-bolder text-white fs-2qx px-3'>INVOICE</span>

              {/*end::Logo*/}
              <div className='text-end py-1 px-sm-3'>
                {/*begin::Logo*/}
                <a href='#' className='d-block mw-150px ms-auto '>
                  {companyLogo ? (
                    <img
                      alt='Logo'
                      src={URL.createObjectURL(companyLogo)}
                      className='w-sm-75 w-50'
                    />
                  ) : (
                    <img
                      alt='Upload Logo'
                      src={'/media/logo.png'}
                      className='sm:w-100 w-50  p-2 rounded-2'
                    />
                  )}
                </a>
                {/*end::Logo*/}
              </div>
            </div>
            <div className='d-flex flex-sm-row flex-col justify-content-between align-items-start px-8 mt-4'>
              <div className=''>
                <span className='badge-light-primary p-2 rounded-lg fw-bold fs-4'>
                  Invoice #{data?.id}
                </span>
              </div>
              <div className=''>
                {/*end::Label*/}
                <div className='fw-semibold fs-5 text-gray-600 mb-1'>Issue Date:</div>
                {/*end::Label*/}
                {/*end::Col*/}
                <div className='fw-bold fs-4 text-gray-800'>{formatCreatedAt(data?.createdAt)}</div>
                {/*end::Col*/}
              </div>
              <div className='fs-5 fw-bold'>
                <p>
                  <strong className='text-muted fs-7'>Status</strong> <br />
                  <span className='fs-3'>
                    {data?.status === 'rejected' ? (
                      <span className='badge badge-light-danger'>Rejected</span>
                    ) : data?.status === 'pending' ? (
                      <span className='badge badge-light-primary'>Pending</span>
                    ) : (
                      <span className='badge badge-light-success'>Approved</span>
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='app-container container-xxl'>
            {/* Invoice Details */}
            <div className='invoice-details p-4 py-6 fs-4 '>
              <p className='mb-2 fw-bold text-gray-800'>
                Dear {data?.customer?.primaryContact?.firstName}{' '}
                {data?.customer?.primaryContact?.lastName},
              </p>
              <p className=' text-gray-600 fs-7'>
                Here are your order details. We thank you for your purchase.
              </p>

              {/* <div className="row my-4">
                        <div className="col-sm-6">
                            <p className="text-secondary">Issue Date:</p>
                            <p>{formatCreatedAt(data?.createdAt)}</p>
                        </div>
                        <div className="col-sm-6">
                            <p className="text-secondary">Billing Address:</p>
                            <p>
                                {data?.customer?.billingAddress.addressLine1}{" "}
                                {data?.customer?.billingAddress.addressLine2}
                                <br />
                                {data?.customer?.billingAddress.city}{" "}
                                {data?.customer?.billingAddress.postalCode}
                                <br />
                                {data?.customer?.billingAddress.country}
                            </p>
                        </div>
                    </div> */}
              {/*begin::Wrapper*/}
              <div className='m-0 px-10 pt-8'>
                <div className='flex-row  d-flex g-5 mb-12 justify-content-around bg-secondary py-8 px-8'>
                  <div className='col-sm-6 border-e border-separate border-gray-500 d-flex flex-column align-items-start px-3'>
                    <div className='fw-bold fs-5 text-dark mb-1'>Billing Address:</div>
                    <div className='fw-semibold fs-7 text-gray-600 text-start'>
                      {data?.customer?.billingAddress?.addressLine1}{' '}
                      {data?.customer?.billingAddress?.addressLine2}
                      <br />
                      {data?.customer?.billingAddress?.city}{' '}
                      {data?.customer?.billingAddress?.postalCode}
                      <br />
                      {data?.customer?.billingAddress?.country}
                    </div>
                  </div>
                  <div className='col-sm-6 d-flex flex-column align-items-end px-3'>
                    <div className='fw-bold fs-5 text-dark mb-1'>Shipping Address:</div>
                    <div className='fw-semibold fs-7 text-gray-600 text-end'>
                      {data?.customer?.shippingAddress?.addressLine1}{' '}
                      {data?.customer?.shippingAddress?.addressLine2}
                      <br />
                      {data?.customer?.shippingAddress?.city}{' '}
                      {data?.customer?.shippingAddress?.postalCode}
                      <br />
                      {data?.customer?.shippingAddress?.country}
                    </div>
                  </div>
                </div>

                {/*end::Row*/}
              </div>
              {/*end::Wrapper*/}
            </div>

            {/* Invoice Items Table */}
            <div className='table-responsive  mb-10'>
              <table
                className='table table-bordered overflow-auto py-2'
                style={{minHeight: '280px'}}
              >
                <thead>
                  <tr className='bg-primary border-white '>
                    <th className='text-white px-2'>Description</th>
                    <th className='text-center text-white'>Quantity</th>
                    <th className='text-center text-white'>Rate</th>
                    <th className='text-center text-white'>Discount</th>
                    <th className='text-center text-white'>Amount</th>
                  </tr>
                </thead>
                <tbody className='align-items-start'>
                  {data?.items?.map((item, index) => (
                    <tr key={index} className='border-b'>
                      <td className='fw-semibold px-2 '>{item?.productName}</td>
                      <td className='text-center'>{item.quantity}</td>
                      <td className='text-center'>
                        {Number(item.price).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                        {/* {data?.customer?.currency} */}
                      </td>
                      <td className='text-center'>{item.discount}</td>
                      <td className='text-center'>
                        {Number(item?.amount).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {/* {data?.customer?.currency} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Summary Section */}
            {/* <div className="invoice-summary d-flex justify-content-end p-4">
                        <table className="table ">
                            <tbody>
                                <tr>
                                    <th>Subtotal:</th>
                                    <td>$ {data?.subtotal}</td>
                                </tr>
                                <tr>
                                    <th>Tax ({data?.tax}%):</th>
                                    <td>$ {data?.tax}</td>
                                </tr>
                                <tr>
                                    <th>Total:</th>
                                    <td>$ {data?.total}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}

            <div className='flex-sm-row flex-col d-flex mb-12 justify-content-between align-items-start'>
              <div className=' px-6 pt-4'>
                <div className='mb-6 fs-4 fw-bolder text-dark text-hover-primary'>
                  Note: <span className='fw-semibold text-gray-600 fs-6'>{data?.notes}</span>
                </div>
                <div className='mb-6 fs-4 fw-bolder text-dark text-hover-primary'>
                  Terms and Conditions:
                  <br />
                  <div className='fw-semibold text-gray-600 fs-6 w-1/2'>
                    {data?.termsNCondition}
                  </div>
                </div>

                {data?.paymentReceived && (
                  <div className=''>
                    <h6 className='mb-4 fs-4 fw-bolder text-dark text-hover-primary'>
                      Payment Info
                    </h6>
                    {/* end::Title */}
                    {/* begin::Item */}
                    <div className='mb-3'>
                      <p className='fw-semibold text-gray-600 fs-7'>
                        Payment Mode:
                        <p className='fw-bold text-gray-600 fs-6'>codelabpay@codelab.co</p>
                      </p>
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <div className='mb-3 '>
                      <p className='fw-semibold text-gray-600 fs-7'>
                        Deposit To:
                        <p className='fw-bold text-gray-600 fs-6'>
                          Nl24IBAN34553477847370033
                          <br />
                          AMB NLANBZTC
                        </p>
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex px-6 '>
                {/*begin::Section*/}
                <table className='table mw-400px invoice-summary'>
                  <tbody>
                    {/* Subtotal */}
                    <tr className='mb-4'>
                      <td className='fw-bold text-gray-600 fs-6'>Subtotal:</td>
                      <td className='text-end fw-bold fs-5 text-gray-800'>
                        {data?.subtotal.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {data?.customer?.currency}
                      </td>
                    </tr>
                    {/* Tax */}
                    <tr className='mb-4'>
                      <td className='fw-bold text-gray-600 fs-6'>Tax ({data?.tax}%):</td>
                      <td className='text-end fw-bold fs-6 text-gray-800'>{vat}</td>
                    </tr>
                    {/* Subtotal + Tax */}
                    <tr className='mb-4'>
                      <td className='fw-bold text-gray-600 fs-6'>Subtotal + Tax:</td>
                      <td className='text-end fw-bold fs-5 text-gray-800'>
                        {data?.total?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {data?.customer?.currency}
                      </td>
                    </tr>
                    {/* Total */}
                    <tr className='border-top border-bottom py-1'>
                      <th className='fw-bold text-dark fs-3'>Total</th>
                      <td className='text-end fw-bold fs-5 text-gray-800 '>
                        {data?.total?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {data?.customer?.currency}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/*end::Section*/}
              </div>
            </div>
            {/* Footer Note */}
            <div className='card-footer'>
              <div className='d-flex flex-row justify-content-between fs-4 fw-semibold  px-5'>
                <div className='border-t py-2 border-dark'>
                  <span>Client Signature</span>
                </div>
                <div className='border-t py-2 border-dark'>
                  <span>Authorized Signature</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceThird
