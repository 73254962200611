import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getInspectionForms, getInspectionRecords} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setInspectionHistoryData} from '../../../../redux/maintenance/inspection/inspectionHistory/inspectionHistorySlice'

const InspectionHistory = () => {
  const {currentUser} = useAuth()
  const [forms, setForms] = useState([])
  // const [list, setList] = useState([])

  const dispatch = useDispatch()
  const list = useSelector(
    (state: any) => state.maintenance.inspectionHistory.inspectionHistoryData
  )

  const fetchData = async () => {
    const res = await getInspectionForms(currentUser?.organization)
    console.log(res)
    setForms(res)
  }

  const fetchList = async () => {
    const res = await getInspectionRecords(currentUser?.organization)
    console.log(res)
    // setList(res)
    dispatch(setInspectionHistoryData(res))
  }

  useEffect(() => {
    fetchList()
    fetchData()
  }, [])

  const handleCloseModal = () => {
    const closeModalButton = document.getElementById('closeModalButton1')
    closeModalButton?.click()
  }

  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Inspection History</h4>
          <div className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#kt_modal_13'>
            <KTIcon iconName='plus' className='me-2' />
            Start Inspection
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Inspection Item</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Inspection Form</th>
                  <th className='min-w-125px'>User</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/inspection/history/view/${item?._id}`}>
                          {item?.product?.productName}
                        </Link>
                      </td>
                      <td>{formatDate(item?.createdAt)}</td>
                      <td>{item?.form?.title}</td>
                      <td>{item?.createdBy?.fullName}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className='modal fade' tabIndex={-1} id='kt_modal_13'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Select Inspection Form</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>

              <div className='modal-body'>
                {forms?.map((form, index) => {
                  return (
                    <div key={index} className='border-bottom py-3'>
                      <div>
                        <Link
                          to={`/inspection/history/add/${form?._id}`}
                          className='fw-bold'
                          onClick={handleCloseModal}
                        >
                          <span className='me-2' style={{color: form?.color}}>
                            &#9679;
                          </span>
                          {form?.title}
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  id='closeModalButton1'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InspectionHistory
