import React, { useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import Select from 'react-select'
import { useAuth } from '../../../../modules/auth'
import profile from '../../../../../_metronic/assets/profile.png'
import { getAllDepartment, getEmployeeByDepart } from '../../../hrm/core/_requests'

export default function AddTeam() {
  const { currentUser } = useAuth()
  const { setFieldValue } = useFormikContext()
  const { values } = useFormikContext()
  const [employeeList, setEmployeeList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [totalHourlyPay, setTotalHourlyPay] = useState(0)
  const [totalHourlyCharges, setTotalHourlyCharges] = useState(0)
  const [masterCheckbox, setMasterCheckbox] = useState(false)

  useEffect(() => {
    const updatedTableData = employeeList.map((item) => ({
      ...item,
      isChecked: masterCheckbox,
    }))
    setEmployeeList(updatedTableData)
    console.log("emp", employeeList)
  }, [masterCheckbox])


  const DepartmentData = async () => {
    try {
      const res = await getAllDepartment(currentUser?.organization)
      console.log('Get all Department Data', res)

      setDepartmentList(res.map((dept) => ({
        value: dept._id,
        label: dept.name,
      }
      )))
    }
    catch (error) {
      console.error('Error fetching department data:', error);
    }
  }

  useEffect(() => {
    DepartmentData()
  }, [])

  const handleDepartmentChange = async (selectedOption) => {
    if (selectedOption) {
      const selectedDepartment = {
        id: selectedOption.value,
        department: selectedOption.label,
      };
      setFieldValue('department', selectedDepartment);
      try {
        const employeeList = await getEmployeeByDepart(selectedOption.value)
        console.log("employeeList ", employeeList)
        setEmployeeList(
          employeeList
            .filter(employee => employee.isActivated)
            .map((r) => ({
              value: r._id,
              label: r.firstName,
              email: r.email,
              department: r?.department?.name,
              dailyPay: r?.dailyPay,
              hourlyPay: r?.hourlyPay,
            }))
        )
      } catch (error) {
        console.error("Error fetching Employee list")
      }
      console.log("employeeList ", employeeList)
    } else {
      setFieldValue('department', null);
    }
  }

  const handleAddEmployee = () => {
    const selectedEmployees = employeeList.filter((employee) => employee.isChecked);
    const updatedTeam = [
      ...values.team,
      ...selectedEmployees.map((employee) => ({
        employee: employee.value,
        name: employee.label,
        email: employee.email,
        department: employee.department,
        dailyPay: employee.dailyPay,
        hourlyPay: employee.hourlyPay,
      }))
    ];
    setFieldValue('team', updatedTeam);
  };


  useEffect(() => {
    const totalHourlyPay = values.team.reduce((acc, teamMember) => acc + (teamMember.hourlyPay || 0), 0)
    const totalHourlyCharges = (values?.totalCalculatedHours) * totalHourlyPay
    setTotalHourlyPay(totalHourlyPay)
    setTotalHourlyCharges(totalHourlyCharges)
    setFieldValue("totalHourlyCharges", totalHourlyCharges)
  }, [values.team])

  return (
    <div className='w-100 '>
      <div className='pb-12 '>
        <h1 className='fw-bold text-dark'>Build a Team</h1>
        <div className='text-muted fw-semibold fs-4'>
          If you need more info, please check
          <a href='#' className='link-primary'>
            Project Guidelines
          </a>
        </div>
      </div>

      <div className='mb-8 relative z-10' style={{ position: 'relative', zIndex: '9999' }}>
        <label className='fs-6 fw-semibold mb-2 '>Select Department</label>

        <Field
          component={Select}
          name='teamMembers'
          aria-label='Select Team Members'
          placeholder='Add project members by name'
          options={departmentList}
          isSearchable={true}
          className='form-control form-control-solid absolute z-999'
          onChange={handleDepartmentChange}
        // isMulti={true}
        />

      </div>

      {employeeList.length > 0 &&
        <div>
          <label className='fs-6 fw-semibold mb-2'>Add Employee</label>
          <div className="card mb-4 w-12/12 mh-300px scroll-y " >
            <div className="card-body p-0">
              <table className="table table-responsive align-middle fs-6 gy-5" >
                <thead className="text-center align-center sticky-top bg-white">
                  <th className="min-w-72px fw-bold">Employee Name</th>
                  <th className="flex-fill fw-bold">Hourly Pay</th>
                  <th className="flex-fill">
                    <div className="form-check form-check-sm form-check-custom form-check-solid border-bottom-0 d-flex justify-content-center align-items-center">
                      <input className="form-check-input" type="checkbox"
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                        checked={masterCheckbox}
                        onChange={() => setMasterCheckbox(!masterCheckbox)}
                      />
                    </div>
                  </th>
                </thead>
                <tbody className="fw-semibold text-gray-800 ">
                  {employeeList.map((e, i) => (
                    <tr key={i}>
                      <td className="text-muted fw-semibold text-center">
                        <div className="text-gray-800 text-hover-primary fs-5">{e.label}</div>
                      </td>
                      <td className="text-muted fw-semibold text-center">
                        <div className="text-gray-800 text-hover-primary fs-5">{e.hourlyPay}</div>
                      </td>
                      <td className="text-muted fw-semibold ">
                        <div className="form-check form-check-sm form-check-custom form-check-solid  d-flex justify-content-center align-items-center ">
                          <input className="form-check-input" type="checkbox" value="1"
                            checked={e.isChecked}
                            onChange={() => {
                              const updatedTableData = [...employeeList]
                              updatedTableData[i].isChecked = !updatedTableData[i].isChecked
                              setEmployeeList(updatedTableData)
                            }} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="d-flex justify-content-end me-18 py-3 mb-4">
                <button type="button" class="btn btn-primary" onClick={handleAddEmployee}>
                  <span className="indicator-label">Add Employee</span>
                </button>
              </div>
            </div>
          </div>
        </div>}

      <div className='mb-3'>
        <div className='fs-6 fw-semibold mb-2'>Team Members</div>
        <div className='mh-300px scroll-y me-n7  '>
          <div className='d-flex flex-stack py-4   '>
            {/* <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='/media/avatars/300-9.jpg' />
                  </div>
                  <div className='ms-5'>
                    <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary mb-2'>
                      {teamMember.name}
                    </a>
                    <div className='fw-semibold text-muted'> {teamMember.email}</div>
                  </div>
                </div> */}

            <div className='card w-100 mb-4'>
              <div className='card-body '>
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4 w-full'>
                    <thead>
                      <tr className='fw-bold text-muted bg-light'>
                        <th className='ps-4 min-w-323px rounded-start'>Employee Names</th>
                        <th className='min-w-125px'>Department Name</th>
                        <th className='min-w-125px'>Hourly Pay</th>
                      </tr>
                    </thead>

                    <tbody>
                      {values.team &&
                        values.team.map((teamMember, index) => (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px me-5'>
                                  <img
                                    src={profile}
                                    // src='/media/avatars/300-9.jpg'
                                    className=''
                                    alt=''
                                  />
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                    {teamMember.name}
                                  </a>
                                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {teamMember.email}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className='ps-8 '>
                              {teamMember.department}
                            </td>
                            <td className='ps-8 '>
                              {teamMember.hourlyPay}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row w-100 '>
        <div className=' mb-3 d-flex whitespace-nowrap space-x-8 items-center w-100'>
          <label className='form-label col-md-8 ps-12'>
            Total employee Hourly Pay:</label>
          <Field
            type='text'
            className='form-control form-control-md form-control-solid w-150px text-center'
            value={totalHourlyPay.toLocaleString('en-US', { minimumFractionDigits: 2 })}
            readOnly
          />
          <span className='text-muted fw-semibold'>Per Hours</span>
        </div>
        <div className=' mb-3 d-flex whitespace-nowrap space-x-8 items-center w-100'>
          <label className='form-label col-md-8 ps-12'>Total Working Hours in the project</label>

          <Field
            type='text'
            className='form-control form-control-md form-control-solid w-150px text-center'
            value={values?.totalCalculatedHours}
            readOnly
          />
          <span className='text-muted fw-semibold'>Total Hours</span>
        </div>
        <div className=' mb-3 d-flex whitespace-nowrap space-x-8 items-center w-100'>
          <label className='form-label col-md-8 ps-12 '>Total Project Manpower Budget:</label>

          <Field
            type='text'
            className='form-control form-control-md form-control-solid w-150px text-center'
            value={totalHourlyCharges.toLocaleString('en-US', { minimumFractionDigits: 2 })}
            readOnly
          />
          <span className='text-muted fw-semibold'>Total Amount</span>
        </div>
      </div>

      {/* <div className='d-flex flex-stack mb-15'>
        <div className='me-5 fw-semibold'>
          <label className='fs-6'>Adding Users by Team Members</label>
          <div className='fs-7 text-muted'>If you need more info, please check budget planning</div>
        </div>

        <label className='form-check form-switch form-check-custom form-check-solid'>
          <input className='form-check-input' type='checkbox' value='' />
        </label>
      </div> */}
    </div>
  )
}
