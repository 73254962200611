import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker';

function DateRangePicker() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <div className="mb-0 ">
      
      <DatePicker
        className="form-control form form-control-solid mr-10"
        placeholderText="Start Date"
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        minDate={new Date()}
        dateFormat="MM/dd/yyyy"
        />
        <DatePicker
        className="form-control form-control-solid ml-10"
        placeholderText="End Date"
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
}

export default DateRangePicker;
