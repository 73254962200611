import React, {useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  getFilteredDeliveryNotes,
  getFilteredDeliveryNotesByAgentId,
  updateDeliveryChallanValidationStatus,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import qs from 'qs'
import {Filtertype2, RootState, ValidFilter} from '../Core/_model'
import Filter2 from '../Components/Filter2'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {useDispatch, useSelector} from 'react-redux'
import {setDeliveryNoteData} from '../../../redux/sales/deliveryNote/deliveryNote'
import Pagination from '../../purchaseOrders/Components/Pagination'
import debounce from 'lodash/debounce'

const DeliveryChallanPage = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredChallanList, setFilteredChallanList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const challanList = useSelector((state: RootState) => state.sales.deliveryNote.deliveryNoteData)
  const [isLoading, setIsLoading] = useState(false)
  const initialRef = useRef(true)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getList = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const data = await getFilteredDeliveryNotes(
        queryString,
        currentUser?.organization,
        currentPage,
        25
      )
      setFilteredChallanList(data?.deliveryNotes)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.deliveryNotes?.length).fill(false))
      return data?.deliveryNotes
    } catch (error) {
      console.error('Axios error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getListForAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const data = await getFilteredDeliveryNotesByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25
      )
      setFilteredChallanList(data?.deliveryNotes)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.deliveryNotes.length).fill(false))
      return data?.deliveryNotes
    } catch (error) {
      console.error('Axios error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getDeliveryChallansData = () => {
    if (permissions?.deliverynote?.admin === false) {
      getListForAgent()
    } else if (
      permissions?.deliverynote?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList()
    }
  }

  const getDeliveryChallans = () => {
    if (challanList.length === 0) {
      if (permissions?.deliverynote?.admin === false) {
        getListForAgent().then((res) => dispatch(setDeliveryNoteData(res)))
      } else if (
        permissions?.deliverynote?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getList().then((res) => dispatch(setDeliveryNoteData(res)))
      }
    } else {
      setFilteredChallanList(challanList)
      setDropdown(Array(challanList.length).fill(false))
    }
  }

  const debounceSearch = debounce(getDeliveryChallansData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage])

  useEffect(() => {
    getDeliveryChallans()
  }, [permissions])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleActivation = async (id, valid) => {
    const res = await updateDeliveryChallanValidationStatus(id, {valid: !valid})
    if (res) {
      getDeliveryChallans()
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
        approval: '', // Reset approval filter
        status: '', // Reset status filter
      },
    })
  }

  const setStatusFilter = (status: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status, // Update status filter value
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
        status: '',
      },
    })
    getDeliveryChallans()
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Delivery Note</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Delivery Note ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getDeliveryChallansData}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'partial', label: 'Partial'},
                {value: 'completed', label: 'Completed'},
              ]}
              setApprovalFilter={setApprovalFilter}
              setStatusFilter={setStatusFilter}
            />
          </div>
        </div>

        {(permissions?.deliverynote?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/sales/deliveryChallan/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              NEW
            </Link>
          </div>
        )}
      </div>
      <div className='relative'>
        <div className='card-body py-3'>
          {isLoading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-50px rounded-start'>Date</th>
                    <th className='min-w-150px'>Delivery Note#</th>
                    <th className='min-w-50px'>Reference Number</th>
                    <th className='min-w-50px'>Sales Order#</th>
                    <th className='min-w-50px'>Customer Name</th>
                    <th className='min-w-100px'>Status</th>
                    {/* <th className='min-w-100px'>INVOICE STATUS</th> */}
                    <th className='min-w-100px'>Invoice Status</th>
                    <th className='min-w-100px'>Approval</th>
                    {(permissions?.deliverynote?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <th className='min-w-100px text-center'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {filteredChallanList?.map((item, index) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {new Date(item?.date)?.toLocaleDateString('en-GB')}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.quotationId?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.salesOrderId?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.customer?.displayName || 'N/A'}
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.status}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/deliveryChallan/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {/* {item?.subtotal ? item?.subtotal : 0} {item?.customer?.currency} */}
                          {item?.invoiceStatus}
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/sales/deliveryChallan/details/${item.id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span
                            className={`badge  fs-7 fw-semibold ${
                              item?.approval === 'pending'
                                ? 'badge-light-primary'
                                : item?.approval === 'completed'
                                ? 'badge-light-success'
                                : 'badge-light-danger'
                            }`}
                          >
                            {item?.approval}
                          </span>
                        </Link>
                      </td>
                      {(permissions?.deliverynote?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='relative'>
                          <div className='d-flex justify-content-center flex-shrink-0'>
                            <button
                              onClick={() => handleClick(index)}
                              className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                            >
                              Actions <RiArrowDropDownLine />
                            </button>
                          </div>
                          {dropdown[index] && (
                            <div className='absolute flex flex-col top-16 right-[0px] z-10  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                              {/* <Link to={`edit/${item._id}`}>
                            <button className='hover:bg-lime-50 p-2  text-start rounded'>
                              Edit
                            </button>
                          </Link> */}
                              {item?.status === 'partial' && (
                                <Link to={`add-partial/${item?.salesOrderId?._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Add Partial
                                  </button>
                                </Link>
                              )}
                              {!item?.valid ? (
                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id, item.valid)}
                                >
                                  Valid
                                </button>
                              ) : (
                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id, item.valid)}
                                >
                                  Invalid
                                </button>
                              )}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default DeliveryChallanPage
