import React from 'react'
import {Route, Routes} from 'react-router-dom'
import FormPage from '../components/Forms/FormPage'
import FormBuilder from '../components/Forms/FormBuilder'
import InspectionHistory from '../components/inspection/InspectionHistory'
import CreateInspection from '../components/inspection/CreateInspection'
import InspectionDetail from '../components/inspection/InspectionDetail'
import ItemFailure from '../components/inspection/ItemFailure'

const InspectionPage = () => {
  return (
    <Routes>
      <Route path='/forms' element={<FormPage />} />
      <Route path='/forms/add' element={<FormBuilder />} />
      <Route path='/history' element={<InspectionHistory />} />
      <Route path='/history/add/:formId' element={<CreateInspection />} />
      <Route path='/history/view/:id' element={<InspectionDetail />} />
      <Route path='/item-failure' element={<ItemFailure />} />
    </Routes>
  )
}

export default InspectionPage
