/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  temporaryCount: number
  permanentCount: number
  freelancerCount: number
}

const EmployeeComposition: React.FC<Props> = ({className, temporaryCount, permanentCount, freelancerCount}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, permanentCount, temporaryCount, freelancerCount))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, temporaryCount, permanentCount, freelancerCount])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 justify-content-start'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 mb-1'>Employee Composition</span>
        </h3>
        {/* begin::Toolbar */}

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '250px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {EmployeeComposition}

function getChartOptions(height: number, permanentCount, temporaryCount, freelancerCount): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [temporaryCount, permanentCount, freelancerCount],
    chart: {
      fontFamily: 'inherit',
      type: 'donut',
      height: height,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          // Adjust the size of the donut hole
          labels: {
            show: true,
          },
        },
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      formatter(legendName: string, opts?: any) {
        return (
          legendName +
          ' ' +
          opts.w.globals.series[opts.seriesIndex] +
          ' - ' +
          opts.w.globals.seriesPercent[opts.seriesIndex]?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) +
          '%'
        )
      },
      width: 200,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      colors: ['white'],
      width: 6,
    },
    labels: ['Temporary', 'Permanent', 'Freelancer'], // Label for the donut chart
    colors: ['#6700FF', '#BB8FCE', '#0018ff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 10,

      padding: {
        left: 5,
        right: 5,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {},
    },
  }
}
