import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import Select from 'react-select'
// import DatePicker from 'react-datepicker';
// import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css'

// import '~flatpickr/dist/flatpickr.css';
// import Flatpickr from "flatpickr";
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css' // Import the Flatpickr CSS
// import { getAgent, getClient, getClientbyagent, getClientbycompany, getInvoiceid, getProperty, getQuoteid, postquote } from '../core/_requests';
// import { Client } from '../core/_models';

import {useAuth} from '../../../modules/auth/core/Auth'
// import { Agentlist } from '../../agent/components/Agentlist';
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {
  editRFQ,
  getEmployee,
  getRFQ,
  getRFQByRFQId,
  getStocks,
  getVendor,
  getVendorByAgentId,
} from '../Core/_requests'
import {getItems} from '../../Inventory/Core/_requests'
import {useDispatch, useSelector} from 'react-redux'
import {setVendorDataList} from '../../../redux/procurement/vendor/vendorSlice'
import {setStock} from '../../../redux/inventory/products/stockSlice'
import {setServices} from '../../../redux/inventory/products/servicesSlice'
import {RootState} from '../../../redux/store'
import {getEmployeeByStatus} from '../../hrm/core/_requests'

function EditRFQ() {
  const rfqId = useParams().id
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const serviceList = useSelector((state: RootState) => state.inventory.services.services)
  const stockData = useSelector((state: RootState) => state.inventory.stock.stock)
  const [loading, setLoading] = useState(false)
  const [orderid, setOrderid] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [defaultAgent, setDefaultAgent] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [rfqNumber, setRfqNumber] = useState('')

  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('3rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('3rem')
  const dispatch = useDispatch()
  const vendorDataList = useSelector((state: RootState) => state.procurement.vendor.vendorData)

  const [quote, setQuote] = useState({
    date: new Date(),
    employee: '',
    vendor: '',
    contactPerson: '',
    id: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: '',
    termsNCondition: ' ',
  })
  const [newItems, setNewItems] = useState<any>([])
  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
      productName: '',
    },
  ])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const currencyOptions = [
    {
      value: 'AED',
      label: 'AED - United Arab Emirates dirham',
      flag: 'flags/united-arab-emirates.svg',
    },
    {value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg'},
    {value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg'},
    {value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg'},
    {value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg'},
    {value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg'},
    {value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg'},
    {value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg'},
    // Add other options similarly
  ]
  const defaultCurrency = currencyOptions.find((option) => option.value === 'AED')

  const getVendorList = async () => {
    try {
      const res = await getVendor('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )
      dispatch(setVendorDataList(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (quote.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [quote.termsNCondition])

  useEffect(() => {
    if (quote.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [quote.notes])

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      console.log(res)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const RFQList = await getRFQ(currentUser?.organization)
        console.log('list', RFQList)

        if (RFQList && RFQList.length > 0) {
          const firstQuote = RFQList[0]

          setQuote((prev) => ({
            ...prev,
            notes: firstQuote.notes || '',
            termsNCondition: firstQuote.termsNCondition || '',
          }))
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchQuote()
  }, [])

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const getProductlist = async () => {
    try {
      let data = []
      if (data.length === 0) {
        const res = await getStocks(currentUser?.organization)
        console.log(res)
        data = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
          productName: r.ProductName,
        }))
        dispatch(setStock(data))
      } else {
        data = stockData
      }
      let serviceData = []
      if (serviceList.length === 0) {
        const resp = await getItems(currentUser?.organization)
        dispatch(setServices(resp))
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
          productName: r.name,
        }))
      } else {
        serviceData = serviceList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
          productName: r.name,
        }))
      }
      setProductList((prev) => {
        const updatedData = [...data, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (vendorDataList?.length === 0) {
      if (permissions?.rfq?.admin === false) {
        getVendorListForAgent()
      } else if (permissions?.rfq?.admin === true || currentUser?.profileType === 'superadmin') {
        getVendorList()
      }
    } else {
      setCustomerList(
        vendorDataList.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )
    }
  }, [permissions])

  useEffect(() => {
    getEmployeeList()
    getProductlist()
  }, [])

  const handleChange = (e): void => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setQuote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    }
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
      // You can add other properties if needed
    }))
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setQuote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        productName: selectedOption.label,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      subtotal: Number(items.reduce((acc, item) => acc + item.quantity, 0)),
      // You can add other properties if needed
    }))
  }, [items])

  useEffect(() => {
    setQuote((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, quote.tax])

  useEffect(() => {
    setDefaultAgent(employeeList.find((option) => option.value === currentUser?._id))
  }, [employeeList])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)
    console.log(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      vendor: selectedOption.value,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleEdit = async () => {
    if (quote.vendor === '' || quote.employee === '') {
      alert('Please select vendor and employee')
      return
    } else if (items?.[0]?.itemId === '') {
      alert('Please select item')
      return
    }
    setLoading(true)
    const itemsList = items.map((item) => {
      return {
        itemId: item.type === 'product' ? item.itemId : null,
        itemsId: item.type === 'item' ? item.itemId : null,
        productName: item.productName,
        quantity: item.quantity,
        type: item.type,
      }
    })
    const data = {
      ...quote,
      order: orderid,
      approval: permissions?.rfq?.enable ? 'accepted' : 'pending',
      id: rfqNumber,
      items: [...itemsList, ...newItems],
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    try {
      console.log(data)
      await editRFQ(data, rfqId).then((res) => {
        console.log('res', res)
        navigate(`/procurement/rfq`)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
        productName: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
          productName: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  const fetchRFQData = async () => {
    try {
      const res = await getRFQByRFQId(rfqId)
      console.log('res', res)
      setQuote((prev) => ({
        ...prev,
        date: new Date(res.date),
        employee: res.employee?._id,
        vendor: res.vendor?._id,
        contactPerson: res?.contactPerson,
        id: res.id,
        subtotal: res.subtotal,
        tax: res.tax,
        total: res.total,
        notes: res.notes,
        termsNCondition: res.termsNCondition,
      }))
      setselectedBillTo({
        value: res.vendor?._id,
        label: res.vendor?.displayName,
      })
      setselectedBillFrom({
        value: res.employee?._id,
        label: res.employee?.firstName,
      })
      setRfqNumber(res.id)
      setOrderid(res.order)
      const data = res.items.filter((item) => !item.itemId && !item.itemsId)
      setNewItems(data)
      setItems(
        res.items
          .filter((item) => item.itemId || item.itemsId)
          .map((item) => ({
            itemId: item.itemId?._id || item.itemsId?._id,
            productName: item?.productName,
            quantity: item.quantity,
            selectedOption: {
              value: item?.itemId?._id || item?.itemsId?._id,
              label: item?.productName,
              inWarehouseQuantity: item.itemId ? item.itemId.inWarehouseQuantity : 1,
            },
            type: item.type,
          }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    fetchRFQData()
  }, [])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={quote.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'>
                      <span className='fs-2x fw-bold text-gray-800'>RFQ #</span>
                      <span className='fs-2x fw-bold text-gray-800'>{rfqNumber}</span>
                    </div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          From
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          To
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='vendor'
                          aria-label='Select a Vendor'
                          placeholder='Select a Vendor'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            {/* <th className='min-w-50px w-250px'>Rate</th> */}
                            {/* <th className='min-w-70px w-150px'>Discount</th> */}
                            {/* <th className='min-w-70px w-250px'>Amount</th> */}
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {items.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <Select
                                  name='itemId'
                                  aria-label='Select a Product'
                                  placeholder='Select a Product'
                                  options={productList}
                                  isSearchable={true}
                                  className='form-control form-control-solid'
                                  onChange={(e) => handleSelectChangeInRows(index, e)}
                                  value={item.selectedOption}
                                  required
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  min={0}
                                  value={item.quantity}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                              <td>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                  onClick={additem}
                                >
                                  <KTIcon iconName='plus-square' className='fs-3' />
                                </div>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                  onClick={() => removeitem(index)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </div>
                              </td>
                            </tr>
                          ))}
                          {newItems.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='absolute w-[250px] pe-7'>
                                {/* begin::Input group */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder='Product Name'
                                  value={item.productName}
                                  onChange={(e) => {
                                    const {name, value} = e.target
                                    setNewItems((prevItems) => {
                                      const updatedItems = [...prevItems]
                                      updatedItems[index] = {
                                        ...updatedItems[index],
                                        [name]: value,
                                      }
                                      return updatedItems
                                    })
                                  }}
                                  required
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  min={0}
                                  value={item.quantity}
                                  onChange={(e) => {
                                    const {name, value} = e.target
                                    setNewItems((prevItems) => {
                                      const updatedItems = [...prevItems]
                                      updatedItems[index] = {
                                        ...updatedItems[index],
                                        [name]: value,
                                      }
                                      return updatedItems
                                    })
                                  }}
                                />
                              </td>
                              <td>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                  onClick={() => {
                                    const temp = [...newItems]
                                    temp.splice(index, 1)
                                    setNewItems(temp)
                                  }}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </div>
                              </td>
                            </tr>
                          ))}
                          {/* button for add new item */}
                          <tr className='border-bottom border-bottom-dashed'>
                            <td colSpan={3} className='text-center'>
                              <button
                                type='button'
                                className='btn btn-light-info w-50'
                                onClick={() => {
                                  setNewItems([
                                    ...newItems,
                                    {
                                      productName: '',
                                      quantity: 0,
                                      unit: '',
                                    },
                                  ])
                                }}
                              >
                                Add New Item
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-start'>
                                <div className='fs-5'>Total Quantity</div>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed'>
                              <span data-kt-element='sub-total' className=' '>
                                {quote.subtotal?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                }) || 0}
                              </span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}

                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={quote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-20 bottom-60 fs-3'
                        />
                      </button>
                    </div>
                    <div className='mb-10 h-100'>
                      <label className='form-label fw-bold fs-6 text-gray-700'>
                        Terms and Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`position-relative  form-control form-control-solid mb-0 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={quote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-20 bottom-32 fs-3'
                        />
                      </button>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='quote'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>Currency</label>
                  <Select
                    name='currency'
                    aria-label='Select a Currency'
                    placeholder='Select currency'
                    options={currencyOptions}
                    defaultValue={defaultCurrency}
                    isSearchable={true}
                    className=''
                  />
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}

                {/* <!--::Separator-->
                        <!--begin::Input group--> */}
                <div className='mb-8'>
                  {/* <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Payment method
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={true}
                      value=''
                    />
                  </label>

                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Late fees
                    </span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>Notes</span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option--> */}
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                <div className='separator separator-dashed mb-8'></div>
                {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}
                  {!submitted ? (
                    <button
                      className='btn btn-primary w-100'
                      id='kt_quote_submit_button'
                      disabled={loading}
                      onClick={handleEdit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Edit RFQ
                    </button>
                  ) : (
                    <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Print Quotation
                    </button>
                  )}
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default EditRFQ
