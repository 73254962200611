import React from 'react'
import {Route, Router, Routes} from 'react-router-dom'
import VehicleList from '../components/VehicleList'

const Vehicle = () => {
  return (
    <Routes>
      <Route path='/' element={<VehicleList />} />
    </Routes>
  )
}

export default Vehicle
