import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {getInspectionForms} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setFormsData} from '../../../../redux/maintenance/inspection/forms/formsSlice'

const FormPage = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [forms, setForms] = useState([])
  const dispatch = useDispatch()
  const forms = useSelector((state: any) => state.maintenance.forms.formsData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getInspectionForms(currentUser?.organization)
    console.log(res)
    // setForms(res)
    dispatch(setFormsData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Inspection Forms</h4>
          {(permissions?.inspectionforms?.create || currentUser?.profileType === 'superadmin') && (
            <div className='btn btn-primary'>
              <Link to='add' className='text-white'>
                <KTIcon iconName='plus' className='me-2' />
                Add Inspection Form
              </Link>
            </div>
          )}
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-striped table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Form Name</th>
                  <th className='min-w-125px'>Category</th>
                  <th className='min-w-125px'>Created On</th>
                </tr>
              </thead>
              <tbody>
                {forms?.map((form: any) => (
                  <tr key={form?._id}>
                    {/* add title with the color dot beside*/}
                    <td>
                      {/* form?.color */}
                      <span className='me-2' style={{color: form?.color}}>
                        &#9679;
                      </span>
                      {form?.title}
                    </td>
                    <td>{form?.category?.categoryName || 'None'}</td>
                    <td>{formatDate(form?.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormPage
