import React from 'react'
import {Routes, Route} from 'react-router-dom'
import InventoryAdjustment from '../Components/inventoryAdjustment/InventoryAdjustment'
import AddInventoryAdjustment from '../Components/inventoryAdjustment/AddInventoryAdjustment'
import AdjustmentDetail from '../Components/inventoryAdjustment/AdjustmentDetail'

const InventoryAdjustmentPage = () => {
  return (
    <Routes>
      <Route path='/' element={<InventoryAdjustment />} />
      <Route path='/add' element={<AddInventoryAdjustment />} />
      <Route path='/:id' element={<AdjustmentDetail />} />
    </Routes>
  )
}

export default InventoryAdjustmentPage
