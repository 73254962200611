import React, {useEffect, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth'
import {addTagToOrg, getTagsByOrgId} from '../Core/_requests'
import {KTSVG} from '../../../../_metronic/helpers'

export default function ProductDetails({
  categoriesOptions,
  categories,
  setCategories,
  tags = [],
  setTags,
  showTagModal,
}) {
  const {currentUser} = useAuth()
  const [selectedOption, setSelectedOption] = useState(null)
  const [name, setName] = useState('')
  const [tagList, setTagList] = useState([])

  // const Tagoptions = [
  //   {value: ' new', label: 'new'},
  //   {value: 'sale', label: 'sale'},
  //   {value: 'trending', label: 'trending'},
  //   {value: 'dscounted', label: 'dscounted'},
  //   {value: 'last 10', label: 'last 10'},
  //   {value: 'selling fast', label: 'selling fast'},
  // ]

  const fetchTags = async () => {
    const res = await getTagsByOrgId(currentUser?.organization)
    const data = res.map((tag) => ({value: tag, label: tag}))
    setTagList(data)
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const Navigate = useNavigate()

  const handleClick = () => {
    const closeModalButton = document.getElementById('closeModalButton1')
    closeModalButton?.click()
    Navigate('/category/add-category')
  }
  const handleSubmit = () => {
    const data = {
      tags: name,
    }
    const res = addTagToOrg(data, currentUser?.organization)
    if (res) {
      setName('')
      setTagList([...tagList, {value: name, label: name}])
      const closeModalButton = document.getElementById('closeModalButton')
      closeModalButton.click()
    }
  }

  return (
    <div class='card card-flush py-4 d-flex flex-column'>
      <div class='card-header '>
        <div class='card-title '>
          <h2>Category Details</h2>
        </div>
      </div>

      <div class='card-body p-0 px-2 pb-2'>
        <label class='form-label'>Categories</label>

        <Select
          class='form-select mb-2'
          options={categoriesOptions}
          // value={categories.map((category) => ({value: category, label: category}))}
          // onChange={(selectedOptions) =>
          //   setCategories(selectedOptions.map((option) => option.value))
          // }
          // value={selectedOption}
          value={categoriesOptions.find((option) => option.value === categories)}
          onChange={(selectedOption) => {
            setSelectedOption(selectedOption)
            setCategories(selectedOption.value)
          }}
        />

        <div class='text-muted fs-7 mb-7'>Add product to a category.</div>

        <button
          onClick={handleClick}
          type='button'
          href='../../demo17/dist/apps/ecommerce/catalog/add-category.html'
          class='btn btn-light-primary btn-sm mb-10'
        >
          <i class='ki-duotone ki-plus fs-2'></i>Create new category
        </button>

        {/* <label className='form-label d-block'>Tags</label>
          <Select
            className='form-control mb-2'
            isMulti
            options={Tagoptions}
            value={tags.map((tag) => Tagoptions.find((t) => t.value === tag))}
            onChange={(selectedOptions) => setTags(selectedOptions.map((option) => option.value))}
          /> */}

        <label class='form-label d-block'>Tags</label>
        <Select
          class='form-control mb-2'
          isMulti
          options={tagList}
          value={tags.map((tag) => tagList.find((t) => t.value === tag))}
          onChange={(selectedOptions) => setTags(selectedOptions.map((option) => option.value))}
        />

        <div class='text-muted fs-7'>Add tags to a product.</div>
        {showTagModal && (
          <div className='card-toolbar me-5' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
            <a className='btn btn-sm btn-light-warning mb-10'>
              <i class='ki-duotone ki-plus fs-2'></i>Create New Tag
            </a>
          </div>
        )}
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add New Tag</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form>
                  <div className='row rowwidthclient'>
                    <div className='form-group mb-3 col-md-12'>
                      <label>Tag Name</label>
                      <input
                        type='text'
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='form-control'
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  id='closeModalButton'
                >
                  Close
                </button>
                <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // {/* <div class='card border-0 p-4 rounded-md'>
    //    <div class='card-body'>
    //      <h5 class='card-title text-start fw-semibold fs-5'>Product Details</h5>
    //      <h2 className='mt-3 mb-1 text-start fw-semibold'>Categories</h2>
    //      <Select
    //       isMulti
    //       options={categoriesOptions}
    //       value={categories.map((category) => ({value: category, label: category}))}
    //       onChange={(selectedOptions) =>
    //         setCategories(selectedOptions.map((option) => option.value))
    //       }
    //     />

    //     <h6 class='card-subtitle mb-2 text-muted text-start mt-2 text-gray-500 '>
    //       Add product to a Category.
    //     </h6>

    //     <button
    //       onClick={handleClick}
    //       className='bg-lime-100 text-lime-500 px-6 flex justify-start mt-6 rounded py-1 hover:bg-lime-500 hover:text-lime-100'
    //     >
    //       + Crete new Category
    //     </button>

    //     <h2 className='mt-3 mb-1 text-start fw-semibold'>Tags</h2>

    //     <Select
    //       isMulti
    //       options={Tagoptions}
    //       value={tags.map((tag) => ({value: tag, label: tag}))}
    //       onChange={(selectedOptions) => setTags(selectedOptions.map((option) => option.value))}
    //     />

    //     <h6 class='card-subtitle mb-2 text-muted text-start mt-2 text-gray-500 '>
    //       Add tags to a product.
    //     </h6>
    //   </div>
    // </div> */}
  )
}
