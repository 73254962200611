import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select' // Make sure to import Select component from 'react-select' package
import Flatpickr from 'react-flatpickr'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getProjectById} from '../core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {uploaddocsinqf} from '../../Inventory/Core/_requests'
import {
  getPurchaseQuotation,
  postPurchaseOrderWithProjectId,
  getPurchaseOrderLastId,
  getVendorByAgentId,
  getVendor,
} from '../../purchaseOrders/Core/_requests'

export const AddPurchaseOrder = () => {
  const {currentUser} = useAuth()
  const projectId = useParams().id
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [filename, setfilename] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const fileInputRef = useRef(null)
  const [permissions, setPermissions] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [purchaseQuotation, setPurchaseQuotation] = useState({
    vendor: '',
    date: new Date(),
    id: '', // Initialize with appropriate value
    subtotal: 0, // Initialize with appropriate value
    tax: 0, // Initialize with appropriate value
    total: 0, // Initialize with appropriate value
    notes: '', // Initialize with appropriate value
    termsCondition: '',
    reference: '',
    expectedDeliveryDate: '',
    paymentTerms: '',
    shipmentPreferences: '',
    document: '',
    items: '',
    docAttached: '',
    // Initialize with appropriate value
  })

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (purchaseQuotation.termsCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [purchaseQuotation.termsCondition])

  useEffect(() => {
    if (purchaseQuotation.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [purchaseQuotation.notes])

  const [items, setItems] = useState([
    {
      itemId: '',
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
      type: 'product',
    },
  ])
  console.log(items)
  const [submitted, setSubmitted] = useState(false)
  const [vendorList, setVendorList] = useState([])
  const [selectedVendor, setSelectedVendor] = useState(null)

  //useState for Custom Quotation no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState(' ')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')

  const handleDateChange = (date) => {
    setPurchaseQuotation({...purchaseQuotation, date: date[0]})
  }
  const handleExpectedDeliveryDateChange = (date) => {
    setPurchaseQuotation({...purchaseQuotation, expectedDeliveryDate: date[0]})
  }
  const handleShipmentPreferenceChange = (selectedOption) => {
    setPurchaseQuotation((prevQuotation) => ({
      ...prevQuotation,
      shipmentPreferences: selectedOption?.value || '', // Assuming the value is a string, adjust accordingly
    }))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  const handleUpload = async () => {
    if (!excelFile) {
      console.error('No file selected')
      return
    }

    const timestamp = Date.now()

    const formData = new FormData()
    formData.append('file', excelFile)
    formData.append('name', purchaseQuotation.reference)
    formData.append('filename', timestamp + filename)
    try {
      setisLoading(true)
      const response = await uploaddocsinqf(formData)

      if (response === 'success') {
        console.log('File uploaded successfully')
        alert('File uploaded successfully')
        setPurchaseQuotation((prev) => {
          return {
            ...prev,
            docAttached: timestamp + filename,
          }
        })
        // Optionally, update UI to indicate successful upload
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      setisLoading(false)
      // Opti
    }
  }

  const fetchProjectData = async () => {
    const res = await getProjectById(projectId)
    console.log(res)
    setData(res)
    setPurchaseQuotation(() => {
      return {
        ...purchaseQuotation,
        customer: res.customer?._id,
      }
    })

    const employeesMap = res.team?.map((team) => {
      return {
        value: team?._id,
        label: team?.name, // Corrected the typo here from "labe" to "label"
      }
    })
    setEmployeeList(employeesMap)

    const itemsList = res.items?.map((r) => ({
      value: r?.itemId?._id,
      label: r?.itemId?.productName, // Corrected the typo here from "labe" to "label"
      price: r?.price,
      type: 'product',
    }))
    setProductList(itemsList)
  }

  const handlePaymentTermsChange = (selectedOption) => {
    setPurchaseQuotation((prevQuotation) => ({
      ...prevQuotation,
      paymentTerms: selectedOption?.value || '', // Assuming the value is a string, adjust accordingly
    }))
  }

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setPurchaseQuotation((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setPurchaseQuotation({...purchaseQuotation, [name]: value})
    }
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setPurchaseQuotation((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))
    
      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  useEffect(() => {
    setPurchaseQuotation((prevInvoice) => ({
      ...prevInvoice,
      subtotal: items.reduce((accumulator, currentItem) => {
        // Assuming that the 'amount' property is a number
        return accumulator + parseFloat(currentItem.amount)
      }, 0),
      // You can add other properties if needed
    }))
  }, [items])

  useEffect(() => {
    setPurchaseQuotation((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, purchaseQuotation.tax])

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        itemId: selectedOption.value,
        selectedOption,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
      }
      return updatedItems
    })
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    // if (name === 'quantity' && items[index].type !== 'item') {
    //   if (value > items[index].selectedOption.inWarehouseQuantity) {
    //     return
    //   }
    // }
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
    if (name === 'termsCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const additem = () => {
    setItems([
      ...items,
      {
        itemId: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
        type: 'product',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          itemId: '',
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
          type: 'product',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  //   const handleSubmit = () => {
  //     // Implement your submission logic here
  //     setSubmitted(true);
  //   };
  console.log(purchaseQuotation)

  const handleSubmit = async () => {
    if (!excelFile) {
      // console.error('No file selected')
      // return
      setPurchaseQuotation({...purchaseQuotation, reference: ''})
    }
    if (selectedVendor === null || selectedVendor === '') {
      alert('Please select a vendor')
      return
    } else if (items[0].itemId === '') {
      alert('Please select an item')
      return
    } else if (purchaseQuotation.expectedDeliveryDate === '') {
      alert('Please select expected delivery date')
      return
    }

    setLoading(true)

    const data = {
      ...purchaseQuotation,
      vendor: selectedVendor.value,
      items: items.map((item) => ({
        itemId: item.type === 'product' ? item.itemId : null,
        itemsId: item.type === 'item' ? item.itemId : null,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
        type: item.type,
      })),
      prefix: prefix,
      customID: customID,
      id: nextNumber,
      approval: permissions?.purchaseorder?.enable ? 'accepted' : 'pending',
      projectId,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    console.log('purchase quotation data', data)
    try {
      await postPurchaseOrderWithProjectId(data).then((res) => {
        console.log('Quotation submitted successfully:', res)
        navigate(`/procurement/purchaseOrdersList`)
      })
    } catch (error) {
      console.error('Error submitting quotation:', error)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      try {
        const PurchaseOrderList = await getPurchaseQuotation(currentUser?.organization)
        console.log('list', PurchaseOrderList)

        if (PurchaseOrderList && PurchaseOrderList.length > 0) {
          const firstOrder = PurchaseOrderList[0]

          setPurchaseQuotation({
            ...purchaseQuotation,
            notes: firstOrder.notes || ' ',
            termsCondition: firstOrder.termsCondition || ' ',
          })
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchPurchaseOrder()
  }, [])

  const paymentTermsOptions = [
    {value: 'net-30', label: 'Net 30 Days'},
    {value: 'net-60', label: 'Net 60 Days'},
    {value: 'net-90', label: 'Net 90 Days'},
  ]
  const shipmentPreferenceOptions = [
    {value: 'normal', label: 'Normal Delivery'},
    {value: 'third-party', label: 'Third Party Delivery'},
    {value: 'express', label: 'Express Delivery'},
    // Add more options as needed
  ]
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])

  const getVendorList = async () => {
    try {
      const query = null
      const data = await getVendor(query, currentUser?.organization)
      console.log('Vendor List:', data)
      setVendorList(
        data.map((r) => ({
          value: r._id,
          label: r.displayName, // Assuming name is the property containing the vendor's name
        }))
      )
    } catch (error) {
      console.error('Error fetching vendor list:', error)
    }
  }

  const getVendorListForAgent = async () => {
    try {
      const res = await getVendorByAgentId('', currentUser?._id)
      console.log(res)
      setVendorList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.purchaseorder?.admin === false) {
      getVendorListForAgent()
    } else if (
      permissions?.purchaseorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getVendorList()
    }
  }, [permissions])

  const getQuotationID = async () => {
    try {
      const res = await getPurchaseOrderLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      setPrefix(res.prefix)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    fetchProjectData()
    getQuotationID()
  }, [])

  //functions for Custom Quotation no
  const handleCloseModal = () => {
    setShowModal(false)
    setCustomID('')
    setPrefix('')
    setNextNumber('')
    getQuotationID()
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      newSalesOrderNumber = `${prefix}${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  // const getSalesOrderID = async () => {
  //   try {
  //     const res = await getSalesOrderLastId()
  //     setNextNumber(res.lastId + 1)
  //     setPrefix(res.prefix)
  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    } else {
      setSalesOrderNumber(`${prefix}${nextNumber}`)
    }
  }, [prefix, nextNumber, customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid '>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl bg-danger'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={purchaseQuotation.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i> */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter Purchase Order number'
                    >
                      <span className='fs-2x fw-bold text-gray-800 '>PURCHASE ORDERS #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={purchaseQuotation.id}
                        placeholder='...'
                        disabled
                      /> */}

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={salesOrderNumber}
                        onChange={(e) => setSalesOrderNumber(e.target.value)}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Quotation No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Quotation numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Quotation numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Quotation numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Quotation No. :</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Quotation Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='w-50'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Vendor Name
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='vendor'
                          aria-label='Select a Vendor'
                          placeholder='Select a Vendor'
                          options={vendorList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleVendorChange}
                          value={selectedVendor}
                        />
                      </div>
                      {/* Col */}
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className=''></div>

                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Reference#
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid '
                        name='reference'
                        placeholder='Reference for Quotation'
                        value={purchaseQuotation.reference}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Expected Delivery Date
                        </label>
                        {/* begin::Input group */}
                        <Flatpickr
                          value={purchaseQuotation.expectedDeliveryDate}
                          onChange={handleExpectedDeliveryDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className='fs-6 fw-bold text-gray-700 mb-3'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Rate</th>
                            <th className='min-w-70px w-150px'>Discount</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            <th className='min-w-70px w-200px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {items.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}
                                <Select
                                  name='itemId'
                                  aria-label='Select a Product'
                                  placeholder='Select a Product'
                                  options={productList}
                                  isSearchable={true}
                                  className='form-control form-control-solid'
                                  onChange={(e) => handleSelectChangeInRows(index, e)}
                                  value={item.selectedOption}
                                  required
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  // max={
                                  //   item?.type === 'product' &&
                                  //   item?.selectedOption?.inWarehouseQuantity
                                  // }
                                  min={0}
                                  value={item.quantity}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='price'
                                  placeholder='0.00'
                                  value={item.price}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='discount'
                                  placeholder='0.00'
                                  min={0}
                                  value={item.discount}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='amount'
                                  placeholder='0.00'
                                  value={item.amount}
                                  disabled
                                  // onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                              <td>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                  onClick={additem}
                                >
                                  <KTIcon iconName='plus-square' className='fs-3' />
                                </div>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                  onClick={() => removeitem(index)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-start'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  Add tax %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed'>
                              <span data-kt-element='sub-total' className=' '>
                                {purchaseQuotation.subtotal}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid '
                                name='tax'
                                placeholder='0.00'
                                value={purchaseQuotation.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px'>Total</th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-70px'>
                              AED
                              <span data-kt-element='grand-total'> {purchaseQuotation.total}</span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={purchaseQuotation.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-20 bottom-60 fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsCondition'
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={purchaseQuotation.termsCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-20 bottom-32 fs-3'
                        />
                      </button>
                    </div>
                    {/* <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <input
                        type='textarea'
                        name='notes'
                        className='form-control form-control-solid'
                        placeholder='Thanks for your business'
                        value={purchaseQuotation.notes}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div></div>
                    {/* Notes */}
                    {/* <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <input
                        type='textarea'
                        name='termsCondition'
                        className='form-control form-control-solid'
                        placeholder='Enter the terms and Conditions for your business to be displayed in your transaction'
                        value={purchaseQuotation.termsCondition}
                        onChange={handleChange}
                      />
                    </div> */}
                    <div className='row gx-10 mb-5'>
                      <div
                        className='card-toolbar me-5'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_11'
                      >
                        <a className='btn btn-sm btn-light-primary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Upload File
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='invoice'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>Payment Terms</label>
                  <Select
                    name='paymentTerms'
                    aria-label='Select a Payment Terms'
                    placeholder='Select Payment Terms'
                    options={paymentTermsOptions}
                    isSearchable={true}
                    className=''
                    onChange={handlePaymentTermsChange}
                  />
                </div>
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>
                    Shipment Preference
                  </label>
                  <Select
                    name='shipmentPreferences'
                    aria-label='Select a Shipment Preference'
                    placeholder='Select shipment preference'
                    options={shipmentPreferenceOptions}
                    isSearchable={true}
                    className=''
                    onChange={handleShipmentPreferenceChange}
                  />
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}

                {/* <!--::Separator-->
                        <!--begin::Input group--> */}

                {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                <div className='separator separator-dashed mb-8'></div>
                {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}
                  {!submitted ? (
                    <button
                      type='button'
                      className='btn btn-primary w-100'
                      id='kt_quote_submit_button'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Create Purchase Order
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-danger w-100'
                      id='kt_quote_submit_button'
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Print Purchase Order
                    </button>
                  )}
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add File</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default AddPurchaseOrder
