import {createSlice} from '@reduxjs/toolkit'

const expenseSlice = createSlice({
  name: 'expense',
  initialState: {
    expenseData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setExpenseData(state, action) {
      state.expenseData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setExpenseData, setLoading, setError} = expenseSlice.actions
export default expenseSlice.reducer
