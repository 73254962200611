import { createSlice } from '@reduxjs/toolkit';

const salesOrderSlice = createSlice({
  name: 'salesOrder',
  initialState: {
    salesOrderData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSalesOrderData(state, action) {
      state.salesOrderData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setSalesOrderData, setLoading, setError } = salesOrderSlice.actions;
export default salesOrderSlice.reducer;
