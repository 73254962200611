import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {getVehiclesList, postVehicleReminder} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'
import {useNavigate} from 'react-router-dom'

const AddVehicleRenewal = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<any>([])
  const [formData, setFormData] = useState({
    vehicle: '',
    renewalType: 'emission',
    dueDate: new Date(),
    dueSoon: new Date(),
    due: '',
    dueType: '1',
    comment: '',
    notification: false,
  })

  const fetchVehicles = async () => {
    const res = await getVehiclesList(currentUser.organization)
    console.log(res)
    const data = res.map((item: any) => {
      return {
        value: item._id,
        label: item.productName,
      }
    })
    setProducts(data)
  }

  useEffect(() => {
    fetchVehicles()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formData.vehicle === '' || formData.renewalType === '') {
      alert('Please fill all the required fields')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      company: currentUser.company,
      organization: currentUser.organization,
    }
    console.log(data)
    const res = await postVehicleReminder(data)
    if (res) {
      navigate('/reminders/vehicle-renewals')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>Add Vehicle Renewal Reminder</h3>
        </div>
        <div className='card-body'>
          <div className='form-group mt-3'>
            <label className='required'>Vehicle</label>
            <Select
              isSearchable
              options={products}
              onChange={(e: any) => setFormData({...formData, vehicle: e?.value})}
            />
          </div>
          <div className='form-group mt-3'>
            <label className='required'>Vehicle Renewal Type</label>
            <select
              className='form-control'
              value={formData.renewalType}
              onChange={(e) => setFormData({...formData, renewalType: e.target.value})}
            >
              <option value='emission'>Emission Test</option>
              <option value='insurance'>Insurance</option>
              <option value='inspection'>Inspection</option>
              <option value='registration'>Registration</option>
            </select>
          </div>
          <div className='form-group mt-3'>
            <label className='required'>Due Date</label>
            <input
              type='date'
              className='form-control'
              value={formData.dueDate.toISOString().split('T')[0]}
              onChange={(e) => setFormData({...formData, dueDate: new Date(e.target.value)})}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-md-4'>
              <div className='form-group'>
                <label>Due Soon Threshold</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Enter Due'
                  value={formData.due}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      due: e.target.value,
                      dueSoon: new Date(
                        new Date().getTime() +
                          Number(e.target.value) * Number(formData.dueType) * 24 * 60 * 60 * 1000
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className='col-md-8 d-flex align-items-end'>
              <div className='form-group'>
                <select
                  className='form-control'
                  value={formData.dueType}
                  onChange={(e) => setFormData({...formData, dueType: e.target.value})}
                >
                  <option value={1}>Days</option>
                  <option value={7}>Weeks</option>
                  <option value={30}>Months</option>
                  <option value={365}>Years</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group mt-3'>
            <label className='form-check form-switch'>
              <input
                type='checkbox'
                checked={formData.notification}
                className='form-check-input me-2'
                onChange={(e) => setFormData({...formData, notification: e.target.checked})}
              />
              Notification
            </label>
          </div>
          <div className='form-group mt-3'>
            <label>Comment</label>
            <textarea
              className='form-control'
              rows={3}
              placeholder='Add an optional comment'
              value={formData.comment}
              onChange={(e) => setFormData({...formData, comment: e.target.value})}
            />
          </div>
          <div className='my-3'>
            <button className='btn btn-primary' disabled={loading} onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddVehicleRenewal
