/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {getReorderStocks} from '../Core/_requests'

type Props = {
  className: string
}

const Lists7: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const [tableData, setTableData] = useState([])
  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  useEffect(() => {
    const fetchData = async () => {
      await getReorderStocks(currentUser.organization).then((res) => {
        setTableData(res)
      })
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser])

  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8 h-100'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>Reorder & Restock </span>
        </h3>
        {/* <div className='card-toolbar'>
          {/* begin::Menu 
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu 
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3'>
        <div className='table-responsive overflow-y-scroll h-300px'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted '>
                <th className='min-w-250px text-start ps-2'>Procuct Name</th>
                <th className='min-w-50px text-center'>Quantity</th>
                <th className='min-w-100px text-center'>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, i) => (
                <tr key={i} className=''>
                  <td className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={`${PF}${item?.thumbnail}`}
                          alt=''
                          className='h-50 align-self-center'
                        />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <span className='text-dark fw-bold text-hover-primary fs-6'>
                        {item?.productName}
                      </span>
                    </div>
                  </td>
                  <td className='text-muted fw-semibold text-center'>
                    {item?.inWarehouseQuantity}
                  </td>
                  <td className='text-center'>
                    <span
                      className={`badge badge-light-${
                        item?.status === 'Limited' ? 'warning' : 'danger'
                      }`}
                    >
                      {item?.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Lists7}
