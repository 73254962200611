/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { parse } from 'path'

type Props = {
  className: string
  color: string
  title :string
}

const ChartsWidget13: React.FC<Props> = ({className,color,title}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, color.toString())) // Convert color to string
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className=' px-5 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-semibold fs-6 '>{title}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_6_chart' ></div>
        {/* end::Chart */}
      
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget13}

function getChartOptions(height: number,color:string): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const baseColor = getCSSVariableValue(`--bs-${color}`)
  const baseLightColor = getCSSVariableValue('--bs-primary-light')
  const secondaryColor = getCSSVariableValue('--bs-primary')

  return {
    series: [
      {
        name: 'Net Profit',
        type: 'bar',
        data: [40, 50, 65, 70, 50, 30],
      },
      // {
      //   name: 'Revenue',
      //   type: 'bar',
      //   data: [20, 20, 25, 30, 30, 20],
      // },
      // {
      //   name: 'Expenses',
      //   type: 'area',
      //   data: [50, 80, 60, 90, 50, 70],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: true,
      height: 150,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '35%',
        
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      max: 80,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      tickAmount: 5,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  }
}
