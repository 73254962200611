import React, { useEffect, useState } from 'react'
import { IoMdSearch } from 'react-icons/io'
import { useNavigate, Link } from 'react-router-dom'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { CgDanger } from 'react-icons/cg'
import { deleteCategory, getCategory } from '../Core/_categoryRequest'
import { KTIcon } from '../../../../_metronic/helpers'
import Search from '../../documentation/Components/Search'
import { useAuth } from '../../../modules/auth'
import Filter2 from '../Components/Filter2'
import qs from 'qs'
import { getFilteredCategory } from '../Core/_categoryRequest'
import {useSelector, useDispatch} from 'react-redux'
import { setCategoryData } from '../../../redux/inventory/category/categorySlice'

export default function CategoryListing() {
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  const categoryData = useSelector((state) => state.inventory.category.categoryData)
  const loading = useSelector((state) => state.inventory.category.loading)
  const [permissions, setPermissions] = useState({})
  const [dropdown, setDropdown] = useState([])
  const [del, setDel] = useState(false)
  const [selectedItem, setselectedItem] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [tableData, setTableData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('All')
  // const [masterCheckbox, setMasterCheckbox] = useState(false)
  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const navigate = useNavigate()

  const handleAddProductClick = () => {
    navigate('/category/add-category')
  }
  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const stringifyObject = (obj) => {
    console.log("OBJECT",obj)
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_type: obj.filter.categoryType,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const fetchData = async () => {
    const queryString = stringifyObject(searchFilters)
    console.log("Query String",queryString)

    // const res = await getCategory(currentUser.organization)
    const res = await getFilteredCategory(queryString, currentUser?.organization)
    setTableData(res)
    console.log("category data", res);
    setDropdown(Array(res.length).fill(false))
    dispatch(setCategoryData(res))
  }

  useEffect(() => {
    if (loading) fetchData()
    else {
      setTableData(categoryData)
      setDropdown(Array(categoryData.length).fill(false))
    }
  }, [])

  const handleDeleteToggle = (i) => {
    setDel(!del)
    setselectedItem(i)
  }

  const handleDelete = async (i) => {
    const res = await deleteCategory(i)
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
    fetchData()
  }

  useEffect(() => {
    const filteredData = categoryData.filter(
      (item) =>
        item.categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.type.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setTableData((prevTableData) => (searchQuery.trim() === '' ? categoryData : filteredData))
  }, [searchQuery, categoryData])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  // useEffect(() => {
  //   const updatedTableData = tableData.map((item) => ({
  //     ...item,
  //     isChecked: masterCheckbox,
  //   }))
  //   setTableData(updatedTableData)
  // }, [masterCheckbox])

  const getCategoryType = (status) => {
    if (status === 'Manual') {
      return <p className='badge badge-light-success'>{status}</p>
    } else {
      return <p className='badge badge-light-primary'>{status}</p>
    }
  }


  let searchFilters = {
    filter: {
      isValid: true,
      categoryType:'',
    },
  }
  const setSearchFilter = (updateValues) => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }
  const setValidityFilter = (isValid) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        status:'',
        categoryType:''
      },
    })
    fetchData()
  }

  return (
    <div className='p-6 md:m-4  card  card-xxl-stretch'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Categories</span>
      <div className='d-flex  justify-between card-header py-5 '>
      <div className=' d-flex flex-row  card-title align-items-start'>
        <div className='d-flex  items-center relative space-x-3 mb-3 mb-md-0'>
          <form
            data-kt-search-element='form'
            className='w-100 position-relative '
            autoComplete='off'
          >
            <KTIcon
              iconName='magnifier'
              className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
            />

            <input
              type='text'
              className='form-control form-control-solid w-250px ps-14'
              name='search'
              placeholder='Search...'
              data-kt-search-element='input'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
            />
          </form>
        </div>

        <div className='d-flex flex-row ml-5'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchData}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
              showCategoryTypeFilter={true}
              categoryTypeOptions={[
                {value: 'service', label: 'Service'},
                {value: 'consumables', label: 'Consumables'},
                {value: 'goods', label: 'Goods'}, 
                {value: 'rentals', label: 'Rentals'},
              ].filter(option => {
                return currentUser?.inventoryFeatures?.[option.value];
              })
            }
              />
          </div>
          </div>

        <div className=' d-flex flex-row  card-title align-items-start'>

          {(permissions?.category?.create || currentUser?.profileType === 'superadmin') && (
            <div className='d-flex justify-between space-x-4 '>
              <button
                href='#'
                className='btn btn-sm btn-light-primary w-full whitespace-nowrap'
                onClick={handleAddProductClick}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Add Category
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive overflow-visible'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={masterCheckbox}
                      onChange={() => setMasterCheckbox(!masterCheckbox)}
                    />
                  </div>
                </th> */}
                <th className='min-w-150px ps-2'>Category</th>
                <th className='min-w-150px'>Category Type</th>
                {(permissions?.category?.update || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-150px text-end px-10'>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, i) => (
                  <tr key={i}>
                    {/* <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={item.isChecked}
                          onChange={() => {
                            const updatedTableData = [...tableData]
                            updatedTableData[i].isChecked = !updatedTableData[i].isChecked
                            setTableData(updatedTableData)
                          }}
                        />
                      </div>
                    </td> */}
                    <td className='d-flex align-items-center ps-4'>
                      {/* <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={`${PF}${item.thumbnail}`}
                            alt=''
                            className='h-50 align-self-center'
                          />
                        </span>
                      </div> */}
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={`product-catalog/${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6 capitalize'>
                            {item.categoryName}
                          </span>
                        </Link>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {item.categoryDescription}
                        </span> */}
                      </div>
                    </td>
                    <td>{item.type}</td>

                    {(permissions?.category?.update ||
                      currentUser?.profileType === 'superadmin') && (
                        <td className='relative'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <button
                              onClick={() => handleClick(i)}
                              className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded  self-end'
                            >
                              Actions <RiArrowDropDownLine />
                            </button>
                          </div>

                          {dropdown[i] && (
                            <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 right-[0px] rounded p-3 z-50 space-y-2'>
                              <Link to={`edit-category/${item._id}`}>
                                <button className='hover:bg-lime-50 p-2 w-100  text-start rounded'>
                                  Edit
                                </button>
                              </Link>
                              {/* <button
                            className='hover:bg-lime-50 p-2 text-start rounded'
                            onClick={() => handleDeleteToggle(i)}
                            data-bs-toggle='modal'
                            data-bs-target='#myModal'
                          >
                            Delete
                          </button>
                          <Link to={`view-category/${item._id}`}>
                            <button className='hover:bg-lime-50 text-gray-700 w-full p-2 text-start rounded'>
                              View Page
                            </button>
                          </Link> */}
                            </div>
                          )}
                        </td>
                      )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div class='modal' id='myModal'>
        <div class='modal-dialog modal-dialog-centered '>
          <div class='modal-content'>
            <div class='modal-header'>
              <h4 class='modal-title mx-56 text-yellow-500 '>
                <CgDanger size={100} />
              </h4>
              <button type='button' class='btn-close' data-bs-dismiss='modal'></button>
            </div>

            <div class='modal-body text-center fs-6'>
              Are you sure you want to delete {tableData[selectedItem]?.productName} ?
            </div>

            <div class='modal-footer text-center mx-auto'>
              <button
                type='button'
                class='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(tableData[selectedItem]?._id)}
              >
                Delete
              </button>
              <button
                type='button'
                class='btn btn-light '
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
