import {createSlice} from '@reduxjs/toolkit'

const roleManagementSlice = createSlice({
  name: 'roleManagement',
  initialState: {
    roleManagementData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setRoleManagementData(state, action) {
      state.roleManagementData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setRoleManagementData, setLoading, setError} = roleManagementSlice.actions
export default roleManagementSlice.reducer
