import React, {useEffect} from 'react'
import {useState} from 'react'
import 'quill/dist/quill.snow.css' // Add css for snow theme
import Thumbnail from '../Components/Thumbnail'
import ProductDetails from '../Components/ProductDetails'
import Media from '../Components/Media'
import Shipping from '../Components/Shipping'
import {useNavigate, useParams} from 'react-router-dom'
import {getProductById} from '../Core/_requests'
import AccountsInformation from '../Components/AccountsInformation'
import {useAuth} from '../../../modules/auth'
import {DocumentsPage} from '../Components/DocumentsPage'
import TransactionTable from '../Components/TransactionTable'

export default function ViewProduct() {
  const {currentUser} = useAuth()
  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'
  const [previewImage, setPreviewImage] = useState(null) // Thumbnail
  const [categoryType, setCategoryType] = useState('')
  const [thumbnail, setThumbnail] = useState(null)
  const [categories, setCategories] = useState('') //Product Details
  const [tags, setTags] = useState([])
  const [productName, setProductName] = useState('') //General
  const [productDescription, setProductDescription] = useState('')
  const [media, setMedia] = useState([]) //Media
  const [sku, setSku] = useState('')
  const [description, setDescription] = useState('')
  const [weight, setWeight] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [length, setLength] = useState('')
  const [selectedImage, setSelectedImage] = useState(null)
  const [mediaName, setMediaName] = useState([])
  const [salesInfo, setSalesInfo] = useState(true)
  const [purchaseInfo, setPurchaseInfo] = useState(true)
  const [inventoryInfo, setInventoryInfo] = useState(true)
  const [salesAccount, setSalesAccount] = useState('')
  const [purchaseAccount, setPurchaseAccount] = useState('')
  const [inventoryAccount, setInventoryAccount] = useState('')
  const [inventoryAccounts, setInventoryAccounts] = useState([])
  // const [categoriesOptions, setCategoriesOptiry.categoryName = useState([])
  const [manufacturer, setManufacturer] = useState('')
  const [brand, setBrand] = useState('')
  const [upc, setUpc] = useState('')
  const [ean, setEan] = useState('')
  const [mpn, setMpn] = useState('')
  const [isbn, setIsbn] = useState('')
  const [unit, setUnit] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [sellingPrice, setSellingPrice] = useState('')
  const [openingStock, setOpeningStock] = useState('')
  const [openingStockRate, setOpeningStockRate] = useState('')
  const [reorderPoint, setReorderPoint] = useState('')
  const [inWarehouseQuantity, setInWarehouseQuantity] = useState('')
  const [stockOnHand, setStockOnHand] = useState('')
  const [commitedStock, setCommitedStock] = useState('')
  const [quantityToBeShipped, setQuantityToBeShipped] = useState('')
  const [quantityToBeReceived, setQuantityToBeReceived] = useState('')
  const [quantityToBeInvoiced, setQuantityToBeInvoiced] = useState('')
  const [quantityToBeBilled, setQuantityToBeBilled] = useState('')
  const [mrq, setMrq] = useState('')
  const [rentalType, setRentalType] = useState('')

  const [accountData, setAccountData] = useState({
    salesAccount: '',
    purchaseAccount: '',
    inventoryAccount: '',
  })

  const id = useParams().id
  const fetchData = async () => {
    const res = await getProductById(id)
    console.log(res)
    setProductName(res.productName)
    setProductDescription(res.productDescription)
    setSku(res.sku)
    setDescription(res.description)
    setWeight(res.weight)
    setWidth(res.width)
    setHeight(res.height)
    setLength(res.length)
    // setMedia(res.media)
    setInWarehouseQuantity(res.inWarehouseQuantity)
    setInventoryInfo(res.inventoryInfo)
    setInventoryAccount(res.inventoryAccount)
    setSalesInfo(res.salesInfo)
    setSalesAccount(res.salesAccount)
    setPurchaseInfo(res.purchaseInfo)
    setPurchaseAccount(res.purchaseAccount)
    setManufacturer(res.manufacturer)
    setBrand(res.brand)
    setUpc(res.upc)
    setEan(res.ean)
    setMpn(res.mpn)
    setIsbn(res.isbn)
    setUnit(res.unit)
    setCostPrice(res.costPrice)
    setSellingPrice(res.price)
    setOpeningStock(res.openingStock)
    setOpeningStockRate(res.openingStockRate)
    setReorderPoint(res.reorderPoint)
    setThumbnail(res.thumbnail)
    setCategories(res?.category?.categoryName)
    setCategoryType(res?.category?.type)
    setTags(res.tags)
    setStockOnHand(res.stockOnHand || 0)
    setCommitedStock(res.commitedStock || 0)
    setQuantityToBeShipped(res.quantityToBeShipped || 0)
    setQuantityToBeReceived(res.quantityToBeReceived || 0)
    setQuantityToBeInvoiced(res.quantityToBeInvoiced || 0)
    setQuantityToBeBilled(res.quantityToBeBilled || 0)
    setMrq(res.mrq || 0)
    setRentalType(res?.rentalType || '')
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container-fluid bg-gray-50 p-4'>
      <div className='row my-4 '>
        <div className='col-xl-4 space-y-6'>
          <div className='card border-0 p-4 rounded-md '>
            <div className='card-body '>
              <h5 className='card-title text-start fw-semibold fs-5'>Thumbnail</h5>
              <img src={`${PF}${thumbnail}`} alt='Thumbnail' className='w-48 h-48 object-cover' />
            </div>
          </div>

          {categoryType === 'rentals' && (
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Rental Details</h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='mb-10 fv-row'>
                  <label className='form-label'>Rental Type</label>
                  <div className='form-control mb-2'>{rentalType}</div>
                </div>
              </div>
            </div>
          )}

          <div className='card card-flush py-4'>
            <div className='card-header '>
              <div className='card-title  '>
                <h2>Category Details</h2>
              </div>
            </div>

            <div className='card-body p-0 px-2 pb-2'>
              <label className='form-label'>Categories</label>
              {<div className='form-control mb-2'>{categories}</div>}

              <label className='form-label d-block'>Tags</label>
              {
                <div className='form-control mb-2'>
                  {tags?.map((tag, idx) => (
                    <span className='badge badge-light mb-2 me-2' key={idx}>
                      {tag}
                    </span>
                  ))}
                </div>
              }
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Stock Details</h2>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='mb-10 fv-row'>
                <label className='form-label'>Opening Stock</label>
                <div className='form-control mb-2'>{openingStock}</div>
              </div>

              {/* subheading for accounting stock */}
              <h3 className='fw-bold fs-5 mb-5'>Accounting Stock</h3>
              <div className='mb-5 fv-row'>
                <label className='form-label'>Stock on Hand</label>
                <div className='form-control mb-2'>{inWarehouseQuantity}</div>
              </div>
              <div className='mb-5 fv-row'>
                <label className='form-label'>Committed Stock</label>
                <div className='form-control mb-2'>{commitedStock}</div>
              </div>
              <div className='mb-10 fv-row'>
                <label className='form-label'>Available for Sale</label>
                <div className='form-control mb-2'>{inWarehouseQuantity + commitedStock}</div>
              </div>
              <h3 className='fw-bold fs-5 mb-5'>Physical Stock</h3>
              <div className='mb-5 fv-row'>
                <label className='form-label'>Stock on Hand</label>
                <div className='form-control mb-2'>{inWarehouseQuantity}</div>
              </div>
              <div className='mb-5 fv-row'>
                <label className='form-label'>Committed Stock</label>
                <div className='form-control mb-2'>{commitedStock}</div>
              </div>
              <div className='mb-10 fv-row'>
                <label className='form-label'>Available for Sale</label>
                <div className='form-control mb-2'>{inWarehouseQuantity + commitedStock}</div>
              </div>

              <div className='mb-10 fv-row'>
                {/* four tiles with value and desc 2 up  and 2 down */}
                {/* add border and round */}
                <div className='d-flex flex-row gap-5 rounded-md border justify-center'>
                  <div className='d-flex flex-column gap-2'>
                    <div className='d-flex flex-column gap-2 p-3'>
                      <div className='fw-bold fs-5 text-center'>{quantityToBeShipped} Qty</div>
                      <div className='fw-bold fs-5 text-center'>To be Shipped</div>
                    </div>
                    {/* a line border */}
                    <div className='border-t border-gray-300'></div>
                    <div className='d-flex flex-column gap-2 p-3'>
                      <div className='fw-bold fs-5 text-center'>{quantityToBeReceived} Qty</div>
                      <div className='fw-bold fs-5 text-center'>To be Received</div>
                    </div>
                  </div>
                  {/* a line border */}
                  <div className='border-r border-gray-300'></div>
                  <div className='d-flex flex-column gap-2'>
                    <div className='d-flex flex-column gap-2 p-3'>
                      <div className='fw-bold fs-5 text-center'>{quantityToBeInvoiced} Qty</div>
                      <div className='fw-bold fs-5 text-center'>To be Invoiced</div>
                    </div>
                    <div className='border-t border-gray-300'></div>
                    <div className='d-flex flex-column gap-2 p-3'>
                      <div className='fw-bold fs-5 text-center'>{quantityToBeBilled} Qty</div>
                      <div className='fw-bold fs-5 text-center'>To be Billed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-8 mt-4'>
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4 active'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_general'
                >
                  Details
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_accounts'
                >
                  Accounts
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_documents'
                >
                  Documents
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-4'
                  data-bs-toggle='tab'
                  href='#kt_ecommerce_add_product_transactions'
                >
                  Transactions
                </a>
              </li>
            </ul>
            <div className='tab-content'>
              <div
                className='tab-pane fade show active'
                id='kt_ecommerce_add_product_general'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Item Details</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Item Name</label>

                        <div className='form-control mb-2'>{productName}</div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Description</label>
                        <div className='w-full rounded-lg h-full'>
                          <textarea
                            name='description'
                            value={description}
                            placeholder=''
                            rows={4}
                            cols={50}
                            readOnly
                            className='w-full rounded border px-4 py-2'
                          ></textarea>
                        </div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className=' form-label'>SKU</label>

                        <div className='form-control mb-2'>{sku}</div>
                      </div>

                      <div className='mb-10 w-50'>
                        <label className='form-label '>Unit</label>
                        <div className='form-control mb-2'>{unit}</div>
                      </div>

                      <div className='mb-10 fv-row'>
                        <label className=' form-label'>MRQ</label>

                        <div className='form-control mb-2'>{mrq}</div>
                      </div>
                    </div>
                  </div>
                  <Media
                    media={media}
                    setMedia={setMedia}
                    mediaName={mediaName}
                    setMediaName={setMediaName}
                  />

                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Other Details</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10'>
                        <label className='form-label'>Manufacturer</label>

                        <div className='form-control mb-2'>{manufacturer}</div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>Brand</label>

                        <div className='form-control mb-2'>{brand}</div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>UPC</label>

                        <div className='form-control mb-2'>{upc}</div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>EAN</label>

                        <div className='form-control mb-2'>{ean}</div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>MPN</label>

                        <div className='form-control mb-2'>{mpn}</div>
                      </div>
                      <div className='mb-10'>
                        <label className='form-label'>ISBN</label>

                        <div className='form-control mb-2'>{isbn}</div>
                      </div>
                    </div>
                  </div>

                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Item Dimensions</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div id='kt_ecommerce_add_product_shipping'>
                        <div className='mb-10 fv-row'>
                          <label className='form-label'>Weight</label>

                          <div className='form-control mb-2'>{weight}</div>
                        </div>

                        <div className='fv-row'>
                          <label className='form-label'>Dimension</label>

                          <div className='d-flex flex-wrap flex-sm-nowrap gap-3'>
                            <div className='form-control mb-2'>{width}</div>
                            <div className='form-control mb-2'>{height}</div>
                            <div className='form-control mb-2'>{length}</div>
                            {/* <input
                              type='number'
                              name='width'
                              className='form-control mb-2'
                              placeholder='Width (w)'
                              value={width}
                              disabled
                            />
                            <input
                              type='number'
                              name='height'
                              className='form-control mb-2'
                              placeholder='Height (h)'
                              value={height}
                              disabled
                            />
                            <input
                              type='number'
                              name='length'
                              className='form-control mb-2'
                              placeholder='Length (l)'
                              value={length}
                              disabled
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_accounts'
                role='tab-panel'
              >
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Sales Information</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Selling Price</label>
                        <div className='form-control mb-2'>{sellingPrice}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Account Information</label>
                        <div className='form-control mb-2'>
                          {salesAccount?.accountName || 'No Account Selected'}
                        </div>
                      </div>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Purchase Information</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Cost Price</label>
                        <div className='form-control mb-2'>{costPrice}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Account Information</label>
                        <div className='form-control mb-2'>
                          {purchaseAccount?.accountName || 'No Account Selected'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Inventory Information</h2>
                      </div>
                    </div>

                    <div className='card-body pt-0'>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Account Information</label>
                        <div className='form-control mb-2'>
                          {inventoryAccount?.accountName || 'No Account Selected'}
                        </div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Opening Stock</label>
                        <div className='form-control mb-2'>{openingStock}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Opening Stock Rate Per Unit</label>
                        <div className='form-control mb-2'>{openingStockRate}</div>
                      </div>
                      <div className='mb-10 fv-row'>
                        <label className='form-label'>Reorder Point</label>
                        <div className='form-control mb-2'>{reorderPoint}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_documents'
                role='tab-panel'
              >
                <DocumentsPage />
              </div>

              <div
                className='tab-pane fade'
                id='kt_ecommerce_add_product_transactions'
                role='tab-panel'
              >
                <TransactionTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
