import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {deleteProjectById, getTargetById} from '../core/_requests'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {changeProjectStatus} from '../core/_requests'

export default function ProjectCard({projectData, fetchProjects}) {
  console.log('projectdata', projectData)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [statusDropdown, setStatusDropdown] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('InProgress')

  useEffect(() => {
    setStatusDropdown(Array(projectData?.length).fill(false))
    setDropdown(Array(projectData?.length).fill(false))
  }, [projectData])

  const handleClick = (event, index) => {
    event.preventDefault()
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleDeleteToggle = (event, i) => {
    event.preventDefault()
    console.log(i)
    setSelectedIndex(i)
  }

  console.log('projectData', projectData)

  const handleDelete = async (i) => {
    try {
      console.log(i)
      const res = await deleteProjectById(i)
      if (res !== null) {
        const closeModalButton = document.getElementById('closeModalButtondelete')
        if (closeModalButton) {
          closeModalButton.click()
        }
      }
      fetchProjects()
    } catch (error) {
      console.error('Error deleting employee:', error)
    }
  }

  const toggleStatusDropdown = (event, index) => {
    event.preventDefault()
    setStatusDropdown((prev) => prev.map((value, i) => (i === index ? !value : false)))
  }

  const handleStatusChange = async (event, status, projectId, index) => {
    event.preventDefault()

    try {
      await changeProjectStatus(status, projectId)
      // projectData[index].status = status
      setStatusDropdown(Array(projectData.length).fill(false))
      console.log('Status changed to:', status)
      fetchProjects()
    } catch (error) {
      console.error('Error changing project status:', error)
    }
  }
  function getStatusColorClass(status) {
    switch (status) {
      case 'InProgress':
        return ' text-primary'
      case 'Completed':
        return ' text-success'
      case 'Overdue':
        return ' text-danger'
      case 'Pending':
        return ' text-warning'
      default:
        return 'bg-gray-100 text-dark'
    }
  }

  return (
    <div>
      <div className='row g-6 g-xl-9'>
        {Array.isArray(projectData) &&
          projectData?.map((data, i) => (
            // {Array.isArray(updatedProjectData) && updatedProjectData.map((data) => (
            <div key={i} className='col-md-6 col-xl-4'>
              {/* <div div className='col-md-6 col-xl-4' > */}
              <Link to={`/project/projectlist/details/${data._id}`}>
                <div className='card border-hover-primary w-full card-xl-stretch'>
                  <div className='card-header border-0 pt-9'>
                    <div className='card-title m-0'>
                      <div className='symbol symbol-50px w-50px bg-light'>
                        <img src='/media/svg/brand-logos/plurk.svg' alt='image' className='p-3' />
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      {/* <span className="badge badge-light-primary fw-bold me-2 px-4 py-3">In Progress</span> */}
                      <div className='relative'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            onClick={(event) => toggleStatusDropdown(event, i)}
                            className={`px-6 py-2 flex items-center rounded self-end bg-gray-100 ${getStatusColorClass(
                              data?.status
                            )}`}
                          >
                            {data?.status || 'Status'}
                            <RiArrowDropDownLine />
                          </button>
                        </div>
                        {statusDropdown[i] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-10 rounded p-3 z-50 space-y-2'>
                            {/* Dropdown for Project Status */}
                            <button
                              className='hover:bg-blue-50 text-primary p-2 w-full text-start rounded'
                              onClick={(event) => {
                                event.stopPropagation()
                                handleStatusChange(event, 'InProgress', data._id, i)
                              }}
                            >
                              In Progress
                            </button>
                            <button
                              className='hover:bg-blue-50  text-warning p-2 w-full text-start rounded'
                              onClick={(event) => {
                                event.stopPropagation()
                                handleStatusChange(event, 'Pending', data._id, i)
                              }}
                            >
                              Pending
                            </button>
                            <button
                              className='hover:bg-blue-50 text-success p-2 w-full text-start rounded'
                              onClick={(event) => {
                                event.stopPropagation()
                                handleStatusChange(event, 'Completed', data._id, i)
                              }}
                            >
                              Completed
                            </button>
                            <button
                              className='hover:bg-blue-50 text-danger p-2 w-full text-start rounded'
                              onClick={(event) => {
                                event.stopPropagation()
                                handleStatusChange(event, 'Overdue', data._id, i)
                              }}
                            >
                              Overdue
                            </button>
                          </div>
                        )}
                      </div>

                      <div className='ms-2 relative '>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            onClick={(event) => handleClick(event, i)}
                            className=' bg-gray-100 hover:bg-blue-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions
                            <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[i] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-10 rounded p-3 z-50 space-y-2'>
                            <Link to={`/project/edit/${data._id}`}>
                              <button className='hover:bg-blue-50 p-2 w-full text-start rounded'>
                                Edit
                              </button>
                            </Link>
                            <button
                              className='hover:bg-blue-50 p-2 text-start rounded '
                              onClick={(event) => handleDeleteToggle(event, data._id)}
                              data-bs-toggle='modal'
                              data-bs-target='#exampleModal'
                              // className="modal-dialog modal-dialog-centered"
                            >
                              {data?.isDeleted ? 'Activate' : 'Deactivate'}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='card-body p-9 w-full'>
                    <div className='fs-3 fw-bold text-dark'>{data.projectName}</div>
                    <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>
                      {data.projectDescription}
                    </p>
                    <div className='d-flex  mb-5'>
                      <div className='border border-gray-300 border-dashed rounded min-w-120px py-3 px-4 me-7 mb-3 '>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {data?.startDate
                            ? new Date(data.startDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })
                            : ''}
                        </div>
                        <div className='fw-semibold text-gray-400'>Start Date</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-120px py-3 px-4 me-7 mb-3 '>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {data?.releaseDate
                            ? new Date(data.releaseDate).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })
                            : ''}
                        </div>
                        <div className='fw-semibold text-gray-400'>Due Date</div>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-4  mb-3'>
                        <div className='fs-6 text-gray-800 fw-bold'>{data.budgetAmount}</div>
                        <div className='fw-semibold text-gray-400'>Budget</div>
                      </div>
                    </div>
                    <div
                      className='h-4px w-100 bg-light mb-8'
                      data-bs-toggle='tooltip'
                      title='This project 50% completed'
                    >
                      {/* <div className="bg-primary rounded h-4px" role="progressbar" style={{ width: `${data.budgetPercentage}%` }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div> */}
                      <div
                        className='bg-primary rounded h-4px'
                        role='progressbar'
                        style={{width: `${(data.totalBudgetOfTargets / data.budgetAmount) * 100}%`}}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                      <div className='fw-semibold text-gray-400 mt-2'>Budget spent </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

        <div
          className='modal fade'
          id='exampleModal'
          tabIndex={-1}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h1 className='modal-title fs-5' id='exampleModalLabel'>
                  Modal title
                </h1>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>

              <div className='modal-body text-center fs-6'>
                Are you sure you want to{' '}
                {projectData?.find((project) => project._id === selectedIndex)?.isDeleted
                  ? 'Activate'
                  : 'Deactivate'}{' '}
                the
                {projectData?.find((project) => project._id === selectedIndex)?.projectName} ?
              </div>

              <div className='modal-footer text-center mx-auto'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-bs-dismiss='modal'
                  id='closeModalButtondelete'
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-danger'
                  onClick={() => handleDelete(selectedIndex)}
                >
                  {' '}
                  {projectData?.find((project) => project._id === selectedIndex)?.isDeleted
                    ? 'Activate'
                    : 'Deactivate'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
