import React from 'react'
import TaskSummery from './TaskSummery'
// import TaskOverTime from './TaskOverTime'
import WhatsOnTheRoad from './WhatsOnTheRoad'
import LatestFile from './LatestFile'
import NewContibutors from './NewContibutors'
// import MyTasks from './MyTasks'
import TargetSpending from './TargetSpending'

export default function Overview({ setProperty, team }) {
  return (
    <div>
      <div className='row gx-6 gx-xl-9'>
        {/* <div class='col-lg-6'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <TaskOverTime />
          </div>
        </div> */}
        <div class='col-lg-6 mb-2'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <WhatsOnTheRoad />
          </div>
        </div>
        <div className='col-lg-6 mb-2'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <TaskSummery setProperty={setProperty} />
          </div>
        </div>
        <div class='col-lg-6'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <NewContibutors team={team} setProperty={setProperty} />
          </div>
        </div>
        <div class='col-lg-6'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <LatestFile setProperty={setProperty} />
          </div>
        </div>
        {/* <div class='col-lg-6'>
          <div className='card card-flush h-lg-100 card-xl-stretch'>
            <MyTasks />
          </div>
        </div> */}
      </div>
      <TargetSpending />
    </div>
  )
}
