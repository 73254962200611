import WareHouse from './Components/WareHouse'
import Supplier from './Components/Supplier'
import {Chart4} from './Components/Chart4'
import PieChart2 from './Components/PieChart2'
import {List4} from './Components/List4'
import {Lists7} from './Components/List7'
import {Chart3} from './Components/Chart3'
import {Stat1} from './Components/Stat1'
import {Charts8} from './Components/Charts8'
import {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {getVendorPerformance} from '../reports/core/requests'
import {OrderUpdate} from './Components/OrderUpdate'

type Props = {}

const Dashboard = (props: Props) => {
  const {currentUser} = useAuth()
  const [vendorsData, setVendorsData] = useState([])

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const data = await getVendorPerformance(
          currentUser.organization,
          new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
          new Date().toISOString()
        )

        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)

        setVendorsData(data)
      } catch (error) {
        console.error('Error fetching sales performance data:', error)
      }
    }

    if (currentUser.organization !== undefined) fetchVendorData()
  }, [currentUser])

  const top5Vendors = vendorsData.filter((vendor) => vendor.isActivated === true).slice(0, 5)

  return (
    <div>
      <div className='row g-5 g-xl-8 mb-6'>
        <div className='col-xl-8'>
          <Stat1 />
        </div>
        <div className='col-xl-4'>
          {/* <PieChart2 /> */}
          <List4 className='card-xl-stretch h-100' vendors={top5Vendors} />
        </div>
      </div>

      <div className='row g-5 g-xl-8 mb-3'>
        <div className='col-xl-6'>
          <Lists7 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          {/* <Chart3 className='card-xl-stretch mb-xl-9' /> */}
          <OrderUpdate />
        </div>
      </div>
      {/* 
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-5'></div>
        <div className='col-xl-3'>
          <List4 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'></div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <Supplier />
        </div>
        <div className='col-xl-6'>
          <WareHouse />
        </div>
      </div> */}

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <Chart4
            className='card-xl-stretch mb-5 mb-xl-8'
            color='primary'
            description=''
            change='v'
          />
        </div>
        <div className='col-xl-8'>
          <Charts8 className='card-xl-stretch mb-xl-8' />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
