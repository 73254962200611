import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {postCustomerById} from '../Core/_requests'
import { useAuth } from '../../../modules/auth'

export default function AddContactPerson({setCustomerData, customerData}) {
  const {customerId} = useParams()
  const {currentUser} = useAuth()
  const [newContactPerson, setNewContactPerson] = useState({
    salutation: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    workPhone: '',
    mobile: ' ',
    department: ' ',
    designation: ' ',
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setNewContactPerson((prevPerson) => ({
      ...prevPerson,
      [name]: value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const updatedCustomerData = {
      ...customerData,
      contactPersons: [...customerData.contactPersons, newContactPerson],
      company: currentUser.company,
      organization: currentUser.organization,
    }
    setCustomerData(updatedCustomerData)
    await postCustomerById(updatedCustomerData, customerId).then((response) => {
      setNewContactPerson({
        salutation: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        workPhone: '',
        mobile: '',
        department: '',
        designation: '',
      })
    })
  }

  return (
    <div>
      <div>
        <form>
          <div className='mb-3 d-flex items-center'>
            <label className='form-label w-1/4'>Name</label>
            <div className='d-flex space-x-4'>
              <select
                className='form-select form-control form-control form-control-solid'
                name='salutation'
                value={newContactPerson.salutation}
                onChange={handleChange}
              >
                <option value='Mr'>Mr</option>
                <option value='Mrs'>Mrs</option>
                <option value='Ms'>Ms</option>
              </select>
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='firstName'
                value={newContactPerson.firstName}
                onChange={handleChange}
                placeholder='First Name'
              />
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='lastName'
                value={newContactPerson.lastName}
                onChange={handleChange}
                placeholder='Last Name'
              />
            </div>
          </div>

          <div className='mb-3 d-flex items-center'>
            <label className='form-label required w-1/4 '>Email Address</label>
            <input
              type='email'
              className='form-control form-control-solid '
              name='emailAddress'
              value={newContactPerson.emailAddress}
              onChange={handleChange}
              placeholder='Email Address'
            />
          </div>

          <div className='mb-3 d-flex items-center'>
            <label className='form-label w-1/4'>Customer Info</label>
            <div className='d-flex space-x-4'>
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='workPhone'
                value={newContactPerson.workPhone}
                onChange={handleChange}
                placeholder='Work Phone'
              />
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='mobile'
                value={newContactPerson.mobile}
                onChange={handleChange}
                placeholder='Mobile Phone'
              />
            </div>
          </div>

          <div className='mb-3 d-flex items-center'>
            <label className='form-label w-1/4'>Other details</label>
            <div className='d-flex space-x-4'>
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='designation'
                value={newContactPerson.designation}
                onChange={handleChange}
                placeholder='Designation'
              />
              <input
                type='text'
                className='form-control form-control form-control form-control-solid'
                name='department'
                value={newContactPerson.department}
                onChange={handleChange}
                placeholder='Department'
              />
            </div>
          </div>

          <div class='modal-footer'>
            <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>
              Close
            </button>
            <button type='submit' onClick={handleSubmit} class='btn btn-primary'>
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
