import {ID, Response} from '../../../../_metronic/helpers'

// _id: {
//   // Assuming ObjectId is a string, adjust this accordingly based on your actual data
//   toString(): string;
// };



export type Project = {
  _id?: ID
  title?: string
  description?: string
  status?: string
  date?: string
  budget?: string
  progress?: string
  users?: string
  statusColor?: string
  badgeColor?: string
  icon?: string

}

export type Manager = {
  _id?: ID
  email?:string
  name?: string
  date?: string
  amount?: string
  status?: string
  badgeColor?: string
  
}
export interface SearchFilter {
  search?: string
  agent?: string
  filter?: Filtertype
}

export interface Filtertype {
  isActive?: boolean
  projectStatus?: string
}

export const initialSearchFilter: SearchFilter = {
  search: '',
  agent: '',
  filter: {
    isActive: false,
    projectStatus:'InProgress',
  },

}

 

// export type ClientQueryResponse = Response<Array<Client>>


