import React from 'react'

export default function Shipping({
  // shipping,
  // setShipping,
  weight,
  setWeight,
  width,
  setWidth,
  height,
  setHeight,
  length,
  setLength,
}) {
  return (
    // <div class="card border-0 p-4 rounded-md mt-3 ">
    //   <div class="card-body space-y-6">
    //     <h5 class="card-title text-start fw-semibold fs-5">Shipping</h5>
    //     <div className="text-start">
    //       <input
    //         type="checkbox"
    //         value={shipping}
    //         onChange={(e) => setShipping(!shipping)}
    //       />
    //       <span className="text-gray-400  text-sm ms-2">
    //         This is a physical test
    //       </span>
    //       <p className="text-gray-400 text-[12px]">
    //         Set if the product is a physical or digital item. Physical products
    //         may require shipping.
    //       </p>
    //     </div>
    //     {shipping ? (
    //       <div className="text-start space-y-2 text-sm">
    //         <div className=" space-y-2">
    //           <label className="text-start">Weight</label>
    //           <div>
    //             <input
    //               placeholder="Product Weight"
    //               className="rounded border px-4 py-2 w-full"
    //               value={weight}
    //               onChange={(e) => setWeight(e.target.value)}
    //             />
    //           </div>
    //           <p className="text-gray-400">
    //             Set a product weight in kilograms (kg).
    //           </p>
    //         </div>
    //         <div>
    //           <label className="text-start">Dimension</label>
    //           <div className="d-flex w-full space-x-2">
    //             <input
    //               placeholder="Width(w)"
    //               className="rounded border px-4 py-2 w-full"
    //               value={width}
    //               onChange={(e) => setWidth(e.target.value)}
    //             />
    //             <input
    //               placeholder="Height(h)"
    //               className="rounded border px-4 py-2 w-full"
    //               value={height}
    //               onChange={(e) => setHeight(e.target.value)}
    //             />
    //             <input
    //               placeholder="Length(l)"
    //               className="rounded border px-4 py-2 w-full"
    //               value={length}
    //               onChange={(e) => setLength(e.target.value)}
    //             />
    //           </div>
    //           <p className="text-gray-400">
    //             Enter the product dimensions in centimeters (cm).
    //           </p>
    //         </div>
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </div>
    <div class='card card-flush py-4'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>Item Dimensions</h2>
        </div>
      </div>

      <div class='card-body pt-0'>
        {/* <div class='fv-row'>
          <div class='form-check form-check-custom form-check-solid mb-2'>
            <input
              class='form-check-input'
              type='checkbox'
              id='kt_ecommerce_add_product_shipping_checkbox'
              value={shipping}
              onChange={(e) => setShipping(!shipping)}
            />
            <label class='form-check-label'>This is a physical product</label>
          </div>

          <div class='text-muted fs-7'>
            Set if the product is a physical or digital item. Physical products may require
            shipping.
          </div>
        </div> */}
        {/* {shipping ? ( */}
          <div id='kt_ecommerce_add_product_shipping'>
            <div class='mb-10 fv-row'>
              <label class='form-label'>Weight</label>

              <input
                type='text'
                name='weight'
                class='form-control mb-2'
                placeholder='Product weight'
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />

              <div class='text-muted fs-7'>Set a item weight in kilograms (kg).</div>
            </div>

            <div class='fv-row'>
              <label class='form-label'>Dimension</label>

              <div class='d-flex flex-wrap flex-sm-nowrap gap-3'>
                <input
                  type='number'
                  name='width'
                  class='form-control mb-2'
                  placeholder='Width (w)'
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
                <input
                  type='number'
                  name='height'
                  class='form-control mb-2'
                  placeholder='Height (h)'
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
                <input
                  type='number'
                  name='length'
                  class='form-control mb-2'
                  placeholder='Lengtn (l)'
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                />
              </div>

              <div class='text-muted fs-7'>Enter the item dimensions in centimeters (cm).</div>
            </div>
          </div>
        {/* ) : (
          ' '
        )} */}
      </div>
    </div>
  )
}
