// import React from 'react'
// import ReportsList from './ReportsList'
// import { Route, Routes } from 'react-router-dom'
// import SalesPerformance from './components/SalesPerformance'
// import VendorPerformance from './components/VendorPerformance'
// import ProjectPerformance from './components/ProjectPerformance'

// const ReportsPage = () => {
//   return (
//     <Routes>
//     <Route path='/' element={<ReportsList/>} />
//     <Route path='/salesperformance' element={<SalesPerformance/>} />
//     <Route path='/vendorperformance' element={<VendorPerformance/>} />
//     <Route path='/projectperformance' element={<ProjectPerformance/>} />

//   </Routes>
//   )
// }

// export default ReportsPage
import React from 'react'

export default function ReportsPage() {
  return <div></div>
}
