import React, {FC, useEffect, useState} from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import Select from 'react-select'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'

const Step6: FC = () => {
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [workPhoneNo, setWorkPhoneNo] = useState<string | undefined>('')
  const [personalNo, setPersonalNo] = useState<string | undefined>('')

  useEffect(() => {
    if (values?.workPhoneNo && values?.personalMobile) {
      setWorkPhoneNo(String(values.workPhoneNo) || '')
      setPersonalNo(String(values.personalMobile) || '')
    }
  }, [])

  useEffect(() => {
    setFieldValue('workPhoneNo', workPhoneNo)
    setFieldValue('personalMobile', personalNo)
  }, [setFieldValue, workPhoneNo, personalNo])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Contact Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Work Phone Number</label>
        <div className='form-control form-control-lg form-control-solid'>
          <PhoneInput
            country={'sa'}
            value={workPhoneNo}
            onChange={(phone) => setWorkPhoneNo(phone)}
          />
        </div>
        {/* <Field
          type='mobile'
          className='form-control form-control-lg form-control-solid'
          name='workPhoneNo'
        /> */}

        <div className='text-danger mt-2'>
          <ErrorMessage name='workPhoneNo' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Extension</label>

        <Field
          type='mobile'
          className='form-control form-control-lg form-control-solid'
          name='extension'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='extension' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Personal Mobile Number</label>
        <div className='form-control form-control-lg form-control-solid'>
          <PhoneInput
            country={'sa'}
            value={personalNo}
            onChange={(phone) => setPersonalNo(phone)}
          />
        </div>
        {/* <Field
          type='mobile'
          className='form-control form-control-lg form-control-solid'
          name='personalMobile'
        /> */}
        <div className='text-danger mt-2'>
          <ErrorMessage name='personalMobile' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Personal Email</label>

        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name='personalEmail'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='personalEmail' />
        </div>
      </div>
      {/* <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Seating Location</label>

        <Field
          type='location'
          className='form-control form-control-lg form-control-solid'
          name='seatingLocation'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='seatingLocation' />
        </div>
      </div> */}

      <div className='fv-row mb-10'>
        <label className='form-label'>Tags</label>
        <Field
          as='textarea'
          name='tags'
          className='form-control form-control-lg form-control-solid'
        ></Field>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Present Address</label>
        <div className='row'>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentAddressLine1'
              className='form-control form-control-lg form-control-solid'
              placeholder='Address Line 1'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentAddressLine2'
              className='form-control form-control-lg form-control-solid'
              placeholder='Address Line 2'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentCountry'
              className='form-control form-control-lg form-control-solid'
              placeholder='Country'
            />

            <div className='text-danger mt-2'>
              <ErrorMessage name='country' />
            </div>
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentState'
              className='form-control form-control-lg form-control-solid'
              placeholder='State'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentCity'
              className='form-control form-control-lg form-control-solid'
              placeholder='City'
            />
          </div>
          <div className='col-md-6 mb-3'>
            <Field
              type='text'
              name='presentPostalcode'
              className='form-control form-control-lg form-control-solid'
              placeholder='Postal Code'
            />
          </div>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentAddressLine1' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentAddressLine2' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentCountry' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentCity' />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='presentPostalCode' />
        </div>
      </div>

      <div className='mb-3'>
        <Field type='checkbox' name='sameAsPresentAddress' id='sameAsPresentAddress' />
        <label htmlFor='sameAsPresentAddress' className='ms-2'>
          Same as Present Address
        </label>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>Permanent Address</label>
        {/* Conditional rendering based on checkbox */}
        {(values as {sameAsPresentAddress: boolean}).sameAsPresentAddress ? (
          <>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentAddressLine1'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 1'
                  value={values?.presentAddressLine1}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentAddressLine2'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 2'
                  value={values?.presentAddressLine2}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentCountry'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                  value={values?.presentCountry}
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='country' />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentState'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                  value={values?.presentState}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentCity'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  value={values?.presentCity}
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='presentPostalCode'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                  value={values?.presentPostalcode}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentAddressLine1'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 1'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentAddressLine2'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Address Line 2'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentCountry'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Country'
                />

                <div className='text-danger mt-2'>
                  <ErrorMessage name='country' />
                </div>
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentState'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='State'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentCity'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                />
              </div>
              <div className='col-md-6 mb-3'>
                <Field
                  type='text'
                  name='permanentPostalCode'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export {Step6}
