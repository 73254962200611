import React, {useState} from 'react'

export default function Automation({
  isManualChecked,
  setIsManualChecked,
  isAutomaticChecked,
  setIsAutomaticChecked,
  allConditionsChecked,
  setAllConditionsChecked,
  anyConditionsChecked,
  setAnyConditionsChecked,
  conditions,
  categoryType,
  setCategoryType,
  setConditions,
}) {
  const handleManualChange = () => {
    setIsManualChecked(!isManualChecked)
    setIsAutomaticChecked(false)
    setCategoryType('Manual')
  }

  const handleAutomaticChange = () => {
    setIsAutomaticChecked(!isAutomaticChecked)
    setIsManualChecked(false)
    setCategoryType('Automated')
  }
  const handleAllConditionsChange = () => {
    setAllConditionsChecked(!allConditionsChecked)
    setAnyConditionsChecked(false)
  }

  const handleAnyConditionsChange = () => {
    setAnyConditionsChecked(!anyConditionsChecked)
    setAllConditionsChecked(false)
  }

  const handleAddCondition = (e) => {
    e.preventDefault()
    setConditions([...conditions, {key: '', operator: '', value: ''}])
  }

  const handleConditionChange = (index, key, value) => {
    const updatedConditions = [...conditions]
    updatedConditions[index][key] = value
    setConditions(updatedConditions)
  }

  const handleDeleteCondition = (e, index) => {
    e.preventDefault()
    const updatedConditions = [...conditions]
    updatedConditions.splice(index, 1)
    setConditions(updatedConditions)
  }

  return (
    <div className='text-start space-y-2 bg-white p-7 mt-3 rounded'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>Automation</h2>
        </div>
      </div>
      {/* <h1 className='text-start fw-semibold mb-3'>Automation</h1> */}
      <label class='form-label mb-5'>Product assignment method</label>

      <div className='d-flex pb-0 p-2 items-center space-x-4 px-3'>
        <input
          type='radio'
          className='h-8 w-8'
          checked={isManualChecked}
          onChange={handleManualChange}
        />

        <div>
          <h6 className='mb-0 '>Manual</h6>
          <p className='text-sm pb-0 text-gray-600'>
            Add products to this category one by one by manually selecting this category during
            product creation or update.
          </p>
        </div>
      </div>

      <div className='d-flex pb-0 p-2 items-center space-x-4 px-3'>
        <input
          type='radio'
          className='h-8 w-8'
          checked={isAutomaticChecked}
          onChange={handleAutomaticChange}
        />

        <div>
          <h6 className='mb-0'>Automatic</h6>
          <p className='text-sm pb-0 text-gray-600'>
            Products matched with the following conditions will be automatically assigned to this
            category.
          </p>
        </div>
      </div>

      {isAutomaticChecked && (
        <div className='p-3'>
          <h5>Conditions</h5>
          <div className='d-flex items-center relative space-x-2'>
            <p className='items-center text-gray-600'>Product must match</p>

            <div className='d-flex space-x-6 items-center'>
              <input
                type='radio'
                className='h-4 w-4 absolute top-1'
                checked={allConditionsChecked}
                onChange={handleAllConditionsChange}
              />
              <p className='items-center text-gray-600'>All conditions</p>
            </div>

            <div className='d-flex space-x-6 items-center'>
              <input
                type='radio'
                className='h-4 w-4 absolute top-1'
                checked={anyConditionsChecked}
                onChange={handleAnyConditionsChange}
              />
              <p className='items-center text-gray-600'>Any conditions</p>
            </div>
          </div>

          {conditions &&
            conditions.map((condition, index) => (
              <div className='row' key={index}>
                <div className='col-md-3 col-12 mb-3'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    value={condition.key}
                    onChange={(e) => handleConditionChange(index, 'key', e.target.value)}
                  >
                    <option value='Product Tag'>Product Tag</option>
                    <option value='Product Title'>Product Title</option>
                    <option value='Product Price'>Product Price</option>
                  </select>
                </div>

                <div className='col-md-3 col-12 mb-3'>
                  <select
                    className='form-select'
                    aria-label='Default select example'
                    value={condition.operator}
                    onChange={(e) => handleConditionChange(index, 'operator', e.target.value)}
                  >
                    <option value='is equal to '>is equal to </option>
                    <option value='is not equal to '>is not equal to </option>
                    <option value='is greather than'>is greather than </option>
                    <option value='is less than'>is less than</option>
                    <option value='starts with'>starts with</option>
                    <option value='ends with'>ends with</option>
                  </select>
                </div>

                <div className='col-md-3 col-8 '>
                  <input
                    className='rounded border px-4 py-2 w-full '
                    value={condition.value}
                    onChange={(e) => handleConditionChange(index, 'value', e.target.value)}
                  />
                </div>

                <div className='col-md-2 col-4'>
                  <button
                    onClick={(e) => handleDeleteCondition(e, index)}
                    className='bg-pink-100 text-pink-500 px-2 py-1'
                  >
                    X
                  </button>
                </div>
              </div>
            ))}

          <button
            onClick={handleAddCondition}
            className='bg-lime-100 text-lime-500 px-6 flex justify-start mt-6 rounded py-1 hover:bg-lime-500 hover:text-lime-100 '
          >
            Add New Condition
          </button>
        </div>
      )}
    </div>
  )
}
