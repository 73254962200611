import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {
  getEmployeeReportSubmission,
  getEmployeeReportSubmissionCount,
  getEmployeeReports,
} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setReportSubmissionData} from '../../../redux/hrm/reportSubmission/reportSubmissionSlice'

const ReportSubmission = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [forms, setForms] = useState([])
  // const [list, setList] = useState([])
  const dispatch = useDispatch()

  const list = useSelector((state: any) => state.hrm.reportSubmission.reportSubmissionData)

  const fetchReportsForm = async () => {
    const res = await getEmployeeReports(currentUser?.organization)
    console.log(res)
    setForms(res)
  }
  const fetchReportsSubmission = async () => {
    const res = await getEmployeeReportSubmissionCount(currentUser.organization)
    console.log('report submissions data', res)
    // setList(res)
    dispatch(setReportSubmissionData(res))
  }
  useEffect(() => {
    fetchReportsForm()
    fetchReportsSubmission()
  }, [])

  const handleAdd = () => {
    navigate('add')
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Employee Report Submission History</h4>
          <div className='btn btn-primary' onClick={handleAdd}>
            <KTIcon iconName='plus' className='me-2' />
            Report Submission
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Form Name</th>
                  <th className='min-w-125px'>Department</th>
                  <th className='min-w-125px'>Role</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Count</th>
                  {/* <th className='min-w-125px'>Employee Email</th> */}
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/hrm/submissionReports/viewSubmissions/${item?._id}`}>
                          {item?.report?.title}
                        </Link>
                      </td>
                      <td>{item?.department?.name}</td>
                      <td>{item?.report?.role}</td>
                      <td>{new Date(item?.report?.createdAt).toLocaleDateString('en-GB')}</td>
                      <td>{item?.count}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportSubmission
