import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReportSubmission from './Components/ReportSubmission'
import AddReportSubmission from './Pages/AddReportSubmission'
import ViewReportSubmissions from './Pages/ViewReportSubmissions'
import ViewSubmissionDetails from './Pages/ViewSubmissionDetails'
export default function EmployeeReportsRoutes() {
    return (
      <Routes>
      <Route path='/' element={<ReportSubmission />} /> 
      <Route path='/add' element={<AddReportSubmission />} /> 
      <Route path='/viewSubmissions/:id' element={<ViewReportSubmissions />} /> 
      <Route path='/viewSubmissionDetails/:id' element={<ViewSubmissionDetails />} /> 

      </Routes>
    )
  }