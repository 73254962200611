import {createSlice} from '@reduxjs/toolkit'

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    organizationData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setOrganizationData(state, action) {
      state.organizationData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setOrganizationData, setLoading, setError} = organizationSlice.actions
export default organizationSlice.reducer
