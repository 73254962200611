import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {KTIcon} from '../../../../../_metronic/helpers'
import {
  getFilteredInvoices,
  getFilteredInvoicesByAgentId,
  invalidateInvoice,
} from '../../Core/_requests'
import {Filtertype2, RootState, ValidFilter} from '../../Core/_model'
import Filter2 from '../Filter2'
import qs from 'qs'
import {useSelector, useDispatch} from 'react-redux'
import {setInvoiceData} from '../../../../redux/sales/invoices/invoice'
import {RiArrowDropDownLine} from 'react-icons/ri'
import Pagination from '../../../purchaseOrders/Components/Pagination'
import debounce from 'lodash/debounce'

function InvoiceList() {
  const {currentUser} = useAuth()
  const initialRef = useRef(true)
  const [permissions, setPermissions] = useState<any>({})
  const [filteredInvoiceList, setFilteredInvoiceList] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const invoiceList = useSelector((state: RootState) => state.sales.invoice.invoiceData)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getdealslist = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredInvoices(queryString, currentUser?.organization, currentPage, 25)
      setFilteredInvoiceList(res?.invoices)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.invoices.length).fill(false))
      return res?.invoices
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const getInvoiceForAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredInvoicesByAgentId(queryString, currentUser?._id, currentPage, 25)
      setFilteredInvoiceList(res?.invoices)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.invoices.length).fill(false))
      return res?.invoices
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const getInvoicesData = () => {
    if (permissions?.invoice?.admin === false) {
      getInvoiceForAgent()
    } else if (permissions?.invoice?.admin === true || currentUser?.profileType === 'superadmin') {
      getdealslist()
    }
  }

  const getInvoices = () => {
    if (invoiceList.length === 0) {
      if (permissions?.invoice?.admin === false) {
        getInvoiceForAgent().then((res) => dispatch(setInvoiceData(res)))
      } else if (
        permissions?.invoice?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getdealslist().then((res) => dispatch(setInvoiceData(res)))
      }
    } else {
      setFilteredInvoiceList(invoiceList)
      setDropdown(Array(invoiceList.length).fill(false))
    }
  }

  const debounceSearch = debounce(getInvoicesData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage])

  useEffect(() => {
    getInvoices()
  }, [permissions])

  const handleActivation = async (id) => {
    const res = await invalidateInvoice(id)

    if (res) {
      getInvoices()
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
        status: '',
      },
    })
    getInvoices()
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Invoices</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Invoice ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getdealslist}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'partial', label: 'Partial'},
                {value: 'paid', label: 'Paid'},
                {value: 'pending', label: 'Pending'},
              ]}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        {(permissions?.invoice?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/sales/invoice/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Invoice
            </Link>
          </div>
        )}
      </div>

      <div className='card-body py-3'>
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Invoice#</th>
                  <th className='min-w-150px'>Order Number</th>
                  <th className='min-w-150px'>Customer Name</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px'>Due Date</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Balance Due</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.invoice?.update || currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {filteredInvoiceList?.map((item: any, index: number) => (
                  <tr key={item?.id?.toString()}>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {/* {formatCreatedAt(item?.date)} */}
                        <td>{new Date(item?.date)?.toLocaleDateString('en-GB')}</td>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.salesOrderRef?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.customer?.displayName}
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>{item?.status}</span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/details/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {formatCreatedAt(item?.dueDate)}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {item?.total.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          {item?.customer?.currency}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/details/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}> {item?.balanceAmount}</span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/sales/invoice/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.invoice?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {item?.status === 'pending' && item?.valid && (
                              <>
                                <Link to={`edit/${item._id}`}>
                                  <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                    Edit
                                  </button>
                                </Link>

                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id)}
                                >
                                  Invalid
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default InvoiceList
