import * as Yup from 'yup'

export interface ICreateAccount {
  primaryContact: {
    salutation: string
    firstName: string
    lastName: string
  },
  companyName: string
  displayName: string
  vendorID: string
  vendorSupplierNo: string
  emailAddress: string
  contactNumbers: {
      workPhone: string
      mobilePhone: string
    },
  idNumber: string
  currency: string
  openingBalance: number
  exchangeRate: number
  paymentTerms: string
  tds: string
  portalLanguage: string
  websiteUrl: string
  industry: string
  department: string
  designation: string
  billingAddress: {
    attention: string
    country: string
    region: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    phone: string
    faxNumber: string
  }
  sameAsBillingAddress: boolean

  shippingAddress: {
    attention: string
    country: string
    region: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    phone: string
    faxNumber: string
  }

  contactPersons: ContactPerson[];
}
interface ContactPerson {
  salutation: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  workPhone: string;
  mobile: string;
  designation : string;
  department : string;
}

const createAccountSchemas = [
  Yup.object({
    // salutatuion: Yup.string().required().label('Salutation'),
    // firstName: Yup.string().required().label('First Name'),
    // lastName: Yup.string().required().label('Last Name'),
    primaryContact: Yup.object().shape({
      salutation: Yup.string().required("Salutation is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last name is required"),
    }),
    companyName: Yup.string().required().label('Company Name'),
    displayName: Yup.string().required().label('Display Name'),
    vendorID: Yup.string().required().label('vendor ID '),

    emailAddress: Yup.string().required().label('Email'),
    contactNumbers: Yup.object().shape({
      // workPhone: Yup.string().required("Work Phone Number is required"),
      mobilePhone: Yup.string().required("Mobile Phone Number is required"),
    }),
    // workPhone: Yup.number().required().label('Work Phone'),
    // personalMobile: Yup.number().required().label('Personal Mobile'),
  }),
  Yup.object({
    // idNumber: Yup.string().required().label('PAN Number'),
    // currency: Yup.string().required().label('Currency'),
    // openingBalance: Yup.number().required().label('Opening Balance'),
    // exchangeRate: Yup.number().required().label('Exchange Rate'),
    // paymentTerms: Yup.string().required().label('Payment Terms'),
    // tds: Yup.string().required().label('TDS'),
    // portalLanguage: Yup.string().required().label('Portal Language'),
    // websiteUrl: Yup.string().required().label('Website URL'),
    // industry: Yup.string().required().label('industry'),
    // designation: Yup.string().required().label('Designation'),
  }),
  Yup.object({
    // presentAddressLine1: Yup.string().required().label('Present Address Line 1'),
    // presentAddressLine2: Yup.string().required().label('Present Address Line 2'),
    // presentCity: Yup.string().required().label('Present City'),
    // presentState: Yup.string().required().label('Present State'),
    // presentCountry: Yup.string().required().label('Present Country'),
    // presentPostalcode: Yup.number().required().label('Present Postal Code'),
    // permanentAddressLine1: Yup.string().required().label('Permanent Address Line 1'),
    // permanentAddressLine2: Yup.string().required().label('Permanent Address Line 2'),
    // permanentCountry: Yup.string().required().label('Permanent Country'),
    // permanentState: Yup.string().required().label('Permanent State'),
    // permanentCity: Yup.string().required().label('Permanent City'),
    // permanentPostalCode: Yup.number().required().label('Permanent Postal Code'),
  }),
  Yup.object({
    // contactSalutation: Yup.string().required().label('Contact Salutation'),
    // contactFirstName: Yup.string().required().label('Contact First Name'),
    // contactLastName: Yup.string().required().label('Contact Last Name'),
    // contactEmail: Yup.string().required().label('Contact Email'),
    // contactWorkPhone: Yup.number().required().label('Contact Work Phone'),
    // contactPersonalMobile: Yup.number().required().label('Contact Personal Mobile'),
    // contactDesignation: Yup.string().required().label('Contact Designation'),
    // contactDepartment: Yup.string().required().label('Contact Department'),
  }),
]

const inits: ICreateAccount = {
  primaryContact: {
    salutation: '',
    firstName: '',
    lastName: '',
  },
  companyName: '',
  displayName: '',
  vendorID:'',
  vendorSupplierNo:'',
  emailAddress: '',
  contactNumbers: {
    workPhone: '',
    mobilePhone: '',
  },
  idNumber:  '',
  currency:  'SAR',
  openingBalance:  0,
  exchangeRate: 0,
  paymentTerms:  '',
  tds:  '',
  portalLanguage:  '',
  websiteUrl:  '',
  industry:  '',
  department:  '',
  designation:  '',
  billingAddress: {
    attention: "",
    country:"",
    region: "",
    addressLine1:"",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    faxNumber: "",
  },
  sameAsBillingAddress: false,
  shippingAddress: {
    attention: "",
    country:"",
    region: "",
    addressLine1:"",
    addressLine2: "",
    city: "",
    state: "",
    postalCode: "",
    phone: "",
    faxNumber: "",
  },
  contactPersons: [
    {
      salutation: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      workPhone: '',
      mobile: '',
      designation : " ",
      department : " ",

    },
  ],
}

export {createAccountSchemas, inits}
