import {createSlice} from '@reduxjs/toolkit'

const journalEntrySlice = createSlice({
  name: 'journalEntry',
  initialState: {
    journalEntryData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setJournalEntryData(state, action) {
      state.journalEntryData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setJournalEntryData, setLoading, setError} = journalEntrySlice.actions
export default journalEntrySlice.reducer
