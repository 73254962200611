import axios, {AxiosResponse} from 'axios'
import {User, UsersQueryResponse} from './_models'
import {ID, Response} from '../../../../../_metronic/helpers'


const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const DELETE_URL = `${API_URL}/agent/deactivate`
const ACTIVATE_URL = `${API_URL}/agent/activate`
// const GET_USERS_URL = `https://preview.keenthemes.com/theme-api/api/users/query`
const GET_USERS_URL = `${API_URL}/agent/active`
const GET_USERBYID_URL = `${API_URL}/agent/detailsums`
const POST_USERS_URL = `${API_URL}/agent/`
const EDIT_USERS_URL = `${API_URL}/agent/edit`
const ROLES_URL = `${API_URL}/user-management/roles`

// const getUsers = (query: string,companyid:string) => {
//   console.log(query)
//    return axios
//     .get(`${GET_USERS_URL}/${companyid}?${query}`)
//     .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
// }

const getUsers = (query: string, departmentid: string, orgid: string) => {
  console.log(query)
  return axios
    .get(`${GET_USERS_URL}/department/${orgid}/${departmentid}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${GET_USERBYID_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (
  user: User,
  companyid: string,
  departmentid: string,
  orgid: string
): Promise<User | undefined> => {
  return axios
    .post(`${POST_USERS_URL}add-user/${companyid}/${orgid}/${departmentid}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createHierarchy1User = (
  user: User,
  companyid: string,
): Promise<User | undefined> => {
  return axios
    .post(`${POST_USERS_URL}add-user/${companyid}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}


const updateUser = (user: User): Promise<User | undefined> => {
  console.log(user)
  return axios
    .put(`${EDIT_USERS_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}


const updateUserWithHierarchy = (user: User): Promise<User | undefined> => {
  console.log(user)
  return axios
    .put(`${EDIT_USERS_URL}/hierarchy/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.put(`${DELETE_URL}/${userId}`).then(() => {})
}

const activateuser = (userId: ID): Promise<void> => {
  return axios.put(`${ACTIVATE_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export async function getRolesWithHierarchy(deptid: string, roleName: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ROLES_URL}/getroleswithhierarchy/${deptid}/${roleName}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  activateuser,
  createHierarchy1User,
  updateUserWithHierarchy
}
