/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../../helpers'

type Props = {
    className: string
    //   description: string
    icon: boolean
    //   stats: number
    labelColor: string
    textColor: string
}

const items: Array<{
    name: string
    initials?: string
    src?: string
    state?: string
}> = [
        { name: 'Alan Warden', initials: 'A', state: 'warning' },
        { name: 'Michael Eberon', src: toAbsoluteUrl('/media/avatars/300-11.jpg') },
        { name: 'Susan Redwood', initials: 'S', state: 'primary' },
        { name: 'Melody Macy', src: toAbsoluteUrl('/media/avatars/300-2.jpg') },
        { name: 'Perry Matthew', initials: 'P', state: 'danger' },
        { name: 'Barry Walter', src: toAbsoluteUrl('/media/avatars/300-12.jpg') },
        { name: 'Barry Walter', src: toAbsoluteUrl('/media/avatars/300-12.jpg') },
    ]

const CardsWidgetOverview = ({ className, icon, labelColor, textColor }: Props) => (
    <div className="">
        {/* <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{stats}</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{description}</span>
        </div>
      </div>
    </div> */}
        <div className="card bg-white rounded-2xl px-3   ">
            <div className="d-flex justify-content-between mb-8 py-2">
                <div className="d-flex align-items-center gap-2">
                    <div className="rounded-circle bg-primary  d-flex align-items-center justify-content-center m-2 p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <g id="icons">
                                <g id="vuesax/linear/user">
                                    <g id="user">
                                        <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M11.4998 2.52051C9.34823 2.52051 7.604 4.26473 7.604 6.41634C7.604 8.56795 9.34823 10.3122 11.4998 10.3122C13.6514 10.3122 15.3957 8.56795 15.3957 6.41634C15.3957 4.26473 13.6514 2.52051 11.4998 2.52051ZM6.229 6.41634C6.229 3.50534 8.58884 1.14551 11.4998 1.14551C14.4108 1.14551 16.7707 3.50534 16.7707 6.41634C16.7707 9.32734 14.4108 11.6872 11.4998 11.6872C8.58884 11.6872 6.229 9.32734 6.229 6.41634Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd" d="M3 21.1042C3 17.0517 6.9757 14 11.5617 14C16.1476 14 20.1233 17.0517 20.1233 21.1042C20.1233 21.4839 19.8155 21.7917 19.4358 21.7917C19.0561 21.7917 18.7483 21.4839 18.7483 21.1042C18.7483 18.0616 15.6657 15.375 11.5617 15.375C7.45763 15.375 4.375 18.0616 4.375 21.1042C4.375 21.4839 4.0672 21.7917 3.6875 21.7917C3.3078 21.7917 3 21.4839 3 21.1042Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="fw-bold fs-4">Teams</div>
                    <span className='p-1  mx-3 '>Add Member</span>
                </div>

            </div>


            <div className='symbol-group symbol-hover flex-nowrap mb-10 px-3'>

                {items.map((item, index) => (
                    <div
                        className='symbol symbol-35px symbol-circle'
                        data-bs-toggle='tooltip'
                        title={item.name}
                        key={`cw7-item-${index}`}
                    >
                        {item.src && <img alt='Pic' src={item.src} />}
                        {item.state && item.initials && (
                            <span
                                className={clsx(
                                    'symbol-label fw-bold',
                                    'bg-' + item.state,
                                    'text-inverse-' + item.state
                                )}
                            >
                                {item.initials}
                            </span>
                        )}
                    </div>
                ))}

                <a href='#' className='symbol symbol-35px symbol-circle'>
                    <span
                        className={clsx('symbol-label fs-8 fw-bold', 'bg-' + labelColor, 'text-' + textColor)}
                    >
                        +12
                    </span>
                </a>
            </div>
        </div>
    </div>
)
export { CardsWidgetOverview }
