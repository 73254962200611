export const unitsList = [
  'box',
  'Nos',
  'cm',
  'dz',
  'ft',
  'g',
  'in',
  'kg',
  'km',
  'lb',
  'mg',
  'ml',
  'm',
  'pcs',
]
