import {createSlice} from '@reduxjs/toolkit'

const taskSlice = createSlice({
  name: 'task',
  initialState: {
    taskData: [],
    loading: true,
    error: null,
  },
  reducers: {
    setTaskData(state, action) {
      state.taskData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setTaskData, setLoading, setError} = taskSlice.actions
export default taskSlice.reducer
