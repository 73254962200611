import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {useAuth} from '../../../modules/auth'
import {getProject, getProjectByAgentId} from '../core/_requests'
import Swal from 'sweetalert2'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setAllProjects} from '../../../redux/projects/projectSlice'

const ProjectCalendar = () => {
  const calendarComponentRef = useRef(null)
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const projectList = useSelector((state: any) => state.projects.projects.allProjects)
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState<any>({})
  const [calendarEvents, setCalendarEvents] = useState([])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProjectData = async () => {
    try {
      const response = await getProject(currentUser?.organization)
      console.log('res', response)
      const data = response?.map((item) => ({
        title: item?.projectName,
        start: item?.releaseDate,
        description: item?.projectDescription,
      }))
      setCalendarEvents(data)
      dispatch(setAllProjects(response))
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  const getProjectDataForAgent = async () => {
    try {
      const response = await getProjectByAgentId(currentUser?._id)
      const data = response?.map((item) => ({
        title: item?.projectName,
        start: item?.releaseDate,
        description: item?.projectDescription,
      }))
      setCalendarEvents(data)
      dispatch(setAllProjects(response))
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (permissions?.myproject?.admin === false) {
      if (projectList.length === 0) {
        getProjectDataForAgent()
      } else {
        const data = projectList?.map((item) => ({
          title: item?.projectName,
          start: item?.releaseDate,
          description: item?.projectDescription,
        }))
        setCalendarEvents(data)
        setLoading(false)
      }
    } else if (
      permissions?.myproject?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      if (projectList.length === 0) {
        getProjectData()
      } else {
        const data = projectList?.map((item) => ({
          title: item?.projectName,
          start: item?.releaseDate,
          description: item?.projectDescription,
        }))
        setCalendarEvents(data)
        setLoading(false)
      }
    }
  }, [permissions])

  const handleDateSelect = (selectInfo) => {
    const {start, end} = selectInfo
    // Do something with the selected date range
  }

  const handleShowModal = (e) => {
    Swal.fire({
      title: e.event.title.toUpperCase(),
      text: e.event.extendedProps.description,
      icon: 'info',
      confirmButtonText: 'Close',
    })
  }

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>Project Calendar</h3>
        </div>
      </div>
      <div className=''>
        <Card>
          <Card.Body>
            <div className='demo-app-calendar' id='mycalendartest'>
              <FullCalendar
                eventTimeFormat={{
                  hour: 'numeric',
                  minute: '2-digit',
                  meridiem: 'short',
                }}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  multiMonthPlugin,
                ]}
                initialView={'dayGridMonth'}
                headerToolbar={{
                  start: 'today prev,next',
                  center: 'title',
                  end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                }}
                selectable={true}
                select={handleDateSelect}
                rerenderDelay={10}
                eventDurationEditable={false}
                weekends={true}
                ref={calendarComponentRef}
                events={calendarEvents}
                eventClick={handleShowModal}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default ProjectCalendar
