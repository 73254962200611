import React, {useEffect, useState} from 'react'
import {getProject, getProjectByAgentId} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setAllProjects} from '../../../redux/projects/projectSlice'

const ProjectDocument = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const projectList = useSelector((state: any) => state.projects.projects.allProjects)
  const [permissions, setPermissions] = useState<any>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getProject(currentUser?.organization)
    console.log(res)
    dispatch(setAllProjects(res))
    setLoading(false)
  }

  const fetchDataForAgent = async () => {
    const res = await getProjectByAgentId(currentUser?._id)
    dispatch(setAllProjects(res))
    setLoading(false)
  }

  useEffect(() => {
    if (permissions?.documents?.admin === false) {
      if (projectList.length === 0) {
        fetchDataForAgent()
      } else {
        setLoading(false)
      }
    } else if (
      permissions?.documents?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      if (projectList.length === 0) {
        fetchData()
      } else {
        setLoading(false)
      }
    }
  }, [permissions])

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    // <div className='container'>
    <div className='card'>
      <div className='card-header d-flex align-items-center'>
        <h3>Project Documents</h3>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 rounded-start'>Project Name</th>
                <th>Start Date</th>
                <th>Release Date</th>
                <th>Budget</th>
                <th>Total Docs</th>
              </tr>
            </thead>
            <tbody>
              {projectList?.map((project: any) => (
                <tr key={project._id}>
                  <td>
                    <Link
                      to={`/project/documents/${project._id}`}
                      className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                    >
                      {project.projectName}
                    </Link>
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {new Date(project.startDate).toLocaleDateString('en-GB')}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {new Date(project.releaseDate).toLocaleDateString('en-GB')}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {project.budgetAmount.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}{' '}
                    {project?.currency}
                  </td>
                  <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                    {project?.files?.length || 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default ProjectDocument
