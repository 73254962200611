import React from 'react'

export default function Card2({ handleInputChange, data, setData }) {
    return (
        <div className='card mb-5 mb-xl-10 px-9 '>
            <div className='container-xxl'>
                <div className='card-header border-0 cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Budget Details</h3>
                    </div>
                </div>

                <div className='row mb-6 '>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Budget Amount</label>

                    <div className='col-lg-8 fv-row'>
                        <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Budget amount'
                            name='budgetAmount'
                            value={data.budgetAmount}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className='row mb-8'>
                    <div className='col-lg-4 col-form-label fw-bold fs-6'>Budget Notes</div>
                    <div className='col-xl-8 fv-row'>
                        <textarea
                            name='budgetNotes'
                            value={data?.budgetNotes}
                            onChange={handleInputChange}
                            className='form-control form-control-solid'
                            rows={5}
                        ></textarea>
                    </div>
                </div>

                {/* <div className='row mb-0 '>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        Allow Changes in Budget
                    </label>

                    <div className='col-lg-8 d-flex align-items-center'>
                        <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                                className='form-check-input w-45px h-30px'
                                type='checkbox'
                                id='allowChangesInBudget'
                                checked={data.changeInBudget}
                                onChange={() => setData({ ...data, changeInBudget: !data.changeInBudget })}
                            />
                            <label className='form-check-label'></label>
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    )
}
