/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {getProjectSummary} from '../../../../app/pages/projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setProjectSummary} from '../../../../app/redux/projects/projectDashboardSlice'
import {RootState} from '../../../../app/redux/store'

type Props = {
  className: string
}

type Project = {
  projectName: string
  status: 'InProgress' | 'Pending' | 'Completed' | 'Overdue'
  managers: string[]
}

const TablesWidget15: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const projectSummary = useSelector((state: any) => state.projects.projectDashboard.projectSummary)
  const projectSummaryLoading = useSelector(
    (state: any) => state.projects.projectDashboard.projectSummaryLoading
  )
  const [projects, setProjects] = useState<Project[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProjectSummary()
        setProjects(data)
        dispatch(setProjectSummary(data))
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    }

    if (projectSummaryLoading && projectSummary.length === 0) fetchData()
    else {
      setProjects(projectSummary)
    }
  }, [])
  const getStatusColor = (status: Project['status']) => {
    switch (status) {
      case 'Completed':
        return 'success'
      case 'InProgress':
        return 'primary'
      case 'Overdue':
        return 'danger'
      case 'Pending':
        return 'warning'
      default:
        return 'dark'
    }
  }

  if (projectSummaryLoading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className={`card ${className}`} style={{maxHeight: '500px', overflowY: 'auto'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h4 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Project Summary</span>
        </h4>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle text-left justify-left gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 min-w-70px text-start'>Project</th>
                    <th className='p-0 min-w-110px text-start'>Project Manager</th>
                    <th className='p-0 min-w-10px text-center'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                {/* <tbody className='border-0 text-left justify-left'>
                  <tr >
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6 '>
                        Brad Simmons
                      </a> 
                    </td>
                    <td className='text-start text-muted fw-semibold'>React, HTML</td>
                    <td className='text-center'>
                      <span className='badge badge-light-success'>Approved</span>
                    </td>
                   
                  </tr>
                  <tr >
                    
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      
                    </td>
                    <td className='text-start text-muted fw-semibold'>Python, MySQL</td>
                    <td className='text-center'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                  
                  </tr>
                  <tr>
                   
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        New Users
                      </a>
                      
                    </td>
                    <td className='text-start text-muted fw-semibold'>Laravel,Metronic</td>
                    <td className='text-center'>
                      <span className='badge badge-light-primary'>Success</span>
                    </td>
                   
                  </tr>
                  <tr>
                   
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      
                    </td>
                    <td className='text-start text-muted fw-semibold'>AngularJS, C#</td>
                    <td className='text-center'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                  
                  </tr>
                  <tr>
                   
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Bestseller Theme
                      </a>
                     
                    </td>
                    <td className='text-start text-muted fw-semibold'>ReactJS, Ruby</td>
                    <td className='text-center'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                   
                  </tr>
                </tbody> */}
                <tbody className='border-0 text-left justify-left'>
                  {projects?.map((project, index) => (
                    <tr key={index}>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {project?.projectName}
                        </a>
                      </td>
                      <td className='text-start text-muted fw-semibold'>
                        {project?.managers?.join(', ')}
                      </td>
                      <td className='text-center'>
                        <span className={`badge badge-light-${getStatusColor(project?.status)}`}>
                          {project?.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Brad Simmons
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>React, HTML</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>Approveed</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    {/* <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td> */}
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-semibold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr> */}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Bestseller Theme
                      </a>
                      <span className='text-muted fw-semibold d-block'>Best Customers</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>ReactJS, Ruby</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-semibold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        New Users
                      </a>
                      <span className='text-muted fw-semibold d-block'>Awesome Users</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Laravel,Metronic</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>Success</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-semibold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTIcon iconName='arrow-right' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget15}
