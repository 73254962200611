/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {getStocks} from '../Core/_requests'
import {getCategory} from '../Core/_categoryRequest'
import {useAuth} from '../../../modules/auth'

type Props = {
  /*  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string */
}

const Stat1: React.FC<Props> = (
  {
    /* className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor, */
  }
) => {
  const {currentUser} = useAuth()
  const [toatalProduct, setToatalProduct] = useState<number>(0)
  const [totalCategories, setTotalCategories] = useState<number>(0)
  const [totalQuantity, setTotalQuantity] = useState<number>(0)

  useEffect(() => {
    const productData = async () => {
      try {
        const response = await getStocks(currentUser?.organization)
        setToatalProduct(response.length)
        const total = response.reduce(
          (sum, product) => sum + (Number(product?.inWarehouseQuantity) || 0),
          // product.onShelfQuantity
          0
        )
        setTotalQuantity(total)

        const categoriesResponse = await getCategory(currentUser?.organization)
        setTotalCategories(categoriesResponse.length)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    if(currentUser.organization !== undefined){
      productData()
    }
  }, [])

  const data = [
    {
      id: 1,
      className: 'card-xl-stretch mb-xl-5',
      svgIcon: 'chart-simple',
      color: 'white',
      iconColor: 'primary',
      title: `${totalQuantity}`,
      description: 'Stock Status',
      range: '+8',
      rangeColor: 'primary',
    },
    {
      id: 2,
      className: 'card-xl-stretch mb-xl-5',
      svgIcon: 'book',
      color: 'white',
      iconColor: 'success',
      title: '27',
      description: 'Low Stocks Alerts',
      range: '+5',
      rangeColor: 'primary',
    },
    {
      id: 3,
      className: 'card-xl-stretch mb-xl-5',
      svgIcon: 'finance-calculator',
      color: 'white',
      iconColor: 'warning',
      title: `${toatalProduct}`,
      description: 'Products',
      range: '+1.2 ',
      rangeColor: 'primary',
    },
    {
      id: 4,
      className: 'card-xl-stretch mb-xl-5',
      svgIcon: 'tag',
      color: 'white',
      iconColor: 'info',
      title: `${totalCategories}`,
      description: 'Categories',
      titleColor: 'black',
      descriptionColor: 'black',
      range: '0.5 ',
      rangeColor: 'primary',
    },
  ]

  return (
    <div className='card card-xl-stretch h-100'>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>Inventory Overview</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Sales Summary</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      <div className='row g-5 g-xl-8 px-4 mb-3'>
        {data.map((item, index) => (
          <div key={index} className='col-xl-6'>
            <a href='#' className={`card bg-${item.color} hoverable ${item.className}`}>
              <div className='card-body'>
                <div className='flex flex-row justify-evenly align-items-center'>
                  <KTIcon
                    iconName={item.svgIcon}
                    className={`text-${item.iconColor} fs-4x ms-n1`}
                  />
                  <div className='flex flex-col align-items-center'>
                    <div className={`text-${item?.titleColor} fw-bold fs-2 my-3`}>
                      {item.title}
                    </div>

                    <div className={`fw-semibold text-${item?.descriptionColor}`}>
                      {item.description}
                    </div>
                    {/* <p className={`text-small text-${item?.rangeColor}`}>{item.range} from Yesterday</p> */}
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Stat1}
