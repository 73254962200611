import {TablesWidget17} from '../../../../_metronic/partials/widgets'
import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {useAuth} from '../../../modules/auth'
import {
  getEmployeeAttendanceByDate,
  getEmployeeWebAttendanceByDate,
  getEmployeesAttendance,
  getEmployeesWebAttendance,
  getEmployeesIdOfAttendanceByOrgId,
  getEmployeesIdOfWebAttendanceByOrgId,
} from '../core/_requests'
import {KTSVG} from '../../../../_metronic/helpers'

export const AttendanceWrapper = () => {
  {
    /* <TablesWidget17 className={''} newMember={'/attendance/applyLeave'} /> */
  }
  const showModal = useRef(null)
  const calendarComponentRef = useRef(null)
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [calendarEvents, setCalendarEvents] = useState([])
  const [currDate, setCurrDate] = useState(new Date())
  const [toggleMenu, setToggleMenu] = useState(false)
  const [presentEmployees, setPresentEmployees] = useState([])
  const [absentEmployees, setAbsentEmployees] = useState([])
  const [employeeList, setEmployeeList] = useState([])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getCalendarData = async () => {
    try {
      if (currentUser?.attendanceMethod === 'mobile') {
        const response = await getEmployeesAttendance(currentUser?.organization)
        console.log('res', response)
        const data = response?.result?.map((item) => ({
          title: `${item?.count}/${response?.totalEmployees}`,
          start: item?.date,
        }))
        setCalendarEvents(data)
      } else if (currentUser?.attendanceMethod === 'web') {
        const response = await getEmployeesWebAttendance(currentUser?.organization)
        console.log('res', response)
        const res = await getEmployeesIdOfWebAttendanceByOrgId(currentUser?.organization)
        console.log('res', res)
        setEmployeeList(res)

        const data1 = []
        // loop to the date uptil 90 days ago and get the count of employees present on that day
        for (let i = 0; i < 90; i++) {
          const date = new Date()
          date.setDate(date.getDate() - i)
          const dateStr = date.toDateString()
          const matchingKey = Object.keys(response).find(
            (key) => new Date(key).toDateString() === dateStr
          )

          const count = matchingKey !== undefined ? response[matchingKey] : 0

          data1.push({
            title: count,
            start: date,
          })
        }
        setCalendarEvents(data1)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchEmployees = async () => {
    const res = await getEmployeesIdOfAttendanceByOrgId(currentUser?.organization)
    console.log('res', res)
    const data = res?.map((item) => ({
      _id: item?.employeeId?._id,
      firstName: item?.employeeId?.firstName,
      lastName: item?.employeeId?.lastName,
    }))
    setEmployeeList(data)
  }

  useEffect(() => {
    getCalendarData()
    if (currentUser?.attendanceMethod === 'mobile') fetchEmployees()
  }, [currentUser?.attendanceMethod])

  const handleDateSelect = (selectInfo) => {
    const {start, end} = selectInfo
    // Do something with the selected date range
  }

  // const handleShowModal = (e) => {
  //   Swal.fire({
  //     title: `Total present employees: ${e.event.title}`,
  //     icon: 'info',
  //     confirmButtonText: 'Close',
  //   })
  // }

  const fetchAttendanceByDate = async () => {
    const data = {
      date: currDate,
      orgid: currentUser?.organization,
    }
    if (currentUser?.attendanceMethod === 'mobile') {
      const res = await getEmployeeAttendanceByDate(data)
      console.log('attendance', res)
      setPresentEmployees(res?.present || [])
      setAbsentEmployees(employeeList.filter((item) => !res?.presentIds?.includes(item._id)))
    } else if (currentUser?.attendanceMethod === 'web') {
      const res = await getEmployeeWebAttendanceByDate(data)
      console.log('attendance', res)
      setPresentEmployees(res?.present || [])
      setAbsentEmployees(employeeList.filter((item) => !res?.presentIds?.includes(item._id)))
    }
  }

  useEffect(() => {
    fetchAttendanceByDate()
  }, [currDate, currentUser?.attendanceMethod])

  const handleShowModal = (e) => {
    setCurrDate(new Date(e.event.start))
    showModal.current.click()
  }

  const renderEventContent = (eventInfo) => {
    return <div className='fw-semibold mx-auto ps-4 fs-3'>{eventInfo.event.title}</div>
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>Employee Attendance</h3>
        </div>
        <button
          type='button'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1234'
          ref={showModal}
          className='d-none'
        ></button>
      </div>
      <div className=''>
        <Card>
          <Card.Body>
            <div className='demo-app-calendar' id='mycalendartest'>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  multiMonthPlugin,
                ]}
                initialView={'dayGridMonth'}
                headerToolbar={{
                  start: 'today prev,next',
                  center: 'title',
                  end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                }}
                selectable={true}
                select={handleDateSelect}
                rerenderDelay={10}
                eventDurationEditable={false}
                weekends={true}
                ref={calendarComponentRef}
                events={calendarEvents}
                eventClick={handleShowModal}
                eventContent={renderEventContent}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1234'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${!toggleMenu ? ' active' : ''}`}
                      onClick={() => setToggleMenu(false)}
                    >
                      Present
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${toggleMenu ? ' active' : ''}`}
                      onClick={() => setToggleMenu(true)}
                    >
                      Absent
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            {toggleMenu ? (
              <>
                <div className='modal-body'>
                  <div className='d-flex flex-column'>
                    {absentEmployees.map((item, index) => (
                      <div key={index} className='d-flex'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex'>
                            <div className='d-flex'>
                              <div className='d-flex flex-column'>
                                <div className='fw-bolder fs-6'>
                                  {item?.firstName} {item?.lastName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='modal-body'>
                  <div className='d-flex flex-column'>
                    {presentEmployees.map((item, index) => (
                      <div key={index} className='d-flex'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex'>
                            <div className='d-flex'>
                              <div className='d-flex flex-column'>
                                <div className='fw-bolder fs-6'>
                                  {item?.firstName} {item?.lastName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton2'
                onClick={() => setToggleMenu(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
