import React, {useEffect, useState, useRef} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {
  getFilteredSalesOrder,
  getFilteredSalesOrderByAgentId,
  updateSalesOrderValidationStatus,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import {Filtertype2, RootState, ValidFilter} from '../Core/_model'
import qs from 'qs'
import Filter2 from '../Components/Filter2'
import {useDispatch, useSelector} from 'react-redux'
import {setSalesOrderData} from '../../../redux/sales/salesOrder/salesOrderSlice'
import debounce from 'lodash/debounce'
import Pagination from '../../purchaseOrders/Components/Pagination'

const SalesOrders = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [dropdown, setDropdown] = useState([])
  const [filteredSalesOrderList, setFilteredSalesOrderList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const salesOrderList = useSelector((state: RootState) => state.sales.salesOrder.salesOrderData)
  const initialRef = useRef(true)
  const [isLoading, setIsLoading] = useState(false)

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getSalesOrderList = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredSalesOrder(
        queryString,
        currentUser?.organization,
        currentPage,
        25
      )
      setFilteredSalesOrderList(res?.salesOrders)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.salesOrders.length).fill(false))
      return res?.salesOrders
    } catch (error) {
      console.error('Error fetching Sales Order list:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getSalesOrderAgent = async () => {
    setIsLoading(true)
    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getFilteredSalesOrderByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25
      )
      setFilteredSalesOrderList(res?.salesOrders)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.salesOrders.length).fill(false))
      return res?.salesOrders
    } catch (error) {
      console.error('Error fetching Sales Order list:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getSalesOrdersData = () => {
    if (permissions?.salesorder?.admin === false) {
      getSalesOrderAgent()
    } else if (
      permissions?.salesorder?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getSalesOrderList()
    }
  }

  const getSalesOrders = () => {
    if (salesOrderList.length === 0) {
      if (permissions?.salesorder?.admin === false) {
        getSalesOrderAgent().then((res) => dispatch(setSalesOrderData(res)))
      } else if (
        permissions?.salesorder?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getSalesOrderList().then((res) => dispatch(setSalesOrderData(res)))
      }
    } else {
      setFilteredSalesOrderList(salesOrderList)
      setDropdown(Array(salesOrderList.length).fill(false))
    }
  }

  const debounceSearch = debounce(getSalesOrdersData, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage])

  useEffect(() => {
    getSalesOrders()
  }, [permissions])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const handleActivation = async (id, valid) => {
    const res = await updateSalesOrderValidationStatus(id, {valid: !valid})
    if (res) {
      getSalesOrders()
    }
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const setStatusFilter = (status: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
        status: '',
      },
    })
    getSalesOrders()
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval,
      },
    })
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Sales Order</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Sales Order ID, Reference)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getSalesOrdersData}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'pending', label: 'Pending'},
                {value: 'completed', label: 'Completed'},
              ]}
              setApprovalFilter={setApprovalFilter}
              setStatusFilter={setStatusFilter}
            />
          </div>
        </div>

        {(permissions?.salesorder?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'/sales/salesorders/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Create Sales Order
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 '>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive '>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered '>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>Sales Order#</th>
                  <th className='min-w-150px'>Reference#</th>
                  <th className='min-w-150px'>Customer Name</th>
                  <th className='min-w-150px'>Order Status</th>
                  <th className='min-w-150px'>Invoiced</th>
                  <th className='min-w-150px'>Payment</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Delivery Method</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.salesorder?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {Array.isArray(filteredSalesOrderList) &&
                  filteredSalesOrderList.map((item: any, index: number) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <td>{new Date(item?.salesOrderDate).toLocaleDateString('en-GB')}</td>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.reference}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.customer?.displayName}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.status}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/invoice/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.invoiceStatus?.id}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/paymentReceived/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item?.paymentStatus?.id}
                        </Link>
                      </td>

                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span className={`badge  fs-6 fw-semibold `}>
                            {item?.total.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}{' '}
                            {item?.customer?.currency}
                          </span>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item._id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span className={`badge  fs-6 fw-semibold `}>
                            {' '}
                            {item?.deliveryMethod}
                          </span>
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/sales/salesorders/${item.id}`}
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          <span
                            className={`badge  fs-7 fw-semibold ${
                              item?.approval === 'pending'
                                ? 'badge-light-primary'
                                : item?.approval === 'accepted'
                                ? 'badge-light-success'
                                : 'badge-light-danger'
                            }`}
                          >
                            {item?.approval}
                          </span>
                        </Link>
                      </td>
                      {(permissions?.salesorder?.update ||
                        currentUser?.profileType === 'superadmin') && (
                        <td className='relative '>
                          <td className='text-end'>
                            <div
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </div>

                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {item?.status === 'pending' && (
                                <div className='menu-item px-3'>
                                  <Link to={`edit/${item._id}`}>
                                    <div className='menu-link px-3'>Edit</div>
                                  </Link>
                                </div>
                              )}

                              <div className='menu-item px-3'>
                                {!item?.valid ? (
                                  <button
                                    className='menu-link px-3'
                                    onClick={() => handleActivation(item._id, item.valid)}
                                  >
                                    Valid
                                  </button>
                                ) : (
                                  <button
                                    className='menu-link px-3'
                                    onClick={() => handleActivation(item._id, item.valid)}
                                  >
                                    Invalid
                                  </button>
                                )}
                              </div>
                            </div>
                          </td>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}
export default SalesOrders
