import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getInspectionRecordById } from '../../core/_requests'
import { ChartsWidget12, ListsWidget13, TablesWidget17 } from '../../../../../_metronic/partials/widgets'
import { KTSVG } from '../../../../../_metronic/helpers'

const InspectionDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<any>()

  const fetchData = async () => {
    const res = await getInspectionRecordById(id)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    if (id) fetchData()
  }, [id])

  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }
  const initialValue = data?.odometer || '100'
  const [value, setValue] = useState<any>(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    // Here you can add functionality to save the updated value
  };
  const imageUrl = "https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI0MEVsSUlobVF4bWI0RTQwYjEzcyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:12ddd31da44053da29e1542eab4876a94ea651a6b2ca0cd02fb099162f6fad85/cache=expiry:max/rotate=exif:true/40ElIIhmQxmb4E40b13s"
  const imageUrl2 = "https://cdn.filestackcontent.com/0zyik8SqQcXb420QgYjQ?signature=61d0e294068a50e3b412d96f7dd36e925e1244efa8ec39bf14ca2515fbb68c1e&policy=eyJjYWxsIjpbInJlYWQiXSwiZXhwaXJ5IjoxNzEyMjQ3ODM3LCJoYW5kbGUiOiIwenlpazhTcVFjWGI0MjBRZ1lqUSJ9"

  return (
    <>
      {/* <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Inspection Details</h4>
        </div>
        <div className='card-body'>
          <h4 className='mb-2'>Inspection Item: {data?.product?.productName}</h4>
          <h3 className='mb-2'>Date: {formatDate(data?.createdAt)}</h3>
          <h3 className='mb-2'>Inspection Form: {data?.form?.title}</h3>
          <h3 className='mb-2'>Submitted By: {data?.createdBy?.fullName}</h3>

          {data?.product?.rentalType === 'vehicle' && (
            <div className='mb-1'>
              <p className='d-inline fs-5'>Odometer Reading: </p>
              <p className='text-primary d-inline fs-5'>{data?.odometer}</p>
            </div>
          )}

          {data?.answers?.map((answer: any, index: number) => (
            <div key={index} className='mb-1'>
              <p className='d-inline fs-5'>{answer?.question}: </p>
              {answer?.questionType === 'checkbox' ? (
                <p className='text-primary d-inline fs-5'>{answer?.answer?.slice(1).join(', ')}</p>
              ) : (
                <>
                  {answer?.questionType === 'signature' ? (
                    <p className='fw-bold fs-2' style={{ fontFamily: 'cursive' }}>
                      {answer?.answer}
                    </p>
                  ) : (
                    <p className='text-primary d-inline fs-5'>{answer?.answer}</p>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div> */}

      <div className='row g-5 gx-xxl-8'>
        <div className="flex-col col-xl-5 mb-5">
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 ">
              <div className="card bg-white border-2">
                <div className="card-header px-6 py-5">Inspection Details</div>
                <div className="card-content p-6" style={{ height: "auto" }}>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4">
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Vehicle
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                          <div className="flex w-full justify-between items-center">
                            <div className="flex items-center first:ml-0 flex-1 min-w-0">
                              <div className="w-full break-words">
                                <div style={{ maxWidth: "max-content" }}>
                                  <div className="flex w-full items-center">
                                    <div className="ml-4 flex items-center first:ml-0 flex-none">
                                      <div className="relative">
                                        <img
                                          alt="Pickup Truck"
                                          className="object-cover outline-none rounded"
                                          src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI5MnhQaktrV1Q2aDUxTkZmQXF6eiIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:98512fe85077eaf682cd7fae5577dab894121be7d74f22830326c6a78b5894f2/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/92xPjKkWT6h51NFfAqzz"
                                          style={{ height: 26, width: 26 }}
                                        />
                                        <div
                                          className="absolute rounded-full text-white shadow-solid"
                                          style={{
                                            bottom: "-2px",
                                            height: 9,
                                            right: "-3px",
                                            width: 9
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(24, 204, 108)",
                                              left: "-5px",
                                              position: "absolute",
                                              top: "-5px"
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={18}
                                              height={18}
                                              fill="currentColor"
                                              viewBox="0 0 24 24"
                                              name="dot-fill"
                                              className=""
                                            >
                                              <path
                                                fill=""
                                                d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ml-4 flex items-center first:ml-0">
                                      <div className="flex flex-col w-full">
                                        <div className="w-full first:mt-0 -mt-3">
                                          <a
                                            className="text-blue-700 spark-a-focus hover:underline"
                                            role="link"
                                            href="/552aeb9291/vehicles/3274956"
                                            style={{ textDecoration: "underline dotted" }}
                                          >
                                            {data?.product?.productName}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ml-4 flex items-center first:ml-0">
                                      <div
                                        className="font-medium rounded-full  inline-block text-sm px-4 leading-4 border border-solid  bg-gray-50 text-gray-600 border-gray-50"
                                        data-testid="tag"
                                      >
                                        <div className="flex w-full items-center">
                                          <div className="ml-3 flex items-center first:ml-0">
                                            Sample
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center first:ml-0">
                              <div
                                aria-label="Click to edit"
                                className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                data-testid="inline-edit-button"
                                role="button"
                                tabIndex={0}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={18}
                                  height={18}
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                  name="edit"
                                  className=""
                                >
                                  <path
                                    fill=""
                                    fillRule="evenodd"
                                    d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4" style={{}}>
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Inspection Form
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <div className="flex w-full items-center">
                          <div className="ml-3 flex items-center first:ml-0">
                            <a
                              className="text-blue-700 spark-a-focus hover:underline"
                              role="link"
                              href="/552aeb9291/inspection_forms/330832?v=1"
                            >
                              <div className="flex w-full items-center">
                                <div className="ml-1 flex items-center first:ml-0">
                                  {data?.form?.title}
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4">
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Started
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <abbr
                          className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                          style={{ textDecoration: "underline dotted" }}
                        >
                          {formatDate(data?.createdAt)}
                        </abbr>
                      </div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4">
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Submitted
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <abbr
                          className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                          style={{ textDecoration: "underline dotted" }}
                        >
                          Tue, Apr 02, 2024 12:19am
                        </abbr>
                      </div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4">
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Duration
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">5m 28s</div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4" style={{}}>
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Submission Source
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <p className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0">
                          Fleetio Web App
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                    data-testid="detail-field"
                    role="group"
                  >
                    <div className="w-full sm:w-1/2 md:w-1/3">
                      <div className="flex items-center w-full relative">
                        <div className="mr-4" style={{}}>
                          <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            Submitted By
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                      <div className="break-words whitespace-pre-wrap w-full">
                        <div style={{ maxWidth: "max-content" }}>
                          <div className="flex w-full items-center">
                            <div className="ml-4 flex items-center first:ml-0 flex-none">
                              <div>
                                <svg
                                  aria-hidden="true"
                                  aria-label="AS"
                                  height={26}
                                  viewBox="0 0 26 26"
                                  width={26}
                                  style={{ color: "rgb(138, 129, 213)", display: "block" }}
                                >
                                  <circle
                                    cx={13}
                                    cy={13}
                                    r={13}
                                    style={{ fill: "currentcolor" }}
                                  />
                                  <text
                                    alignmentBaseline="central"
                                    className="text-white"
                                    dominantBaseline="central"
                                    fontSize="10px"
                                    textAnchor="middle"
                                    x={13}
                                    y={13}
                                    style={{
                                      cursor: "inherit",
                                      fill: "currentcolor",
                                      userSelect: "none"
                                    }}
                                  >
                                    {data?.createdBy?.fullName[0]}
                                  </text>
                                </svg>
                              </div>
                            </div>
                            <div className="ml-4 flex items-center first:ml-0">
                              <a
                                className="text-blue-700 spark-a-focus hover:underline"
                                role="link"
                                href="/552aeb9291/contacts/1866579"
                                style={{ textDecoration: "underline dotted" }}
                              >
                                {data?.createdBy?.fullName}
                              </a>
                            </div>
                            <div className="ml-4 flex items-center first:ml-0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 mt-5">
              <div className="card shodow mb-5 mb-xl-8 border-2 bg-white p-1">
                <img src="/media/misc/inspectionMap.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xxl-7 mb-5 mb-xl-8">
          <div className="" style={{ justifySelf: "stretch" }}>
            <div className="card bg-white border-2">
              <div className="card-header px-6 py-5">Inspection Items</div>
              <div
                className="card-content card-content-flush p-0"
                style={{ height: "auto" }}
              >
                <div
                  className="allow-wrap content-align-top spark-table-wrapper"
                  data-testid="simple-table"
                >
                  <table className="table table-bordered" role="table">
                    <tbody>
                      <tr className="">
                        <td data-size="fit" />
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Odometer Reading
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <div className="flex flex-col w-full">
                                <div className="w-full first:mt-0 mt-5">
                                  <div className="flex flex-col w-full">
                                    <div className="w-full first:mt-0 mt-3">
                                      <div style={{ minWidth: 150 }}>
                                        <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                                          <div className="flex w-full justify-between items-center">
                                            <div className="flex items-center first:ml-0 flex-1 min-w-0">
                                              <div className="w-full break-words">
                                                <div className="flex w-full justify-start items-center">
                                                  <div className="ml-3 flex items-center first:ml-0">
                                                    <div>
                                                      {isEditing ? (
                                                        <input
                                                          type="text"
                                                          value={value}
                                                          onChange={handleInputChange}
                                                          onBlur={handleSave}
                                                          autoFocus
                                                          className="break-words max-w-full text-md text-yellow-600 text-left font-normal line-through cursor-text font-sans m-0"
                                                          style={{
                                                            textDecoration: "underline dotted"
                                                          }}
                                                        />
                                                      ) : (
                                                        <span className='' onClick={handleEdit}>
                                                          {value}
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="ml-3 flex items-center first:ml-0">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={16}
                                                      height={16}
                                                      fill="currentColor"
                                                      viewBox="0 0 24 24"
                                                      color="#F2AA2A"
                                                      name="void"
                                                      className="#F2AA2A custom-color"
                                                    >
                                                      <path
                                                        fill=""
                                                        d="M3.289 20.711a.986.986 0 0 1 0-1.393l16.029-16.03a.986.986 0 1 1 1.393 1.394L4.682 20.712a.986.986 0 0 1-1.393 0Z"
                                                      />
                                                      <path
                                                        fill=""
                                                        fillRule="evenodd"
                                                        d="M20.711 4.682a.986.986 0 1 0-1.393-1.393L3.287 19.318a.986.986 0 1 0 1.394 1.393l16.03-16.029ZM12 6a6 6 0 0 0-5.793 7.569 1 1 0 1 1-1.931.52 8 8 0 0 1 9.814-9.814 1 1 0 0 1-.521 1.932A6.01 6.01 0 0 0 12 6Zm6.498 3.205a1 1 0 0 1 1.226.705 8 8 0 0 1-9.814 9.814 1 1 0 1 1 .521-1.93 6 6 0 0 0 7.362-7.362 1 1 0 0 1 .705-1.227Z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className="flex items-center first:ml-0">
                                              <div
                                                aria-label="Click to edit"
                                                className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                                data-testid="inline-edit-button"
                                                role="button"
                                                tabIndex={0}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="edit"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    fillRule="evenodd"
                                                    d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </div>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full first:mt-0 mt-3">
                                      <button data-bs-toggle="modal"
                                        data-bs-target="#imageModal">
                                        <img
                                          alt="Meter Image"
                                          src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI0MEVsSUlobVF4bWI0RTQwYjEzcyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:12ddd31da44053da29e1542eab4876a94ea651a6b2ca0cd02fb099162f6fad85/cache=expiry:max/rotate=exif:true/40ElIIhmQxmb4E40b13s"
                                          height={150}
                                          width={150}
                                        />
                                      </button>
                                    </div>
                                    <div className="modal " tabIndex={-1} id="imageModal">
                                      <div className="modal-dialog modal-dialog-centered ">
                                        <div className="modal-content shadow-none">
                                          <div className="modal-header">
                                            <h5 className="modal-title">Image Preview - Meter Image</h5>
                                            <div
                                              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <KTSVG
                                                path="media/icons/duotune/arrows/arr061.svg"
                                                className="svg-icon svg-icon-2x"
                                              />
                                            </div>
                                          </div>
                                          <div className="modal-body">
                                            <img
                                              alt="Meter Image"
                                              src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI0MEVsSUlobVF4bWI0RTQwYjEzcyIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:12ddd31da44053da29e1542eab4876a94ea651a6b2ca0cd02fb099162f6fad85/cache=expiry:max/rotate=exif:true/40ElIIhmQxmb4E40b13s"
                                              className="w-full h-full"
                                            />
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-light"
                                              data-bs-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                            <button type="button" onClick={() => window.open(imageUrl, '_blank')} className="btn btn-primary">
                                              Open in New Tab
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div className="w-full first:mt-0 mt-3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td colSpan={4}>
                          <p className="break-words max-w-full text-lg text-gray-900 text-left font-semibold normal-case font-sans m-0">
                            Item Checklist
                          </p>
                        </td>
                      </tr>
                      <tr className="cursor-pointer">
                        <td data-size="fit">
                          <div className="-mt-2">
                            <button
                              className="leading-normal inline-flex items-center font-normal spark-button-focus h-7 text-sm px-4 bg-transparent border-0 border-solid text-blue-700 hover:text-blue-800 active:text-blue-700 rounded-md"
                              type="button"
                              data-testid="expand-toggle"
                              aria-disabled="false"
                              tabIndex={0}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                color="text-gray-600"
                                name="caret-right"
                                className="text-gray-600 custom-color"
                              >
                                <path
                                  fill=""
                                  d="M8.5 17.015c0 .855 1.122 1.303 1.817.725l5.695-4.733c.65-.542.65-1.472 0-2.014L10.317 6.26c-.695-.578-1.817-.13-1.817.725v10.03z"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Interior Cleanliness
                                  </p>
                                </div>
                                <div className="ml-4 flex items-start first:ml-0">
                                  <div className="flex w-full items-center">
                                    <div className="ml-3 flex items-center first:ml-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        color="text-gray-600"
                                        name="image"
                                        className="text-gray-600 custom-color"
                                      >
                                        <path
                                          fill=""
                                          fillRule="evenodd"
                                          d="M3 7a4 4 0 014-4h10a4 4 0 014 4V17a4 4 0 01-4 4H7a4 4 0 01-4-4V7zm16 0v6.586L16.414 11a2 2 0 00-2.828 0L10 14.586 9.414 14a2 2 0 00-2.828 0L5 15.586V7a2 2 0 012-2h10a2 2 0 012 2zM7 19c-.702 0-1.32-.362-1.677-.91L8 15.415 11.586 19H7zm10 0h-2.586l-3-3L15 12.414l4 4V17a2 2 0 01-2 2zM9 6a3 3 0 100 6 3 3 0 000-6zM8 9a1 1 0 112 0 1 1 0 01-2 0z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0">
                                Take a photo of the interior
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <button data-bs-toggle="modal"
                                data-bs-target="#imageModal2">
                                <img
                                  alt="inspection item"
                                  className=" max-w-half "
                                  height={150}
                                  width={150}
                                  src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiIwenlpazhTcVFjWGI0MjBRZ1lqUSIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:c0fc000bc17959a2ecefa06f0439f806ce6e41d94b2f1efd792cbaebe3142b14/resize=w:500,h:500/cache=expiry:max/rotate=exif:true/0zyik8SqQcXb420QgYjQ"

                                />
                              </button>
                              <div className="modal " tabIndex={-1} id="imageModal2">
                                <div className="modal-dialog modal-dialog-centered ">
                                  <div className="modal-content shadow-none">
                                    <div className="modal-header">
                                      <h5 className="modal-title">Image Preview - Photo</h5>
                                      <div
                                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <KTSVG
                                          path="media/icons/duotune/arrows/arr061.svg"
                                          className="svg-icon svg-icon-2x"
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        alt="Image"
                                        src='https://cdn.filestackcontent.com/0zyik8SqQcXb420QgYjQ?signature=61d0e294068a50e3b412d96f7dd36e925e1244efa8ec39bf14ca2515fbb68c1e&policy=eyJjYWxsIjpbInJlYWQiXSwiZXhwaXJ5IjoxNzEyMjQ3ODM3LCJoYW5kbGUiOiIwenlpazhTcVFjWGI0MjBRZ1lqUSJ9'
                                        className="w-full h-auto"
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button type="button" onClick={() => window.open(imageUrl2, '_blank')} className="btn btn-primary">
                                        Open in New Tab
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>
                      {data?.answers?.map((answer: any, index: number) =>
                        <tr key={index} className="">
                          <td data-size="fit" />
                          <td>
                            <div className="flex flex-col w-full">
                              <div className="w-full first:mt-0 mt-1">
                                <div className="flex w-full items-start">
                                  <div className="ml-4 flex items-start first:ml-0">
                                    <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                      {answer?.question}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full first:mt-0 mt-1">
                                <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0" />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="flex w-full items-start">
                              <div className="ml-4 flex items-start first:ml-0">
                                <div className="flex w-full">

                                  {answer?.answer[0] === "Pass" ? (<div className="ml-2 first:ml-0">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height={18}
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                      color="text-green-600"
                                      name="check"
                                      className="text-green-600 custom-color"
                                    >
                                      <path
                                        fill=""
                                        fillRule="evenodd"
                                        d="M20.707 6.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 16.586 19.293 6.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>) : (<div className="ml-2 first:ml-0" />)}
                                  <div className="ml-2 first:ml-0">
                                    <p className="break-words max-w-full text-md text-green-600 text-left font-normal normal-case font-sans m-0">
                                      {answer?.questionType === 'checkbox' ? (
                                        <p className='text-primary d-inline fs-5'>{answer?.answer?.slice(1).join(', ')}</p>
                                      ) : (
                                        <>
                                          {answer?.questionType === 'signature' ? (
                                            <p className='fw-bold fs-2' style={{ fontFamily: 'cursive' }}>
                                              {answer?.answer}
                                            </p>
                                          ) : (
                                            <p className='text-primary d-inline fs-5'>{answer?.answer}</p>
                                          )}
                                        </>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td />
                        </tr>
                      )}
                      <tr className="cursor-pointer">
                        <td data-size="fit">
                          <div className="-mt-2">
                            <button
                              className="leading-normal inline-flex items-center font-normal spark-button-focus h-7 text-sm px-4 bg-transparent border-0 border-solid text-blue-700 hover:text-blue-800 active:text-blue-700 rounded-md"
                              type="button"
                              data-testid="expand-toggle"
                              aria-disabled="false"
                              tabIndex={0}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                color="text-gray-600"
                                name="caret-right"
                                className="text-gray-600 custom-color"
                              >
                                <path
                                  fill=""
                                  d="M8.5 17.015c0 .855 1.122 1.303 1.817.725l5.695-4.733c.65-.542.65-1.472 0-2.014L10.317 6.26c-.695-.578-1.817-.13-1.817.725v10.03z"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Transmission
                                  </p>
                                </div>
                                <div className="ml-4 flex items-start first:ml-0">
                                  <div className="flex w-full items-center">
                                    <div className="ml-3 flex items-center first:ml-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        color="text-gray-600"
                                        name="comment"
                                        className="text-gray-600 custom-color"
                                      >
                                        <path
                                          fill=""
                                          fillRule="evenodd"
                                          d="M2 7a4 4 0 014-4h12a4 4 0 014 4v8a4 4 0 01-4 4h-3.697l-5.748 3.832A1 1 0 017 22v-3H6a4 4 0 01-4-4V7zm4-2a2 2 0 00-2 2v8a2 2 0 002 2h2a1 1 0 011 1v2.131l4.193-2.795a2 2 0 011.11-.336H18a2 2 0 002-2V7a2 2 0 00-2-2H6z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <div className="flex w-full">
                                <div className="ml-2 first:ml-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    color="text-red-600"
                                    name="close"
                                    className="text-red-600 custom-color"
                                  >
                                    <path
                                      fill=""
                                      fillRule="evenodd"
                                      d="M5.293 5.293a1 1 0 011.414 0l12 12a1 1 0 01-1.414 1.414l-12-12a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                    <path
                                      fill=""
                                      fillRule="evenodd"
                                      d="M18.707 5.293a1 1 0 010 1.414l-12 12a1 1 0 01-1.414-1.414l12-12a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <div className="ml-2 first:ml-0">
                                  <p className="break-words max-w-full text-md text-red-600 text-left font-normal normal-case font-sans m-0">
                                    Fail
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full justify-end">
                            <div className=" first:ml-0">
                              <a
                                className="text-blue-700 spark-a-focus hover:underline"
                                role="link"
                                href="/552aeb9291/issues/8"
                                style={{ textDecoration: "underline dotted" }}
                              >
                                Issue #8
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>



                      <tr className="cursor-pointer">
                        <td data-size="fit">
                          <div className="-mt-2">
                            <button
                              className="leading-normal inline-flex items-center font-normal spark-button-focus h-7 text-sm px-4 bg-transparent border-0 border-solid text-blue-700 hover:text-blue-800 active:text-blue-700 rounded-md"
                              type="button"
                              data-testid="expand-toggle"
                              aria-disabled="false"
                              tabIndex={0}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={18}
                                height={18}
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                color="text-gray-600"
                                name="caret-right"
                                className="text-gray-600 custom-color"
                              >
                                <path
                                  fill=""
                                  d="M8.5 17.015c0 .855 1.122 1.303 1.817.725l5.695-4.733c.65-.542.65-1.472 0-2.014L10.317 6.26c-.695-.578-1.817-.13-1.817.725v10.03z"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Lighting Devices and Reflectors
                                  </p>
                                </div>
                                <div className="ml-4 flex items-start first:ml-0">
                                  <div className="flex w-full items-center">
                                    <div className="ml-3 flex items-center first:ml-0">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                        color="text-gray-600"
                                        name="comment"
                                        className="text-gray-600 custom-color"
                                      >
                                        <path
                                          fill=""
                                          fillRule="evenodd"
                                          d="M2 7a4 4 0 014-4h12a4 4 0 014 4v8a4 4 0 01-4 4h-3.697l-5.748 3.832A1 1 0 017 22v-3H6a4 4 0 01-4-4V7zm4-2a2 2 0 00-2 2v8a2 2 0 002 2h2a1 1 0 011 1v2.131l4.193-2.795a2 2 0 011.11-.336H18a2 2 0 002-2V7a2 2 0 00-2-2H6z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <div className="flex w-full">
                                <div className="ml-2 first:ml-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    color="text-green-600"
                                    name="check"
                                    className="text-green-600 custom-color"
                                  >
                                    <path
                                      fill=""
                                      fillRule="evenodd"
                                      d="M20.707 6.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 16.586 19.293 6.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <div className="ml-2 first:ml-0">
                                  <p className="break-words max-w-full text-md text-green-600 text-left font-normal normal-case font-sans m-0">
                                    Pass
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>

                      <tr>
                        <td colSpan={4}>
                          <p className="break-words max-w-full text-lg text-gray-900 text-left font-semibold normal-case font-sans m-0">
                            Sign-Off
                          </p>
                        </td>
                      </tr>
                      <tr className="">
                        <td data-size="fit" />
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Vehicle Condition OK
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0">
                                This must be checked if there are no defects.
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <div className="flex w-full">
                                <div className="ml-2 first:ml-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                    color="text-green-600"
                                    name="check"
                                    className="text-green-600 custom-color"
                                  >
                                    <path
                                      fill=""
                                      fillRule="evenodd"
                                      d="M20.707 6.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 16.586 19.293 6.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <div className="ml-2 first:ml-0">
                                  <p className="break-words max-w-full text-md text-green-600 text-left font-normal normal-case font-sans m-0">
                                    Excellent
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>
                      <tr className="">
                        <td data-size="fit" />
                        <td>
                          <div className="flex flex-col w-full">
                            <div className="w-full first:mt-0 mt-1">
                              <div className="flex w-full items-start">
                                <div className="ml-4 flex items-start first:ml-0">
                                  <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                    Reviewing Driver's Signature
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="w-full first:mt-0 mt-1">
                              <p className="break-words max-w-full text-xs text-gray-600 text-left font-light normal-case font-sans m-0" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="flex w-full items-start">
                            <div className="ml-4 flex items-start first:ml-0">
                              <p className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0">
                                Tgggg
                              </p>
                            </div>
                          </div>
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default InspectionDetail
