import React from 'react'

export default function General({
  productName,
  setProductName,
  productDescription,
  setProductDescription,
  isProduct,
}) {
  return (
    <div class='card card-flush py-4'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>General</h2>
        </div>
      </div>

      <div class='card-body pt-0'>
        <div class='mb-10 fv-row'>
          <label class='required form-label'>{isProduct ? 'Product Name' : 'Category Name'}</label>

          <input
            type='text'
            name='product_name'
            class='form-control mb-2'
            placeholder='Product name'
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />

          <div class='text-muted fs-7'>
            A product name is required and recommended to be unique.
          </div>
        </div>

        <div>
          <label class='form-label'>Description</label>
          <div className='w-full rounded-lg h-full'>
            <textarea
              name='w3review'
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              placeholder='type your text here..'
              rows='4'
              cols='50'
              className='w-full rounded border px-4 py-2'
            ></textarea>
          </div>

          <div class='text-muted fs-7'>Set a description to the product for better visibility.</div>
        </div>
      </div>
    </div>
    // <div className='text-start space-y-2 bg-white p-7 mt-3 rounded'>
    //   <form>
    //     <h1 className='text-start fw-semibold mb-3'>General</h1>
    //     <label className='text-start'>{isProduct ? 'Product Name' : 'Category Name'}</label>
    //     <div>
    //       <input
    //         placeholder='Product name'
    //         className='rounded border px-4 py-2 w-full'
    //         value={productName}
    //         onChange={(e) => setProductName(e.target.value)}
    //       />
    //     </div>
    //     <p className='text-gray-400'>A product name is required and recommended to be unique.</p>

    //     <div className='space-y-3 mt-3'>
    //       <label className='text-start'>Description</label>
    //       <div className='w-full rounded-lg h-full'>
    //         <textarea
    //           name='w3review'
    //           value={productDescription}
    //           onChange={(e) => setProductDescription(e.target.value)}
    //           placeholder='type your text here..'
    //           rows='4'
    //           cols='50'
    //           className='w-full rounded border px-4 py-2'
    //         ></textarea>
    //       </div>
    //     </div>
    //     <p className='card-text w-full mt-4 text-gray-500 text-start'>
    //       Set a description for the product for better visibility.
    //     </p>
    //   </form>
    // </div>
  )
}
