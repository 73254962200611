import {createSlice} from '@reduxjs/toolkit'

const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    employeeData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setEmployeeData(state, action) {
      state.employeeData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setEmployeeData, setLoading, setError} = employeeSlice.actions
export default employeeSlice.reducer
