import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getQuotebyids} from '../../Core/_requests'

function QuoteSecondArabic({companyLogo}) {
  const [vat, setVat] = useState(0)
  const id = useParams().id
  const [data, setdata] = useState([])
  
  useEffect(() => {
    const getquotedetails = async () => {
      await getQuotebyids(id).then((res) => {
        console.log(res)
        setdata(res)
        console.log('quoteData', res)
      })
    }
    
    getquotedetails()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data[0]?.tax) {
      const tax = (data[0]?.subtotal * data[0]?.tax) / 100
      setVat(tax)
    }
  }, [data])

  return (
    <>
      <div
        className='app-main flex-column flex-row-fluid '
        id='kt_app_main'
        style={{display: 'flex', flexDirection: 'row-reverse', textAlign: 'right'}}
      >
        {/*begin::Content wrapper*/}
        <div className='d-flex flex-column flex-column-fluid'>
          {/*begin::Toolbar*/}
          <div
            id='kt_app_toolbar'
            className='app-toolbar mb-6 '
            // data-kt-sticky="false"
            // data-kt-sticky-name="app-toolbar"
            // data-kt-sticky-offset="{default: 'false', lg: '300px'}"
          ></div>
          {/*end::Toolbar*/}
          {/*begin::Content*/}
          <div id='pdf-content' className='app-content print-content flex-column-fluid '>
            {/*begin::Content container*/}
            <div id='kt_app_content_container' className='app-container  container-xxl '>
              {/* begin::Invoice 3*/}
              <div className='card'>
                {/* begin::Body*/}
                <div className='card-body py-8'>
                  {/* begin::Wrapper*/}
                  <div className='mw-lg-1050px w-100'>
                    {/* begin::Header*/}
                    <div className='d-flex justify-content-between flex-column align-items-center flex-sm-row mb-9'>
                      <h4 className='fw-bolder text-gray-800 fs-2qx pe-5 pb-4'>
                        {/* QUOTE */}
                        يقتبس
                      </h4>
                      {/*end::Logo*/}
                      <div className='text-sm-end'>
                        {/*begin::Logo*/}
                        <a href='#' className='d-block mw-150px ms-sm-auto'>
                          {companyLogo ? (
                            <img
                              alt='Logo'
                              src={URL.createObjectURL(companyLogo)}
                              className='w-100'
                            />
                          ) : (
                            <img
                              alt='Upload Logo'
                              src={'/media/logo.png'}
                              className='w-100 bg-dark p-2 rounded-2'
                            />
                          )}
                        </a>
                        {/*end::Logo*/}
                        {/*begin::Text*/}
                        <div className='text-sm-end fw-semibold fs-4 text-muted mt-7'>
                          <div>Company Address</div>
                          <div>Mississippi 96522</div>
                        </div>
                        {/*end::Text*/}
                      </div>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className='pb-2'>
                      {/*begin::Wrapper*/}
                      <div className='d-flex flex-column gap-7 gap-md-10'>
                        {/*begin::Message*/}
                        <div className='fw-bold fs-2'>
                          {/* <span className="text-muted">QUOTE To: </span> */}
                          <span className='text-muted'>اقتباس ل </span>
                          {data[0]?.customer.primaryContact.firstName}{' '}
                          {data[0]?.customer.primaryContact.lastName}{' '}
                          {/* <span className="fs-6">({data[0]?.customer.emailAddress})</span>,<br /> */}
                          <br />
                          <span className='text muted fs-5'>
                            هنا تفاصيل طلبك. نشكركم على الشراء.
                          </span>
                          {/*Here are your order details. We thank you for your purchase.*/}
                        </div>
                        {/*begin::Message*/}
                        {/*begin::Separator*/}
                        <div className='separator' />
                        {/*begin::Separator*/}
                        {/*begin::Order details*/}
                        <div className=' d-flex justify-content-between gap-7 gap-sm-0  flex-column flex-sm-row mb-5  fw-bold'>
                          <div className='d-flex flex-md-root flex-column mb-1'>
                            {/* <span className="text-muted">Order ID</span> */}
                            <span className='text-muted'>رقم التعريف الخاص بالطلب</span>
                            <span className='fs-6'>#{data[0]?.orderNo}</span>
                          </div>
                          <div className='flex-md-root d-flex flex-column mb-1'>
                            <div className='text-muted'>تاريخ الإصدار</div>
                            {/*Issue Date:*/}
                            {/* <span className="text-muted">Date</span> */}
                            <span className='fs-6'>{formatCreatedAt(data[0]?.createdAt)}</span>
                          </div>
                          <div className='flex-md-root d-flex flex-column mb-1'>
                            {/* <span className="text-muted">Quote ID</span> */}
                            <span className='text-muted'>معرف الاقتباس</span>
                            <span className='fs-6'>#{data[0]?.id}</span>
                          </div>
                          {/* <div className="flex-root d-flex flex-column">
                                                        <span className="text-muted">Shipment ID</span>
                                                        <span className="fs-5">#SHP-0025410</span>
                                                    </div> */}
                        </div>
                        {/*end::Order details*/}
                        {/*begin::Billing & shipping*/}
                        <div className='d-flex flex-column flex-sm-row mb-10 gap-7 gap-sm-20 justify-content-between xl:w-3/5'>
                          <div className='d-flex flex-md-root flex-column fw-bold'>
                            {/* <span className="text-muted mb-2">Billing Address</span> */}
                            <div className='fw-bold fs-5 text-dark mb-1'>عنوان وصول الفواتير:</div>
                            {/*Billing Address:*/}

                            <span className='fs-6'>
                              {data[0]?.customer?.billingAddress.addressLine1}{' '}
                              {data[0]?.customer?.billingAddress.addressLine2}
                              <br />
                              {data[0]?.customer?.billingAddress.city}{' '}
                              {data[0]?.customer?.billingAddress.postalCode}
                              <br />
                              {data[0]?.customer?.billingAddress.country}
                            </span>
                          </div>
                          <div className='d-flex flex-md-root flex-column fw-bold '>
                            <div className=''>
                              {/* <span className="text-muted mb-2">Shipping Address</span> */}
                              <div className='fw-bold fs-5 text-dark '>عنوان الشحن:</div>
                              {/*Shipping Address:*/}

                              <span className='fs-6'>
                                {data[0]?.customer?.shippingAddress.addressLine1}{' '}
                                {data[0]?.customer?.shippingAddress.addressLine2}
                                <br />
                                {data[0]?.customer?.shippingAddress.city}{' '}
                                {data[0]?.customer?.shippingAddress.postalCode}
                                <br />
                                {data[0]?.customer?.shippingAddress.country}
                              </span>
                            </div>
                          </div>
                        </div>

                        {/*end::Billing & shipping*/}
                        {/*begin:Order summary*/}
                        <div className='flex-grow-1'>
                          {/*begin::Table*/}
                          <div className='table-responsive border border-dashed mb-9'>
                            <table className='table mb-3'>
                              <thead>
                                <tr className='border fs-6 fw-bold text-muted'>
                                  <th className='min-w-175px text-start pb-2 '>وصف</th>{' '}
                                  {/*Description*/}
                                  <th className='min-w-70px text-center pb-2'>كمية</th>
                                  {/*Quantity*/}
                                  <th className='min-w-80px text-center pb-2'>معدل</th>
                                  {/*Rate*/}
                                  <th className='min-w-80px text-center pb-2  '>تخفيض</th>
                                  {/*Discount*/}
                                  <th className='min-w-80px text-center pb-2 '>كمية</th>
                                  {/*Amount*/}
                                </tr>
                              </thead>
                              <tbody>
                                {data[0]?.items.map((item, index) => (
                                  <tr
                                    key={index}
                                    className='fw-bold text-gray-700 fs-5 text-center border-bottom border-dashed'
                                  >
                                    <td className='d-flex align-items-center text-start pt-6'>
                                      <i className='fa fa-genderless text-danger fs-2 me-2' />
                                      {item?.productName}
                                      {/* {console.log("item",item.itemId.productName)} */}
                                    </td>
                                    <td className='pt-6'>{item.quantity}</td>
                                    {/* {console.log("quantity",item.quantity)} */}
                                    <td className='pt-6'>
                                      {Number(item?.price).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data[0]?.customer?.currency} */}
                                    </td>
                                    {/* {console.log("price",item.price)} */}
                                    <td className='pt-6'>{item.discount}</td>
                                    {/* {console.log("discount",item.discount)} */}
                                    <td className='pt-6 text-gray-900 fw-bolder'>
                                      {Number(item?.amount).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data[0]?.customer?.currency} */}
                                    </td>
                                    {/* {console.log("amount",item.amount)} */}
                                  </tr>
                                ))}
                                <tr className='border'>
                                  <td className='border-0'></td>
                                  <td className='border-0'></td>
                                  <td className='border-0'>
                                    {' '}
                                    {/* Subtotal: */}
                                    المجموع الفرعي:
                                  </td>
                                  <td>{data[0]?.customer?.currency}</td>
                                  <td className='text-center'>
                                    {data[0]?.subtotal?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>

                                <tr className='border'>
                                  <td className='border-0'></td>
                                  <td className='border-0'></td>
                                  <td className='border-0'>
                                    {/* Tax */}
                                    {data[0]?.tax} ضريبة
                                  </td>
                                  <td>{data[0]?.customer?.currency}</td>
                                  <td className='text-center'>{vat}</td>
                                </tr>

                                <tr className='border'>
                                  <td className='border-0'></td>
                                  <td className='border-0'></td>
                                  <td className='border-0'>
                                    {' '}
                                    {/* Subtotal + Tax */}
                                    المجموع الفرعي + الضريبة
                                  </td>
                                  <td>{data[0]?.customer?.currency}</td>
                                  <td className='text-center'>
                                    {data[0]?.total?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>
                                <tr className='border'>
                                  <td className='border-0'></td>
                                  <td className='border-0'></td>
                                  <td className='border-0'>
                                    {/* Total */}
                                    المجموع
                                  </td>
                                  <td>{data[0]?.customer?.currency}</td>
                                  <td className='text-center'>
                                    {data[0]?.total?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}{' '}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className='d-flex flex-sm-row flex-column border-bottom justify-content-between'>
                            <div className='mb-10 '>
                              <div className='  pt-4'>
                                <div className='mb-5 fw-bolder text-gray-600 text-hover-primary'>
                                  {/* Note: {" "} */}
                                  ملحوظة :
                                  <span className='fw-semibold text-gray-600 fs-6'>
                                    {data[0]?.notes}
                                  </span>
                                </div>
                                <div className='mb-5 fw-bolder text-gray-600 text-hover-primary'>
                                  {/* Terms and Conditions: */}
                                  الأحكام والشروط:
                                  <br />
                                  <div className='fw-semibold text-gray-600 fs-6 w-1/2'>
                                    {data[0]?.termsNCondition}
                                  </div>
                                </div>

                                {data[0]?.paymentReceived && (
                                  <div className=''>
                                    <h6 className='mb-5 fw-bolder text-gray-600 text-hover-primary'>
                                      {/* Payment Info */}
                                      بيانات الدفع
                                    </h6>
                                    {/* end::Title */}
                                    {/* begin::Item */}
                                    <div className='mb-3'>
                                      <p className='fw-semibold text-gray-600 fs-7'>
                                        Payment Mode:
                                        <p className='fw-bold text-gray-600 fs-6'>
                                          codelabpay@codelab.co
                                        </p>
                                      </p>
                                    </div>
                                    {/* end::Item */}
                                    {/* begin::Item */}
                                    <div className='mb-3 '>
                                      <p className='fw-semibold text-gray-600 fs-7'>
                                        Deposit To:
                                        <p className='fw-bold text-gray-600 fs-6'>Nl24IBA</p>
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className='mw-300px flex-root flex-column me-9 '>
                              {/* <div className='d-flex flex-row justify-content-between mb-3'> */}
                              {/*begin::Accountname*/}
                              {/* <div className='fw-semibold pe-10 text-gray-600 fs-6'> */}
                              {/* Subtotal: */}
                              {/* المجموع الفرعي: */}
                              {/* </div>
                                <div className=' fw-bold fs-5 text-gray-800'>
                                  {data[0]?.subtotal?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                  {data[0]?.customer?.currency}
                                </div>
                              </div> */}
                              <div className='d-flex flex-stack mb-3'>
                                {/*begin::Accountname*/}
                                <div className='fw-semibold pe-10 text-gray-600 fs-6'>
                                  {/* Tax */}
                                  {/* {data[0]?.tax} ضريبة */}
                                </div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                {/* <div className=' fw-bold fs-5 text-gray-800'>{vat}</div> */}
                                {/*end::Label*/}
                              </div>
                              <div className='d-flex flex-stack mb-3'>
                                {/*begin::Accountnumber*/}
                                <div className='fw-semibold pe-10 text-gray-600 fs-6'>
                                  {/* Subtotal + Tax */}
                                  {/* المجموع الفرعي + الضريبة */}
                                </div>
                                {/*end::Accountnumber*/}
                                {/*begin::Number*/}
                                {/* <div className='fw-bold fs-5 text-gray-800'>
                                  {data[0]?.total?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                  {data[0]?.customer?.currency}
                                </div> */}
                                {/*end::Number*/}
                              </div>
                              {/* <div className='d-flex flex-stack '>
                                <div className='fw-semibold pe-10 text-gray-600 fs-5 '>
                                  {/* Total */}
                              {/* المجموع */}
                              {/* </div> */}
                              {/* <div className='text-center  fw-bold fs-4 text-gray-800'>
                                  {data[0]?.customer?.currency}
                                  {data[0]?.total?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Body*/}
                    {/* begin::Footer*/}
                    {/* <div className="d-flex py-2 flex-column flex-sm-row justify-content-between  align-items-center">

                                            <div className="border py-5 pt-20 px-2 text-center fw-bold fs-5 underline  items-bottom">
                                                Authorized Signature
                                            </div>

                                            <div className=" hide-on-pdf d-flex flex-stack flex-wrap mt-5 ">

                                                <a
                                                    href="/sales/quotes/add"
                                                    className="hide-on-pdf btn btn-primary my-1"
                                                >
                                                    Create Quote
                                                </a>
                                                {/* end::Action*/}
                    {/* </div>
                                        </div> */}
                    {/* end::Footer*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Body*/}
                <div className='card-footer mt-20'>
                  <div className='d-flex flex-row justify-content-between fs-4 fw-semibold  px-5'>
                    <div className='border-t py-2 border-dark'>
                      {/* <span>Client Signature</span> */}
                      <span>توقيع العميل</span> {/*Client Signature*/}
                    </div>
                    <div className='border-t py-2 border-dark'>
                      {/* <span>Authorized Signature</span> */}
                      <span>توقيع معتمد</span> {/*Authorized Signature*/}
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Invoice 1*/}{' '}
            </div>
            {/*end::Content container*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Content wrapper*/}
        {/*begin::Footer*/}
        {/*end::Footer*/}{' '}
      </div>
    </>
  )
}

export default QuoteSecondArabic
