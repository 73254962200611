import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Department from './Pages/Department'
import ViewDepartment from './Pages/ViewDepartment'
import AddDepartment from './Pages/AddDepartment'
import EditDepartment from './Pages/EditDepartment'
export default function DepartmentRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Department />} />
      <Route path='/add' element={<AddDepartment />} />
      <Route path='/:id' element={<ViewDepartment />} />
      <Route path='/edit/:id' element={<EditDepartment />} />
      

    </Routes>
  )
}
