/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'

type Props = {
  className: string
  items?: number
  vendors?: Array<any>
}

const List4: React.FC<Props> = ({className, items = 6, vendors}) => {
  return (
    <div className='card card-xl-stretch h-100'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Supplier Management</span>
        </h3>
        {/* begin::Menu */}
        {/* <div className='card-toolbar'>
         
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
         
        </div> */}
        {/* end::Menu */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {vendors ? (
        <div
          className='px-6 py-9 position-relative z-index-1'
          // style={{marginTop: '-100px'}}
        >
          {vendors?.map((customer, index) => (
            <div key={customer.customerId || index} className='d-flex align-items-center mb-6'>
              {/* begin::Symbol */}
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='compass' className='fs-1' />
                </span>
              </div>
              {/* end::Symbol */}
              {/* begin::Description */}
              <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='#' className='fs-7 text-gray-800 text-hover-primary fw-bold'>
                    {customer.vendorName}
                  </a>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-7 text-gray-800 pe-1'>
                    {customer?.totalBusiness?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}{' '}
                    {customer.currency}
                  </div>
                  <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />
                </div>
                {/* end::Label */}
              </div>
              {/* end::Description */}
            </div>
          ))}
        </div>
      ) : (
        <div className='card-body pt-5'>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <KTIcon iconName='briefcase' className={` fs-3x ms-n1`} />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Top Authors
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>Mark, Rowling, Esther</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>+82$</span>
            </div>
            {/* end::Section */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <KTIcon iconName='chart-simple' className={` fs-3x ms-n1`} />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Popular Authors
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>Randy, Steve, Mike</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>+280$</span>
            </div>
            {/* end::Section */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <KTIcon iconName='cheque' className={` fs-3x ms-n1`} />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  New Users
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>John, Pat, Jimmy</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>+4500$</span>
            </div>
            {/* end::Section */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <KTIcon iconName='chart-pie-simple' className={` fs-3x ms-n1`} />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Active Customers
                </a>
                <span className='text-muted fw-semibold d-block fs-7'>Mark, Rowling, Esther</span>
              </div>
              <span className='badge badge-light fw-bold my-2'>+4500$</span>
            </div>
            {/* end::Section */}
          </div>
          {/* end::Item */}
        </div>
      )}
      {/* end::Body */}
    </div>
  )
}

export {List4}
