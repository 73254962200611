import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

const MEETINGS_URL = `${API_URL}/task/eventbyagentid/`
const ONLY_MEETINGS_URL = `${API_URL}/task/meetingsbyagentid/`
const APPROVE_ACC_URL = `${API_URL}/task/meeting/approve/`
const REJECT_ACC_URL = `${API_URL}/task/meeting/reject/`
const STATUS_URL = `${API_URL}/task/meeting/status/`

export async function getmeetings(agentid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${MEETINGS_URL}${agentid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('search agent error:', error)
    throw error
  }
}

export async function getOnlyMeetings(agentid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ONLY_MEETINGS_URL}${agentid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('search agent error:', error)
    throw error
  }
}

export async function setStatusApprove(id: string) {
  try {
    const response = await axios.put(`${APPROVE_ACC_URL}${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setStatusReject(id: string) {
  try {
    const response = await axios.put(`${REJECT_ACC_URL}${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}

export async function setStatus(id: string, status: string) {
  try {
    const response = await axios.put(`${STATUS_URL}${id}`, {status})
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Account error:', error)
    throw error
  }
}
