import React from 'react'

export default function ProductTemplate({template, setTemplate}) {
  return (
    // <div class="card border-0 p-4 rounded-md">
    //   <div class="card-body">
    //     <h5 class="card-title text-start fw-semibold fs-5 ">
    //       Product Template
    //     </h5>
    //     <h2 className="mt-3 mb-1 text-start fw-semibold">
    //       Select a product template
    //     </h2>

    //     <select
    //       class="form-select border mt-2 mb-2"
    //       aria-label="Default select example"
    //       value={template}
    //       onChange={(e) => {
    //         setTemplate(e.target.value);
    //       }}
    //     >
    //       <option selected value="Default Template">
    //         Default Template
    //       </option>
    //       <option value="Electronics">Electronics</option>
    //       <option value="Office Stationary">Office Stationary</option>
    //       <option value="Fashion">Fashion</option>
    //       <option value="Electronics">Electronics</option>
    //     </select>
    //     <p class="card-text  w-full mt-4 text-gray-500 text-start">
    //       Assign a template from your current theme to define how a single
    //       product is displayed.
    //     </p>
    //   </div>
    // </div>

    <div class='card card-flush py-4'>
      <div class='card-header'>
        <div class='card-title'>
          <h2>Product Template</h2>
        </div>
      </div>

      <div class='card-body pt-0'>
        <label for='kt_ecommerce_add_product_store_template' class='form-label'>
          Select a product template
        </label>

        <select
          class='form-select mb-2'
          data-control='select2'
          data-hide-search='true'
          data-placeholder='Select an option'
          id='kt_ecommerce_add_product_store_template'
          value={template}
          onChange={(e) => {
            setTemplate(e.target.value)
          }}
        >
          <option></option>
          <option value='default' selected='selected'>
            Default template
          </option>
          <option value='electronics'>Electronics</option>
          <option value='office'>Office stationary</option>
          <option value='fashion'>Fashion</option>
        </select>

        <div class='text-muted fs-7'>
          Assign a template from your current theme to define how a single product is displayed.
        </div>
      </div>
    </div>
  )
}
