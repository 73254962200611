/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {useAuth} from '../../../../app/modules/auth'
import {getProjectBudgets} from '../../../../app/pages/projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../app/redux/store'
import {setProjectBudget} from '../../../../app/redux/projects/projectDashboardSlice'

type Props = {
  className: string
}

const ChartsWidget10: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const projectBudget = useSelector((state: any) => state.projects.projectDashboard.projectBudget)
  const projectBudgetLoading = useSelector(
    (state: any) => state.projects.projectDashboard.projectBudgetLoading
  )
  const {currentUser} = useAuth()
  const orgid = currentUser.organization
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [totalBudget, setTotalBudget] = useState<number>(0)
  const [totalCost, setTotalCost] = useState<number>(0)
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, totalBudget, totalCost))
    if (chart) {
      chart.render()
    }

    return chart
  }
  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, totalBudget, totalCost])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {totalBudget, totalCost} = await getProjectBudgets(orgid)
        setTotalBudget(totalBudget)
        setTotalCost(totalCost)
        console.log('Budget data', {totalBudget, totalCost})

        dispatch(setProjectBudget({totalBudget, totalCost}))
      } catch (error) {
        console.error('Error fetching project  data:', error)
      }
    }

    if (projectBudgetLoading) fetchData()
    else {
      setTotalBudget(projectBudget.totalBudget)
      setTotalCost(projectBudget.totalCost)
    }
  }, [orgid])

  if (projectBudgetLoading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 justify-content-start'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 mb-1'>Budget Tracker</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav gap-8 '>
            <li className='nav-item  badge bg-gray-200 rounded px-2 py-1'>{/* New Project */}</li>
            <li className='nav-item  badge bg-gray-200 rounded px-2 py-1'>{/* Edit List */}</li>
          </ul>
        </div>

        {/* begin::Toolbar */}

        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        <div className='d-flex gap-5'>
          <div className='card-body bg-gray-200 rounded'>
            <span className='fs-9 fw-bold'>Total Budget:</span>
            <div>
              <span className='fs-3 fw-bold'>
                {totalBudget?.toLocaleString('en-US', {style: 'currency', currency: 'AED'})}
              </span>
              {/* <span className='fs-9 text-muted mx-1'>AED</span> */}
            </div>
          </div>
          <div className='card-body bg-gray-200 rounded'>
            <span className='fs-9 fw-bold'>Total Cost:</span>
            <div>
              <span className='fs-3 fw-bold'>
                {totalCost?.toLocaleString('en-US', {style: 'currency', currency: 'AED'})}
              </span>
              {/* <span className='fs-9 text-muted mx-1'>AED</span> */}
            </div>
          </div>
        </div>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget10}

function getChartOptions(height: number, totalBudget: number, totalCost: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-primary-light')
  const total = totalBudget + totalCost
  const budgetPercentage = ((totalBudget / total) * 100).toFixed(2)
  const costPercentage = ((totalCost / total) * 100).toFixed(2)
  return {
    // series: [38.6,30.8,22.5,8.1],
    series: [parseFloat(budgetPercentage), parseFloat(costPercentage)],
    chart: {
      fontFamily: 'inherit',
      type: 'donut',
      height: height,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%', // Adjust the size of the donut hole
          labels: {
            show: true,

            name: {
              show: true,
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: 'black',
              offsetY: -10,
              formatter: function (val) {
                return `${val}%`
              },
            },
          },
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      colors: ['white'],
      width: 6,
    },
    labels: ['Total Budget', 'Total Cost'], // Label for the donut chart
    colors: ['#0d47a1', '#64b5f6'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 10,

      padding: {
        left: 5,
        right: 5,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val}%`
        },
      },
    },
  }
}
