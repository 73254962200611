import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getSalesPerformance} from '../core/requests'
import SalesSummaryCard from './salesSummaryCard'
import SalesStatBar from './SalesStatBar'
import DateFilter from '../../account/components/DateFilter'

const SalesPerformance: React.FC = () => {
  const {currentUser} = useAuth()
  const orgid = currentUser.organization
  const [salesPerformanceData, setSalesPerformanceData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dateState, setDateState] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    endDate: new Date().toISOString(),
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSalesPerformance(orgid, dateState.startDate, dateState.endDate)

        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)

        setSalesPerformanceData(data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching sales performance data:', error)
      }
    }

    fetchData()
  }, [orgid, dateState.endDate])

  // Slice the first 5 customers from the salesPerformanceData array
  const top5Customers = salesPerformanceData
    .filter((sales) => sales.isActivated === true)
    .slice(0, 5)

  return (
    <div>
      <div className='flex-1'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 '>
          <div className='md:col-span-1 pb-4'>
            {/* Left card */}
            <div className='card h-fit'>
              {/* Card content */}
              {/* <!--begin::Chart widget 5--> */}
              <div className='card card-flush h-md-100'>
                <div id='kt_charts_widget_5' className='min-h-auto'>
                  <SalesStatBar customers={top5Customers} />
                </div>
              </div>
              {/* <!--end::Chart widget 5--> */}
            </div>
          </div>
          <div className='md:col-span-1 pb-4'>
            <div className='card card-flush h-md-100'>
              <div id='kt_charts_widget_5' className='min-h-auto'>
                <SalesSummaryCard customers={top5Customers} />{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card p-4'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-between align-items-center'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Sales Performance</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
          </h3>
          <DateFilter setDateState={setDateState} dateState={dateState} />
        </div>

        <div className='card-body py-3'>
          {loading ? (
            <div className='d-flex align-items-center justify-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Customers</th>
                    <th className='min-w-140px'>No. of Quotations</th>
                    <th className='min-w-140px'>No. of Sales Orders</th>
                    <th className='min-w-140px'>No. of Delivery Notes</th>
                    <th className='min-w-140px'>No. of Invoices</th>
                    <th className='min-w-140px'>No. of Payment Receives</th>
                    <th className='min-w-140px'>Total Business</th>
                    <th className='min-w-140px'>Performance Percentage</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {salesPerformanceData
                    .filter((sales) => sales.isActivated === true)
                    .map((customer, index) => (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'></div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {customer.customerName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td className='text-start'>{customer.quotationCount}</td>
                        <td className='text-start'>{customer.salesOrderCount}</td>
                        <td className='text-start'>{customer.deliveryNoteCount}</td>
                        <td className='text-start'>{customer.invoiceCount}</td>
                        <td className='text-start'>{customer.paymentCount}</td>
                        <td className='text-start'>
                          {' '}
                          {customer?.totalBusiness?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                          {customer.currency}
                        </td>
                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-muted me-2 fs-7 fw-semibold'>
                                {customer.performancePercentage.toFixed(2)}%
                              </span>
                            </div>
                            <div className='progress h-6px w-100'>
                              <div
                                className='progress-bar bg-info'
                                role='progressbar'
                                style={{width: `${customer.performancePercentage}%`}}
                              ></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default SalesPerformance
