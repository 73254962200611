import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {postCustomerById} from '../Core/_requests'

export default function Comments({className, commentList}) {
  const {customerId} = useParams()
  const [comments, setComments] = useState()
  const [commentsArray, setCommentsArray] = useState([])

  useEffect(() => {
    setCommentsArray(commentList)
  }, [commentList])

  const handleDelete = async (index) => {
    try {
      const updatedComments = [...commentsArray]
      // const deletedComment = updatedComments.splice(index, 1)[0]

      const data = {
        comments: updatedComments,
      }
      await postCustomerById(data, customerId)
      setCommentsArray(updatedComments)
    } catch (error) {
      console.error('Error deleting comment:', error)
    }
  }

  const handleSubmit = async () => {
    try {
      const newComment = {
        text: comments,
        date: new Date().toISOString(),
      }

      const data = {
        comments: [...commentsArray, newComment],
      }

      console.log('data', data)

      const res = await postCustomerById(data, customerId)
      console.log(res.comments)

      setCommentsArray((prevComments) => [...prevComments, newComment])
      setComments('')
      console.log('commentsArray', commentsArray)
    } catch (error) {
      console.error('Error submitting comment:', error)
    }
  }

  const formatCreatedAt = (createdAt) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    return formattedDate
  }

  return (
    <div>
      <div className={`card w-100 position-relative ${className} `}>
        <div className='card-header align-items-center border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bold text-dark'>Comments</span>
          </h3>
          <div className='card-toolbar'></div>
        </div>

        <div className='d-flex align-items-center justify-content-center  mb-5 mt-20'>
          <form className='position-absolute bottom-0 mb-6 card w-75'>
            <div className='separator mb-4'></div>
            <textarea
              className='form-control border-0 p-0 ps-10 pe-10 resize-none min-h-25px'
              rows={1}
              placeholder='Add Comments...'
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
            <div className='separator mt-4'></div>
            <div className='position-absolute top-0 end-0 me-n5'>
              <span
                className='btn btn-icon btn-sm btn-active-color-primary ps-0 pe-15 mt-2'
                onClick={handleSubmit}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen016.svg'
                  className='svg-icon-muted svg-icon-2hx'
                />
              </span>
            </div>
          </form>
        </div>
      </div>
      <div className='card'>
        <div className='card-body pb-0 h-400px overflow-scroll '>
          {commentsArray?.map((note, index) => (
            <div className='d-flex align-items-center bg-light-dark rounded p-5 mb-7 ' key={index}>
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  {note?.text}
                </a>

                <span className='text-muted fw-semibold d-block'>
                  {formatCreatedAt(note?.date)}
                </span>
              </div>
              <button
                onClick={() => {
                  handleDelete(index)
                }}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
