import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  goodsData: [],
  loading: true,
  fetch: true,
}

const goodsSlice = createSlice({
  name: 'goods',
  initialState,
  reducers: {
    setGoodsData(state, action) {
      state.goodsData = action.payload
      state.loading = false
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
    },
    addGoods(state, action) {
      state.goodsData.push(action.payload)
    },
    setGoodsDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setGoodsData, setLoading, addGoods, setGoodsDataFetch} = goodsSlice.actions
export default goodsSlice.reducer
