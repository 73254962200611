import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {Docs, Filtertype, SearchFilter, initialSearchFilter} from '../Core/_models'
import qs from 'qs'
import {getDocs} from '../Core/_requests'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import Filter from '../Components/Filter'
import profile from '../../../_metronic/assets/profile.png'
import Search from '../Components/Search'
import Table from '../Components/Table'
import AddDocs from '../Components/AddDocs'

function Home() {
  const {currentUser} = useAuth()
  const PF = process.env.REACT_PF
  const [docs, setDocs] = useState<Docs[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  let searchFilters: SearchFilter = initialSearchFilter

  const setSearchFilter = (updateValues: {
    search?: string
    agent?: string
    filter?: Partial<Filtertype>
  }): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.agent && {agent: updateValues.agent}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  useEffect(() => {
    console.log('searchFilter:', searchFilters)
  }, [searchFilters])

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        agent: obj.agent,
        filter_status: obj.filter.status,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const getDocsList = async () => {
    if (currentUser?.profileType === 'agent') {
      setSearchFilter({
        agent: currentUser?._id,
      })
    }

    try {
      console.log(searchFilters)
      const queryString = stringifyObject(searchFilters)
      console.log('astring', queryString)
      const res = await getDocs(queryString)
      setDocs(res)
      console.log('docs', docs)
    } catch (error) {
      console.error('Error fetching doc list:', error)
    }
  }

  useEffect(() => {
    getDocsList()
  }, [currentUser])

  const searchdoc = async () => {
    if (currentUser?.profileType === 'agent') {
      setSearchFilter({
        agent: currentUser?._id,
        search: searchTerm,
      })
    } else {
      setSearchFilter({
        search: searchTerm,
      })
    }

    try {
      const queryString = stringifyObject(searchFilters)
      const res = await getDocs(queryString)
      setDocs(res)
      console.log(res)
    } catch (error) {
      console.error('Error fetching doc list:', error)
    }
  }

  useEffect(() => {
    searchdoc()
  }, [searchTerm])

  const handlereset = async () => {
    setSearchFilter(initialSearchFilter)
    getDocsList()
  }

  return (
    <div className={`card h-md-50 mb-5 mb-xl-10 overflow-scroll`}>
      <div className='card-header border-0 pt-5'>
        <div className='d-flex flex-row  card-title align-items-start'>
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <Filter
            searchFilters={searchFilters}
            setSearchFilter={setSearchFilter}
            handlereset={handlereset}
            getDocsList={getDocsList}
          />
        </div>
        <div className='d-flex flex-row  align-content-end'>
          <AddDocs handlereset={handlereset} />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <Table docs={docs} handlereset={handlereset} />
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default Home
