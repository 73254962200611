import React, {useState} from 'react'
import profile from '../../../../../_metronic/assets/profile.png'

const Users = ({users}) => {
  console.log('users', users)
  return (
    <div className='row g-6 g-xl-9'>
      {users?.map((user, index) => (
        <div className='col-md-6 col-xxl-4 key={index}'>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column pt-12 p-9'>
              <div className='symbol symbol-65px symbol-circle mb-5'>
                <img
                  src={profile}
                  // src='/media//avatars/300-6.jpg'
                  alt='image'
                />
                <div className='bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3'></div>
              </div>

              <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bold mb-0'>
                {user?.fullName}
              </a>

              <div className='fw-semibold text-gray-400 mb-6'>Art Director at Novica Co.</div>

              <div className='d-flex flex-center flex-wrap'>
                <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3'>
                  <div className='fs-6 fw-bold text-gray-700'>$14,560</div>
                  <div className='fw-semibold text-gray-400'>Earnings</div>
                </div>

                <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3'>
                  <div className='fs-6 fw-bold text-gray-700'>23</div>
                  <div className='fw-semibold text-gray-400'>Tasks</div>
                </div>
                <div className='border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3'>
                  <div className='fs-6 fw-bold text-gray-700'>$236,400</div>
                  <div className='fw-semibold text-gray-400'>Sales</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Users
