import React, {useEffect, useState} from 'react'
import {getEquipmentsList, getVehiclesList, postIssue} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {getEmployeeByStatus} from '../../../hrm/core/_requests'
import {useNavigate} from 'react-router-dom'

const AddIssue = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [products, setProducts] = useState<any>([])
  const [employees, setEmployees] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    product: '',
    rentalType: 'vehicle',
    priority: 'nopriority',
    reportedDate: new Date(),
    summary: '',
    description: '',
    assignedTo: '',
    dueDate: null,
  })

  const fetchEmployeeList = async () => {
    const res = await getEmployeeByStatus('', currentUser?.organization)
    const data = res?.map((item: any) => ({
      label: item.firstName + ' ' + item.lastName,
      value: item._id,
    }))
    setEmployees(data)
  }

  const fetchProducts = async () => {
    if (formData?.rentalType === 'vehicle') {
      const res = await getVehiclesList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      setFormData({...formData, product: data[0]?.value})
    } else {
      const res = await getEquipmentsList(currentUser?.organization)
      console.log(res)
      const data = res?.map((item: any) => ({
        label: item.productName,
        value: item._id,
      }))
      setProducts(data)
      setFormData({...formData, product: data[0]?.value})
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [formData.rentalType])

  useEffect(() => {
    fetchEmployeeList()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!formData.product || !formData.summary) {
      alert('Please fill all required fields')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      reportedDate: formData.reportedDate.toISOString(),
      dueDate: formData.dueDate?.toISOString(),
      assignedTo: formData.assignedTo === '' ? null : formData.assignedTo,
      organization: currentUser?.organization,
      company: currentUser?.company,
    }
    console.log(data)
    const res = await postIssue(data)
    if (res) {
      navigate('/issues/issue-list')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h4>Add Issue</h4>
        </div>
        <div className='card-body'>
          <form>
            <div className='mb-3'>
              <label className='form-label'>Rental Type</label>
              <select
                className='form-select'
                value={formData.rentalType}
                onChange={(e) => setFormData({...formData, rentalType: e.target.value})}
              >
                <option value='vehicle'>Vehicle</option>
                <option value='equipment'>Equipment</option>
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label required'>Product</label>
              <Select
                options={products}
                value={products.find((item: any) => item.value === formData.product)}
                onChange={(e: any) => setFormData({...formData, product: e?.value})}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Priority</label>
              <select
                className='form-select'
                value={formData.priority}
                onChange={(e) => setFormData({...formData, priority: e.target.value})}
              >
                <option value='nopriority'>No Priority</option>
                <option value='low'>Low</option>
                <option value='medium'>Medium</option>
                <option value='high'>High</option>
                <option value='critical'>Critical</option>
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label required'>Reported Date</label>
              <Datetime
                value={formData.reportedDate}
                inputProps={{className: 'form-control'}}
                dateFormat='DD-MM-YYYY'
                onChange={(e: any) => setFormData({...formData, reportedDate: e.toDate()})}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label required'>Summary</label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter summary'
                value={formData.summary}
                onChange={(e) => setFormData({...formData, summary: e.target.value})}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Description</label>
              <textarea
                className='form-control'
                placeholder='Enter description'
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Assigned To</label>
              <Select
                options={employees}
                value={employees.find((item: any) => item.value === formData.assignedTo)}
                onChange={(e: any) => setFormData({...formData, assignedTo: e?.value})}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>
                Due Date(optional) Considered overdue after this date
              </label>
              <Datetime
                inputProps={{className: 'form-control'}}
                dateFormat='DD-MM-YYYY'
                onChange={(e: any) => setFormData({...formData, dueDate: e.toDate()})}
              />
            </div>
            <button
              type='button'
              disabled={loading}
              onClick={handleSubmit}
              className='btn btn-primary'
            >
              Add Issue
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddIssue
