import React from 'react'

export default function Card3({ loading, data }) {
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='container-xxl'>
                <div className='card-header border-0 cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Project Summary</h3>
                    </div>
                </div>

                <div className='d-flex flex-stack flex-grow-1'>
                    <div className='row w-100 pt-8'>
                        <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                            <label className='form-label col-md-10 ps-12'>Estimated Budget:</label>

                            <input
                                type='text'
                                className='form-control form-control-md form-control-solid w-150px '
                                value={
                                    data?.budgetAmount
                                        ? data.budgetAmount.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                        })
                                        : ''
                                }
                                readOnly
                            />

                        </div>
                        <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                            <label className='form-label col-md-10 ps-12'>Total Product Budget</label>

                            <input
                                type='text'
                                className='form-control form-control-md form-control-solid w-150px'
                                value={
                                    data?.productSubTotal ? data?.productSubTotal.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                    }) : ""}
                                readOnly
                            />

                        </div>
                        <div className=' mb-3 d-flex whitespace-nowrap space-x-4 items-center w-100'>
                            <label className='form-label col-md-10 ps-12 '>

                                Total Manpower Budget:
                            </label>

                            <input
                                type='text'
                                className='form-control form-control-md form-control-solid w-150px '
                                value={data?.totalHourlyCharges ? data?.totalHourlyCharges.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                }) : ' '}
                                readOnly
                            />

                        </div>
                    </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9 '>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                        {!loading && 'Save Changes'}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
