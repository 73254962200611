import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {getItemFailure} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setItemFailureData} from '../../../../redux/maintenance/inspection/itemFailure/itemFailureSlice'

const ItemFailure = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [itemfailure, setitemfailure] = useState<any>([])

  const dispatch = useDispatch()
  const itemfailure = useSelector((state: any) => state.maintenance.itemFailure.itemFailureData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getItemFailure(currentUser?.organization)
    console.log(res)
    // setitemfailure(res)
    dispatch(setItemFailureData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4>Inspection Item Failures</h4>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-striped'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Item Name</th>
                  <th className='min-w-125px'>Fault</th>
                  <th className='min-w-125px'>Stage</th>
                </tr>
              </thead>
              <tbody>
                {itemfailure?.map((issue: any) => (
                  <tr key={issue.id}>
                    <td>{formatDate(issue?.reportedDate)}</td>
                    <td>{issue.product?.productName}</td>
                    <td>{issue?.summary}</td>
                    <td>#{issue.issueId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemFailure
