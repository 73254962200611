import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'

const Step5: FC = () => {
  return (
    // <div className='w-100'>
    //   {/* <div className='pb-8 pb-lg-10'>
    //     <h2 className='fw-bolder text-dark'>Your Are Done!</h2>

    //     <div className='text-gray-400 fw-bold fs-6'>
    //       If you need more info, please
    //       <Link to='/auth/login' className='link-primary fw-bolder'>
    //         {' '}
    //         Sign In
    //       </Link>
    //       .
    //     </div>
    //   </div>

    //   <div className='mb-0'>
    //     <div className='fs-6 text-gray-600 mb-5'>
    //       Writing headlines for blog posts is as much an art as it is a science and probably
    //       warrants its own post, but for all advise is with what works for your great & amazing
    //       audience.
    //     </div>

    //     <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
    //       <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
    //       <div className='d-flex flex-stack flex-grow-1'>
    //         <div className='fw-bold'>
    //           <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4>
    //           <div className='fs-6 text-gray-600'>
    //             To start using great tools, please, please
    //             <a href='/dashboard' className='fw-bolder'>
    //               {' '}
    //               Create Team Platform
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div> */}
    // </div>
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Identity Information</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Passport Number</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='passportNumber'
        />
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='passportNumber' />
        </div> */}
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>National identity number</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='idNumber'
        />
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='idNumber' />
        </div> */}
      </div>
      {/* <div className='mb-10 fv-row'> */}
      {/* <label className='form-label mb-3'>Aadhaar</label>

        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name='aadhaarNumber'
        /> */}
      {/* <div className='text-danger mt-2'>
          <ErrorMessage name='aadhaarNumber' />
        </div> */}
      {/* </div> */}
    </div>
  )
}

export {Step5}
