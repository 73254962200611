import {createSlice} from '@reduxjs/toolkit'

const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documentData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDocumentData(state, action) {
      state.documentData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setDocumentData, setLoading, setError} = documentSlice.actions
export default documentSlice.reducer
