import React, { FC, useEffect, useRef, useState } from 'react'
import { Step1 } from '../Components/LeaveManagement/applyLeaveForm/Step1'
import { KTIcon } from '../../../../_metronic/helpers'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { Form, Formik, FormikValues } from 'formik'
import { applyLeaveSchemas, IApplyLeave, inits } from '../core/ApplyLeaveHelper'
import { postLeaveApply } from '../core/_requests'
import { useAuth } from '../../../modules/auth'



export const ApplyLeave: FC = () => {
  const {currentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(applyLeaveSchemas[0])
  const [initValues] = useState<IApplyLeave>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }



  const submitStep = async (values: IApplyLeave, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(applyLeaveSchemas[stepper.current.currentStepIndex - 1])
    // Check if the submission is the final step
    if (stepper.current.currentStepIndex === stepper.current.totalStepsNumber) {
      // Call postLeaveApply function imported from request.tsx
      console.log(values);
      const data = {
        ...values,
        company: currentUser?.company,
        organization: currentUser?.organization,
      }
      postLeaveApply(data)
    }


   
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    
    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>

            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Apply Leave</h3>
            </div>

          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>


                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    {/* <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button> */}
                  </div>

                  <div>
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}



