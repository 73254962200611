import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
import 'flatpickr/dist/themes/material_blue.css'
import 'flatpickr/dist/flatpickr.min.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useNavigate, useParams} from 'react-router-dom'

import {
  getAccountsNameForPaymentReceivedInvoice,
  getCustomer,
  getCustomerByStatusForAgent,
  getInvoiceByCustomerId,
  getInvoicebyids,
  getPaymentReceivedLastId,
  postPaymentReceived,
} from '../Core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {getNotes, getTerms} from '../../account_settings/core/_request'

const AddPaymentReceivedWithInvoice = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [customerName, setCustomerName] = useState(null)
  const [contactPersonList, setContactPersonList] = useState<any>([])
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [amount, setAmount] = useState('')
  const [balance, setBalance] = useState('')
  const [accountsNameList, setAccountsNameList] = useState<any>([
    {
      label: '',
      value: '',
    },
  ])
  const [selectedCustomerCurrency, setSelectedCustomerCurrency] = useState('')

  //useState for Custom Payment Received no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())
  const [customID, setCustomID] = useState('')
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('3rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('3rem')
  const [selectedInvoiceId, setSelectedInvoiceId] = useState('')
  const [selectedInvoiceLabel, setSelectedInvoiceLabel] = useState(null)
  const [invoiceList, setInvoiceList] = useState([])
  const [paymentPercentage, setPaymentPercentage] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([
    {
      productName: '',
      itemId: null,
      itemsId: null,
      price: 0,
      quantity: 0,
      discount: 0,
      amount: '',
    },
  ])

  const fetchData = async () => {
    const res = await getInvoicebyids(params.id)
    console.log(res)
    setSelectedCustomer({
      label: res?.customer?.displayName,
      value: res?.customer?._id,
    })
    setSelectedCustomerCurrency(res?.customer?.currency)
    setSelectedInvoiceLabel(res?.id)
    setSelectedInvoiceId(res?._id)
    setAmount(res.total)
    setBalance(res.balanceAmount)
    setSelectedInvoiceItems(
      res?.items?.map((item) => ({
        productName: item?.productName,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
      }))
    )
    setPaymentReceived((prev) => ({
      ...prev,
      customer: res.customer?._id,
      items: res.items.map((item) => ({
        productName: item.productName,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
      })),
    }))
  }

  useEffect(() => {
    if (params.id !== undefined) {
      fetchData()
    }
  }, [params.id])

  const getInvoiceByCustomer = async (customerId) => {
    try {
      if (customerId !== undefined) {
        const response = await getInvoiceByCustomerId(customerId)
        console.log(response)
        setInvoiceList(response)
      }
    } catch (error) {
      console.error('Error fetching quotations:', error)
    }
  }

  const invoiceOptions = invoiceList?.map((invoice) => ({
    value: invoice._id,
    label: invoice.id,
  }))

  const handleInvoiceChange = (selectedOption) => {
    setSelectedInvoiceId(selectedOption.value)
    setSelectedInvoiceLabel(selectedOption.label)

    // Find the selected quotation and set its items to the state
    const selectedInvoice = invoiceList?.find((quotation) => quotation._id === selectedOption.value)

    if (selectedInvoice) {
      setAmount(selectedInvoice.total)
      setBalance(selectedInvoice.balanceAmount)
      setSelectedInvoiceItems(
        selectedInvoice.items.map((item) => ({
          productName: item?.productName,
          price: item.price,
          quantity: item.quantity,
          discount: item.discount,
          amount: item.amount,
        }))
      )
      setPaymentReceived((prevState) => ({
        ...prevState,
        totalAmount: selectedInvoice.total,
        items: selectedInvoice.items.map((item) => ({
          productName: item.productName,
          price: item.price,
          quantity: item.quantity,
          discount: item.discount,
          amount: item.amount,
        })),
        invoiceNumber: selectedInvoice.id,
      }))
    }
  }

  useEffect(() => {
    getInvoiceByCustomer(customerName?.value)
    setSelectedInvoiceId(null)
    setSelectedInvoiceLabel(null)
    setAmount('')
    setBalance('')
  }, [customerName])

  const fetchAccountsName = async () => {
    const res = await getAccountsNameForPaymentReceivedInvoice(currentUser?.organization)
    console.log(res)
    const data = res?.map((items) => {
      return {
        label: items?.accountName,
        value: items?._id,
      }
    })
    setAccountsNameList(data)
    setPaymentReceived((prev) => ({
      ...prev,
      depositTo: data[0]?.value,
    }))
  }

  const [paymentReceived, setPaymentReceived] = useState({
    id: '',
    customer: '',
    paymentDate: new Date(),
    amountReceived: '',
    bankCharge: '',
    paymentMode: '',
    depositTo: '',
    reference: '',
    notes: ' ',
    termsNCondition: ' ',
    document: '',
    items: [],
    contactPerson: '',
  })

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const paymentModeOptions = [
    {
      value: 'CreditCard',
      label: 'Credit Card',
    },
    {
      value: 'DebitCard',
      label: 'Debit Card',
    },
    {
      value: 'NetBanking',
      label: 'Net Banking',
    },
    {
      value: 'PayPal',
      label: 'PayPal',
    },
    {
      value: 'cash',
      label: 'Cash',
    },
    {
      value: 'DigitalWallet',
      label: 'Digital Wallet',
    },
    // Add more options as needed
  ]

  const handleDateChange = (selectedDate) => {
    setPaymentReceived((prevData) => ({
      ...prevData,
      paymentDate: selectedDate[0],
    }))
  }

  const handleBillToChange = (selectedOption) => {
    setCustomerName(selectedOption)
    setSelectedCustomer(selectedOption)
    setContactPersonList(
      selectedOption.contactPersons?.map((person) => ({
        value: person?._id,
        label: person?.firstName,
      }))
    )
    setPaymentReceived((prevData) => ({
      ...prevData,
      customer: selectedOption.value,
      contactPerson: selectedOption.contactPersons?.[0]?._id,
    }))
    const selectedCustomer = customerList.find(
      (customer) => customer.value === selectedOption.value
    )
    if (selectedCustomer) {
      setSelectedCustomerCurrency(selectedCustomer.currency)
    }
  }

  const handleChange = (e): void => {
    e.preventDefault()
    const {name, value} = e.target
    setPaymentReceived((prevData) => ({
      ...prevData,
      [name]: value,
    }))
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const getPaymentID = async () => {
    try {
      const res = await getPaymentReceivedLastId(currentUser?.organization)
      console.log('ye hai res', res)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName, // Corrected the typo here from "labe" to "label"
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      console.log(res)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
          contactPersons: r?.contactPersons,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.paymentreceived?.admin === false) {
      getCustomerForAgent()
    } else if (
      permissions?.paymentreceived?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getCustomerList()
    }
  }, [permissions])

  useEffect(() => {
    getPaymentID()
    fetchAccountsName()
  }, [])

  useEffect(() => {
    const fetchReceipt = async () => {
      try {
        const ReceiptNotes = await getNotes(currentUser?.organization, 'Delivery Notes')
        const ReceiptTerms = await getTerms(currentUser?.organization, 'Delivery Notes')

        setPaymentReceived({
          ...paymentReceived,
          notes: ReceiptNotes[0]?.notes || '',
          termsNCondition: ReceiptTerms?.terms || '',
        })
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }
    fetchReceipt()
  }, [])

  const handleSubmit = async () => {
    if (paymentReceived.customer === '') {
      alert('Please select a customer')
      return
    } else if (selectedInvoiceId === '' || selectedInvoiceId === null) {
      alert('Please select an invoice')
      return
    } else if (paymentReceived.amountReceived === '') {
      alert('Please enter payment in %')
      return
    } else if (paymentReceived.depositTo === '') {
      alert('Please select an account for deposit')
      return
    } else if (Number(balance) - Number(paymentReceived.amountReceived) < 0) {
      alert('Amount received cannot be greater than balance')
      return
    }

    setLoading(true)

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...paymentReceived,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      reference: selectedInvoiceId,
      status: balance === paymentReceived.amountReceived ? 'closed' : 'partial',
      approval: permissions?.paymentreceived?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await postPaymentReceived(data, '0').then((res) => {
        console.log('Submitted', res)
        navigate(`/sales/paymentReceived`)
      })
    } catch (error) {
      console.error('Error creating invoice:', error)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      console.log(prefix, nextNumber)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  useEffect(() => {
    if (paymentReceived.termsNCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [paymentReceived.termsNCondition])

  useEffect(() => {
    if (paymentReceived.notes === '') {
      setNotesTextareaHeight('6 rem')
    }
  }, [paymentReceived.notes])

  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            <div className='card'>
              <div className='card-body p-12'>
                <form action='' id='kt_quote_form'>
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>

                      <div className='position-relative d-flex align-items-center w-150px'>
                        <Flatpickr
                          value={paymentReceived.paymentDate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter payment received number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Payment Receipt #</span>
                      {/* <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={paymentReceived.id}
                        placeholder='...'
                        disabled
                      /> */}
                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        placeholder='...'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>

                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Payment Received No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Payment Received numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Payment Received numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Payment Received numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>PAYMENT RECEIVED NO:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Payment Received Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* End of modal content */}
                      </div>
                    </div>
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                  </div>

                  <div className='separator separator-dashed my-10'></div>

                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Customer Name
                        </label>
                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedCustomer}
                        />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Contact Person
                        </label>
                        <Select
                          name='contactPerson'
                          aria-label='Select a Contact Person'
                          placeholder='Select a Contact Person'
                          options={contactPersonList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={(selectedOption) => {
                            setPaymentReceived((prevInvoice) => ({
                              ...prevInvoice,
                              contactPerson: selectedOption.value,
                            }))
                          }}
                          value={contactPersonList?.find(
                            (person) => person.value === paymentReceived.contactPerson
                          )}
                        />
                      </div>
                    </div>
                    <div className='w-50'>
                      <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                        Invoice #
                      </label>
                      <Select
                        name='reference'
                        aria-label='Select a Invoice'
                        placeholder='Select a Invoice'
                        options={invoiceOptions}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={handleInvoiceChange}
                        value={
                          selectedInvoiceLabel
                            ? {
                                value: selectedInvoiceId,
                                label: selectedInvoiceLabel,
                              }
                            : null
                        }
                      />
                    </div>
                    {selectedInvoiceLabel ? (
                      <>
                        <div className='fs-6 fw-bold text-gray-700 my-4'>Item Table</div>
                        <div className='table-responsive mb-10'>
                          {/* begin::Table */}
                          <table
                            className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                            data-kt-element='items'
                          >
                            {/* begin::Table head */}
                            <thead>
                              <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                                <th className='min-w-100px w-500px'>Item</th>
                                <th className='min-w-50px'>Quantity</th>
                                <th className='min-w-100px w-125px'>Rate</th>
                                <th className='min-w-50px'>Discount</th>
                                <th className='min-w-70px w-125px'>Amount</th>
                                {/* <th className='min-w-50px'>Action</th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {selectedInvoiceItems?.map((item, index) => (
                                <tr
                                  className='border-bottom border-bottom-dashed'
                                  data-kt-element='item'
                                  key={index}
                                >
                                  <td className='pe-7'>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      name='productName'
                                      placeholder=''
                                      value={item?.productName}
                                      readOnly
                                    />
                                  </td>
                                  <td className='pe-7'>
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      name='quantity'
                                      placeholder='0.00'
                                      min={0}
                                      value={item.quantity}
                                      readOnly
                                    />
                                  </td>

                                  <td className='pe-7'>
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      name='price'
                                      placeholder='0.00'
                                      value={item.price}
                                      readOnly
                                    />
                                  </td>

                                  <td className='pe-7'>
                                    <input
                                      type='number'
                                      className='form-control form-control-solid'
                                      name='discount'
                                      placeholder='0.00'
                                      value={item.discount}
                                      readOnly
                                    />
                                  </td>

                                  <td>
                                    <input
                                      type='number'
                                      className='form-control form-control-solid text-end'
                                      name='amount'
                                      placeholder='0.00'
                                      value={item.amount}
                                      readOnly
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className='row gx-10 mb-5'>
                          <div className='col-xl-6'>
                            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                              Total Amount
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-solid'
                              name='amount'
                              placeholder='Amount'
                              value={amount}
                              readOnly
                            />
                          </div>
                          <div className='col-xl-6'>
                            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                              Balance
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-solid'
                              name='balance'
                              placeholder='Balance'
                              value={balance}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className='row gx-10 mb-5'>
                          <div className='col-xl-4'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Payment in %
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-solid'
                              name='paymentPercentage'
                              placeholder='0%'
                              min='0'
                              max='100'
                              value={paymentPercentage}
                              onChange={(e) => {
                                setPaymentPercentage(e.target.value)
                                setPaymentReceived((prevInvoice) => ({
                                  ...prevInvoice,
                                  amountReceived: (
                                    (Number(e.target.value) / 100) *
                                    Number(balance)
                                  ).toFixed(2),
                                }))
                                setPaymentAmount(
                                  ((Number(e.target.value) / 100) * Number(balance)).toFixed(2)
                                )
                              }}
                            />
                          </div>
                          <div className='col-xl-4'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Payment in Amount
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-solid'
                              name='paymentAmount'
                              placeholder='Payment Amount'
                              value={paymentAmount}
                              min='0'
                              max={balance}
                              onChange={(e) => {
                                setPaymentAmount(e.target.value)
                                setPaymentReceived((prevInvoice) => ({
                                  ...prevInvoice,
                                  amountReceived: e.target.value,
                                }))
                                setPaymentPercentage(
                                  ((Number(e.target.value) / Number(balance)) * 100).toFixed(2)
                                )
                              }}
                            />
                          </div>
                          <div className='col-xl-4 d-flex align-items-end justify-center'>
                            <button
                              type='button'
                              className='btn btn-light-primary btn-sm w-100'
                              onClick={() => {
                                setPaymentReceived((prevInvoice) => ({
                                  ...prevInvoice,
                                  amountReceived: balance,
                                }))
                                setPaymentAmount(balance)
                                setPaymentPercentage('100')
                              }}
                            >
                              Pay in Full
                            </button>
                          </div>
                        </div>
                        <div className='row gx-10 mb-5'>
                          <div className='col-xl-4'>
                            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                              Amount Received
                            </label>
                            <div className='relative d-flex align-items-center mb-3'>
                              <div className='absolute bg-secondary fs-3 py-3 px-4 rounded-s-xl'>
                                {selectedCustomerCurrency}
                              </div>
                              <input
                                type='number'
                                className='form-control form-control-solid ps-20'
                                name='amountReceived'
                                placeholder='Amount Received'
                                value={paymentReceived.amountReceived}
                                min='0'
                                // max={balance}
                                // onChange={handleChange}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className='col-xl-4'>
                            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                              Balance Left
                            </label>
                            <div className='relative d-flex align-items-center mb-3'>
                              <input
                                type='number'
                                className='form-control form-control-solid'
                                name='balanceLeft'
                                placeholder='Balance Left'
                                value={Number(balance) - Number(paymentReceived.amountReceived)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className='col-xl-4'>
                            <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                              Bank Charge
                            </label>
                            <input
                              type='number'
                              className='form-control form-control-solid '
                              name='bankCharge'
                              placeholder='Bank Charge'
                              value={paymentReceived.bankCharge}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className='row gx-10 mb-5'>
                          <div className='mb-10 col-lg-6'>
                            <label className='form-label required fw-bold fs-6 text-gray-700'>
                              Payment Mode
                            </label>
                            <Select
                              name='paymentMode'
                              aria-label='Select a Payment Mode'
                              placeholder='Select a Payment Mode'
                              options={paymentModeOptions}
                              isSearchable={true}
                              className='form-control form-control-solid'
                              onChange={(selectedOption) => {
                                setPaymentReceived((prevInvoice) => ({
                                  ...prevInvoice,
                                  paymentMode: selectedOption.value,
                                }))
                              }}
                            />
                          </div>
                          <div className='mb-10 col-lg-6'>
                            <label className='form-label required fw-bold fs-6 text-gray-700'>
                              Deposit To
                            </label>
                            <Select
                              name='depositTo'
                              aria-label='Select a Deposit To'
                              placeholder='Select a account for Deposit'
                              options={accountsNameList}
                              isSearchable={true}
                              className='form-control form-control-solid'
                              onChange={(selectedOption: any) => {
                                setPaymentReceived((prevInvoice) => ({
                                  ...prevInvoice,
                                  depositTo: selectedOption.value,
                                }))
                              }}
                            />
                          </div>
                          <div className='mb-0 py-2 position-relative'>
                            <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                            <textarea
                              name='notes'
                              className={`form-control form-control-solid mb-0 h-10 ${
                                isEditingNotes ? 'border border-dark-subtle' : ''
                              }`}
                              placeholder='Enter customer notes'
                              value={paymentReceived.notes}
                              onChange={handleChange}
                              readOnly={!isEditingNotes}
                              style={{
                                minHeight: '6rem',
                                height: notesTextareaHeight,
                                resize: 'none',
                              }}
                            />
                            <button onClick={(e) => handleEditClick(e, 'notes')}>
                              <KTIcon
                                iconName='pencil'
                                className='position-absolute text-black bg-gray-100 right-12 top-[4rem] fs-3'
                              />
                            </button>
                          </div>
                          <div className='mb-10 h-100 position-relative '>
                            <label className='form-label fw-bold fs-6 text-gray-700'>
                              Terms and Conditions
                            </label>
                            <textarea
                              name='termsNCondition'
                              style={{
                                minHeight: '6rem',
                                height: termsTextareaHeight,
                                resize: 'none',
                              }}
                              className={` form-control form-control-solid mb-0 ${
                                isEditingTerms ? 'border border-dark-subtle' : ''
                              }`}
                              placeholder='Enter terms and conditions'
                              value={paymentReceived.termsNCondition}
                              onChange={handleChange}
                              readOnly={!isEditingTerms}
                            />
                            <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                              <KTIcon
                                iconName='pencil'
                                className='absolute text-black bg-gray-100 right-12 top-[4rem] fs-3'
                              />
                            </button>
                          </div>
                          <div className='mb-0'>
                            <button
                              className='btn btn-primary w-25'
                              id='kt_quote_submit_button'
                              onClick={handleSubmit}
                              disabled={loading}
                            >
                              <i className='ki-duotone ki-triangle fs-3'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              Record Payment
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddPaymentReceivedWithInvoice
