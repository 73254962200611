import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
// import { getDeals, getQuotes, getQuotesbyAgentId } from '../core/_requests';
import {KTIcon} from '../../../../_metronic/helpers'
import {getPayrollsByMonth} from '../core/_requests'
import Swal from 'sweetalert2'

function PayrollMonthTable() {
  const {currentUser} = useAuth()
  const params = useParams()
  const month = params?.month
  const [payrollList, setpayrollList] = useState<any>([])

  const fetchpayrollList = async () => {
    // if(currentUser?.profileType === "superadmin"){
    const res = await getPayrollsByMonth(month, currentUser?.organization)
    setpayrollList(res)
    // }else if(currentUser?.profileType === "superadmin"){
    //   const res = await getQuotesbyAgentId(currentUser?._id)
    //   setpayrollList(res)
    //   console.log(res)
    // }
  }
  useEffect(() => {
    fetchpayrollList()
  }, [])

  const showDetail = (item: any) => {
    Swal.fire({
      title: 'Payroll Details',
      // showing all details like employee name, paid through, amount, date, notes
      html: `
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label class="form-label">Employee</label>
                <input type="text" class="form-control" value="${
                  item?.employeeId?.firstName
                }" readonly>
            </div>
            </div>
            <div class="col-6">
            <div class="mb-3">
              <label class="form-label
                ">Paid Through</label>
                    <input type="text" class="form-control" value="${
                      item?.paidThrough?.accountName
                    }" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Amount</label>
                    <input type="text" class="form-control" value="${item?.salary}" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Date</label>
                    <input type="text" class="form-control" value="${formatCreatedAt(
                      item?.startDate
                    )} - ${formatCreatedAt(item?.endDate)}" readonly>
            </div>
            </div>
            <div class="col-12">
            <div class="mb-3">
              <label class="form-label
                ">Notes</label>
                    <textarea class="form-control" readonly>${item?.notes}</textarea>
            </div>
            </div>
        </div>
        `,

      showCloseButton: true,
      showCancelButton: false,
      customClass: 'swal-wide',
      focusConfirm: false,
      confirmButtonText: 'Close',
    })
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Payrolls of <span className='capitalize'>{month}</span>
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Created On</th>
                <th className='min-w-150px text-center'>Employee</th>
                <th className='min-w-150px text-center'>Paid Through</th>
                <th className='min-w-150px text-center'>Amount</th>
                <th className='min-w-150px rounded-end text-center'>Date</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {payrollList?.map((item: any, index) => (
                <tr
                  key={item?._id?.toString()}
                  onClick={() => {
                    showDetail(item)
                  }}
                >
                  <td>
                    <Link
                      to={`#`}
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {formatCreatedAt(item?.createdAt)}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?.employeeId?.firstName}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.paidThrough?.accountName}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.salary}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-center text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {formatCreatedAt(item?.startDate)} - {formatCreatedAt(item?.endDate)}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default PayrollMonthTable
