import {useEffect, useRef} from 'react'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import ApexCharts, {ApexOptions} from 'apexcharts'

interface Vendor {
  vendorName: string
  performancePercentage: number
}

interface Props {
  vendors: Vendor[]
}

const VendorStatBar: React.FC<Props> = ({vendors}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current || vendors.length === 0) {
      return
    }

    const chartData = vendors.map((vendor) => ({
      x: vendor.vendorName,
      y: vendor.performancePercentage,
    }))

    const chart = new ApexCharts(chartRef.current, getChartOptions(chartData))
    chart.render()

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [vendors, mode])

  return (
    <div className='card-body'>
      <div className='card-body pt-5 ps-6'>
        <div className='card-header flex-nowrap pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>Vendors Performance</span>
          </h3>
        </div>
        <div>
          <div
            ref={chartRef}
            id='kt_charts_widget_8_chart'
            style={{height: '350px'}}
            className='card-rounded-bottom'
          ></div>
        </div>
      </div>
    </div>
  )
}

export default VendorStatBar

function getChartOptions(chartData: {x: string; y: number}[]): ApexOptions {
  return {
    series: [
      {
        name: 'Performance Percentage',
        data: chartData,
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      min: 0, // Minimum value of x-axis
      max: 100, // Maximum value of x-axis
      tickAmount: 5, // Show 11 ticks (0, 10, 20, ..., 100)
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: 700,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value: number) => `${value.toFixed(2)}%`, // Format y-axis value to show up to two decimal places
      },
    },
  }
}
