import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import ShippingAddress from '../Pages/ShippingAddress'
import BillingAddress from '../Pages/BillingAddress'

export default function CustomerAddress({
  customerData,
  billingAddress,
  setBillingAddress,
  handleReset,
  setShippingAddress,
  shippingAddress,
}) {
  return (
    <div>
      <div>
        <div>
          <div className='card-title mt-4 relative'> Billing Address</div>
          <button type='button' data-bs-toggle='modal' data-bs-target='#exampleModal2'>
            <KTIcon iconName='pencil' className='absolute right-9' />
          </button>
          <p className='m-0'>{customerData?.billingAddress?.attention}</p>
          <p className='m-0'>{customerData?.billingAddress?.addressLine1}</p>
          <p className='m-0'>{customerData?.billingAddress?.addressLine2}</p>
          <p className='m-0'> {customerData?.billingAddress?.state}</p>
          <p className='m-0'> {customerData?.billingAddress?.city}</p>
          <p className='m-0'> {customerData?.billingAddress?.postalCode}</p>
          <p className=' m-0'>{customerData?.billingAddress?.phone}</p>
        </div>
        <div>
          <div className='card-title mt-4 relative'>Shipping Address </div>
          <button type='button' data-bs-toggle='modal' data-bs-target='#exampleModal3'>
            <KTIcon iconName='pencil' className='absolute right-9' />
          </button>
          <p className='m-0'>{customerData?.shippingAddress?.attention}</p>
          <p className='m-0'>{customerData?.shippingAddress?.addressLine1}</p>
          <p className='m-0'>{customerData?.shippingAddress?.addressLine2}</p>
          <p className='m-0'> {customerData?.shippingAddress?.state}</p>
          <p className='m-0'> {customerData?.shippingAddress?.city}</p>
          <p className='m-0'> {customerData?.shippingAddress?.postalCode}</p>
          <p className=' m-0'>{customerData?.shippingAddress?.phone}</p>
        </div>
      </div>
      <div
        class='modal fade'
        id='exampleModal2'
        tabindex='-1'
        aria-labelledby='exampleModalLabel2'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='exampleModalLabel2'>
                Billing Address
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <BillingAddress
                billingAddress={billingAddress}
                setBillingAddress={setBillingAddress}
                handleReset={handleReset}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class='modal fade'
        id='exampleModal3'
        tabindex='-1'
        aria-labelledby='exampleModalLabel3'
        aria-hidden='true'
      >
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='exampleModalLabel3'>
                Shipping Address
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <ShippingAddress
                shippingAddress={shippingAddress}
                setShippingAddress={setShippingAddress}
                handleReset={handleReset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
