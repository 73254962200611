import {Field} from 'formik'
import React, {useEffect, useState} from 'react'
import {getTargetByProjectId, getProjectById} from '../../core/_requests'
import {useParams} from 'react-router-dom'

interface ProjectData {
  budgetAmount: number
  budgetNotes: string
  totalHourlyCharges: number
  productSubTotal: number
}

const Budget = () => {
  const [data, setData] = useState<ProjectData | null>(null)
  const [loading, setLoading] = useState(false)
  const [target, setTarget] = useState([])

  const id = useParams().id

  const fetchData = async () => {
    try {
      const res = await getProjectById(id)
      setData(res)
      console.log('data', data)
    } catch (error) {
      console.error('Error fetching the employee details', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const getTarget = async () => {
    try {
      const target = await getTargetByProjectId(id)
      setTarget(target)
      console.log('targets', target)
    } catch (error) {
      console.log('error')
    }
  }

  useEffect(() => {
    getTarget()
  }, [])

  const totalBudgetSpent = target.reduce((total, target) => {
    return total + target.targetBudget
  }, 0)

  const totalBudgetPercentage = ((totalBudgetSpent / data?.budgetAmount) * 100).toFixed(2)
  // const totalBudgetSpent = data?.totalHourlyCharges + data?.productSubTotal
  // const totalBudgetPercentage = ((totalBudgetSpent / data?.budgetAmount) * 100).toFixed(2)
  const budgetAmount = data?.budgetAmount ?? 0

  return (
    <div className='container-xxl'>
      <div className='card mb-5 mb-xl-10 border'>
        <div className='container-xxl'>
          <div className='card-header border-0 cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'> PROJECT BUDGET </h3>
            </div>
          </div>

          <div className='card-body border-top p-9'>
            <form noValidate className='form'>
              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>Current Status</div>
                </div>
                <div className='col-xl-9'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between w-100 fs-4 fw-bold mb-3'>
                      <span>Budget</span>
                      <span>
                        {totalBudgetSpent.toLocaleString()} of {budgetAmount.toLocaleString()}
                      </span>
                    </div>
                    <div className='h-8px bg-light rounded mb-3'>
                      <div
                        className='bg-success rounded h-8px'
                        role='progressbar'
                        style={{width: `${totalBudgetPercentage}%`}}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      ></div>
                    </div>
                    <div className='fw-semibold text-gray-600'>
                      {target.length} Targets are remaining
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>Total Budget</div>
                </div>

                <div className='col-xl-9'>
                  <div
                    className='position-relative w-md-300px'
                    data-kt-dialer='true'
                    data-kt-dialer-min='1000'
                    data-kt-dialer-max='50000'
                    data-kt-dialer-step='1000'
                    data-kt-dialer-prefix='$'
                    data-kt-dialer-decimals='2'
                  >
                    <input
                      type='text'
                      className='form-control form-control-solid border-0 ps-12'
                      data-kt-dialer-control='input'
                      placeholder='Amount'
                      name='manageBudget'
                      value={data?.budgetAmount}
                    />
                  </div>
                </div>
              </div>

              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>Budget Notes</div>
                </div>

                <div className='col-xl-9'>
                  <textarea
                    name='budgetNotes'
                    value={data?.budgetNotes}
                    className='form-control form-control-solid'
                    rows={5}
                    readOnly
                  ></textarea>
                </div>
              </div>

              <div className='row mb-8'>
                <div className='col-xl-3'>
                  <div className='fs-6 fw-semibold mt-2 mb-3'>Overuse Notifications</div>
                </div>

                <div className='col-xl-9'>
                  <div className='d-flex fw-semibold h-100'>
                    <div className='form-check form-check-custom form-check-solid me-9'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='email'
                        name='notification.email'
                      />
                      <label className='form-check-label ms-3' htmlFor='email'>
                        Email
                      </label>
                    </div>

                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        name='notification.phone'
                        type='checkbox'
                      />
                      <label className='form-check-label ms-3' htmlFor='phone'>
                        Phone
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Budget
