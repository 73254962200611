/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import {useAuth} from '../../../../modules/auth'
import {
  getPaymentReceived,
  getPaymentReceivedByAgentId,
  setPaymentReceivedStatusApprove,
  setPaymentReceivedStatusReject,
} from '../../Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../Core/_model'
import {setPaymentReceivedApprovalData} from '../../../../redux/sales/approval/paymentReceived/paymentReceivedApprovalSlice'

const PaymentReceivedApproval: React.FC = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()
  const paymentReceived = useSelector(
    (state: RootState) => state.sales.paymentReceivedApproval.paymentReceivedApprovalData
  )

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    if (permissions?.paymentreceived?.admin === false) {
      getListbyAgent()
    } else if (
      permissions?.paymentreceived?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getlist()
    }
  }

  const getlist = async () => {
    const res = await getPaymentReceived(currentUser?.organization)
    // setPaymentReceived(res)
    dispatch(setPaymentReceivedApprovalData(res))
  }

  const getListbyAgent = async () => {
    const res = await getPaymentReceivedByAgentId(currentUser?._id)
    // setPaymentReceived(res)
    dispatch(setPaymentReceivedApprovalData(res))
  }

  useEffect(() => {
    fetchData()
  }, [permissions])

  const handleApproved = async (id, index) => {
    const res = await setPaymentReceivedStatusApprove(id)
    if (res) {
      fetchData()
    }
  }

  const handleReject = async (id, index) => {
    const res = await setPaymentReceivedStatusReject(id)
    if (res) {
      fetchData()
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card mt-5'>
      <div className='card-header border-0 pt-5 flex justify-between'>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Payment Received</span>
          </h3>
        </div>
      </div>

      <div className='card-body py-3 '>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px rounded-start'>ID</th>
                <th className='min-w-200px'>Employee</th>
                <th className='min-w-200px'>Customer</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-100px'>Approval</th>
                {(currentUser?.profileType === 'superadmin' ||
                  permissions?.salesapproval?.update) && (
                  <th className='min-w-200px rounded-end px-10'>Action</th>
                )}
              </tr>
            </thead>

            <tbody>
              {paymentReceived?.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center ps-4'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user?.id}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      {/* <div className='symbol symbol-50px me-5'>
                        <img
                          src={user?.agent.profilePic ? PF + user?.agent.profilePic : profile}
                          className=''
                          alt=''
                        />
                      </div> */}
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user?.employee?.firstName}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user?.customer?.displayName}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatCreatedAt(user.createdAt)}
                    </a>
                  </td>
                  <td>
                    <span
                      className={`badge fs-7 fw-semibold ${
                        user.approval === 'accepted'
                          ? 'badge-light-success'
                          : user.approval === 'rejected'
                          ? 'badge-light-danger'
                          : 'badge-light-warning'
                      }`}
                    >
                      {user?.approval}
                    </span>
                  </td>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.salesapproval?.update) && (
                    <td className='text-center'>
                      <div className='d-flex'>
                        <div className='dropdown'>
                          <button
                            className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm dropdown-toggle'
                            type='button'
                            id={`actionDropdown${index}`}
                            data-bs-toggle='dropdown'
                            aria-expanded='false'
                          >
                            Actions
                          </button>
                          <ul className='dropdown-menu' aria-labelledby={`actionDropdown${index}`}>
                            {(user.approval === 'pending' || user.approval === 'rejected') && (
                              <li>
                                <button
                                  className='dropdown-item'
                                  onClick={() => handleApproved(user._id, index)}
                                >
                                  Accept
                                </button>
                              </li>
                            )}
                            {(user.approval === 'pending' || user.approval === 'accepted') && (
                              <li>
                                <button
                                  className='dropdown-item'
                                  onClick={() => handleReject(user._id, index)}
                                >
                                  Reject
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export {PaymentReceivedApproval}
