export const industryList = [
  {
    title: 'Manufacturing',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
  {
    title: 'Retail & General Trading',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
  {
    title: 'FMC/Services',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
  {
    title: 'Rental & Equipment',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
  {
    title: 'Construction/Engineering',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
  {
    title: 'E-commerce/Online Store',
    items: [
      {
        name: 'item1',
        link: '/',
      },
    ],
  },
]
