import {createSlice} from '@reduxjs/toolkit'

const serviceHistorySlice = createSlice({
  name: 'serviceHistory',
  initialState: {
    serviceHistoryData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setServiceHistoryData(state, action) {
      state.serviceHistoryData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setServiceHistoryData, setLoading, setError} = serviceHistorySlice.actions
export default serviceHistorySlice.reducer
