import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  getFilteredBills,
  getFilteredBillsByAgentId,
  setBillsStatusApprove,
  setBillsStatusReject,
} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setBills, setBillsDataFetch} from '../../../../redux/procurement/bills/billsSlice'
import Pagination from '../Pagination'

const BillsApproval = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [billsData, setBillsData] = useState([])
  const billsList = useSelector((state: RootState) => state.procurement.bills.bills)
  const totalPagesStore = useSelector((state: RootState) => state.procurement.bills.totalPages)
  const [loading, setLoading] = useState(false)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 25
  const [permissions, setPermissions] = useState<any>({})

  const fetchdata = () => {
    if (billsList.length === 0 || currentPage !== 1) {
      setLoading(true)
      if (permissions?.bills?.admin === false) {
        getBillsByAgent()
      } else if (permissions?.bills?.admin === true || currentUser?.profileType === 'superadmin') {
        getList()
      }
    } else {
      setBillsData(billsList)
      setTotalPages(totalPagesStore)
    }
  }

  const getList = async () => {
    try {
      const data = await getFilteredBills('', currentUser?.organization, currentPage, itemsPerPage)
      setBillsData(data?.bills)
      setTotalPages(data?.totalPages)
      if (currentPage === 1) dispatch(setBills(data))
      setLoading(false)
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }

  const getBillsByAgent = async () => {
    const res = await getFilteredBillsByAgentId('', currentUser?._id, currentPage, itemsPerPage)
    setBillsData(res?.bills)
    setTotalPages(res?.totalPages)
    if (currentPage === 1) dispatch(setBills(res))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser, currentPage])

  useEffect(() => {
    fetchdata()
  }, [permissions])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  const handleApproved = async (id, index) => {
    const res = await setBillsStatusApprove(id)
    if (res) {
      const updatedData = billsData.map((item, idx) =>
        idx === index ? {...item, approval: 'accepted'} : item
      )
      setBillsData(updatedData)
      if (currentPage === 1) {
        dispatch(
          setBills({
            bills: [],
            totalPages: 1,
          })
        )
        dispatch(setBillsDataFetch())
      }
    }
  }

  const handleReject = async (id, index) => {
    const res = await setBillsStatusReject(id)
    if (res) {
      const updatedData = billsData.map((item, idx) =>
        idx === index ? {...item, approval: 'rejected'} : item
      )
      setBillsData(updatedData)
      if (currentPage === 1) {
        dispatch(
          setBills({
            bills: [],
            totalPages: 1,
          })
        )
        dispatch(setBillsDataFetch())
      }
    }
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>BILLS</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-100px rounded-start'>Date</th>
                  <th className='min-w-100px'>Bill#</th>
                  <th className='min-w-100px'>Reference</th>
                  <th className='min-w-150px'>Vendor</th>
                  <th className='min-w-100px'>Approval</th>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.procurementapproval?.update) && (
                    <th className='min-w-100px rounded-end px-10'>Action</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {billsData?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.billDate)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.orderNo?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/billsList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <span
                        className={`badge fs-7 fw-semibold ${
                          item.approval === 'accepted'
                            ? 'badge-light-success'
                            : item.approval === 'rejected'
                            ? 'badge-light-danger'
                            : 'badge-light-warning'
                        }`}
                      >
                        {item?.approval}
                      </span>
                    </td>
                    {(currentUser?.profileType === 'superadmin' ||
                      permissions?.procurementapproval?.update) && (
                      <td className='text-center'>
                        <div className='d-flex'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm dropdown-toggle'
                              type='button'
                              id={`actionDropdown${index}`}
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Actions
                            </button>
                            <ul
                              className='dropdown-menu'
                              aria-labelledby={`actionDropdown${index}`}
                            >
                              {(item.approval === 'pending' || item.approval === 'rejected') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleApproved(item._id, index)}
                                  >
                                    Accept
                                  </button>
                                </li>
                              )}
                              {(item.approval === 'pending' || item.approval === 'accepted') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleReject(item._id, index)}
                                  >
                                    Reject
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  )
}

export default BillsApproval
