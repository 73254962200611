import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials'
import ApexCharts, {ApexOptions} from 'apexcharts'

const StatBar = () => {
    const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])
  return (
    <div>
         <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{height: '350px'}}
          className='card-rounded-bottom'
        ></div>
    </div>
  )
}

export  default StatBar
function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const color4 = getCSSVariableValue('--bs-dark')
  
    return {
      series: [{
        data: [400, 430, 448, 470, 540, 580, 690,]
      }],
        chart: {
        type: 'bar',
        height: 350
        
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
         
        }
        
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: ['Phones','Laptops','TVs','Cameras','Consoles','Smartwatches','Speakers'],
        labels:{
          style:{
            colors: labelColor,
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 500,
          }
        }
      },
      yaxis: {
        
        labels:{
          style:{
            colors:color4 ,
            fontSize: '12px',
            fontFamily: 'Inter',
            fontWeight: 700,
          }
        }
      }
    }
  }