import React, {useEffect, useState} from 'react'
import {
  getEmployeeByEmail,
  getEmployeeReportbyDeptandRole,
  getEmployeeReportbyId,
  getEmployeeReports,
  postEmployeeReportAnswersByFormId,
} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import { useNavigate } from 'react-router-dom'

const AddReportSubmission = () => {
  const navigate=useNavigate()
  const {currentUser} = useAuth()
  const [formData, setFormData] = useState(null)
  const [answers, setAnswers] = useState([])
  const [employee, setEmployee] = useState(null)
  const [employeeEmail, setEmployeeEmail] = useState('')
  const [isEmailTyped, setIsEmailTyped] = useState(false)
  const [formOptions, setFormOptions] = useState([])
  const [selectedForm, setSelectedForm] = useState('')
  const [isFetchingEmployee, setIsFetchingEmployee] = useState(false)

  const fetchEmployeebyEmail = async () => {
    setIsFetchingEmployee(true)
    try {
      const res = await getEmployeeByEmail(employeeEmail)
      console.log('Employee by Email', res)
      setEmployee(res)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert('This email does not exist.')
      } else {
        console.error('Error fetching employee data:', error)
      }
    } finally {
      setIsFetchingEmployee(false)
    }
  }

  useEffect(() => {
    setIsEmailTyped(!!employeeEmail.trim())
  }, [employeeEmail])

  useEffect(() => {
    const fetchFormList = async () => {
      try {
        if (employee && employee.department && employee.role) {
          const forms = await getEmployeeReportbyDeptandRole(employee.department._id, employee.role)
          setFormOptions(forms.map((form) => ({value: form._id, label: form.title})))
        }
      } catch (error) {
        console.error('Error fetching forms:', error)
      }
    }
    fetchFormList()
  }, [employee])

  const fetchFormData = async (formId) => {
    try {
      const formData = await getEmployeeReportbyId(formId)
      console.log('Form Data:', formData)
      setFormData(formData)
    } catch (error) {
      console.error('Error fetching form data:', error)
    }
  }

  useEffect(() => {
    if (selectedForm) {
      fetchFormData(selectedForm)
    }
  }, [selectedForm])
  console.log('form options', formOptions)

  // Function to handle user input for text question
  const handleTextChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for checkbox question
  const handleCheckboxChange = (index, optionIndex, event) => {
    const newAnswers = [...answers]
    if (!newAnswers[index]) {
      newAnswers[index] = []
    }
    if (event.target.checked) {
      newAnswers[index].push(formData.questions[index].options[optionIndex])
    } else {
      const optionToRemove = formData.questions[index].options[optionIndex]
      newAnswers[index] = newAnswers[index].filter((option) => option !== optionToRemove)
    }
    setAnswers(newAnswers)
  }

  // Function to handle user input for date question
  const handleDateChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value // Assuming the input value is stored as a string
    setAnswers(newAnswers)
  }

  // Function to handle user input for number question
  const handleNumberChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value // Assuming the input value is stored as a string
    setAnswers(newAnswers)
  }

  // Function to handle user input for dropdown question
  const handleDropdownChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for multiple choice question
  const handleMultipleChoiceChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for meter question
  const handleMeterChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for yesno question
  const handleYesNoChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value === 'yes'
    setAnswers(newAnswers)
  }

  // Function to handle user input for photo and signature question
  const handleFileChange = (index, event) => {
    const newAnswers = [...answers]
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      newAnswers[index] = e.target.result
      setAnswers(newAnswers)
    }
    reader.readAsDataURL(file)
  }

  const handleFormChange = (selectedForm) => {
    setSelectedForm(selectedForm)
  }

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      // // Check if all answers are filled
      // if (answers.length !== formData.questions.length) {
      //   alert('Please answer all questions before submitting.')
      //   return
      // }
      const data = {
        department: employee.department._id,
        role: employee.role,
        lat: null,
        lng: null,
        answers: answers.map((answer, index) => ({
          question: formData.questions[index].questionText,
          questionType: formData.questions[index].questionType,
          answer: answer,
          comment: null,
          photo: null,
        })),
        createdBy: employee._id,
        organization: currentUser?.organization,
        company: currentUser?.company,
      }

      // Send data to the backend
      const response = await postEmployeeReportAnswersByFormId(selectedForm, data)
      console.log('Response from backend:', response)
      setEmployee(null)
      setEmployeeEmail('')
      setIsEmailTyped(false)
      setFormOptions([])
      setSelectedForm('')
      setAnswers([])
      setFormData(null)
      alert('Answers submitted successfully!')
    } catch (error) {
      console.error('Error submitting answers:', error)
      alert('Error submitting answers. Please try again later.')
    }
    navigate(-1)
  }
  console.log('employee id', employee?._id)
  return (
    <div className='form-container w-[60vw] border border-slate-500 p-5 rounded-[5px]'>
      <form onSubmit={handleSubmit}>
        <div className='mb-3 flex gap-5 items-center'>
          <label htmlFor='email' className='form-label font-[600] text-lg w-[160px]'>
            Employee Email
          </label>
          <input
            type='email'
            className='form-control'
            id='email'
            value={employeeEmail}
            onChange={(e) => setEmployeeEmail(e.target.value)}
            required
          />
          <button
            type='submit'
            className='btn btn-primary w-[200px]'
            onClick={fetchEmployeebyEmail}
            disabled={!isEmailTyped || isFetchingEmployee}
          >
            {isFetchingEmployee ? 'Fetching...' : 'Fetch Reports'}
          </button>
        </div>
        {employee && (
          <div className='card mb-3'>
            <div className='card-body'>
              <div className='form-group flex gap-5 items-center mb-3'>
                <label className='w-[10%] font-[500]'>Department</label>
                <input
                  type='text'
                  className='form-control w-[90%]'
                  value={employee?.department?.name}
                  readOnly
                />
              </div>
              <div className='form-group flex gap-5 items-center mb-3'>
                <label className='w-[10%] font-[500]'>Role</label>
                <input
                  type='text'
                  className='form-control w-[90%]'
                  value={employee?.role}
                  readOnly
                />
              </div>
              {/* Select field for forms */}
              <div className='form-group flex gap-5 items-center mb-3'>
              <label className='w-[10%] font-[500]'>Select Form</label>
                <select
                  className='form-select w-[90%]'
                  onChange={(e) => handleFormChange(e.target.value)}
                  value={selectedForm}
                >
                  <option value=''>Select Form</option>
                  {formOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {formData && (
          <div className='card'>
            <div className='card-body'>
              <h2 className='border-b-2 p-2 mb-5'>{formData.title}</h2>
              {formData.questions.map((question, questionIndex) => (
                <div key={questionIndex} className='form-group mb-3 flex gap-5 items-center'>
                  <div className='w-[50%]'>
                  <div className='d-flex flex-column gap-1'>
                    <label className='font-[500] text-lg'>{question.questionText}</label>
                    {question.shortDescription && <p>{question.shortDescription}</p>}
                  </div>
                  </div>
                  <div className='w-[50%]'>
                  
                  {question.questionType === 'text' && (
                    <input
                      type='text'
                      className='form-control w-[50%]'
                      value={answers[questionIndex] || ''}
                      onChange={(event) => handleTextChange(questionIndex, event)}
                      required={question.isRequired}
                    />
                  )}
                  {question.questionType === 'checkbox' && (
                    <div className='d-flex flex-column gap-2'>
                      {question.options.map((option, optionIndex) => (
                        <label key={optionIndex}>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            required={question.isRequired}
                            checked={
                              answers[questionIndex]
                                ? answers[questionIndex].includes(option)
                                : false
                            }
                            onChange={(event) =>
                              handleCheckboxChange(questionIndex, optionIndex, event)
                            }
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}
                  {question.questionType === 'date' && (
                    <input
                      type='date'
                      className='form-control'
                      value={answers[questionIndex] || ''}
                      onChange={(event) => handleDateChange(questionIndex, event)}
                    />
                  )}
                  {question.questionType === 'number' && (
                    <input
                      type='number'
                      className='form-control'
                      value={answers[questionIndex] || ''}
                      min={question.min}
                      max={question.max}
                      onChange={(event) => handleNumberChange(questionIndex, event)}
                    />
                  )}
                  {question.questionType === 'dropdown' && (
                    <select
                      className='form-select'
                      value={answers[questionIndex] || ''}
                      onChange={(event) => handleDropdownChange(questionIndex, event)}
                      required={question.isRequired}
                    >
                      <option value=''>Select an option</option>
                      {question.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                  {question.questionType === 'multiplechoice' && (
                    <div className='d-flex flex-column gap-2'>
                      {question.options.map((option, optionIndex) => (
                        <label key={optionIndex}>
                          <input
                            className='form-check-input'
                            type='radio'
                            value={option}
                            name={question._id}
                            checked={answers[questionIndex] === option}
                            onChange={(event) => handleMultipleChoiceChange(questionIndex, event)}
                          />
                          {option}
                        </label>
                      ))}
                    </div>
                  )}
                  {question.questionType === 'meter' && (
                    <input
                      type='range'
                      className='form-control'
                      min={question.min || 0}
                      max={question.max || 100}
                      value={answers[questionIndex] || ''}
                      onChange={(event) => handleMeterChange(questionIndex, event)}
                      required={question.isRequired}
                    />
                  )}
                  {question.questionType === 'yesno' && (
                    <div className='flex gap-5'>
                      <label>
                        <input
                          type='radio'
                          value='yes'
                          checked={answers[questionIndex] === true}
                          onChange={(event) => handleYesNoChange(questionIndex, event)}
                          required={question.isRequired}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          value='no'
                          checked={answers[questionIndex] === false}
                          onChange={(event) => handleYesNoChange(questionIndex, event)}
                          required={question.isRequired}
                        />
                        No
                      </label>
                    </div>
                  )}
                  {question.questionType === 'photo' && (
                    <input
                      type='file'
                      className='form-control'
                      accept='image/*'
                      onChange={(event) => handleFileChange(questionIndex, event)}
                    />
                  )}
                  {question.questionType === 'signature' && (
                    <input
                      type='file'
                      className='form-control'
                      accept='image/*'
                      onChange={(event) => handleFileChange(questionIndex, event)}
                      required={question.isRequired}
                    />
                  )}
                </div>
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-end mb-3 pr-5'>
              <button type='submit' className='btn btn-primary w-[150px]'>
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddReportSubmission
