import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Home from '../documentation/Pages/Home'
import Dashboard from './Dashboard'
import InventoryAdjustmentPage from './Pages/InventoryAdjustmentPage'

const chatBreadCrumbs: Array<PageLink> = [
    {
      title: 'iventory',
      path: '/',
      isSeparator: false,
      isActive: false,
    }
  ]
  
  const InventoryPage = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='items'
            element={
              <>
                <PageTitle breadcrumbs={chatBreadCrumbs}>Items</PageTitle>
                <Home />
              </>
            }
          />
          <Route
            path='adjustments/*'
            element={
              <>
                <PageTitle breadcrumbs={chatBreadCrumbs}>Adjustments</PageTitle>
                <InventoryAdjustmentPage />
              </>
            }
          />
          <Route
            path='dashboard'
            element={
              <>
                <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
                <Dashboard />
              </>
            }
          />
          
         
        
          <Route index element={<Navigate to='/inventory/dashboard' />} />
        </Route>
      </Routes>
    )
  }
  
  export default InventoryPage