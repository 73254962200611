import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {
  getProject,
  getProjectReportsByProjectId,
  postProjectReportSubmission,
} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setAllProjects} from '../../../redux/projects/projectSlice'

const AddProjectReportSubmission = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectList = useSelector((state: any) => state.projects.projects.allProjects)
  const {currentUser} = useAuth()
  const [formData, setFormData] = useState(null)
  const [answers, setAnswers] = useState([])
  const [formOptions, setFormOptions] = useState([])
  const [projects, setProjects] = useState(null)
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedForm, setSelectedForm] = useState('')
  const [forms, setForms] = useState([])

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsData = await getProject(currentUser.organization)
        setProjects(projectsData)
        dispatch(setAllProjects(projectsData))
      } catch (error) {
        console.error('Error fetching projects:', error)
      }
    }
    if (projectList.length === 0) fetchProjects()
  }, [])

  useEffect(() => {
    if (selectedProject) {
      const fetchFormList = async () => {
        try {
          const forms = await getProjectReportsByProjectId(selectedProject)
          setForms(forms)
          console.log('forms Received', forms)
          setFormOptions(forms.map((form) => ({value: form._id, label: form.title})))
        } catch (error) {
          console.error('Error fetching forms:', error)
        }
      }
      fetchFormList()
    }
  }, [selectedProject])

  console.log('form options', formOptions)

  // Function to handle user input for text question
  const handleTextChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for checkbox question
  const handleCheckboxChange = (index, optionIndex, event) => {
    const newAnswers = [...answers]
    if (!newAnswers[index]) {
      newAnswers[index] = []
    }
    if (event.target.checked) {
      newAnswers[index].push(formData.questions[index].options[optionIndex])
    } else {
      const optionToRemove = formData.questions[index].options[optionIndex]
      newAnswers[index] = newAnswers[index].filter((option) => option !== optionToRemove)
    }
    setAnswers(newAnswers)
  }

  // Function to handle user input for date question
  const handleDateChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value // Assuming the input value is stored as a string
    setAnswers(newAnswers)
  }

  // Function to handle user input for number question
  const handleNumberChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value // Assuming the input value is stored as a string
    setAnswers(newAnswers)
  }

  // Function to handle user input for dropdown question
  const handleDropdownChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for multiple choice question
  const handleMultipleChoiceChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for meter question
  const handleMeterChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value
    setAnswers(newAnswers)
  }

  // Function to handle user input for yesno question
  const handleYesNoChange = (index, event) => {
    const newAnswers = [...answers]
    newAnswers[index] = event.target.value === 'yes'
    setAnswers(newAnswers)
  }

  // Function to handle user input for photo and signature question
  const handleFileChange = (index, event) => {
    const newAnswers = [...answers]
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      newAnswers[index] = e.target.result
      setAnswers(newAnswers)
    }
    reader.readAsDataURL(file)
  }
  const handleFormChange = async (selectedFormId) => {
    setSelectedForm(selectedFormId)
    const selectedFormData = forms.find((form) => form._id === selectedFormId)
    setFormData(selectedFormData)
  }
  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const submissionData = {
        form: selectedForm,
        project: selectedProject,
        inspectionDate: new Date(),
        answers: answers.map((answer, index) => ({
          question: formData.questions[index].questionText,
          questionType: formData.questions[index].questionType,
          answer: Array.isArray(answer) ? answer : [answer],
          comment: formData.questions[index].comment || '',
          photo:
            formData.questions[index].questionType === 'photo' ||
            formData.questions[index].questionType === 'signature'
              ? answer
              : '', // Add photo or signature if available
        })),
        createdBy: currentUser._id,
        organization: currentUser.organization,
        company: formData.company,
      }

      const response = await postProjectReportSubmission(submissionData)
      console.log('Answers submitted successfully:', response)
      alert('Answers submitted successfully!')
      setAnswers([])
      setSelectedProject('')
      setSelectedForm('')
    } catch (error) {
      console.error('Error submitting answers:', error)
      alert('Error submitting answers. Please try again later.')
    }
    navigate(-1)
  }
  return (
    <div className='form-container w-[60vw] border border-slate-500 p-5 rounded-[5px]'>
      <div className='mb-3  flex gap-5 items-center'>
        <label htmlFor='projectSelect' className='form-label w-[10%] font-[500]'>
          Select Project:
        </label>
        <select
          id='projectSelect'
          className='form-select w-[90%]'
          value={selectedProject}
          onChange={handleProjectChange}
        >
          <option value=''>Select a project</option>
          {projects?.map((project) => (
            <option key={project?._id} value={project?._id}>
              {project?.projectName}
            </option>
          ))}
        </select>
      </div>
      <div className='mb-3  flex gap-5 items-center'>
        <label htmlFor='formSelect' className='form-label w-[10%] font-[500]'>
          Select Form:
        </label>
        <select
          id='formSelect'
          className='form-select w-[90%]'
          value={selectedForm}
          onChange={(event) => handleFormChange(event.target.value)}
        >
          <option value=''>Select a form</option>
          {formOptions.map((form) => (
            <option key={form.value} value={form.value}>
              {form.label}
            </option>
          ))}
        </select>
      </div>
      <form onSubmit={handleSubmit}>
        {formData && (
          <div className='card'>
            <div className='card-body'>
              <h2 className='border-b-2 p-2 mb-5'>{formData.title}</h2>
              {formData.questions.map((question, questionIndex) => (
                <div key={questionIndex} className='form-group  mb-3 flex gap-5 items-center'>
                  <div className='w-[50%]'>
                    <div className='d-flex flex-column gap-1'>
                      <label className='font-[500] text-lg'>{question.questionText}</label>
                      {question.shortDescription && <p>{question.shortDescription}</p>}
                    </div>
                  </div>
                  <div className='w-[50%]'>
                    {question.questionType === 'text' && (
                      <input
                        type='text'
                        className='form-control'
                        value={answers[questionIndex] || ''}
                        onChange={(event) => handleTextChange(questionIndex, event)}
                        required={question.isRequired}
                      />
                    )}
                    {question.questionType === 'checkbox' && (
                      <div className='d-flex flex-column gap-2'>
                        {question.options.map((option, optionIndex) => (
                          <label key={optionIndex}>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              required={question.isRequired}
                              checked={
                                answers[questionIndex]
                                  ? answers[questionIndex].includes(option)
                                  : false
                              }
                              onChange={(event) =>
                                handleCheckboxChange(questionIndex, optionIndex, event)
                              }
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                    {question.questionType === 'date' && (
                      <input
                        type='date'
                        className='form-control'
                        value={answers[questionIndex] || ''}
                        onChange={(event) => handleDateChange(questionIndex, event)}
                      />
                    )}
                    {question.questionType === 'number' && (
                      <input
                        type='number'
                        className='form-control'
                        value={answers[questionIndex] || ''}
                        min={question.min}
                        max={question.max}
                        onChange={(event) => handleNumberChange(questionIndex, event)}
                      />
                    )}
                    {question.questionType === 'dropdown' && (
                      <select
                        className='form-select'
                        value={answers[questionIndex] || ''}
                        onChange={(event) => handleDropdownChange(questionIndex, event)}
                        required={question.isRequired}
                      >
                        <option value=''>Select an option</option>
                        {question.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    )}
                    {question.questionType === 'multiplechoice' && (
                      <div className='d-flex flex-column gap-2'>
                        {question.options.map((option, optionIndex) => (
                          <label key={optionIndex}>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={option}
                              name={question._id}
                              checked={answers[questionIndex] === option}
                              onChange={(event) => handleMultipleChoiceChange(questionIndex, event)}
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                    )}
                    {question.questionType === 'meter' && (
                      <input
                        type='range'
                        className='form-control'
                        min={question.min || 0}
                        max={question.max || 100}
                        value={answers[questionIndex] || ''}
                        onChange={(event) => handleMeterChange(questionIndex, event)}
                        required={question.isRequired}
                      />
                    )}
                    {question.questionType === 'yesno' && (
                      <div>
                        <label>
                          <input
                            type='radio'
                            value='yes'
                            checked={answers[questionIndex] === true}
                            onChange={(event) => handleYesNoChange(questionIndex, event)}
                            required={question.isRequired}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type='radio'
                            value='no'
                            checked={answers[questionIndex] === false}
                            onChange={(event) => handleYesNoChange(questionIndex, event)}
                            required={question.isRequired}
                          />
                          No
                        </label>
                      </div>
                    )}
                    {question.questionType === 'photo' && (
                      <input
                        type='file'
                        className='form-control'
                        accept='image/*'
                        onChange={(event) => handleFileChange(questionIndex, event)}
                      />
                    )}
                    {question.questionType === 'signature' && (
                      <input
                        type='file'
                        className='form-control'
                        accept='image/*'
                        onChange={(event) => handleFileChange(questionIndex, event)}
                        required={question.isRequired}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-end mb-3 pr-5'>
              <button type='submit' className='btn btn-primary w-[150px]'>
                Submit
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default AddProjectReportSubmission
