// src/store.ts
import {configureStore, combineReducers} from '@reduxjs/toolkit'
import salesReducer from './sales/salesReducer'
import procurementReducer from './procurement/procurementReducer'
import inventoryReducer from './inventory/inventoryReducer'
import hrmReducer from './hrm/hrmReducer'
import accountReducer from './accounts/accountReducer'
import maintenanceReducer from './maintenance/maintenanceReducer'
import taskReducer from './task/taskReducer'
import meetingReducer from './meeting/meetingReducer'
import sprintReducer from './sprints/sprintReducer'
import toolsReducer from './tools/toolsReducer'
import projectReducer from './projects/projectReducer'
import userManagementReducer from './user-management/userManagementReducer'
import roleManagementReducer from './role-management/roleManagementReducer'
import organizationReducer from './organization/organizationReducer'
import superAdminDepartmentReducer from './superAdminDepartment/superAdminDepartmentReducer'
import moduleReducer from './modules/moduleReducer'
import {resetStore} from './resetAction'

const appReducer = combineReducers({
  sales: salesReducer,
  procurement: procurementReducer,
  inventory: inventoryReducer,
  hrm: hrmReducer,
  account: accountReducer,
  maintenance: maintenanceReducer,
  task: taskReducer,
  meeting: meetingReducer,
  sprint: sprintReducer,
  tools: toolsReducer,
  projects: projectReducer,
  userManagement: userManagementReducer,
  roleManagement: roleManagementReducer,
  organization: organizationReducer,
  superAdminDepartment: superAdminDepartmentReducer,
  module: moduleReducer,
})

const rootReducer = (state, action) => {
  if (action.type === resetStore.type) {
    state = undefined // This will reset the state
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

export default store
export type RootState = ReturnType<typeof store.getState>
