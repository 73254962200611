import React, { useEffect, useState } from 'react'
import { IoMdSearch } from 'react-icons/io'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { CgDanger } from 'react-icons/cg'
import { deleteStock } from '../Core/_requests'
import { productCatalog } from '../Core/_categoryRequest'
import { KTIcon } from '../../../../_metronic/helpers'

export default function ProductCatalog() {
  const [dropdown, setDropdown] = useState([])
  const [del, setDel] = useState(false)
  const [selectedItem, setselectedItem] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [originalData, setOriginalData] = useState([])
  const [tableData, setTableData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('All')
  const [masterCheckbox, setMasterCheckbox] = useState(false)
  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const navigate = useNavigate()

  const params = useParams()

  const handleAddProductClick = () => {
    navigate('/product/goods/add-product')
  }

  const fetchData = async () => {
    const products = await productCatalog(params.id)
    console.log('products', products)
    setTableData(products)
    setOriginalData(products)
    setDropdown(Array(products.length).fill(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeleteToggle = (i) => {
    setDel(!del)
    setselectedItem(i)
  }

  const handleDelete = async (i) => {
    const products = await deleteStock(i)
    if (products !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
    fetchData()
  }

  useEffect(() => {
    const filteredData = originalData.filter(
      (item) =>
        item?.productName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.sku?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setTableData((prevTableData) => (searchQuery.trim() === '' ? originalData : filteredData))
  }, [searchQuery, originalData])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  useEffect(() => {
    const statusFilteredData = originalData.filter((item) => item.status === selectedStatus)
    setTableData((prevTableData) => (selectedStatus === 'All' ? originalData : statusFilteredData))
  }, [selectedStatus, originalData])

  const getStatusColor = (status) => {
    if (status == 'Schedule') {
      return <p className='badge badge-light-success'>{status}</p>
    } else if (status == 'Published') {
      return <p className='badge badge-light-primary'>{status}</p>
    } else if (status == 'Inactive') {
      return <p className='badge badge-light-danger'>{status}</p>
    } else {
      return <p className='badge badge-light'>{status}</p>
    }
  }

  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      isChecked: masterCheckbox,
    }))
    setTableData(updatedTableData)
  }, [masterCheckbox])

  return (
    <div className=' p-4 m-4 card card-xxl-stretch'>
      <div className='d-flex flex-wrap justify-between card-header py-5'>
        <div className='d-flex rounded-1 items-center relative space-x-3 '>
          {/* <form
            data-kt-search-element='form'
            className='w-100 position-relative '
            autoComplete='off'
          >
            <KTIcon
              iconName='magnifier'
              className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
            />

            <input
              type='text'
              className='form-control  border-0  ps-10 bg-gray-100'
              name='search'
              placeholder='Search...'
              data-kt-search-element='input'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </form> */}
        </div>
        <div className='d-flex justify-between space-x-4 ms-3'>
          {/* <select
            className='form-select form-select-sm w-md-125px form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            onChange={(e) => setSelectedStatus(e.target.value)}
            value={selectedStatus}
          >
            <option value='All' disabled>
              Status
            </option>

            <option value='All'>All</option>
            <option value='Published'>Published</option>
            <option value='Schedule'>Scheduled</option>
            <option value='Inactive'>Inactive</option>
          </select> */}

          <button
            href='#'
            className='btn btn-sm btn-light-primary w-full whitespace-nowrap'
            onClick={handleAddProductClick}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add Item
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input '
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={masterCheckbox}
                      onChange={() => setMasterCheckbox(!masterCheckbox)}
                    />
                  </div>
                </th> */}
                <th className='min-w-150px'>ITEM</th>
                <th className='text-end min-w-100px'>SKU</th>
                <th className='text-end min-w-100px '>QTY</th>
                <th className='text-end min-w-100px'>PRICE</th>
                <th className='text-end min-w-150px'>CATEGORY</th>
                <th className='text-end min-w-100px'>STATUS</th>
                <th className='text-end min-w-150px '>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, i) => (
                  <tr key={i} className=''>
                    {/* <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={item.isChecked}
                          onChange={() => {
                            const updatedTableData = [...tableData]
                            updatedTableData[i].isChecked = !updatedTableData[i].isChecked
                            setTableData(updatedTableData)
                          }}
                        />
                      </div>
                    </td> */}
                    <td className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={`${PF}${item.thumbnail}`}
                            alt=''
                            className='h-50 align-self-center'
                          />
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={`/product/goods/view-product/${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.productName}
                          </span>
                        </Link>
                      </div>
                    </td>
                    <td className='text-muted fw-semibold text-end'>{item.sku}</td>
                    <td className='text-muted fw-semibold text-end'>{item.inWarehouseQuantity}</td>
                    <td className='text-muted fw-semibold text-end'>{item.price}</td>
                    <td>
                      {/* <div className='rating justify-content-end'>
                        <div className='rating-label me-2 checked'>
                          <i className='bi bi-star-fill fs-5'></i>
                        </div>
                        <div className='rating-label me-2 checked'>
                          <i className='bi bi-star-fill fs-5'></i>
                        </div>
                        <div className='rating-label me-2 checked'>
                          <i className='bi bi-star-fill fs-5'></i>
                        </div>
                        <div className='rating-label me-2'>
                          <i className='bi bi-star-fill fs-5'></i>
                        </div>
                        <div className='rating-label me-2'>
                          <i className='bi bi-star-fill fs-5'></i>
                        </div>
                      </div> */}
                    </td>

                    <td className='text-end'>{getStatusColor(item.status)}</td>
                    <td className='relative '>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          onClick={() => handleClick(i)}
                          className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                        >
                          Actions <RiArrowDropDownLine />
                        </button>
                      </div>
                      {dropdown[i] && (
                        <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 rounded p-3 z-50 space-y-2'>
                          <Link to={`/product/edit-product/${item._id}`}>
                            <button className='hover:bg-lime-50 p-2  text-start rounded'>
                              Edit
                            </button>
                          </Link>
                          <button
                            className='hover:bg-lime-50 p-2 text-start rounded'
                            onClick={() => handleDeleteToggle(i)}
                            data-bs-toggle='modal'
                            data-bs-target='#myModal'
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div class='modal' id='myModal'>
        <div class='modal-dialog modal-dialog-centered '>
          <div class='modal-content'>
            <div class='modal-header'>
              <h4 class='modal-title mx-56 text-yellow-500 '>
                <CgDanger size={100} />
              </h4>
              <button type='button' class='btn-close' data-bs-dismiss='modal'></button>
            </div>

            <div class='modal-body text-center fs-6'>
              Are you sure you want to delete {tableData[selectedItem]?.productName} ?
            </div>

            <div class='modal-footer text-center mx-auto'>
              <button
                type='button'
                class='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(tableData[selectedItem]?._id)}
              >
                Delete
              </button>
              <button
                type='button'
                class='btn btn-light '
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
