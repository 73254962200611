import React, {useEffect, useRef, useState} from 'react'
import {editTarget, getProjectById, getTeamsByProjectId} from '../core/_requests'
import {useParams} from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
import {KTIcon} from '../../../../_metronic/helpers'

export default function TargetEdit({formData, editTaskId, getTargets}: any) {
  // const [data, setData] = useState([])
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const [users, setUsers] = useState([])
  const params = useParams()
  const [itemsList, setItemsList] = useState([
    {
      label: '',
      quantity: 0,
      price: 0,
      value: '',
    },
  ])
  const [item, setItem] = useState([
    {
      item: '',
      quantity: 0,
      maxQuantity: 0,
      price: 0,
    },
  ])
  const [manpower, setManpower] = useState({})

  const [data, setData] = useState({
    title: '',
    assignedTo: [],
    user: '',
    startDate: new Date(),
    dueDate: new Date(),
    targetDetails: '',
    tags: '',
    targetBudget: 0,
    targetNotifications: {
      email: false,
      phone: false,
    },
    hoursPerDay: 0,
  })

  useEffect(() => {
    if (formData) {
      setData({
        ...formData,
        assignedTo: formData?.assignedTo?.map((item) => item?._id),
      })
      setItem(
        formData?.items?.map((item) => {
          return {
            item: item?.itemId?._id,
            quantity: item?.quantity,
            // maxQuantity: item?.itemId?.quantity,
            price: Number(item?.price),
          }
        })
      )
    }
  }, [formData])

  const fetchData = async () => {
    try {
      const res = await getProjectById(params.id)
      // setData(res)
      const usersData = res?.assignedAgents
      setUsers(usersData)
      const itemsData = res?.items?.map((item) => {
        return {
          label: item?.itemId?.productName,
          quantity: item?.quantity,
          price: item?.price,
          value: item?.itemId?._id,
        }
      })
      setItemsList(itemsData)
      console.log('data', res)
    } catch (error) {
      console.error('Error fetching the employee details', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  console.log('formdata', data)
  const [teamMembers, setTeamMembers] = useState([])

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const fetchTeamMembers = async () => {
    const res = await getTeamsByProjectId(params.id)
    console.log(res)
    const teamMembersData = res?.map((team) => {
      return {
        label: team.name,
        value: team?.employee?._id,
        // hourlyPay: team.hourlyPay,
      }
    })
    setTeamMembers(teamMembersData)
    let manpowerData = {}
    await res?.forEach((team) => {
      manpowerData[team?.employee?._id] = team?.employee?.hourlyPay
    })
    setManpower(manpowerData)
  }

  const handleEdit = async (e) => {
    e.preventDefault()
    const updatedData = {
      ...data,
      items:
        item?.length === 1
          ? item[0]?.item === ''
            ? []
            : item?.map((item) => {
                return {
                  itemId: item.item,
                  quantity: item.quantity,
                  price: item.price,
                }
              })
          : item?.map((item) => {
              return {
                itemId: item.item,
                quantity: item.quantity,
                price: item.price,
              }
            }),
    }
    console.log('formdata', updatedData)
    const res = await editTarget(editTaskId, updatedData)
    if (res) {
      getTargets()
      closeModalRef.current?.click()
    }
  }

  useEffect(() => {
    fetchTeamMembers()
  }, [])

  const handleAddItem = () => {
    setItem([...item, {item: '', quantity: 0, maxQuantity: 0, price: 0}])
  }

  const handleRemoveItem = (index) => {
    if (item.length === 1) {
      return
    }
    const list = [...item]
    list.splice(index, 1)
    setItem(list)
  }

  useEffect(() => {
    let budget = item.reduce((acc, item) => acc + Number(item.price * item.quantity), 0)
    budget += data.assignedTo?.reduce((acc, id) => acc + Number(manpower[id]) * data.hoursPerDay, 0)
    setData({
      ...data,
      targetBudget: budget,
    })
  }, [item, data.assignedTo, data.hoursPerDay])

  return (
    <div className='modal-dialog modal-dialog-centered mw-650px'>
      <div className='modal-content rounded'>
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <i className='ki-duotone ki-cross fs-1'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </div>
        </div>

        <div className='modal-body scroll-y px-10 px-lg-15 pt-0 pb-15'>
          <form>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>Edit Target</h1>

              <div className='text-muted fw-semibold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='fw-bold link-primary'>
                  Project Guidelines
                </a>
                .
              </div>
            </div>

            <div className='d-flex flex-column mb-8 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span className='required'>Target Title</span>
                <span
                  className='ms-1'
                  data-bs-toggle='tooltip'
                  title='Specify a target name for future usage and reference'
                >
                  <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                </span>
              </label>

              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Enter Target Title'
                name='title'
                value={data.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className='row g-9 mb-8'>
              <div className='col-md-6 fv-row'>
                <label className='required fs-6 fw-semibold mb-2'>Assign Manpower</label>
                {/* <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Select a Team Member'
                  name='assignedTo'
                  value={data.assignedTo}
                  onChange={(e) => setData({...data, assignedTo: e.target.value})}
                >
                  <option value=''>Select user...</option>
                  {teamMembers && teamMembers.length > 0 ? (
                    teamMembers.map((member) => (
                      <option key={member._id} value={member._id}>
                        {member.name}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>
                      No team members available
                    </option>
                  )}
                </select> */}
                <Select
                  isMulti
                  name='assignedTo'
                  options={teamMembers}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  value={data.assignedTo.map((id) => {
                    return {
                      label: teamMembers.find((member) => member.value === id)?.label,
                      value: id,
                    }
                  })}
                  onChange={(e) => setData({...data, assignedTo: e.map((item) => item.value)})}
                />
              </div>

              <div className='col-md-6 fv-row'>
                <label className='required fs-6 fw-semibold mb-2'>Assign User</label>
                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-hide-search='true'
                  data-placeholder='Select a User'
                  name='user'
                  value={data.user}
                  onChange={(e) => setData({...data, user: e.target.value})}
                >
                  <option value=''>Select user...</option>

                  {users && users.length > 0 ? (
                    users.map((member) => (
                      <option key={member._id} value={member._id}>
                        {member.fullName}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>
                      No Users available
                    </option>
                  )}
                </select>
              </div>
            </div>

            <div className='row g-9 mb-8'>
              <div className='col-md-6 fv-row'>
                <label className='required fs-6 fw-semibold mb-2'>Start Date</label>

                <div className='position-relative d-flex align-items-center'>
                  <i className='ki-duotone ki-calendar-8 fs-2 position-absolute mx-4'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                  </i>

                  <Flatpickr
                    value={data.startDate}
                    className='form-control form-control-solid ps-10'
                    onChange={(date) => setData({...data, startDate: date})}
                  />
                </div>
              </div>
              <div className='col-md-6 fv-row'>
                <label className='required fs-6 fw-semibold mb-2'>Due Date</label>

                <div className='position-relative d-flex align-items-center'>
                  <i className='ki-duotone ki-calendar-8 fs-2 position-absolute mx-4'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                    <span className='path6'></span>
                  </i>

                  <Flatpickr
                    value={data.dueDate}
                    className='form-control form-control-solid ps-10'
                    onChange={(date) => setData({...data, dueDate: date})}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex flex-column mb-8'>
              <label className='fs-6 fw-semibold mb-2'>Target Details</label>
              <textarea
                className='form-control form-control-solid'
                rows={3}
                name='targetDetails'
                placeholder='Type Target Details'
                value={data.targetDetails}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className='d-flex flex-column mb-8'>
              <label className='fs-6 fw-semibold mb-2'>Items</label>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800 text-start'>
                    <th className='min-w-125px'>Item</th>
                    <th className='min-w-125px'>Qty</th>
                    <th className='min-w-125px'>Amount</th>
                    <th className='min-w-125px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {item?.map((item, index) => (
                    <tr>
                      <td>
                        <Select
                          name='item'
                          options={itemsList}
                          value={itemsList.find((i) => i.value === item.item)}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          onChange={(e) =>
                            setItem((prev) => {
                              const newItems = [...prev]
                              newItems[index] = {
                                ...newItems[index],
                                item: e.value,
                                maxQuantity: e.quantity,
                                price: e.price,
                              }
                              return newItems
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          className='form-control form-control-solid'
                          placeholder='Enter Quantity'
                          name='quantity'
                          min={0}
                          max={item.maxQuantity}
                          value={item.quantity}
                          onChange={(e) =>
                            setItem((prev) => {
                              const newItems = [...prev]
                              newItems[index] = {
                                ...newItems[index],
                                quantity: Number(e.target.value),
                              }
                              return newItems
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type='number'
                          className='form-control form-control-solid'
                          placeholder='0.00'
                          value={item.quantity * item.price}
                          readOnly
                        />
                      </td>
                      <td>
                        <button
                          type='button'
                          className='btn btn-sm btn-light btn-active-light-primary'
                          onClick={handleAddItem}
                        >
                          <KTIcon iconName='plus' className='ms-2' />
                        </button>
                        <button
                          type='button'
                          className='btn btn-sm btn-light btn-active-light-primary'
                          onClick={() => handleRemoveItem(index)}
                        >
                          <KTIcon iconName='trash' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className='row g-9 mb-8'>
              <div className='col-md-6 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>No. of Hours per day</span>
                </label>

                <input
                  type='number'
                  className='form-control form-control-solid'
                  placeholder='Enter Target Budget'
                  name='hrs per day'
                  min={0}
                  max={24}
                  value={data.hoursPerDay}
                  onChange={(e) => {
                    setData({...data, hoursPerDay: Number(e.target.value)})
                  }}
                />
              </div>
            </div>
            <div className='row g-9 mb-8'>
              <div className='col-md-6 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Target Budget</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Specify a target priorty'>
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <input
                  type='number'
                  className='form-control form-control-solid'
                  placeholder='Enter Target Budget'
                  name='targetBudget'
                  min={0}
                  value={data.targetBudget}
                  onChange={(e) => {
                    setData({...data, targetBudget: Number(e.target.value)})
                  }}
                />
              </div>

              <div className='col-md-6 fv-row'>
                <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Tags</span>
                  <span className='ms-1' data-bs-toggle='tooltip' title='Specify a target priorty'>
                    <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </span>
                </label>

                <select
                  className='form-select form-select-solid'
                  data-control='select2'
                  data-placeholder='Select a Tag'
                  value={data.tags}
                  onChange={(e) => setData({...data, tags: e.target.value})}
                >
                  <option value=''>Select a Tag...</option>
                  <option value='important'>Important</option>
                  <option value='medium'>Medium</option>
                  <option value='flexible'>Flexible</option>
                </select>
              </div>
            </div>

            <div className='d-flex flex-stack mb-8'>
              <div className='me-5'>
                <label className='fs-6 fw-semibold'>Adding Users by Team Members</label>
                <div className='fs-7 fw-semibold text-muted'>
                  If you need more info, please check budget planning
                </div>
              </div>

              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='1' checked={true} />
                <span className='form-check-label fw-semibold text-muted'>Allowed</span>
              </label>
            </div>

            <div className='mb-15 fv-row'>
              <div className='d-flex flex-stack'>
                <div className='fw-semibold me-5'>
                  <label className='fs-6'>Notifications</label>
                  <div className='fs-7 text-muted'>Allow Notifications by Phone or Email</div>
                </div>

                <div className='d-flex align-items-center'>
                  <label className='form-check form-check-custom form-check-solid me-10'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      checked={data.targetNotifications.email}
                      onChange={(e) =>
                        setData({
                          ...data,
                          targetNotifications: {
                            ...data.targetNotifications,
                            email: e.target.checked,
                          },
                        })
                      }
                    />
                    <span className='form-check-label fw-semibold'>Email</span>
                  </label>

                  <label className='form-check form-check-custom form-check-solid'>
                    <input
                      className='form-check-input h-20px w-20px'
                      type='checkbox'
                      checked={data.targetNotifications.phone}
                      onChange={(e) =>
                        setData({
                          ...data,
                          targetNotifications: {
                            ...data.targetNotifications,
                            phone: e.target.checked,
                          },
                        })
                      }
                    />
                    <span className='form-check-label fw-semibold'>Phone</span>
                  </label>
                </div>
              </div>
            </div>

            <div className='text-center'>
              <button
                type='button'
                id='kt_modal_new_target_cancel'
                data-bs-dismiss='modal'
                className='btn btn-light me-3'
                ref={closeModalRef}
              >
                Cancel
              </button>
              <button type='button' className='btn btn-primary' onClick={handleEdit}>
                <span className='indicator-label'>Submit</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
