import React, {useEffect, useState} from 'react'
import {getParentAccounts, postAccount} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

const AddAccount = () => {
  const {currentUser} = useAuth()
  const [parentAccountName, setParentAccountName] = useState('')
  const [formData, setFormData] = useState({
    accountType: 'otherasset',
    accountName: '',
    subAccount: false,
    parentAccount: '',
    accountCode: '',
    accountNumber: '',
    currency: 'AED',
    description: '',
    watchList: false,
  })
  const [subAccount, setSubAccount] = useState(false)
  const [parentAccountsList, setParentAccountsList] = useState<any>({})
  const [parentAccountToggle, setParentAccountToggle] = useState(false)
  const [accountNumberToggle, setAccountNumberToggle] = useState(false)
  const navigate = useNavigate()

  const fetchParentAccounts = async () => {
    const res = await getParentAccounts(currentUser?.organization)
    console.log(res)
    if (res) {
      setParentAccountsList(res)
    }
  }

  useEffect(() => {
    fetchParentAccounts()
  }, [currentUser])

  const handleChange = (e) => {
    const {name, value} = e.target
    console.log(value)
    const updatedFormData = {...formData}
    updatedFormData[name] = value
    setFormData(updatedFormData)
    if (
      name === 'accountType' &&
      (value === 'othercurrentasset' ||
        value === 'cash' ||
        value === 'fixedasset' ||
        value === 'othercurrentliability' ||
        value === 'equity' ||
        value === 'income' ||
        value === 'expense' ||
        value === 'costofgoodssold')
    ) {
      setSubAccount(true)
    } else if (name === 'accountType') {
      setSubAccount(false)
      setParentAccountToggle(false)
      setFormData((prev) => {
        const updatedData = {...prev}
        updatedData.parentAccount = ''
        return updatedData
      })
    }
    if (name === 'accountType' && value === 'bank') {
      setSubAccount(false)
      setAccountNumberToggle(true)
    }
  }

  const handleChecked = (e) => {
    const {name, checked} = e.target
    const updatedFormData = {...formData}
    updatedFormData[name] = checked
    setFormData(updatedFormData)
    if (name === 'subAccount' && checked === true) {
      setParentAccountToggle(true)
    } else {
      setParentAccountToggle(false)
      setFormData((prev) => {
        const updatedData = {...prev}
        updatedData.parentAccount = ''
        return updatedData
      })
    }
  }
  //   console.log(formData.accountType)
  // console.log(parentAccountName)
  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(formData)
    const data = {
      ...formData,
      parentAccount: formData?.parentAccount !== '' ? formData?.parentAccount : null,
      parentAccountName,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    console.log(data)
    const res = await postAccount(data)
    if (res) {
      navigate('/account/chartofaccounts')
    }
  }

  return (
    <div className='card p-4'>
      <div className='card-header pt-4'>
        <h3>Create Account</h3>
      </div>
      <div className='card-body'>
        <form>
          <div className='d-flex flex-col gap-2'>
            <div className=''>
              <p>
                <span className='required'>Account Type</span>
              </p>
              <select
                name='accountType'
                onChange={handleChange}
                className='form-control form-control-solid w-50'
              >
                <optgroup label='Assets'>
                  <option value={'otherasset'}>Other Asset</option>
                  <option value={'othercurrentasset'}>Other Current Asset</option>
                  <option value={'cash'}>Cash</option>
                  <option value={'bank'}>Bank</option>
                  <option value={'fixedasset'}>Fixed Asset</option>
                  <option value={'stock'}>Stock</option>
                  <option value={'paymentclearing'}>Payment Clearing</option>
                </optgroup>
                <optgroup label='Liability'>
                  <option value={'othercurrentliability'}>Other Current Liability</option>
                  <option value={'creditcard'}>Credit Card</option>
                  <option value={'longtermliability'}>Long term Liability</option>
                  <option value={'otherliability'}>Other Liability</option>
                </optgroup>
                <optgroup label='Equity'>
                  <option value={'equity'}>Equity</option>
                </optgroup>
                <optgroup label='Income'>
                  <option value={'income'}>Income</option>
                  <option value={'otherincome'}>Other Income</option>
                </optgroup>
                <optgroup label='Expense'>
                  <option value={'expense'}>Expense</option>
                  <option value={'costofgoodssold'}>Cost of Goods Sold</option>
                  <option value={'otherexpense'}>Other Expense</option>
                </optgroup>
              </select>
            </div>
            <div className=''>
              <p>
                <span className='required'>Account Name</span>
              </p>
              <input
                type='text'
                name='accountName'
                value={formData?.accountName}
                className='form-control form-control-solid w-50'
                onChange={handleChange}
              />
            </div>
            {subAccount && (
              <>
                <div>
                  <input
                    type='checkbox'
                    name='subAccount'
                    className='form-check-input w-20px h-20px me-4'
                    onChange={handleChecked}
                  />
                  <span>Make this a sub-account</span>
                </div>
                {parentAccountToggle && (
                  <div className=''>
                    <p>
                      <span className='required'>Parent Account</span>
                    </p>
                    <select
                      name='parentAccount'
                      onChange={(e) => {
                        setParentAccountName(
                          parentAccountsList[formData.accountType]?.find(
                            (item) => item.id === e.target.value
                          )?.name
                        )
                        handleChange(e)
                      }}
                      className='form-control form-control-solid w-50'
                    >
                      <option value=''>Select Parent Account</option>
                      {parentAccountsList[formData.accountType]?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            )}
            <div className=''>
              <p>Account Code</p>
              <input
                type='text'
                name='accountCode'
                value={formData.accountCode}
                onChange={handleChange}
                className='form-control form-control-solid w-50'
              />
            </div>
            {accountNumberToggle && (
              <>
                <div>
                  <p>Account Number</p>
                  <input
                    type='text'
                    name='accountNumber'
                    value={formData.accountNumber}
                    onChange={handleChange}
                    className='form-control form-control-solid w-50'
                  />
                </div>
                {/* <div>
                  <p>Currency</p>
                  <select name="" id=""></select>
                </div> */}
              </>
            )}
            <div className=''>
              <p>Description</p>
              <input
                type='text'
                name='description'
                value={formData.description}
                onChange={handleChange}
                className='form-control form-control-solid w-50'
              />
            </div>
            {!accountNumberToggle && (
              <div className='my-2'>
                <input
                  type='checkbox'
                  name='watchList'
                  onChange={handleChecked}
                  className='form-check-input w-20px h-20px me-4'
                />
                <span>Add to watchlist on my Dashoard</span>
              </div>
            )}
          </div>
          <button type='button' className='btn btn-success my-3' onClick={handleSubmit}>
            Save
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddAccount
