import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  inventoryAdjustment: [],
  loading: true,
}

const inventoryAdjustmentSlice = createSlice({
  name: 'inventoryAdjustment',
  initialState,
  reducers: {
    setInventoryAdjustment(state, action) {
      state.inventoryAdjustment = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
})

export const {setInventoryAdjustment, setLoading} = inventoryAdjustmentSlice.actions
export default inventoryAdjustmentSlice.reducer
