import React from 'react'
import {Route, Routes} from 'react-router-dom'
import SprintList from './component/SprintList'
import SprintDetails from './component/SprintDetail'

const SprintPage = () => {
  return (
    <Routes>
      <Route path='/' element={<SprintList />} />
      <Route path=':projectid' element={<SprintDetails />} />
    </Routes>
  )
}

export default SprintPage
