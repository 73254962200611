import {combineReducers} from '@reduxjs/toolkit'

import customerReducer from './customer/customerSlice'
import orderManagementReducer from './orderManangement/orderManagementSlice'
import quotationReducer from './quotation/quotationSlice'
import salesOrderReducer from './salesOrder/salesOrderSlice'
import deliveryNoteReducer from './deliveryNote/deliveryNote'
import invoiceReducer from './invoices/invoice'
import paymentReceivedReducer from './paymentReceived/paymentReceivedSlice'
import salesPerformanceReducer from './salesPerformance/salesPerformanceSlice'
import quotationApprovalReducer from './approval/quotation/quotationApprovalSlice'
import invoiceApprovalReducer from './approval/invoice/invoiceApprovalSlice'
import salesOrderApprovalReducer from './approval/salesOrder/salesOrderApprovalSlice'
import deliveryNoteApprovalReducer from './approval/deliveryNote/deliveryNoteApprovalSlice'
import paymentReceivedApprovalReducer from './approval/paymentReceived/paymentReceivedApprovalSlice'


const rootReducer = combineReducers({
     customer: customerReducer,
     orderManagement:orderManagementReducer,
     quotation:quotationReducer,
     salesOrder:salesOrderReducer,
     deliveryNote:deliveryNoteReducer,
     invoice:invoiceReducer,
     paymentReceived:paymentReceivedReducer,
     salesPerformance:salesPerformanceReducer,
     quotationApproval:quotationApprovalReducer,
     invoiceApproval:invoiceApprovalReducer,
     salesOrderApproval:salesOrderApprovalReducer,
     deliveryNoteApproval:deliveryNoteApprovalReducer,
     paymentReceivedApproval:paymentReceivedApprovalReducer

   })
   export default rootReducer
