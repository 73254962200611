import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {useAuth} from '../../../modules/auth'
import {getTargetByProjectId} from '../core/_requests'
import Swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import {useThemeMode} from '../../../../_metronic/partials'
import {KTUtil} from '../../../../_metronic/assets/ts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

const TargetCalendar = () => {
  const params = useParams()
  const projectid = params.id
  const calendarComponentRef = useRef(null)
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [calendarEvents, setCalendarEvents] = useState([])
  const [isCalendar, setIsCalendar] = useState(true)
  const [projectName, setProjectName] = useState('')
  const [ganttChart, setGanttChart] = useState<any>([
    {
      name: 'Project',
      data: [
        {
          x: 'Project',
          y: [new Date().getTime(), new Date().getTime()],
        },
      ],
    },
  ])

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, ganttChart, isCalendar])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-primary')
    var baseLightColor = KTUtil.getCSSVariableValue('--bs-success')
    var secondaryColor = KTUtil.getCSSVariableValue('--bs-info')

    return {
      series: ganttChart,
      chart: {
        type: 'rangeBar',
        fontFamily: 'inherit',
        height: height,
        toolbar: {
          show: false,
        },
      },
      colors: [baseColor, secondaryColor, baseLightColor],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '100%',

          borderRadius: 5,
          distributed: false,
          dataLabels: {
            position: 'top',
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: 'horizontal',
          },
        },
      },
      xaxis: {
        type: 'datetime',
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        // show: false,
        axisBorder: {
          show: false,
        },
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getProjectData = async () => {
    try {
      const response = await getTargetByProjectId(projectid)
      console.log('res', response)
      const data = response?.map((item) => ({
        title: item?.title,
        start: item?.dueDate,
        description: item?.targetDetails,
        budget: item?.targetBudget,
        color: item?.tags === 'important' ? 'red' : item?.tags === 'medium' ? 'orange' : 'green',
      }))
      setCalendarEvents(data)
      console.log('calendarEvents', calendarEvents)
      const data1 = response?.map((item) => ({
        name: item?.title,
        data: [
          {
            x: item?.title,
            y: [new Date(item?.startDate).getTime(), new Date(item?.dueDate).getTime()],
          },
        ],
      }))
      setGanttChart(data1)
      console.log('setGanttChart', ganttChart)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getProjectData()
  }, [permissions])

  const handleDateSelect = (selectInfo) => {
    const {start, end} = selectInfo
    // Do something with the selected date range
  }
  console.log('calendarEvents', calendarEvents)
  console.log('setGanttChart', ganttChart)

  const handleShowModal = (e) => {
    Swal.fire({
      title: e.event.title.toUpperCase(),
      html: `
      <p>${e.event.extendedProps.description}</p>
      <p><strong>Budget:</strong> ${e.event.extendedProps.budget}</p>
      `,
      icon: 'info',
      confirmButtonText: 'Close',
    })
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center justify-content-end gap-4'>
          <button
            className={`btn btn-outline-primary me-2 ${isCalendar ? 'active' : ''}`}
            onClick={() => setIsCalendar(true)}
          >
            Calendar
          </button>
          <button
            className={`btn btn-outline-primary ${!isCalendar ? 'active' : ''}`}
            onClick={() => setIsCalendar(false)}
          >
            Gantt Chart
          </button>
        </div>
        {isCalendar ? (
          <div className=''>
            <Card>
              <Card.Body>
                <div className='demo-app-calendar' id='mycalendartest'>
                  <FullCalendar
                    eventTimeFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      meridiem: 'short',
                    }}
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      listPlugin,
                      multiMonthPlugin,
                    ]}
                    initialView={'dayGridMonth'}
                    headerToolbar={{
                      start: 'today prev,next',
                      center: 'title',
                      end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                    }}
                    selectable={true}
                    select={handleDateSelect}
                    rerenderDelay={10}
                    eventDurationEditable={false}
                    weekends={true}
                    ref={calendarComponentRef}
                    events={calendarEvents}
                    eventClick={handleShowModal}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        ) : (
          <div className={`card`}>
            <div className='card-body'>
              {/* begin::Chart */}
              <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '500px'}} />
              {/* end::Chart */}
            </div>
            {/* end::Body */}
          </div>
        )}
      </div>
    </div>
  )
}

export default TargetCalendar
