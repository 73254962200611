import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {AttendanceWrapper} from './Pages/AttendanceWrapper'
import {ApplyLeave} from './Pages/ApplyLeave'

export default function LeavePage() {
  return (
    <Routes>
      <Route path='/' element={<AttendanceWrapper />} />
      <Route path='/applyLeave' element={<ApplyLeave />} />
    </Routes>
  )
}
