export const reportsMenus = [
  {
    title: 'Business Overview',
    items: [
      {
        name: 'Profit and Loss',
        link: 'profitandloss',
      },
      {
        name: 'Cash Flow Statement',
        link: 'cfstatement',
      },
      {
        name: 'Balance Sheet',
        link: 'balancesheet',
      },
      // {
      //   name: 'Business Performance Ratios',
      //   link: 'businessperformanceratio',
      // },
      {
        name: 'Movement of Equity',
        link: 'movementofequity',
      },
    ],
  },
  {
    title: 'Sales',
    items: [
      {
        name: 'Sales by Customer',
        link: 'salesbycustomer',
      },
      {
        name: 'Sales by Item',
        link: 'salesbyitem',
      },
      {
        name: 'Sales by Sales Person',
        link: 'salesbysp',
      },
    ],
  },
  {
    title: 'Receivables',
    items: [
      {
        name: 'Customer Balances',
        link: 'customerbalances',
      },
      // {
      //   name: 'AR Aging Summary',
      //   link: 'invoiceaging',
      // },
      // {
      //   name: 'AR Aging Details',
      //   link: 'invoiceaging/details',
      // },
      {
        name: 'Invoice Details',
        link: 'invoicedetails',
      },
      {
        name: 'Delivery Challan Details',
        link: 'dcdetails',
      },
      {
        name: 'Quote Details',
        link: 'quotesdetails',
      },
      {
        name: 'Customer Balance Summary',
        link: 'customerbalancesummary',
      },
      {
        name: 'Receivable Summary',
        link: 'recevablesummary',
      },
      {
        name: 'Receivable Details',
        link: 'receivabledetails',
      },
    ],
  },
  {
    title: 'Payments Received',
    items: [
      {
        name: 'Payments Received',
        link: 'paymentsreceived',
      },
      {
        name: 'Time to Get Paid',
        link: 'timetopay',
      },
      {
        name: 'Credit Note Details',
        link: 'cndetails',
      },
      {
        name: 'Refund History',
        link: 'refundhistory',
      },
    ],
  },
  {
    title: 'Payables',
    items: [
      {
        name: 'Vendor Balance Summary',
        link: 'vendorbalancesummary',
      },
      {
        name: 'Payable Summary',
        link: 'payablesummary',
      },
      {
        name: 'Payable Details',
        link: 'payabledetails',
      },
    ],
  },
  {
    title: 'Purchases and Expenses',
    items: [
      {
        name: 'Expense Details',
        link: 'expensedetails',
      },
      {
        name: 'Expenses by Category',
        link: 'expbycategory',
      },
      {
        name: 'Expenses by Customer',
        link: 'expbycustomer',
      },
      {
        name: 'Expenses by Vendor',
        link: 'expbyvendor',
      },
      // {
      //   name: 'Billable Expense Details',
      //   link: 'billableexpensedetails',
      // },
    ],
  },
  {
    title: 'Accountant',
    items: [
      {
        name: 'Account Transactions',
        link: 'accounttxns',
      },
      {
        name: 'Account Type Summary',
        link: 'accounttypesummary',
      },
      {
        name: 'General Ledger',
        link: 'generalledger',
      },
      {
        name: 'Detailed General Ledger',
        link: 'detailedgeneralledger',
      },
      {
        name: 'Journal Report',
        link: 'journals',
      },
      {
        name: 'Trial Balance',
        link: 'trialbalance',
      },
    ],
  },
]
