import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getInvoicebyids} from '../../Core/_requests'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

function InvoiceSecond({companyLogo}) {
  const id = useParams().id
  const [data, setdata] = useState<any>()
  
  useEffect(() => {
    const getinvoicedetails = async () => {
      await getInvoicebyids(id).then((res) => {
        console.log('data', res)
        setdata(res)
      })
    }

    getinvoicedetails()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='container p-5 px-20 app-main flex-column flex-row-fluid ' id='kt_app_main'>
      {/* Header Table */}
      <div id='pdf-content' className='app-content print-content flex-column-fluid '>
        <table className='table table-responsive table-bordered mb-4 border-gray-400 border-2'>
          <tbody>
            <tr className=' '>
              <td className='text-center align-middle bg-primary w-1/2'>
                <h1 className='m-0 fs-2qx mw-100  text-white'>INVOICE</h1>
              </td>

              <td className='text-center bg-light-primary py-5'>
                {companyLogo ? (
                  <img
                    alt='Logo'
                    src={URL.createObjectURL(companyLogo)}
                    className='img-fluid display-block mx-auto mw-75 min-w-25'
                  />
                ) : (
                  <img
                    alt='Logo'
                    src='/media/logo.png'
                    className='img-fluid bg-dark rounded min-w-25 mw-50 p-3 mx-auto display-block'
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {/* Invoice Info Table */}
        <div className='table-responsive flex-grow-1'>
          <table className='table table-bordered mb-4 border-2 border-gray-300 overflow-sm-hidden overflow-auto whitespace-nowrap'>
            <tbody>
              <tr className='w-full '>
                <td className='w-1/3 fs-5'>
                  <strong className='fs-3'>Billing Address:</strong>
                  <br />
                  {data?.customer.primaryContact.firstName} {data?.customer.primaryContact.lastName}
                  <br />
                  {data?.customer.billingAddress.addressLine1}, {data?.customer.billingAddress.city}
                  <br />
                  {data?.customer.billingAddress.country}
                </td>
                <td className='w-1/3 text-start fs-5'>
                  <p>
                    <strong className='text-muted fs-7'>Order ID:</strong> <br />#{data?.orderNo}
                  </p>
                  <p>
                    <strong className='text-muted fs-7'>Invoice Date:</strong>
                    <br /> {formatCreatedAt(data?.createdAt)}
                  </p>
                  <p>
                    <strong className='text-muted fs-7'>Invoice ID:</strong>
                    <br /> #{data?.id}
                  </p>
                </td>
                <td className='w-1/3 fs-5 fw-bold'>
                  <p>
                    <strong className='text-muted fs-7'>Status</strong> <br />
                    <span className='fs-3'>
                      {data?.status === 'rejected' ? (
                        <span className='badge badge-light-danger'>Rejected</span>
                      ) : data?.status === 'pending' ? (
                        <span className='badge badge-light-primary'>Pending</span>
                      ) : (
                        <span className='badge badge-light-success'>Approved</span>
                      )}
                    </span>
                  </p>
                </td>
              </tr>
              <tr className='w-full'>
                <td className='fs-5'>
                  <strong className='fs-3'>Shipping Address:</strong>
                  <br />
                  {data?.customer.primaryContact.firstName} {data?.customer.primaryContact.lastName}
                  <br />
                  {data?.customer.billingAddress.addressLine1}, {data?.customer.billingAddress.city}
                  <br />
                  {data?.customer.billingAddress.country}
                </td>
                <td className='fs-7'>
                  <strong className='fs-3 '>Order Received By:</strong>
                  <br />
                  <div className='mb-6 mt-4'>
                    <div className='fw-semibold text-gray-600 fs-7'>Employee Name:</div>
                    <div className='fw-bold text-gray-800 '>
                      {data?.employee?.firstName} {data?.employee?.lastName}
                    </div>
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='mb-6'>
                    <div className='fw-semibold text-gray-600 fs-7'>Mobile Number:</div>
                    <div className='fw-bold text-gray-800 '>{data?.employee?.phoneNo}</div>
                  </div>
                  <div className='mb-6'>
                    <div className='fw-semibold text-gray-600 fs-7'>Email Address:</div>
                    <div className='fw-bold text-gray-800 '>{data?.employee?.email}</div>
                  </div>
                </td>
                <td className='fs-5'>
                  {data?.paymentDetails && (
                    <div className='mb-20 '>
                      <h6 className='mb-8 fw-bolder text-gray-800 text-hover-primary'>
                        PAYMENT DETAILS
                      </h6>
                      {/* end::Title */}
                      {/* begin::Item */}
                      <div className='mb-6'>
                        <div className='fw-semibold text-gray-600 fs-7'>Payment Mode:</div>
                        <div className='fw-bold text-gray-800 fs-6'>Cash</div>
                      </div>
                      {/* end::Item */}
                      {/* begin::Item */}
                      <div className='mb-6 '>
                        <div className='fw-semibold text-gray-600 fs-7'>Deposit To:</div>
                        <div className='fw-bold text-gray-800 fs-6'>
                          Nl24IB
                          <br />
                          AMB NLANBZTC
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Items Table */}
        <div className='table-responsive'>
          <table className='table  table-bordered mb-4 border-2 border-gray-300  overflow-sm-hidden overflow-x whitespace-nowrap'>
            <thead>
              <tr className='bg-secondary border-white'>
                <th>Description</th>
                <th className='text-center'>Quantity</th>
                <th className='text-center'>Rate</th>
                <th className='text-center'>Discount</th>
                <th className='text-center'>Amount</th>
              </tr>
            </thead>
            <tbody className='min-height-row overflow-hidden' style={{height: '270px'}}>
              {data?.items.map((item, index) => (
                <tr key={index}>
                  <td className='fw-semibold fs-5 w-1/2 '>
                    {item?.productName}
                    <br />
                    <OverlayTrigger
                      key={`tooltip-${index}`}
                      placement='top'
                      overlay={
                        <Tooltip id={`tooltip-top-${index}`}>
                          {item?.itemId?.productDescription}
                        </Tooltip>
                      }
                    >
                      <span
                        className='text-muted fs-7 d-inline-block text-truncate'
                        style={{maxWidth: '200px'}}
                      >
                        {item?.itemId?.productDescription}
                      </span>
                    </OverlayTrigger>
                  </td>
                  <td className='text-center fs-5'>{item.quantity}</td>
                  <td className='text-center fs-5'>
                    {item?.price !== undefined
                      ? Number(item.price).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })
                      : ''}
                    {/* {data?.customer?.currency} */}
                  </td>
                  <td className='text-center fs-5'>{item.discount}</td>
                  <td className='text-center fs-5'>
                    {Number(item?.amount).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}{' '}
                    {/* {data?.customer?.currency} */}
                  </td>
                </tr>
              ))}
            </tbody>
            <tbody>
              <tr className='bg-light-primary'>
                <td></td>
                <td></td>
                <td></td>
                <td className='text-start'>
                  <strong>Subtotal:</strong>
                </td>
                <td className='text-end'>
                  {data?.subtotal.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}{' '}
                  {data?.customer?.currency}
                </td>
              </tr>
              <tr className='bg-light-primary'>
                <td className=''>Note: {data?.notes}</td>
                <td></td>
                <td></td>
                <td className='text-start'>
                  <strong>Tax:</strong>
                </td>
                <td className='text-end'>{data?.tax}</td>
              </tr>
              <tr className='bg-light-primary'>
                <td>
                  Terms and Conditions:
                  <br />
                  <span className='fw-semibold text-gray-600 fs-6' style={{maxWidth: '100px'}}>
                    {data?.termsNCondition}
                  </span>
                </td>
                <td></td>
                <td></td>
                <td className='text-start bg-light-info fs-4'>
                  <strong>Total:</strong>
                </td>
                <td className='text-end fs-4 bg-light-info'>
                  {data?.total.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}{' '}
                  {data?.customer?.currency}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Summary Table */}
        {/* <table className="table table-bordered bg-light-primary">
                <tbody>
                    <tr>
                        <td className="text-end"><strong>Subtotal:</strong></td>
                        <td className="text-end">{data?.subtotal}</td>
                    </tr>
                    <tr>
                        <td className="text-end"><strong>Tax:</strong></td>
                        <td className="text-end">{data?.tax}</td>
                    </tr>
                    <tr>
                        <td className="text-end"><strong>Total:</strong></td>
                        <td className="text-end">{data?.total}</td>
                    </tr>
                </tbody>
            </table> */}

        {/* Footer Note Table */}
        <div className='card-footer mt-20'>
          <div className='d-flex flex-row justify-content-between fs-4 fw-semibold  px-5'>
            <div className='border-t py-2 border-dark'>
              <span>Client Signature</span>
            </div>
            <div className='border-t py-2 border-dark'>
              <span>Authorized Signature</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceSecond
