import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import {getServiceRecords} from '../../core/_requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useSelector, useDispatch} from 'react-redux'
import {setServiceHistoryData} from '../../../../redux/maintenance/service/serviceHistory/serviceHistorySlice'

const ServiceHistory = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [tableData, setTableData] = useState<any>([])
  const dispatch = useDispatch()
  const tableData = useSelector((state: any) => state.maintenance.serviceHistory.serviceHistoryData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser.permissions)
    }
  }, [currentUser])

  const fetchData = async () => {
    const res = await getServiceRecords(currentUser?.organization)
    console.log(res)
    // setTableData(res)
    dispatch(setServiceHistoryData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4>Service History</h4>
          <div>
            <Link to='add' className='text-white btn btn-primary'>
              <KTIcon iconName='plus' className='me-2' />
              Add Service Entry
            </Link>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-striped'>
              <thead>
                <tr className=''>
                  <th>Item Name</th>
                  <th>Completed</th>
                  <th>Repair Priority Class</th>
                  <th>Meter</th>
                  <th>Issues</th>
                  <th>Vendor</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((row: any) => (
                  <tr key={row?._id}>
                    <td>{row?.product?.productName}</td>
                    <td>
                      <Link to={`${row?._id}`}>{formatDate(row?.completionDate)}</Link>
                    </td>
                    <td className='uppercase'>{row?.priority}</td>
                    <td>{row?.odometer || 'N/A'}</td>
                    <td>
                      <div className='d-flex flex-column gap-1'>
                        {row?.issues?.map((item, idx) => {
                          return <span key={idx}>#{item.issueId}</span>
                        })}
                      </div>
                    </td>
                    <td>{row?.vendor?.displayName || ''}</td>
                    <td>
                      {row?.vendor?.currency || 'AED'} {row?.total}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceHistory
