import {createSlice} from '@reduxjs/toolkit'

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDashboardData(state, action) {
      state.dashboardData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setDashboardData, setLoading, setError} = dashboardSlice.actions
export default dashboardSlice.reducer
