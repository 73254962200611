import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {updateCategory, getCategoryById} from '../Core/_categoryRequest'
import {useDispatch} from 'react-redux'
import { updateCategory as updatedCategory } from '../../../redux/inventory/category/categorySlice'

export default function EditCategory() {
  const navigate = useNavigate()
  const {id} = useParams()
  const dispatch = useDispatch()
  const {currentUser} = useAuth()
  const [formData, setFormData] = useState({
    categoryName: '',
    description: '',
    type: 'service',
  })

  const fetchData = async () => {
    const res = await getCategoryById(id)
    console.log('res', res)
    if (res) {
      setFormData(res)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleEdit = async () => {
    const data = {
      ...formData,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    const res = await updateCategory(id, data)
    if (res) {
      dispatch(updatedCategory({
        categoryName: formData.categoryName,
        description: formData.description,
        type: formData.type,
        _id: id,
      }))
      navigate('/category')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h2 className='fw-bold'>Edit Category</h2>
        </div>
        <div className='card-body'>
          <form>
            <div className='mb-3'>
              <label htmlFor='name' className='form-label required'>
                Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid w-50'
                id='categoryName'
                name='categoryName'
                value={formData.categoryName}
                onChange={handleChange}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='description' className='form-label required'>
                Description
              </label>
              <input
                type='text'
                className='form-control form-control-solid w-50'
                id='description'
                name='description'
                value={formData.description}
                onChange={handleChange}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='type' className='form-label'>
                Type
              </label>
              <select
                className='form-select form-control-solid w-50'
                id='type'
                name='type'
                value={formData.type}
                onChange={(e) => setFormData({...formData, type: e.target.value})}
              >
                {currentUser?.inventoryFeatures?.service && (
                  <option value='service'>Service</option>
                )}
                {currentUser?.inventoryFeatures?.goods && <option value='goods'>Goods</option>}
                {currentUser?.inventoryFeatures?.rentals && (
                  <option value='rentals'>Rentals</option>
                )}
                {currentUser?.inventoryFeatures?.consumables && (
                  <option value='consumables'>Consumables</option>
                )}
              </select>
            </div>
            <div className=''>
              <button type='button' className='btn btn-warning my-3' onClick={handleEdit}>
                Edit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
