import {createSlice} from '@reduxjs/toolkit'

const serviceTaskSlice = createSlice({
  name: 'serviceTask',
  initialState: {
    serviceTaskData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setServiceTaskData(state, action) {
      state.serviceTaskData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setServiceTaskData, setLoading, setError} = serviceTaskSlice.actions
export default serviceTaskSlice.reducer
