import React, {useEffect, useState} from 'react'
import {editItem, getItemById, productImage} from '../../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../../modules/auth'
import Thumbnail from '../Thumbnail'
import {categoryImages, getCategoriesForItems} from '../../Core/_categoryRequest'
import ProductDetails from '../ProductDetails'
import AccountsInformation from '../AccountsInformation'

const EditItem = () => {
  const {id} = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [selectedImage, setSelectedImage] = useState('')
  const [categories, setCategories] = useState('')
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [categoryTypes, setCategoryTypes] = useState([])
  const [tags, setTags] = useState([])
  const [sellingPrice, setSellingPrice] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: 0,
    thumbnail: '',
    quantity: 1,
    // type: 'service',
    sku: '',
    unit: '',
    salesAccount: '',
    purchaseAccount: '',
  })
  const [salesInfo, setSalesInfo] = useState(false)
  const [purchaseInfo, setPurchaseInfo] = useState(false)
  const [salesAccount, setSalesAccount] = useState('')
  const [purchaseAccount, setPurchaseAccount] = useState('')

  const fetchData = async () => {
    const res = await getItemById(id)
    console.log('res', res)
    setFormData(
      res && {
        ...res,
        thumbnail: res.thumbnail,
        category: res.category,
        tags: res.tags,
        price: res.price,
        costPrice: res.costPrice,
        salesAccount: res.salesAccount,
        purchaseAccount: res.purchaseAccount,
      }
    )
    setCategories(res.category)
    setSellingPrice(res.price)
    setCostPrice(res.costPrice)
    if (res.salesAccount) {
      setSalesAccount(res.salesAccount?._id)
      setSalesInfo(true)
    }
    if (res.purchaseAccount) {
      setPurchaseAccount(res.purchaseAccount?._id)
      setPurchaseInfo(true)
    }
    if (res.thumbnail) {
      const s3ImageUrl = `https://realestify-assets.s3.me-central-1.amazonaws.com/${res.thumbnail}`

      setPreviewImage(s3ImageUrl)
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesForItems(currentUser.organization)
      console.log('response', response)
      const categories = response.map((category) => ({
        value: category._id,
        label: category.categoryName,
      }))
      // console.log('categories', categories)
      setCategoriesOptions(categories)
      const categoryTypes = response.map((category) => category.type)
      setCategoryTypes(categoryTypes)
    } catch (error) {
      console.log('error fetching categories', error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchCategories()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  const handleEdit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const imageData = selectedImage ? new FormData() : null

    if (imageData) {
      const productPic = formData.thumbnail
      imageData.append('name', productPic)
      imageData.append('image', selectedImage)
    }

    if (selectedImage) {
      imageData.append('name', formData.thumbnail)
      imageData.append('image', selectedImage)
      await productImage(imageData)
    }

    await categoryImages(imageData).then(async (resp) => {
      let data = {
        ...formData,
        category: categories,
        tags: tags,
        price: sellingPrice,
        costPrice: costPrice,
        salesAccount: salesAccount,
        purchaseAccount: purchaseAccount,
        organization: currentUser?.organization,
        company: currentUser?.company,
      }
      const res = await editItem(id, data)
      if (res) {
        navigate('/items')
      }
    })
  }

  return (
    <form onSubmit={handleEdit}>
      <div className='container-fluid bg-gray-50 p-4'>
        <div className='row my-4 '>
          <div className='col-xl-4 space-y-6'>
            <Thumbnail
              previewImage={previewImage}
              setPreviewImage={setPreviewImage}
              thumbnail={formData.thumbnail}
              setThumbnail={(thumbnail) => setFormData((prev) => ({...prev, thumbnail}))}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />

            <ProductDetails
              categoriesOptions={categoriesOptions}
              categories={categories}
              setCategories={setCategories}
              tags={tags}
              setTags={setTags}
              showTagModal={true}
            />
          </div>
          <div className='col-xl-8 mt-4'>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4 active'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_general'
                  >
                    Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-active-primary pb-4'
                    data-bs-toggle='tab'
                    href='#kt_ecommerce_add_product_accounts'
                  >
                    Accounts
                  </a>
                </li>
              </ul>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <div className='card card-flush py-4'>
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Service Details</h2>
                        </div>
                      </div>
                      <div className='card-body pt-0'>
                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Service Name</label>

                          <input
                            type='text'
                            name='name'
                            className='form-control mb-2'
                            placeholder='Item name'
                            value={formData.name}
                            onChange={handleChange}
                          />

                          <div className='text-muted fs-7'>
                            An service name is required and recommended to be unique.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>Description</label>
                          <div className='w-full rounded-lg h-full'>
                            <textarea
                              name='description'
                              value={formData.description}
                              onChange={(e) =>
                                setFormData({...formData, description: e.target.value})
                              }
                              placeholder='type your text here..'
                              rows={4}
                              cols={50}
                              className='w-full rounded border px-4 py-2'
                            ></textarea>
                          </div>

                          <div className='text-muted fs-7'>
                            Set a description to the service for better visibility.
                          </div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='required form-label'>SKU</label>

                          <input
                            type='text'
                            name='sku'
                            className='form-control mb-2'
                            placeholder='SKU Number'
                            value={formData.sku}
                            onChange={handleChange}
                          />

                          <div className='text-muted fs-7'>Enter the product SKU.</div>
                        </div>

                        <div className='mb-10 fv-row'>
                          <label className='form-label required'>Unit</label>
                          <select
                            className='form-select form-control-solid'
                            id='unit'
                            name='unit'
                            value={formData.unit}
                            onChange={(e) => setFormData({...formData, unit: e.target.value})}
                          >
                            <option value=''>Select Unit</option>
                            <option value='box'>box</option>
                            <option value='cm'>cm</option>
                            <option value='dz'>dz</option>
                            <option value='ft'>ft</option>
                            <option value='g'>g</option>
                            <option value='in'>in</option>
                            <option value='kg'>kg</option>
                            <option value='km'>km</option>
                            <option value='lb'>lb</option>
                            <option value='mg'>mg</option>
                            <option value='ml'>ml</option>
                            <option value='m'>m</option>
                            <option value='pcs'>pcs</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='kt_ecommerce_add_product_accounts'
                  role='tab-panel'
                >
                  <div className='d-flex flex-column gap-7 gap-lg-10'>
                    <AccountsInformation
                      sellingPrice={sellingPrice}
                      setSellingPrice={setSellingPrice}
                      costPrice={costPrice}
                      setCostPrice={setCostPrice}
                      salesInfo={salesInfo}
                      setSalesInfo={setSalesInfo}
                      purchaseInfo={purchaseInfo}
                      setPurchaseInfo={setPurchaseInfo}
                      salesAccount={salesAccount}
                      setSalesAccount={setSalesAccount}
                      purchaseAccount={purchaseAccount}
                      setPurchaseAccount={setPurchaseAccount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-end mt-4 space-x-3 '>
          <button
            type='submit'
            className='border p-2 px-3 text-white rounded-1 font-semibold'
            style={{backgroundColor: '#b4e602'}}
            disabled={loading}
          >
            Save Changes
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditItem
