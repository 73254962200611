import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getProjectById, getTargetByProjectId } from '../core/_requests';

export default function TargetSpending() {

  const [target, setTarget] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedTime, setSelectedTime] = useState('All');
  const [projectData, setProjectData] = useState({
    projectType: '',
    customer: '',
    currency: '',
    projectName: '',
    progress: 0,
    projectDescription: '',
    startDate: ' ',
    releaseDate: '',
    notification: {
      email: false,
      phone: false,
    },
    budgetAmount: 0,
    changeInBudget: false,
    status: 'InProgress',
    team: [],
    target: [],
    isDeleted: false,
  })

  const projectid = useParams().id

  const fetchTasks = async () => {
    try {
      const res = await getTargetByProjectId(projectid)
      console.log("res", res)
      setTarget(res);
      console.log("target", target)
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    fetchTasks()
  }, [])

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await getProjectById(projectid)
        setProjectData(response)

      } catch (error) {
        console.error('Error fetching project data:', error)
      }
    }

    fetchProjectData()
  }, [])

  console.log("projectdata", projectData)

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const filteredTargets = target.filter((target) =>
    target.title.toLowerCase().includes(searchQuery.toLowerCase())
  ).filter((target) =>
    selectedStatus === 'All' || target.status.toLowerCase() === selectedStatus.toLowerCase()
  ).filter((target) =>
    selectedTime === 'All'
      ? true
      : selectedTime === 'thisyear'
        ? new Date(target.startDate).getFullYear() === new Date().getFullYear()
        : selectedTime === 'thismonth'
          ? new Date(target.startDate).getMonth() === new Date().getMonth() &&
          new Date(target.startDate).getFullYear() === new Date().getFullYear()
          : selectedTime === 'lastmonth'
            ? new Date(target.startDate).getMonth() ===
            (new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1) &&
            new Date(target.startDate).getFullYear() ===
            (new Date().getMonth() === 0
              ? new Date().getFullYear() - 1
              : new Date().getFullYear())
            : selectedTime === 'last90days'
              ? new Date(target.startDate) >
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 90
              )
              : true
  );

  return (
    <div class='card card-flush mt-6 mt-xl-9'>
      <div class='card-header mt-5'>
        <div class='card-title flex-column'>
          <h3 class='fw-bold mb-1'>Targets Spendings</h3>
          {/* <div class='fs-6 text-gray-400'>Total $260,300 sepnt so far</div> */}
        </div>

        <div class='card-toolbar my-1'>
          <div class='me-6 my-1'>
            <select
              id='kt_filter_year'
              name='year'
              data-control='select2'
              data-hide-search='true'
              class='w-125px form-select form-select-solid form-select-sm'
              value={selectedTime}
              onChange={handleTimeChange}
            >
              <option value='All' selected='selected'>
                All time
              </option>
              <option value='thisyear'>This year</option>
              <option value='thismonth'>This month</option>
              <option value='lastmonth'>Last month</option>
              <option value='last90days'>Last 90 days</option>
            </select>
          </div>

          <div class='me-4 my-1'>
            <select
              id='kt_filter_orders'
              name='orders'
              data-control='select2'
              data-hide-search='true'
              class='w-125px form-select form-select-solid form-select-sm'
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              <option value='All' selected='selected'>
                Status
              </option>
              <option value='inprogress'>In Progress</option>
              <option value='new'>Not Started</option>
              <option value='DONE'>Done</option>
            </select>
          </div>

          <div class='d-flex align-items-center position-relative my-1'>
            <i class='ki-duotone ki-magnifier fs-3 position-absolute ms-3'>
              <span class='path1'></span>
              <span class='path2'></span>
            </i>
            <input
              // type='text'
              // id='kt_filter_search'
              // class='form-control form-control-solid form-select-sm w-150px ps-9'
              // placeholder='Search Target'
              type='text'
              id='kt_filter_search'
              className='form-control form-control-solid form-select-sm w-150px ps-9'
              placeholder='Search Target'
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div class='card-body pt-0'>
        <div class='table-responsive'>
          <table
            id='kt_profile_overview_table'
            class='table table-row-bordered table-row-dashed gy-4 align-middle fw-bold'
          >
            <thead class='fs-7 text-gray-400 text-uppercase'>
              <tr>
                <th class='min-w-130px'>Target name</th>
                <th class='min-w-130px'>User</th>
                <th class='min-w-90px'>Start Date</th>
                <th class='min-w-90px'>Due Date</th>
                <th class='min-w-50px'>Amount</th>
                <th class='min-w-50px'>Status</th>
                <th class='min-w-50px'>Tags </th>
              </tr>
            </thead>
            <tbody class='fs-6'>
              {/* {target?.map((target, i) => ( */}
              {filteredTargets.map((target, i) => (
                <tr key={i}>
                  <td>
                    <div class='d-flex align-items-center'>
                      {/* <div class='me-5 position-relative'>
                        <div class='symbol symbol-35px symbol-circle'>
                          <img alt='Pic' src='/media/avatars/300-6.jpg' />
                        </div>
                      </div> */}
                      <div class='d-flex flex-column justify-content-center'>
                        <a href='' class='fs-6 text-gray-800 text-hover-primary'>
                        </a>
                        <div class='fw-bold text-gray-800'>{target.title}</div>
                      </div>
                    </div>
                  </td>
                  <td className=''>
                    {target.user ? target.user.fullName : "No user assigned"}
                  </td>
                  <td>{new Date(target.startDate).toLocaleDateString('en-GB')}</td>
                  <td>{new Date(target.dueDate).toLocaleDateString('en-GB')}</td>

                  <td>{target.targetBudget ? target?.targetBudget : 0}{' '}
                    {projectData.customer.currency}
                  </td>
                  <td>
                    <span class={`badge  fw-bold px-4 py-3 ${target?.status === 'inprogress' ? 'badge-light-warning'
                      : target?.status === 'new'
                        ? 'badge-light-primary'
                        : 'badge-light-success'}`}>
                      {target?.status === 'new'
                        ? 'NOT STARTED'
                        : target?.status === 'inprogress'
                          ? 'IN PROGRESS'
                          : 'DONE'}
                    </span>
                  </td>
                  <td>{target.tags}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
