import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

const SPRINT_URL = `${API_URL}/sprint/`

export const addProject = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${SPRINT_URL}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const editProjectById = async (data: any, projectId: string): Promise<any> => {
  try {
    const response = await axios.put(`${SPRINT_URL}${projectId}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getProjects = async (orgid: string): Promise<any> => {
  try {
    const response = await axios.get(`${SPRINT_URL}${orgid}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getProjectById = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(`${SPRINT_URL}sprintbyid/${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getAgentsByProjectId = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(`${SPRINT_URL}getagents/${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteProject = async (id: string): Promise<any> => {
  try {
    const response = await axios.delete(`${SPRINT_URL}${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const addTask = async (data: any, projectId: string): Promise<any> => {
  try {
    const response = await axios.post(`${SPRINT_URL}${projectId}/task`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const editTask = async (data: any, taskId: string): Promise<any> => {
  try {
    const response = await axios.put(`${SPRINT_URL}updatetask/${taskId}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const changeTaskStatus = async (status: string, taskId: string): Promise<any> => {
  try {
    const response = await axios.put(`${SPRINT_URL}change/${taskId}`, {status})
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getTaskById = async (taskId: string): Promise<any> => {
  try {
    const response = await axios.get(`${SPRINT_URL}gettasks/${taskId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteTaskById = async (taskId: string): Promise<any> => {
  try {
    const response = await axios.delete(`${SPRINT_URL}deletetask/${taskId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}
