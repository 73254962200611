import { string } from "yup";
import { KTIcon } from "../../../../helpers";

type Props = {
 title : string,
 Param1: string,
  Param1Number: number,
  Param2 : string,
  Param2Number: number,
  className: string
}

const CardsWidget13 = ({title,Param1,Param1Number,Param2, Param2Number, className}:Props) => {
  return (
    <div className={`card ${className}`}>
      <div
        className="w-full px-6 py-6 "
      >
        <h3
          className="font-sans m-0 font-semibold text-lg leading-6"
        >
          <div className="flex w-full items-center">
            <div className="ml-4 flex items-center first:ml-0">
             {title}
            </div>
          </div>
        </h3>

      </div>

      <div className="card-body flex items-center px-3">
        <div className="flex w-full justify-evenly items-center mb-10">
          {/* <div className="flex items-center">

            <div className="flex flex-col w-full">
              <div className="w-full text-center">
                <span className="break-words max-w-full text-3xl text-red-600 text-left font-normal normal-case font-sans m-0">
                  {Param1Number}
                </span>
              </div>
              <div className="w-full text-center">
                <span className="break-words max-w-full text-md text-gray-400 text-left font-normal normal-case font-sans m-0">
                  {Param1}
                </span>
              </div>
            </div>

          </div> */}
          <div className=" flex items-center ">

            <div className="flex flex-col w-full">
              <div className="w-full first:mt-0 text-center">
                <span className="break-words max-w-full text-4xl text-yellow-600 text-left font-normal normal-case font-sans m-0">
                  {Param2Number}
                </span>
              </div>
              <div className="w-full text-center">
                <span className="break-words max-w-full text-md text-gray-400 text-left font-normal normal-case font-sans m-0">
                  {Param2}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  );
};

export { CardsWidget13 };
