import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useThemeMode } from '../../../_metronic/partials'
import SalesStat from './SalesStat'
import Stat1 from './stat1'
import StatBar from './statBar'
import { StatWorldMap } from '../../../_metronic/partials/widgets'





const StoreAnalytics = () => {
	const chartRef = useRef<HTMLDivElement | null>(null)
	const {mode} = useThemeMode()
  
	useEffect(() => {
	  const chart = refreshChart()
  
	  return () => {
		if (chart) {
		  chart.destroy()
		}
	  }
	}, [chartRef, mode])
  
	const refreshChart = () => {
	  if (!chartRef.current) {
		return
	  }
  
	  const height = parseInt(getCSS(chartRef.current, 'height'))
  
	  const chart = new ApexCharts(chartRef.current, getChartOptions(height))
	  if (chart) {
		chart.render()
	  }
  
	  return chart
	}
  return (
    <div>
        {/*  */}
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						
						<div className="container-xxl" id="kt_content_container">
							
							<div className="row g-5 g-xl-10">
								
								<div className="col-xxl-6 mb-md-5 mb-xl-10">
									
									<div className="row g-5 g-xl-10">
										
										<div className="col-md-6 col-xl-6 mb-xxl-10">
											{/* <!--begin::Card widget 8--> */}
											<div className="card overflow-hidden h-md-50 mb-5 mb-xl-10">
												
											<Stat1 className={''} chartColor={'info'} chartHeight={''}/>

												
											</div>
											{/* <!--end::Card widget 8-->

											<!--begin::Card widget 5--> */}
											<div className="card card-flush h-md-50 mb-xl-10">
												
												<div className="card-header pt-5">
													
													<div className="card-title d-flex flex-column">
														
														<div className="d-flex align-items-center">
															
															<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">1,836</span>
															
															<span className="badge badge-light-danger fs-base">
															<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>2.2%</span>
															
														</div>
														
														<span className="text-gray-400 pt-1 fw-semibold fs-6">Total Sales</span>
														
													</div>
													
												</div>
												
												
												<div className="card-body d-flex align-items-end pt-0">
													
													<div className="d-flex align-items-center flex-column mt-3 w-100">
														<div className="d-flex justify-content-between w-100 mt-auto mb-2">
															<span className="fw-bolder fs-6 text-dark">1,048 to Goal</span>
															<span className="fw-bold fs-6 text-gray-400">62%</span>
														</div>
														<div className="h-8px mx-3 w-100 bg-light-success rounded">
															<div className="bg-success rounded h-8px" role="progressbar" style={{width: "62%;" /* aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" */}}></div>
														</div>
													</div>
													
												</div>
												
											</div>
											{/* <!--end::Card widget 5--> */}
										</div>
										
										
										<div className="col-md-6 col-xl-6 mb-xxl-10">
											{/* <!--begin::Card widget 9--> */}
											<div className="card overflow-hidden h-md-50 mb-5 mb-xl-10">
												
												<Stat1 className={''} chartColor={'danger'} chartHeight={''}/>
												
											</div>
											{/* <!--end::Card widget 9-->

											<!--begin::Card widget 7--> */}
											<div className="card card-flush h-md-50 mb-xl-10">
												
												<div className="card-header pt-5">
													
													<div className="card-title d-flex flex-column">
														
														<span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">6.3k</span>
														
														<span className="text-gray-400 pt-1 fw-semibold fs-6">Total New Customers</span>
														
													</div>
													
												</div>
												
												
												<div className="card-body d-flex flex-column justify-content-end pe-0">
													
													<span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Today’s Heroes</span>
													
													
													<div className="symbol-group symbol-hover flex-nowrap">
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
															<span className="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
														</div>
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michael Eberon">
															<img alt="Pic" src="/media/avatars/300-11.jpg" />
														</div>
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
															<span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
														</div>
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Melody Macy">
															<img alt="Pic" src="/media/avatars/300-2.jpg" />
														</div>
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Perry Matthew">
															<span className="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
														</div>
														<div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Barry Walter">
															<img alt="Pic" src="/media/avatars/300-12.jpg" />
														</div>
														<a href="#" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
															<span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">+42</span>
														</a>
													</div>
													
												</div>
												
											</div>
										{/* 	<!--end::Card widget 7--> */}
										</div>
										
									</div>
									
								</div>
								
								
								<div className="col-xxl-6 mb-5 mb-xl-10">
									{/* <!--begin::Maps widget 1--> */}
									<div className="card card-flush h-md-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-dark">World Sales</span>
												<span className="text-gray-400 pt-2 fw-semibold fs-6">Top Selling Countries</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Create Invoice</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link flex-stack px-3">Create Payment
														<span className="ms-2" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference">
															<i className="ki-duotone ki-information fs-6">
																<span className="path1"></span>
																<span className="path2"></span>
																<span className="path3"></span>
															</i>
														</span></a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Generate Bill</a>
													</div>
													
													
													<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
														<a href="#" className="menu-link px-3">
															<span className="menu-title">Subscription</span>
															<span className="menu-arrow"></span>
														</a>
														
														<div className="menu-sub menu-sub-dropdown w-175px py-4">
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Plans</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Billing</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Statements</a>
															</div>
															
															
															<div className="separator my-2"></div>
															
															
															<div className="menu-item px-3">
																<div className="menu-content px-3">
																	
																	<label className="form-check form-switch form-check-custom form-check-solid">
																		
																		<input className="form-check-input w-30px h-20px" type="checkbox" value="1" /* checked="checked" */ name="notifications" />
																		
																		
																		<span className="form-check-label text-muted fs-6">Recuring</span>
																		
																	</label>
																	
																</div>
															</div>
															
														</div>
														
													</div>
													
													
													<div className="menu-item px-3 my-1">
														<a href="#" className="menu-link px-3">Settings</a>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body d-flex flex-center">
											{/* <!--begin::Map container--> */}
											<div id="kt_maps_widget_1_map" className="w-100 h-350px">
												<StatWorldMap/>
											</div>
											
										</div>
										
									</div>
									{/* <!--end::Maps widget 1--> */}
								</div>
								
							</div>
							
							
							<div className="row g-5 g-xl-10 g-xl-10">
								
								<div className="col-xl-4 mb-xl-10">
									{/* <!--begin::Engage widget 1--> */}
									<div className="card h-md-100" dir="ltr">
										
										<div className="card-body d-flex flex-column flex-center">
											
											<div className="mb-2">
												
												<h1 className="fw-semibold text-gray-800 text-center lh-lg">Have you tried
												<br />new
												<span className="fw-bolder">Invoice Manager ?</span></h1>
												
												
												<div className="py-10 text-center">
													<img src="/media/svg/illustrations/easy/2.svg" className="theme-light-show w-200px" alt="" />
													<img src="/media/svg/illustrations/easy/2-dark.svg" className="theme-dark-show w-200px" alt="" />
												</div>
												
											</div>
											
											
											<div className="text-center mb-1">
												
												<a className="btn btn-sm btn-primary me-2" href="../../demo17/dist/apps/ecommerce/customers/listing.html">Try now</a>
												
												
												<a className="btn btn-sm btn-light" href="../../demo17/dist/apps/invoices/view/invoice-1.html">Learn more</a>
												
											</div>
											
										</div>
										
									</div>
									{/* <!--end::Engage widget 1--> */}
								</div>
								
								
								<div className="col-xl-4 mb-xl-10">
									{/* <!--begin::Chart widget 5--> */}
									<div className="card card-flush h-md-100">
										
										<div className="card-header flex-nowrap pt-5">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-dark">Top Selling Categories</span>
												<span className="text-gray-400 pt-2 fw-semibold fs-6">8k social visitors</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
													</div>
													
													
													<div className="separator mb-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Ticket</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Customer</a>
													</div>
													
													
													<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
														
														<a href="#" className="menu-link px-3">
															<span className="menu-title">New Group</span>
															<span className="menu-arrow"></span>
														</a>
														
														
														<div className="menu-sub menu-sub-dropdown w-175px py-4">
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Admin Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Staff Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Member Group</a>
															</div>
															
														</div>
														
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Contact</a>
													</div>
													
													
													<div className="separator mt-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<div className="menu-content px-3 py-3">
															<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
														</div>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body pt-5 ps-6">
											<div id="kt_charts_widget_5" className="min-h-auto">
												<StatBar />
											</div>
										</div>
										
									</div>
									{/* <!--end::Chart widget 5--> */}
								</div>
								
								
								<div className="col-xl-4 mb-5 mb-xl-10">
									{/* <!--begin::List widget 6--> */}
									<div className="card card-flush h-md-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-gray-800">Top Selling Products</span>
												<span className="text-gray-400 mt-1 fw-semibold fs-6">8k social visitors</span>
											</h3>
											
											
											<div className="card-toolbar">
												<a href="../../demo17/dist/apps/ecommerce/catalog/categories.html" className="btn btn-sm btn-light">View All</a>
											</div>
											
										</div>
										
										
										<div className="card-body pt-4">
											
											<div className="table-responsive">
												
												<table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
													
													<thead>
														<tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
															<th className="p-0 w-50px pb-1">ITEM</th>
															<th className="ps-0 min-w-140px"></th>
															<th className="text-end min-w-140px p-0 pb-1">TOTAL PRICE</th>
														</tr>
													</thead>
													
													<tbody>
														<tr>
															<td>
																<img src="/media/stock/ecommerce/210.png" className="w-50px" alt="" />
															</td>
															<td className="ps-0">
																<a href="../../demo17/dist/apps/ecommerce/sales/details.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">Elephant 1802</a>
																<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
															</td>
															<td>
																<span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">$72.00</span>
															</td>
														</tr>
														<tr>
															<td>
																<img src="/media/stock/ecommerce/215.png" className="w-50px" alt="" />
															</td>
															<td className="ps-0">
																<a href="../../demo17/dist/apps/ecommerce/sales/details.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">Red Laga</a>
																<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
															</td>
															<td>
																<span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">$45.00</span>
															</td>
														</tr>
														<tr>
															<td>
																<img src="/media/stock/ecommerce/209.png" className="w-50px" alt="" />
															</td>
															<td className="ps-0">
																<a href="../../demo17/dist/apps/ecommerce/sales/details.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">RiseUP</a>
																<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
															</td>
															<td>
																<span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">$168.00</span>
															</td>
														</tr>
														<tr>
															<td>
																<img src="/media/stock/ecommerce/214.png" className="w-50px" alt="" />
															</td>
															<td className="ps-0">
																<a href="../../demo17/dist/apps/ecommerce/sales/details.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">Yellow Stone</a>
																<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
															</td>
															<td>
																<span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">$72.00</span>
															</td>
														</tr>
													</tbody>
													
												</table>
											</div>
											
										</div>
										
									</div>
									{/* <!--end::List widget 6--> */}
								</div>
								
							</div>
							
							
							<div className="row g-5 g-xl-10">
								
								<div className="col-xxl-4 mb-xxl-10">
									{/* <!--begin::List widget 7--> */}
									<div className="card card-flush h-md-100">
										
										<div className="card-header py-7">
											
											<div className="m-0">
												
												<div className="d-flex align-items-center mb-2">
													
													<span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">0.37%</span>
													
													
													<span className="badge badge-light-danger fs-base">
													<i className="ki-duotone ki-arrow-up fs-5 text-danger ms-n1">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>8.02%</span>
													
												</div>
												
												
												<span className="fs-6 fw-semibold text-gray-400">Online store convertion rate</span>
												
											</div>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
													</div>
													
													
													<div className="separator mb-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Ticket</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Customer</a>
													</div>
													
													
													<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
														
														<a href="#" className="menu-link px-3">
															<span className="menu-title">New Group</span>
															<span className="menu-arrow"></span>
														</a>
														
														
														<div className="menu-sub menu-sub-dropdown w-175px py-4">
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Admin Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Staff Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Member Group</a>
															</div>
															
														</div>
														
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Contact</a>
													</div>
													
													
													<div className="separator mt-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<div className="menu-content px-3 py-3">
															<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
														</div>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body pt-0">
											
											<div className="mb-0">
												
												<div className="d-flex flex-stack">
													
													<div className="d-flex align-items-center me-5">
														
														<div className="symbol symbol-30px me-5">
															<span className="symbol-label">
																<i className="ki-duotone ki-magnifier fs-3 text-gray-600">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>
															</span>
														</div>
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Search Retargeting</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-6 me-3">0.24%</span>
														
														
														<div className="d-flex flex-center">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>2.4%</span>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<div className="d-flex align-items-center me-5">
														
														<div className="symbol symbol-30px me-5">
															<span className="symbol-label">
																<i className="ki-duotone ki-tiktok fs-3 text-gray-600">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>
															</span>
														</div>
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Social Retargeting</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-6 me-3">0.94%</span>
														
														
														<div className="d-flex flex-center">
															
															<span className="badge badge-light-danger fs-base">
															<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>9.4%</span>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<div className="d-flex align-items-center me-5">
														
														<div className="symbol symbol-30px me-5">
															<span className="symbol-label">
																<i className="ki-duotone ki-sms fs-3 text-gray-600">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>
															</span>
														</div>
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Email Retargeting</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-6 me-3">1.23%</span>
														
														
														<div className="d-flex flex-center">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.2%</span>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<div className="d-flex align-items-center me-5">
														
														<div className="symbol symbol-30px me-5">
															<span className="symbol-label">
																<i className="ki-duotone ki-icon fs-3 text-gray-600">
																	<span className="path1"></span>
																	<span className="path2"></span>
																	<span className="path3"></span>
																</i>
															</span>
														</div>
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Referrals Customers</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-6 me-3">0.08%</span>
														
														
														<div className="d-flex flex-center">
															
															<span className="badge badge-light-danger fs-base">
															<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.4%</span>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<div className="d-flex align-items-center me-5">
														
														<div className="symbol symbol-30px me-5">
															<span className="symbol-label">
																<i className="ki-duotone ki-abstract-25 fs-3 text-gray-600">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>
															</span>
														</div>
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Other</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-6 me-3">0.46%</span>
														
														
														<div className="d-flex flex-center">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>8.3%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
										</div>
										
									</div>
									{/* <!--end::List widget 7--> */}
								</div>
								
								
								<div className="col-xxl-8 mb-5 mb-xl-10">
									{/* <!--begin::Chart widget 13--> */}
									<div className="card card-flush h-md-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-dark">Sales Statistics</span>
												<span className="text-gray-400 pt-2 fw-semibold fs-6">Top Selling Products</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-100px py-4" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Remove</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Mute</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Settings</a>
													</div>
													
												</div>
												
											</div>
											

										</div>
										
										
										<div className="card-body pt-5">
											
											 <div id="kt_charts_widget_13_chart" className="w-100"></div> 
			
												<SalesStat/>
										
										</div>
										
									</div>
									{/* <!--end::Chart widget 13--> */}
								</div>
								
							</div>
							
							
							<div className="row g-5 g-xl-10 g-xl-10">
								
								<div className="col-xl-4 mb-xl-10">
									{/* <!--begin::List widget 8--> */}
									<div className="card card-flush h-xl-100">
										
										<div className="card-header pt-7 mb-5">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-gray-800">Visits by Country</span>
												<span className="text-gray-400 mt-1 fw-semibold fs-6">20 countries share 97% visits</span>
											</h3>
											
											
											<div className="card-toolbar">
												<a href="../../demo17/dist/apps/ecommerce/sales/listing.html" className="btn btn-sm btn-light">View All</a>
											</div>
											
										</div>
										
										
										<div className="card-body pt-0">
											
											<div className="m-0">
												
												<div className="d-flex flex-stack">
													
													<img src="/media/flags/united-states.svg" className="me-4 w-25px" style={{borderRadius: "4px"}}/>
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">United States</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">9,763</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-success fs-base">
																<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>2.6%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<img src="/media/flags/brazil.svg" className="me-4 w-25px" style={{borderRadius: "4px"}}/>
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Brasil</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">All Social Channels</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">4,062</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-danger fs-base">
																<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>0.4%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
												
													<img src="/media/flags/turkey.svg" className="me-4 w-25px" style={{borderRadius: "4px"}} />
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Turkey</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Mailchimp Campaigns</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">1,680</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-success fs-base">
																<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>0.2%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<img src="/media/flags/france.svg" className="me-4 w-25px" style={{borderRadius: "4px"}} />
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">France</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Impact Radius visits</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">849</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-success fs-base">
																<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>4.1%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<img src="assets/media/flags/india.svg" className="me-4 w-25px" style={{borderRadius: "4px"}} />
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">India</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Many Sources</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">604</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-danger fs-base">
																<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>8.3%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex flex-stack">
													
													<img src="/media/flags/sweden.svg" className="me-4 w-25px" style={{borderRadius: "4px"}} />
													
													
													<div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Sweden</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Social Network</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-6 me-3 d-block">237</span>
															
															
															<div className="m-0">
																
																<span className="badge badge-light-success fs-base">
																<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																	<span className="path1"></span>
																	<span className="path2"></span>
																</i>1.9%</span>
																
															</div>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
										</div>
										
									</div>
									{/* <!--end::LIst widget 8--> */}
								</div>
								
								
								<div className="col-xl-4 mb-xl-10">
									{/* <!--begin::List widget 9--> */}
									<div className="card card-flush h-xl-100">
										
										<div className="card-header py-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-gray-800">Social Network Visits</span>
												<span className="text-gray-400 mt-1 fw-semibold fs-6">8k social visitors</span>
											</h3>
											
											
											<div className="card-toolbar">
												<a href="#" className="btn btn-sm btn-light">View All</a>
											</div>
											
										</div>
										
										
										<div className="card-body card-body d-flex justify-content-between flex-column pt-3">
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/dribbble-icon-1.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Dribbble</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Community</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">579</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>2.6%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
										
											
											<div className="separator separator-dashed my-3"></div>
											
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/linkedin-1.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Linked In</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Social Media</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">2,588</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-danger fs-base">
															<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.4%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
											
											<div className="separator separator-dashed my-3"></div>
											
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/slack-icon.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Slack</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Messanger</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">794</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.2%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
											
											<div className="separator separator-dashed my-3"></div>
											
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/youtube-3.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">YouTube</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Video Channel</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">1,578</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>4.1%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
											
											<div className="separator separator-dashed my-3"></div>
											
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/instagram-2-1.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Instagram</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Social Network</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">3,458</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>8.3%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
											
											<div className="separator separator-dashed my-3"></div>
											
											
											<div className="d-flex flex-stack">
												
												<img src="/media/svg/brand-logos/facebook-3.svg" className="me-4 w-30px" style={{borderRadius: "4px"}} />
												
												
												<div className="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
													
													<div className="me-5">
														
														<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Facebook</a>
														
														
														<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Social Network</span>
														
													</div>
													
													
													<div className="d-flex align-items-center">
														
														<span className="text-gray-800 fw-bold fs-4 me-3">2,047</span>
														
														
														<div className="m-0">
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>1.9%</span>
															
														</div>
														
													</div>
													
												</div>
												
											</div>
											
										</div>
										
									</div>
									{/* <!--end::List widget 9--> */}
								</div>
								
								
								<div className="col-xl-4 mb-5 mb-xl-10">
									{/* <!--begin::Chart widget 14--> */}
									<div className="card card-flush h-xl-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-dark">Departments</span>
												<span className="text-gray-400 pt-2 fw-semibold fs-6">Performance & achievements</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Create Invoice</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link flex-stack px-3">Create Payment
														<span className="ms-2" data-bs-toggle="tooltip" title="Specify a target name for future usage and reference">
															<i className="ki-duotone ki-information fs-6">
																<span className="path1"></span>
																<span className="path2"></span>
																<span className="path3"></span>
															</i>
														</span></a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Generate Bill</a>
													</div>
													
													
													<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
														<a href="#" className="menu-link px-3">
															<span className="menu-title">Subscription</span>
															<span className="menu-arrow"></span>
														</a>
														
														<div className="menu-sub menu-sub-dropdown w-175px py-4">
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Plans</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Billing</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Statements</a>
															</div>
															
															
															<div className="separator my-2"></div>
															
															
															<div className="menu-item px-3">
																<div className="menu-content px-3">
																	
																	<label className="form-check form-switch form-check-custom form-check-solid">
																		
																		<input className="form-check-input w-30px h-20px" type="checkbox" value="1" /* checked="checked" */ name="notifications" />
																		
																		
																		<span className="form-check-label text-muted fs-6">Recuring</span>
																		
																	</label>
																	
																</div>
															</div>
															
														</div>
														
													</div>
													
													
													<div className="menu-item px-3 my-1">
														<a href="#" className="menu-link px-3">Settings</a>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body pt-5">
											
											<div id="kt_charts_widget_14_chart" className="w-100 h-350px">
												
											</div>
										
										</div>
										
									</div>
									{/* <!--end::Chart widget 14--> */}
								</div>
								
							</div>
							
							
							<div className="row g-5 g-xl-10 g-xl-10">
								
								<div className="col-xl-4">
									{/* <!--begin::List widget 12--> */}
									<div className="card card-flush h-xl-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-gray-800">Visits by Source</span>
												<span className="text-gray-400 mt-1 fw-semibold fs-6">29.4k visitors</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
													</div>
													
													
													<div className="separator mb-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Ticket</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Customer</a>
													</div>
													
													
													<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
														
														<a href="#" className="menu-link px-3">
															<span className="menu-title">New Group</span>
															<span className="menu-arrow"></span>
														</a>
														
														
														<div className="menu-sub menu-sub-dropdown w-175px py-4">
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Admin Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Staff Group</a>
															</div>
															
															
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">Member Group</a>
															</div>
															
														</div>
														
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">New Contact</a>
													</div>
													
													
													<div className="separator mt-3 opacity-75"></div>
													
													
													<div className="menu-item px-3">
														<div className="menu-content px-3 py-3">
															<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
														</div>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body d-flex align-items-end">
											
											<div className="w-100">
												
												<div className="d-flex align-items-center">
													
													<div className="symbol symbol-30px me-5">
														<span className="symbol-label">
															<i className="ki-duotone ki-rocket fs-3 text-gray-600">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</div>
													
													
													<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Direct Source</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Direct link clicks</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-4 me-3">1,067</span>
															
															
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>2.6%</span>
															
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex align-items-center">
													
													<div className="symbol symbol-30px me-5">
														<span className="symbol-label">
															<i className="ki-duotone ki-tiktok fs-3 text-gray-600">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</div>
													
													
													<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Social Networks</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">All Social Channels</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-4 me-3">24,588</span>
															
															
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>4.1%</span>
															
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex align-items-center">
													
													<div className="symbol symbol-30px me-5">
														<span className="symbol-label">
															<i className="ki-duotone ki-sms fs-3 text-gray-600">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</div>
													
													
													<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Email Newsletter</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Mailchimp Campaigns</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-4 me-3">794</span>
															
															
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.2%</span>
															
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex align-items-center">
													
													<div className="symbol symbol-30px me-5">
														<span className="symbol-label">
															<i className="ki-duotone ki-icon fs-3 text-gray-600">
																<span className="path1"></span>
																<span className="path2"></span>
																<span className="path3"></span>
															</i>
														</span>
													</div>
													
													
													<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Referrals</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Impact Radius visits</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-4 me-3">6,578</span>
															
															
															
															<span className="badge badge-light-danger fs-base">
															<i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>0.4%</span>
															
															
														</div>
														
													</div>
													
												</div>
												
												<div className="separator separator-dashed my-3"></div>
												
												
												<div className="d-flex align-items-center">
													
													<div className="symbol symbol-30px me-5">
														<span className="symbol-label">
															<i className="ki-duotone ki-abstract-25 fs-3 text-gray-600">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>
														</span>
													</div>
													
													
													<div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
														
														<div className="me-5">
															
															<a href="#" className="text-gray-800 fw-bold text-hover-primary fs-6">Other</a>
															
															
															<span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Many Sources</span>
															
														</div>
														
														
														<div className="d-flex align-items-center">
															
															<span className="text-gray-800 fw-bold fs-4 me-3">79,458</span>
															
															
															
															<span className="badge badge-light-success fs-base">
															<i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
																<span className="path1"></span>
																<span className="path2"></span>
															</i>8.3%</span>
															
															
														</div>
														
													</div>
													
												</div>
												
												
												<div className="text-center pt-8 d-1">
													<a href="../../demo17/dist/apps/ecommerce/sales/details.html" className="text-primary opacity-75-hover fs-6 fw-bold">View Store Analytics
													<i className="ki-duotone ki-arrow-right fs-3 text-primary">
														<span className="path1"></span>
														<span className="path2"></span>
													</i></a>
												</div>
												
											</div>
											
										</div>
										
									</div>
									{/* <!--end::List widget 12--> */}
								</div>
								
								
								<div className="col-xl-8">
									{/* <!--begin::Chart widget 15--> */}
									<div className="card card-flush h-xl-100">
										
										<div className="card-header pt-7">
											
											<h3 className="card-title align-items-start flex-column">
												<span className="card-label fw-bold text-dark">Author Sales</span>
												<span className="text-gray-400 pt-2 fw-semibold fs-6">Statistics by Countries</span>
											</h3>
											
											
											<div className="card-toolbar">
												
												<button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
													<i className="ki-duotone ki-dots-square fs-1 text-gray-400 me-n1">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
													</i>
												</button>
												
												<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-100px py-4" data-kt-menu="true">
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Remove</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Mute</a>
													</div>
													
													
													<div className="menu-item px-3">
														<a href="#" className="menu-link px-3">Settings</a>
													</div>
													
												</div>
												
											</div>
											
										</div>
										
										
										<div className="card-body pt-5">
											
											{/* <div id="kt_charts_widget_15_chart" className="min-h-auto ps-4 pe-6 mb-3 h-350px"></div> */}
											<div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
										
										</div>
										
									</div>
									{/* <!--end::Chart widget 15--> */}
								</div>
								
							</div>
							
						</div>
						
					</div>
					
    </div>
  )
}

export  {StoreAnalytics}

function getChartOptions(height: number): ApexOptions {
	const labelColor = getCSSVariableValue('--bs-gray-500')
	const borderColor = getCSSVariableValue('--bs-gray-200')
	const baseColor = getCSSVariableValue('--bs-primary')
	const secondaryColor = getCSSVariableValue('--bs-gray-300')
  
	return {
	  series: [
		{
		  name: '',
		  data: [725, 625, 602, 509, 322, 214,204,200,165,152,125,99],
		},
		
	  ],
	  chart: {
		fontFamily: 'inherit',
		type: 'bar',
		height: height,
		toolbar: {
		  show: false,
		},
	  },
	  plotOptions: {
		bar: {
		  horizontal: false,
		  columnWidth: '70%',
		  borderRadius: 5,
		},
	  },
	  legend: {
		show: false,
	  },
	  dataLabels: {
		enabled: false,
	  },
	  stroke: {
		show: true,
		width: 2,
		colors: ['transparent'],
	  },
	  xaxis: {
		categories: ['US', 'UK', 'China', 'Japan', 'Germany', 'France','India','Spain','Italy','Russia','Norway','Canada'],
		axisBorder: {
		  show: false,
		},
		axisTicks: {
		  show: false,
		},
		labels: {
		  style: {
			colors: labelColor,
			fontSize: '12px',
		  },
		},
	  },
	  yaxis: {
		
		labels: {
		  style: {
			colors: labelColor,
			fontSize: '12px',
		  },
		},
	  },
	  fill: {
		opacity: 1,
	  },
	  states: {
		normal: {
		  filter: {
			type: 'none',
			value: 0,
		  },
		},
		hover: {
		  filter: {
			type: 'none',
			value: 0,
		  },
		},
		active: {
		  allowMultipleDataPointsSelection: false,
		  filter: {
			type: 'none',
			value: 0,
		  },
		},
	  },
	  tooltip: {
		style: {
		  fontSize: '12px',
		},
		y: {
		  formatter: function (val) {
			return '' + val + ' '
		  },
		},
	  },
	  colors: [baseColor, secondaryColor],
	  grid: {
		borderColor: borderColor,
		strokeDashArray: 4,
		yaxis: {
		  lines: {
			show: true,
		  },
		},
	  },
	}
  }