import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ServiceHistory from '../components/service/ServiceHistory'
import AddServiceEntry from '../components/service/AddServiceEntry'
import ServiceDetail from '../components/service/ServiceDetail'

const ServicePage = () => {
  return (
    <Routes>
      <Route path='/history' element={<ServiceHistory />} />
      <Route path='/history/:id' element={<ServiceDetail />} />
      <Route path='/history/add' element={<AddServiceEntry />} />
    </Routes>
  )
}

export default ServicePage
