import React, {ChangeEvent, FormEvent, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Docs} from '../Core/_models'
import camera from '../../../../_metronic/assets/camera.png'
import {addDocs, postImages} from '../Core/_requests'
import {profile} from 'console'

type props = {
  handlereset: () => void
}

const AddDocs: React.FC<props> = ({handlereset}) => {
  const [imagePreview, setImagePreview] = useState<any>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)

  const [formData, setFormData] = useState<Docs>({
    name: '',
    phone: '',
    email: '',
    status: '',
    profilePic: '',
  })

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  // console.log(imagePreview, selectedImage, formData.profilePic)
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      setSelectedImage(file)

      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }

      setFormData({
        ...formData,
        profilePic: Date.now() + file.name,
      })

      reader.readAsDataURL(file)
    }
  }
  console.log('profilePic', formData.profilePic)
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const imageData = new FormData()
    const productPic = formData.profilePic
    imageData.append('name', productPic)
    imageData.append('image', selectedImage)
    console.log(formData.profilePic)
    console.log('file', selectedImage)

    await postImages(imageData).then(async (res) => {
      await addDocs(formData).then((response) => {
        handlereset()

        setFormData({
          name: '',
          profilePic: '',
          phone: '',
          email: '',
          status: '',
        })
        const closeModalButton = document.getElementById('closeModalButton')
        if (closeModalButton) {
          closeModalButton.click()
        }
      })
    })
  }

  return (
    <>
      <div className='card-toolbar me-5' data-bs-toggle='modal' data-bs-target='#kt_modal_adddocs'>
        <a className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Docs
        </a>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_adddocs'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Docs</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></div>
            </div>

            <div className='row modal-body'>
              <form>
                <div className='row'>
                  <div className='messagepicinput d-flex flex-center '>
                    <label htmlFor='messagegrouppic' className='messagesharefilelabel'>
                      <div className='symbol symbol-100px border border-gray-300 '>
                        {imagePreview && <img src={imagePreview} alt='add' />}
                        {!imagePreview && <img className='' src={camera} alt='add' />}
                      </div>

                      <input
                        type='file'
                        id='messagegrouppic'
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                      />
                    </label>
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Full Name</label>
                    <input
                      type='text'
                      name='name'
                      value={formData.name}
                      onChange={handleInputChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Email</label>
                    <input
                      type='text'
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Phone</label>
                    <input
                      type='text'
                      name='phone'
                      value={formData.phone}
                      onChange={handleInputChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Status</label>
                    <select
                      name='status'
                      value={formData.status}
                      onChange={handleInputChange}
                      className='form-control'
                    >
                      <option value=''>Choose</option>
                      <option value={'busy'}>busy</option>
                      <option value={'available'}>available</option>
                      <option value={'offline'}>offline</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDocs
