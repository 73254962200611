import React, {useEffect, useState} from 'react'
import {IoMdSearch} from 'react-icons/io'
import {useNavigate, Link, useParams} from 'react-router-dom'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {CgDanger} from 'react-icons/cg'
import {
  deleteStock,
  getFilteredStocksByType,
  getTagsByOrgId,
  getStocksByType,
} from '../Core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import Filter2 from '../Components/Filter2'
import {getCategoriesForProducts} from '../Core/_categoryRequest'
import qs from 'qs'
import {useSelector, useDispatch} from 'react-redux'
import {setGoodsData} from '../../../redux/inventory/products/goodsSlice'
import {setConsumablesData} from '../../../redux/inventory/products/consumablesSlice'
import {setRentalsData} from '../../../redux/inventory/products/rentalsSlice'

export default function ProductTable() {
  const {currentUser} = useAuth()
  const {categoryType} = useParams()
  const dispatch = useDispatch()
  const goodsList = useSelector((state) => state.inventory.goods.goodsData)
  const goodsLoading = useSelector((state) => state.inventory.goods.loading)
  const rentalsList = useSelector((state) => state.inventory.rentals.rentalsData)
  const rentalsLoading = useSelector((state) => state.inventory.rentals.loading)
  const consumablesList = useSelector((state) => state.inventory.consumables.consumablesData)
  const consumablesLoading = useSelector((state) => state.inventory.consumables.loading)
  const [loading, setLoading] = useState(true)
  const [dropdown, setDropdown] = useState([])
  const [permissions, setPermissions] = useState({})
  const [del, setDel] = useState(false)
  const [selectedItem, setselectedItem] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [originalData, setOriginalData] = useState([])
  const [tableData, setTableData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('All')
  const [masterCheckbox, setMasterCheckbox] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [tagList, setTagList] = useState([])
  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const stringifyObject = (obj) => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_status: obj.filter.status,
        filter_category: obj.filter.category,
        filter_tag: obj.filter.tag,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const handleAddProductClick = () => {
    navigate(`/product/${categoryType}/add-product/`)
  }

  console.log('catgory Type', categoryType)
  const fetchData = async () => {
    const queryString = stringifyObject(searchFilters)
    console.log('query String', queryString)
    // const res = await getStocksByType(currentUser?.organization, categoryType)
    const res = await getFilteredStocksByType(queryString, currentUser?.organization, categoryType)
    setTableData(res)
    console.log('Data', tableData)
    setOriginalData(res)
    setDropdown(Array(res.length).fill(false))
    if (categoryType === 'goods') dispatch(setGoodsData(res))
    else if (categoryType === 'consumables') dispatch(setConsumablesData(res))
    else if (categoryType === 'rentals') dispatch(setRentalsData(res))
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (categoryType === 'goods') {
      if (goodsList.length === 0) {
        fetchData()
      } else {
        setTableData(goodsList)
        setOriginalData(goodsList)
        setDropdown(Array(goodsList.length).fill(false))
        setLoading(false)
      }
    } else if (categoryType === 'consumables') {
      if (consumablesList.length === 0) {
        fetchData()
      } else {
        setTableData(consumablesList)
        setOriginalData(consumablesList)
        setDropdown(Array(consumablesList.length).fill(false))
        setLoading(false)
      }
    } else if (categoryType === 'rentals') {
      if (rentalsList.length === 0) {
        fetchData()
      } else {
        setTableData(rentalsList)
        setOriginalData(rentalsList)
        setDropdown(Array(rentalsList.length).fill(false))
        setLoading(false)
      }
    }
  }, [currentUser, categoryType])

  const handleDeleteToggle = (i) => {
    setDel(!del)
    setselectedItem(i)
  }

  const handleDelete = async (i) => {
    const res = await deleteStock(i)
    if (res !== null) {
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
    fetchData()
  }

  useEffect(() => {
    const filteredData = originalData.filter(
      (item) =>
        item?.productName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.sku?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setTableData((prevTableData) => (searchQuery.trim() === '' ? originalData : filteredData))
  }, [searchQuery, originalData])

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  useEffect(() => {
    const statusFilteredData = originalData.filter((item) => item.status === selectedStatus)
    setTableData((prevTableData) => (selectedStatus === 'All' ? originalData : statusFilteredData))
  }, [selectedStatus, originalData])

  const getStatusColor = (item) => {
    switch (item.status) {
      case 'OutOfStock':
        return <span className='badge badge-light-danger'>Out Of Stock</span>
      case 'Available':
        return <span className='badge badge-light-success'>{item.status}</span>
      case 'Limited':
        return <span className='badge badge-light-warning'>{item.status}</span>
      default:
        return <span className='badge badge-light-primary'>{item.status}</span>
    }
  }

  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      isChecked: masterCheckbox,
    }))
    setTableData(updatedTableData)
  }, [masterCheckbox])

  const fetchTags = async () => {
    const res = await getTagsByOrgId(currentUser?.organization)
    const data = res.map((tag) => ({value: tag, label: tag}))
    setTagList(data)
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const fetchCategories = async () => {
    const res = await getCategoriesForProducts(currentUser?.organization, categoryType)
    console.log('category', res)
    const updatedCategoryOption = res.map((data) => ({
      value: data._id,
      label: data.categoryName,
    }))
    setCategoryOptions(updatedCategoryOption)
  }

  useEffect(() => {
    fetchCategories()
  }, [categoryType])

  let searchFilters = {
    filter: {
      isValid: true,
      status: '',
      category: '',
      tag: '',
    },
  }
  const setSearchFilter = (updateValues) => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }
  const setValidityFilter = (isValid) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        category: '',
        status: '',
        tag: '',
      },
    })
    fetchData()
  }

  const setStatusFilter = (status) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        approval: searchFilters.filter.approval,
        status: status,
      },
    })
  }

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className=' p-4 m-4  card  card-xxl-stretch '>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>
        {categoryType === 'goods'
          ? 'Items'
          : categoryType === 'consumables'
          ? 'Consumables'
          : 'Rentals'}
      </span>

      <div className='d-flex flex-row justify-between card-header py-5 '>
        <div className=' d-flex flex-row  card-title align-items-start'>
          <div className='d-flex rounded-1 items-center justify-center relative space-x-3  mb-4 mb-md-0'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />

              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              />
            </form>
          </div>

          <div className=' d-flex flex-row ml-5'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={fetchData}
              setValidityFilter={setValidityFilter}
              statusOptions={[
                {value: 'OutOfStock', label: 'Out of Stock'},
                {value: 'Limited', label: 'Limited'},
                {value: 'Available', label: 'Available'},
              ]}
              setStatusFilter={setStatusFilter}
              showCategoryFilter={true}
              categoryOptions={categoryOptions}
              showTagFilter={true}
              tagOptions={tagList}
            />
          </div>
        </div>

        <div className=' d-flex flex-row  card-title align-items-start'>
          <div className='d-flex justify-between space-x-4 '>
            <div class='w-100 mw-150px'>
              <select
                class='form-select form-select-solid'
                data-control='select2'
                data-hide-search='true'
                data-placeholder='Status'
                data-kt-ecommerce-product-filter='status'
                onChange={(e) => setSelectedStatus(e.target.value)}
                value={selectedStatus}
              >
                <option></option>
                <option value='All'>All</option>
                <option value='Published'>Published</option>
                <option value='Schedule'>Scheduled</option>
                <option value='Inactive'>Inactive</option>
              </select>
            </div>

            {(permissions?.products?.create || currentUser?.profileType === 'superadmin') && (
              <button
                href='#'
                className='btn btn-sm btn-light-primary w-full whitespace-nowrap'
                onClick={handleAddProductClick}
              >
                <KTIcon iconName='plus' className='fs-2' />
                Add Item
              </button>
            )}
          </div>
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted '>
                <th>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                      checked={masterCheckbox}
                      onChange={() => setMasterCheckbox(!masterCheckbox)}
                    />
                  </div>
                </th>
                <th className='min-w-150px text-center'>ITEM</th>
                <th className='min-w-100px text-end'>SKU</th>
                <th className='min-w-100px text-end'>QTY</th>
                <th className='min-w-100px text-end'>MRQ</th>
                <th className='min-w-100px text-end'>PRICE</th>
                <th className='min-w-150px text-center'>CATEGORY</th>
                <th className='min-w-150px text-center'>TAGS</th>
                <th className='min-w-100px text-center'>STATUS</th>
                {(permissions?.products?.update || currentUser?.profileType === 'superadmin') && (
                  <th className='min-w-100px  text-center'>ACTIONS</th>
                )}
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((item, i) => (
                  <tr key={i} className=''>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                          checked={item.isChecked}
                          onChange={() => {
                            const updatedTableData = [...tableData]
                            updatedTableData[i].isChecked = !updatedTableData[i].isChecked
                            setTableData(updatedTableData)
                          }}
                        />
                      </div>
                    </td>
                    <td className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={`${PF}${item.thumbnail}`}
                            alt=''
                            className='h-50 align-self-center'
                          />
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <Link to={`/product/${categoryType}/view-product/${item._id}`}>
                          <span className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.productName}
                          </span>
                        </Link>
                      </div>
                    </td>
                    <td className='text-muted fw-semibold text-end'>{item.sku}</td>
                    <td className='text-muted fw-semibold text-end'>{item.inWarehouseQuantity}</td>
                    <td className='text-muted fw-semibold text-end'>
                      {item.mrqCheckbox ? item.mrq : 'NA'}
                    </td>
                    <td className='text-muted fw-semibold text-end'>{item.price}</td>
                    <td className={`fw-semibold text-center ${item?.category?.categoryName === 'Uncategorized' ? '!text-red-600 !uppercase' : 'text-muted'}`}>
                      {item.category?.categoryName}
                    </td>
                    <td className='text-muted fw-semibold text-center'>{item.tags.join(', ')}</td>

                    <td className='text-center'>{getStatusColor(item)}</td>
                    {(permissions?.products?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            onClick={() => handleClick(i)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>

                        {dropdown[i] && (
                          <div className='absolute flex flex-col bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] top-16 rounded p-3 z-50 space-y-2'>
                            <Link to={`/product/edit-product/${categoryType}/${item._id}`}>
                              <button className='hover:bg-lime-50 p-2 w-100 text-start rounded'>
                                Edit
                              </button>
                            </Link>
                            {categoryType === 'rentals' && (
                              <Link to={`/maintenance/work-order/add/${item._id}`}>
                                <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                  Schedule a Maintenance
                                </button>
                              </Link>
                            )}
                            <button
                              className='hover:bg-lime-50 p-2 text-start rounded'
                              onClick={() => handleDeleteToggle(i)}
                              data-bs-toggle='modal'
                              data-bs-target='#myModal'
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div class='modal' id='myModal'>
        <div class='modal-dialog modal-dialog-centered '>
          <div class='modal-content'>
            <div class='modal-header'>
              <h4 class='modal-title mx-56 text-yellow-500 '>
                <CgDanger size={100} />
              </h4>
              {/* <button type='button' class='btn-close' data-bs-dismiss='modal'></button> */}
            </div>

            <div class='modal-body text-center fs-6'>
              Are you sure you want to delete {tableData[selectedItem]?.productName} ?
            </div>

            <div class='modal-footer text-center mx-auto'>
              <button
                type='button'
                class='btn btn-danger '
                data-bs-dismiss='modal'
                onClick={() => handleDelete(tableData[selectedItem]?._id)}
              >
                Delete
              </button>
              <button
                type='button'
                class='btn btn-light '
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
