import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import {
  getAccountsForExpensePaidThrough,
  getEmployeeTotalWorkingHoursByProject,
  postPayroll,
} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {
  getEmployeeByStatus,
  getEmployeeTotalWebWorkingHours,
  getEmployeeTotalWorkingHours,
} from '../../hrm/core/_requests'
import {getFilteredProject} from '../../projects/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setActiveProjects} from '../../../redux/projects/projectSlice'

const AddPayroll = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectListData = useSelector((state: any) => state.projects.projects.activeProjects)
  const {currentUser} = useAuth()
  const [paidThroughAccounts, setPaidThroughAccounts] = useState({})
  const [loading, setLoading] = useState(false)
  const [payroll, setPayroll] = useState({
    employeeId: '',
    employeeName: '',
    jobRoleId: '',
    departmentId: '',
    startDate: new Date(),
    endDate: new Date(),
    month: new Date(),
    notes: '',
    status: 'pending',
    salary: 0,
    paidThrough: '',
  })

  // const [employees, setEmployees] = useState([]);
  const payrollList = [
    {value: 'full', label: 'Salary Payment'},
    {value: 'advance', label: 'Advance Payment'},
    {value: 'timesheet', label: 'Attendance Timesheet Payment'},
    {value: 'projecttimesheet', label: 'Project Timesheet Payment'},
  ]
  const [payrollType, setpayrollType] = useState(null)
  const [employeeList, setEmployeeList] = useState<any>([])
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [roles, setRoles] = useState('')
  const [departments, setDepartments] = useState()
  const [totalWorkingHours, setTotalWorkingHours] = useState(0)
  const [totalOvertimeHours, setTotalOvertimeHours] = useState(0)
  const [project, setProject] = useState(null)
  const [projectList, setProjectList] = useState<any>([])

  const fetchProjects = async () => {
    const res = await getFilteredProject('', currentUser?.organization)
    const data = res.map((r) => ({
      value: r._id,
      label: r.projectName,
    }))
    setProjectList(data)
    dispatch(setActiveProjects(data))
  }

  const getEmployeeList = async () => {
    try {
      const res = await getEmployeeByStatus('filter_status=true', currentUser?.organization)
      setEmployeeList(
        res?.map((r) => ({
          value: r._id,
          label: r.firstName,
          jobRoleId: r.role, // Store jobRoleId along with employee data
          departmentId: r.department?._id || '',
          departmentName: r.department?.name, // Store departmentId along with employee data
          hourlyPay: r.hourlyPay,
          overtimePay: r.overtimePay || 0,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getEmployeeTotalWorkingHoursByEmployeeId = async () => {
    const data = {
      from: payroll.startDate,
      to: payroll.endDate,
    }
    if (currentUser?.attendanceMethod === 'mobile') {
      const res = await getEmployeeTotalWorkingHours(payroll.employeeId, data)
      setPayroll((prevData) => ({
        ...prevData,
        salary: res.totalWorkingHours * selectedEmployee?.hourlyPay || 0,
      }))
      setTotalWorkingHours(res.totalWorkingHours)
    } else if (currentUser?.attendanceMethod === 'web') {
      const res = await getEmployeeTotalWebWorkingHours(payroll.employeeId, data)
      setPayroll((prevData) => ({
        ...prevData,
        salary: res.totalWorkingHours * selectedEmployee?.hourlyPay || 0,
      }))
      setTotalWorkingHours(res.totalWorkingHours)
    }
  }

  const getEmployeeTotalWorkingHoursByProjectID = async () => {
    const res = await getEmployeeTotalWorkingHoursByProject(project.value, selectedEmployee.value)
    setPayroll((prevData) => ({
      ...prevData,
      month: res?.startDate,
      startDate: res?.startDate,
      endDate: res?.endDate,
      salary:
        res?.recordedTime?.regular * selectedEmployee?.hourlyPay +
          (res?.recordedTime?.ot * selectedEmployee?.overtimePay || 0) || 0,
    }))
    setTotalWorkingHours(res?.recordedTime?.regular || 0)
    setTotalOvertimeHours(res?.recordedTime?.ot || 0)
  }

  useEffect(() => {
    if (
      payrollType?.value === 'projecttimesheet' &&
      selectedEmployee &&
      project &&
      project.value &&
      selectedEmployee.value
    ) {
      getEmployeeTotalWorkingHoursByProjectID()
    }
  }, [selectedEmployee, payrollType, project])

  const handlepayrollChange = (selectedOption) => {
    setpayrollType(selectedOption)
    console.log(selectedOption)
  }

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption)
    const employeeId = selectedOption?.value || ''
    // const selectedEmployee = employeeList.find((emp) => emp._id === value)

    setPayroll((prevData) => ({
      ...prevData,
      employeeId: employeeId, // Corrected the key from "employee" to "employeeId"
      employeeName: selectedOption.label,
      jobRoleId: selectedOption?.jobRoleId || '', // Update jobRoleId based on selected employee
      departmentId: selectedOption?.departmentId || '',
    }))
    setDepartments(selectedOption?.departmentName)
    setRoles(selectedOption?.jobRoleId || '')
  }

  const handleChange = (e) => {
    const {name, value} = e.target
    setPayroll({...payroll, [name]: value})

    // If the changed field is employeeId, find the selected employee and update jobRoleId and departmentId
    if (name === 'employeeId') {
      const selectedEmployee = employeeList.find((emp) => emp._id === value)
      if (selectedEmployee) {
        setPayroll({
          ...payroll,
          jobRoleId: selectedEmployee.jobRoleId,
          departmentId: selectedEmployee.department._id,
        })
      }
    }
  }

  const handleDateChange = (date, name) => {
    setPayroll({...payroll, [name]: date[0]})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    console.log(payroll)
    const data = {
      ...payroll,
      type: payrollType,
      salary: Number(payroll.salary),
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      await postPayroll(data).then((res) => {
        console.log('Payroll added:', res)
        setLoading(false)
        navigate('/hrm/payroll')
      })
    } catch (error) {
      console.error('Error submitting leave application:', error)
    }
  }

  const fetchAccounts = async () => {
    const resp = await getAccountsForExpensePaidThrough(currentUser?.organization)
    const paidThrough = {
      Cash: [],
      Bank: [],
      'Other Current Liability': [],
      Equity: [],
      'Other Current Asset': [],
      'Fixed Asset': [],
    }
    resp.forEach((r) => {
      if (r.accountType === 'cash') {
        paidThrough['Cash'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'bank') {
        paidThrough['Bank'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentliability') {
        paidThrough['Other Current Liability'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'equity') {
        paidThrough['Equity'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'othercurrentasset') {
        paidThrough['Other Current Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      } else if (r.accountType === 'fixedasset') {
        paidThrough['Fixed Asset'].push({
          value: r?._id,
          label: r?.accountName,
        })
      }
    })
    setPaidThroughAccounts(paidThrough)
    setPayroll((prev) => ({
      ...prev,
      paidThrough: paidThrough['Cash'][0]?.value,
    }))
  }

  useEffect(() => {
    getEmployeeList()
    fetchAccounts()
    if (projectListData.length === 0) fetchProjects()
    else {
      setProjectList(projectListData)
    }
  }, [])

  useEffect(() => {
    if (payrollType?.value === 'timesheet' && selectedEmployee) {
      getEmployeeTotalWorkingHoursByEmployeeId()
    }
  }, [payroll.startDate, payroll.endDate, selectedEmployee, payrollType])

  return (
    <div className='container-lg'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className='card card-custom card-stretch'>
            <div className='card-body'>
              <h2 className='text-center mb-4'>Add Payroll</h2>
              <form onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label htmlFor='employeeId' className='form-label'>
                    Employee
                  </label>
                  <Select
                    name='employeeId'
                    options={employeeList}
                    onChange={handleEmployeeChange}
                    value={selectedEmployee}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='jobRoleId' className='form-label'>
                    Job Role
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='jobRole'
                    name='jobRoleId'
                    value={roles}
                    onChange={(e) => setRoles(e.target.value)}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='departmentId' className='form-label'>
                    Department
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='departmentId'
                    name='departmentId'
                    value={departments}
                    // onChange={handleChange}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='payroll' className='form-label'>
                    Payroll Type
                  </label>
                  <Select
                    name='payroll'
                    options={payrollList}
                    onChange={handlepayrollChange}
                    value={payrollType}
                  />
                </div>
                {payrollType?.value === 'projecttimesheet' && (
                  <div className='mb-3'>
                    <label htmlFor='project' className='form-label required'>
                      Select Project
                    </label>
                    <Select
                      name='project'
                      options={projectList}
                      onChange={(selectedOption: any) => setProject(selectedOption)}
                      value={project}
                    />
                  </div>
                )}
                {/* amount input */}
                <div className='mb-3'>
                  <label htmlFor='month' className='form-label'>
                    Select Month
                  </label>
                  <Flatpickr
                    value={payroll.month}
                    onChange={(date) => handleDateChange(date, 'month')}
                    className='form-control'
                    options={{dateFormat: 'F Y'}}
                    disabled={payrollType?.value === 'projecttimesheet'}
                  />
                </div>
                <div className='row gx-8'>
                  <div className='mb-3 col-6'>
                    <label htmlFor='startDate' className='form-label'>
                      Start Date
                    </label>
                    <Flatpickr
                      value={payroll.startDate}
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      className='form-control'
                      options={{
                        dateFormat: 'd-m-Y',
                      }}
                      disabled={payrollType?.value === 'projecttimesheet'}
                    />
                  </div>
                  <div className='mb-3 col-6'>
                    <label htmlFor='endDate' className='form-label'>
                      End Date
                    </label>
                    <Flatpickr
                      value={payroll.endDate}
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      className='form-control'
                      options={{
                        dateFormat: 'd-m-Y',
                      }}
                      disabled={payrollType?.value === 'projecttimesheet'}
                    />
                  </div>
                </div>
                {(payrollType?.value === 'timesheet' ||
                  payrollType?.value === 'projecttimesheet') && (
                  <div className='row gx-8 mb-3'>
                    <div className='col-6'>
                      <label htmlFor='totalHours' className='form-label'>
                        Total Hours
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='totalHours'
                        name='totalHours'
                        value={totalWorkingHours}
                        readOnly
                      />
                    </div>
                    <div className='col-6'>
                      <label htmlFor='hourlyPay' className='form-label'>
                        Hourly Pay
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='hourlyPay'
                        name='hourlyPay'
                        value={selectedEmployee?.hourlyPay}
                        readOnly
                      />
                    </div>
                  </div>
                )}
                {payrollType?.value === 'projecttimesheet' && (
                  <div className='row gx-8 mb-3'>
                    <div className='col-6'>
                      <label htmlFor='totalHours' className='form-label'>
                        Total Over Time Hours
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='totalHours'
                        name='totalHours'
                        value={totalOvertimeHours}
                        readOnly
                      />
                    </div>
                    <div className='col-6'>
                      <label htmlFor='hourlyPay' className='form-label'>
                        Over Time Hourly Pay
                      </label>
                      <input
                        type='number'
                        className='form-control'
                        id='hourlyPay'
                        name='hourlyPay'
                        value={selectedEmployee?.overtimePay || 0}
                        readOnly
                      />
                    </div>
                  </div>
                )}
                <div className='mb-3'>
                  <label htmlFor='salary' className='form-label'>
                    Salary
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    id='salary'
                    name='salary'
                    value={payroll.salary}
                    onChange={handleChange}
                  />
                </div>
                <div className='mb-6'>
                  <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                    Paid Through
                  </label>
                  {/* begin::Input group */}
                  <select
                    className='form-select form-select-solid'
                    value={payroll.paidThrough}
                    name='paidThrough'
                    onChange={handleChange}
                  >
                    {Object.keys(paidThroughAccounts).map((key, index) => {
                      return (
                        <optgroup label={key} key={index}>
                          {paidThroughAccounts[key].map((item) => {
                            return (
                              <option value={item?.value} key={item?.value}>
                                {item?.label}
                              </option>
                            )
                          })}
                        </optgroup>
                      )
                    })}
                  </select>
                </div>
                <div className='mb-3'>
                  <label htmlFor='notes' className='form-label'>
                    Notes
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='notes'
                    name='notes'
                    value={payroll.notes}
                    onChange={handleChange}
                  />
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    <span className='indicator-label'>
                      {loading ? 'Please wait...' : 'Add Payroll'}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPayroll
