import React from 'react'
import {Route, Router, Routes} from 'react-router-dom'
import EquipmentList from '../components/EquipmentList'

const Equipment = () => {
  return (
    <Routes>
      <Route path='/' element={<EquipmentList />} />
    </Routes>
  )
}

export default Equipment
