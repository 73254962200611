import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {getProjectReportSubmissionCount} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setProjectReport} from '../../../redux/projects/projectReportSlice'

type ProjectReportSubmissionProps = {
  showAddButton?: boolean
  showCountColumn?: boolean
}

const ProjectReportSubmission: React.FC<ProjectReportSubmissionProps> = ({
  showAddButton = true,
  showCountColumn = true,
}) => {
  const dispatch = useDispatch()
  const list = useSelector((state: any) => state.projects.projectReport.projectReport)
  const loading = useSelector((state: any) => state.projects.projectReport.loading)
  const navigate = useNavigate()

  const fetchReportsSubmission = async () => {
    const res = await getProjectReportSubmissionCount()
    console.log(res)
    dispatch(setProjectReport(res))
  }

  useEffect(() => {
    if (loading) fetchReportsSubmission()
  }, [])

  const handleAdd = () => {
    navigate('add')
  }

  if (loading)
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )

  return (
    <div className='container'>
      <div className='card' style={{height: '500px', overflowY: 'auto'}}>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title fw-bold fs-3 mb-1'>Project Report Submission History</h4>
          {showAddButton && (
            <div className='btn btn-primary' onClick={handleAdd}>
              <KTIcon iconName='plus' className='me-2' />
              Report Submission
            </div>
          )}
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Form Name</th>
                  <th className='min-w-125px'>Project</th>
                  <th className='min-w-125px'>User</th>
                  <th className='min-w-125px'>Date</th>
                  {showCountColumn && <th className='min-w-125px'>Count</th>}{' '}
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/project/reports/viewforms/${item?._id}`}>{item?.title}</Link>
                      </td>
                      <td>{item?.projectName}</td>
                      <td>{item?.fullName}</td>
                      <td>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</td>
                      {showCountColumn && <td>{item?.submissionCount}</td>}

                      <td>
                        {/* <Link to={`/inspection/history/view/${item?._id}`}> */}
                        {/* {item?.department?.name} */}
                        {/* </Link> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectReportSubmission
