import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  consumablesData: [],
  loading: true,
  fetch: true,
}

const consumablesSlice = createSlice({
  name: 'consumables',
  initialState,
  reducers: {
    setConsumablesData(state, action) {
      state.consumablesData = action.payload
      state.loading = false
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
    },
    addConsumables(state, action) {
      state.consumablesData.push(action.payload)
    },
    setConsumablesDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setConsumablesData, setLoading, addConsumables, setConsumablesDataFetch} =
  consumablesSlice.actions
export default consumablesSlice.reducer
