import React, { useEffect, useRef } from 'react'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../_metronic/partials'
import ApexCharts, {ApexOptions} from 'apexcharts'

const SalesStat = () => {
    const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])
  return (
    <div>
         <div
          ref={chartRef}
          id='kt_charts_widget_8_chart'
          style={{height: '350px'}}
          className='card-rounded-bottom'
        ></div>
    </div>
  )
}

export default SalesStat
function getChartOptions(height: number): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const strokeColor = getCSSVariableValue('--bs-gray-300')
  
    const color1 = getCSSVariableValue('--bs-warning')
    const color1Light = getCSSVariableValue('--bs-warning')
  
    const color2 = getCSSVariableValue('--bs-success')
    const color2Light = getCSSVariableValue('--bs-success')
  
    const color3 = getCSSVariableValue('--bs-primary')
    const color3Light = getCSSVariableValue('--bs-primary')
  
    return {
      series: [
        {
          name: 'Net Profit',
          data: [321, 346, 338, 327, 305, 309,312,301,197,193,111,87,79,81,75,88,87,71],
        },
        {
          name: 'Revenue',
          data: [650, 683, 691, 642, 699, 721,737,680,664,648,697,633,621,210,232,219,285,277,],
        },
        {
          name: 'Expenses',
          data: [1587, 1567, 1617, 1630, 1660, 1683,1691,1298,1275,1246,1318,1213,1199,1110,1165,1145,1180,1159],
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: height,
        stacked:true,
        stackType:'normal',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient:
        {
          type: 'vertical',
          shadeIntensity: 0,
          opacityFrom: 0.8,
          opacityTo: 0.2,
          stops: [0, 100],
        }
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 0,
        
      },
      xaxis: {
        categories: ['2003','2004','2005','2006','2007','2008','2009','2010', '2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021',],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          
          position: 'front',
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
         
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        shared: false,

        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '$' + val + ' thousands'
          },
        },
      },
      colors: [color1Light, color2Light, color3Light],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
            lines: {
              show: true,
            },
          },
       
      },
      markers: {
        colors: [color1, color2, color3],
        strokeColors: [color1, color2, color3],
        strokeWidth: 3,
      },
    }
  }