import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {editDeliveryNotes, getDeliveryNotes, getDeliveryNotesDetailById} from '../Core/_requests'
import {useNavigate, useParams} from 'react-router-dom'

const EditDeliveryNotes = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const deliveryNoteId = useParams().id
  const [orderId, setOrderId] = useState('')
  const [quotationName, setQuotationName] = useState('')
  const [quotationId, setQuotationId] = useState('')
  const [permissions, setPermissions] = useState<any>({})
  const [customerName, setCustomerName] = useState(null)
  const [deliveryDate, setDeliveryDate] = useState(new Date())
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState(' ')
  const [selectedQuotationItems, setSelectedQuotationItems] = useState([
    {
      productName: '',
      itemId: null,
      itemsId: null,
      price: '',
      quantity: 0,
      type: 'product',
      deliveredQuantity: 0,
      deliveringQuantity: 0,
      balanceQuantity: 0,
    },
  ])

  // const [Note, setNote] = useState(initialDeliveryNote)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)

  const [deliveryNoteIds, setDeliveryNoteIds] = useState('')

  const [deliveryNote, setDeliveryNote] = useState({
    customer: '',
    id: '',
    reference: '',
    date: new Date(),
    challanType: '',
    items: [],
    notes: ' ',
    termsNCondition: ' ',
    document: '',
  })

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (deliveryNote.termsNCondition === '') {
      setTermsTextareaHeight('3rem')
    }
  }, [deliveryNote.termsNCondition])

  useEffect(() => {
    if (deliveryNote.notes === '') {
      setNotesTextareaHeight('3rem')
    }
  }, [deliveryNote.notes])

  //Function to handle changes in input fields
  const handleChange = (e) => {
    const {name, value} = e.target
    console.log('change', name, value)
    if (name === 'notes' && value.trim() === '') {
      setDeliveryNote((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setDeliveryNote((prevNote) => ({...prevNote, [name]: value}))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setDeliveryNote((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const handleDateChange = (date) => {
    setDeliveryDate(date)
  }

  useEffect(() => {
    setSelectedQuotationItems((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          balanceQuantity: item.quantity - item.deliveredQuantity - item.deliveringQuantity,
        }
      })
    })
  }, [selectedQuotationItems])

  // Function to handle form submission
  const handleEdit = async (e) => {
    e.preventDefault()
    const data = {
      ...deliveryNote,
      items: selectedQuotationItems,
      quotationId: quotationId,
      order: orderId,
      id: deliveryNoteIds,
      approval: permissions?.deliverynote?.enable ? 'accepted' : 'pending',
      company: currentUser?.company,
      organization: currentUser?.organization,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      await editDeliveryNotes(data, deliveryNoteId).then((res) => {
        navigate(`/sales/deliveryChallan`)
      })
    } catch (error) {
      console.error('Error submitting delivery note', error)
    }
  }

  useEffect(() => {
    const fetchDeliveryNotes = async () => {
      try {
        const deliveryNotesList = await getDeliveryNotes(currentUser?.organization)
        console.log('list', deliveryNotesList)

        if (deliveryNotesList && deliveryNotesList.length > 0) {
          const firstDeliveryNote = deliveryNotesList[0]

          setDeliveryNote({
            ...deliveryNote,
            notes: firstDeliveryNote.notes || 'Default notes value',
            termsNCondition:
              firstDeliveryNote.termsNCondition || 'Default terms and condition value',
          })
        }
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchDeliveryNotes()
  }, [])

  useEffect(() => {
    const fetchDeliveryNoteData = async () => {
      const res = await getDeliveryNotesDetailById(deliveryNoteId)
      console.log(res)
      setDeliveryNote({
        ...res,
        customer: res.customer?._id,
        reference: res.reference,
        date: new Date(res.date),
        challanType: res.challanType,
        notes: res.notes,
        termsNCondition: res.termsNCondition,
        document: res.document,
      })
      setDeliveryNoteIds(res.id)
      setOrderId(res.order)
      setCustomerName(res.customer?.displayName)
      setQuotationName(res.quotationId?.id)
      setQuotationId(res.quotationId?._id)
      setSelectedQuotationItems(
        res.items.map((item) => {
          return {
            productName: item.itemId ? item?.productName : item?.productName,
            itemId: item.itemId?._id || null,
            itemsId: item.itemsId?._id || null,
            price: item.price,
            quantity: item.quantity,
            type: item.type,
            deliveredQuantity: item.deliveredQuantity,
            deliveringQuantity: item.deliveringQuantity,
            balanceQuantity: item.balanceQuantity,
          }
        })
      )
    }

    fetchDeliveryNoteData()
  }, [])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            <div className='card'>
              <div className='card-body p-12'>
                <form action='' id='kt_quote_form'>
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify deliveryNote date'
                    >
                      <div className=' d-flex align-items-center w-150px'></div>
                    </div>

                    <div className='d-flex align-items-center flex-equal fw-row me-4 order-2'>
                      <span className='fs-2x fw-bold text-gray-800'>DELIVERY CHALLAN #</span>
                      <span className='fs-2x fw-bold text-gray-800'>{deliveryNoteIds}</span>
                    </div>
                  </div>

                  <div className='separator separator-dashed my-10'></div>

                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'></div>

                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Customer Name
                      </label>

                      <span className='fs-6 fw-bold text-gray-800'>{customerName}</span>
                    </div>

                    <div className='w-50'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Quotation #
                      </label>
                      <span className='fs-6 fw-bold text-gray-800'>{quotationName}</span>
                    </div>

                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Date</label>
                        <Flatpickr
                          value={deliveryDate}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick delivery date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                    </div>

                    <div className='fs-6 fw-bold text-gray-700 mb-3'>ITEMS & DESCRIPTION</div>
                    <div className='table-responsive mb-10'>
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-500px'>Item</th>
                            <th className='min-w-150px w-250px'>Quantity</th>
                            <th className='min-w-50px w-250px'>Delivered Quantity</th>
                            <th className='min-w-70px w-150px'>Delivering Quantity</th>
                            <th className='min-w-70px w-250px'>Balance Quantity</th>
                          </tr>
                        </thead>

                        <tbody className=''>
                          {selectedQuotationItems.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='productName'
                                  placeholder=''
                                  value={item?.productName}
                                  disabled={true}
                                />
                              </td>
                              <td className='pe-7'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='quantity'
                                  placeholder='0.00'
                                  value={item.quantity}
                                  disabled
                                />
                              </td>

                              <td className='pe-7'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='deliveredQuantity'
                                  placeholder='0.00'
                                  value={item.deliveredQuantity}
                                  onChange={(e) => {
                                    setSelectedQuotationItems((prevItems) => {
                                      const updatedItems = [...prevItems]
                                      if (Number(e.target.value) > item.quantity) {
                                        return updatedItems
                                      } else if (Number(e.target.value) < 0) {
                                        return updatedItems
                                      }
                                      updatedItems[index] = {
                                        ...updatedItems[index],
                                        deliveredQuantity: Number(e.target.value),
                                      }
                                      return updatedItems
                                    })
                                  }}
                                />
                              </td>

                              <td className='pe-7'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid'
                                  name='deliveringQuantity'
                                  placeholder='0.00'
                                  value={item.deliveringQuantity}
                                  onChange={(e) => {
                                    setSelectedQuotationItems((prevItems) => {
                                      const updatedItems = [...prevItems]
                                      if (Number(e.target.value) > item.quantity) {
                                        return updatedItems
                                      } else if (Number(e.target.value) < 0) {
                                        return updatedItems
                                      }
                                      updatedItems[index] = {
                                        ...updatedItems[index],
                                        deliveringQuantity: Number(e.target.value),
                                      }
                                      return updatedItems
                                    })
                                  }}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='balanceQuantity'
                                  placeholder='0.00'
                                  value={item.balanceQuantity}
                                  disabled
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Customer Notes
                      </label>
                      <textarea
                        name='notes'
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={deliveryNote.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '3rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-16 bottom-64 fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '3rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`position-relative  form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={deliveryNote.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-16 bottom-36 fs-3'
                        />
                      </button>
                    </div>
                    <div></div>

                    <button
                      className='btn btn-primary w-30'
                      id='kt_quote_submit_button'
                      onClick={handleEdit}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Edit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EditDeliveryNotes
