import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Dropdown1, useThemeMode} from '../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import {getIncomeNExpense} from '../../account/core/_requests'

type Props = {
  className: string
}

interface MonthlyData {
  income: number
  expense: number
}

export interface ApiResponse {
  [month: string]: MonthlyData
}

const Chart3: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [chartData, setChartData] = useState<{income: number[]; expense: number[]}>({
    income: [],
    expense: [],
  })
  const [chart, setChart] = useState<ApexCharts | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getIncomeNExpense(currentUser.organization)
        const monthlyIncome = new Array(12).fill(0)
        const monthlyExpense = new Array(12).fill(0)

        for (const [month, values] of Object.entries(data)) {
          const monthIndex = parseInt(month, 10) - 1
          if (monthIndex >= 0 && monthIndex < 12) {
            monthlyIncome[monthIndex] = values.income
            monthlyExpense[monthIndex] = values.expense
          }
        }

        setChartData({
          income: monthlyIncome,
          expense: monthlyExpense,
        })
      } catch (error) {
        console.error('Error fetching chart data:', error)
      }
    }

    if (currentUser.organization !== undefined) fetchData()
  }, [currentUser.organization])

  useEffect(() => {
    if (chartRef.current && chartData.income.length > 0 && chartData.expense.length > 0) {
      const height = parseInt(getCSS(chartRef.current, 'height'))
      const chartOptions = getChartOptions(height, chartData)

      if (chart) {
        chart.updateOptions(chartOptions)
      } else {
        const newChart = new ApexCharts(chartRef.current, chartOptions)
        newChart.render()
        setChart(newChart)
      }
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartData, chart])

  return (
    <div className={`card ${className} h-100`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Income and Expense</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '300px'}} />
      </div>
    </div>
  )
}

export {Chart3}

function getChartOptions(
  height: number,
  chartData: {income: number[]; expense: number[]}
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-success-500')
  const borderColor = getCSSVariableValue('--bs-success-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-success')

  return {
    series: [
      {
        name: 'Income',
        data: chartData.income,
      },
      {
        name: 'Expense',
        data: chartData.expense,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: -4,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return (
            'SAR ' +
            val?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })
          )
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
