import {createSlice} from '@reduxjs/toolkit'

const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    vendorData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setVendorDataList(state, action) {
      state.vendorData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setVendorDataList, setLoading, setError} = vendorSlice.actions
export default vendorSlice.reducer
