/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className: string
  description: string
  change: string
  color: string
}

const Chart4: React.FC<Props> = ({className, description, change, color}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card mt-2 ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-4'>
        <div className='card-header align-items-center border-0 mt-4'>
          <h4 className='card-title align-items-start flex-column'>
            <span className='fw-semibold text-dark'>Product Performance</span>
          </h4>
        </div>
        {/* <div className='d-flex flex-stack flex-grow-1 card-p'>
          <div className='d-flex flex-column me-2'>
            <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
              {title}
            </a>

            <span
              className='text-muted fw-semibold mt-1'
              dangerouslySetInnerHTML={{__html: description}}
            ></span>
          </div>

          <span className='symbol symbol-50px'>
            <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
              {change}
            </span>
          </span>
        </div> */}
        <div
          ref={chartRef}
          className='statistics-widget-3-chart card-rounded-bottom'
          style={{height: '150px'}}
        ></div>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select pt-4 form-select-white form-select-sm '
          defaultValue='Contact'
          style={{border: 'none'}}
        >
          <option value='Contact Details'>Contact Details</option>
        </select>
        <select
          name='status'
          data-control='select2'
          data-hide-search='true'
          className='form-select form-select-white pt-4 form-select-sm '
          defaultValue='Order History'
          style={{border: 'none'}}
        >
          <option value='Order History'>Order History</option>
        </select>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Chart4}

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-primary-light')

  const baseColor1 = getCSSVariableValue('--bs-primary')
  const baseColor2 = getCSSVariableValue('--bs-success')
  const baseLightColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-success-light')
  const secondaryLightColor = getCSSVariableValue('--bs-success')
  const lightColor = getCSSVariableValue('--bs-primary-light')
  return {
    series: [
      {
        name: 'Net Profit',
        data: [80, 70, 100, 90, 75, 80],
      },
      {
        name: 'complete',
        data: [55, 55, 60, 60, 55, 55],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor1, baseColor2],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 40,
      max: 120,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    /* colors: [lightColor,secondaryColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    }, */
    colors: [lightColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      colors: [baseLightColor, secondaryLightColor],
      strokeColors: [baseLightColor, secondaryLightColor],
      strokeWidth: 3,
    },
  }
}
