import {createSlice} from '@reduxjs/toolkit'

const payrollSlice = createSlice({
  name: 'payroll',
  initialState: {
    payrollData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setPayrollData(state, action) {
      state.payrollData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setPayrollData, setLoading, setError} = payrollSlice.actions
export default payrollSlice.reducer
