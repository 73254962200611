import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import { useAuth } from '../../../../modules/auth'
import { ICreateAccount } from '../../core/CreateAccountWizardHelper'
import { getDepartmentNRoles } from '../../core/_requests'

const Step1: FC = () => {
  const {currentUser} = useAuth()
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  const [departmentList, setDepartmentList] = useState([])

  const fetchDepartmentsNRoles = async () => {
    const res = await getDepartmentNRoles(currentUser?.organization)
    console.log(res)
    if (res) {
      setDepartmentList(res?.department)
    }
  }

  useEffect(() => {
    fetchDepartmentsNRoles()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Basic Information</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div>
      </div>
      {/* 
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='1-1'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>1-1</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='2-10'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>2-10</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='10-50'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>10-50</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='50+'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>50+</span>
            </label>
          </div>
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div> */}

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Employee Id</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='employeeId'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='employeeId' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='required form-label mb-3'>First Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='firstName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='firstName' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='required form-label mb-3'>Last Name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='lastName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='lastName' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Nick name</label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='nickName'
        />
        {/* <div className='text-danger mt-2'>
          <ErrorMessage name='lastName' />
        </div> */}
      </div>

      <div className=' mb-10 fv-row'>
        <label className='required form-label mb-3'>Email Adress</label>

        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name='email'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='email' />
        </div>
      </div>

      {/* toggle switch for add user */}
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label fw-bold fs-6'>Add User</label>
        <div className='col-lg-8 fv-row'>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field className='form-check-input' type='checkbox' name='addUser' />
            <span className='form-check-label'>Add User</span>
          </label>
        </div>
      </div>
      
      {
        values.addUser && (
          <>
            <div className='mb-10 fv-row'>
              <label className='required form-label mb-3'>Password</label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='password'
              />
            </div>

            {/* department */}
            <div className='mb-10 fv-row'>
              <label className='required form-label mb-3'>Department</label>

              <Field
                as='select'
                className='form-select form-select-lg form-select-solid'
                name='departmentID'
              >
                <option value=''>Select Department</option>
                {departmentList?.map((department: any) => (
                  <option key={department?._id} value={department?._id}>
                    {department?.name}
                  </option>
                ))}
              </Field>
            </div>

              {/* roles from selected department.roles */}
            <div className='mb-10 fv-row'>
              <label className='required form-label mb-3'>Role</label>

              <Field
                as='select'
                className='form-select form-select-lg form-select-solid'
                name='roleID'
              >
                <option value=''>Select Role</option>
                {
                  departmentList?.find((department: any) => department._id === values.departmentID)?.roles.map((role: any) => (
                    <option key={role._id} value={role.name}>
                      {role.name}
                    </option>
                  ))
                }
              </Field>
              </div>

          </>
        )
      }

      {/* <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select Account Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='bank' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Company Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance your post flow
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Developer Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart-pie-4' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Testing Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance time travel rivers
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='3' />
            </span>
          </label>
        </div>
      </div> */}
    </div>
  )
}

export {Step1}
