import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import AddProduct from './Pages/AddProduct'
import ViewProduct from './Pages/ViewProduct'
import EditProduct from './Pages/EditProduct'

export default function Products() {
  return (
    <Routes>
      <Route path='/:categoryType/view-product/:id' element={<ViewProduct />} />
      <Route path='/:categoryType' element={<Home />} />
      <Route path='/:categoryType/add-product/' element={<AddProduct />} />
      <Route path='/edit-product/:categoryType/:id' element={<EditProduct />} />
    </Routes>
  )
}
