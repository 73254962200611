import {combineReducers} from '@reduxjs/toolkit'

import taskReducer from './taskSlice'
import calenderReducer from './calendarSlice'
import meetingReducer from './meetingSlice'
import sprintReducer from './sprintSlice'

const toolsReducer = combineReducers({
  task: taskReducer,
  calender: calenderReducer,
  meeting: meetingReducer,
  sprint: sprintReducer,
})

export default toolsReducer
