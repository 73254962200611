import React, {useRef, useEffect, useState} from 'react'
import {Col, Row, Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, {Draggable} from '@fullcalendar/interaction'
import Swal from 'sweetalert2'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import {addevents, deletetodo, editEvent, getAllevents, getevents, gettodo} from './core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AgentList from './AgentList'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {getCustomerByStatus, getUsersByOrgId} from '../sales/Core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setCalendarData} from '../../redux/tools/calendarSlice'
import {RootState} from '../../redux/store'

const EventCalendar = () => {
  const calendarComponentRef = useRef(null)
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const calendarData = useSelector((state: any) => state.tools.calender.calenderData)
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])
  const [calendarEvents, setCalendarEvents] = useState([])
  const [todolist, settodolist] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [title, settitle] = useState('')
  const [description, setDescription] = useState('')
  const [reminder, setReminder] = useState('5')
  const [selectedOption, setSelectedOption] = useState('')
  const [assiopen, setassiopen] = useState(false)
  const [color, setcolor] = useState('')
  const [agentids, setagentids] = useState<string[]>([])
  const editTodo = useRef(null)
  const [editTodoId, seteditTodoId] = useState('')
  const [property, setProperty] = useState('todo')
  const [clientsList, setClientsList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [calendarEventModal, setCalendarEventModal] = useState<any>({})
  const [agentsList, setAgentsList] = useState<any>([])
  const [selectedAgent, setselectedAgent] = useState<any>(null)

  const handleShowModal = (e) => {
    setShowModal(true)
    setCalendarEventModal(e.event)
  }
  const handleCloseModal = () => {
    setShowModal(false)
    setCalendarEventModal({})
  }

  const handleDeleteEvent = () => {
    handleCloseModal()
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        const resp = handleDeleteTodo(calendarEventModal?._def?.extendedProps?._id)
        if (resp) {
          settodolist((prev) => {
            return prev.filter((item) => item._id !== calendarEventModal?._def?.extendedProps?._id)
          })
          calendarEventModal?.remove()
          Swal.fire('Deleted!', 'Your Event has been deleted.', 'success')
        }
      }
    })
  }

  const handleEditEvent = () => {
    handleCloseModal()
    settitle(calendarEventModal?.title)
    setSelectedDate(calendarEventModal?.start)
    setSelectedOption(calendarEventModal?._def?.extendedProps?.priority)
    setcolor(calendarEventModal?._def?.extendedProps?.color)
    setProperty(calendarEventModal?._def?.extendedProps?.type)
    setselectedBillFrom({
      label: calendarEventModal?._def?.extendedProps?.client?.name,
      value: calendarEventModal?._def?.extendedProps?.client?.name,
    })
    setagentids(calendarEventModal?._def?.extendedProps?.agent)
    seteditTodoId(calendarEventModal?._def?.extendedProps?._id)
    setDescription(calendarEventModal?._def?.extendedProps?.description)
    setReminder(calendarEventModal?._def?.extendedProps?.reminder)
    Swal.fire({
      title: 'Edit Event ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Edit it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        editTodo.current.click()
      }
    })
  }

  const getClientList = async () => {
    try {
      const res = await getCustomerByStatus('', currentUser?.organization)
      setClientsList(
        res.map((r) => ({
          value: r._id,
          label: r.displayName, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getAgenttList = async () => {
    try {
      const res = await getUsersByOrgId(currentUser?.organization)
      setAgentsList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (currentUser?.profileType === 'superadmin' || permissions?.calendar?.admin) {
      getClientList()
      getAgenttList()
    }
  }, [permissions])

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (selectedOption === 'Important') {
      setcolor('#dc3545')
    } else if (selectedOption === 'Medium') {
      setcolor('#ffc107')
    } else {
      setcolor('#28a745')
    }
  }, [selectedOption])

  useEffect(() => {
    console.log(color)
  }, [color])

  const getevent = async () => {
    try {
      if (permissions?.calendar?.admin === false) {
        const res = await getevents(currentUser?._id)
        setCalendarEvents(res) // Assuming that res is an array
        console.log(res)
        dispatch(setCalendarData(res))
      } else if (currentUser?.profileType === 'superadmin' || permissions?.calendar?.admin) {
        const res = await getAllevents(currentUser?.organization)
        setCalendarEvents(res) // Assuming that res is an array
        console.log('hi', res)
        dispatch(setCalendarData(res))
      }
      const rest = await gettodo(currentUser?._id)
      settodolist(rest) // Assuming that res is an array
    } catch (error) {
      console.error('Error fetching todo list:', error)
    }
  }

  useEffect(() => {
    if (calendarData.length === 0) getevent()
    else {
      setCalendarEvents(calendarData)
    }
  }, [permissions])

  const handletodosubmit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: property === 'task' ? 'todo' : property,
      customer: selectedBillFrom ? selectedBillFrom.value : null,
      priority: selectedOption,
      color: color,
      company: currentUser?.company,
      organization: currentUser?.organization,
      agentids,
      description,
      reminder,
    }

    await addevents(todo, currentUser?._id).then((res) => {
      const updatevents = [...calendarEvents, res]
      console.log(res)
      settitle('')
      setSelectedOption('')
      setcolor('')
      setSelectedDate(new Date())
      setagentids([])
      setCalendarEvents(updatevents)
      getevent()
      setProperty('')
      setDescription('')
      setReminder('')
      setselectedBillFrom(null)

      const closeModalButton = document.getElementById('closeModalButton')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })

    // Close the modal
  }
  const handletodoedit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: property === 'task' ? 'todo' : property,
      customer: selectedBillFrom.value,
      priority: selectedOption,
      color: color,
      company: currentUser?.company,
      organization: currentUser?.organization,
      agentids,
      description,
      reminder,
    }

    await editEvent(editTodoId, todo).then((res) => {
      const updatevents = [...calendarEvents, res]
      settitle('')
      setSelectedOption('')
      setcolor('')
      setSelectedDate(new Date())
      setagentids([])
      setCalendarEvents(updatevents)
      setProperty('')
      setselectedBillFrom(null)
      setDescription('')
      setReminder('')
      getevent()

      const closeModalButton = document.getElementById('closeModalButton2')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })

    // Close the modal
  }

  useEffect(() => {
    let draggableEl = document.getElementById('external-events')
    new Draggable(draggableEl, {
      itemSelector: '.fc-event',
      eventData: function (eventEl) {
        let title = eventEl.getAttribute('title')
        let id = eventEl.getAttribute('data')
        return {
          title: title,
          id: id,
        }
      },
    })
  }, [])

  //  const calendarEvent = [
  //     {
  //        title: "Lunch",
  //        start: new Date("2023-09-05 00:00"),
  //        id: "12344522",
  //        color:'#28a745',

  //     },
  //     {
  //        title: "Repeating Event",
  //        start: new Date("2023-09-12 00:00"),
  //        end: new Date("2023-09-13 10:00"),
  //        id: "12322511",
  //        color: "green"
  //     },
  //     {
  //        title: "Atlanta Monster",
  //        start: new Date("2023-09-16 00:00"),
  //        id: "12311523",
  //        color: "green"
  //     },
  //     {
  //        title: "Event",
  //        start: new Date("2023-09-21 00:00"),
  //        end: new Date("2023-09-22 10:00"),
  //        id: "12334566",
  //         color: "green"
  //     },
  //     {
  //        title: "My Favorite",
  //        start: new Date("2023-10-01 00:00"),
  //        id: "12343210",
  //     },
  //     {
  //        title: "Birthday",
  //        start: new Date("2023-10-10 00:00"),
  //        id: "12344577",
  //     },
  //  ];

  const events = [
    {title: 'Event 1', id: '1'},
    {title: 'Event 2', id: '2'},
    {title: 'Event 3', id: '3'},
    {title: 'Event 4', id: '4'},
    {title: 'Event 5', id: '5'},
  ]

  //  const eventClick = (eventClick) => {
  //     Swal.fire({
  //        title: eventClick.event.title,
  //        html: `<div class="table-responsive">
  //           <table class="table">
  //              <tbody>
  //                 <tr>
  //                    <td>Title</td>
  //                    <td><strong>${eventClick.event.title}</strong></td>
  //                 </tr>
  //                 <tr>
  //                    <td>Start Time</td>
  //                    <td><strong>${eventClick.event.start}</strong></td>
  //                 </tr>
  //              </tbody>
  //           </table>
  //        </div>
  //        `,
  //        showCancelButton: true,
  //        confirmButtonColor: "#d33",
  //        cancelButtonColor: "#3085d6",
  //        confirmButtonText: "Remove Event",
  //        cancelButtonText: "Edit Event",
  //        allowOutsideClick: false,
  //     }).then((result) => {
  //        if (result.value) {
  //           // console.log(eventClick.event?._def?.extendedProps?._id);
  //           Swal.fire({
  //               title: "Are you sure?",
  //               icon: "warning",
  //               showCancelButton: true,
  //               confirmButtonText: "Yes, delete it!",
  //               cancelButtonText: "No, cancel!",
  //               confirmButtonColor: "#d33",
  //               cancelButtonColor: "#3085d6",
  //           }).then((res)=>{
  //             if(res.value){
  //             const resp = handleDeleteTodo(eventClick.event?._def?.extendedProps?._id)
  //             if(resp){
  //               settodolist((prev)=>{
  //                 return prev.filter((item)=>item._id!==eventClick.event?._def?.extendedProps?._id)
  //               });
  //               eventClick.event.remove();
  //               Swal.fire("Deleted!", "Your Event has been deleted.", "success");
  //             }
  //           }
  //           })
  //        }
  //        else{
  //         // console.log(eventClick.event)
  //         Swal.fire({
  //           title: "Edit Event ?",
  //           icon: "warning",
  //           showCancelButton: true,
  //           confirmButtonText: "Yes, Edit it!",
  //           cancelButtonText: "No, cancel!",
  //           confirmButtonColor: "#d33",
  //           cancelButtonColor: "#3085d6",
  //         }).then((res)=>{
  //           if(res.value){
  //             editTodo.current.click();
  //             settitle(eventClick.event.title)
  //             setSelectedDate(eventClick.event.start)
  //             setSelectedOption(eventClick.event?._def?.extendedProps?.priority)
  //             setcolor(eventClick.event?._def?.extendedProps?.color)
  //             setProperty(eventClick.event?._def?.extendedProps?.type)
  //             setselectedBillFrom({label:eventClick.event?._def?.extendedProps?.client.name ,value:eventClick.event?._def?.extendedProps?.client.name})
  //             setagentids(eventClick.event?._def?.extendedProps?.agent)
  //             seteditTodoId(eventClick.event?._def?.extendedProps?._id)
  //           }})
  //     }
  //   })

  // }

  const handleDeleteTodo = async (todoId) => {
    const res = await deletetodo(todoId)
    return res
  }

  const handleDateSelect = (selectInfo) => {
    const {start, end} = selectInfo
    // Do something with the selected date range
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleAgentChange = async (selectedOption) => {
    setselectedAgent(selectedOption)
    if (selectedOption === null) {
      const res = await getevents(currentUser?._id)
      const rest = await gettodo(currentUser?._id)
      setCalendarEvents(res) // Assuming that res is an array
      settodolist(rest)
      return
    }
    const res = await getevents(selectedOption.value)
    const rest = await gettodo(selectedOption.value)
    setCalendarEvents(res) // Assuming that res is an array
    settodolist(rest)
  }

  return (
    <div className='animated fadeIn demo-app '>
      {(currentUser?.profileType === 'superadmin' || permissions?.calendar?.admin) && (
        <div className='d-flex justify-end m-4'>
          <div
            className='btn btn-primary me-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
            <div
              className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
              data-kt-menu='true'
              style={{zIndex: 1000000}}
            >
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                  <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                    Agent:
                  </label>

                  <Select
                    name='agent'
                    aria-label='Select a Agent'
                    placeholder='Select agent'
                    options={agentsList}
                    defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                    isSearchable={true}
                    className='form-control form-control-solid'
                    onChange={handleAgentChange}
                    value={selectedAgent}
                  />
                </div>

                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setselectedAgent(null)
                      handleAgentChange(null)
                      // getevent()
                    }}
                  >
                    MY EVENTS
                  </button>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setselectedAgent(null)
                      // handleAgentChange(null)
                      // getevent()
                      setCalendarEvents(calendarData)
                    }}
                  >
                    ALL
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
          </div>
        </div>
      )}
      <Row>
        <Col lg={3}>
          <Card>
            <div className='h-500px overflow-scroll'>
              {/* begin::Header */}
              <div className='card-header flex justify-between border-0   '>
                <h3 className='card-title fw-bold text-dark'>Todo</h3>
                <div className='card-toolbar gap-3'>
                  {/* begin::Menu */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                  >
                    <KTIcon iconName='plus' className='fs-2' />
                  </button>
                  <button
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1234'
                    ref={editTodo}
                  ></button>

                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body pt-2'>
                <div id='external-events'>
                  {todolist.map((event) => (
                    <div title={event.title} key={event.id}>
                      {event.type === 'meeting' ? (
                        <Link to={``} className='d-flex align-items-center mb-8'>
                          <span
                            className={`bullet bullet-vertical h-40px me-5`}
                            style={{backgroundColor: event.color}}
                          ></span>
                          {/* end::Bullet */}
                          {/* begin::Checkbox */}

                          {/* end::Checkbox */}
                          {/* begin::Description */}
                          <div className='flex-grow-1'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              {event.title}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{event.type}</span>
                          </div>
                          {/* end::Description */}
                          <span
                            className={`badge  fs-8 fw-bold ${
                              event.priority === 'Important'
                                ? 'badge-light-danger'
                                : event.priority === 'Medium'
                                ? 'badge-light-warning'
                                : 'badge-light-success'
                            }`}
                          >
                            {event.priority}
                          </span>
                        </Link>
                      ) : (
                        <div className='d-flex align-items-center mb-8'>
                          <span
                            className={`bullet bullet-vertical h-40px me-5`}
                            style={{backgroundColor: event.color}}
                          ></span>

                          <div className='flex-grow-1'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                              {event.title}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{event.type}</span>
                          </div>
                          {/* end::Description */}
                          <span
                            className={`badge  fs-8 fw-bold ${
                              event.priority === 'Important'
                                ? 'badge-light-danger'
                                : event.priority === 'Medium'
                                ? 'badge-light-warning'
                                : 'badge-light-success'
                            }`}
                          >
                            {event.priority}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Card>
        </Col>

        <Col lg={9}>
          <Card>
            <Card.Body>
              <div className='demo-app-calendar' id='mycalendartest'>
                <FullCalendar
                  // show time with am and pm in calendar
                  eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    // omitZeroMinute: false,
                    meridiem: 'short',
                  }}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                    multiMonthPlugin,
                  ]}
                  initialView={'dayGridMonth'}
                  headerToolbar={{
                    start: 'today prev,next',
                    center: 'title',
                    end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
                  }}
                  selectable={true}
                  select={handleDateSelect}
                  rerenderDelay={10}
                  eventDurationEditable={false}
                  // editable={true}
                  // droppable={true}
                  weekends={true}
                  ref={calendarComponentRef}
                  events={calendarEvents}
                  // eventDrop={eventClick}
                  // eventReceive={handleEventReceive}
                  eventClick={(e) => {
                    handleShowModal(e)
                  }}
                  // eventRemove={handleEventRemove}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showModal && (
        <div className='modal fade show' tabIndex={-1} role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title capitalize'>
                  {calendarEventModal?._def?.extendedProps?.client !== null ? 'Meeting' : 'Task'}
                </h5>
                <button type='button' className='close' onClick={handleCloseModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          <strong className='capitalize'>{calendarEventModal?.title}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Start Time</td>
                        <td>
                          <strong>{calendarEventModal?.start.toString()}</strong>
                        </td>
                      </tr>
                      {calendarEventModal?._def?.extendedProps?.description !== '' && (
                        <tr>
                          <td>Description</td>
                          <td>
                            <strong>{calendarEventModal?._def?.extendedProps?.description}</strong>
                          </td>
                        </tr>
                      )}
                      {calendarEventModal?._def?.extendedProps?.agent?.length > 0 && (
                        <tr>
                          <td>Agents </td>
                          <td>
                            <strong>
                              {calendarEventModal?._def?.extendedProps?.agent
                                ?.map((agent) => agent.fullName)
                                .join(', ')}
                            </strong>
                          </td>
                        </tr>
                      )}
                      {calendarEventModal?._def?.extendedProps?.customer !== null && (
                        <tr>
                          <td>Customer </td>
                          <td>
                            <strong>
                              {calendarEventModal?._def?.extendedProps?.customer?.displayName}
                            </strong>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                  Close
                </button>
                <button type='button' className='btn btn-danger' onClick={handleDeleteEvent}>
                  Remove Event
                </button>
                <button type='button' className='btn btn-warning' onClick={handleEditEvent}>
                  Edit Event
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'task' ? ' active' : ''}`}
                      onClick={() => setProperty('task')}
                    >
                      Task
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'meeting' ? ' active' : ''}`}
                      onClick={() => setProperty('meeting')}
                    >
                      Meeting
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {!assiopen ? (
              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter title'
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat='MMMM d, yyyy'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Time</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat='h:mm aa'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Description</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Description (Optional)'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Reminder</label>
                  <select
                    className='form-select'
                    value={reminder}
                    onChange={(e) => setReminder(e.target.value)}
                  >
                    <option value='5'>5 minutes before</option>
                    <option value='15'>15 minutes before</option>
                    <option value='30'>30 minutes before</option>
                    <option value='60'>1 hour before</option>
                    <option value='10'>1 day before</option>
                  </select>
                </div>

                {property === 'meeting' && (
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Customer</label>
                    <Select
                      name='client'
                      aria-label='Select Customer'
                      placeholder='Select Customer'
                      options={clientsList}
                      isSearchable={true}
                      onChange={handleBillFromChange}
                      value={selectedBillFrom}
                    />
                  </div>
                )}

                <div className='container mt-3'>
                  <label htmlFor='exampleSelect' className='form-label'>
                    Select Priority
                  </label>
                  <select
                    className='form-select'
                    id='exampleSelect'
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value=''>Select Priority</option>
                    <option value='Important'>Important</option>
                    <option value='Medium'>Medium</option>
                    <option value='Flexible'>Flexible</option>
                    {/* Add more options as needed */}
                  </select>
                </div>

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mt-5'
                  onClick={() => setassiopen(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Assign User
                </button>
              </div>
            ) : (
              <div className='modal-body'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mb-5'
                  onClick={() => setassiopen(false)}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </button>
                <AgentList agentids={agentids} setagentids={setagentids} />
              </div>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handletodosubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1234'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {!assiopen ? (
              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter title'
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat='MMMM d, yyyy'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Time</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat='h:mm aa'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Description</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Description (Optional)'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Reminder</label>
                  <select
                    className='form-select'
                    value={reminder}
                    onChange={(e) => setReminder(e.target.value)}
                  >
                    <option value='5'>5 minutes before</option>
                    <option value='15'>15 minutes before</option>
                    <option value='30'>30 minutes before</option>
                    <option value='60'>1 hour before</option>
                    <option value='10'>1 day before</option>
                  </select>
                </div>

                <div className='container mt-3'>
                  <label htmlFor='exampleSelect' className='form-label'>
                    Select Priority
                  </label>
                  <select
                    className='form-select'
                    id='exampleSelect'
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value=''>Select Priority</option>
                    <option value='Important'>Important</option>
                    <option value='Medium'>Medium</option>
                    <option value='Flexible'>Flexible</option>
                    {/* Add more options as needed */}
                  </select>
                </div>

                {property === 'meeting' && (
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Customer</label>
                    <Select
                      name='client'
                      aria-label='Select Customer'
                      placeholder='Select Customer'
                      options={clientsList}
                      isSearchable={true}
                      onChange={handleBillFromChange}
                      value={selectedBillFrom}
                    />
                  </div>
                )}

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mt-5'
                  onClick={() => setassiopen(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Assign Agent
                </button>
              </div>
            ) : (
              <div className='modal-body'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mb-5'
                  onClick={() => setassiopen(false)}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </button>
                <AgentList agentids={agentids} setagentids={setagentids} />
              </div>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton2'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handletodoedit}>
                Edit changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCalendar
