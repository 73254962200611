import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AddLeave } from './Pages/AddLeave'
import EmployeeLeavePage from './Components/LeaveManagement/EmployeeLeavePage'
import LeaveApply from './Pages/LeaveApply'
import EmployeeLeaveDetails from './Components/LeaveManagement/EmployeeLeaveDetails'
import EmployeeLeaveMainPage from './Components/EmployeeLeaveMainPage'


export default function LeaveManagement() {
    return (
        <Routes>
            <Route path='/' element={<EmployeeLeaveMainPage />} />
            <Route path='/add' element={<LeaveApply />} />
            <Route path='/:month' element={<EmployeeLeavePage />} />
            <Route path='/addLeaveType' element={<AddLeave />} />
            <Route path='/detail/:id' element={<EmployeeLeaveDetails />} />
        </Routes>
    )
}
