import * as Yup from 'yup'

export interface ICreateAccount {
  fullName: string
  email: string
  password: string
  phone: string
  companyName: string
  companySize: number
  modules: Array<string>
}

const createAccountSchemas = [
  Yup.object({
    companyName: Yup.string().required().label('Company Name'),
    companySize: Yup.number().required().label('Company Size'),
  }),
  Yup.object({
    fullName: Yup.string().required().label('Account Name'),
    email: Yup.string().email().required().label('Contact Email'),
    password: Yup.string().required().label('Password'),
    phone: Yup.string().required().label('Contact Phone'),
  }),
  Yup.object({
    modules: Yup.array().required().label('Modules'),
  }),
]

const inits: ICreateAccount = {
  fullName: '',
  email: '',
  password: '',
  phone: '',
  companyName: '',
  companySize: 1,
  modules: [],
}

export {createAccountSchemas, inits}
