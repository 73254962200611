import React, {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {createEmpDepartment, editEmployeeDepartmentById, getEmployeeDepartmentById, getEmployeesDepartmentById} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'

const EditDepartment = () => {
    const { id } = useParams();
    const {currentUser} = useAuth()
    console.log("department Id",id);
  const [departmentData, setDepartmentData] = useState({
    name: '',
    roles:[]
  
  })
  const [roles, setRoles] = useState([])
  const navigate = useNavigate()
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setDepartmentData((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
      // You can add other properties if needed
    }))
  }
  useEffect(() => {
  const fetchDepartmentDetails = async () => {
    try {
      const departmentDetails = await getEmployeesDepartmentById(id); 
      setDepartmentData({
        name: departmentDetails?.name,
        roles: departmentDetails.roles
      });
      setRoles(departmentDetails.roles);
      
      console.log("Department Details:", departmentDetails);
    } catch (error) {
      console.error('Error fetching department details:', error);
    }
  };

  fetchDepartmentDetails();
}, [id]);


  const handleSubmit = async () => {
    console.log("Department",departmentData)
    const data = {
      name: departmentData.name,
      roles: roles,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    try {
      await editEmployeeDepartmentById(id,data).then((res) => {
        console.log('Submitted', res)
        navigate('/hrm/empDepartment')
      })
    } catch (error) {
      console.error('Error Editing Department:', error)
    }
  }

  const handleChangeRoles = (index: number, e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const updatedRoles = [...roles];
    updatedRoles[index] = value;
    setRoles(updatedRoles);
};


  
  const handleAddItem = () => {
    setRoles([...roles, ''])
  }

  const handleDeleteItem = (e, index) => {
    if (roles.length === 1) {
      setRoles([''])
      return
    }
    const temp = [...roles]
    temp.splice(index, 1)
    setRoles(temp)
  }




  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      {/* begin::Date */}
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}

                        {/* Datepicker */}
                        {/* begin::Icon */}
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <span className='fs-2x fw-bold text-gray-800'>EDIT DEPARTMENT</span>

                    {/* Input group */}
                    {/* begin::Input group */}

                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}

                      {/* Col */}
                      {/* begin::Col */}

                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                        Department Name
                      </label>
                      <input
                        type='text'
                        className='form-control form-control-solid '
                        name='name'
                        placeholder='Name Of Department'
                        value={departmentData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className=''>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Roles</label>
                      {roles?.map((item, index) => {
                        return (
                          <div className='d-flex align-items-center mt-1 gap-1'>
                            <input
                              type='text'
                              name='roles'
                              className='form-control form-control-solid'
                              placeholder='Roles'
                               value={roles[index]}
                               onChange={(e) => handleChangeRoles(index, e)}                             />
                            {/* button for deleting the role */}
                            <button
                              type='button'
                              className='btn btn-sm btn-icon text-hover-primary'
                              onClick={(e) => {
                                handleDeleteItem(e, index)
                              }}
                            >
                              <KTIcon iconName='trash' className='fs-3 ms-3' />
                            </button>
                          </div>
                        )
                      })}
                      <div className='d-flex align-items-center justify-center mt-4'>
                        <button
                          type='button'
                          className='btn btn-sm btn-info'
                          onClick={handleAddItem}
                        >
                          Add Role
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='invoice'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}

                {/* <!--::Input group-->
                              <!--begin::Separator--> */}

                {/* <!--::Separator-->
                              <!--begin::Input group--> */}

                {/* <!--::Input group-->
                              <!--begin::Separator--> */}
                {/* <!--::Separator-->
                              <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}

                  <button
                    className='btn btn-primary w-100'
                    id='kt_quote_submit_button'
                    onClick={handleSubmit}
                  >
                    <i className='ki-duotone ki-triangle fs-3'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                    EDIT DEPARTMENT
                  </button>
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default EditDepartment
