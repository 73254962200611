import React, {useEffect, useState} from 'react'
import {getEmployeeReportSubmissionByReportId} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'

const ViewReportSubmissions = () => {
  const {id} = useParams()
  const [submissions, setSubmissions] = useState([])

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const data = await getEmployeeReportSubmissionByReportId(id)
        setSubmissions(data.submissions)
        console.log('data Received', submissions)
      } catch (error) {
        console.error('Error fetching submissions:', error)
      }
    }

    fetchSubmissions()
  }, [])
  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header my-4 d-flex align-items-center justify-between'>
          <h4 className='card-title'>Employee Report Submissions </h4>
          {/* <div className='btn btn-primary' onClick={handleAdd}>
                <KTIcon iconName='plus' className='me-2' />
                Report Submission
              </div> */}
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='min-w-125px'>Form Name</th>
                  <th className='min-w-125px'>Department</th>
                  <th className='min-w-125px'>Role</th>
                  <th className='min-w-125px'>Date</th>
                  <th className='min-w-125px'>Employee Email</th>
                  {/* <th className='min-w-125px'>Employee Email</th> */}
                </tr>
              </thead>
              <tbody>
                {submissions.map((submission, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/hrm/submissionReports/viewSubmissionDetails/${submission?._id}`}>
                        {submission.formName}
                      </Link>
                    </td>
                    <td>{submission.department.name}</td>
                    <td>{submission.role}</td>
                    <td>{new Date(submission.date).toLocaleDateString('en-GB')}</td>
                    <td>{submission.createdBy.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewReportSubmissions
