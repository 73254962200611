import {createSlice} from '@reduxjs/toolkit'

const chartOfAccountantSlice = createSlice({
  name: 'chartOfAccountant',
  initialState: {
    chartOfAccountantData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setChartOfAccountantData(state, action) {
      state.chartOfAccountantData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setChartOfAccountantData, setLoading, setError} = chartOfAccountantSlice.actions
export default chartOfAccountantSlice.reducer
