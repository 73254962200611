import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {
  getFilteredPaymentMade,
  getFilteredPaymentMadeByAgentId,
  invalidatePaymentMade,
} from '../Core/_requests'
import {useAuth} from '../../../modules/auth'
import {Filtertype2, ValidFilter} from '../Core/_model'
import Filter2 from '../Components/Filter2'
import qs from 'qs'
import {useDispatch, useSelector} from 'react-redux'
import {setPaymentMadeDataList} from '../../../redux/procurement/paymentMade/paymentMadeSlice'
import {RootState} from '../../../redux/store'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {setBillsDataFetch} from '../../../redux/procurement/bills/billsSlice'
import debounce from 'lodash/debounce'
import Pagination from '../Components/Pagination'

const PaymentMadeList = () => {
  const initialRef = useRef(true)
  const [permissions, setPermissions] = useState<any>({})
  const [filteredPaymentMadeList, setFilteredPaymentMadeList] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const paymentMadeData = useSelector(
    (state: RootState) => state.procurement.paymentMade.paymentMadeData
  )
  const totalPagesStore = useSelector(
    (state: RootState) => state.procurement.paymentMade.totalPages
  )
  const reFetch = useSelector((state: RootState) => state.procurement.paymentMade.fetch)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const stringifyObject = (obj: ValidFilter): string => {
    const queryString = qs.stringify(
      {
        filter_validity: obj.filter.isValid,
        filter_approval: obj.filter.approval,
        filter_status: obj.filter.status,
        search_query: searchQuery,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const handleClick = (index) => {
    const newDropdownState = dropdown.map((_, i) => (i === index ? !dropdown[i] : false))
    setDropdown(newDropdownState)
  }

  const getList = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    try {
      // const data = await getPaymentMade(currentUser?.organization)
      const data = await getFilteredPaymentMade(
        queryString,
        currentUser?.organization,
        currentPage,
        25
      )
      setFilteredPaymentMadeList(data?.paymentsMade)
      setTotalPages(data?.totalPages)
      setDropdown(Array(data?.paymentsMade?.length)?.fill(false))
      return data
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    } finally {
      setIsLoading(false)
    }
  }

  const getPaymentMadebyAgent = async () => {
    const queryString = stringifyObject(searchFilters)
    setIsLoading(true)
    // const res = await getPaymentMadeByAgentId(currentUser?._id)
    try {
      const res = await getFilteredPaymentMadeByAgentId(
        queryString,
        currentUser?._id,
        currentPage,
        25
      )
      setFilteredPaymentMadeList(res?.paymentsMade)
      setTotalPages(res?.totalPages)
      setDropdown(Array(res?.paymentsMade?.length)?.fill(false))
      return res
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchPaymentsMade = () => {
    if (permissions?.paymentmade?.admin === false) {
      getPaymentMadebyAgent()
    } else if (
      permissions?.paymentmade?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList()
    }
  }

  const fetchPaymentsMadeData = () => {
    if (permissions?.paymentmade?.admin === false) {
      getPaymentMadebyAgent().then((res) => dispatch(setPaymentMadeDataList(res)))
    } else if (
      permissions?.paymentmade?.admin === true ||
      currentUser?.profileType === 'superadmin'
    ) {
      getList().then((res) => dispatch(setPaymentMadeDataList(res)))
    }
  }

  useEffect(() => {
    if (paymentMadeData.length === 0 || reFetch) {
      fetchPaymentsMadeData()
    } else {
      setFilteredPaymentMadeList(paymentMadeData)
      setDropdown(Array(paymentMadeData.length)?.fill(false))
      setTotalPages(totalPagesStore)
    }
  }, [permissions])

  const debounceSearch = debounce(fetchPaymentsMade, 500)

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return
    }

    debounceSearch()

    return () => debounceSearch?.cancel()
  }, [searchQuery, currentPage])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      // day: '2-digit',
      // month: 'short',
      // year: 'numeric',
    })
    return formattedDate
  }

  let searchFilters: ValidFilter = {
    filter: {
      isValid: true,
      approval: '',
    },
  }

  const setSearchFilter = (updateValues: {filter?: Partial<Filtertype2>}): void => {
    searchFilters = {
      ...searchFilters,
      ...(updateValues.filter && {
        filter: {
          ...searchFilters.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const setValidityFilter = (isValid: boolean) => {
    setSearchFilter({
      filter: {
        isValid,
      },
    })
  }

  const handlereset = async () => {
    setSearchFilter({
      filter: {
        isValid: true,
        approval: '',
      },
    })
    setFilteredPaymentMadeList(paymentMadeData)
    setDropdown(Array(paymentMadeData.length)?.fill(false))
    setCurrentPage(1)
  }

  const setApprovalFilter = (approval: string) => {
    setSearchFilter({
      filter: {
        isValid: searchFilters.filter.isValid,
        status: searchFilters.filter.status,
        approval: approval, // Update approval filter value
      },
    })
  }

  const handleActivation = async (id) => {
    const res = await invalidatePaymentMade(id)

    if (res) {
      fetchPaymentsMadeData()
      dispatch(setBillsDataFetch())
    }
  }

  return (
    <div className='card card-flush'>
      <span className='card-label fw-bold fs-3 ps-15 pt-8 '>Payments</span>
      <div className='card-header border-0 pt-5'>
        <div className='flex gap-5'>
          <div className=' d-flex flex-row  card-title align-items-start'>
            <form
              data-kt-search-element='form'
              className='w-100 position-relative '
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-2'
              />
              <input
                type='text'
                className='form-control form-control-solid w-250px ps-14'
                name='search'
                placeholder='Search (Payments ID)'
                data-kt-search-element='input'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </form>
          </div>

          <div className=' d-flex flex-row  card-title align-items-start'>
            <Filter2
              searchFilters={searchFilters}
              setSearchFilter={setSearchFilter}
              handlereset={handlereset}
              getDocsList={getList}
              setValidityFilter={setValidityFilter}
              showStatusFilter={false}
              setApprovalFilter={setApprovalFilter}
            />
          </div>
        </div>

        {(permissions?.paymentmade?.create || currentUser?.profileType === 'superadmin') && (
          <div className='card-toolbar'>
            <Link to={'add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              New
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {isLoading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>Date</th>
                  <th className='min-w-150px'>ID#</th>
                  <th className='min-w-150px'>Bill#</th>
                  {/* <th className='min-w-150px'>Reference#</th> */}
                  <th className='min-w-150px'>Vendor Name</th>
                  <th className='min-w-100px'>Payment#</th>
                  <th className='min-w-100px'>Mode</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Unused Amount</th>
                  <th className='min-w-100px'>Approval</th>
                  {(permissions?.purchaseorder?.update ||
                    currentUser?.profileType === 'superadmin') && (
                    <th className='min-w-100px text-center'>Actions</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {filteredPaymentMadeList?.map((item: any, index: number) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {/* {item?.paymentDate} */}
                        {formatCreatedAt(item?.paymentDate)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.billsData[0]?.billNo?.id}
                      </Link>
                    </td>
                    {/* <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.billsData[0]?.purchaseOrder?.id}
                      </Link>
                    </td> */}
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.paymentMode}
                      </Link>
                    </td>
                    <td className=''>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.billsData[0]?.billAmount.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {item?.vendor?.currency}
                      </Link>
                    </td>

                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span className={`badge  fs-6 fw-semibold `}>
                          {item?.billsData[0]?.dueAmount
                            ? item?.billsData[0].dueAmount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : 0}{' '}
                          {item?.vendor?.currency}
                        </span>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/paymentMadeList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            item?.approval === 'pending'
                              ? 'badge-light-primary'
                              : item?.approval === 'accepted'
                              ? 'badge-light-success'
                              : 'badge-light-danger'
                          }`}
                        >
                          {item?.approval}
                        </span>
                      </Link>
                    </td>
                    {(permissions?.paymentmade?.update ||
                      currentUser?.profileType === 'superadmin') && (
                      <td className='relative'>
                        <div className='d-flex justify-content-center flex-shrink-0'>
                          <button
                            onClick={() => handleClick(index)}
                            className=' bg-gray-100 hover:bg-lime-50 px-6 py-2 flex items-center rounded self-end'
                          >
                            Actions <RiArrowDropDownLine />
                          </button>
                        </div>
                        {dropdown[index] && (
                          <div className='absolute flex flex-col top-16 right-[0px] z-50  bg-white  w-40 shadow-[0_16px_16px_6px_rgba(128,128,128,0.1)] rounded p-3 space-y-2'>
                            {item?.valid && (
                              <>
                                {/* <Link to={`edit/${item._id}`}>
                              <button className='hover:bg-lime-50 p-2  text-start rounded'>
                                Edit
                              </button>
                            </Link> */}
                                <button
                                  className='hover:bg-lime-50 p-2 text-start rounded'
                                  onClick={() => handleActivation(item._id)}
                                >
                                  Invalid
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  )
}

export default PaymentMadeList
