import * as Yup from 'yup'

export interface IApplyLeave {
  // employeeId:string
  // leaveType:string
  // dateOfLeave:number
  // teamEmail:string
  // leaveReason
    employeeId: string;
    // jobRoleId: string;
    departmentId: string;
    leaveType: string;
    startDate: Date;
    endDate: Date;
    reason: string;
}
// export interface IApplyLeave {
//   employeeId: string;
//   jobRoleId: string;
//   departmentId: string;
//   leaveType: string;
//   startDate: Date;
//   endDate: Date;
//   reason: string;
//   status: 'pending' | 'approved' | 'rejected';
// }

const applyLeaveSchemas = [
  Yup.object({
    // employeeId: Yup.string().required().label('Employee Id'),
    // leaveType: Yup.string().required().label('Leave Type'),
    // dateOfLeave: Yup.number().required().label('Date'),
    // teamEmail: Yup.string().required().label('Team Email Id'),
    // leaveReason: Yup.string().required().label('Reason for leave'),
    employeeId: Yup.string().required().label('Employee Id'),
        // jobRoleId: Yup.string().required().label('Job Role Id'),
        departmentId: Yup.string().required().label('Department Id'),
        leaveType: Yup.string().required().label('Leave Type'),
        startDate: Yup.date().required().label('Start Date'),
        endDate: Yup.date().required().label('End Date'),
        reason: Yup.string().required().label('Reason for leave'),
        // status: Yup.string().required().oneOf(['pending', 'approved', 'rejected']).label('Status'),
    
  }),
  Yup.object({
   
  }),
  Yup.object({
   
  }),
  Yup.object({


  }),

 Yup.object({

 }),
 Yup.object({


 })
  
]

// const applyLeaveSchemas = [
//   Yup.object({
//     employeeId: Yup.string().required().label('Employee Id'),
//     jobRoleId: Yup.string().required().label('Job Role Id'),
//     departmentId: Yup.string().required().label('Department Id'),
//     leaveType: Yup.string().required().label('Leave Type'),
//     startDate: Yup.date().required().label('Start Date'),
//     endDate: Yup.date().required().label('End Date'),
//     reason: Yup.string().required().label('Reason for leave'),
//     status: Yup.string().required().oneOf(['pending', 'approved', 'rejected']).label('Status'),
//   }),
// ];

const inits: IApplyLeave = {
  employeeId:'',
  departmentId:'',
  leaveType:'',
  startDate:new Date(),
  endDate:new Date(),
  reason:''
}
// const inits: IApplyLeave = {
//   employeeId: '',
//   jobRoleId: '',
//   departmentId: '',
//   leaveType: '',
//   startDate: new Date(),
//   endDate: new Date(),
//   reason: '',
//   status: 'pending',
// };

export {applyLeaveSchemas, inits}
