import { createSlice } from '@reduxjs/toolkit';

const salesPerformanceSlice = createSlice({
  name: 'salesPerformance',
  initialState: {
    salesPerformanceData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setSalesPerformanceData(state, action) {
      state.salesPerformanceData = action.payload;
      state.loading=false;
      state.error=null;
    },
    setLoading(state){
     state.loading = true;
     state.error=null;
    },
    setError(state, action) {
     state.loading=false;
           state.error = action.payload;
    },
  },
});

export const { setSalesPerformanceData, setLoading, setError } = salesPerformanceSlice.actions;
export default salesPerformanceSlice.reducer;
