import React from 'react'

export default function Card5({ data, agentList, setData }) {

    const handleAssignedAgentClick = (memberId) => {
        const isSelected = data.assignedAgents.some((member) => member.value === memberId)
        let updatedAssignedAgent
        if (isSelected) {
            updatedAssignedAgent = data.assignedAgents.filter((member) => member.value !== memberId)
        } else {
            const member = { value: memberId, label: memberId }
            updatedAssignedAgent = [...data.assignedAgents, member]
        }
        setData({ ...data, assignedAgents: updatedAssignedAgent })
    }

    // Handle adding team members from the dropdown
    const handleAssignedAgentChange = (userId) => {
        if (userId) {
            const selectedUser = agentList.find((user) => user.value === userId)
            if (selectedUser) {
                const alreadyAdded = data.assignedAgents.some(
                    (member) => member.value === selectedUser.value
                )
                if (!alreadyAdded) {
                    setData((prevData) => ({
                        ...prevData,
                        assignedAgents: [
                            ...prevData.assignedAgents,
                            { value: selectedUser.value, label: selectedUser.label },
                        ],
                    }))
                }
            }
        }
    }

    return (
        <div className='card mb-5 mb-xl-10 px-9'>
            <div className='container-xxl'>
                <div className='card-header border-0 cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Assign Users</h3>
                    </div>
                </div>

                <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Assigned Users
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <select
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Select team member'
                            value=''
                            onChange={(e) => handleAssignedAgentChange(e.target.value)}
                        >
                            <option value=''>Add User</option>
                            {agentList.map((agent) => (
                                <option key={agent.value} value={agent.value}>
                                    {agent.label}
                                </option>
                            ))}
                        </select>

                        <div className='d-flex flex-row mt-2'>
                            {data.assignedAgents.map((member) => (
                                <div key={member.value} className='me-3'>
                                    <span
                                        className='badge badge-primary cursor-pointer'
                                        onClick={() => handleAssignedAgentClick(member.value)}
                                    >
                                        {member.label}
                                        <span className='ms-2'>x</span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
