import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
// import { getDeals, getQuotes, getQuotesbyAgentId } from '../core/_requests';
import {KTIcon} from '../../../../_metronic/helpers'
import {getJournals} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setJournalEntryData} from '../../../redux/accounts/accountant/journalEntry/journalEntrySlice'

function JournalPage() {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  // const [journalList, setJournalList] = useState<any>([])

  const dispatch = useDispatch()
  const journalList = useSelector((state: any) => state.account.journalEntry.journalEntryData)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchJournalList = async () => {
    const res = await getJournals(currentUser?.organization)
    const sortedList = res.sort((a: any, b: any) => {
      const dateA: any = new Date(a.createdAt)
      const dateB: any = new Date(b.createdAt)
      return dateB - dateA
    })
    // setJournalList(sortedList)
    dispatch(setJournalEntryData(sortedList))
    console.log(res)
  }

  useEffect(() => {
    fetchJournalList()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Manual Journals</span>
        </h3>

        {(currentUser?.profileType === 'superadmin' || permissions?.journal?.create) && (
          <div className='card-toolbar'>
            <Link to={'/account/journals/add'} className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Journal
            </Link>
          </div>
        )}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Date</th>
                <th className='min-w-150px'>Journal#</th>
                <th className='min-w-150px'>Reference</th>
                <th className='min-w-100px'>Amount</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {journalList?.map((item: any, index) => (
                <tr key={item?._id?.toString()}>
                  <td>
                    <Link
                      to={`#`}
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {formatCreatedAt(item?.date)}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {item?.id}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.reference}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`#`}
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {item?.subtotal.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default JournalPage
