import {createSlice} from '@reduxjs/toolkit'

const documentMaintenanceSlice = createSlice({
  name: 'documentMaintenance',
  initialState: {
    documentMaintenanceData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDocumentMaintenanceData(state, action) {
      state.documentMaintenanceData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setDocumentMaintenanceData, setLoading, setError} = documentMaintenanceSlice.actions
export default documentMaintenanceSlice.reducer
