import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css' // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css' // Import the Flatpickr CSS
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import {useAuth} from '../../../../modules/auth/core/Auth'
import {KTIcon, KTSVG} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {
  getAccountsNameForPaymentReceivedInvoice,
  getCustomer,
  getCustomerByStatusForAgent,
  getInvoiceLastId,
  getItemsByType,
  getDeliveryNotesByCustomerId,
  getStocksForSales,
  postInvoice,
  postInvoiceWithSalesOrder,
  getSalesOrdersByCustomerId,
  getFilteredStocks,
} from '../../Core/_requests'
import {add, endOfMonth, addMonths} from 'date-fns'
import {getEmployeeByStatus} from '../../../hrm/core/_requests'
import {uploaddocsinqf} from '../../../Inventory/Core/_requests'
import {getNotes, getTerms} from '../../../account_settings/core/_request'
import {useSelector, useDispatch} from 'react-redux'
import {setSalesStock} from '../../../../redux/inventory/products/stockSlice'
import {setServices} from '../../../../redux/inventory/products/servicesSlice'
import {setRentalsData} from '../../../../redux/inventory/products/rentalsSlice'
import {setGoodsData} from '../../../../redux/inventory/products/goodsSlice'
import {RootState} from '../../../../redux/store'
import {unitsList} from '../../../../utils/units'

function CreateInvoice() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const salesStock = useSelector((state: RootState) => state.inventory.stock.salesStock)
  const servicesList = useSelector((state: RootState) => state.inventory.services.services)
  const goodsList = useSelector((state: RootState) => state.inventory.goods.goodsData)
  const rentalsList = useSelector((state: RootState) => state.inventory.rentals.rentalsData)
  const [categoryType, setCategoryType] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [filename, setfilename] = useState('')
  const [excelFile, setExcelFile] = useState(null)
  const [name, setname] = useState('')
  const fileInputRef = useRef(null)
  const [permissions, setPermissions] = useState<any>({})
  const [customerList, setCustomerList] = useState<any>([])
  const [employeeList, setEmployeeList] = useState<any>([])
  const [productList, setProductList] = useState<any>([])
  const [salesOrderList, setSalesOrderList] = useState<any>([])
  const [salesOrderList1, setSalesOrderList1] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [partialStatus, setPartialStatus] = useState('')
  const [partialId, setPartialId] = useState('')
  const [isSalesOrder, setIsSalesOrder] = useState(false)
  const [showProductSelectionButton, setShowProductSelectionButton] = useState(true)
  const [selectFromInventory, setSelectFromInventory] = useState(true)
  const [selectYearAndMonth, setSelectYearAndMonth] = useState(new Date())

  const [newItem, setNewItem] = useState<any>([
    {
      productName: '',
      unit: '',
      itemId: null,
      itemsId: null,
      price: '',
      quantity: 0,
      discount: 0,
      amount: '',
    },
  ])

  //useState for Custom Invoive no
  const [showModal, setShowModal] = useState(false)
  const [salesOrderNumber, setSalesOrderNumber] = useState('')
  const [selectedOption, setSelectedOption] = useState('autoGenerate') // Default selected option
  const [prefix, setPrefix] = useState('')
  const [nextNumber, setNextNumber] = useState('')
  const [customID, setCustomID] = useState('')
  const [lateFees, setLateFees] = useState(false)
  const [shippingFees, setShippingFees] = useState(false)
  const [accountsNameList, setAccountsNameList] = useState([])
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTerms, setIsEditingTerms] = useState(false)
  const [termsTextareaHeight, setTermsTextareaHeight] = useState('6rem')
  const [notesTextareaHeight, setNotesTextareaHeight] = useState('6rem')
  const [selectedCustomerCurrency, setSelectedCustomerCurrency] = useState('')

  const termsOption = [
    {label: 'Net 15', value: 'net15'},
    {label: 'Net 30', value: 'net30'},
    {label: 'Net 45', value: 'net45'},
    {label: 'Net 60', value: 'net60'},
    {label: 'Net 90', value: 'net90'},
    {label: 'Due on Receipt', value: 'receipt'},
    {label: 'Due on End of Month', value: 'endofMonth'},
    {label: 'Due on End of Next Month', value: 'endOfNextMonth'},
    {label: 'Custom', value: 'custom'},
  ]
  const [selectedTerms, setSelectedTerms] = useState<any>()
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState(null)
  const [selectedSalesOrderLabel, setSelectedSalesOrderLabel] = useState(null)
  const [selectedSalesOrderId1, setSelectedSalesOrderId1] = useState(null)
  const [selectedSalesOrderLabel1, setSelectedSalesOrderLabel1] = useState(null)
  const [selectedSalesOrderItems, setSelectedSalesOrderItems] = useState([
    {
      productName: '',
      itemId: null,
      itemsId: null,
      price: '',
      unit: '',
      quantity: 0,
      discount: 0,
      amount: '',
      type: 'product',
      salesAccount: '',
      inventoryAccount: '',
      purchaseAccount: '',
      selectedOption: {
        value: '',
        label: '',
        inWarehouseQuantity: 0,
      },
    },
  ])

  const handleDragEnd = (result) => {
    if (!result.destination) return // dropped outside the list

    if (selectedSalesOrderItems.length > 1) {
      const itemsCopy = [...selectedSalesOrderItems]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)
      // Update your state with the reordered items
      setSelectedSalesOrderItems(itemsCopy)
    } else if (newItem.length > 1) {
      const itemsCopy = [...newItem]
      const [reorderedItem] = itemsCopy.splice(result.source.index, 1)
      itemsCopy.splice(result.destination.index, 0, reorderedItem)

      // Update your state with the reordered items
      setNewItem(itemsCopy)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setfilename(event.target.files[0].name)
    setExcelFile(file)
  }

  const handleUpload = async () => {
    if (!excelFile) {
      console.error('No file selected')
      return
    }

    const timestamp = Date.now()

    const formData = new FormData()
    formData.append('file', excelFile)
    formData.append('name', name)
    formData.append('filename', timestamp + filename)
    try {
      setisLoading(true)
      const response = await uploaddocsinqf(formData)

      if (response === 'success') {
        setInvoice((prevInvoice) => ({
          ...prevInvoice,
          docAttached: timestamp + filename,
        }))
        console.log('File uploaded successfully')
        alert('File uploaded successfully')
        // Optionally, update UI to indicate successful upload
      } else {
        alert('failed to upload please try again')
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error)
      setisLoading(false)
      // Opti
    }
  }

  const handleTermsChange = (terms: any) => {
    setSelectedTerms(terms)
    if (terms.value !== 'custom') {
      const {date} = invoice
      let dueDate

      switch (terms.value) {
        case 'net15':
          dueDate = add(date, {days: 15})
          break
        case 'net30':
          dueDate = add(date, {days: 30})
          break
        case 'net45':
          dueDate = add(date, {days: 45})
          break
        case 'net60':
          dueDate = add(date, {days: 60})
          break
        case 'net90':
          dueDate = add(date, {days: 90})
          break
        case 'endofMonth':
          dueDate = endOfMonth(date)
          break
        case 'endOfNextMonth':
          dueDate = endOfMonth(addMonths(date, 1))
          break
        case 'receipt':
          dueDate = date
          break
        default:
          break
      }
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        dueDate, // Flatpickr returns an array of selected dates
      }))
    }
  }

  const [invoice, setInvoice] = useState({
    date: new Date(),
    dueDate: new Date(),
    employee: '',
    customer: '',
    id: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: '',
    salesOrderRef: '',
    termsNCondition: '',
    lpo: '',
    trn: '',
    orderNo: '',
    lateFees: 0,
    shippingFee: 0,
    paymentReceived: false,
    paymentMode: 'cash',
    depositTo: '',
    advanceReceived: false,
    discount: 0,
    advance: 0,
    balance: 0,
    docAttached: '',
  })

  const getDeliveryNotesByCustomer = async (customerId) => {
    try {
      if (customerId !== undefined) {
        const response = await getDeliveryNotesByCustomerId(customerId)
        setSalesOrderList(response)
      }
    } catch (error) {
      console.error('Error fetching sales orders:', error)
    }
  }

  const getSalesOrderByCustomer = async (customerId) => {
    try {
      if (customerId !== undefined) {
        const response = await getSalesOrdersByCustomerId(customerId)
        setSalesOrderList1(response)
      }
    } catch (error) {
      console.error('Error fetching sales orders:', error)
    }
  }

  const deliveryNoteOptions = salesOrderList?.salesorders?.map((salesorder) => ({
    value: salesorder._id,
    label: salesorder.id,
  }))

  const salesOrderOptions = salesOrderList1?.salesorders?.map((salesorder) => ({
    value: salesorder._id,
    label: salesorder.id,
  }))

  const handleDeliveryNoteChange = (selectedOption) => {
    setSelectedSalesOrderId1(null)
    setSelectedSalesOrderLabel1(null)
    setSelectFromInventory(true)
    setIsSalesOrder(false)
    setShowProductSelectionButton(false)
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      advanceReceived: false,
    }))
    setSelectedSalesOrderId(selectedOption.value)
    setSelectedSalesOrderLabel(selectedOption.label)
    setNewItem([])

    // Find the selected salesorder and set its items to the state
    const selectedSalesOrder = salesOrderList?.salesorders.find(
      (salesorder) => salesorder._id === selectedOption.value
    )

    if (selectedSalesOrder) {
      let total = 0
      const selectedItems = selectedSalesOrder.items.map((item) => {
        total += item.deliveringQuantity * Number(item.price) - item?.discount / item.quantity || 0
        return {
          productName: item.productName,
          unit: item?.unit || null,
          quantity: item?.deliveringQuantity,
          itemId: item.itemId,
          itemsId: item.itemsId,
          price: item.price,
          type: item.type,
          amount:
            item.deliveringQuantity * Number(item.price) - item?.discount / item.quantity || 0,
          discount: item?.discount / item.quantity,
          salesAccount: item.salesAccount,
          purchaseAccount: item.purchaseAccount,
          inventoryAccount: item.inventoryAccount,
        }
      })
      setSelectedSalesOrderItems(selectedItems)
      setPartialStatus(
        selectedSalesOrder?.status === 'advance' ? 'partial' : selectedSalesOrder?.status
      )
      if (selectedSalesOrder?.status === 'partial') {
        setPartialId(selectedSalesOrder?.id?.split('-P-')[1])
      }
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        subtotal: Number(total),
        total: Number(total),
      }))
    }
  }

  const handleSalesOrderChange = (selectedOption) => {
    setSelectedSalesOrderId(null)
    setSelectedSalesOrderLabel(null)
    setIsSalesOrder(true)
    setSelectedSalesOrderId1(selectedOption.value)
    setSelectedSalesOrderLabel1(selectedOption.label)
    setSelectFromInventory(true)
    setShowProductSelectionButton(false)
    setNewItem([])

    // Find the selected salesorder and set its items to the state
    const selectedSalesOrder = salesOrderList1?.salesorders.find(
      (salesorder) => salesorder._id === selectedOption.value
    )

    if (selectedSalesOrder) {
      let total = 0
      const selectedItems = selectedSalesOrder.items.map((item) => {
        total += Number(item.amount)
        return {
          productName: item.productName,
          unit: item?.unit || null,
          quantity: item?.quantity,
          itemId: item.itemId,
          itemsId: item.itemsId,
          price: item.price,
          type: item.type,
          amount: item.amount,
          discount: item?.discount,
          salesAccount: item.salesAccount,
          purchaseAccount: item.purchaseAccount,
          inventoryAccount: item.inventoryAccount,
        }
      })
      setSelectedSalesOrderItems(selectedItems)
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        subtotal: Number(total),
        total: Number(total),
      }))
    }
  }

  useEffect(() => {
    if (selectedBillTo?.value !== undefined) {
      getDeliveryNotesByCustomer(selectedBillTo?.value)
      getSalesOrderByCustomer(selectedBillTo?.value)
    }
    setSelectedSalesOrderId(null)
    setSelectedSalesOrderLabel(null)
    setSelectedSalesOrderId1(null)
    setSelectedSalesOrderLabel1(null)
    setSelectedSalesOrderItems([
      {
        productName: '',
        itemId: null,
        itemsId: null,
        unit: '',
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        type: 'product',
        salesAccount: '',
        inventoryAccount: '',
        purchaseAccount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
      },
    ])
  }, [selectedBillTo])

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const currencyOptions = [
    {
      value: 'AED',
      label: 'AED - United Arab Emirates dirham',
      flag: 'flags/united-arab-emirates.svg',
    },
    {value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg'},
    {value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg'},
    {value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg'},
    {value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg'},
    {value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg'},
    {value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg'},
    {value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg'},
    // Add other options similarly
  ]
  const defaultCurrency = currencyOptions.find((option) => option.value === 'AED')

  const getCustomerList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (invoice.termsNCondition === '') {
      setTermsTextareaHeight('6rem')
    }
  }, [invoice.termsNCondition])

  useEffect(() => {
    if (invoice.notes === '') {
      setNotesTextareaHeight('6rem')
    }
  }, [invoice.notes])

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const InvoiceNotes = await getNotes(currentUser?.organization, 'Invoice')
        const InvoiceTerms = await getTerms(currentUser?.organization, 'Invoice')

        setInvoice((prev) => ({
          ...prev,
          notes: InvoiceNotes[0]?.notes || '',
          termsNCondition: InvoiceTerms?.terms || '',
        }))
      } catch (error) {
        console.error('Error fetching delivery notes:', error)
      }
    }

    fetchInvoices()
  }, [])

  const getEmployeeList = async () => {
    try {
      const queryString = 'search=&agent=&filter_status=true'
      const res = await getEmployeeByStatus(queryString, currentUser?.organization)
      setEmployeeList(
        res.map((r) => ({
          value: r._id,
          label: r.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
      const defaultAgent = res?.find(
        (option) => option?.firstName + ' ' + option?.lastName === currentUser?.fullName
      )
      if (defaultAgent) {
        setselectedBillFrom({
          value: defaultAgent?._id,
          label: currentUser?.fullName,
        })
        setInvoice((prev) => ({
          ...prev,
          employee: defaultAgent?._id,
        }))
      }
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getProductlist = async () => {
    try {
      let salesStockData = []
      if (salesStock.length === 0) {
        const res = await getStocksForSales(currentUser?.organization)
        console.log(res)
        salesStockData = res.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
        dispatch(setSalesStock(res))
      } else {
        salesStockData = salesStock.map((r) => ({
          value: r._id,
          label: r.productName,
          price: r.price,
          inWarehouseQuantity: r.inWarehouseQuantity,
          type: 'product',
        }))
      }

      let serviceData = []
      if (servicesList.length === 0) {
        const resp = await getItemsByType('service', currentUser?.organization)
        serviceData = resp.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        dispatch(setServices(resp))
      } else {
        serviceData = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
      }
      setProductList((prev) => {
        const updatedData = [...salesStockData, ...serviceData]
        return updatedData
      })
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }
  useEffect(() => {
    if (customID) {
      setSalesOrderNumber(customID)
    }
  }, [customID])

  const getInvoiceID = async () => {
    try {
      const res = await getInvoiceLastId(currentUser?.organization)
      setNextNumber(res.lastId + 1)
      const lastPrefix = res?.prefix.slice(0, res?.prefix?.trim()?.length - 7)
      setPrefix(lastPrefix)
      setSalesOrderNumber(`${res?.prefix}${res?.lastId + 1}`)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getCustomerForAgent = async () => {
    try {
      const res = await getCustomerByStatusForAgent('', currentUser?._id)
      setCustomerList(
        res.map((r) => ({
          value: r?._id,
          label: r?.displayName,
          currency: r?.currency,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (permissions?.invoice?.admin === false) {
      getCustomerForAgent()
    } else if (permissions?.invoice?.admin === true || currentUser?.profileType === 'superadmin') {
      getCustomerList()
    }
  }, [permissions])

  useEffect(() => {
    getEmployeeList()
    fetchAccountsName()
    getProductlist()
    getInvoiceID()
  }, [])

  const fetchAccountsName = async () => {
    const res = await getAccountsNameForPaymentReceivedInvoice(currentUser?.organization)
    const data = res?.map((items) => {
      return {
        label: items?.accountName,
        value: items?._id,
      }
    })
    setAccountsNameList(data)
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      depositTo: res?.[0]?._id,
    }))
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ): void => {
    e.preventDefault()
    const {name, value} = e.target
    if (name === 'notes' && value.trim() === '') {
      setInvoice((prev) => ({
        ...prev,
        notes: '• ' + value,
      }))
    } else {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        [name]: value,
      }))
    }
    if (name === 'termsNCondition') {
      setTermsTextareaHeight(`${e.target.scrollHeight}px`)
    } else if (name === 'notes') {
      setNotesTextareaHeight(`${e.target.scrollHeight}px`)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setInvoice((prev) => ({
        ...prev,
        notes: prev.notes + '\n• ',
      }))

      setNotesTextareaHeight(`${event.target.scrollHeight}px`)
    }
  }

  const handleEditClick = (e, field) => {
    e.preventDefault()
    if (field === 'notes') {
      setIsEditingNotes(!isEditingNotes)
      setIsEditingTerms(false) // Make sure only one is in edit mode at a time
    } else if (field === 'termsNCondition') {
      setIsEditingTerms(!isEditingTerms)
      setIsEditingNotes(false) // Make sure only one is in edit mode at a time
    }
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    if (name === 'quantity' && selectedSalesOrderItems[index].type !== 'item') {
      if (value > selectedSalesOrderItems[index].selectedOption.inWarehouseQuantity) {
        return
      }
    }
    setSelectedSalesOrderItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
    setSelectedSalesOrderItems((prevItems) => {
      const updatedItems = [...prevItems]
      const data: any = (
        Number(prevItems[index].price) * Number(prevItems[index].quantity) -
        Number(prevItems[index].discount)
      ).toFixed(2)

      updatedItems[index] = {
        ...updatedItems[index],
        amount: data,
      }
      return updatedItems
    })
  }

  const handleNewItemChange = (index, e) => {
    const {name, value} = e.target
    setNewItem((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      const sum = (
        Number(updatedItems[index].price) * Number(updatedItems[index].quantity) -
        Number(updatedItems[index].discount)
      ).toFixed(2)
      updatedItems[index] = {
        ...updatedItems[index],
        amount: sum,
      }
      return updatedItems
    })
  }

  const handleSelectChangeInRows = (index, selectedOption) => {
    console.log(selectedOption)
    setSelectedSalesOrderItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        productName: selectedOption.label,
        itemId: selectedOption.value,
        selectedOption,
        salesAccount: selectedOption.salesAccount,
        price: selectedOption.price,
        amount: selectedOption.price,
        quantity: 1,
        type: selectedOption.type,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    let sum = selectedSalesOrderItems.reduce((acc, item) => {
      return acc + Number(item.amount)
    }, 0)
    sum += newItem.reduce((acc, item) => {
      return acc + Number(item.amount)
    }, 0)
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      subtotal: sum,
    }))
  }, [selectedSalesOrderItems, newItem])

  useEffect(() => {
    setInvoice((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [selectedSalesOrderItems, invoice.tax, newItem])

  useEffect(() => {
    if (invoice.subtotal === 0) {
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        total: 0,
      }))
      return
    }
    setInvoice((prevInvoice) => {
      const subtotal = Number(prevInvoice.subtotal) || 0
      const tax = Number(prevInvoice.tax) || 0
      const shippingFee = Number(prevInvoice.shippingFee) || 0
      const lateFees = Number(prevInvoice.lateFees) || 0
      const discount = Number(prevInvoice.discount) || 0

      const total = parseFloat((subtotal * (1 + tax / 100) + shippingFee + lateFees).toFixed(2))

      // deduct discount percent from total
      const discountAmount = (total - (total * discount) / 100).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(discountAmount),
        // You can add other properties if needed
      }
    })
  }, [invoice.tax, invoice.shippingFee, invoice.lateFees, invoice.discount, newItem])

  useEffect(() => {
    setInvoice((prevInvoice) => {
      const total = Number(prevInvoice.total) || 0
      const advance = Number(prevInvoice.advance) || 0
      const balance = (total - advance).toFixed(2)
      return {
        ...prevInvoice,
        balance: parseFloat(balance),
        // You can add other properties if needed
      }
    })
  }, [invoice.advance])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      customer: selectedOption.value,
    }))

    const selectedCustomer = customerList.find(
      (customer) => customer.value === selectedOption.value
    )
    if (selectedCustomer) {
      setSelectedCustomerCurrency(selectedCustomer.currency)
    }
  }

  const handleBillFromChange = (selectedOption) => {
    console.log(selectedOption)
    setselectedBillFrom(selectedOption)
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      employee: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }
  const handledueDateChange = (selectedDate) => {
    setSelectedTerms({label: 'Custom', value: 'custom'})
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      dueDate: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  const handleSubmit = async () => {
    if (!excelFile) {
      setname('')
    }
    if (invoice.customer === '') {
      alert('Please select customer')
      return
    } else if (invoice.employee === '') {
      alert('Please select employee')
      return
    }
    if (!selectFromInventory && newItem[0].productName === '') {
      alert('Please add item')
      return
    }

    setLoading(true)

    const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
    const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
    const dNM = `${prefix}-${year}-${month}-`

    const data = {
      ...invoice,
      items: selectFromInventory ? selectedSalesOrderItems : newItem,
      prefix: dNM,
      customID: customID,
      id: nextNumber,
      status: invoice.paymentReceived ? 'paid' : invoice.advanceReceived ? 'advance' : 'pending',
      deliveryNoteId: selectedSalesOrderId,
      salesOrderRef: selectedSalesOrderId1,
      partialStatus,
      partialId,
      approval: permissions?.invoice?.enable ? 'accepted' : 'pending',
      organization: currentUser?.organization,
      company: currentUser?.company,
      agent: currentUser?._id,
    }
    console.log(data)
    try {
      if (isSalesOrder) {
        await postInvoiceWithSalesOrder(data, '0').then((res) => {
          setSubmitted(true)
          setLoading(false)
          console.log('res', res)
          navigate(`/sales/invoice/${res._id}`)
        })
      } else {
        await postInvoice(data, '0').then((res) => {
          setSubmitted(true)
          setLoading(false)
          navigate(`/sales/invoice/${res._id}`)
          console.log('res', res)
        })
      }
    } catch (error) {
      console.error('Error creating invoice:', error)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleCategoryChange = async (e) => {
    setCategoryType(e.target.value)
    if (e.target.value === 'goods' || e.target.value === 'rentals') {
      if (e.target.value === 'goods') {
        if (goodsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setGoodsData(res))
        } else {
          const data = goodsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      } else {
        if (rentalsList.length === 0) {
          const res = await getFilteredStocks(currentUser?.organization, e.target.value)
          const data = res.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
          dispatch(setRentalsData(res))
        } else {
          const data = rentalsList.map((r) => ({
            value: r._id,
            label: r.productName,
            price: r.price,
            inWarehouseQuantity: r.inWarehouseQuantity,
            type: 'product',
          }))
          setProductList(data)
        }
      }
    } else {
      if (servicesList.length === 0) {
        const res = await getItemsByType('service', currentUser?.organization)
        const data = res.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
        dispatch(setServices(res))
      } else {
        const data = servicesList.map((r) => ({
          value: r._id,
          label: r.name,
          price: r.price,
          inWarehouseQuantity: 1,
          type: 'item',
        }))
        setProductList(data)
      }
    }
  }

  const additem = () => {
    setSelectedSalesOrderItems([
      ...selectedSalesOrderItems,
      {
        productName: '',
        unit: '',
        itemId: null,
        itemsId: null,
        price: '',
        quantity: 0,
        discount: 0,
        amount: '',
        type: 'product',
        salesAccount: '',
        inventoryAccount: '',
        purchaseAccount: '',
        selectedOption: {
          value: '',
          label: '',
          inWarehouseQuantity: 0,
        },
      },
    ])
  }

  const removeitem = (index) => {
    if (selectedSalesOrderItems.length === 1) {
      setSelectedSalesOrderItems([
        {
          productName: '',
          unit: '',
          itemId: null,
          itemsId: null,
          price: '',
          quantity: 0,
          discount: 0,
          amount: '',
          type: 'product',
          salesAccount: '',
          inventoryAccount: '',
          purchaseAccount: '',
          selectedOption: {
            value: '',
            label: '',
            inWarehouseQuantity: 0,
          },
        },
      ])
      return
    }
    const temp = [...selectedSalesOrderItems]
    temp.splice(index, 1)
    setSelectedSalesOrderItems(temp)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSaveChanges = () => {
    let newSalesOrderNumber = ''
    if (selectedOption === 'autoGenerate') {
      setPrefix(prefix)
      setNextNumber(nextNumber)
      setCustomID('')
      const year = String(selectYearAndMonth.getFullYear() - 2000).padStart(2, '0')
      const month = String(selectYearAndMonth.getMonth() + 1).padStart(2, '0')
      const dNM = `${year}-${month}`
      newSalesOrderNumber = `${prefix}-${dNM}-${nextNumber}`
    } else if (selectedOption === 'manualInput') {
      setCustomID(salesOrderNumber)
      setPrefix('')
      setNextNumber('')
      newSalesOrderNumber = salesOrderNumber
    }
    setSalesOrderNumber(newSalesOrderNumber)
    setShowModal(false)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            <div className='d-flex justify-end mb-2'>
              <button
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
                data-kt-menu='true'
                style={{zIndex: 1000000}}
              >
                <div className='px-7 py-2'>
                  <div className='fs-5 text-dark fw-bolder '>Filter Options</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Category:</label>
                    <select
                      className='form-select form-select-solid fw-bolder w-75'
                      onChange={handleCategoryChange}
                      value={categoryType}
                    >
                      <option value=''>Select an option</option>
                      {currentUser?.inventoryFeatures?.goods && (
                        <option value='goods'>Goods</option>
                      )}
                      {currentUser?.inventoryFeatures?.rentals && (
                        <option value='rentals'>Rentals</option>
                      )}
                      {currentUser?.inventoryFeatures?.service && (
                        <option value='service'>Services</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice date'
                    >
                      {/* begin::Date */}
                      {/* Date */}
                      {/* begin::Input */}

                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter invoice number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Invoice #</span>

                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-200px'
                        value={salesOrderNumber}
                        onChange={(e) => setSalesOrderNumber(e.target.value)}
                        placeholder='Invoice #'
                        readOnly
                      />

                      <button
                        className='btn btn-light ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowModal(true)
                        }}
                      >
                        <KTIcon iconName='gear' />
                      </button>
                      <div>
                        {showModal && (
                          <div
                            className='modal'
                            tabIndex={-1}
                            role='dialog'
                            style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}
                          >
                            <div className='modal-dialog modal-dialog-centered' role='document'>
                              <div className='modal-content'>
                                <div className='modal-header'>
                                  <h5 className='modal-title'>Customize Invoice No.</h5>
                                  <button
                                    type='button'
                                    className='btn-close'
                                    onClick={handleCloseModal}
                                  ></button>
                                </div>
                                <div className='modal-body'>
                                  {/* Your modal body content goes here */}
                                  <p>How would you like to Invoice numbers?</p>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='autoGenerate'
                                      value='autoGenerate'
                                      checked={selectedOption === 'autoGenerate'}
                                      onChange={() => setSelectedOption('autoGenerate')}
                                    />
                                    <label className='form-check-label' htmlFor='autoGenerate'>
                                      Continue auto-generating Invoice numbers
                                    </label>
                                  </div>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='salesOrderOption'
                                      id='manualInput'
                                      value='manualInput'
                                      checked={selectedOption === 'manualInput'}
                                      onChange={() => setSelectedOption('manualInput')}
                                    />
                                    <label className='form-check-label' htmlFor='manualInput'>
                                      Enter Invoice numbers manually
                                    </label>
                                  </div>

                                  {/* Additional input fields for manual input */}
                                  {selectedOption === 'autoGenerate' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>Prefix:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={prefix}
                                          onChange={(e) => setPrefix(e.target.value)}
                                          placeholder='Enter Prefix'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Month & Year:</label>
                                        <br />
                                        <DatePicker
                                          selected={selectYearAndMonth}
                                          onChange={(date) => setSelectYearAndMonth(date)}
                                          dateFormat='yyyy/MM'
                                          showMonthYearPicker
                                          className='form-control'
                                        />
                                      </div>
                                      <div className='mb-3'>
                                        <label className='form-label'>Next Number:</label>
                                        <input
                                          type='number'
                                          className='form-control'
                                          value={nextNumber}
                                          onChange={(e) => setNextNumber(e.target.value)}
                                          placeholder='Enter Next Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {selectedOption === 'manualInput' && (
                                    <div className='mt-3'>
                                      <div className='mb-3'>
                                        <label className='form-label'>INVOICE NO:</label>
                                        <input
                                          type='text'
                                          className='form-control'
                                          value={salesOrderNumber}
                                          onChange={(e) => setSalesOrderNumber(e.target.value)}
                                          placeholder='Enter Invoice Number'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className='modal-footer'>
                                  <button
                                    type='button'
                                    className='btn btn-secondary'
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={handleSaveChanges}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify invoice due date'
                    ></div>
                  </div>

                  <div className='separator separator-dashed my-10'></div>

                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Bill From
                        </label>

                        <Select
                          name='employee'
                          aria-label='Select a Employee'
                          placeholder='Select Employee'
                          options={employeeList}
                          defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillFromChange}
                          value={selectedBillFrom}
                        />
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                          Bill To
                        </label>

                        <Select
                          name='customer'
                          aria-label='Select a Customer'
                          placeholder='Select a Customer'
                          options={customerList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                    </div>

                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Delivery Note#
                        </label>
                        <Select
                          name='deliveryNoteNo.'
                          aria-label='Select a Sales order'
                          placeholder='Select a Delivery Note'
                          options={deliveryNoteOptions}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleDeliveryNoteChange}
                          value={
                            selectedSalesOrderLabel
                              ? {
                                  value: selectedSalesOrderId,
                                  label: selectedSalesOrderLabel,
                                }
                              : null
                          }
                        />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Sales Order#
                        </label>
                        <Select
                          name='salesOrderRef'
                          aria-label='Select a Sales order'
                          placeholder='Select a Sales order'
                          options={salesOrderOptions}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleSalesOrderChange}
                          value={
                            selectedSalesOrderLabel1
                              ? {
                                  value: selectedSalesOrderId1,
                                  label: selectedSalesOrderLabel1,
                                }
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          LPO No.
                        </label>
                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='lpo'
                          placeholder='LPO'
                          value={invoice.lpo}
                          onChange={handleChange}
                        />
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>TRN No</label>

                        <input
                          type='text'
                          className='form-control form-control-solid '
                          name='trn'
                          placeholder='TRN'
                          value={invoice.trn}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Invoice Date
                        </label>
                        <Flatpickr
                          value={invoice.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>Terms</label>
                        <Select
                          name='terms'
                          aria-label='Select a Terms'
                          placeholder='Select a Terms'
                          options={termsOption}
                          className='form-control form-control-solid'
                          onChange={handleTermsChange}
                          value={selectedTerms}
                        />
                      </div>

                      <div className='col-lg-4'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Due Date
                        </label>
                        <Flatpickr
                          value={invoice.dueDate}
                          onChange={handledueDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                          options={{
                            dateFormat: 'd-m-Y',
                          }}
                        />
                      </div>
                    </div>

                    {showProductSelectionButton && (
                      <div className='d-flex align-items-center justify-content-start gap-5 mt-5 mb-3'>
                        <button
                          type='button'
                          className={`btn btn-light-success ${selectFromInventory && 'active'}`}
                          onClick={() => {
                            setSelectFromInventory(true)
                            setNewItem([])
                            setSelectedSalesOrderItems([
                              {
                                productName: '',
                                unit: '',
                                itemId: null,
                                itemsId: null,
                                price: '',
                                quantity: 0,
                                discount: 0,
                                amount: '',
                                inventoryAccount: '',
                                salesAccount: '',
                                purchaseAccount: '',
                                selectedOption: {
                                  value: '',
                                  label: '',
                                  inWarehouseQuantity: 0,
                                },
                                type: 'product',
                              },
                            ])
                          }}
                        >
                          Select from Inventory
                        </button>
                        <button
                          type='button'
                          className={`btn btn-light-warning ${!selectFromInventory && 'active'}`}
                          onClick={() => {
                            setSelectFromInventory(false)
                            setSelectedSalesOrderItems([])
                            setNewItem([
                              {
                                productName: '',
                                unit: '',
                                itemId: null,
                                itemsId: null,
                                price: '',
                                quantity: 0,
                                discount: 0,
                                amount: '',
                              },
                            ])
                          }}
                        >
                          Add New Item
                        </button>
                      </div>
                    )}

                    <div className='fs-6 fw-bold text-gray-700 mb-3 mt-5'>Item Table</div>
                    <div className='table-responsive mb-10'>
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-125px w-500px'>Item</th>
                            <th className='min-w-50px'>Quantity</th>
                            <th className='min-w-70px w-70px'>Rate</th>
                            {!selectFromInventory && (
                              <th className='min-w-40px w-40px px-0'>Unit</th>
                            )}
                            <th className='min-w-50px'>Discount</th>
                            <th className='min-w-70px w-250px'>Amount</th>
                            {selectedSalesOrderId === null && selectedSalesOrderId1 === null && (
                              <th className='min-w-70px w-50px'>Action</th>
                            )}
                          </tr>
                        </thead>

                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId='droppable'>
                            {(provided) => (
                              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                {selectFromInventory &&
                                  selectedSalesOrderItems?.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className=' border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='pe-7'>
                                            {/* begin::Input group */}
                                            {selectedSalesOrderId === null &&
                                            selectedSalesOrderId1 === null ? (
                                              <Select
                                                name='itemId'
                                                aria-label='Select a Product'
                                                placeholder='Select a Product'
                                                options={productList}
                                                isSearchable={true}
                                                className='form-control form-control-solid min-w-200px'
                                                onChange={(e) => handleSelectChangeInRows(index, e)}
                                                value={item.selectedOption}
                                              />
                                            ) : (
                                              <>
                                                <div className='form-control form-control-solid'>
                                                  {item?.productName}
                                                </div>
                                              </>
                                            )}
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              min={0}
                                              value={item.quantity}
                                              disabled={
                                                selectedSalesOrderId === null &&
                                                selectedSalesOrderId1 === null
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              disabled={
                                                selectedSalesOrderId === null &&
                                                selectedSalesOrderId1 === null
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              disabled={
                                                selectedSalesOrderId === null &&
                                                selectedSalesOrderId1 === null
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          {selectedSalesOrderId === null &&
                                            selectedSalesOrderId1 === null && (
                                              <td>
                                                <div
                                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                                  onClick={additem}
                                                >
                                                  <KTIcon iconName='plus-square' className='fs-3' />
                                                </div>
                                                <div
                                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                                  onClick={() => removeitem(index)}
                                                >
                                                  <KTIcon iconName='trash' className='fs-3' />
                                                </div>
                                                <div
                                                  {...provider.dragHandleProps}
                                                  className='fs-3 fw-bold text-gray-400 ms-3'
                                                >
                                                  .{index + 1}.
                                                </div>
                                              </td>
                                            )}
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                                {!selectFromInventory &&
                                  newItem.map((item, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={`item-${index}`}
                                      index={index}
                                    >
                                      {(provider) => (
                                        <tr
                                          className=' border-bottom border-bottom-dashed'
                                          data-kt-element='item'
                                          key={index}
                                          ref={provider.innerRef}
                                          {...provider.draggableProps}
                                        >
                                          <td className='pe-0'>
                                            {/* begin::Input group */}
                                            <input
                                              type='text'
                                              className='form-control form-control-solid'
                                              name='productName'
                                              placeholder='Enter Item Name'
                                              value={item.productName}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='quantity'
                                              placeholder='0.00'
                                              min={0}
                                              value={item.quantity}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='price'
                                              placeholder='0.00'
                                              value={item.price}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td className='px-2'>
                                            {/* begin::Input group */}
                                            <select
                                              name='unit'
                                              id=''
                                              className='form-control form-control-solid'
                                              value={item.unit}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            >
                                              <option value=''>unit</option>
                                              {unitsList.map((unit, index) => (
                                                <option value={unit} key={unit}>
                                                  {unit}
                                                </option>
                                              ))}
                                            </select>
                                          </td>

                                          <td className='pe-7'>
                                            {/* begin::Input group */}

                                            <input
                                              type='number'
                                              className='form-control form-control-solid'
                                              name='discount'
                                              placeholder='0.00'
                                              value={item.discount}
                                              onChange={(e) => handleNewItemChange(index, e)}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type='number'
                                              className='form-control form-control-solid text-end'
                                              name='amount'
                                              placeholder='0.00'
                                              value={item.amount}
                                              disabled
                                              // onChange={(e) => handleChangerows(index, e)}
                                            />
                                          </td>
                                          <td>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                              onClick={() => {
                                                setNewItem([
                                                  ...newItem,
                                                  {
                                                    productName: '',
                                                    unit: '',
                                                    itemId: null,
                                                    itemsId: null,
                                                    price: '',
                                                    quantity: 0,
                                                    discount: 0,
                                                    amount: '',
                                                  },
                                                ])
                                              }}
                                            >
                                              <KTIcon iconName='plus-square' className='fs-3' />
                                            </div>
                                            <div
                                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                              onClick={() => {
                                                if (newItem.length === 1) {
                                                  setNewItem([
                                                    {
                                                      productName: '',
                                                      unit: '',
                                                      itemId: null,
                                                      itemsId: null,
                                                      price: '',
                                                      quantity: 0,
                                                      discount: 0,
                                                      amount: '',
                                                    },
                                                  ])
                                                  return
                                                }
                                                const temp = [...newItem]
                                                temp.splice(index, 1)
                                                setNewItem(temp)
                                              }}
                                            >
                                              <KTIcon iconName='trash' className='fs-3' />
                                            </div>
                                            <div
                                              {...provider.dragHandleProps}
                                              className='fs-3 fw-bold text-gray-400 ms-3'
                                            >
                                              .{index + 1}.
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                              </tbody>
                            )}
                          </Droppable>
                        </DragDropContext>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-end'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  type='button'
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  VAT %
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Discount in percentage'
                                >
                                  Discount %
                                </button>
                                {lateFees && <div className='fs-5 mt-5'>Late Fees</div>}
                                {shippingFees && <div className='fs-5 mt-5'>Shipping Fee</div>}
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed text-end'>
                              <span data-kt-element='sub-total' className='me-8'>
                                {invoice.subtotal
                                  ? invoice.subtotal.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })
                                  : 0}{' '}
                                {selectedCustomerCurrency}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid text-end'
                                name='tax'
                                placeholder='0.00'
                                min='0'
                                value={invoice.tax}
                                onChange={handleChange}
                              />
                              <input
                                type='number'
                                className='form-control form-control-solid text-end'
                                name='discount'
                                placeholder='0.00'
                                min='0'
                                value={invoice.discount}
                                onChange={handleChange}
                              />
                              {lateFees && (
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='lateFees'
                                  placeholder='0.00'
                                  min='0'
                                  value={invoice.lateFees}
                                  onChange={handleChange}
                                />
                              )}
                              {shippingFees && (
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='shippingFee'
                                  min='0'
                                  placeholder='0.00'
                                  value={invoice.shippingFee}
                                  onChange={handleChange}
                                />
                              )}
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-100px w-100px text-end'>Total</th>
                            <th className=' fs-4 text-nowrap min-w-70px w-70px text-end '>
                              <span data-kt-element='grand-total text-end'>
                                {invoice.total
                                  ? invoice.total.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })
                                  : 0}{' '}
                                {selectedCustomerCurrency}
                              </span>
                            </th>
                          </tr>
                          {invoice.advanceReceived && (
                            <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                              <th className='text-primary'></th>
                              <th className='text-primary'></th>
                              <th className='text-primary'></th>
                              <th className='border-bottom border-bottom-dashed ps-0'>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='fs-5'>Advance</div>
                                  <div className='fs-4 mt-5'>Balance</div>
                                </div>
                              </th>
                              <th className='border-bottom border-bottom-dashed text-center'>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-center'
                                  name='advance'
                                  placeholder='0.00'
                                  min='0'
                                  value={invoice.advance}
                                  onChange={handleChange}
                                />
                                <span data-kt-element='sub-total' className=' '>
                                  AED{' '}
                                  {invoice.balance.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </span>
                              </th>
                            </tr>
                          )}
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <textarea
                        name='notes'
                        className={`  form-control form-control-solid mb-0 h-10 ${
                          isEditingNotes ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter customer notes'
                        value={invoice.notes}
                        onChange={handleChange}
                        readOnly={!isEditingNotes}
                        onKeyDown={handleKeyPress}
                        style={{
                          minHeight: '6rem',
                          height: notesTextareaHeight,
                          resize: 'none',
                        }}
                      />
                      <button onClick={(e) => handleEditClick(e, 'notes')}>
                        <KTIcon
                          iconName='pencil'
                          className='position-absolute text-black bg-gray-100 right-10 top-[3rem] fs-3'
                        />
                      </button>
                    </div>

                    <div className='mb-10 position-relative'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>
                        Terms & Conditions
                      </label>
                      <textarea
                        name='termsNCondition'
                        style={{
                          minHeight: '6rem',
                          height: termsTextareaHeight,
                          resize: 'none',
                        }}
                        className={`form-control form-control-solid mb-0 h-10 ${
                          isEditingTerms ? 'border border-dark-subtle' : ''
                        }`}
                        placeholder='Enter terms and conditions'
                        value={invoice.termsNCondition}
                        onChange={handleChange}
                        readOnly={!isEditingTerms}
                      />
                      <button onClick={(e) => handleEditClick(e, 'termsNCondition')}>
                        <KTIcon
                          iconName='pencil'
                          className='absolute text-black bg-gray-100 right-10 top-[3rem] fs-3'
                        />
                      </button>
                    </div>
                    {invoice.paymentReceived && (
                      <div className=''>
                        <div className='row gx-10 mb-5'>
                          {/* begin::Col */}
                          <div className='col-lg-6'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Payment Mode
                            </label>
                            {/* begin::Input group */}
                            <select
                              className='form-select form-select-solid'
                              value={invoice.paymentMode}
                              name='paymentMode'
                              onChange={handleChange}
                            >
                              <option value='cash'>Cash</option>
                              <option value='cheque'>Cheque</option>
                            </select>
                            {/* Input group */}
                          </div>
                          {/* Col */}
                          {/* begin::Col */}
                          <div className='col-lg-6'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Deposit To
                            </label>
                            {/* begin::Input group */}
                            <select
                              className='form-select form-select-solid'
                              value={invoice.depositTo}
                              name='depositTo'
                              onChange={handleChange}
                            >
                              {accountsNameList?.map((item) => {
                                return (
                                  <option value={item?.value} key={item?.value}>
                                    {item?.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          {/* Col */}
                        </div>
                      </div>
                    )}
                    <div className='row gx-10 mb-5'>
                      <div
                        className='card-toolbar me-5'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_11'
                      >
                        <a className='btn btn-sm btn-light-primary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Upload File
                        </a>
                      </div>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='invoice'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>Currency</label>
                  <Select
                    name='currency'
                    aria-label='Select a Currency'
                    placeholder='Select currency'
                    options={currencyOptions}
                    defaultValue={defaultCurrency}
                    isSearchable={true}
                    className=''
                  />
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}

                {/* <!--::Separator-->
                        <!--begin::Input group--> */}
                <div className='mb-8'>
                  {/* <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Payment Received
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='paymentReceived'
                      checked={invoice.paymentReceived}
                      onChange={(e) => {
                        setInvoice((prevInvoice) => ({
                          ...prevInvoice,
                          paymentReceived: e.target.checked,
                          // You can add other properties if needed
                        }))
                      }}
                    />
                  </label>

                  {isSalesOrder && (
                    <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                      <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                        Advance Received
                      </span>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='advanceReceived'
                        checked={invoice.advanceReceived}
                        onChange={(e) => {
                          setInvoice((prevInvoice) => ({
                            ...prevInvoice,
                            advanceReceived: e.target.checked,
                            // You can add other properties if needed
                          }))
                          if (e.target.checked) {
                            setPartialStatus('advance')
                          } else {
                            setPartialStatus('')
                          }
                        }}
                      />{' '}
                    </label>
                  )}

                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Shipping fees
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={shippingFees}
                      onChange={(e) => {
                        setShippingFees(e.target.checked)
                        setInvoice((prevInvoice) => ({
                          ...prevInvoice,
                          shippingFee: 0,
                        }))
                      }}
                    />
                  </label>
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Late fees
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={lateFees}
                      onChange={(e) => {
                        setLateFees(e.target.checked)
                        setInvoice((prevInvoice) => ({
                          ...prevInvoice,
                          lateFees: e.target.checked ? 20 : 0,
                        }))
                      }}
                    />
                  </label>
                  {/* <!--::Option--> */}
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                <div className='separator separator-dashed mb-8'></div>
                {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}
                  {!submitted ? (
                    <button
                      className='btn btn-primary w-100'
                      id='kt_quote_submit_button'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      {loading ? 'Creating Invoice...' : 'Create Invoice'}
                    </button>
                  ) : (
                    <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Print Invoice
                    </button>
                  )}
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_11'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Files</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>File Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className='form-control'
                    />
                  </div>

                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload File
                    </a>
                  </div>

                  <input
                    type='file'
                    accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{display: 'none'}}
                  />
                  {excelFile && <p>Selected file: {excelFile.name}</p>}
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                disabled={isLoading}
                onClick={handleUpload}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default CreateInvoice
