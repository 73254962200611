import React, { lazy } from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {NewVendor} from './Pages/NewVendor'
import VendorWrapper from './Pages/VendorWrapper'
import {ViewVendor} from './Pages/ViewVendor'
import PurchaseOrdersList from './Pages/PurchaseOrdersList'
import {AddQuotation} from './Components/AddQuotation'
import PurchaseReceiveList from './Pages/PurchaseReceivesList'
import AddPurchaseReceive from './Components/AddPurchaseReceive'
import AddRFQ from './Components/AddRFQ'
import OrderManagement from './Pages/OrderManagement'
import OrderManagementHeader from './Pages/OrderManagementHeader'
import AddRFQToOrder from './Components/AddRFQToOrder'
import PurchaseOrderDetails from './Pages/PurchaseOrderDetails'
import PurchaseReceiveDetails from './Pages/PurchaseReceiveDetails'
import RFQDetails from './Pages/RFQDetails'
import EditVendor from './Pages/EditVendor'
import BillsList from './Pages/BillsList'
import AddBills from './Components/AddBills'
import ApprovalPage from './Pages/ApprovalPage'
import PaymentMadeList from './Pages/PaymentMadeList'
import AddPaymentMade from './Components/AddPaymentMade'
import BillsDetails from './Components/Bills/BillsDetails'
import AddPurchaseOrderToOrder from './Components/AddPurchaseOrderToOrder'
import AddBillsToOrder from './Components/AddBillsToOrder'
import AddPaymentMadeToOrder from './Components/AddPaymentMadeToOrder'
import AddPurchaseReceiveToOrder from './Components/AddPurchaseReceiveToOrder'
import BillDetails from './Pages/BillDetails'
import EditRFQ from './Components/EditRFQ'
import VendorPerformance from '../reports/components/VendorPerformance'
import PaymentMadeDetails from './Pages/PaymentMadeDetails'
import EmailComposerPurchaseOrder from './Components/EmailComposerPurchaseOrder'
import EmailComposerPurchaseReceive from './Components/EmailComposerPurchaseReceive'
import EmailComposerRFQ from './Components/EmailComposerRFQ'
import EmailComposerBills from './Components/EmailComposerBills'
import AddPurchaseReceiveWithPurchaseOrder from './Components/AddPurchaseReceivWithPurchaseOrder'
import AddBillsWithPurchaseReceive from './Components/AddBillsWithPurchaseReceive'
import AddPaymentMadeWithBill from './Components/AddPaymentMadeWithBill'
import AddPartialPurchaseReceive from './Components/AddPartialPurchaseReceive'
import EmailComposerPaymentMade from './Components/EmailComposerPaymentMade'
import EmailComposerVendor from './Components/EmailComposerVendor'
import { SuspensedView } from '../../routing/PrivateRoutes'
import RFQ from './Pages/RFQ'

export default function VendorPage() {
  const EditPurchaseOrder = lazy(()=>import('./Components/EditPurchaseOrder'))
  return (
    <Routes>
      <Route path='/vendor' element={<VendorWrapper />} />
      <Route path='/vendor/newVendor' element={<NewVendor />} />
      <Route path='/vendor/view-vendor/:id' element={<ViewVendor />} />
      <Route path='/vendor/view-vendor/:id/email-composer/:dateFilter' element={<EmailComposerVendor />} />
      <Route path='/vendor/edit/:id' element={<EditVendor />} />

      <Route path='/purchaseOrdersList' element={<PurchaseOrdersList />} />
      <Route path='/purchaseOrdersList/:id' element={<PurchaseOrderDetails />} />
      <Route path='/purchaseOrdersList/edit/:id' element={
        <SuspensedView>
          <EditPurchaseOrder />
        </SuspensedView>
    } />
      <Route
        path='/purchaseOrdersList/:id/email-composer'
        element={<EmailComposerPurchaseOrder />}
      />
      <Route path='/purchaseOrdersList/add' element={<AddQuotation />} />

      <Route path='/purchaseReceiveList' element={<PurchaseReceiveList />} />
      <Route path='/purchaseReceiveList/:id' element={<PurchaseReceiveDetails />} />
      <Route
        path='/purchaseReceiveList/:id/email-composer'
        element={<EmailComposerPurchaseReceive />}
      />
      <Route path='/purchaseReceiveList/add' element={<AddPurchaseReceive />} />
      <Route path='/purchaseReceiveList/add-partial/:id' element={<AddPartialPurchaseReceive />} />
      <Route
        path='/purchaseReceiveList/purchaseorder/:id'
        element={<AddPurchaseReceiveWithPurchaseOrder />}
      />

      <Route path='/rfq' element={<RFQ />} />
      <Route path='/rfq/add' element={<AddRFQ />} />
      <Route path='/rfq/:id' element={<RFQDetails />} />
      <Route path='/rfq/:id/email-composer' element={<EmailComposerRFQ />} />
      <Route path='/rfq/edit/:id' element={<EditRFQ />} />

      <Route path='/order-management' element={<OrderManagement />} />
      <Route path='/order-management/orderdetails/:id' element={<OrderManagementHeader />} />
      <Route path='/rfqtoorder/add/:id' element={<AddRFQToOrder />} />
      <Route path='/purchaseordertoorder/add/:id/' element={<AddPurchaseOrderToOrder />} />
      <Route path='/billstoorder/add/:id/' element={<AddBillsToOrder />} />
      <Route path='/purchasereceivetoorder/add/:id/' element={<AddPurchaseReceiveToOrder />} />
      <Route path='/paymentmadetoorder/add/:id/' element={<AddPaymentMadeToOrder />} />

      <Route path='/billsList' element={<BillsList />} />
      <Route path='/billsList/add' element={<AddBills />} />
      <Route path='/billsList/:id' element={<BillDetails />} />
      <Route path='/billsList/:id/email-composer' element={<EmailComposerBills />} />
      <Route path='/billsList/purchasereceive/:id' element={<AddBillsWithPurchaseReceive />} />

      <Route path='/paymentMadeList' element={<PaymentMadeList />} />
      <Route path='/paymentMadeList/add' element={<AddPaymentMade />} />
      <Route path='/paymentMadeList/:id' element={<PaymentMadeDetails />} />
      <Route path='/paymentMadeList/:id/email-composer' element={<EmailComposerPaymentMade />} />
      <Route path='/paymentMadeList/bill/:id' element={<AddPaymentMadeWithBill />} />

      <Route path='/performance' element={<VendorPerformance />} />

      <Route path='/approval/*' element={<ApprovalPage />} />
    </Routes>
  )
}
