import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ADD_IMAGES_URL = `${API_URL}/images`

export const createCategory = async (category) => {
  try {
    const response = await axios.post(`${API_URL}/category`, category)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function categoryImages(imageData) {
  try {
    const response = await axios.post(ADD_IMAGES_URL, imageData)
    console.log('image uploaded to server')
    return response.data
  } catch (error) {
    console.error('Add image error:', error)
    throw error
  }
}

export const getCategory = async (orgId) => {
  try {
    const response = await axios.get(`${API_URL}/category/${orgId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getFilteredCategory= async (query, orgid) => {
  try {
    const response = await axios.get(`${API_URL}/category/filter/${orgid}/?${query}`)
    console.log('Stocks Fetched', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCategoriesForItems = async (orgId) => {
  try {
    const response = await axios.get(`${API_URL}/category/items/${orgId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCategoriesForProducts = async (orgId, category) => {
  try {
    const response = await axios.get(`${API_URL}/category/products/${orgId}/${category}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCategoryById = async (categoryID) => {
  try {
    const response = await axios.get(`${API_URL}/category/getcategorybyid/${categoryID}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const deleteCategory = async (stockId) => {
  try {
    const response = await axios.delete(`${API_URL}/category/${stockId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const updateCategory = async (categoryID, categorydata) => {
  try {
    console.log(categorydata)
    const response = await axios.put(`${API_URL}/category/${categoryID}`, categorydata)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

console.log('products catelog', `${API_URL}/products/`)
export const productCatalog = async (categoryID) => {
  try {
    const response = await axios.get(`${API_URL}/category/productscategory/${categoryID}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    console.error('Error in productCatalog request:', error.message)
    throw error
  }
}
