import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  performance: [],
  loading: true,
  error: null,
}

const projectPerformanceSlice = createSlice({
  name: 'projectPerformance',
  initialState,
  reducers: {
    setPerformance(state, action) {
      state.performance = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
  },
})

export const {setPerformance, setLoading, setError} = projectPerformanceSlice.actions
export default projectPerformanceSlice.reducer
