import  { useEffect, useRef } from 'react';
import { MapChart, MapPolygon, MapPolygonSeries, ZoomControl, } from '@amcharts/amcharts5/map';
import { Root } from '@amcharts/amcharts5/.internal/core/Root';
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import themes_Animated from '@amcharts/amcharts5/themes/Animated'

type Props = {};

const StatWorldMap = (props: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const root = Root.new(chartRef.current);
    root.setThemes([themes_Animated.new(root)]);

    const chart = root.container.children.push(MapChart.new(root, {
      panX: "translateX",
      panY: "translateY",
    }));

    const polygonSeries = chart.series.push(MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow,
      exclude: ["AQ"]
    }));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      interactive: true
    });

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: root.interfaceColors.get("primaryButtonHover")
    });

    polygonSeries.mapPolygons.template.states.create("active", {
      fill: root.interfaceColors.get("primaryButtonHover")
    });

    var previousPolygon;

    polygonSeries.mapPolygons.template.on("active", function (active, target) {
      if (previousPolygon && previousPolygon != target) {
        previousPolygon.set("active", false);
      }
    
      if (target.get("active")) {
        const dataItem = target.dataItem as MapPolygon['dataItem']; // Explicitly type the data item
        polygonSeries.zoomToDataItem(dataItem as any);
      } else {
        chart.goHome();
      }
    
      previousPolygon = target;
    });
    chart.set("zoomControl", ZoomControl.new(root, {}));

    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
    });

    chart.appear(1000, 100);

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [chartRef]);

  return (
    <div>
      <div
        ref={chartRef}
        id="chartdiv"
        style={{ height: '350px' }}
        className="card-rounded-bottom"
      ></div>
    </div>
  );
};

export  {StatWorldMap};
