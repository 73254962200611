import React, {FC} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {DatePicker} from '../../../../../_metronic/partials/widgets'
import {ICreateAccount} from '../../core/CreateAccountWizardHelper'

const Step4: FC = () => {
  const {setFieldValue, values} = useFormikContext<ICreateAccount>()
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Personal Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            Help Page
          </a>
          .
        </div>
      </div>
      {/* 
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Specify Team Size
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='1-1'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>1-1</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='2-10'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>2-10</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='10-50'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>10-50</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='radio'
              className='btn-check'
              name='accountTeamSize'
              value='50+'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>50+</span>
            </label>
          </div>
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div> */}

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Date of Birth</label>
        <input
          type='date'
          name='dateOfBirth'
          value={
            values?.dateOfBirth ? new Date(values.dateOfBirth).toISOString().split('T')[0] : ''
          }
          onChange={(e) => {
            setFieldValue('dateOfBirth', e.target.value)
            const today = new Date();
            const birthDateObj = new Date(e.target.value);
            
            let age = today.getFullYear() - birthDateObj.getFullYear();
            const monthDifference = today.getMonth() - birthDateObj.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
              age--;
            }
 
            setFieldValue('age', age)
          }}
          className='form-control form-control-md form-control-solid'
        ></input>

        {/* <Field as='date' name='dateOfBirth' />
        <DatePicker /> */}
        <div className='text-danger mt-2'>
          <ErrorMessage name='dateOfBirth' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Age</label>

        <Field
          type='number'
          className='form-control form-control-lg form-control-solid'
          name='age'
          placeholder='enter your age'
        />
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Gender</label>

        <Field as='select' name='gender' className='form-select form-select-lg form-select-solid'>
          <option></option>
          <option value='Male'>Male</option>
          <option value='Female'>Female</option>
        </Field>
      </div>

      <div className='mb-10 fv-row'>
        <label className=' align-items-center form-label mb-5'>Marital Status</label>
        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-2'>
              <Field
                className='form-check-input '
                type='radio'
                name='maritalStatus'
                value='single'
              />
            </span>
            <span className='text-muted fs-6 form-control form-control-md form-control-solid'>
              Single
            </span>
          </label>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid me-2'>
              <Field
                className='form-check-input'
                type='radio'
                name='maritalStatus'
                value='Married'
              />
            </span>
            <span className='text-muted fs-6 form-control form-control-md form-control-solid '>
              Married
            </span>
          </label>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label'>About Employee</label>
        <Field
          as='textarea'
          name='aboutMe'
          className='form-control form-control-lg form-control-solid'
        ></Field>
      </div>

      {/* <div className='mb-0 fv-row'>
        <label className='d-flex align-items-center form-label mb-5'>
          Select Account Plan
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Monthly billing will be based on your account plan'
          ></i>
        </label>

        <div className='mb-0'>
          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='bank' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Company Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance your post flow
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='1' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-5 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Developer Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>Use images to your post time</span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='2' />
            </span>
          </label>

          <label className='d-flex flex-stack mb-0 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label'>
                  <KTIcon iconName='chart-pie-4' className='fs-1 text-gray-600' />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                  Testing Account
                </span>
                <span className='fs-6 fw-bold text-gray-400'>
                  Use images to enhance time travel rivers
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <Field className='form-check-input' type='radio' name='accountPlan' value='3' />
            </span>
          </label>
        </div>
      </div> */}
    </div>
  )
}

export {Step4}
