import React, {useEffect, useState} from 'react'
import {getGroupInvoiceById, sendGroupInvoiceMailByID} from '../Core/_requests'
import toast from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

const EmailComposerGroupInvoice = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [data, setData] = useState<any>()

  const [formData, setFormData] = useState({
    from: currentUser.email,
    to: '',
    cc: '',
    subject: '',
    body: '',
    attachFile: true,
    attachpdf: true,
  })

  const id = useParams().id
  const [emailLoading, setEmailLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getGroupInvoiceById(id)
        setData(res)
        if (res && res.customer && res.customer.emailAddress) {
          const formattedDate = new Date(res.date).toLocaleDateString('en-GB')
          setFormData((prevData) => ({
            ...prevData,
            to: res.customer.emailAddress,
            subject: `INVOICE FROM QUANTUMFLOW (${res.id})`,
            body: `Dear Customer,\n\nAttached is the Invoice for your recent interaction with QuantumFlow.\n\n Invoice: ${res.id} \n\n Date: ${formattedDate}\n\nThank you for your business!`,
          }))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    getData()
  }, [id])

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target
    const newValue = type === 'checkbox' ? checked : value
    setFormData((prev) => ({...prev, [name]: newValue}))
  }

  const sendEmail = async (id) => {
    setEmailLoading(true)
    try {
      const emailData = {
        to: formData.to,
        cc: formData.cc,
        sendMain: formData.attachpdf,
        sendDefault: formData.attachFile,
        subject: formData.subject,
        body: formData.body,
      }
      console.log('email data', emailData)
      const response = await sendGroupInvoiceMailByID(id, emailData)
      setEmailLoading(false)
      toast.success('Email sent successfully', {
        position: 'top-center', // Position the notification at the top center of the screen
        style: {
          fontSize: '18px', // Increase font size
          width: '400px', // Adjust width
          textAlign: 'center', // Center text
        },
      })
      navigate(-1)
    } catch (error) {
      console.error('Axios error:', error)
      setEmailLoading(false)
      throw error
    }
  }

  return (
    <div className='flex justify-center items-center '>
      <div className='w-2/3 p-4 bg-white rounded-lg shadow-md'>
        <h2 className='text-3xl py-2 font-semibold mb-4 shadow-inherit border-b'>Compose Email</h2>

        <div className=''>
          <div className='flex flex-col gap-5 w-[100%]'>
            <div className='flex gap-2 items-center w-[100%]'>
              <label
                className='w-[55px] block text-gray-700 text-[14px] font-bold mb-2'
                htmlFor='from'
              >
                From
              </label>
              <input
                type='email'
                id='from'
                name='from'
                value={formData.from}
                onChange={handleChange}
                className='shadow-md appearance-none border rounded w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none'
                disabled
              />
            </div>

            <div className='flex gap-2 items-center'>
              <label
                className='w-[55px] block text-gray-700 text-[14px] font-bold mb-2'
                htmlFor='to'
              >
                To
              </label>
              <input
                type='email'
                id='to'
                name='to'
                value={formData.to}
                onChange={handleChange}
                className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
            </div>

            <div className='flex gap-2 items-center'>
              <label
                className='w-[55px] block text-gray-700 text-[14px] font-bold mb-2'
                htmlFor='cc'
              >
                Cc
              </label>
              <input
                type='email'
                id='cc'
                name='cc'
                value={formData.cc}
                onChange={handleChange}
                className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
            </div>

            <div className='flex gap-2 items-center'>
              <label
                className='w-[55px] block text-gray-700 text-[14px] font-bold mb-2'
                htmlFor='subject'
              >
                Subject
              </label>
              <input
                type='text'
                id='subject'
                name='subject'
                value={formData.subject}
                onChange={handleChange}
                className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
            </div>
            <hr />
          </div>

          <div className='mt-4 col-span-6 mb-4 space-y-2 row-span-5'>
            <textarea
              id='body'
              name='body'
              placeholder='Email Body Here'
              value={formData.body}
              onChange={handleChange}
              rows={10}
              className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'
            ></textarea>
            <hr />
          </div>

          <label className='block border px-2 py-4 rounded-[5px]  bg-slate-50 text-gray-700 text-[14px] font-bold mb-2'>
            Attach Invoice PDF
            <input
              name='attachpdf'
              type='checkbox'
              checked={formData.attachpdf}
              onChange={handleChange}
              className='ml-2 input-[type=fil]'
            />
          </label>

          {/* Add checkbox for attaching files */}
          <div className='mb-4 justify-between border p-2 rounded-[5px] flex items-center bg-slate-50 row-span-1'>
            <label className='block text-gray-700 text-[14px] font-bold mb-2'>
              Attach Related Documents
              <input
                name='attachFile'
                type='checkbox'
                checked={formData.attachFile}
                onChange={handleChange}
                className='ml-2 input-[type=fil]'
              />
            </label>

            <button
              type='button'
              className={`btn btn-sm btn-primary ${emailLoading ? 'disabled' : ''}`}
              onClick={() => sendEmail(id)}
              disabled={emailLoading}
            >
              {emailLoading ? 'Sending...' : 'Send Email'}
            </button>
          </div>
        </div>
      </div>

      {/* Add buttons or other UI elements as needed */}
    </div>
  )
}

export default EmailComposerGroupInvoice
