import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ADD_IMAGES_URL = `${API_URL}/images`
const ADD_MEDIA_URL = `${API_URL}/media`
const ACCOUNTS_URL = `${API_URL}/accounts`
const ITEMS_URL = `${API_URL}/items`
const POST_DOCS_URL = `${API_URL}/uploaddocsinproduct/`
const POST_DOCS_URL_QF = `${API_URL}/uploaddocsinqf/`
const POST_DOCS_SERVICE_URL = `${API_URL}/uploaddocsinitems/`
const TRANSACTIONS_URL = `${API_URL}/transactions`
const INVENTORY_ADJUSTMENT_URL = `${API_URL}/inventory-adjustment`
const COMPANY_URL = `${API_URL}/company`

export const createStock = async (stockData) => {
  try {
    const response = await axios.post(`${API_URL}/products`, stockData)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStocks = async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStocksByType = async (orgid, category) => {
  try {
    const response = await axios.get(`${API_URL}/products/${orgid}/${category}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getFilteredStocksByType= async (query, orgid, category) => {
  try {
    console.log("API",`${API_URL}/products/filter/${orgid}/${category}?${query}`)
    const response = await axios.get(`${API_URL}/products/filter/${orgid}/${category}?${query}`)
    console.log('Stocks Fetched', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getReorderStocks= async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/reorder-n-restock/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getOrderUpdates= async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/get-order-updates/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getProductById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/products/getproductbyid/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const deleteStock = async (stockId) => {
  try {
    const response = await axios.delete(`${API_URL}/products/${stockId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const updateProduct = async (productID, productdata) => {
  try {
    console.log(productdata)
    const response = await axios.put(`${API_URL}/products/${productID}`, productdata)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function productImage(imageData) {
  try {
    const response = await axios.post(ADD_IMAGES_URL, imageData)
    console.log('image uploaded to server')
    return response.data
  } catch (error) {
    console.error('Add image error:', error)
    throw error
  }
}

export async function productMedia(mediaData) {
  try {
    const response = await axios.post(ADD_MEDIA_URL, mediaData)
    console.log('images uploaded to server')
    return response.data
  } catch (error) {
    console.error('Add images error:', error)
    throw error
  }
}

export const getAccounts = async (orgid, accountsType) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/${orgid}/${accountsType}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccountsForInventoryAdjustment = async (orgid) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/accountsforinventoryadjustment/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getStockAccounts = async (orgid) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getstockaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getTagsByOrgId = async (orgid) => {
  try {
    const response = await axios.get(`${API_URL}/products/gettags/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addTagToOrg = async (data, orgid) => {
  try {
    const response = await axios.put(`${API_URL}/products/addtags/${orgid}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postItem = async (data) => {
  try {
    const response = await axios.post(`${ITEMS_URL}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getItems = async (orgid) => {
  try {
    const response = await axios.get(`${ITEMS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getItemById = async (id) => {
  try {
    const response = await axios.get(`${ITEMS_URL}/getitembyid/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function uploaddocs(formData, id) {
  try {
    const response = await axios.post(`${POST_DOCS_URL}${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocsinqf(formData) {
  try {
    const response = await axios.post(`${POST_DOCS_URL_QF}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getDocs(docid) {
  try {
    const response = await axios.get(`${API_URL}/products/documents/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deletedocs = async (id, docid) => {
  try {
    const response = await axios.delete(`${API_URL}/products/documents/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editdocs = async (data, id, docid) => {
  try {
    const response = await axios.put(`${API_URL}/products/documents/${id}/${docid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocservice(formData, id) {
  try {
    const response = await axios.post(`${POST_DOCS_SERVICE_URL}${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getDocsForService(docid) {
  try {
    const response = await axios.get(`${API_URL}/items/documents/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deletedocsInService = async (id, docid) => {
  try {
    const response = await axios.delete(`${API_URL}/items/documents/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editdocsInService = async (data, id, docid) => {
  try {
    const response = await axios.put(`${API_URL}/items/documents/${id}/${docid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getTransactionsByProductId(id) {
  try {
    const response = await axios.get(`${TRANSACTIONS_URL}/product/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getTransactionsByserviceId(id) {
  try {
    const response = await axios.get(`${TRANSACTIONS_URL}/service/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function editItem(id, data) {
  try {
    const response = await axios.put(`${ITEMS_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function addReason(id, data) {
  try {
    const response = await axios.put(`${INVENTORY_ADJUSTMENT_URL}/add-reason/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getReasons(id) {
  try {
    const response = await axios.get(`${INVENTORY_ADJUSTMENT_URL}/get-reasons/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function postAdjustment(data, status) {
  try {
    const response = await axios.post(`${INVENTORY_ADJUSTMENT_URL}/${status}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInventoryAdjustments(orgid) {
  try {
    const response = await axios.get(`${INVENTORY_ADJUSTMENT_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getInventoryAdjustmentById(id) {
  try {
    const response = await axios.get(`${INVENTORY_ADJUSTMENT_URL}/get-adjustment/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function SetInventoryAdjustmentStatus(id) {
  try {
    const response = await axios.put(`${INVENTORY_ADJUSTMENT_URL}/approve/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateInventoryFeature(companyid, data) {
  try {
    const response = await axios.put(`${COMPANY_URL}/inventory-feature/${companyid}`, data);
    return response.data;
  } catch (error) {
    console.error("Add Client error:", error);
    throw error;
  }
}