import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getInvoicebyids} from '../../Core/_requests'

function InvoiceArabic({companyLogo}) {
  const [vat, setVat] = useState(0)
  const id = useParams().id
  const [data, setdata] = useState<any>()

  useEffect(() => {
    const getinvoicedetails = async () => {
      await getInvoicebyids(id).then((res) => {
        console.log('data', res)
        setdata(res)
      })
    }

    getinvoicedetails()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    if (data?.tax) {
      const tax = (data?.subtotal * data?.tax) / 100
      setVat(tax)
    }
  }, [data])

  return (
    <>
      <div
        id='pdf-content'
        className='app-content flex-column-fluid print-content '
        style={{display: 'flex', flexDirection: 'row-reverse', textAlign: 'right'}}
      >
        <div id='kt_app_content_container' className='app-container container-xxl '>
          <div className='card '>
            <div className='card-body p-lg-15'>
              <div className='d-flex flex-column flex-xl-row'>
                <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
                  <div className='mt-n1'>
                    <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row mb-8'>
                      <h4 className='fw-bolder text-gray-800 fs-1  pb-7 '>فاتورة</h4>

                      <div className='text-sm-end'>
                        <a href='#' className='d-block mw-150px ms-sm-auto '>
                          {companyLogo ? (
                            <img
                              alt='Logo'
                              src={URL.createObjectURL(companyLogo)}
                              className='w-100'
                            />
                          ) : (
                            <img
                              alt='Upload Logo'
                              src={'/media/logo.png'}
                              className='w-100 bg-dark p-2 rounded-2'
                            />
                          )}
                        </a>
                      </div>
                    </div>

                    <div className='mt-n1'>
                      <div className='fw-bold fs-2 mb-8'>
                        {/*Dear*/} عزيزي{data?.customer?.primaryContact?.firstName}{' '}
                        {data?.customer?.primaryContact?.lastName} ,{' '}
                        <span className='fw-semibold text-muted fs-6'>
                          ({data?.customer?.emailAddress})
                        </span>
                        <br />
                        <span className='text fs-5'>هنا تفاصيل طلبك. نشكركم على الشراء.</span>
                        {/*Here are your order details. We thank you for your purchase.*/}
                      </div>
                      {/*end::Top*/}
                      {/*begin::Wrapper*/}
                      <div className='m-0'>
                        {/*begin::Label*/}
                        <div className='fw-bold fs-3 text-gray-800 mb-8'>فاتورة {data?.id}</div>
                        {/*Invoice}
                        {/*end::Label*/}
                        {/*begin::Row*/}
                        <div className='row g-5 mb-11'>
                          {/*end::Col*/}
                          <div className='col-sm-6'>
                            {/*end::Label*/}
                            <div className='fw-bold fs-7 text-gray-800 mb-1'>تاريخ الإصدار</div>
                            {/*Issue Date:*/}
                            {/*end::Label*/}
                            {/*end::Col*/}
                            <div className='fw-bold fs-6 text-gray-800'>
                              {formatCreatedAt(data?.createdAt)}
                            </div>
                            {/*end::Col*/}
                          </div>
                          {/*end::Col*/}
                          {/*end::Col*/}

                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Row*/}
                        <div className='row g-5 mb-12'>
                          {/*end::Col*/}
                          <div className='col-sm-6'>
                            {/*end::Label*/}
                            <div className='fw-bold fs-5 text-dark mb-1'>عنوان وصول الفواتير:</div>
                            {/*Billing Address:*/}
                            {/*end::Label*/}
                            {/*end::Text*/}
                            {/*end::Text*/}
                            {/*end::Description*/}
                            <div className='fw-semibold fs-7 text-gray-600'>
                              {data?.customer?.billingAddress?.addressLine1}{' '}
                              {data?.customer?.billingAddress?.addressLine2}
                              <br />
                              {data?.customer?.billingAddress?.city}{' '}
                              {data?.customer?.billingAddress?.postalCode}
                              <br />
                              {data?.customer?.billingAddress?.country}
                            </div>
                            {/*end::Description*/}
                          </div>
                          {/*end::Col*/}
                          {/*end::Col*/}
                          <div className='col-sm-6 '>
                            {/*end::Label*/}
                            <div className='fw-bold fs-5 text-dark mb-1'>عنوان الشحن:</div>
                            {/*Shipping Address:*/}
                            {/*end::Label*/}
                            {/*end::Text*/}
                            {/*end::Text*/}
                            {/*end::Description*/}
                            <div className='fw-semibold fs-7 text-gray-600'>
                              {data?.customer?.shippingAddress?.addressLine1}{' '}
                              {data?.customer?.shippingAddress.addressLine2}
                              <br />
                              {data?.customer?.shippingAddress?.city}{' '}
                              {data?.customer?.shippingAddress?.postalCode}
                              <br />
                              {data?.customer?.shippingAddress?.country}
                            </div>
                            {/*end::Description*/}
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Content*/}
                        <div className='flex-grow-1'>
                          {/*begin::Table*/}
                          <div className='table-responsive border-bottom mb-9'>
                            <table className='table mb-3'>
                              <thead>
                                <tr className='border-bottom fs-6 fw-bold '>
                                  <th className='min-w-175px text-start pb-2 '>وصف</th>{' '}
                                  {/*Description*/}
                                  <th className='min-w-70px text-center pb-2'>كمية</th>
                                  {/*Quantity*/}
                                  <th className='min-w-80px text-center pb-2'>معدل</th>
                                  {/*Rate*/}
                                  <th className='min-w-80px text-center pb-2'>تخفيض</th>
                                  {/*Discount*/}
                                  <th className='min-w-80px text-center pb-2'>كمية</th>
                                  {/*Amount*/}
                                </tr>
                              </thead>
                              <tbody>
                                {data?.items?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className='fw-bold text-gray-700 fs-5 text-center'
                                  >
                                    <td className='d-flex align-items-center text-start pt-6'>
                                      <i className='fa fa-genderless text-danger fs-2 me-2' />
                                      {item?.productName}
                                      {/* {console.log("item",item.itemId.productName)} */}
                                    </td>
                                    <td className='pt-6'>{item.quantity}</td>
                                    {/* {console.log("quantity",item.quantity)} */}
                                    <td className='pt-6'>
                                      {Number(item?.price).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data?.customer?.currency} */}
                                    </td>
                                    {/* {console.log("price",item.price)} */}
                                    <td className='pt-6'>{item.discount}</td>
                                    {/* {console.log("discount",item.discount)} */}
                                    <td className='pt-6 text-gray-900 fw-bolder '>
                                      {Number(item?.amount).toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}{' '}
                                      {/* {data?.customer?.currency} */}
                                    </td>
                                    {/* {console.log("amount",item.amount)} */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {/*end::Table*/}
                          {/*begin::Container*/}
                          <div className='d-flex justify-content-end'>
                            {/*begin::Section*/}
                            <div className='mw-400px'>
                              {/*begin::Item*/}
                              <div className='d-flex flex-stack mb-3'>
                                {/*begin::Accountname*/}
                                <div className='fw-bold pe-10 text-gray-600 fs-7'>
                                  {/* Subtotal: */}
                                  المجموع الفرعي:
                                </div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className='text-end fw-bold fs-6 text-gray-800'>
                                  {data?.subtotal.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                  {data?.customer?.currency}
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className='d-flex flex-stack mb-3'>
                                {/*begin::Accountname*/}
                                <div className='fw-semibold pe-10 text-gray-600 fs-7'>
                                  {' '}
                                  {data?.tax} ضريبة
                                </div>
                                {/*end::Accountname*/}
                                {/*begin::Label*/}
                                <div className='text-end fw-bold fs-6 text-gray-800'>{vat}</div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className='d-flex flex-stack mb-3'>
                                {/*begin::Accountnumber*/}
                                <div className='fw-bold pe-10 text-gray-600 fs-7'>
                                  {/*Subtotal + Tax*/}
                                  المجموع الفرعي + الضريبة
                                </div>
                                {/*end::Accountnumber*/}
                                {/*begin::Number*/}
                                <div className='text-end fw-bold fs-6 text-gray-800'>
                                  {data?.total.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                  {data?.customer?.currency}
                                </div>
                                {/*end::Number*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className='d-flex flex-stack'>
                                {/*begin::Code*/}
                                <div className='fw-bold pe-10 text-gray-600 fs-7'>المجموع</div>
                                {/*Total*/}
                                {/*end::Code*/}
                                {/*begin::Label*/}
                                <div className='text-end fw-bold fs-6 text-gray-800'>
                                  {data?.total.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}{' '}
                                  {data?.customer?.currency}
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Item*/}
                            </div>
                            {/*end::Section*/}
                          </div>
                          {/*end::Container*/}
                        </div>
                        {/*end::Content*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>

                    {/* end::Wrapper */}
                  </div>
                  {/* end::Invoice 2 content */}
                </div>
                {/* end::Content */}
                {/* begin::Sidebar */}
                <div className='m-0 '>
                  {/* begin::Invoice 2 sidebar */}
                  {/* d-print-none */}
                  <div className=' border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten'>
                    {/* begin::Labels */}
                    <div className='mb-4'>
                      <span className='badge badge-light-danger me-2'>
                        {data?.status
                          ? data?.status.charAt(0).toUpperCase() +
                            data?.status.slice(1).toLowerCase()
                          : ''}
                      </span>
                      {data?.paid ? (
                        <span className='badge badge-light-success'>
                          يدفع بواسطة:{data?.paymentMethod}
                        </span>
                      ) : (
                        <span className='badge badge-light-warning'>في انتظار الدفع</span>
                      )}
                    </div>
                    {/* end::Labels */}
                    {/* begin::Title */}
                    <div className='d-flex flex-xl-column flex-sm-row flex-column justify-content-between'>
                      <div className='mb-20'>
                        <h6 className='mb-8 fw-bolder text-gray-600 text-hover-primary'>
                          {/* Terms and Conditions */}
                          الأحكام والشروط:
                        </h6>
                        {/* end::Title */}
                        {/* begin::Item */}
                        <div className='mb-6'>
                          {/* <div className='fw-semibold text-gray-600 fs-7'>Paypal:</div>
                          <div className='fw-bold text-gray-800 fs-6'>codelabpay@codelab.co</div> */}
                          <div>
                            {/* <ul className='fw-bold text-gray-800 fs-6'>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Payment is due within 15 days from<br /> the date of the invoice.</li>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Any questions about this invoice <br />should be directed to: XYZ </li>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Thank you for your business.</li>
                            </ul> */}
                            {data?.termsNConditions}
                          </div>
                        </div>

                        <h6 className='mb-4 fw-bolder text-gray-600 text-hover-primary'>ملحوظة</h6>
                        <div className='fw-bold text-gray-800 fs-6'>
                          {/* <div className='fw-semibold text-gray-600 fs-7'>Paypal:</div>
                          <div className='fw-bold text-gray-800 fs-6'>codelabpay@codelab.co</div> */}
                          <div>
                            {/* <ul className='fw-bold text-gray-800 fs-6'>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Payment is due within 15 days from<br /> the date of the invoice.</li>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Any questions about this invoice <br />should be directed to: XYZ </li>
                              <li className='mb-6 pb-4 border-dashed border-bottom border-gray-400'>Thank you for your business.</li>
                            </ul> */}
                            {data?.notes}
                          </div>
                        </div>
                      </div>
                      {data?.paymentDetails && (
                        <div className='mb-20 '>
                          <h6 className='mb-8 fw-bolder text-gray-600 text-hover-primary'>
                            {/* PAYMENT DETAILS */}
                            بيانات الدفع
                          </h6>
                          {/* end::Title */}
                          {/* begin::Item */}
                          <div className='mb-6'>
                            <div className='fw-bold text-gray-600 fs-5'>باي بال:</div>
                            {/*Paypal:*/}
                            <div className='fw-bold text-gray-800 fs-6'>codelabpay@codelab.co</div>
                          </div>
                          {/* end::Item */}
                          {/* begin::Item */}
                          <div className='mb-6 '>
                            <div className='fw-bold text-gray-600 fs-5'>حساب:</div>
                            {/*Account:*/}
                            <div className='fw-bold text-gray-800 fs-6'>
                              Nl24IBAN34553477847370033
                              <br />
                              AMB NLANBZTC
                            </div>
                          </div>
                        </div>
                      )}
                      {/* end::Item */}
                      {/* begin::Item */}
                      <div className=''>
                        <h6 className='mb-8 fw-bolder text-gray-600 text-hover-primary'>
                          {/* ORDER RECEIVED BY : */}
                          تم استلام الطلب بواسطة:
                        </h6>
                        {/* end::Title */}
                        {/* begin::Item */}
                        <div className='mb-6'>
                          <div className='fw-bold text-gray-600 fs-5'>اسم الموظف:</div>
                          {/*Employee Name:*/}
                          <div className='fw-bold text-gray-800 fs-6'>
                            {data?.employee?.firstName} {data?.employee?.lastName}
                          </div>
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='mb-6'>
                          <div className='fw-bold text-gray-600 fs-5'>رقم الهاتف المحمول:</div>
                          {/*Mobile Number:*/}
                          <div className='fw-bold text-gray-800 fs-6'>
                            {data?.employee?.phoneNo}
                          </div>
                        </div>
                        <div className='mb-6'>
                          <div className='fw-bold text-gray-600 fs-5'>عنوان البريد الإلكتروني:</div>
                          {/*Email Address:*/}
                          <div className='fw-bold text-gray-800 fs-6'>{data?.employee?.email}</div>
                        </div>
                      </div>
                    </div>
                    {/* end::Item */}
                    {/* begin::Item */}

                    {/* end::Item */}
                    {/* begin::Title */}

                    {/* end::Item */}
                  </div>
                  {/* end::Invoice 2 sidebar */}
                </div>
                {/* end::Sidebar */}
              </div>
              {/* end::Layout */}
            </div>
            {/* end::Body */}
            {/* <div className='card-footer text-center'>
              {data?.notes} ملحوظة:
            </div> */}
          </div>
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default InvoiceArabic
