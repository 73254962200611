import {createSlice} from '@reduxjs/toolkit'

const reportSubmissionSlice = createSlice({
  name: 'reportSubmission',
  initialState: {
    reportSubmissionData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setReportSubmissionData(state, action) {
      state.reportSubmissionData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setReportSubmissionData, setLoading, setError} = reportSubmissionSlice.actions
export default reportSubmissionSlice.reducer
