import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../../modules/auth'
import {getRentalCategories, postInspectionForm} from '../../core/_requests'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

const FormBuilder = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    color: '#ffffff',
    category: '',
    enableLocation: false,
  })
  const [loading, setLoading] = useState(false)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [questions, setQuestions] = useState([
    {
      questionText: '',
      questionType: 'text',
      //   question type can be [
      //     "text",
      //     "date",
      //     "number",
      //     "dropdown",
      //     "meter",
      //     "yesno",
      //     "photo",
      //     "signature",
      //     "checkbox",
      //     "multiplechoice",
      //   ]
      options: [''],
      isRequired: false,
      isCommentRequired: false,
      isPhotoRequired: false,
      shortDescription: '',
      min: '',
      max: '',
    },
  ])

  const fetchCategories = async () => {
    const res = await getRentalCategories(currentUser?.organization)
    const data = res.map((item) => {
      return {value: item._id, label: item.categoryName}
    })
    setCategoryOptions(data)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const addQuestion = () => {
    setQuestions((prev) => [
      ...prev,
      {
        questionText: '',
        questionType: 'text',
        options: [''],
        isRequired: false,
        isCommentRequired: false,
        isPhotoRequired: false,
        shortDescription: '',
        min: '',
        max: '',
      },
    ])
  }

  const removeQuestion = (index) => {
    if (questions.length === 1) return
    setQuestions((prev) => prev.filter((_, i) => i !== index))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formData.title === '') {
      alert('Please enter title')
      return
    }
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].questionText === '') {
        alert('Please enter question text')
        return
      }
    }
    setLoading(true)
    const data = {
      ...formData,
      questions,
      company: currentUser?.company,
      organization: currentUser?.organization,
    }
    console.log('data', data)
    const res = await postInspectionForm(data)
    if (res) {
      navigate('/inspection/forms')
    }
  }

  console.log('questions', questions)
  return (
    <div className='container'>
      <div className='d-flex flex-column gap-4'>
        <div className='card'>
          <div className='card-header'>
            <h4 className='card-title'>Details</h4>
          </div>
          <div className='card-body'>
            <div className='form-group mb-3'>
              <label className='required'>Title</label>
              <input
                type='text'
                className='form-control w-75'
                placeholder='Enter title'
                value={formData.title}
                onChange={(e) => setFormData({...formData, title: e.target.value})}
              />
            </div>
            <div className='form-group mb-3'>
              <label>Description</label>
              <textarea
                className='form-control w-75'
                placeholder='Enter description'
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
              />
            </div>
            <div className='form-group'>
              <label className='d-block'>Color</label>
              <input
                type='color'
                className='rounded-circle w-25px p-0'
                value={formData.color}
                onChange={(e) => setFormData({...formData, color: e.target.value})}
              />
              <input
                type='color'
                className='ms-5 rounded w-40px p-0'
                value={formData.color}
                disabled
              />
            </div>
            
            <label className="ms-1 text-muted mb-3">Choose color for self identification</label>

            <div className='form-group mb-3'>
              <label>Category</label>
              <select
                className='form-control w-75'
                value={formData.category}
                onChange={(e) => setFormData({...formData, category: e.target.value})}
              >
                <option value=''>Select Category</option>
                {categoryOptions.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='form-group mb-3'>
              <div className='form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='enableLocation'
                  checked={formData.enableLocation}
                  onChange={(e) => setFormData({...formData, enableLocation: e.target.checked})}
                />
                <label className='' htmlFor='enableLocation'>
                  Enable Location Exception Tracking
                </label>
              </div>
            </div>
          </div>
        </div>
        {questions?.map((question, index) => (
          <div className='card'>
            <div className='card-body'>
              <div className='d-flex justify-content-between'>
                <h4 className='card-title'>Inspection item {index + 1}</h4>
                <div>
                  <button className='btn btn-sm btn-primary me-2' onClick={addQuestion}>
                    <KTIcon iconName='plus' />
                  </button>
                  <button className='btn btn-sm btn-danger' onClick={() => removeQuestion(index)}>
                    <KTIcon iconName='trash' />
                  </button>
                </div>
              </div>
              <div className='form-group mb-3'>
                <label className='required'>Question</label>
                <input
                  type='text'
                  className='form-control w-75'
                  placeholder='Enter question'
                  value={question.questionText}
                  onChange={(e) =>
                    setQuestions((prev) =>
                      prev.map((item, i) =>
                        i === index ? {...item, questionText: e.target.value} : item
                      )
                    )
                  }
                />
              </div>
              <div className='form-group mb-3'>
                <label>Short Description</label>
                <input
                  type='text'
                  className='form-control w-75'
                  placeholder='Enter short description'
                  value={question.shortDescription}
                  onChange={(e) =>
                    setQuestions((prev) =>
                      prev.map((item, i) =>
                        i === index ? {...item, shortDescription: e.target.value} : item
                      )
                    )
                  }
                />
              </div>
              <div className='form-group mb-3'>
                <label className='required'>Question Type</label>
                <select
                  className='form-control w-75'
                  value={question.questionType}
                  onChange={(e) =>
                    setQuestions((prev) =>
                      prev.map((item, i) =>
                        i === index ? {...item, questionType: e.target.value} : item
                      )
                    )
                  }
                >
                  <option value='text'>Text</option>
                  <option value='date'>Date</option>
                  <option value='number'>Number</option>
                  <option value='dropdown'>Dropdown</option>
                  <option value='meter'>Meter</option>
                  <option value='yesno'>Pass/Fail</option>
                  <option value='photo'>Photo</option>
                  <option value='signature'>Signature</option>
                  <option value='checkbox'>Checkbox</option>
                  <option value='multiplechoice'>Multiple Choice</option>
                </select>
              </div>
              {(question.questionType === 'dropdown' ||
                question.questionType === 'checkbox' ||
                question.questionType === 'multiplechoice') && (
                <div className='form-group mb-3'>
                  <label>Options</label>
                  <div className='d-flex gap-2 overflow-x-auto'>
                    {question.options.map((option, i) => (
                      <div key={i} className='d-flex gap-2'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter option'
                          value={option}
                          onChange={(e) =>
                            setQuestions((prev) =>
                              prev.map((item, j) =>
                                j === index
                                  ? {
                                      ...item,
                                      options: item.options.map((o, k) =>
                                        k === i ? e.target.value : o
                                      ),
                                    }
                                  : item
                              )
                            )
                          }
                        />
                        <button
                          className='btn btn-sm btn-danger'
                          onClick={() =>
                            setQuestions((prev) =>
                              prev.map((item, j) =>
                                j === index
                                  ? {...item, options: item.options.filter((_, k) => k !== i)}
                                  : item
                              )
                            )
                          }
                        >
                          <KTIcon iconName='trash' />
                        </button>
                      </div>
                    ))}
                    <button
                      className='btn btn-sm btn-primary'
                      onClick={() =>
                        setQuestions((prev) =>
                          prev.map((item, i) =>
                            i === index ? {...item, options: [...item.options, '']} : item
                          )
                        )
                      }
                    >
                      <KTIcon iconName='plus' />
                    </button>
                  </div>
                </div>
              )}
              {question.questionType === 'number' && (
                // input for min and max
                <div className='row gx-10 mb-3'>
                  <div className='col-6'>
                    <label>Min</label>
                    <input
                      type='number'
                      className='form-control'
                      value={question.min}
                      min={0}
                      onChange={(e) =>
                        setQuestions((prev) =>
                          prev.map((item, i) =>
                            i === index ? {...item, min: e.target.value} : item
                          )
                        )
                      }
                    />
                  </div>
                  <div className='col-6'>
                    <label>Max</label>
                    <input
                      type='number'
                      className='form-control'
                      value={question.max}
                      min={0}
                      onChange={(e) =>
                        setQuestions((prev) =>
                          prev.map((item, i) =>
                            i === index ? {...item, max: e.target.value} : item
                          )
                        )
                      }
                    />
                  </div>
                </div>
              )}
              <div className='form-group mb-3'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id={`isRequired-${index}`}
                    checked={question.isRequired}
                    onChange={(e) =>
                      setQuestions((prev) =>
                        prev.map((item, i) =>
                          i === index ? {...item, isRequired: e.target.checked} : item
                        )
                      )
                    }
                  />
                  <label className='' htmlFor={`isRequired-${index}`}>
                    Required
                  </label>
                </div>
              </div>
              <div className='form-group mb-3'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id={`isCommentRequired-${index}`}
                    checked={question.isCommentRequired}
                    onChange={(e) =>
                      setQuestions((prev) =>
                        prev.map((item, i) =>
                          i === index ? {...item, isCommentRequired: e.target.checked} : item
                        )
                      )
                    }
                  />
                  <label className='' htmlFor={`isCommentRequired-${index}`}>
                    Comment Required
                  </label>
                </div>
              </div>
              <div className='form-group mb-3'>
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id={`isPhotoRequired-${index}`}
                    checked={question.isPhotoRequired}
                    onChange={(e) =>
                      setQuestions((prev) =>
                        prev.map((item, i) =>
                          i === index ? {...item, isPhotoRequired: e.target.checked} : item
                        )
                      )
                    }
                  />
                  <label className='' htmlFor={`isPhotoRequired-${index}`}>
                    Photo Required
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* submit button */}
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-primary uppercase'
            type='button'
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormBuilder
