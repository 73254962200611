import axios, {Axios, AxiosResponse} from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const PRODUCT_URL = `${API_URL}/products`
const PARTS_URL = `${API_URL}/parts`
const WORK_ORDER_URL = `${API_URL}/work-order`
const POST_DOC = `${API_URL}/quantumflow/uploaddocs`
const FILES_URL = `${API_URL}/files`
const INSPECTION_FORM_URL = `${API_URL}/inspection-form`
const INSPECTION_RECORD_URL = `${API_URL}/inspection-record`
const ISSUES_URL = `${API_URL}/maintenance-issue`
const SERVICE_RECORD_URL = `${API_URL}/service-record`
const SYSTEM_CODE_URL = `${API_URL}/system-code`
const SERVICE_TASK_URL = `${API_URL}/service-task`
const SERVICE_REMINDER_URL = `${API_URL}/service-reminder`

export async function getRentalItems(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${WORK_ORDER_URL}/getrentalitems/${orgid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postWorkOrder(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${WORK_ORDER_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function editWorkOrder(data, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${WORK_ORDER_URL}/${id}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getWorkOrders(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${WORK_ORDER_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getWorkOrderById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${WORK_ORDER_URL}/getworkorderbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getVehiclesList(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PRODUCT_URL}/vehicle/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEquipmentsList(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PRODUCT_URL}/equipment/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getPartsList(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARTS_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getPartsById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${PARTS_URL}/getpartbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function deletePart(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${PARTS_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postParts(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${PARTS_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocs(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${POST_DOC}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getDocs(id, orgid): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${FILES_URL}/${id}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postDoc(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${FILES_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function editDoc(data, id, docid, orgid): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${FILES_URL}/${orgid}/${id}/${docid}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getMaintenanceDocs(orgid): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${FILES_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getRentalCategories(orgid): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${API_URL}/category/products/${orgid}/rentals`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postInspectionForm(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${INSPECTION_FORM_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getInspectionForms(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INSPECTION_FORM_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getInspectionFormById(formid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${INSPECTION_FORM_URL}/formbyid/${formid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postInspectionRecord(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${INSPECTION_RECORD_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getInspectionRecords(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${INSPECTION_RECORD_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getInspectionRecordById(formid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${INSPECTION_RECORD_URL}/recordbyid/${formid}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postIssue(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${ISSUES_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getIssues(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ISSUES_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getIssueById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ISSUES_URL}/issuebyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getItemFailure(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ISSUES_URL}/item-failure/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getIssuesByProductId(productId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ISSUES_URL}/product/${productId}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postServiceRecord(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SERVICE_RECORD_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getServiceRecords(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_RECORD_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getServiceRecordById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_RECORD_URL}/servicebyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// post category code
export async function postCategoryCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/category`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// get category code
export async function getCategoryCodes(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/category/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// post system code
export async function postSystemCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/system`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// get system code
export async function getSystemCodes(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/system/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// post assembly code
export async function postAssemblyCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/assembly`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// get assembly code
export async function getAssemblyCodes(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/assembly/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// post component code
export async function postComponentCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/component`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// get component code
export async function getComponentCodes(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/component/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postRepairCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/repair`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getRepairCodes(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/repair/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postRepairPriorityCode(data): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SYSTEM_CODE_URL}/priority`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getRepairPriorityCodes(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SYSTEM_CODE_URL}/priority/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postServiceTask(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SERVICE_TASK_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getServiceTasks(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_TASK_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getServiceSubTasks(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_TASK_URL}/subtasks/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postServiceReminder(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SERVICE_REMINDER_URL}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getServiceReminders(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_REMINDER_URL}/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function postVehicleReminder(data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${SERVICE_REMINDER_URL}/vehicle`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getVehicleReminders(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SERVICE_REMINDER_URL}/vehicle/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
