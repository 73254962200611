import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AddCategory from './Pages/AddCategory'
import CategoryListing from './Pages/CategoryListing'
import EditCategory from './Pages/EditCategory'
import ViewCategory from './Pages/ViewCategory'
import ProductCatalog from './Pages/ProductCatalog'

export default function CategoryPage() {
  return (
    <Routes>
      <Route path='/' element={<CategoryListing />} />
      <Route path='/add-category' element={<AddCategory />} />
      <Route path='/edit-category/:id' element={<EditCategory />} />
      <Route path='/view-category/:id' element={<ViewCategory />} />
      <Route path='/product-catalog/:id' element={<ProductCatalog />} />
    </Routes>
  )
}
