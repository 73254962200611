import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {SearchFilter} from '../core/_model'


type DeptOption = {
  value: string
  label: string
}
type Props = {
  searchFilters: SearchFilter
  setSearchFilter: (updateValues: SearchFilter) => void
  setStatusFilter: (isActive: boolean) => void
  getDocsList: () => void
  handlereset: () => void
  showDeptFilter?: boolean
  showContractFilter?: boolean
  deptOptions?: DeptOption[]
}

const Filter: React.FC<Props> = ({
  handlereset,
  setSearchFilter,
  searchFilters,
  setStatusFilter,
  getDocsList,
  showDeptFilter = false,
  showContractFilter = false,
  deptOptions = [],

}) => {
  const [status, setstatus] = useState('active')
  const [dept, setDept] = useState('')
  const [contract, setContract] = useState('')


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    setSearchFilter({
      ...searchFilters,
      filter: {
        ...searchFilters.filter,
        isActive: status === 'active',
        ...(dept && { dept }),
        ...(contract && { contract }),
       
      },
    })
  }, [status, setSearchFilter, searchFilters, dept, contract])

  const handleStatusChange = (e) => {
    const isActive = e.target.value === 'active'
    setstatus(isActive ? 'active' : 'inactive')
  }

  const handleDepartmentChange = (e) => {
    setDept(e.target.value)
  }

  return (
    <>
      <div
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleStatusChange}
              // value={searchFilters.filter.isActive ? 'active' : 'inactive'}
              value={status}
            >
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>

          {showDeptFilter && ( // Render status filter only if showStatusFilter is true
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Department:</label>
              <select
                className='form-select form-select-solid fw-bolder w-75'
                onChange={ handleDepartmentChange}
                value={dept}
              >
                <option value=''>All</option>
                {deptOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )
          }
          {showContractFilter && (
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Contract:</label>
              <select
                className='form-select form-select-solid fw-bolder w-75'
                onChange={(e)=>setContract(e.target.value)}
                value={contract}
              >
                <option value=''>All</option>
                <option value='temporary'>Temporary</option>
                <option value='permanent'>Permanent</option>
                <option value='freelancer'>Freelancer</option>
              </select>
            </div>
          )}

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handlereset}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
              onClick={getDocsList}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter
