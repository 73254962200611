import React, {FC, useEffect, useState} from 'react'
import {useParams, Link} from 'react-router-dom'
import {getOrdersByProjectId, getPurchaseOrdersByProjectId} from '../core/_requests'

interface props  {
  isSalesOrder: boolean
}

const OrderTable: FC<props>= ({isSalesOrder}) => {
  const params = useParams().id
  const [sales, setSales] = useState(isSalesOrder)
  const [data, setData] = useState([])

  const fetchSalesOrderManagement = async () => {
    const res = await getOrdersByProjectId(params)
    console.log(res)
    setData(res)
  }

  const fetchPurchaseOrderManagement = async () => {
    const res = await getPurchaseOrdersByProjectId(params)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    if (sales) fetchSalesOrderManagement()
    else fetchPurchaseOrderManagement()
  }, [params, sales])

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center justify-content-end gap-4'>
          <button
            className={`btn btn-outline-primary me-2 ${sales ? 'active' : ''}`}
            onClick={() => setSales(true)}
          >
            Sales Order Management
          </button>
          <button
            className={`btn btn-outline-primary ${!sales ? 'active' : ''}`}
            onClick={() => setSales(false)}
          >
            Purchase Order Management
          </button>
        </div>
        {sales ? (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-100px rounded-start'>ID</th>
                    <th className='min-w-200px'>Name</th>
                    <th className='min-w-200px'>Employee</th>
                    <th className='min-w-200px'>Customer</th>

                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data?.map((item: any) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.id}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.orderName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.employee?.firstName + ' ' + item?.employee?.lastName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.customer?.displayName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.project?.projectName}
                          </a>
                        </Link>
                      </td>

                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            <span
                              className={`badge  fs-7 fw-semibold ${
                                item?.status === 'open'
                                  ? 'badge-light-primary'
                                  : 'badge-light-success'
                              }`}
                            >
                              {item?.status}
                            </span>
                          </a>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-100px rounded-start'>ID</th>
                    <th className='min-w-200px'>Name</th>
                    <th className='min-w-200px'>Employee</th>
                    <th className='min-w-200px'>Vendor</th>
                    <th className='min-w-200px'>Project</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data?.map((item: any) => (
                    <tr key={item?._id?.toString()}>
                      <td>
                        <Link to={`/procurement/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.id}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/procurement/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.orderName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/procurement/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.employee?.firstName + ' ' + item?.employee?.lastName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/procurement/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.vendor?.displayName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/sales/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            {item?.project?.projectName}
                          </a>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/procurement/order-management/orderdetails/${item._id}`}>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                          >
                            <span
                              className={`badge  fs-7 fw-semibold ${
                                item?.status === 'open'
                                  ? 'badge-light-primary'
                                  : 'badge-light-success'
                              }`}
                            >
                              {' '}
                              {item?.status}
                            </span>
                          </a>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderTable
