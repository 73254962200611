import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getServiceTasks} from '../../core/_requests'
import Swal from 'sweetalert2'
import {useSelector, useDispatch} from 'react-redux'
import {setServiceTaskData} from '../../../../redux/maintenance/service/serviceTask/serviceTaskSlice'

const ServiceTask = () => {
  const {currentUser} = useAuth()
  // const [taskList, setTaskList] = useState([])
  const dispatch = useDispatch()
  const taskList = useSelector((state: any) => state.maintenance.serviceTask.serviceTaskData)

  const fetchTaskList = async () => {
    const res = await getServiceTasks(currentUser?.organization)
    console.log('taskList', res)
    // setTaskList(res)
    dispatch(setServiceTaskData(res))
  }

  useEffect(() => {
    fetchTaskList()
  }, [])

  const handleTaskClick = (task) => {
    Swal.fire({
      title: task?.name,
      html: `
            <p><strong>Description:</strong> ${task?.description}</p>
            <p><strong>Subtasks:</strong> ${
              task?.subTask?.map((item) => `<span class="badge bg-primary">${item?.name}</span>`) ||
              '-'
            }</p>
            <p><strong>Reason for Repair Code:</strong> ${task?.repairCode}</p>
            <p><strong>Category Code:</strong> ${task?.categoryCode}</p>
            <p><strong>System Code:</strong> ${task?.systemCode}</p>
            <p><strong>Assembly Code:</strong> ${task?.assemblyCode}</p>
            <p><strong>Component Code:</strong> ${task?.componentCode}</p>
        `,
    })
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex justify-between align-items-center'>
          <h4 className='card-title'>Service Task</h4>
          <div>
            <Link to='add' className='btn btn-primary'>
              <KTIcon iconName='plus' className='me-2' />
              Add Service Task
            </Link>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-bordered'>
              <thead>
                <tr className='rounded bg-gray-200'>
                  <th className='ps-2 min-w-150px'>Name</th>
                  <th className='min-w-150px'>Service Entries</th>
                  <th className='min-w-150px'>Service Reminders</th>
                  <th className='min-w-150px'>Work Orders</th>
                  <th className='min-w-200px'>Reason for Repair Code</th>
                  <th className='min-w-150px'>Category Code</th>
                  <th className='min-w-150px'>System Code</th>
                  <th className='min-w-150px'>Assembly Code</th>
                  <th className='min-w-150px'>Component Code</th>
                </tr>
              </thead>
              <tbody>
                {taskList?.map((task, index) => (
                  <tr key={index}>
                    <td
                      className='ps-2 cursor-pointer text-primary'
                      onClick={(e) => {
                        handleTaskClick(task)
                      }}
                    >
                      {task?.name}
                    </td>
                    <td>{task?.serviceRecord?.length || 0}</td>
                    <td>{task?.serviceReminder?.length || 0}</td>
                    <td>{task?.workOrder?.length || 0}</td>
                    <td>{task?.repairCode}</td>
                    <td>{task?.categoryCode}</td>
                    <td>{task?.systemCode}</td>
                    <td>{task?.assemblyCode}</td>
                    <td>{task?.componentCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceTask
