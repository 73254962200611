import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getEmployeeAttendanceById, getEmployeeWebAttendanceById} from '../core/_requests'
import ProgressBar from './ProgressBarAttendance'
import {KTIcon} from '../../../../_metronic/helpers'
import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api'
// import loadScript from 'load-script'
import {useAuth} from '../../../modules/auth'

const TimeTracker = () => {
  const {id} = useParams()

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  })

  const {currentUser} = useAuth()
  const [timeTracker, setTimeTracker] = useState([])
  const [employee, setEmployee] = useState<any>({})

  const [showModal, setShowModal] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [markerLabel, setMarkerLabel] = useState([])
  const [center, setCenter] = useState({
    lat: 25.276987,
    lng: 55.296249,
  })
  // const [scriptLoaded, setScriptLoaded] = useState(false)

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  }

  // useEffect(() => {
  //   const loadGoogleScript = async () => {
  //     try {
  //       await new Promise<void>((resolve, reject) => {
  //         loadScript(
  //           `https://maps.googleapis.com/maps/api/js?key=AIzaSyA7grj1MqTFqXCMFr_iRHTrAtZSCU3opDQ&libraries=places`,
  //           (error) => {
  //             if (error) {
  //               reject(error)
  //             } else {
  //               resolve()
  //             }
  //           }
  //         )
  //       })
  //       setScriptLoaded(true)
  //     } catch (error) {
  //       console.error('Error loading Google Maps script', error)
  //     }
  //   }

  //   if (currentUser?.attendanceMethod === 'web') {
  //     loadGoogleScript()
  //   }
  // }, [currentUser?.attendanceMethod])

  const fetchTimeSheet = async () => {
    try {
      if (currentUser?.attendanceMethod === 'mobile') {
        const res = await getEmployeeAttendanceById(id)
        console.log(res)
        setTimeTracker(res?.attendance || [])
        setEmployee(res?.employeeId || {})
      } else if (currentUser?.attendanceMethod === 'web') {
        const res = await getEmployeeWebAttendanceById(id)
        console.log(res)
        setTimeTracker(res || [])
        setEmployee(res[0]?.employeeId || {})
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (id !== undefined) fetchTimeSheet()
  }, [id, currentUser?.attendanceMethod])

  const calculateWorkingHours = (inTime, outTime, breakTime, breakOutTime) => {
    // 2024-04-16T15:49:27.035+00:00 time format is this
    if (!inTime || !outTime || !breakTime || !breakOutTime) return '-'
    const inTimeDate = new Date(inTime)
    const outTimeDate = new Date(outTime)
    if (breakTime && breakOutTime) {
      const breakInTimeDate = new Date(breakTime)
      const breakOutTimeDate = new Date(breakOutTime)
      let diff = outTimeDate.getTime() - inTimeDate.getTime()
      diff -= breakOutTimeDate.getTime() - breakInTimeDate.getTime()
      const hours = Math.floor(diff / 1000 / 60 / 60)
      const minutes = Math.floor((diff / 1000 / 60) % 60)
      return hours + ' hours ' + minutes + ' minutes'
    }
    const diff = outTimeDate.getTime() - inTimeDate.getTime()
    const hours = Math.floor(diff / 1000 / 60 / 60)
    const minutes = Math.floor((diff / 1000 / 60) % 60)
    return hours + ' hours ' + minutes + ' minutes'
  }

  const formatTime = (date) => {
    if (!date) return '-'
    const d = new Date(date)
    // return 12/4/2024, 4:15:15 PM
    return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()} ${d.getHours() >= 12 ? 'PM' : 'AM'}`
  }
  const formatDate = (date) => {
    const d = new Date(date)
    // return 12/4/2024, 4:15:15 PM
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
  }

  const TotalHours = (inTime, outTime) => {
    const inTimeDate = new Date(inTime)
    const outTimeDate = new Date(outTime)

    // Handling the case where outTime is at 00:00 hours
    if (outTime === '00:00') {
      // Increment outTimeDate by a day
      outTimeDate.setDate(outTimeDate.getDate() + 1)
    }

    const diff = outTimeDate.getTime() - inTimeDate.getTime()
    const totalHours = diff / (1000 * 60 * 60) // Convert milliseconds to hours
    const hours = Math.floor(totalHours)
    const minutes = Math.floor((totalHours - hours) * 60)

    // Formatting the result
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    return formattedHours + ':' + formattedMinutes
  }

  return (
    <>
      <div className={`card `}>
        {/* begin::Header */}
        <div className='card-header d-flex align-items-center'>
          <h4>
            Time Tracker{' '}
            {(employee?.firstName !== undefined ? 'of ' : '') +
              (employee?.firstName || '') +
              ' ' +
              (employee?.lastName || '')}
          </h4>
        </div>
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gy-6'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-60px rounded-start'>Date</th>
                  <th className='min-w-60px text-center'>Check In </th>
                  <th className='min-w-400px px-8 '></th>
                  <th className='min-w-60px text-center'>Check Out</th>
                  <th className='min-w-60px text-center'>Total Hours</th>
                  <th className='min-w-60px text-end rounded-end'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}

              <tbody className=''>
                {timeTracker?.map((item, index) => (
                  <tr key={index} className='py-5'>
                    <td>
                      <span className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6 '>
                        {formatDate(item?.checkIn?.date)}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'>
                        {formatTime(item?.checkIn?.date)}
                      </span>
                    </td>
                    <td>
                      <div className='pe-10'>
                        <ProgressBar
                          schedule={{
                            dayStart: 9,
                            dayEnd: 21,
                            workStart: new Date(item?.checkIn?.date).getHours(),
                            workEnd: new Date(item?.checkOut?.date).getHours(),
                            breaks: item?.breakIn?.date
                              ? [
                                  {
                                    start: new Date(item?.breakIn?.date).getHours(),
                                    end: new Date(item?.breakOut?.date).getHours(),
                                    className: 'bg-danger',
                                  },
                                ]
                              : [],
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <a
                        href='/'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'
                      >
                        {formatTime(item?.checkOut?.date)}
                      </a>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center'>
                        {currentUser?.attendanceMethod === 'mobile'
                          ? TotalHours(item?.checkIn?.date, item?.checkOut?.date)
                          : calculateWorkingHours(
                              item.checkIn.date,
                              item?.checkOut?.date,
                              item?.breakIn?.date,
                              item?.breakOut?.date
                            )}
                      </span>
                    </td>
                    {currentUser?.attendanceMethod === 'web' && (
                      <td>
                        <div
                          className='text-primary cursor-pointer fs-6'
                          onClick={() => {
                            setShowModal(true)
                            setCoordinates([
                              {
                                lat: item?.checkIn?.location?.latitude,
                                lng: item?.checkIn?.location?.longitude,
                              },
                              {
                                lat: item?.checkOut?.location?.latitude,
                                lng: item?.checkOut?.location?.longitude,
                              },
                              item?.breakIn?.location && {
                                lat: item?.breakIn?.location?.latitude,
                                lng: item?.breakIn?.location?.longitude,
                              },
                              item?.breakIn?.location && {
                                lat: item?.breakOut?.location?.latitude,
                                lng: item?.breakOut?.location?.longitude,
                              },
                            ])
                            setMarkerLabel(['Check In', 'Check Out', 'Break In', 'Break Out'])
                            setCenter({
                              lat: item?.checkIn?.location?.latitude,
                              lng: item?.checkIn?.location?.longitude,
                            })
                          }}
                        >
                          Map <KTIcon iconName='arrow-up-right' className='text-primary' />
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
        {showModal && (
          <div className='modal' style={{display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Choose your Location</h5>
                  <button
                    type='button'
                    className='btn-close'
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className='modal-body'>
                  {/* Modal body content */}
                  <div className='card-body py-4'>
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={14}
                      onClick={(e) => {
                        // handleMapClick(e)
                        setSelectedMarker(null)
                      }}
                    >
                      {/* You can add markers, polylines, etc. here if needed */}
                      {coordinates?.map((item, index) => {
                        return (
                          <Marker
                            position={item}
                            key={index}
                            onClick={() => setSelectedMarker(index)}
                            label={markerLabel[index]}
                          >
                            {selectedMarker === index && (
                              <InfoWindow
                                onCloseClick={() => setSelectedMarker(null)}
                                position={item}
                              >
                                <div>
                                  <h3>Marker {index + 1}</h3>
                                  <input
                                    type='text'
                                    value={markerLabel[index]}
                                    readOnly
                                    className='form-control form-control-solid mb-3 mb-lg-0'
                                  />
                                </div>
                              </InfoWindow>
                            )}
                          </Marker>
                        )
                      })}
                    </GoogleMap>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TimeTracker
