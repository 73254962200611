import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {
  getFilteredPaymentMade,
  getFilteredPaymentMadeByAgentId,
  setPaymentMadeStatusApprove,
  setPaymentMadeStatusReject,
} from '../../Core/_requests'
import {useAuth} from '../../../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {
  setPaymentMadeDataFetch,
  setPaymentMadeDataList,
} from '../../../../redux/procurement/paymentMade/paymentMadeSlice'
import Pagination from '../Pagination'

const PaymentMadeApproval = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const [paymentMade, setPaymentMade] = useState([])
  const paymentMadeList = useSelector(
    (state: RootState) => state.procurement.paymentMade.paymentMadeData
  )
  const [permissions, setPermissions] = useState<any>({})
  const totalPagesStore = useSelector(
    (state: RootState) => state.procurement.paymentMade.totalPages
  )

  const [loading, setLoading] = useState(false)
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 25

  const getList = async () => {
    try {
      const data = await getFilteredPaymentMade(
        '',
        currentUser?.organization,
        currentPage,
        itemsPerPage
      )
      setPaymentMade(data?.paymentsMade)
      setTotalPages(data?.totalPages)
      if (currentPage === 1) dispatch(setPaymentMadeDataList(data))
      setLoading(false)
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }

  const getPaymentMadebyAgent = async () => {
    const res = await getFilteredPaymentMadeByAgentId(
      '',
      currentUser?._id,
      currentPage,
      itemsPerPage
    )
    setPaymentMade(res?.paymentsMade)
    setTotalPages(res?.totalPages)
    if (currentPage === 1) dispatch(setPaymentMadeDataList(res))
    setLoading(false)
  }

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchData = () => {
    if (paymentMadeList.length === 0 || currentPage !== 1) {
      setLoading(true)
      if (permissions?.paymentmade?.admin === false) {
        getPaymentMadebyAgent()
      } else if (
        permissions?.paymentmade?.admin === true ||
        currentUser?.profileType === 'superadmin'
      ) {
        getList()
      }
    } else {
      setPaymentMade(paymentMadeList)
      setTotalPages(totalPagesStore)
    }
  }

  useEffect(() => {
    fetchData()
  }, [permissions, currentPage])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {})
    return formattedDate
  }

  const handleApproved = async (id, index) => {
    const res = await setPaymentMadeStatusApprove(id)
    if (res) {
      const updatedData = paymentMade.map((item, i) => {
        if (i === index) {
          return {...item, approval: 'accepted'}
        }
        return item
      })
      setPaymentMade(updatedData)
      if (currentPage === 1) {
        dispatch(
          setPaymentMadeDataList({
            paymentsMade: [],
            totalPages: 1,
          })
        )
        dispatch(setPaymentMadeDataFetch())
      }
    }
  }

  const handleReject = async (id, index) => {
    const res = await setPaymentMadeStatusReject(id)
    if (res) {
      const updatedData = paymentMade.map((item, i) => {
        if (i === index) {
          return {...item, approval: 'rejected'}
        }
        return item
      })
      setPaymentMade(updatedData)
      if (currentPage === 1) {
        dispatch(
          setPaymentMadeDataList({
            paymentsMade: [],
            totalPages: 1,
          })
        )
        dispatch(setPaymentMadeDataFetch())
      }
    }
  }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Payments Made</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {loading ? (
          <div className='d-flex align-items-center justify-center'>
            <div className='loader'></div>
          </div>
        ) : (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-100px rounded-start'>Date</th>
                  <th className='min-w-100px'>ID</th>
                  <th className='min-w-100px'>Bill#</th>
                  <th className='min-w-150px'>Vendor</th>
                  <th className='min-w-100px'>Amount</th>
                  <th className='min-w-100px'>Approval</th>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.procurementapproval?.update) && (
                    <th className='min-w-100px rounded-end px-10'>Action</th>
                  )}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {paymentMade?.map((item: any, index) => (
                  <tr key={item?._id?.toString()}>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark ps-4 fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(item?.paymentDate)}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.billsData[0]?.billNo?.id}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.vendor?.displayName}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/procurement/purchaseOrdersList/${item._id}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {item?.billsData[0]?.billAmount}
                      </Link>
                    </td>
                    <td>
                      <span
                        className={`badge fs-7 fw-semibold ${
                          item.approval === 'accepted'
                            ? 'badge-light-success'
                            : item.approval === 'rejected'
                            ? 'badge-light-danger'
                            : 'badge-light-warning'
                        }`}
                      >
                        {item?.approval}
                      </span>
                    </td>
                    {(currentUser?.profileType === 'superadmin' ||
                      permissions?.procurementapproval?.update) && (
                      <td className='text-center'>
                        <div className='d-flex'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm dropdown-toggle'
                              type='button'
                              id={`actionDropdown${index}`}
                              data-bs-toggle='dropdown'
                              aria-expanded='false'
                            >
                              Actions
                            </button>
                            <ul
                              className='dropdown-menu'
                              aria-labelledby={`actionDropdown${index}`}
                            >
                              {(item.approval === 'pending' || item.approval === 'rejected') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleApproved(item._id, index)}
                                  >
                                    Accept
                                  </button>
                                </li>
                              )}
                              {(item.approval === 'pending' || item.approval === 'accepted') && (
                                <li>
                                  <button
                                    className='dropdown-item'
                                    onClick={() => handleReject(item._id, index)}
                                  >
                                    Reject
                                  </button>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default PaymentMadeApproval
