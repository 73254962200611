import {createSlice} from '@reduxjs/toolkit'

interface ProjectStatus {
  totalProjects: number
  inProgressCount: number
  overdueCount: number
  pendingCount: number
  completedCount: number
}

interface ProjectSummary {
  projectName: string
  status: 'InProgress' | 'Pending' | 'Completed' | 'Overdue'
  managers: string[]
}

interface ProjectBudget {
  totalBudget: number
  totalCost: number
}

const initialState = {
  projectStatus: null as ProjectStatus | null,
  projectStatusLoading: true,
  projectTargets: [],
  projectTargetsLoading: true,
  projectSummary: [] as ProjectSummary[],
  projectSummaryLoading: true,
  projectBudget: {
    totalBudget: 0,
    totalCost: 0,
  } as ProjectBudget,
  projectBudgetLoading: true,
}

const projectDashboardSlice = createSlice({
  name: 'projectDashboard',
  initialState,
  reducers: {
    setProjectStatus(state, action) {
      state.projectStatus = action.payload
      state.projectStatusLoading = false
    },
    setProjectTargets(state, action) {
      state.projectTargets = action.payload
      state.projectTargetsLoading = false
    },
    setProjectSummary(state, action) {
      state.projectSummary = action.payload
      state.projectSummaryLoading = false
    },
    setProjectBudget(state, action) {
      state.projectBudget = action.payload
      state.projectBudgetLoading = false
    },
  },
})

export const {setProjectStatus, setProjectTargets, setProjectSummary, setProjectBudget} =
  projectDashboardSlice.actions
export default projectDashboardSlice.reducer
