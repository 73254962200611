import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {CustomerInterface} from '../../../Core/_model'

const Step2: FC = () => {
  const {setFieldValue, values} = useFormikContext<CustomerInterface>()
  const [workPhoneNo, setWorkPhoneNo] = useState<string | undefined>('')
  const [personalNo, setPersonalNo] = useState<string | undefined>('')

  useEffect(() => {
    if (values?.contactNumbers?.workPhone && values?.contactNumbers?.mobilePhone) {
      setWorkPhoneNo(String(values?.contactNumbers?.workPhone) || '')
      setPersonalNo(String(values?.contactNumbers?.mobilePhone) || '')
    }
  }, [values?.contactNumbers?.workPhone, values?.contactNumbers?.mobilePhone])

  useEffect(() => {
    setFieldValue('contactNumbers.workPhone', workPhoneNo)
    setFieldValue('contactNumbers.mobilePhone', personalNo)
  }, [setFieldValue, workPhoneNo, personalNo])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Basic Details</h2>
      </div>
      
      <div className='fv-row mb-10 '>
        <label className='form-label required '>Primary Contact</label>
        <div className='d-flex align-items-center space-x-3'>
          <Field
            as='select'
            name='primaryContact.salutation'
            className='form-select form-select-lg form-select-solid '
          >
            <option value=''>Select </option>
            <option value='Mr'>Mr</option>
            <option value='Mrs'>Mrs</option>
            <option value='Ms'>Ms</option>
          </Field>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='primaryContact.firstName'
            placeholder='First Name'
          />

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='primaryContact.lastName'
            placeholder='Last Name'
          />
        </div>
        <div className='d-flex align-items-center justify-between'>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.salutation' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.firstName' />
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='primaryContact.lastName' />
          </div>
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label  required'>Company Name</label>
        <div className='d-flex align-items-center'>
          <Field
            type='text'
            name='companyName'
            className='form-control form-control-lg form-control-solid'
            placeholder='Company Name'
          />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Customer Display Name</label>
        <div className='d-flex align-items-center'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='displayName'
            placeholder='Customer Display Name'
          />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='displayName' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required'>Customer VAT Number</label>
        <div className='d-flex align-items-center'>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='vatNumber'
            placeholder='Customer VAT Number'
          />
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='vatNumber' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label required '>Customer Email</label>
        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name='emailAddress'
          placeholder='Email Address'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='emailAddress' />
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>Customer Phone</label>
        <div className='d-flex align-items-center space-x-3'>
          <div className='form-control form-control-lg form-control-solid'>
            <label className='mb-3 form-label text-muted'>Landline Number</label>
            <PhoneInput
              country={'sa'}
              value={workPhoneNo}
              onChange={(phone) => setWorkPhoneNo(phone)}
            />
          </div>

          <div className='form-control form-control-lg form-control-solid'>
            <label className='mb-3 form-label required text-muted'>Mobile Number</label>
            <PhoneInput
              country={'sa'}
              value={personalNo}
              onChange={(phone) => setPersonalNo(phone)}
            />
          </div>
        </div>
        {!personalNo && (
          <div className='text-danger mt-2 fs-6 font-normal'>Phone number is required</div>
        )}
      </div>
    </div>
  )
}

export {Step2}
