import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from '../Components/Steps/Step1'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from '../core/CreateAccountWizardHelper'
import {Step2} from '../Components/Steps/Step2'
import {Step3} from '../Components/Steps/Step3'
import {Step4} from '../Components/Steps/Step4'
import {Step5} from '../Components/Steps/Step5'
import {Step6} from '../Components/Steps/Step6'
import {addEmployee} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import { createUser } from '../../user-management/users-list/core/_requests'

const NewMember: FC = () => {
  const {currentUser} = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }
  const navigate = useNavigate()

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)
      const mappedValues = {
        employeeId: values.employeeId,
        firstName: values.firstName,
        lastName: values.lastName,
        nickName: values.nickName,
        email: values.email,
        department: values.department,
        location: values.location,
        designation: values.designation,
        role: values.role,
        employementType: values.employmentType,
        employeeStatus: values.employeeStatus,
        sourceOfHire: values.sourceOfHire,
        dateOfJoining: values.dateOfJoining,
        reportingManager: values.reportingManager,
        dateOfBirth: values.dateOfBirth,
        age: values.age,
        gender: values.gender,
        maritalStatus: values.maritalStatus,
        aboutMe: values.aboutMe,
        passportNumber: values.passportNumber,
        idNumber: values.idNumber,
        aadhaarNumber: values.aadharNumber,
        workPhoneNo: values.workPhoneNo,
        personalMobile: values.personalMobile,
        extension: values.extension,
        personalEmail: values.personalEmail,
        seatingLocation: values.seatingLocation,
        tags: values.tags,
        currentAddress: {
          addressLine1: values.presentAddressLine1,
          addressLine2: values.presentAddressLine2,
          city: values.presentCity,
          state: values.presentState,
          country: values.presentCountry,
          postalCode: values.presentPostalcode,
        },
        isPermanentSameAsCurrent: values.sameAsPresentAddress,
        permanentAddress: {
          addressLine1: values.permanentAddressLine1,
          addressLine2: values.permanentAddressLine2,
          city: values.permanentCity,
          state: values.permanentState,
          country: values.permanentCountry,
          postalCode: values.permanentPostalCode,
        },
        company: currentUser?.company,
        organization: currentUser?.organization,
        ...values,
      }
      // stepper.current.goto(1)
      console.log('mappedValues', mappedValues)
      const response = await addEmployee(mappedValues)
      if(values?.addUser){
        const data = {
          email: values.email,
          fullName: `${values.firstName} ${values.lastName}`,
          password: values.password,
          role: values.roleID,
          phone: values?.workPhoneNo?.toString(),
        }
        const res = await createUser(data, currentUser?.company, values.departmentID, currentUser?.organization)
      }
      console.log('submited', response)
      console.log('submited', values)
      navigate('/hrm/employee')
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Basic Information</h3>
            </div>

            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Basic Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Work Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Salary Information</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Personal Details</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Indentity Information</h3>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title-dark'>Contact Details</h3>
            </div>
          </div> */}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='card-body xl:fixed px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Basic Information</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Work Information</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Salary Information</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Personal Details</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 5*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Indentity Information</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>
            {/* begin::Line*/}
            <div className='stepper-line h-40px'></div>
            {/* end::Line*/}
            {/* end::Step 5*/}
            {/* begin::Step 6*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>6</span>
                </div>
                {/* end::Icon*/}

                <div className='stepper-label'>
                  <h3 className='stepper-title-dark'>Contact Details</h3>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}
            </div>

            {/* end::Step 6*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step6 />
              </div>

              <div className='d-flex flex-stack pt-15'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' disabled={submitting} className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {!isSubmitButton && 'Continue'}
                      {isSubmitButton && 'Submit'}
                      <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {NewMember}
