import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import {useAuth} from '../../../modules/auth/core/Auth'
import {getProjectPerformance} from '../core/_requests'
import ProjectsSummaryCard from './ProjectsSummaryCard'
import ProjectsStatBar from './ProjectsStatBar'
import {useSelector, useDispatch} from 'react-redux'
import {setPerformance} from '../../../redux/projects/projectPerformanceSlice'
import {RootState} from '../../../redux/store'

const ProjectPerformance: React.FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const projectPerformanceData = useSelector(
    (state: RootState) => state.projects.projectPerformance.performance
  )
  const loading = useSelector((state: any) => state.projects.projectPerformance.loading)
  const orgid = currentUser.organization

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getProjectPerformance(orgid)
        // Sort data by performance percentage in descending order
        data.sort((a, b) => b.performancePercentage - a.performancePercentage)
        dispatch(setPerformance(data))
      } catch (error) {
        console.error('Error fetching project performance data:', error)
      }
    }

    if (loading) fetchData()
  }, [orgid])

  // Slice the first 5 projects from the salesPerformanceData array
  const top5Projects = projectPerformanceData.filter((project) => !project.isDeleted).slice(0, 5)

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <div className='flex-1 '>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 '>
          <div className='md:col-span-1 pb-4'>
            {/* Left card */}
            <div className='card h-fit'>
              {/* Card content */}

              {/* <!--begin::Chart widget 5--> */}
              <div className='card card-flush h-md-100'>
                <div id='kt_charts_widget_5' className='min-h-auto'>
                  <ProjectsStatBar projects={top5Projects} />
                </div>
              </div>
              {/* <!--end::Chart widget 5--> */}
            </div>
          </div>
          <div className='md:col-span-1 pb-4'>
            <div className='card card-flush h-md-100'>
              <div id='kt_charts_widget_5' className='min-h-auto'>
                <ProjectsSummaryCard projects={top5Projects} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card p-4'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Projects Performance</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Projects</th>
                  <th className='min-w-140px'>No.of Targets</th>
                  <th className='min-w-140px'>No.of Completed Targets</th>
                  <th className='min-w-140px'>No.of In Progress Tasks</th>
                  <th className='min-w-140px'>No.of Products</th>
                  <th className='min-w-140px'>No.of Employees</th>
                  <th className='min-w-140px'>Total Expense</th>
                  <th className='min-w-140px'>Total Budget</th>
                  <th className='min-w-140px'>Performance Percentage</th>
                </tr>
              </thead>
              <tbody>
                {projectPerformanceData
                  .filter((project) => !project.isDeleted)
                  .map((project, index) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'></div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {project.projectName}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className='text-start'>{project.numOfTargets}</td>
                      <td className='text-start'>{project.numOfCompletedTargets}</td>
                      <td className='text-start'>{project.numOfInProgressTasks}</td>
                      <td className='text-start'>{project.numOfProducts}</td>
                      <td className='text-start'>{project.numOfEmployees}</td>
                      <td className='text-start'>
                        {project?.totalExpense?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {project.currency}
                      </td>
                      <td className='text-start'>
                        {project?.totalBudget?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                        {project.currency}
                      </td>
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {project.performancePercentage.toFixed(2)}%
                            </span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-info'
                              role='progressbar'
                              style={{width: `${project.performancePercentage}%`}}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectPerformance
