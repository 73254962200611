import React from 'react'

export default function Status({status, setStatus, setScheduleDate, scheduleDate}) {
  const getStatusIndicatorColor = () => {
    switch (status) {
      case 'Published':
        return 'bg-blue-400'
      case 'Draft':
        return 'bg-green-400'
      case 'Schedule':
        return 'bg-orange-400'
      case 'Inactive':
        return 'bg-red-600'
      default:
        return ''
    }
  }

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value
    setStatus(selectedStatus)

    // If the selected status is 'Schedule', you can initialize the date and time input
    if (selectedStatus === 'Schedule') {
      setScheduleDate('')
    }
  }

  const formatScheduleDate = (dateString) => {
    const parsedDate = new Date(dateString)
    return parsedDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  return (
    <div class='card border-0 p-4 rounded-md'>
      <div class='card-body'>
        <div className='flex justify-between'>
          <h5 class='card-title text-start fw-semibold fs-5 '>Status</h5>
          <div className={`rounded h-3 w-3 ${getStatusIndicatorColor()}`}></div>
        </div>

        <div className='pt-3'>
          <select
            class='form-select mb-2'
            data-control='select2'
            data-hide-search='true'
            data-placeholder='Select an option'
            id='kt_ecommerce_add_product_status_select'
            value={status}
            onChange={handleStatusChange}
          >
            <option></option>
            <option value='Published' selected='selected'>
              Published
            </option>
            <option value='Draft'>Draft</option>
            <option value='Schedule'>Scheduled</option>
            <option value='Inactive'>Inactive</option>
          </select>

          {/* <select
            class='form-select'
            value={status}
            onChange={handleStatusChange}
            aria-label='Default select example'
          >
            <option selected value='Published'>
              Published
            </option>
            <option value='Draft'>Draft</option>
            <option value='Schedule'>Schedule</option>
            <option value='Inactive'>Inactive</option>
          </select> */}
        </div>
        <p class='card-text  w-full mt-4 text-gray-500 text-start'>Set the product status.</p>
        {status === 'Schedule' && (
          <div className='mt-3 text-start '>
            <label className='mt-3 mb-1'>Select Publishing date and time</label>
            <input
              class='form-control'
              id='kt_ecommerce_add_product_status_datepicker'
              placeholder='Pick date & time'
              type='datetime-local'
              value={scheduleDate}
              onChange={(e) => setScheduleDate(e.target.value)}
            />
            {/* <input
              className='border p-2 px-4 rounded mt-2'
              placeholder='Pick Date and time'
              type='datetime-local'
              value={scheduleDate}
              onChange={(e) => setScheduleDate(e.target.value)}
            /> */}
            {scheduleDate && (
              <p className='mt-2'>Selected Date: {formatScheduleDate(scheduleDate)}</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
