import React, {FC, useState} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {CustomerInterface} from '../../../Core/_model'

const Step5: FC = () => {
  const {values, setFieldValue} = useFormikContext<CustomerInterface>()
  const [tables, setTables] = useState(() => {
    return values.contactPersons
      ? Array.from({length: values.contactPersons.length}, (_, index) => index)
      : [0]
  })

  const fields = [
    {
      name: 'salutation',
      type: 'select',
      placeholder: 'Salutation',
      options: ['', 'Mr.', 'Mrs.', 'Ms', 'Miss.', 'Dr.'],
    },
    {name: 'firstName', type: 'text', placeholder: 'First Name'},
    {name: 'lastName', type: 'text', placeholder: 'Last Name'},
    {name: 'emailAddress', type: 'email', placeholder: 'Email Address'},
    {name: 'workPhone', type: 'mobile', placeholder: 'Work Phone'},
    {name: 'mobile', type: 'mobile', placeholder: 'Personal Mobile'},
    {name: 'designation', type: 'text', placeholder: 'Designation'},
    {name: 'department', type: 'text', placeholder: 'Department'},
  ]

  const addTable = () => {
    // setTables(prevTables => [...prevTables, prevTables.length]);
    setTables([...tables, tables.length])
  }
  const removeTable = (index) => {
    if (tables.length > 1) {
      const updatedTables = tables.filter((_, i) => i !== index)
      setTables(updatedTables)
      const updatedContactPersons = values.contactPersons.filter((_, i) => i !== index)
      setFieldValue('contactPersons', updatedContactPersons)
    }
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Contact Persons</h2>
      </div>
      <>
        {tables.map((_, tableIndex) => (
          <div key={tableIndex}>
            <table className='w-full border-collapse border border-gray-200 mb-6'>
              <tbody>
                {fields.map((field, index) => (
                  <tr key={index} className='border-b border-gray-200'>
                    <td>
                      <label className='form-label mb-3'>{field.placeholder}</label>
                    </td>
                    <td>
                      {field.type === 'select' ? (
                        <Field
                          as='select'
                          className='form-select form-select-lg form-select-solid'
                          name={`contactPersons[${tableIndex}].${field.name}`}
                          placeholder={field.placeholder}
                        >
                          {field.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option}>
                              {option}
                            </option>
                          ))}
                        </Field>
                      ) : field.type === 'mobile' ? (
                        <div className='form-control form-control-lg form-control-solid'>
                          <PhoneInput
                            country={'sa'}
                            // value={values.contactPersons[tableIndex][field.name]}
                            value={
                              (values.contactPersons[tableIndex] &&
                                values.contactPersons[tableIndex][field.name]) ||
                              ''
                            }
                            onChange={(phone) =>
                              setFieldValue(`contactPersons[${tableIndex}].${field.name}`, phone)
                            }
                          />
                        </div>
                      ) : (
                        <Field
                          type={field.type}
                          className='form-control form-control-lg form-control-solid'
                          name={`contactPersons[${tableIndex}].${field.name}`}
                          placeholder={field.placeholder}
                        />
                      )}
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={field.name} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {tables.length > 1 && (
              <button
                onClick={() => removeTable(tableIndex)}
                className='btn overflow-hidden object-cover '
              >
                <KTIcon iconName='cross-circle' className='fs-1 text-danger' />
                Remove
              </button>
            )}
          </div>
        ))}
      </>
      <div className='float-right '>
        <button type='button' onClick={addTable} className='btn '>
          <KTIcon iconName='plus-circle' className='fs-1 text-primary' />
          Add Table
        </button>
      </div>
    </div>
  )
}

export {Step5}
