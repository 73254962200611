import React, { useCallback, useEffect, useState } from 'react'
import { getQuotebyids, sendQuotationMailByID } from '../Core/_requests'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../modules/auth'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import './custom-quill-styles.css';
import { Accept, useDropzone } from 'react-dropzone'


const EmailComposerQuotation = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  const [data, setData] = useState<any>()
  const [files, setFiles] = useState([])
  const [name, setName] = useState('')
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);

  const [formData, setFormData] = useState({
    from: currentUser.email,
    to: '',
    cc: '',
    subject: '',
    body: '',
    attachFile: true,
    attachpdf: true,
  })


  const id = useParams().id
  const [emailLoading, setEmailLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getQuotebyids(id);
        setData(res);
        if (res && res[0].customer && res[0].customer.emailAddress) {
          const formattedDate = new Date(res[0].date).toLocaleDateString('en-GB');
          const htmlFormattedBody = `
            <p>Dear Customer,</p>
            <p>Attached is the Quotation for your recent interaction with QuantumFlow.</p>
            <p><strong>Quotation: ${res[0].id}</strong></p>
            <p><strong>Date: ${formattedDate}</strong></p>
            <p>Thank you for your business!</p>
          `;
          setFormData(prevData => ({
            ...prevData,
            to: res[0].customer.emailAddress,
            subject: `QUOTATION FROM QUANTUMFLOW (${res[0].id})`,
            body: htmlFormattedBody,
            // body: `Dear Customer,\n\nAttached is the Quotation for your recent interaction with QuantumFlow.\n\n Quotation: ${res[0].id} \n\n Date: ${formattedDate} \n\nThank you for your business!`,
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getData();
  }, [id]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prev) => ({ ...prev, [id]: newValue }));
  };


  const toggleCc = () => {
    setCcActive(!ccActive);
  };

  const toggleBcc = () => {
    setBccActive(!bccActive);
  };


  const sendEmail = async (id) => {
    setEmailLoading(true);
    try {
      const emailData = {
        to: formData.to,
        cc: formData.cc,
        sendMain: formData.attachpdf,
        sendDefault: formData.attachFile,
        subject: formData.subject,
        body: formData.body,
      };
      console.log("email data", emailData);
      const response = await sendQuotationMailByID(id, emailData);
      setEmailLoading(false);
      // toast.success('Email sent successfully');
      toast.success('Email sent successfully', {
        position: 'top-center', // Position the notification at the top center of the screen
        style: {
          fontSize: '18px', // Increase font size
          width: '400px', // Adjust width
          textAlign: 'center', // Center text
        },
      });
      navigate(-1)
    } catch (error) {
      console.error('Axios error:', error);
      setEmailLoading(false);
      throw error;
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['image'],
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'image'
  ]
  const acceptedFileTypes: Accept = {
    'audio/*': ['audio/*'],
    'video/*': ['video/*'],
    'image/*': ['image/*'],
    'application/pdf': ['application/pdf'],
  }


  const handleQuillChange = (e) => {
    console.log(e);
    // const text = e.replace(/(<([^>]+)>)/gi, ''); // Remove all HTML tags
    // const sanitizedText = DOMPurify.sanitize(text,config); // Optionally sanitize further
    // console.log(sanitizedText);
    setFormData((prev) => ({ ...prev, body: e }));
    // setFormData((prev) =>({...prev,[field]:value})) // Update body with plain text
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles)
    setName(acceptedFiles[0].name)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFileTypes,
  })

  const removeFile = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  return (
    <>
      <div className="flex-lg-row-fluid ms-lg-7 ms-xl-10">
        {/*begin::Card*/}
        <div className="card">
          <div className="card-header d-flex align-items-center justify-content-between py-3">
            <h2 className="card-title m-0">Compose Message</h2>
            {/*begin::Toggle*/}
            <a
              href="#"
              className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none"
              data-bs-toggle="tooltip"
              data-bs-dismiss="click"
              data-bs-placement="top"
              id="kt_inbox_aside_toggle"
              aria-label="Toggle inbox menu"
              data-bs-original-title="Toggle inbox menu"
              data-kt-initialized={1}
            >
              <i className="ki-duotone ki-burger-menu-2 fs-3 m-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
                <span className="path7" />
                <span className="path8" />
                <span className="path9" />
                <span className="path10" />
              </i>{" "}
            </a>
            {/*end::Toggle*/}
          </div>
          <div className="card-body p-0">
            {/*begin::Form*/}
            <form id="kt_inbox_compose_form">
              {/*begin::Body*/}
              <div className="d-block">
                <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                  {/*begin::Label*/}
                  <div className="text-gray-900 fw-bold w-75px">From:</div>
                  {/*end::Label*/}
                  {/*begin::Input*/}


                  <input
                    role='textBox'
                    className="form-control form-control-transparent border-0"
                    tabIndex={-1}
                    type='email'
                    id='from'
                    name='from'
                    value={formData.from}
                    onChange={handleChange}
                  />
                  {/*end::Input*/}

                </div>
                {/*begin::To*/}

                <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                  {/*begin::Label*/}
                  <div className="text-gray-900 fw-bold w-75px">To:</div>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input
                    className="form-control form-control-transparent border-0"
                    type='email'
                    id='to'
                    name='to'
                    value={formData.to}
                    onChange={handleChange}
                    tabIndex={-1}
                  />
                  {/*end::Input*/}
                  {/*begin::CC & BCC buttons*/}
                  <div className="ms-auto w-75px text-end">
                    <span className={`text-muted fs-bold cursor-pointer text-hover-primary me-2 ${ccActive ? 'active' : ''}`} onClick={toggleCc}>
                      Cc
                    </span>
                    <span className={`text-muted fs-bold cursor-pointer text-hover-primary ${bccActive ? 'active' : ''}`} onClick={toggleBcc}>
                      Bcc
                    </span>
                  </div>
                  {/*end::CC & BCC buttons*/}
                </div>
                {/*end::To*/}
                {/*begin::CC*/}
                <div className={`d-flex align-items-center border-bottom px-8 min-h-50px ${ccActive ? '' : 'd-none'}`} data-kt-inbox-form="cc">
                  {/* Label */}

                  <div className="text-gray-900 fw-bold w-75px">Cc:</div>

                  {/* Input */}
                  <input
                    role='textBox'
                    className="form-control form-control-transparent border-0"
                    tabIndex={-1}
                    type='email'
                    id='cc'
                    name='cc'
                    value={formData.cc}
                    onChange={handleChange}
                  />
                  {/* Close */}
                  <span className="btn btn-clean btn-xs btn-icon" data-kt-inbox-form="cc_close" onClick={toggleCc}>
                    <i className="ki-duotone ki-cross fs-5">
                      <span className="path1" />
                      <span className="path2" />
                    </i>{" "}
                  </span>
                </div>

                {/* BCC */}
                <div className={`d-flex align-items-center border-bottom px-8 min-h-50px ${bccActive ? '' : 'd-none'}`} data-kt-inbox-form="bcc">
                  {/* Label */}
                  <div className="text-gray-900 fw-bold w-75px">Bcc:</div>
                  {/* Input */}

                  <input type="text" className="form-control form-control-transparent border-0" name="compose_bcc" id='compose_bcc' defaultValue="" data-kt-inbox-form="tagify" tabIndex={-1} />
                  {/* Close */}
                  <span className="btn btn-clean btn-xs btn-icon" data-kt-inbox-form="bcc_close" onClick={toggleBcc}>
                    <i className="ki-duotone ki-cross fs-5">
                      <span className="path1" />
                      <span className="path2" />
                    </i>{" "}
                  </span>
                </div>
                {/*end::BCC*/}
                {/*begin::Subject*/}
                <div className="d-flex align-items-center border-bottom px-8 min-h-50px">
                  <div className="text-gray-500 fw-bold w-75px">Subject:</div>

                  <input
                    className="form-control form-control-transparent border-0 px-8 min-h-45px"
                    placeholder="Subject"
                    id='subject'
                    name='subject'
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
                {/*end::Subject*/}
                {/*begin::Message*/}
                <div className="mt-4 col-span-6 mb-4 space-y-2 row-span-5">
                  {/* <div className="h-250px ql-container ql-snow ql-toolbar border-0"  ref={quillRef}  /> */}
                  <ReactQuill
                    id='body'
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={formData.body}
                    onChange={handleQuillChange}
                    className='bg-white border-0 h-250px p-0 ql-container ql-snow ql-toolbar appearance-none  leading-tight focus:outline-none'
                  />

                </div>
                {/*end::Message*/}
                {/*begin::Attachments*/}

                <div
                  className="dropzone dropzone-queue px-8 py-4"
                  data-kt-inbox-form="dropzone"
                >
                  <div className="dropzone-items">
                    {files.map((file, index) => (
                      <div className='dropzone-item p-5' key={index}>
                        <div className='dropzone-file align-items-center'>
                          <div className='dropzone-filename text-dark' title={file.name}>
                            <span>{file.name}</span>
                            <strong>({(file.size / 1024).toFixed(2)}kb)</strong>
                          </div>
                          <div className='dropzone-error mt-0'></div>
                        </div>
                        <div className='dropzone-toolbar'>
                          <span className='dropzone-cancel' onClick={() => removeFile(index)}>
                            <i className='ki-duotone ki-cross fs-2'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                          </span>
                        </div>
                      </div>
                    ))}

                  </div>
                  <div className="dz-default dz-message">

                    <button className="dz-button" type="button">
                      Drop files here to upload
                    </button>
                  </div>
                </div>
                {/*end::Attachments*/}
              </div>
              {/*end::Body*/}
              {/*begin::Footer*/}
              <div className="d-flex flex-stack flex-wrap gap-2 py-8 ps-8 pe-5 border-top ">
                {/*begin::Actions*/}
                <div className="d-flex align-items-center me-3">
                  {/*begin::Send*/}
                  <div className="btn-group me-4">
                    {/*begin::Submit*/}
                    <button
                      type='button'
                      className={`btn btn-md btn-primary ${emailLoading ? 'disabled' : ''}`}
                      onClick={() => sendEmail(id)}
                      disabled={emailLoading}
                      style={{ marginLeft: 'auto' }}
                    >
                      {emailLoading ? 'Sending...' : 'Send Email'}
                    </button>
                    {/*end::Submit*/}
                    {/*begin::Send options*/}
                    <span
                      className="btn btn-primary dropup btn-icon fs-bold w-30px pe-0"
                      role="button"
                    >
                      <span className="dropdown-toggle dropdown-toggle-split transition delay-300 ease-in-out" data-bs-toggle="dropdown" aria-expanded="false">
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </span>
                      <ul className="dropdown-menu px-3 pe-5 py-4 fs-7 gap-3 ">
                        <li >
                          <a className="dropdown-item" href="#">
                            <span className='text-secondary fw-semibold'>Schedule Send</span>
                          </a>
                        </li>
                        <li >
                          <a className="dropdown-item" href="#">
                            <span className='text-secondary fw-semibold'>Save & Archive</span>
                          </a>
                        </li>
                        <li >
                          <a className="dropdown-item" href="#">
                            <span className='text-secondary fw-semibold'>Cancel</span>
                          </a>
                        </li>
                      </ul>
                      {/*end::Menu*/}{" "}
                    </span>
                    {/*end::Send options*/}
                  </div>
                  {/*end::Send*/}
                  {/*begin::Upload attachement*/}
                  <span
                    {...getRootProps()}

                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable"
                    id="kt_inbox_reply_attachments_select"
                    data-kt-inbox-form="dropzone_upload"
                  >
                    <input {...getInputProps()} />

                    <i className="ki-duotone ki-paper-clip fs-2 m-0" />{" "}
                  </span>
                  {/*end::Upload attachement*/}
                  {/*begin::Attach Pdf*/}
                  <span
                    className="btn btn-icon w-full fs-bold  pe-0 dropup "
                    role="button"
                  >
                    <div className=" transition delay-300 ease-in-out p-4 hover:scale-120 active:scale-120 " data-bs-toggle="dropdown" aria-expanded="false">
                      <i className="fas fa-ellipsis-v fs-4 m-0"></i>
                    </div>
                    <ul className="dropdown-menu px-3 pe-5 py-4 fs-7 w-max">
                      <li >
                        <div className="form-check form-switch py-2">
                          <input className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id='attachpdf'
                            name='attachpdf'
                            checked={formData.attachpdf}
                            onChange={handleChange} />
                          <label className="form-check-label"
                            htmlFor=""> <div className=' whitespace-nowrap overflow: hidden;'>Attach Quotation PDF</div></label>
                        </div>

                      </li>
                      <li >
                        <div className="form-check form-switch py-2">
                          <input className="form-check-input"
                            role="switch"
                            id='attachFile'
                            name='attachFile'
                            type='checkbox'
                            checked={formData.attachFile}
                            onChange={handleChange} />
                          <label className="form-check-label " htmlFor="flexSwitchCheckChecked">
                            <div className="whitespace-nowrap ">Attach Related Documents</div></label>
                        </div>
                      </li>

                    </ul>
                    {/*end::Menu*/}{" "}
                  </span>
                  {/*end::Attach Pdf*/}
                </div>
                {/*end::Actions*/}
                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center">
                  {/*begin::More actions*/}
                  <span
                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2"
                    data-toggle="tooltip"
                    title="More actions"
                  >
                    <i className="ki-duotone ki-setting-2 fs-2">
                      <span className="path1" />
                      <span className="path2" />
                    </i>{" "}
                  </span>
                  {/*end::More actions*/}
                  {/*begin::Dismiss reply*/}
                  <span
                    className="btn btn-icon btn-sm btn-clean btn-active-light-primary"
                    data-inbox="dismiss"
                    data-toggle="tooltip"
                    title="Dismiss reply"
                  >
                    <i className="ki-duotone ki-trash fs-2">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </i>{" "}
                  </span>
                  {/*end::Dismiss reply*/}
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Footer*/}
            </form>
            {/*end::Form*/}{" "}
          </div>
        </div >
        {/*end::Card*/}
      </div >


    </>
  )
}

export default EmailComposerQuotation
