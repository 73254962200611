import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {CgDanger} from 'react-icons/cg'
import {useParams} from 'react-router-dom'
import {getmailsByCustomerId} from '../Core/_requests'

export default function Mails() {
  const id = useParams().customerId
  const [selectedOption, setSelectedOption] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [mailList, setMailList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await getmailsByCustomerId(id)
      setMailList(res)
    }

    fetchData()
  }, [id])

  const formatDate = (date) => {
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  const formatDateNTime = (date) => {
    // Ensure we have a valid date object
    if (!(date instanceof Date)) {
      return 'Invalid Date'
    }

    // Get the components of the date
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-based
    const year = date.getFullYear()
    let hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const ampm = hours >= 12 ? 'PM' : 'AM'

    // Convert hours to 12-hour format
    hours = hours % 12
    hours = hours ? hours : 12

    // Format the time part
    const timeString = hours.toString().padStart(2, '0') + ':' + minutes + ampm

    // Construct the formatted date string
    const formattedDate = day + '/' + month + '/' + year + ' ' + timeString

    return formattedDate
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
    setModalOpen(!isModalOpen)
  }

  const closeModal = () => {
    setModalOpen(!isModalOpen)
  }

  const handleDelete = () => {
    // Add your delete logic here
    console.log('Delete logic goes here')
    closeModal()
  }

  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header bg-body-tertiary'>
          <h3 className='card-title '>System Mails</h3>
          <div className=' d-flex items-center'>
            <KTIcon iconName='sms' className='fs-2 text-primary ' />

            <select
              className='form-select form-select-solid ms-2'
              aria-label='Select example'
              onChange={handleOptionChange}
              value={selectedOption}
            >
              <option>Link Email account</option>
              <option value='Outlook'>Outlook</option>
              <option value='Zoho'>Zoho Mail</option>
              <option value='Link'>Link with work account</option>
            </select>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-borderless table-vertical-center'>
              <thead>
                <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-140px'></th>
                  <th className='p-0 min-w-120px'></th>
                  <th className='p-0 min-w-120px'></th>
                </tr>
              </thead>
              <tbody>
                {mailList?.map((mail, index) => (
                  <tr key={index}>
                    <td className='p-0'>
                      <KTIcon iconName='sms' className='fs-2 text-primary ' />
                    </td>
                    <td className='p-0'>{formatDateNTime(new Date(mail?.createdAt))}</td>
                    <td className='p-0'>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {mail?.email}
                      </span>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {mail?.type}
                      </span>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>{mail?.id}</span>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {formatDate(mail?.date)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className='modal' id='myModal' tabIndex='-1' role='dialog'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title mx-56 text-yellow-500 '>
                  <CgDanger size={100} />
                </h4>
                <button type='button' className='btn-close' onClick={closeModal}></button>
              </div>

              <div className='modal-body text-center fs-6'>Are you sure you want to delete?</div>

              <div className='modal-footer text-center mx-auto'>
                <button type='button' className='btn btn-danger' onClick={handleDelete}>
                  Delete
                </button>
                <button type='button' className='btn btn-light' onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
