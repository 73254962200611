import React, { useEffect, useState } from 'react'

export default function Card1({ handleInputChange, data, handleNotificationChange, customerList, setData }) {

    useEffect(() => {
        if (data.totalCalculatedDays !== null) {
            let morningShiftHours = parseFloat(data.morningShiftValue) || 0;
            let eveningShiftHours = parseFloat(data.eveningShiftValue) || 0;
            const totalHours = data.totalCalculatedDays * (morningShiftHours + eveningShiftHours)
            setData({
                ...data,
                totalCalculatedHours: totalHours
            })
        }
    }, [data.totalCalculatedDays, data.morningShiftValue, data.eveningShiftValue])

    useEffect(() => {
        if (data?.startDate && data?.releaseDate) {
            const start = new Date(data?.startDate)
            const release = new Date(data?.releaseDate)
            const differenceInTime = release.getTime() - start.getTime()
            const totalCalculatedDays = Math.round(differenceInTime / (1000 * 3600 * 24))
            setData({
                ...data,
                totalCalculatedDays: totalCalculatedDays
            })
        }
    }, [data?.startDate, data?.releaseDate])

    console.log("data", data)

    return (
        <div className='card mb-5 mb-xl-10 container-xxl px-9'>
            <div className='card-header border-0  cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Project Details</h3>
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Project Type
                </label>

                <div className='col-lg-8 fv-row'>
                    <select
                        className='form-select form-select-solid form-select-lg'
                        name='projectType'
                        value={data.projectType}
                        onChange={handleInputChange}
                    >
                        <option value='Personal'>Personal Project</option>
                        <option value='Team'>Team Project</option>
                    </select>
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Customer Name
                </label>
                <div className='col-lg-8 fv-row'>
                    <select
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Customer name'
                        name='customer'
                        value={data.customer}
                        onChange={handleInputChange}
                    >
                        {customerList.map((customer) => (
                            <option key={customer.value} value={customer.value}>
                                {customer.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Project Name
                </label>

                <div className='col-lg-8 fv-row'>
                    <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Project name'
                        name='projectName'
                        value={data.projectName}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Project Description
                </label>

                <div className='col-lg-8 fv-row'>
                    <textarea
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Project description'
                        name='projectDescription'
                        value={data.projectDescription}
                        onChange={handleInputChange}
                    ></textarea>
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Start Date</label>
                <div className='col-lg-8 fv-row'>
                    <input
                        type='date'
                        name='startDate'
                        value={data.startDate}
                        onChange={handleInputChange}
                        className='form-control form-control-md form-control-solid'
                    />
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Release Date
                </label>

                <div className='col-lg-8 fv-row'>
                    <input
                        type='date'
                        className='form-control form-control-lg form-control-solid'
                        name='releaseDate'
                        value={data.releaseDate}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className='row mb-6'>

                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Total number Of Days
                </label>
                <div className='col-lg-8 fv-row'>
                    <input
                        type='text'
                        name='totalCalculatedDays'
                        className='form-control form-control-lg form-control-solid'
                        value={data.totalCalculatedDays}
                        readOnly
                    />
                </div>

            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Morning Shift
                </label>

                <div className='col-lg-8 fv-row d-flex items-center'>
                    <input
                        className='form-check-input me-3'
                        type='checkbox'
                        name='morningShiftBoolean'
                        value={data.morningShiftBoolean}
                        onChange={handleInputChange}
                    />

                    <input
                        type='number'
                        name='morningShiftValue'
                        value={data.morningShiftValue}
                        placeholder='Number of Hours'
                        className='form-control form-control-lg form-control-solid'
                        disabled={!data.morningShiftBoolean}
                        onChange={handleInputChange}
                    ></input>
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Evening Shift
                </label>
                <div className='col-lg-8 fv-row d-flex items-center'>
                    <input
                        className='form-check-input me-3'
                        type='checkbox'
                        name='eveningShiftBoolean'
                        value={data.eveningShiftBoolean}
                        onChange={handleInputChange}
                    />

                    <input
                        type='number'
                        name='eveningShiftValue'
                        placeholder='Number of Hours'
                        className='form-control form-control-lg form-control-solid '
                        value={data.eveningShiftValue}
                        disabled={!data.eveningShiftBoolean}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Total number of hours
                </label>
                <div className='col-lg-8 fv-row d-flex items-center'>
                    <input
                        type='text'
                        className='form-control form-control-md form-control-solid'
                        name='totalCalculatedHours'
                        value={data.totalCalculatedHours}
                        onChange={handleInputChange}
                        readOnly
                    />
                </div>
            </div>

            <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Notifications</label>

                <div className='col-lg-8 fv-row'>
                    <div className='d-flex align-items-center mt-3'>
                        <label className='form-check form-check-inline form-check-solid me-5'>
                            <input
                                className='form-check-input'
                                name='notification.email'
                                type='checkbox'
                                checked={data.notification.email}
                                onChange={() => handleNotificationChange('email')}
                            />
                            <span className='fw-bold ps-2 fs-6'>Email</span>
                        </label>

                        <label className='form-check form-check-inline form-check-solid'>
                            <input
                                className='form-check-input'
                                name='notification.phone'
                                type='checkbox'
                                checked={data.notification.phone}
                                onChange={() => handleNotificationChange('phone')}
                            />
                            <span className='fw-bold ps-2 fs-6'>Phone</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
