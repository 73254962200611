import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { getTransactionsByProjectId } from '../core/_requests'

const TransactionTable = () => {
  const id = useParams().id
  const [transactions, setTransactions] = useState<any[]>([])
  const fetchData = async () => {
    const res = await getTransactionsByProjectId(id)
    console.log(res)
    setTransactions(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const date = new Date(createdAt)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
  }

  return (
    <div className='card mt-5'>
      <div className='card-header'>
        <h4 className='card-title'>Transactions</h4>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction Details</th>
                <th>Type</th>
                <th>Debit</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item, index) => (
                <tr key={item?._id}>
                  <td>{formatCreatedAt(item?.createdAt)}</td>
                  <td>{item?.id}</td>
                  <td className='capitalize'>{item?.type}</td>
                  <td>{item?.debit !== 0 && item?.debit}</td>
                  <td>{item?.credit !== 0 && item?.credit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TransactionTable
