import React, { useEffect, useState } from 'react'
import { getEmployee } from '../../../sales/Core/_requests'
import { useAuth } from '../../../../modules/auth'
import { getEmployeeByStatus } from '../../../hrm/core/_requests'

export default function Card4({ data, setData }) {
    const { currentUser } = useAuth()
    const [employeeList, setEmployeeList] = useState([])
    const getEmployeeList = async () => {
        try {
            const queryString = 'search=&agent=&filter_status=true'
            console.log(queryString)
            const employees = await getEmployeeByStatus(queryString, currentUser?.organization)
            console.log('employee', employees)
            setEmployeeList(
                employees.filter(employee => employee.isActivated)
                .map((r) => ({
                  value: r?._id,
                  label: r?.firstName,
                  email: r?.email,
                  department: r?.department?.name,
                  dailyPay: r?.dailyPay,
                  hourlyPay: r?.hourlyPay,
                }))
            )
        } catch (error) {
            console.error('Error fetching employees:', error)
        }
    }

    useEffect(() => {
        getEmployeeList()
    }, [])


    const handleTeamMemberChange = (employeeId) => {
        if (employeeId) {
            const selectedEmployee = employeeList.find((employee) => employee.value === employeeId)
            if (selectedEmployee) {
                const alreadyAdded = data.team.some((member) => member.value === selectedEmployee.value)
                if (!alreadyAdded) {
                    setData((prevData) => ({
                        ...prevData,
                        team: [
                            ...prevData.team,
                            { 
                                value: selectedEmployee.value, 
                                label: selectedEmployee.label,
                                email: selectedEmployee.email,
                                department: selectedEmployee.department,
                                dailyPay: selectedEmployee.dailyPay,
                                hourlyPay: selectedEmployee.hourlyPay,
                            },
                        ],
                    }))
                }
            }
        }
    }

    const handleTeamMemberClick = (memberId) => {
        const isSelected = data.team.some((member) => member.value === memberId)
        let updatedTeam
        if (isSelected) {
            updatedTeam = data.team.filter((member) => member.value !== memberId)
        } else {
            const member = { value: memberId, label: memberId }
            updatedTeam = [...data.team, member]
        }
        setData({ ...data, team: updatedTeam })
    }

    return (
        <div className='card mb-5 mb-xl-10 px-9'>
            <div className='container-xxl'>
                <div className='card-header border-0 cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Build A Team</h3>
                    </div>
                </div>

                <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                        Team Members
                    </label>
                    <div className='col-lg-8 fv-row'>
                        <select
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Select team member'
                            value=''
                            onChange={(e) => handleTeamMemberChange(e.target.value)}
                        >
                            <option value=''>Add Team Member</option>
                            {employeeList.map((employee) => (
                                <option key={employee.value} value={employee.value}>
                                    {employee.label}
                                </option>
                            ))}
                        </select>

                        <div className='d-flex flex-row mt-2'>
                            {data?.team.map((member) => (
                                <div key={member.value} className='me-3'>
                                    <span
                                        className='badge badge-primary cursor-pointer'
                                        onClick={() => handleTeamMemberClick(member.value)}
                                    >
                                        {member.label}
                                        <span className='ms-2'>x</span>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
