import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import {getModules} from '../../../auth/core/_requests'

const Step3: FC = () => {
  const [modules, setModules] = useState([])

  useEffect(() => {
    const fetchModules = async () => {
      const res = await getModules()
      setModules(res)
    }

    fetchModules()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Select Modules</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='text-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div>
        <div className='fv-row'>
          <div className='row'>
            {modules?.map((module: any, index: number) => {
              return (
                <div className='col-lg-6' key={module?._id}>
                  <Field
                    type='checkbox'
                    className='btn-check'
                    name='modules'
                    value={module?._id}
                    id={`kt_create_account_form_module_${index}`}
                  />
                  <label
                    className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                    htmlFor={`kt_create_account_form_module_${index}`}
                  >
                    <span className='d-block fw-bold text-start'>
                      <span className='text-dark fw-bolder d-block fs-4 mb-2'>{module?.name}</span>
                    </span>
                  </label>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
