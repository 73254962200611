import {createSlice} from '@reduxjs/toolkit'

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    vehicleData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setVehicleData(state, action) {
      state.vehicleData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setVehicleData, setLoading, setError} = vehicleSlice.actions
export default vehicleSlice.reducer
