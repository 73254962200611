/* eslint-disable react/jsx-no-target-blank */
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import {useContext, useEffect, useState} from 'react'
import {SettingContext} from './Sidebar'

const SidebarFooter = () => {
  const {setting, setSetting} = useContext(SettingContext)

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <Link
        // navigate to setting if settnig is true, otherwise navigate to dashboard
        to={setting ? '/dashboards' : '/setting'}
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        onClick={() => setSetting(!setting)}
      >
        <span className='btn-label'>{setting ? 'Close Setting' : 'Account Setting'}</span>
        <KTIcon iconName='setting' className='btn-icon fs-2 m-0' />
      </Link>
    </div>
  )
}

export {SidebarFooter}
