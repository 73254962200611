import React, {useEffect, useState} from 'react'
import {getOrderDetailsById} from '../Core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

function OrderManagementHistory() {
  const [data, setdata] = useState<any>([])
  const orderId = useParams().id
  const {currentUser} = useAuth()

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  useEffect(() => {
    const getdata = async () => {
      await getOrderDetailsById(currentUser?.company, orderId).then((res) => {
        setdata(res)
        console.log(res)
      })
    }

    getdata()
  }, [orderId])

  return (
    <div className='card card-flush py-4 flex-row-fluid'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>Orders History</h2>
        </div>
        <div className='d-flex flex-row'>
          <div className='card-toolbar me-5'>
            <Link to={`/sales/quotetoorder/add/${orderId}`} className='btn btn-sm btn-light-info'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Quote
            </Link>
          </div>
          <div className='card-toolbar me-5'>
            <Link
              to={`/sales/salesorderstoorder/add/${orderId}`}
              className='btn btn-sm btn-light-warning'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add Sales Order
            </Link>
          </div>
          <div className='card-toolbar me-5'>
            <Link
              to={`/sales/deliverynotetoorder/add/${orderId}`}
              className='btn btn-sm btn-light-danger'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add Delivery Note
            </Link>
          </div>
          <div className='card-toolbar me-5'>
            <Link
              to={`/sales/invoicetoorder/add/${orderId}`}
              className='btn btn-sm btn-light-primary'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add Invoice
            </Link>
          </div>
          <div className='card-toolbar me-5'>
            <Link
              to={`/sales/paymentreceivedtoorder/add/${orderId}`}
              className='btn btn-sm btn-light-success'
            >
              <KTIcon iconName='plus' className='fs-2' />
              Add Payment Receipt
            </Link>
          </div>
        </div>
      </div>

      <div className='card-body pt-0'>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-100px'>Date Added</th>
                {/* <th className='min-w-175px'>Property</th> */}
                <th className='min-w-50px'>Bill Id</th>
                <th className='min-w-50px'>Bill Type</th>
                <th className='min-w-50px'>Propose Price</th>
                <th className='min-w-100px'>Action</th>
              </tr>
            </thead>

            <tbody className='fw-semibold text-gray-600'>
              {data?.map((item, index) => (
                <tr key={item?._id}>
                  <td>{formatCreatedAt(item.createdAt)}</td>
                  {/* <td>{item.inventory?.name}</td> */}
                  <td>{item?.id}</td>
                  <td>
                    <div
                      className={`capitalize badge ${
                        item?.type === 'quote'
                          ? 'badge-light-info'
                          : item?.type === 'invoice'
                          ? 'badge-light-primary'
                          : item?.type === 'salesorders'
                          ? 'badge-light-warning'
                          : item?.type === 'deliveryChallan'
                          ? 'badge-light-danger'
                          : 'badge-light-success'
                      }`}
                    >
                      {item?.type}
                    </div>
                  </td>

                  <td>{item?.total}</td>
                  <td>
                    <Link
                      to={
                        item?.type === 'quote'
                          ? `/sales/quotes/${item._id}`
                          : `/sales/${item.type}/${item._id}`
                      }
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                    >
                      <i className='fa-solid fa-folder-open'></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrderManagementHistory
