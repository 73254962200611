export interface SearchFilter {
  search?: string
  agent?: string
  filter?: Filtertype
}
export interface Filtertype {
  // status?: string
  isActive?: boolean
}

export interface Filtertype2 {
  isValid?: boolean
  status?: string
  approval?: string
}

export interface ValidFilter {
  filter?: Filtertype2
}
export const initialSearchFilter: SearchFilter = {
  search: '',
  agent: '',
  // filter: {
  //   status: 'Active',
  // },
  filter: {
    isActive: true,
  },
}
interface PurchaseItem {
  itemId: string
  price: string
  quantity: number
  discount: number
  amount: string
  _id: string
  productName: string
}

interface PurchaseQuotation {
  _id: string
  issuer: string
  employee?: string
  items: PurchaseItem[]
  id?: number | null
  date: string
  total: number
  subtotal: number
  tax: number
  notes: string
  lpo: string
  trn: string
  orderNo: string
  paid: boolean
  type: string
  status: string
  createdAt: string
  updatedAt: string
  __v: number
  reference?: string
  expectedDeliveryDate?: string | null
  paymentTerms?: string
  termsCondition?: string
  document?: string
}

export interface PurchaseOrder {
  purchaseQuotationIds: string[]
  vendorId: string
  totalPurchaseOrders: number
  purchaseQuotations: PurchaseQuotation[]
}

interface VendorState {
  vendorData: any[]
  loading: boolean
  error: null
}

interface orderManagementState {
  orderData: any[]
  loading: boolean
  error: null
}

interface rfqState {
  rfqData: any[]
  totalPages: number
  loading: boolean
  error: null
}

interface purchaseOrderState {
  purchaseOrderData: any[]
  totalPages: number
  loading: boolean
  error: null
}

interface purchaseReceiveState {
  purchaseReceiveData: any[]
  totalPages: number
  loading: boolean
  error: null
}

interface billsState {
  bills: any[]
  totalPages: number
  loading: boolean
  error: null
}

interface paymentMadeState {
  paymentMadeData: any[]
  totalPages: number
  loading: boolean
  error: null
}

interface procurementState {
  vendor: VendorState
  orderManagement: orderManagementState
  rfq: rfqState
  purchaseOrder: purchaseOrderState
  purchaseReceive: purchaseReceiveState
  bills: billsState
  paymentMade: paymentMadeState
}

export interface RootState {
  procurement: procurementState
}
