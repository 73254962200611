import React, {useState} from 'react'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth'

export default function RentalDetails({rentalType, setRentalType}) {
  const {currentUser} = useAuth()
  const [selectedOption, setSelectedOption] = useState(null)

  const rentalOptions = [
    {value: 'vehicle', label: 'Vehicle'},
    {value: 'equipment', label: 'Equipment'},
  ]

  return (
    <div class='card card-flush py-4 d-flex flex-column'>
      <div class='card-header '>
        <div class='card-title '>
          <h2>Rental Details</h2>
        </div>
      </div>

      <div class='card-body p-0 px-2 pb-2'>
        <label class='form-label'>Rental Type</label>

        <Select
          class='form-select mb-2'
          options={rentalOptions}
          // value={selectedOption}
          onChange={(selectedOption) => {
            // setSelectedOption(selectedOption)
            setRentalType(selectedOption.value)
          }}
        />
      </div>
    </div>
  )
}
