import React, {useEffect, useRef, useState} from 'react'

import {useAuth} from '../../modules/auth'
import {Col, Row, Card} from 'react-bootstrap'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, {Draggable} from '@fullcalendar/interaction'
import Swal from 'sweetalert2'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'

import {addevents, getevents, gettodo} from './core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
// import AgentList from './AgentList'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {getCustomer} from '../sales/Core/_requests'
function TodoList() {
  const {currentUser} = useAuth()
  const [todolist, settodolist] = useState([])
  const calendarComponentRef = useRef(null)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [title, settitle] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [assiopen, setassiopen] = useState(false)
  const [color, setcolor] = useState('')
  const [agentids, setagentids] = useState<string[]>([])
  const [property, setProperty] = useState('todo')
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [clientsList, setClientsList] = useState<any>([])

  const getClientList = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      setClientsList(
        res.map((r) => ({
          value: r?._id,
          label: r?.primaryContact?.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getClientListbyagent = async () => {
    try {
      const res = await getCustomer('', currentUser?.organization)
      setClientsList(
        res.map((r) => ({
          value: r?._id,
          label: r?.primaryContact?.firstName, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (currentUser?.profileType === 'superadmin' || currentUser?.profileType === 'admin') {
      getClientList()
    } else if (currentUser?.profileType === 'agent') {
      getClientListbyagent()
    }
  }, [])

  useEffect(() => {
    if (selectedOption === 'Important') {
      setcolor('#dc3545')
    } else if (selectedOption === 'Medium') {
      setcolor('#ffc107')
    } else {
      setcolor('#28a745')
    }
  }, [selectedOption])

  const getevent = async () => {
    try {
      const res = await gettodo(currentUser?._id)
      settodolist(res) // Assuming that res is an array
      console.log(res)
    } catch (error) {
      console.error('Error fetching todo list:', error)
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }
  useEffect(() => {
    // getClientList()
    // getevent()
  }, [])

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
  }

  const handletodosubmit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: 'todo',
      priority: selectedOption,
      color: color,
      agentids,
    }

    await addevents(todo, currentUser?._id).then((res) => {
      const updatevents = [...todolist, res]
      console.log(res)
      settitle('')
      setSelectedOption('')
      setcolor('')
      setSelectedDate(new Date())
      setagentids([])
      settodolist(updatevents)
      getevent()
      setProperty('')
      setselectedBillFrom(null)

      const closeModalButton = document.getElementById('closeModalButton')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className='card w-100 h-100 overflow-scroll'>
      <div className='card-header flex justify-between border-0   '>
        <h3 className='card-title fw-bold text-dark'>Todo</h3>
        <div className='card-toolbar gap-3'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_153'
          >
            <KTIcon iconName='plus' className='fs-2' />
          </button>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div id='external-events'>
          {todolist.map((event) => (
            <div title={event.title} key={event.id}>
              {event.type === 'meeting' ? (
                <Link
                  to={`/clients/clientDetails/${event.client?._id}`}
                  className='d-flex align-items-center mb-8'
                >
                  <span
                    className={`bullet bullet-vertical h-40px me-5`}
                    style={{backgroundColor: event.color}}
                  ></span>
                  {/* end::Bullet */}
                  {/* begin::Checkbox */}

                  {/* end::Checkbox */}
                  {/* begin::Description */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {event.title}
                    </a>
                    <span className='text-muted fw-semibold d-block'>{event.type}</span>
                  </div>
                  {/* end::Description */}
                  <span
                    className={`badge  fs-8 fw-bold ${
                      event.priority === 'Important'
                        ? 'badge-light-danger'
                        : event.priority === 'Medium'
                        ? 'badge-light-warning'
                        : 'badge-light-success'
                    }`}
                  >
                    {event.priority}
                  </span>
                </Link>
              ) : (
                <div className='d-flex align-items-center mb-8'>
                  <span
                    className={`bullet bullet-vertical h-40px me-5`}
                    style={{backgroundColor: event.color}}
                  ></span>

                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {event.title}
                    </a>
                    <span className='text-muted fw-semibold d-block'>{event.type}</span>
                  </div>
                  {/* end::Description */}
                  <span
                    className={`badge  fs-8 fw-bold ${
                      event.priority === 'Important'
                        ? 'badge-light-danger'
                        : event.priority === 'Medium'
                        ? 'badge-light-warning'
                        : 'badge-light-success'
                    }`}
                  >
                    {event.priority}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_153'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex overflow h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'task' ? ' active' : ''}`}
                      onClick={() => setProperty('task')}
                    >
                      Task
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6  
                  ${property === 'meeting' ? ' active' : ''}`}
                      onClick={() => setProperty('meeting')}
                    >
                      Meeting
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {!assiopen ? (
              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter title'
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat='MMMM d, yyyy'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Time</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat='h:mm aa'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                {property === 'meeting' && (
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label'>Select Client</label>
                    <Select
                      name='client'
                      aria-label='Select Client'
                      placeholder='Select client'
                      options={clientsList}
                      isSearchable={true}
                      onChange={handleBillFromChange}
                      value={selectedBillFrom}
                    />
                  </div>
                )}

                <div className='container mt-3'>
                  <label htmlFor='exampleSelect' className='form-label'>
                    Select Priority
                  </label>
                  <select
                    className='form-select'
                    id='exampleSelect'
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value=''>Select Priority</option>
                    <option value='Important'>Important</option>
                    <option value='Medium'>Medium</option>
                    <option value='Flexible'>Flexible</option>
                    {/* Add more options as needed */}
                  </select>
                </div>

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mt-5'
                  onClick={() => setassiopen(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Assign Agent
                </button>
              </div>
            ) : (
              <div className='modal-body'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mb-5'
                  onClick={() => setassiopen(false)}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </button>
                {/* <AgentList agentids={agentids} setagentids={setagentids} /> */}
              </div>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handletodosubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TodoList
