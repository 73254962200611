import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MyProjects from "./Pages/MyProjects";
import { AddProject } from './components/AddProject';
import ProjectDetails from './Pages/ProjectDetails';
import UpdateProject from './components/UpdateProject';
import ProjectCalendar from './Pages/ProjectCalendar';
import ProjectDocument from './Pages/ProjectDocument';
import { FilesPage } from './components/FilesPage';
import ProjectGanttChart from './Pages/ProjectGanttChart';
import ProjectPerformance from './Pages/ProjectPerformance';
import AddSalesOrder from './components/AddSalesOrder';
import AddPurchaseOrder from './components/AddPurchaseOrder';
import ProjectReportSubmission from './components/ProjectReportSubmission'
import AddProjectReportSubmission from './Pages/AddProjectReportSubmission'
import SubmissionsView from './Pages/SubmissionsView'
import SubmissionDetails from './Pages/SubmissionDetails'
import CategorySummary from './components/CategorySummary';
import IndividualSummary from './components/IndividualSummary';
import CategorySummaryInvoice from './components/CategorySummaryInvoice';
import IndividualSummaryInvoice from './components/IndividualSummaryInvoice';

export default function ProjectsPage() {
  return (
    <Routes>
      <Route path='/projectlist' element={<MyProjects />} />
      <Route path='/projectlist/add' element={<AddProject />} />
      <Route path='/projectlist/details/:id' element={<ProjectDetails />} />
      <Route path='edit/:id' element={<UpdateProject />} />
      <Route path='/calendar/calendar' element={<ProjectCalendar />} />
      <Route path='/calendar/gantt-chart' element={<ProjectGanttChart />} />
      <Route path='/documents' element={<ProjectDocument />} />
      <Route path='/documents/:id' element={<FilesPage />} />
      <Route path='/performance' element={<ProjectPerformance />} />
      <Route path='/add-salesorder/:id' element={<AddSalesOrder />} />
      <Route path='/add-purchaseorder/:id' element={<AddPurchaseOrder />} />
      <Route path='/reports' element={<ProjectReportSubmission />} />
      <Route path='/reports/add' element={<AddProjectReportSubmission />} />
      <Route path='/reports/viewforms/:id' element={<SubmissionsView />} />
      <Route path='/reports/viewDetails/:id' element={<SubmissionDetails />} />
      <Route path='/category-summary/:id' element={<CategorySummary />} />
      <Route path='/category-summary/invoice/:id' element={<CategorySummaryInvoice />} />
      <Route path='/individual-summary/:id' element={<IndividualSummary />} />
      <Route path='/individual-summary/invoice/:id' element={<IndividualSummaryInvoice />} />



    </Routes>
  )
}

