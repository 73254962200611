import React from 'react'
import {Route, Routes} from 'react-router-dom'
import AddItem from '../Components/items/AddItem'
import ItemList from '../Components/items/ItemList'
import ItemDetail from '../Components/items/ItemDetail'
import EditItem from '../Components/items/EditItem'

const ItemsPage = () => {
  return (
    <Routes>
      <Route path='/' element={<ItemList />} />
      <Route path='/add' element={<AddItem />} />
      <Route path='/:id' element={<ItemDetail />} />
      <Route path='/edit/:id' element={<EditItem />} />
    </Routes>
  )
}

export default ItemsPage

