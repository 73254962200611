import React, {FC} from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import DateRangePicker from '../../../../../../_metronic/partials/widgets/_new/datePicker/rangeDatePicker'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Leave</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Employee Id</label>

        <Field
            as='select'
            name='employeeId'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='1'>S Corporation</option>
            <option value='1'>C Corporation</option>
            <option value='2'>Sole Proprietorship</option>
            <option value='3'>Non-profit</option>
            <option value='4'>Limited Liability</option>
            <option value='5'>General Partnership</option>
          </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='employeeId' />
        </div>
      </div>
      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Leave Type</label>

        <Field
            as='select'
            name='leaveType'
            className='form-select form-select-lg form-select-solid'
          >
            <option></option>
            <option value='1'>S Corporation</option>
            <option value='1'>C Corporation</option>
            <option value='2'>Sole Proprietorship</option>
            <option value='3'>Non-profit</option>
            <option value='4'>Limited Liability</option>
            <option value='5'>General Partnership</option>
          </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='leaveType' />
        </div>
      </div>
      <div className='mb-10 fv-row '>
        <label className='form-label required mb-3'>Date</label>
        <Field
          as='date'
          name='dateOfLeave'
          component = {DateRangePicker}
        />
        
        <div className='text-danger mt-2'>
          <ErrorMessage name='dateOfLeave' />
        </div>
      </div>
      <div className='mb-10 fv-row '>
        <label className='form-label required mb-3'>Team Email Id</label>

        <Field
          type='email'
          className='form-control form-control-lg form-control-solid'
          name='teamEmail'
          placeholder='abc@gmail.com'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='teamEmail' />
        </div>
      </div>
      <div className='mb-10 fv-row '>
        <label className='form-label required mb-3'>Reason for leave</label>

        <Field
          as='textarea'
          className='form-control form-control-lg form-control-solid'
          name='leaveReason'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='leaveReason' />
        </div>
      </div>
    </div>
  )
}

export {Step1}
