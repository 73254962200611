import {createSlice} from '@reduxjs/toolkit'

const inventoryMaintenanceSlice = createSlice({
  name: 'inventoryMaintenance',
  initialState: {
    inventoryMaintenanceData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setInventoryMaintenanceData(state, action) {
      state.inventoryMaintenanceData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setInventoryMaintenanceData, setLoading, setError} = inventoryMaintenanceSlice.actions
export default inventoryMaintenanceSlice.reducer
