import {combineReducers} from '@reduxjs/toolkit'

import vendorReducer from './vendor/vendorSlice'
import vendorPerformanceReducer from './vendorPerformance/vendorPerformanceSlice'
import orderManagementReducer from './orderManagement/orderManagementSlice'
import rfqReducer from './rfq/rfqSlice'
import purchaseOrderReducer from './purchaseOrder/purchaseOrderSlice'
import purchaseReceiveReducer from './purchaseReceive/purchaseReceiveSlice'
import billsReducer from './bills/billsSlice'
import paymentMadeReducer from './paymentMade/paymentMadeSlice'

const rootReducer = combineReducers({
  vendor: vendorReducer,
  vendorPerformance: vendorPerformanceReducer,
  orderManagement: orderManagementReducer,
  rfq: rfqReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseReceive: purchaseReceiveReducer,
  bills: billsReducer,
  paymentMade: paymentMadeReducer,
})

export default rootReducer
