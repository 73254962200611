import React from 'react'
import {postCustomerById} from '../Core/_requests'
import {useParams} from 'react-router-dom'

export default function EditContact({formValues, setFormValues, handleReset}) {
  const {customerId} = useParams()

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }))
  }
  
  const handleNameChange = (e) => {
    const {name, value} = e.target
    setFormValues((prevValues) => ({
      primaryContact: {
        ...prevValues.primaryContact,
        [name]: value,
      },
    }))
  }

  const handlePhoneChange = (e) => {
    const {name, value} = e.target
    setFormValues((prevValues) => ({
      contactNumbers: {
        ...prevValues.contactNumbers,
        [name]: value,
      },
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      console.log('formValues', formValues)
      await postCustomerById(formValues, customerId).then((response) => {
        handleReset()
        const closeModalButton = document.getElementById('closeModalButton')
        if (closeModalButton) {
          closeModalButton.click()
        }
      })
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='mb-3 d-flex items-center'>
          <label className='form-label w-1/4'>Name</label>
          <div className='d-flex space-x-4'>
            <select
              className='form-select form-control form-control form-control-solid'
              name='salutation'
              value={formValues?.primaryContact?.salutation}
              onChange={handleNameChange}
            >
              <option value='Mr'>Mr</option>
              <option value='Mrs'>Mrs</option>
              <option value='Ms'>Ms</option>
            </select>
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='firstName'
              value={formValues?.primaryContact?.firstName}
              onChange={handleNameChange}
              placeholder='First Name'
            />
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='lastName'
              value={formValues?.primaryContact?.lastName}
              onChange={handleNameChange}
              placeholder='Last Name'
            />
          </div>
        </div>

        <div className='mb-3 d-flex items-center'>
          <label className='form-label required w-1/4 '>Customer Email</label>
          <input
            type='email'
            className='form-control form-control-solid '
            name='emailAddress'
            value={formValues?.emailAddress}
            onChange={handleInputChange}
            placeholder='Email Address'
          />
        </div>

        <div className='mb-3 d-flex items-center'>
          <label className='form-label w-1/4'>Customer Phone</label>
          <div className='d-flex space-x-4'>
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='workPhone'
              value={formValues?.contactNumbers?.workPhone}
              onChange={handlePhoneChange}
              placeholder='Work'
            />
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='mobilePhone'
              value={formValues?.contactNumbers?.mobilePhone}
              onChange={handlePhoneChange}
              placeholder='Mobile'
            />
          </div>
        </div>

        <div className='mb-3 d-flex items-center'>
          <label className='form-label w-1/4'>Other details</label>
          <div className='d-flex space-x-4'>
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='designation'
              value={formValues?.designation}
              onChange={handleInputChange}
              placeholder='Designation'
            />
            <input
              type='text'
              className='form-control form-control form-control form-control-solid'
              name='department'
              value={formValues?.department}
              onChange={handleInputChange}
              placeholder='Department'
            />
          </div>
        </div>

        <div class='modal-footer'>
          <button
            type='button'
            class='btn btn-secondary'
            data-bs-dismiss='modal'
            id='closeModalButton'
          >
            Close
          </button>
          <button type='submit' onClick={handleSubmit} class='btn btn-primary'>
            Save changes
          </button>
        </div>
      </form>
    </div>
  )
}
