import React from 'react'
import {useParams} from 'react-router-dom'
import {postCustomerById} from '../Core/_requests'

export default function ShippingAddress({shippingAddress, setShippingAddress, handleReset}) {
  const {customerId} = useParams()

  const handleShippingInputChange = (e) => {
    const {name, value} = e.target
    setShippingAddress({...shippingAddress, [name]: value})
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const dataToSend = {
        shippingAddress: {
          ...shippingAddress,
        },
      }

      await postCustomerById(dataToSend, customerId).then((response) => {
        handleReset()
        const closeModalButton = document.getElementById('closeModalButton')
        if (closeModalButton) {
          closeModalButton.click()
        }
      })
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }

  return (
    <div>
      <form>
        <div className='mb-3'>
          <label className='form-label'>Attention</label>
          <input
            type='text'
            className='form-control'
            name='attention'
            value={shippingAddress.attention}
            onChange={handleShippingInputChange}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>Country / Region</label>
          <input
            type='text'
            className='form-control'
            name='country'
            value={shippingAddress.country}
            onChange={handleShippingInputChange}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>Address</label>
          <input
            type='text'
            className='form-control'
            name='addressLine1'
            value={shippingAddress.addressLine1}
            onChange={handleShippingInputChange}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>Street 2</label>
          <input
            type='text'
            className='form-control'
            name='addressLine2'
            value={shippingAddress.addressLine2}
            onChange={handleShippingInputChange}
          />
        </div>

        <div className='mb-3'>
          <label className='form-label'>City</label>
          <input
            type='text'
            className='form-control'
            name='city'
            value={shippingAddress.city}
            onChange={handleShippingInputChange}
          />
        </div>

        <div className='d-flex space-x-2'>
          <div className='mb-3'>
            <label className='form-label'>State</label>
            <input
              type='text'
              className='form-control'
              name='state'
              value={shippingAddress.state}
              onChange={handleShippingInputChange}
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>Zip Code</label>
            <input
              type='text'
              className='form-control'
              name='zipCode'
              value={shippingAddress.zipCode}
              onChange={handleShippingInputChange}
            />
          </div>
        </div>

        <div className='d-flex space-x-2'>
          <div className='mb-3'>
            <label className='form-label'>Phone</label>
            <input
              type='text'
              className='form-control'
              name='phone'
              value={shippingAddress.phone}
              onChange={handleShippingInputChange}
            />
          </div>

          <div className='mb-3'>
            <label className='form-label'>Fax Number</label>
            <input
              type='text'
              className='form-control'
              name='faxNumber'
              value={shippingAddress.faxNumber}
              onChange={handleShippingInputChange}
            />
          </div>
        </div>

        <div class='modal-footer'>
          <button
            type='button'
            class='btn btn-secondary'
            data-bs-dismiss='modal'
            id='closeModalButton'
          >
            Close
          </button>
          <button type='submit' onClick={handleSubmit} class='btn btn-primary'>
            Save changes
          </button>
        </div>
      </form>
    </div>
  )
}
