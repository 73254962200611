import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {QuoteApproval} from '../Components/approvals/QuoteApproval'
import {InvoiceApproval} from '../Components/approvals/InvoiceApproval'
import {SalesOrderApproval} from '../Components/approvals/SalesOrderApproval'
import {DeliveryNoteApproval} from '../Components/approvals/DeliveryNoteApproval'
import {PaymentReceivedApproval} from '../Components/approvals/PaymentReceivedApproval'

const ApprovalPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route path='quotes' element={<QuoteApproval />} />
      <Route path='invoice' element={<InvoiceApproval />} />
      <Route path='salesorders' element={<SalesOrderApproval />} />
      <Route path='deliveryChallan' element={<DeliveryNoteApproval />} />
      <Route path='paymentReceived' element={<PaymentReceivedApproval />} />
      <Route index element={<Navigate to='quotes' />} />
    </Routes>
  )
}

export default ApprovalPage
