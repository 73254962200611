import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../print.css'
import {getRFQById} from '../../Core/_requests'
import {GoGlobe} from 'react-icons/go'
import {MdOutlineMailOutline} from 'react-icons/md'

function RFQ({companyLogo, color, setColor, minFooter}) {
  const NUMBERS = new Map([
    [1e9, 'Billion'],
    [1e6, 'Million'],
    [1e3, 'Thousand'],
    [1e2, 'Hundred'],
    [90, 'Ninety'],
    [80, 'Eighty'],
    [70, 'Seventy'],
    [60, 'Sixty'],
    [50, 'Fifty'],
    [40, 'Forty'],
    [30, 'Thirty'],
    [20, 'Twenty'],
    [19, 'Nineteen'],
    [18, 'Eighteen'],
    [17, 'Seventeen'],
    [16, 'Sixteen'],
    [15, 'Fifteen'],
    [14, 'Fourteen'],
    [13, 'Thirteen'],
    [12, 'Twelve'],
    [11, 'Eleven'],
    [10, 'Ten'],
    [9, 'Nine'],
    [8, 'Eight'],
    [7, 'Seven'],
    [6, 'Six'],
    [5, 'Five'],
    [4, 'Four'],
    [3, 'Three'],
    [2, 'Two'],
    [1, 'One'],
    [0, 'Zero'],
  ])

  const numberToWords = (num) => {
    for (const [intValue, textValue] of NUMBERS as any) {
      if (num >= intValue) {
        const prefix = num >= 100 ? numberToWords(Math.trunc(num / intValue)) : ''
        const suffix = num % intValue > 0 ? numberToWords(num % intValue) : ''
        return `${prefix} ${textValue} ${suffix}`.trim()
      }
    }
    return ''
  }

  const {id} = useParams()
  const [data, setRFQData] = useState<any>()
  const [contactPerson, setContactPerson] = useState<any>({})
  const [imageSize, setImageSize] = useState<any>(false)
  const [page, setPage] = useState(1)

  const handleOnLoad = (e) => {
    const img = e.target
    if (img.naturalWidth > img.naturalHeight) {
      setImageSize(true)
    } else {
      setImageSize(false)
    }
  }

  const getData = async () => {
    try {
      const res = await getRFQById(id)
      console.log('res', res)
      setRFQData(res)
      setColor(res?.company?.procurementColor)
      if (res?.items?.length > 9) {
        const pages = Math.ceil((res?.items.length - 9) / 20)
        if ((res?.items.length - 10) / 20 > 16) {
          setPage(pages + 2)
        } else {
          setPage(pages + 1)
        }
      }
      if (res?.contactPerson !== '') {
        setContactPerson(
          res?.vendor?.contactPersons?.find((person) => person?._id === res?.contactPerson) || {}
        )
      } else {
        setContactPerson(res?.vendor?.primaryContact)
      }
    } catch (error) {
      console.error('Axios error:', error)
      throw error
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const PF = 'https://realestify-assets.s3.me-central-1.amazonaws.com/'

  const PageContent = Array.from({length: page}).map((_, idx) => (
    <div
      key={idx}
      id={`pdf-content-${idx}`}
      className='card overflow-hidden mx-auto !rounded-none'
      style={{
        height: '297mm',
        width: '210mm',
      }}
    >
      {/* begin::Body */}
      <div id='pdfHeader' className='p-2 mx-3 mt-2'>
        <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
          {/*end::Logo*/}
          <div className='text-sm-end'>
            {/*begin::Logo*/}
            <a href='#' className={`d-block ${imageSize ? 'mw-275px' : 'mw-150px'} ms-sm-auto`}>
              {companyLogo ? (
                <img
                  alt='Logo'
                  src={URL.createObjectURL(companyLogo)}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              ) : (
                <img
                  alt='thumbnail'
                  src={PF + data?.company?.companyLogo}
                  className={imageSize ? 'w-100' : 'w-50'}
                  onLoad={handleOnLoad}
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          <h4 className='fw-bold text-gray-800 xl:fs-1 d-flex flex-column text-right header-text'>
            <span
              className='mb-1 text-right fs-1'
              style={{
                // fontFamily: 'Rubik',
                fontFamily: 'Scheherazade New',
                fontOpticalSizing: 'auto',
                fontStyle: 'normal',
                wordSpacing: '10px',
              }}
            >
              {data?.company?.arabicName}
            </span>
            <span
              className='mb-1 text-right fs-2 tracking-wider'
              style={{
                color: color,
              }}
            >
              {data?.company?.name}
            </span>
            <span className='fs-5 text-muted'>
              VAT {data?.company?.vat} <span style={{color: color}}>|</span> C.R.{' '}
              {data?.company?.cr}
            </span>
          </h4>
        </div>
      </div>
      <div className='border-b-black border-b mx-9'></div>
      <div className='card-body px-lg-15'>
        {/* begin::Layout */}

        <div className='d-flex flex-column '>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-xl-0'>
            {/* begin::Invoice 1 content */}
            <div className='mt-n1'>
              <div className=''>
                {/*begin::Top*/}

                {idx === 0 && (
                  <div className='mb-6 fs-5 fw-bold text-gray-800 uppercase'>
                    Request for Quotation
                  </div>
                )}
                {/*end::Top*/}
                {/*begin::Wrapper*/}
                <div className='m-0'>
                  {idx === 0 && (
                    <div
                      className='d-flex justify-content-between flex-column flex-sm-row fs-7'
                      style={{
                        textTransform: 'uppercase',
                      }}
                    >
                      <div className=' mb-5'>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>RFQ NO</span>
                          <span className=''>{data?.id}</span>
                        </div>
                        <div className='d-flex flex-column mb-5'>
                          <span className='fw-bold'>Customer</span>

                          <span className=''>{data?.vendor?.companyName}</span>
                        </div>
                        <div className='d-flex flex-md-root flex-column mb-5'>
                          <span className='fw-bold mb-1'>Customer Address</span>
                          <span className=' mw-200px'>
                            {data?.vendor?.billingAddress?.addressLine1}{' '}
                            {data?.vendor?.billingAddress?.addressLine2}
                            <br />
                            {data?.vendor?.billingAddress?.city}{' '}
                            {data?.vendor?.billingAddress?.postalCode}
                            {/* <br /> */}
                            {`\n`}
                            {data?.vendor?.billingAddress?.country}
                          </span>
                        </div>
                      </div>
                      <div className='  mb-5 '>
                        <div className='d-flex  flex-column mb-5'>
                          <span className='fw-bold'>Attention</span>
                          <span className=''>
                            {contactPerson?.salutation} {contactPerson?.firstName}{' '}
                            {contactPerson?.lastName}
                          </span>
                        </div>
                        <div className=' d-flex flex-column mb-5'>
                          <span className='fw-bold'>Designation</span>
                          <span className=''>{contactPerson?.designation}</span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Email ID</span>
                          <span className=''>{contactPerson?.emailAddress}</span>
                        </div>
                      </div>
                      <div className='text-end  mb-5 '>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Vendor ID</span>

                          <span className=''>
                            <br />
                          </span>
                        </div>
                        <div className=' d-flex flex-column  '>
                          <span className='fw-bold'>Contact Number</span>

                          <span className=''>{data?.vendor?.contactNumbers?.mobilePhone}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*end::Row*/}
                  {/*begin::Content*/}
                  <div className='flex-grow-1'>
                    {/*begin::Table*/}
                    <div className='table-responsive'>
                      <table className='table table-bordered m-0 border-gray-700'>
                        <thead>
                          <tr
                            className='fs-7 fw-bold uppercase'
                            style={{
                              backgroundColor: color,
                            }}
                          >
                            <th className='min-w-50px text-white text-center pb-3 '>
                              <span className='arabic-font'>رقم</span>
                              <br />
                              <span>S. No.</span>
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>وصف</span>
                              <br />
                              Item
                            </th>
                            <th className='min-w-70px text-white text-center pb-3'>
                              <span className='arabic-font'>كمية</span>
                              <br />
                              Quantity
                            </th>
                            <th className='min-w-80px text-white text-center pb-3'>
                              <span className='arabic-font'>وحدة قياس</span>
                              <br />
                              UOM
                            </th>
                          </tr>
                        </thead>
                        <tbody className=''>
                          {data?.items
                            ?.slice(
                              idx === 1 || idx === 0 ? idx * 13 : idx * 20 - 7,
                              idx === 0 ? 13 : idx * 20 + 13
                            )
                            .map((item, index) => (
                              <tr
                                key={index}
                                className='fw-semibold text-gray-600 fs-8 text-center'
                              >
                                <td className=' text-center'>
                                  {idx === 0
                                    ? index + 1
                                    : idx === 1
                                    ? index + 14
                                    : index + idx * 20 - 6}
                                </td>
                                <td className='text-justify'>{item?.productName}</td>
                                <td className=''>{item.quantity}</td>
                                <td className='uppercase'>
                                  {item?.unit ||
                                    item?.itemId?.unit ||
                                    item?.itemsId?.unit ||
                                    'units'}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/*end::Table*/}
                    {/*begin::Container*/}
                    {/*end::Container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>

              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          {(idx + 1 === page || page === 1) && (
            <div className={`m-0 p-2 mt-5`}>
              <div className='d-flex  flex-column fs-6'>
                <h6 className=' fw-bold text-danger'>Note</h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.notes?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
                <h6 className=' fw-bold text-danger'>Terms</h6>
                <p
                  className='min-h-40px'
                  style={{
                    lineHeight: '8px',
                  }}
                >
                  {data?.termsNCondition?.split('\n').map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </p>
              </div>
              {/* end::Invoice 2 sidebar */}
            </div>
          )}
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
      <div id='pdfFooter' className='card-footer text-center py-2 px-0 fw-bold mb-1'>
        <div className={`bg-[#515560] ${!minFooter && '-skew-x-12'} text-white`}>
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            {!minFooter &&
              `Mobile :${' '}
        ${data?.company?.mobileNumber} | `}
            <MdOutlineMailOutline
              style={{
                color,
              }}
              className='inline mr-2'
            />
            {data?.company?.companyEmail}
            <GoGlobe
              className='inline mr-2 ml-4'
              style={{
                color,
              }}
            />
            {data?.company?.webURL}
          </div>
        </div>
        <div
          className={`${!minFooter && '-skew-x-12'} text-white`}
          style={{
            backgroundColor: `${color}`,
          }}
        >
          <div className={`${!minFooter && 'skew-x-12'} py-1`}>
            P.O. Box : {data?.company?.pOBox} | Address: {data?.company?.companyAddress}
          </div>
        </div>
      </div>
      <div className='d-flex justify-center'>
        <p className='fs-7 mb-1'>
          Page {idx + 1} of {page}
        </p>
      </div>
    </div>
  ))

  return (
    <>
      <div className='app-content flex-column-fluid '>
        {/* begin::Content container */}

        <div id='kt_app_content_container' className='print-content container-xxl serif-font'>
          {/* begin::Invoice 2 main */}
          {PageContent}
          {/* end::Invoice 2 main */}
        </div>
        {/* end::Content container */}
      </div>
    </>
  )
}

export default RFQ
