import React from 'react'

interface PaginationProps {
  totalPages: number
  currentPage: number
  setCurrentPage: (currentPage: number) => void
}

const Pagination: React.FC<PaginationProps> = ({totalPages, currentPage, setCurrentPage}) => {
  return (
    <div className='d-flex justify-content-center my-4'>
      <nav>
        <ul className='pagination'>
          {Array.from({length: totalPages}).map((_, index) => (
            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button onClick={() => setCurrentPage(index + 1)} className='page-link'>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
