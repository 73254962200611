import {createSlice} from '@reduxjs/toolkit'

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    userManagementData: [],
    loading: false,
    error: null,
  },
  reducers: {
    setUserManagementData(state, action) {
      state.userManagementData = action.payload
      state.loading = false
      state.error = null
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setUserManagementData, setLoading, setError} = userManagementSlice.actions
export default userManagementSlice.reducer
