import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {Link} from 'react-router-dom'
import {getMaintenanceDocs} from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setDocumentMaintenanceData} from '../../../redux/maintenance/documents/documentMaintenanceSlice'

const Document = () => {
  const {currentUser} = useAuth()
  // const [data, setData] = useState<any>()
  const dispatch = useDispatch()
  const data = useSelector(
    (state: any) => state.maintenance.documentMaintenance.documentMaintenanceData
  )

  const fetchData = async () => {
    const res = await getMaintenanceDocs(currentUser?.organization)
    console.log(res)
    // setData(res)
    dispatch(setDocumentMaintenanceData(res))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    // format date in dd/mm/yyyy 20/03/2024
    const date = new Date(createdAt)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const formattedDate = `${day}/${month}/${year}`
    return formattedDate
  }

  return (
    // <div className='container'>
    <div className='card'>
      <div className='card-header d-flex align-items-center'>
        <h3>Documents</h3>
      </div>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 rounded-start'>Name</th>
                <th>Total Docs</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link
                    to={`vehicle`}
                    className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                  >
                    Vehicle
                  </Link>
                </td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                  {data?.vehicle || 0}
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    to={`equipment`}
                    className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                  >
                    Equipment
                  </Link>
                </td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                  {data?.equipment || 0}
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    to={`maintenance`}
                    className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                  >
                    Maintenance
                  </Link>
                </td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                  {data?.maintenance || 0}
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    to={`service`}
                    className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                  >
                    Service
                  </Link>
                </td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                  {data?.service || 0}
                </td>
              </tr>
              <tr>
                <td>
                  <Link
                    to={`inventory`}
                    className='ps-4 text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'
                  >
                    Inventory
                  </Link>
                </td>
                <td className='text-dark fw-bold text-hover-primary mb-1 fs-6 capitalize'>
                  {data?.inventory || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    // </div>
  )
}

export default Document
