import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useAuth} from '../../../../modules/auth'
import {getVehicleReminders} from '../../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {setVehicleRenewalData} from '../../../../redux/maintenance/reminders/vehicleRenenwal/vehicleRenewalSlice'

const VehicleRenewal = () => {
  const {currentUser} = useAuth()
  // const [reminderList, setReminderList] = useState([])
  const dispatch = useDispatch()
  const reminderList = useSelector(
    (state: any) => state.maintenance.vehicleRenewal.vehicleRenewalData
  )

  const fetchReminder = async () => {
    const res = await getVehicleReminders(currentUser.organization)
    console.log(res)
    // setReminderList(res)
    dispatch(setVehicleRenewalData(res))
  }

  useEffect(() => {
    fetchReminder()
  }, [])

  const formatDate = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center justify-between'>
          <h3>Vehicle Renewal</h3>
          <Link to='add' className='btn btn-primary'>
            <KTIcon iconName='plus' className='me-2' />
            Add Reminder
          </Link>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead className='bg-gray-200'>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-2'>Vehicle</th>
                  <th>Renewal Type</th>
                  <th>Status</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                {reminderList?.map((item: any) => (
                  <tr key={item?._id}>
                    <td className='ps-2'>{item?.vehicle?.productName}</td>
                    <td className='uppercase'>{item?.renewalType}</td>
                    <td>{new Date(item?.dueDate) < new Date() ? 'Overdue' : 'Upcoming'}</td>
                    <td>{formatDate(new Date(item?.dueDate))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleRenewal
