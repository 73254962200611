import React, {useEffect, useRef, useMemo} from 'react'
import ApexCharts from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'

type Props = {
  className?: string
  value: number[]
  labels: string[]
}

const TopExpenseChart: React.FC<Props> = ({className = '', value, labels}) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const {mode} = useThemeMode()

  const chartOptions = useMemo(() => getChartOptions(value, labels), [value, labels])

  useEffect(() => {
    if (!chartRef.current) return

    const chart = new ApexCharts(chartRef.current, chartOptions)
    chart.render()

    return () => {
      chart.destroy()
    }
  }, [chartOptions, mode])

  return (
    <div className={`card ${className} h-100`}>
      <div className='card-header border-0 pt-5 justify-content-start'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top Expenses</span>
        </h3>
      </div>
      <div className='card-body'>
        <div ref={chartRef} style={{height: '250px'}} />
      </div>
    </div>
  )
}

const getChartOptions = (value: number[], labels: string[]): ApexCharts.ApexOptions => ({
  series: value,
  chart: {
    fontFamily: 'inherit',
    type: 'donut',
    height: 350,
    toolbar: {show: true},
  },
  plotOptions: {
    pie: {
      donut: {
        size: '55%',
        labels: {show: true},
      },
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    horizontalAlign: 'left',
    formatter: (legendName, opts) => {
      const seriesIndex = opts?.seriesIndex ?? 0
      const percent = opts?.w.globals.seriesPercent[seriesIndex]?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
      return `${legendName} ${opts?.w.globals.series[seriesIndex]} - ${percent}%`
    },
    width: 500,
  },
  dataLabels: {enabled: false},
  fill: {type: 'solid', opacity: 1},
  stroke: {colors: ['white'], width: 6},
  labels,
  colors: ['#E57373', '#F06292', '#64B5F6', '#4DB6AC', '#FFD54F'],
  grid: {
    borderColor: getCSSVariableValue('--bs-gray-200'),
    strokeDashArray: 10,
    padding: {left: 5, right: 5},
  },
  states: {
    normal: {filter: {type: 'none', value: 0}},
    hover: {filter: {type: 'none', value: 0}},
    active: {
      allowMultipleDataPointsSelection: true,
      filter: {type: 'none', value: 0},
    },
  },
  tooltip: {style: {fontSize: '12px'}, y: {}},
})

export {TopExpenseChart}
