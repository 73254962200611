import { ErrorMessage, Field, useFormikContext } from "formik"
import { FC, useEffect } from "react"
import { KTIcon } from "../../../../../_metronic/helpers/components/KTIcon"
import { getProjectById } from "../../core/_requests";
import { useParams } from "react-router-dom";

const ProjectType: FC = () => {

  const {initialValues} = useFormikContext()
  console.log("see",initialValues)
  const handleDateChange = (date,form) => {
    // Update the form values with the new date
    form.setFieldValue('releaseDate', date);
  };



  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Choose Project Type
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Project Settings is based on your selected Project type'
          ></i>
        </h2>

        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='projectType'
              value='personal'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTIcon iconName='address-book' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Personal Project </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {/* Create Personal Project */}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field 
              type='radio'
              className='btn-check'
              name='projectType'
              value='Team'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTIcon iconName='briefcase' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Team Project</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {/* Create Team Project */}
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='projectType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {ProjectType}