import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  purchaseOrderData: [],
  loading: false,
  totalPages: 1,
  error: null,
  fetch: true,
}

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    setPurchaseOrderDataList(state, action) {
      state.purchaseOrderData = action.payload.purchaseOrders
      state.totalPages = action.payload.totalPages
      state.loading = false
      state.error = null
      state.fetch = false
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setPurchaseOrderDataFetch(state) {
      state.fetch = true
    },
  },
})

export const {setPurchaseOrderDataList, setLoading, setError, setPurchaseOrderDataFetch} =
  purchaseOrderSlice.actions
export default purchaseOrderSlice.reducer
