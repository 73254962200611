import axios, {AxiosResponse} from 'axios'
import {Docs} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DOCS_URL = `${API_URL}/docs`
const ADD_IMAGES_URL = `${API_URL}/images`

export async function addDocs(formData: Docs) {
  try {
    const response: AxiosResponse<Docs> = await axios.post(`${DOCS_URL}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Company error:', error)
    throw error
  }
}

export const getDocs = async (query: string) => {
  try {
    const response: AxiosResponse<Docs[]> = await axios.get<Array<Docs>>(`${DOCS_URL}?${query}`)
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const editDocs = async (id: string, data: Docs) => {
  try {
    await axios.put<Array<Docs>>(`${DOCS_URL}/${id}`, data)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteDocs = async (id: string) => {
  try {
    await axios.delete<Array<Docs>>(`${DOCS_URL}/${id}`)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function postImages(imageData: any) {
  try {
    const response = await axios.post(ADD_IMAGES_URL, imageData)
    console.log('image uploaded to server')
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add image error:', error)
    throw error
  }
}
