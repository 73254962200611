import React, {useEffect, useState} from 'react'
import {usePageData} from '../../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {getLeaveDetailByID} from '../../core/_requests'

const EmployeeLeaveDetails = () => {
  const {id} = useParams()
  const [leaveDetails, setLeaveDetails] = useState()
  const fetchData = async () => {
    try {
      const response = await getLeaveDetailByID(id)
      setLeaveDetails(response)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      <h2>Leave Data - {id}</h2>
      <pre>{JSON.stringify(leaveDetails, null, 2)}</pre>
    </div>
  )
}

export default EmployeeLeaveDetails
