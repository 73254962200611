import React, {useEffect, useState} from 'react'
import {FieldArray, Field, useFormikContext, useField} from 'formik'
import Select from 'react-select'
import {useAuth} from '../../../../modules/auth'
import {getUsersByOrgId} from '../../../sales/Core/_requests'

export default function AssignUser() {
  const {currentUser} = useAuth()
  const {setFieldValue} = useFormikContext()
  const {values} = useFormikContext()
  console.log('values', values)
  const [agentList, setAgentList] = useState([])

  const handleAgentChange = (selectedOptions) => {
    const selectedAgents = selectedOptions.map((option) => option.value)
    setFieldValue('assignedAgents', selectedAgents)
  }

  const getAgents = async () => {
    try {
      const res = await getUsersByOrgId(currentUser?.organization)
      console.log(res)
      setAgentList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName,
          email: r.email,
        }))
      )
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }
  useEffect(() => {
    getAgents()
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-12 '>
        <h1 className='fw-bold text-dark'>Assign Users to the Project</h1>

        <div className='text-muted fw-semibold fs-4'>
          If you need more info, please check{' '}
          <a href='#' className='link-primary'>
            Project Guidelines
          </a>
        </div>
      </div>

      <div className='mb-8'>
        <label className='fs-6 fw-semibold mb-2'>Assign Users</label>
        <Field
          component={Select}
          name='assignedAgents'
          aria-label='Select Users'
          placeholder='Assign project users by name'
          options={agentList}
          isSearchable={true}
          className='form-control form-control-solid'
          onChange={handleAgentChange}
          isMulti={true}
        />
      </div>
    </div>
  )
}
