import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {SearchFilter} from '../core/_models'

type Props = {
  searchFilters: SearchFilter
  setSearchFilter: (updateValues: SearchFilter) => void
  setStatusFilter: (isActive: boolean) => void
  getDocsList: () => void
  handlereset: () => void
}

const Filter: React.FC<Props> = ({
  handlereset,
  setSearchFilter,
  searchFilters,
  setStatusFilter,
  getDocsList,
}) => {
  const [status, setstatus] = useState('active')
  const [projectStatus, setProjectStatus] = useState('InProgress')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    setSearchFilter({
      ...searchFilters,
      filter: {
        ...searchFilters.filter,
        isActive: status === 'inactive',
        projectStatus: projectStatus,
      },
    })
  }, [status, projectStatus, setSearchFilter, searchFilters])

  const handleStatusChange = (e) => {
    const isActive = e.target.value === 'inactive'
    setstatus(isActive ? 'inactive' : 'active')
    setStatusFilter(isActive)
  }

  const handleProjectStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setProjectStatus(value)
  }

  return (
    <>
      <div
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-6 whitespace-nowrap'>
              Current Status:
            </label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleStatusChange}
              // value={searchFilters.filter.isActive ? 'active' : 'inactive'}
              value={status}
            >
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>

          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-6 whitespace-nowrap'>
              Project Status:
            </label>
            <select
              className='form-select form-select-solid fw-bolder w-75'
              onChange={handleProjectStatusChange}
              value={projectStatus}
            >
              <option value='InProgress'>In Progress</option>
              <option value='Pending'>Pending</option>
              <option value='Completed'>Completed</option>
              <option value='Overdue'>Overdue</option>
            </select>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handlereset}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
              onClick={getDocsList}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter
