import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {getServiceTasks, getVehiclesList, postServiceReminder} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'
import {useNavigate} from 'react-router-dom'

const AddServiceReminder = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [taskList, setTaskList] = useState([])
  const [products, setProducts] = useState<any>([])
  const [formData, setFormData] = useState({
    vehicle: '',
    serviceTask: '',
    timeInterval: 0,
    interval: '',
    intervalType: '1',
    timeDue: new Date(),
    due: '',
    dueType: '1',
    notification: false,
  })

  const fetchVehicles = async () => {
    const res = await getVehiclesList(currentUser.organization)
    console.log(res)
    const data = res.map((item: any) => {
      return {
        value: item._id,
        label: item.productName,
      }
    })
    setProducts(data)
  }

  const fetchTaskList = async () => {
    const res = await getServiceTasks(currentUser?.organization)
    console.log('taskList', res)
    const data = res.map((item: any) => {
      return {
        value: item._id,
        label: item.name,
      }
    })
    setTaskList(data)
  }

  useEffect(() => {
    fetchVehicles()
    fetchTaskList()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (formData.vehicle === '' || formData.serviceTask === '') {
      alert('Please fill all the required fields')
      return
    }
    setLoading(true)
    const data = {
      ...formData,
      company: currentUser.company,
      organization: currentUser.organization,
    }
    console.log(data)
    const res = await postServiceReminder(data)
    if (res) {
      navigate('/reminders/service')
    }
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h3>Add Service Reminder</h3>
        </div>
        <div className='card-body'>
          <div className='form-group mt-3'>
            <label className='required'>Vehicle</label>
            <Select
              isSearchable
              options={products}
              onChange={(e: any) => setFormData({...formData, vehicle: e?.value})}
            />
          </div>
          <div className='form-group mt-3'>
            <label className='required'>Service Task</label>
            <Select
              isSearchable
              options={taskList}
              onChange={(e: any) => setFormData({...formData, serviceTask: e?.value})}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-md-4'>
              <div className='form-group'>
                <label>Time Interval</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Every'
                  value={formData.interval}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      interval: e.target.value,
                      timeInterval: Number(e.target.value) * Number(formData.intervalType),
                    })
                  }
                />
              </div>
            </div>
            <div className='col-md-2 d-flex align-items-end'>
              <div className='form-group'>
                <select
                  className='form-control'
                  value={formData.intervalType}
                  onChange={(e) => setFormData({...formData, intervalType: e.target.value})}
                >
                  <option value={1}>Days</option>
                  <option value={7}>Weeks</option>
                  <option value={30}>Months</option>
                  <option value={365}>Years</option>
                </select>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label>Time Due Soon Threshold</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Enter Due'
                  value={formData.due}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      due: e.target.value,
                      timeDue: new Date(
                        new Date().getTime() +
                          Number(e.target.value) * Number(formData.dueType) * 24 * 60 * 60 * 1000
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className='col-md-2 d-flex align-items-end'>
              <div className='form-group'>
                <select
                  className='form-control'
                  value={formData.dueType}
                  onChange={(e) => setFormData({...formData, dueType: e.target.value})}
                >
                  <option value={1}>Days</option>
                  <option value={7}>Weeks</option>
                  <option value={30}>Months</option>
                  <option value={365}>Years</option>
                </select>
              </div>
            </div>
          </div>
          <div className='form-group mt-3'>
            <label className='form-check form-switch'>
              <input
                type='checkbox'
                checked={formData.notification}
                className='form-check-input me-2'
                onChange={(e) => setFormData({...formData, notification: e.target.checked})}
              />
              Notification
            </label>
          </div>
          <div className='my-3'>
            <button className='btn btn-primary' disabled={loading} onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddServiceReminder
