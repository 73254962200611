import React, {useEffect, useState} from 'react'
import {
  ChartsWidget12,
  ListsWidget13,
  StatisticsWidget7,
  TablesWidget17,
} from '../../../../_metronic/partials/widgets'
import EmployeeTable from '../../marketing/EmployeeTable'
import {useAuth} from '../../../modules/auth'
import {
  getEmployeeWebAttendanceByDate,
  getTotalDepartment,
  getTotalEmployeeComposition,
  getTotalEmployees,
} from '../core/_requests'
import {EmployeeComposition} from '../Components/EmployeeComposition'
import ReportSubmissionSummary from '../Components/ReportSubmissionSummary'

function HrmWrapper() {
  const {currentUser} = useAuth()
  const [totalDept, setTotalDept] = useState('Loading')
  const [totalEmployees, setTotalEmployees] = useState('Loading')
  const [totalAttendance, setTotalAttendance] = useState('Loading')
  const [empComposition, setEmpComposition] = useState({
    totalTemporaryEmployee: 0,
    totalPermanentEmployee: 0,
    totalFreelancerEmployee: 0,
  })

  useEffect(() => {
    const fetchTotalDept = async () => {
      await getTotalDepartment(currentUser.organization).then((res) => {
        setTotalDept(res)
      })
    }

    const fetchTotalEmployees = async () => {
      await getTotalEmployees(currentUser.organization).then((res) => {
        setTotalEmployees(res)
      })
    }

    const fetchTotalComposition = async () => {
      await getTotalEmployeeComposition(currentUser.organization).then((res) => {
        setEmpComposition(res)
      })
    }

    const fetchTotalAttendance = async () => {
      if (currentUser?.attendanceMethod === 'web') {
        const res = await getEmployeeWebAttendanceByDate({
          date: new Date(),
          orgid: currentUser?.organization,
        })
        setTotalAttendance(res?.presentIds?.length || 0)
        // setAbsentEmployees(employeeList.filter((item) => !res?.presentIds?.includes(item._id)))
      }
    }

    if (currentUser !== undefined) {
      fetchTotalDept()
      fetchTotalEmployees()
      fetchTotalAttendance()
      fetchTotalComposition()
    }
  }, [currentUser])

  return (
    <>
      <div className=' row g-5 g-xl-8 mb-5 mb-xl-8'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Total Departments'
            title={totalDept}
            description='Compared to the previous quarter'
            badgeColor='success'
            color={''}
            svgIcon={''}
            iconColor={''}
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Total Employees'
            title={totalEmployees}
            badgeColor='success'
            description='Compared to the previous quarter'
            color={''}
            svgIcon={''}
            iconColor={''}
          />
        </div>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-2'>
          <StatisticsWidget7
            className=''
            header='Total Attendance'
            title={`${totalAttendance} / ${totalEmployees}`}
            badgeColor='danger'
            description='Compared to the previous quarter'
            color={''}
            svgIcon={''}
            iconColor={''}
          />
        </div>
      </div>
      <div className='row g-5 gx-xxl-8'>
        <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
          <div className='mb-5 mb-xl-8'>
            <EmployeeComposition
              className='card-shrink overflow-hidden justify-around'
              temporaryCount={empComposition.totalTemporaryEmployee}
              permanentCount={empComposition.totalPermanentEmployee}
              freelancerCount={empComposition.totalFreelancerEmployee}
            />
          </div>
          <div className='mb-xl-8'>
            {/* <ListsWidget13 className={''} /> */}
            <ReportSubmissionSummary />
          </div>
        </div>
        <div className='col-xxl-8 mb-xl-8 col-lg-8'>
          {/* <TablesWidget17  className='card-xl-stretch' newMember={'/NewMember'}/> */}
          <EmployeeTable tableHeight={750} />
        </div>
      </div>
    </>
  )
}

export default HrmWrapper
