import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../modules/auth'
import { useParams } from 'react-router-dom'
import { getServiceRecordById } from '../../core/_requests'

const ServiceDetail = () => {
  const { currentUser } = useAuth()
  const { id } = useParams()
  const [data, setData] = useState<any>({})

  const fetchData = async () => {
    const res = await getServiceRecordById(id)
    console.log(res)
    setData(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const formatDate = (date: string) => {
    const d = new Date(date)
    // retur 03/12/2021
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <>


      <div className='row g-5 gx-xxl-8'>
        <div className="flex-col col-xl-5 mb-5">
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 mt-5">
              <div className="card bg-white card-shadow border">
                <div className="card-header px-6 py-5 border-0">Details</div>
                <div className="card-content p-6" style={{ height: "auto" }}>
                  <div className="flex flex-col w-full">
                    <div className="w-full first:mt-0 ">
                      <div className="flex flex-col w-full">
                        <div className="w-full first:mt-0 mt-4">
                          <h4 className="font-sans m-0 font-bold text-md">All Fields</h4>
                        </div>
                        <div className="w-full first:mt-0 mt-4">
                          <div>
                            <div className="flex flex-col rounded-none">
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4">
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Vehicle
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div style={{ maxWidth: "max-content" }}>
                                      <div className="flex w-full items-center">
                                        <div className="ml-4 flex items-center first:ml-0 flex-none">
                                          <div className="relative">
                                            {/* <img
                                              alt="Pickup Truck"
                                              className="object-cover outline-none rounded"
                                              src="https://d8g9nhlfs6lwh.cloudfront.net/security=policy:eyJoYW5kbGUiOiI5MnhQaktrV1Q2aDUxTkZmQXF6eiIsImV4cGlyeSI6NDUzNDk0OTM0MywiY2FsbCI6WyJyZWFkIiwiY29udmVydCJdfQ==,signature:98512fe85077eaf682cd7fae5577dab894121be7d74f22830326c6a78b5894f2/resize=w:75,h:75,fit:crop/cache=expiry:max/rotate=exif:true/92xPjKkWT6h51NFfAqzz"
                                              style={{ height: 26, width: 26 }}
                                            /> */}
                                            <div
                                              className="absolute rounded-full text-white shadow-solid"
                                              style={{
                                                bottom: "-2px",
                                                height: 9,
                                                right: "-3px",
                                                width: 9
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(24, 204, 108)",
                                                  left: "-5px",
                                                  position: "absolute",
                                                  top: "-5px"
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="dot-fill"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                  />
                                                </svg>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 -mt-3">
                                              <a
                                                className="text-blue-700 spark-a-focus hover:underline"
                                                role="link"
                                                href="/552aeb9291/vehicles/3274956"
                                                style={{
                                                  textDecoration: "underline dotted"
                                                }}
                                              >
                                                {data?.product?.productName}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Repair Priority Class
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div className="flex w-full justify-between items-center">
                                      <div className="ml-5 flex items-center first:ml-0 flex-1 min-w-0">
                                        <div className="-mx-4 -my-3 px-4 py-3 w-full rounded-md hover:bg-gray-50 group relative">
                                          <div className="flex w-full justify-between items-center">
                                            <div className="flex items-center first:ml-0 flex-1 min-w-0">
                                              <div className="w-full break-words">
                                                <div className="flex w-full justify-start items-center">
                                                  <div className="flex items-center first:ml-0">
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={20}
                                                        fill="currentColor"
                                                        viewBox="0 0 24 24"
                                                        color="green"
                                                        name="dot-fill"
                                                        className="green custom-color"
                                                      >
                                                        <path
                                                          fill=""
                                                          d="M12 6a6 6 0 100 12 6 6 0 000-12z"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </div>
                                                  <div className="flex items-center first:ml-0">
                                                    <div className="flex w-full items-center">
                                                      <div className="ml-2 flex items-center first:ml-0">
                                                        <span className="text-md text-gray-900 text-left font-normal normal-case font-sans m-0 truncate">
                                                          ....
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex items-center first:ml-0">
                                              <div
                                                aria-label="Click to edit"
                                                className="cursor-pointer invisible hover-none:text-gray-200 hover-none:visible group-hover:visible"
                                                data-testid="inline-edit-button"
                                                role="button"
                                                tabIndex={0}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={18}
                                                  height={18}
                                                  fill="currentColor"
                                                  viewBox="0 0 24 24"
                                                  name="edit"
                                                  className=""
                                                >
                                                  <path
                                                    fill=""
                                                    fillRule="evenodd"
                                                    d="M17 5.121c-.42 0-.878.172-1.293.586L14.414 7 17 9.586l1.293-1.293c.415-.415.586-.873.586-1.293 0-.42-.172-.878-.586-1.293-.415-.414-.873-.586-1.293-.586zM15.586 11L13 8.414 6.874 14.54 5.581 18.42l3.879-1.293L15.586 11zM17 3.121c.995 0 1.95.415 2.707 1.172.757.757 1.172 1.712 1.172 2.707 0 .995-.415 1.95-1.172 2.707l-9 9a1 1 0 01-.39.242l-6 2a1 1 0 01-1.266-1.265l2-6a1 1 0 01.242-.391l9-9C15.05 3.536 16.005 3.12 17 3.12z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Start Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.startDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Completion Date
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {formatDate(data?.completionDate)}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4">
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Odometer
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div className="flex w-full justify-start items-center">
                                      <div className="ml-3 flex items-center first:ml-0">
                                        <p className="break-words max-w-full text-md undefined text-left font-normal font-sans m-0">
                                          {data?.odometer}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group"
                              >
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Created By
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <div style={{ maxWidth: "max-content" }}>
                                      <div className="flex w-full items-center">
                                        <div className="ml-4 flex items-center first:ml-0 flex-none">
                                          <div>
                                            <svg
                                              aria-hidden="true"
                                              aria-label="AS"
                                              height={26}
                                              viewBox="0 0 26 26"
                                              width={26}
                                              style={{
                                                color: "rgb(138, 129, 213)",
                                                display: "block"
                                              }}
                                            >
                                              <circle
                                                cx={13}
                                                cy={13}
                                                r={13}
                                                style={{ fill: "currentcolor" }}
                                              />
                                              <text
                                                alignmentBaseline="central"
                                                className="text-white"
                                                dominantBaseline="central"
                                                fontSize="10px"
                                                textAnchor="middle"
                                                x={13}
                                                y={13}
                                                style={{
                                                  cursor: "inherit",
                                                  fill: "currentcolor",
                                                  userSelect: "none"
                                                }}
                                              >
                                                {data?.createdBy?.fullName[0]}
                                              </text>
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0">
                                          <a
                                            className="text-blue-700 spark-a-focus hover:underline"
                                            role="link"
                                            href="/552aeb9291/contacts/1866579"
                                            style={{ textDecoration: "underline dotted" }}
                                          >
                                            {data?.createdBy?.fullName}
                                          </a>
                                        </div>
                                        <div className="ml-4 flex items-center first:ml-0" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Vendor
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.vendor?.displayName}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Reference
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.reference}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="transition-colors duration-500 flex flex-col sm:flex-row items-start w-full py-5 border-b border-gray-25 last:border-b-0"
                                data-testid="detail-field"
                                role="group">
                                <div className="w-full sm:w-1/2 md:w-1/3">
                                  <div className="flex items-center w-full relative">
                                    <div className="mr-4" style={{}}>
                                      <span className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                                        Notes
                                      </span>
                                    </div>
                                    <div
                                      className="flex align-items-center"
                                      style={{ width: 16 }}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-0 pt-3 sm:pt-0 sm:pl-6 w-full sm:w-1/2 md:w-2/3 justify-start detail-field-value">
                                  <div className="break-words whitespace-pre-wrap w-full">
                                    <abbr
                                      className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case cursor-default font-sans m-0"
                                      style={{ textDecoration: "underline dotted" }}
                                    >
                                      {data?.notes}
                                    </abbr>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="mb-5 mb-xl-8">
            <div className="w-full first:mt-0 mt-5">
              <div className="card bg-white card-shadow border">
                <div className="card-header px-6 pt-6 fs-3 text-md fw-bold border-0">Resolved Issues</div>
                <div className="card-content p-6" style={{ height: "auto" }}>
                  <div className="flex flex-col w-full">
                    <div className="w-full first:mt-0">
                      <div className="flex flex-col w-full">
                        {data?.issues?.map((item, index) =>
                          <div key={index} className="w-full first:mt-0 mt-2 text-blue-700 spark-a-focus hover:underline" style={{ textDecoration: "underline dotted" }}>
                            <span className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0">
                              #{item.issueId} - {item.summary}
                            </span>

                          </div>)}
                        <div className="w-full first:mt-0 mt-2">
                          <p className="break-words max-w-full text-md text-gray-600 text-left font-normal normal-case font-sans m-0">
                            {/* Bolt in back left side tire. Slow leak. */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-xxl-7 mb-5 mb-xl-8">
          <div className="spark-column" style={{ justifySelf: "stretch" }}>
            <div className="flex flex-col w-full">
              <div className="w-full first:mt-0 " />
              <div className="w-full first:mt-0 ">
                <div className="card bg-white card-shadow border mt-5">
                  <div className="card-header px-6 pt-5 border-0 fs-3 fw-bold text-md">Line Items</div>
                  <div
                    className="card-content card-content-flush p-0"
                    style={{ height: "auto" }}
                  >
                    <div className="flex flex-col w-full">
                      <div className="w-full first:mt-0 ">
                        <div className="flex flex-col w-full">
                          <div className="w-full first:mt-0 ">
                            <div className="flex flex-col w-full">
                              <div className="w-full first:mt-0 ">
                                <div className="p-6">
                                  <div className="md:flex py-0 md:px-0 justify-between relative px-6 divide-y md:divide-y-0 md:divide-x divide-gray-200 rounded-lg border border-gray-50 stat-group">
                                    <div className="md:px-6 px-5 w-full py-6 ">
                                      <div className="flex w-full">
                                        <div className=" first:ml-0">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 mt-2">
                                              <div className="flex w-full items-center">
                                                <div className="ml-2 flex items-center first:ml-0">
                                                  <span className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0 leading-5">
                                                    Labor
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-full first:mt-0 mt-2">
                                              <span className="break-words max-w-full text-2xl text-gray-900 text-left font-normal normal-case font-sans m-0">
                                                <div className="text-left">{data?.laborCost}</div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" first:ml-0 flex-1 min-w-0" />
                                        <div className=" first:ml-0">
                                          <div className="stat-actions"> </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="md:px-6 px-5 w-full py-6 border-e">
                                      <div className="flex w-full">
                                        <div className=" first:ml-0">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 mt-2">
                                              <div className="flex w-full items-center">
                                                <div className="ml-2 flex items-center first:ml-0">
                                                  <span className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0 leading-5">
                                                    Parts
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-full first:mt-0 mt-2">
                                              <span className="break-words max-w-full text-2xl text-gray-900 text-left font-normal normal-case font-sans m-0">
                                                <div className="text-left">{data?.partsCost}</div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" first:ml-0 flex-1 min-w-0" />
                                        <div className=" first:ml-0">
                                          <div className="stat-actions"> </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="md:px-6 px-5 w-full py-6">
                                      <div className="flex w-full">
                                        <div className=" first:ml-0">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 mt-2">
                                              <div className="flex w-full items-center">
                                                <div className="ml-2 flex items-center first:ml-0">
                                                  <span className="break-words max-w-full text-md text-gray-900 text-left font-normal normal-case font-sans m-0 leading-5">
                                                    Total
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-full first:mt-0 mt-2">
                                              <span className="break-words max-w-full text-2xl text-gray-900 text-left font-normal normal-case font-sans m-0">
                                                <div className="text-left">{data?.total}</div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className=" first:ml-0 flex-1 min-w-0" />
                                        <div className=" first:ml-0">
                                          <div className="stat-actions"> </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full first:mt-0 mt-5">
                                <div className="relative overflow-auto min-w-full">
                                  <table className="table table-bordered border-collapse  w-full">
                                    <thead className="text-gray-900 text-md">
                                      <tr className="border-gray-50">
                                        <th className="px-6 py-5 text-left font-semibold w-3/5">
                                          Item
                                        </th>
                                        <th className="px-6 py-5 text-right font-semibold">
                                          Labor
                                        </th>
                                        <th className="px-6 py-5 text-right font-semibold">
                                          Parts
                                        </th>
                                        <th className="px-6 py-5 text-right font-semibold">
                                          Subtotal
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="border-b border-gray-50">
                                        <td className="border-gray-50 px-6 py-6 text-left font-medium">
                                          <div className="flex flex-col w-full">
                                            <div className="w-full first:mt-0 mt-2">
                                              <div className="flex w-full items-center">
                                                <div className="ml-4 flex items-center first:ml-0">
                                                  <div
                                                    style={{ maxWidth: "max-content" }}
                                                  >
                                                    <div className="flex flex-col w-full">
                                                      <div className="w-full first:mt-0 mt-2">
                                                        <div className="flex w-full items-center">
                                                          <div className="ml-4 flex items-center first:ml-0">
                                                            <abbr
                                                              className="break-words max-w-full text-md text-gray-900 text-left normal-case cursor-default font-sans m-0"
                                                              style={{
                                                                textDecoration:
                                                                  "underline dotted"
                                                              }}
                                                            >
                                                              Engine Oil &amp; Filter
                                                              Replacement
                                                            </abbr>
                                                          </div>
                                                          <div className="ml-4 flex items-center first:ml-0">
                                                            <span>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={16}
                                                                height={16}
                                                                fill="currentColor"
                                                                viewBox="0 0 24 24"
                                                                color="text-gray-400"
                                                                name="symbol-s-square-fill"
                                                                className="text-gray-400 custom-color"
                                                              >
                                                                <path
                                                                  fill=""
                                                                  fillRule="evenodd"
                                                                  d="M3 7a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7zm12.776 6.733c0 1.843-1.417 3.017-3.79 3.017-2.25 0-3.661-1.024-3.755-2.698l-.006-.113h2.186l.006.057c.063.555.681.955 1.606.955.893 0 1.48-.406 1.48-.98v-.007c0-.506-.4-.743-1.455-.949l-.931-.181c-1.905-.369-2.73-1.268-2.73-2.673v-.007c.007-1.767 1.506-2.904 3.58-2.904 2.26 0 3.49 1.143 3.628 2.736l.006.075h-2.123l-.013-.075c-.093-.562-.655-.937-1.486-.937-.825 0-1.312.368-1.312.905v.007c0 .512.412.793 1.387.987l.93.18c1.918.376 2.792 1.144 2.792 2.599v.006z"
                                                                  clipRule="evenodd"
                                                                />
                                                              </svg>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="w-full first:mt-0 mt-2">
                                                        <p className="break-words max-w-full undefined text-gray-900 text-left font-normal normal-case font-sans m-0" />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-full first:mt-0 mt-2">
                                              <div className="p-6 pl-7 pb-0">
                                                <div className="flex flex-col w-full">
                                                  <div className="w-full first:mt-0 mt-4">
                                                    <div className="flex w-full">
                                                      <div className="ml-8 first:ml-0">
                                                        <div className="flex flex-col w-full">
                                                          <div className="w-full first:mt-0 mt-0">
                                                            <div className="text-gray-600 text-sm font-normal">
                                                              <div
                                                                style={{
                                                                  display: "inline-block",
                                                                  overflow: "hidden",
                                                                  textOverflow:
                                                                    "ellipsis",
                                                                  width: "100%"
                                                                }}
                                                              >
                                                                <p
                                                                  style={{
                                                                    display: "inline",
                                                                    whiteSpace: "nowrap"
                                                                  }}
                                                                >
                                                                  Reason for Repair
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="w-full first:mt-0 mt-0">
                                                            <div style={{ width: 170 }}>
                                                              <div className="flex flex-col w-full">
                                                                <div className="w-full first:mt-0">
                                                                  <span className="text-gray-200 text-sm font-normal">
                                                                    —
                                                                  </span>
                                                                </div>
                                                                <div className="w-full first:mt-0">
                                                                  <div className="text-gray-600 text-xs font-normal -mt-3" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="ml-8 first:ml-0">
                                                        <div className="flex flex-col w-full">
                                                          <div className="w-full first:mt-0 mt-0">
                                                            <div className="text-gray-600 text-sm font-normal">
                                                              <div
                                                                style={{
                                                                  display: "inline-block",
                                                                  overflow: "hidden",
                                                                  textOverflow:
                                                                    "ellipsis",
                                                                  width: "100%"
                                                                }}
                                                              >
                                                                <p
                                                                  style={{
                                                                    display: "inline",
                                                                    whiteSpace: "nowrap"
                                                                  }}
                                                                >
                                                                  Category / System /
                                                                  Assembly / Component
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="w-full first:mt-0 mt-0">
                                                            <div className="flex w-full">
                                                              <div className="ml-4 first:ml-0">
                                                                <div
                                                                  style={{
                                                                    maxWidth: 105
                                                                  }}
                                                                >
                                                                  <div className="flex flex-col w-full">
                                                                    <div className="w-full first:mt-0">
                                                                      <span className="text-gray-200 text-sm font-normal">
                                                                        —
                                                                      </span>
                                                                    </div>
                                                                    <div className="w-full first:mt-0">
                                                                      <div className="text-gray-600 text-xs font-normal -mt-3" />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="ml-4 first:ml-0">
                                                                <div
                                                                  style={{
                                                                    maxWidth: 105
                                                                  }}
                                                                >
                                                                  <div className="flex flex-col w-full">
                                                                    <div className="w-full first:mt-0">
                                                                      <span className="text-gray-200 text-sm font-normal">
                                                                        —
                                                                      </span>
                                                                    </div>
                                                                    <div className="w-full first:mt-0">
                                                                      <div className="text-gray-600 text-xs font-normal -mt-3" />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="border-gray-50 px-6 py-6 text-right font-medium"
                                          style={{ verticalAlign: "top", width: 120 }}
                                        >
                                          <span className="text-gray-900">
                                            <div className="text-right">$18.00</div>
                                          </span>
                                        </td>
                                        <td
                                          className="border-gray-50 px-6 py-6 text-right font-medium"
                                          style={{ verticalAlign: "top", width: 120 }}
                                        >
                                          <span className="text-gray-900">
                                            <div className="text-right">$28.99</div>
                                          </span>
                                        </td>
                                        <td
                                          className="border-gray-50 px-6 py-6 text-right font-medium"
                                          style={{ verticalAlign: "top", width: 120 }}
                                        >
                                          <span className="text-gray-900">
                                            <div className="text-right">$46.99</div>
                                          </span>
                                        </td>
                                      </tr>
                                     
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="w-full first:mt-0 mt-5">
                                <div className="relative overflow-auto min-w-full pt-5 pb-4">
                                  <table className="min-w-full w-full">
                                    <tbody>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td className="px-6 pt-5 pb-0 whitespace-nowrap">
                                          <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                            Subtotal
                                          </p>
                                        </td>
                                        <td
                                          className="px-6 pt-5 pb-0 text-right"
                                          style={{ width: 175 }}
                                        >
                                          <div className="flex w-full justify-end items-center">
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <span>+</span>
                                            </div>
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <div className="text-right">$70.99</div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-0 whitespace-nowrap">
                                          <p className="break-words max-w-full text-md text-gray-600 text-left font-medium normal-case font-sans m-0 indent-5">
                                            Labor
                                          </p>
                                        </td>
                                        <td
                                          className="px-6 pb-0 text-right text-gray-600"
                                          style={{ width: 175 }}
                                        >
                                          <div className="text-right">$42.00</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td
                                          className="px-6 pt-0 pb-5 whitespace-nowrap border-b border-gray-50"
                                          style={{ width: 140 }}
                                        >
                                          <p className="break-words max-w-full text-md text-gray-600 text-left font-medium normal-case font-sans m-0 indent-5">
                                            Parts
                                          </p>
                                        </td>
                                        <td
                                          className="px-6 pt-0 pb-5 text-right border-b border-gray-50 text-gray-600"
                                          style={{ width: 175 }}
                                        >
                                          <div className="text-right">$28.99</div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5 whitespace-nowrap">
                                          <div className="flex w-full justify-start items-center">
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                                Tax
                                              </p>
                                            </div>
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <p className="break-words max-w-full text-md text-gray-400 text-left font-normal normal-case font-sans m-0">
                                                (0.0%)
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="px-6 py-5 text-right"
                                          style={{ width: 175 }}
                                        >
                                          <div className="flex w-full justify-end items-center">
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <span>+</span>
                                            </div>
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <div className="text-right">$0.00</div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td
                                          className="px-6 py-5 whitespace-nowrap border-b border-gray-50"
                                          style={{ width: 140 }}
                                        >
                                          <div className="flex w-full justify-start items-center">
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <p className="break-words max-w-full text-md text-gray-900 text-left font-medium normal-case font-sans m-0">
                                                Discount
                                              </p>
                                            </div>
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <p className="break-words max-w-full text-md text-gray-400 text-left font-normal normal-case font-sans m-0">
                                                (0.0%)
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          className="px-6 py-5 text-right border-b border-gray-50"
                                          style={{ width: 175 }}
                                        >
                                          <div className="flex w-full justify-end items-center">
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <span>-</span>
                                            </div>
                                            <div className="ml-2 flex items-center first:ml-0">
                                              <div className="text-right">$0.00</div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5" />
                                        <td className="px-6 py-5 whitespace-nowrap">
                                          <p className="break-words max-w-full text-lg text-gray-900 text-left font-semibold normal-case font-sans m-0">
                                            Total
                                          </p>
                                        </td>
                                        <td
                                          className="px-6 py-5 text-right"
                                          style={{ width: 175 }}
                                        >
                                          <p className="break-words max-w-full text-lg text-gray-900 text-left font-bold normal-case font-sans m-0" />
                                          <div className="text-right">$70.99</div>
                                          <p />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </>
  )
  //   remember tax and discount values are in %
}

export default ServiceDetail
